/*eslint-disable */
import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import mammoth from 'mammoth/mammoth.browser';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import './index.css';
import getErrorMessage from 'services/helpers/getErrorMessage';

function Doc(props) {
  const { item, close } = props;
  const { url } = item;

  useEffect(() => {
    fetch(url)
      .then(data => {
        mammoth
          .convertToHtml(
            { arrayBuffer: data.blob() },
            { includeDefaultStyleMap: true },
          )
          .then((result) => {
            const docEl = document.createElement('div');
            docEl.className = 'document-container';
            docEl.innerHTML = result.value;
            document.getElementById('docx').innerHTML = docEl.outerHTML;
          })
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      })
  }, [url]);

  return (
    <div className="docx-holder">
      <div id="docx" />
      <div onClick={close} className="button-close">
        {t`Close`}
      </div>
    </div>
  );

}

Doc.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string
  }),
  close: PropTypes.func
};

Doc.defaultProps = {
  item: {},
  close: () => {}
};

export default Doc;
