import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';

/* eslint-disable no-shadow */
export function* createResource(api, { data }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.post], `/${resource}`, data);
    yield put(actions.resourceCreateSuccess(resource, detail, { data }, thunk));
  } catch (e) {
    yield put(actions.resourceCreateFailure(resource, e, { data }, thunk));
  }
}

// TODO: Write a createResouceDetail item

// Helper function to read the stream
function* readStream(stream) {
  const reader = stream.getReader();
  let result = '';
  while (true) {
    const { done, value } = yield call([reader, reader.read]);
    if (done) break;
    result += new TextDecoder().decode(value);
  }
  return result;
}

export function* readResourceList(api, props, { resource, thunk }) {
  try {
    const list = yield call([api, api.get], `/${resource}`, props);
    yield put(actions.resourceListReadSuccess(resource, list, props, thunk));
  } catch (e) {
    let errorData;
    if (e.response?.body?.getReader) {
      try {
        const bodyText = yield call(readStream, e.response.body);
        errorData = JSON.parse(bodyText);
      } catch (streamError) {
        errorData = e;
      }
    }

    yield put(
      actions.resourceListReadFailure(resource, errorData, props, thunk),
    );
  }
}

export function* readResourceDetail(api, { needle }, { resource, thunk }) {
  try {
    const detail = yield call([api, api.get], `/${resource}/${needle}`);
    yield put(
      actions.resourceDetailReadSuccess(resource, detail, { needle }, thunk),
    );
  } catch (e) {
    yield put(
      actions.resourceDetailReadFailure(resource, e, { needle }, thunk),
    );
  }
}

export function* updateResource(api, { needle, data }, { resource, thunk }) {
  try {
    const detail = yield call(
      [api, api.put],
      `/${resource}${needle ? `/${needle}` : ''}`,
      data,
    );
    yield put(
      actions.resourceUpdateSuccess(resource, detail, { needle, data }, thunk),
    );
  } catch (e) {
    yield put(
      actions.resourceUpdateFailure(resource, e, { needle, data }, thunk),
    );
  }
}

export function* deleteResource(api, { needle, data }, { resource, thunk }) {
  try {
    const detail = yield call(
      [api, api.delete],
      `/${resource}${needle ? `/${needle}` : ''}`,
      data,
    );
    yield put(
      actions.resourceDeleteSuccess(resource, detail, { needle }, thunk),
    );
  } catch (e) {
    yield put(actions.resourceDeleteFailure(resource, e, { needle }, thunk));
  }
}

export function* watchResourceCreateRequest(api, { payload, meta }) {
  yield call(createResource, api, payload, meta);
}

export function* watchResourceListReadRequest(api, { payload, meta }) {
  yield call(readResourceList, api, payload, meta);
}

export function* watchResourceDetailReadRequest(api, { payload, meta }) {
  yield call(readResourceDetail, api, payload, meta);
}

export function* watchResourceUpdateRequest(api, { payload, meta }) {
  yield call(updateResource, api, payload, meta);
}

export function* watchResourceDeleteRequest(api, { payload, meta }) {
  yield call(deleteResource, api, payload, meta);
}

export default function* _({ api }) {
  yield takeEvery(
    actions.RESOURCE_CREATE_REQUEST,
    watchResourceCreateRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_LIST_READ_REQUEST,
    watchResourceListReadRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_DETAIL_READ_REQUEST,
    watchResourceDetailReadRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_UPDATE_REQUEST,
    watchResourceUpdateRequest,
    api,
  );
  yield takeEvery(
    actions.RESOURCE_DELETE_REQUEST,
    watchResourceDeleteRequest,
    api,
  );
}
