import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';
import { rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner, Heading, Button } from 'components';

import { toDashboard } from 'services/routehelpers';
import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { setXeroRefreshApi } from 'services/apihelpers';

function XeroRefresh(props) {
  const { history, location } = props;
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const search = queryString.parse(location.search);
  const response = useSelector((state) =>
    fromResource.getList(state, setXeroRefreshApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${setXeroRefreshApi}ListRead`),
  );
  const handleDashboardRedirect = () => {
    history.replace(toDashboard());
  };

  useEffect(() => {
    if (response?.error) {
      setError(error);
    } else {
      history.replace(toDashboard());
      toast.success(t`Xero integration tokens were refreshed successfully`);
    }
  }, [JSON.stringify(response)]);
  useEffect(() => {
    dispatch(resourceListReadRequest(setXeroRefreshApi, search));
  }, []);

  return (
    <Container>
      {failed || error ? (
        <>
          <Heading
            kind="danger"
            level={3}
            style={{ maxWidth: '500px' }}
            alignment="center"
          >
            {error?.message ||
              error ||
              t`Something went wrong! Please try again later.`}
          </Heading>
          <Button
            fill
            wide
            type="submit"
            kind="success"
            onClick={handleDashboardRedirect}
          >
            <Trans>Go to dashboard</Trans>
          </Button>
        </>
      ) : (
        <Spinner type="normal" />
      )}
    </Container>
  );
}

export default XeroRefresh;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
