import { t } from '@lingui/macro';

export const accrualsInfoModalGridFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'accountId',
      'accountDescription',
      'period',
      'transactionId',
      'description',
      'taxDescription',
    ],
  },
];

export default { accrualsInfoModalGridFilters };
