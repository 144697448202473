import { t } from '@lingui/macro';

export const vatOutgoingBalanceFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['description'],
  },
];

export default { vatOutgoingBalanceFilters };
