import { stringify } from 'query-string';
import store from 'store/configure';
import * as PAGE_PATH from '../../components/constants';

export const decodeUrl = (url) => {
  const companyId = url.split('/')[2];
  const accountingYear = url.split('/')[3];
  const accountingPeriod = url.split('/')[4];

  return {
    companyUuid: companyId,
    year: accountingYear,
    period: accountingPeriod,
  };
};

export const getUrlWithPrefilledParams = (url, query, id, subId) => {
  const companyFromSession = store.getState().company;

  let newUrl = url;
  let company;

  if (companyFromSession) {
    company = companyFromSession;
  }

  if (company && company.uuid) {
    newUrl = newUrl.replace(':companyId?', company.uuid);
    newUrl = newUrl.replace(':accountingYear?', +company.currentAccountingYear);
    newUrl = newUrl.replace(
      ':accountingPeriod?',
      company.currentWorkingPeriodEnd,
    );
  }

  if (id) {
    newUrl = `${newUrl}/${id}`;
  }

  if (subId) {
    newUrl = `${newUrl}/${subId}`;
  }

  if (query) {
    newUrl = `${newUrl}?${stringify(query)}`;
  }

  return newUrl;
};

export const getUrlWithPredefinedCompany = (url, companyData, query) => {
  let newUrl = url;

  if (companyData && companyData.uuid) {
    newUrl = newUrl.replace(':companyId?', companyData.uuid);
    newUrl = newUrl.replace(
      ':accountingYear?',
      +companyData.currentAccountingYear,
    );
    newUrl = newUrl.replace(
      ':accountingPeriod?',
      companyData.currentWorkingPeriodEnd,
    );
  }

  if (query && Object.keys(query).length) {
    // Remove session state from query
    const newQuery = Object.keys(query).reduce((acc, key) => {
      if (key !== 'code' && key !== 'state' && key !== 'session_state') {
        acc[key] = query[key];
      }
      return acc;
    }, {});

    newUrl = `${newUrl}?${stringify(newQuery)}`;
  }

  return newUrl;
};

export const toSelectCompany = () =>
  getUrlWithPrefilledParams(PAGE_PATH.SELECT_COMPANY_PAGE_PATH);

/* Dashboard routes */
export const toHome = () =>
  getUrlWithPrefilledParams(PAGE_PATH.ACCOUNTFLOW_PAGE_PATH);

export const toDashboard = (query, predefinedCompany) =>
  predefinedCompany
    ? getUrlWithPredefinedCompany(
        PAGE_PATH.DASHBOARD_PAGE_PATH,
        predefinedCompany,
        query,
      )
    : getUrlWithPrefilledParams(PAGE_PATH.DASHBOARD_PAGE_PATH, query);

/* Account Reconciliation routes */
export const toAccountReconciliation = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.OVERVIEW_RECONCILIATION_PAGE_PATH, query);

export const toPayrollReconciliation = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.PAYROLL_RECONCILIATION_PAGE_PATH, query);

export const toVATReconciliation = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.VAT_RECONCILIATION_PAGE_PATH, query);

export const toVATReconciliationDetailed = (query, id) =>
  getUrlWithPrefilledParams(PAGE_PATH.VAT_RECONCILIATION_PAGE_PATH, query, id);

export const toVATControlDrilldown = (query, id) =>
  getUrlWithPrefilledParams(
    PAGE_PATH.VAT_RECONCILIATION_CONTROL_PAGE_PATH,
    query,
    id,
  );

export const toReconciliation = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.ACCOUNT_RECONCILIATION_PAGE_PATH, query);

export const toOpeningBalanceRegistration = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.OPENING_BALANCE_PAGE_PATH, query);

export const toReconciliationSettings = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.RECONCILIATION_SETTINGS_PAGE_PATH, query);

export const toAssets = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.ASSETS_RECONCILIATION_PAGE_PATH, query);

/* Admin routes */
export const toGeneralSettings = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.GENERAL_SETTINGS_PAGE_PATH, query);

export const toOrganisationSettings = (query) =>
  getUrlWithPrefilledParams(
    PAGE_PATH.ADMIN_ORGANISATION_SETTINGS_PAGE_PATH,
    query,
  );

export const toGroupSettings = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.GROUP_SETTINGS_PAGE_PATH, query);

export const toGroupCompanyDetailed = (query, id) =>
  getUrlWithPrefilledParams(
    PAGE_PATH.GROUP_SETTINGS_COMPANY_PAGE_PATH,
    query,
    id,
  );

export const toGroupUserDetailed = (query, id) =>
  getUrlWithPrefilledParams(PAGE_PATH.GROUP_SETTINGS_USER_PAGE_PATH, query, id);

export const toAdminCompanySettings = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.ADMIN_COMPANY_SETTINGS_PAGE_PATH, query);

export const toAdminUserSettings = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.ADMIN_USER_SETTINGS_PAGE_PATH, query);

/* Reports routes */
export const toReportsTrialBalance = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.REPORTS_OVERVIEW_PAGE_PATH, query);

export const toReportsAccountDetails = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.ACCOUNT_DETAILS_REPORT_PAGE_PATH, query);

export const toAudit = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.AUDIT_PAGE_PATH, query);

export const toFiles = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.FILES_PAGE_PATH, query);

export const toAuditDetailed = (query, id, subId) =>
  getUrlWithPrefilledParams(PAGE_PATH.AUDIT_PAGE_PATH, query, id, subId);

export const toPrintableReports = (query) =>
  getUrlWithPrefilledParams(PAGE_PATH.GENERATE_REPORT_VIEWER_PAGE_PATH, query);
