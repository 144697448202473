import React, { useMemo } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, Row, Col } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import {
  payrollSSDetailsAltinn,
  payrollSSDetailsGL,
} from 'services/tableshapes';
import { formatNumber } from 'services/helpers';

import { DESKTOP } from 'constants/mediaSizes';

function PayrollSocialSecurityModal(props) {
  const {
    data,
    type,
    search,
    loading,
    toggleModal,
    handleAction,
    activePeriods,
    changePercentage,
    actionSubmitting,
    toggleChangePercentage,
  } = props;
  const ssTitle = t`Social Security - Details`;
  const vpTitle = t`Vacation Pay - Details`;

  const filterByPeriod = (item) => {
    if (search.accumulated) {
      return +item.period <= +activePeriods;
    }

    if (search.bimonthly) {
      return [activePeriods - 1, +activePeriods].includes(+item.period);
    }

    return +item.period === +activePeriods;
  };
  let glSum = data?.glAccounts?.reduce(
    (value, next) =>
      value +
      (next?.lines
        ?.filter(filterByPeriod)
        ?.reduce((sum, item) => sum + item.amount, 0) || 0),
    0,
  );
  const reportedSum = data?.reportedElements
    ?.filter(filterByPeriod)
    ?.reduce((value, next) => value + next.amount * (next.percentage / 100), 0);

  const altinnData = useMemo(() => {
    const altinnDataItems = [];

    data?.reportedElements?.filter(filterByPeriod)?.forEach((item) => {
      altinnDataItems.push(item);
    });

    return altinnDataItems;
  }, [JSON.stringify(data?.reportedElements)]);

  const glData = useMemo(() => {
    const glDataItems = [];

    data?.glAccounts?.forEach((item) => {
      item.lines?.filter(filterByPeriod).forEach((line) => {
        glSum += line.amount;
        glDataItems.push({
          ...line,
          description: `${item.accountId} - ${item.accountDescription}`,
        });
      });
    });

    return glDataItems;
  }, [JSON.stringify(data?.glAccounts)]);

  const content = (
    <>
      <Row>
        <GlobalContainer>
          <TableContainer width={64}>
            <Header level={4} alignment="center">
              <Trans>Altinn</Trans>
            </Header>
            <ButtonHolder>
              <Button magnify size="sm" onClick={toggleChangePercentage}>
                <Trans>Change percentage</Trans>
              </Button>
            </ButtonHolder>
            <CustomTableWithAutoSettings
              {...{
                data: altinnData,
                loading,
                isModal: true,
                hideFilters: true,
                handleAction,
                actionSubmitting,
                columns: payrollSSDetailsAltinn(changePercentage),
                tableId: 'payrolSSDetailsAltinn',
                loadingId: 'accountId',
              }}
            />
          </TableContainer>
          <TableContainer width={36}>
            <Header level={4} alignment="center">
              <Trans>GL</Trans>
            </Header>
            <CustomTableWithAutoSettings
              {...{
                data: glData,
                loading,
                handleAction,
                isModal: true,
                hideFilters: true,
                columns: payrollSSDetailsGL(),
                tableId: 'payrolSSDetailsGl',
                loadingId: 'accountId',
              }}
            />
          </TableContainer>
        </GlobalContainer>
      </Row>
      <div className="text-right">
        <strong>
          <Trans>Deviation:</Trans>{' '}
        </strong>
        <span
          className={cx({
            'text-danger': Math.sign(reportedSum - glSum) === -1,
            'text-primary': Math.sign(reportedSum - glSum) === 1,
          })}
        >
          {formatNumber(reportedSum - glSum).formatted}
        </span>
      </div>
    </>
  );

  return (
    <ModalStyles
      show
      bsSize="xl"
      animation={false}
      onEscapeKeyDown={toggleModal}
    >
      <Modal.Header>
        <Heading level={3}>{type === 'ss' ? ssTitle : vpTitle}</Heading>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>{content}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </ModalStyles>
  );
}

export default PayrollSocialSecurityModal;

const ButtonHolder = styled.div`
  height: 25px;
  position: absolute;
  top: -5px;
  left: 20px;
`;

const TableContainer = styled.div`
  width: ${({ width }) => `${width}%`};
`;

const Header = styled((props) => <Heading {...props} />)`
  margin-top: 0;
`;

const ModalStyles = styled((props) => <Modal {...props} />)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1240px;
    }
  }
`;

const GlobalContainer = styled.div`
  width: 100%;
  padding: 0 15px 10px;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
