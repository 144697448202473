import React from 'react';
import { HelpBlock as BootStrapHelpBlock } from 'react-bootstrap';

/**
 * Help Block component
 */
function HelpBlock({ ...props }) {
  return <BootStrapHelpBlock {...props} />;
}

export default HelpBlock;
