import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';
import styled from 'styled-components/macro';
import { Col, FormControl, FormGroup } from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import {
  Input,
  Label,
  Toggle,
  Spinner,
  HelpBlock,
  DayPicker,
} from 'components';

export function ReactFormField(props) {
  const {
    help,
    type,
    size,
    name,
    label,
    loading,
    multiple,
    children,
    feedback,
    onChange,
    inputGrid,
    horizontal,
    setLoading,
    inputGridOffset,
    validatePristine,
    defaultIsTouched,
  } = props;

  const validateField = async (value, instance) => {
    if (props.validate) {
      // in case if passed array of functions, go through all of them and validate
      if (Array.isArray(props.validate)) {
        // eslint-disable-next-line
        for (let i = 0; i < props.validate.length; i++) {
          if (typeof props.validate[i] === 'function') {
            // eslint-disable-next-line no-await-in-loop
            const error = await props.validate[i](value, instance.form?.values);

            if (error) {
              if (typeof setLoading === 'function') {
                setLoading(false);
              }
              return error;
            }
          }
        }
        // in case if it's simply one function passed
      } else if (typeof props.validate === 'function') {
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
        // eslint-disable-next-line no-return-await
        return await props.validate(value, instance.form?.values);
      }
    }

    return false;
  };

  const validate = async (value, instance) =>
    // eslint-disable-next-line no-return-await
    await instance.debounce(
      // eslint-disable-next-line no-return-await
      async () => await validateField(value, instance),
      instance.meta?.isTouched ? 800 : 0,
    );

  const {
    getInputProps,
    meta: { error },
    runValidation,
  } = useField(props.name, {
    validate,
    validatePristine,
    defaultIsTouched,
    defaultValue: '',
  });
  const inputProps = getInputProps();

  if (type === 'checkbox') {
    inputProps.checked =
      inputProps.value === 'true' || inputProps.value === true;
  }

  const customOnChange = (e) => {
    if (type === 'checkbox') {
      e.target.value = e.target.checked;
    } else if (type === 'toggle') {
      // eslint-disable-next-line no-param-reassign
      e = {
        target: {
          value: inputProps.value === 'true' ? 'false' : 'true',
        },
      };
    } else if (type === 'autoSuggest') {
      // eslint-disable-next-line no-param-reassign
      e = {
        target: {
          value: multiple
            ? e.map((item) => item.value) || []
            : e[0]?.value || '',
        },
      };
    }
    inputProps.onChange(e);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };
  const gridValue = { md: inputGrid };
  const smOffset = ['checkbox', 'radio', 'toggle'].includes(type)
    ? { smOffset: inputGridOffset }
    : '';
  const labelComponent =
    horizontal && label && !['checkbox', 'toggle'].includes(type) ? (
      <Col componentClass={Label} sm={2}>
        {label}
      </Col>
    ) : (
      <Label>{label}</Label>
    );
  let inputComponent;

  if (type === 'autoSuggest') {
    const selected =
      props.options.filter((el) => {
        if (multiple && Array.isArray(inputProps.value)) {
          return inputProps.value.includes(el.value);
        }

        return el.value === inputProps.value;
      }) || [];
    inputComponent = (
      <AutoSuggestInput
        clearButton
        id={name}
        selected={selected}
        placeholder={label}
        handleAutoSuggestInputChange={customOnChange}
        {...props}
        {...inputProps}
      />
    );
  } else if (type === 'toggle') {
    inputComponent = (
      <Toggle {...props} {...inputProps} onClick={customOnChange} />
    );
  } else if (type === 'date') {
    inputComponent = <DayPicker {...props} {...inputProps} />;
  } else {
    inputComponent = loading ? (
      <LoadingContainer>
        <InputSpinner>
          <Spinner />
        </InputSpinner>
        <Input {...props} {...inputProps} disabled onChange={customOnChange}>
          {children}
        </Input>
      </LoadingContainer>
    ) : (
      <Input {...props} {...inputProps} onChange={customOnChange}>
        {children}
      </Input>
    );

    inputComponent = horizontal ? (
      <Col {...smOffset} {...gridValue}>
        {inputComponent}
      </Col>
    ) : (
      inputComponent
    );
  }

  // For cases when we have "validate" prop related to other values which can changes
  // because library doesn't rerun validation by itself
  useEffect(() => {
    runValidation();
  }, [JSON.stringify(props.validate)]);

  return (
    <FormGroup
      style={{
        marginLeft:
          horizontal && ['checkbox', 'toggle'].includes(type)
            ? '5px'
            : undefined,
        marginBottom:
          horizontal && ['checkbox', 'toggle'].includes(type)
            ? '8px'
            : undefined,
      }}
      bsSize={size}
      controlId={props.id && name}
    >
      {!['checkbox', 'toggle', 'autoSuggest'].includes(type) && labelComponent}
      {inputComponent}
      {!!error && <HelpBlock style={{ color: 'red' }}>{error}</HelpBlock>}
      {help && <HelpBlock>{help}</HelpBlock>}
      {feedback && <FormControl.Feedback />}
    </FormGroup>
  );
}

ReactFormField.propTypes = {
  help: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'file',
    'date',
    'email',
    'radio',
    'select',
    'number',
    'toggle',
    'textarea',
    'password',
    'checkbox',
    'autoSuggest',
  ]),
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  multiple: PropTypes.bool,
  children: PropTypes.node,
  feedback: PropTypes.bool,
  onChange: PropTypes.func,
  inputGrid: PropTypes.number,
  horizontal: PropTypes.bool,
  setLoading: PropTypes.func,
  inputGridOffset: PropTypes.number,
  validatePristine: PropTypes.bool,
  defaultIsTouched: PropTypes.bool,
};

ReactFormField.defaultProps = {
  help: undefined,
  type: 'text',
  size: undefined,
  label: undefined,
  options: [],
  validate: () => false,
  multiple: undefined,
  children: undefined,
  feedback: undefined,
  onChange: undefined,
  inputGrid: undefined,
  horizontal: false,
  setLoading: undefined,
  inputGridOffset: undefined,
  validatePristine: true,
  defaultIsTouched: false,
};

export default ReactFormField;

const LoadingContainer = styled.div`
  position: relative;
`;

const InputSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
