import { t } from '@lingui/macro';
import {
  bankTemplateDataFormatter,
  simpleLedgerDataFormatter,
} from 'services/dataFormatters/accounts';

import {
  getSLReportSettings,
  getBankReportSettings,
  getGLReportSettings,
  getVATReportSettings,
  getAssetsReportSettings,
  getOverviewReportSettings,
  getPayrollReportSettings,
  getTrialBalanceReportSettings,
} from 'services/hooks/reports';

// eslint-disable-next-line max-len
import { vatOverviewReportDataFormatter } from 'services/hooks/vat/useVATOverviewData';
import { vatReportDataFormatter } from 'services/hooks/vat/useVATReportData';
import { vatOutgoingBalanceFormatter } from 'services/dataFormatters/vat';
import {
  a07Formatter,
  balanceReconciliationFormatter,
  outgoingBalanceFormatter,
  paymentReconciliationFormatter,
  reportedSalaryDetailsFormatter,
  reportedSalaryFormatter,
  vacationPayFormatter,
  withheldTaxFormatter,
} from 'services/dataFormatters/payroll';

import { generatePDF } from './generator/useReportGenerator';

const generateMultipleReport = async (
  accounts,
  response,
  options,
  filters,
  meta,
) => {
  const {
    vatData,
    company,
    username,
    companySID,
    companyUUID,
    getFiles,
    orgNumber,
    assetsData,
    payrollData,
    getVATFiles,
    getRowColor,
    trialBalanceData,
    getPayrollFiles,
    accountingYear,
    accountingPeriod,
    currentPeriodType,
    accountOverviewData,
    skipDownload,
  } = meta;

  const reports = [];
  const pages = [];

  response.forEach((account) => {
    const { accountId } = account.account;
    const accountData = accounts.find((a) => a.accountId === accountId);
    const { name, approved } = accountData;

    if (accountData.accountTemplateCode === 'SIMPLE_LEDGER') {
      const { simpleLedger } = account;
      const reportSettings = getSLReportSettings({
        accountingYear,
        accountingPeriod,
        currentPeriodType,
        accountName: name,
        account: accountId,
        tableLevelApproved: approved,
        accountInfo: account.account,
        rows: simpleLedgerDataFormatter(simpleLedger, []),
      });

      pages.push(reportSettings.data[0]);
    } else if (accountData.accountTemplateCode === 'GENERAL') {
      const { transactionLineDtos, accruals } = account;
      const reportSettings = getGLReportSettings({
        data: transactionLineDtos,
        accrualsData: accruals,
        currentPeriodType,
        accountingPeriod,
        accountingYear,
        accountInfo: account.account,
        currentAccount: {
          accountId,
          name,
        },
        reconcileFilters: filters.generalReconcile,
        accrualsFilters: filters.generalAccruals,
        tableLevelApproved: approved,
      });

      pages.push(reportSettings.data[0]);
    } else if (accountData.accountTemplateCode === 'BANK') {
      const { bankReconView } = account;

      let glTotal = 0;
      let bankTotal = 0;

      const setGlTotal = (value) => {
        glTotal = value;
      };

      const setBankTotal = (value) => {
        bankTotal = value;
      };

      const { dataGL, dataBank } = bankTemplateDataFormatter({
        data: bankReconView,
        setGlTotal,
        setBankTotal,
        accountingPeriod,
      });

      const reportSettings = getBankReportSettings({
        dataGL,
        glTotal,
        bankTotal,
        dataBank,
        accountingYear,
        accountInfo: account.account,
        bookedBalance: bankReconView?.bookedBalance,
        accountingPeriod,
        currentPeriodType,
        currentAccount: {
          accountId,
          name,
        },
        filters: filters.bank,
        tableLevelApproved: approved,
      });

      pages.push(reportSettings.data[0]);
    }
  });

  reports.push({
    company,
    username,
    orgNumber,
    currentPeriodType,
    folderName: t`accounts`,
    data: pages,
    landscape: true,
    getFiles,
    // eslint-disable-next-line max-len
    fileName: `${company} (${orgNumber}) - (${accountingPeriod}, ${accountingYear})`,
  });

  if (options.withOverview) {
    const accountOverviewReport = getOverviewReportSettings({
      rows: accountOverviewData,
      company,
      companyUUID,
      username,
      getRowColor,
      orgNumber,
      accountingYear,
      accountingPeriod,
      currentPeriodType,
    });

    reports.push(accountOverviewReport);
  }

  if (options.withTrialBalance) {
    const trialBalanceReport = getTrialBalanceReportSettings({
      data: trialBalanceData,
      company,
      companyUUID,
      username,
      orgNumber,
      accountingYear,
      frequency: filters.trialBalance,
    });

    reports.push(trialBalanceReport);
  }

  const term = Math.ceil(+accountingPeriod / 2);
  if (options.withVAT) {
    const [overview, report, outgoing, outgoingDeviation] = vatData;

    const outgoingBalanceData = vatOutgoingBalanceFormatter(
      outgoing,
      outgoingDeviation,
      term,
    );

    const vatReport = getVATReportSettings({
      getFiles: getVATFiles,
      activePeriods: accountingPeriod,
      accountingYear,
      company,
      orgNumber,

      vatOverviewData: {
        reportData: vatOverviewReportDataFormatter(overview, accountingPeriod),
      },
      vatReportData: {
        reportData: vatReportDataFormatter(report, accountingPeriod),
      },
      outgoingBalanceData: { rows: outgoingBalanceData.rows },
    });

    reports.push(vatReport);
  }

  if (options.withAssets) {
    const [register, overview] = assetsData;

    const assetsReport = getAssetsReportSettings({
      company,
      orgNumber,
      data: register,
      categories: overview,
      accountingPeriod,
      accountingYear,
      currentPeriodType,
    });

    reports.push(assetsReport);
  }

  if (options.withPayroll) {
    const [a07, prAndOb, salary, salaryDetails, ss, vpgl, vp, wt, approval] =
      payrollData;

    const a07Data = {
      rows: a07Formatter(a07),
    };

    const ssPaymentReconciliation = paymentReconciliationFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'SSTP',
    });
    const wtPaymentReconciliation = paymentReconciliationFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'WTP',
    });
    const ftPaymentReconciliation = paymentReconciliationFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'FTP',
    });

    const ssOutgoingBalance = outgoingBalanceFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'SSTP',
      glTitle: t`Due social security from GL:`,
      paymentTitle: t`Due social security from Payments:`,
    });
    const wtOutgoingBalance = outgoingBalanceFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'WTP',
      glTitle: t`Due withheld tax GL:`,
      paymentTitle: t`Due withheld tax from Payments:`,
    });

    const ftOutgoingBalance = outgoingBalanceFormatter({
      data: prAndOb,
      term,
      subTabEntry: 'FTP',
      glTitle: t`Due financial tax from GL:`,
      paymentTitle: t`Due financial tax from Payments:`,
    });

    const PRandOBdata = {
      rows: {
        ssPaymentReconciliation,
        wtPaymentReconciliation,
        ftPaymentReconciliation,
        ssOutgoingBalance,
        wtOutgoingBalance,
        ftOutgoingBalance,
      },
    };

    const withheldTaxData = {
      rows: withheldTaxFormatter(wt),
    };
    const vacationPayData = {
      rows: vacationPayFormatter(vpgl, vp),
    };
    const dataSocialSecurity = {
      rows: balanceReconciliationFormatter({
        data: ss?.[0] || {},
        bimonthly: true,
        accumulated: true,
        activePeriods: accountingPeriod,
      }),
    };
    const reportedSalaryData = {
      rows: reportedSalaryFormatter(salary, true, true, true),
      manual: salary?.manual,
    };
    const reportedSalaryDetailsData = {
      ...reportedSalaryDetailsFormatter({
        data: salaryDetails,
        bimonthly: true,
        accumulated: true,
        activePeriods: accountingPeriod,
      }),
    };

    const payrollReport = getPayrollReportSettings({
      company,
      orgNumber,
      getFiles: getPayrollFiles,
      bimonthly: true,
      accumulated: true,
      onlyNumbers: true,
      activePeriods: accountingPeriod,
      accountingYear,
      payrollApproved: approval?.status,

      a07Data,
      PRandOBdata,
      withheldTaxData,
      vacationPayData,
      dataSocialSecurity,
      reportedSalaryData,
      reportedSalaryDetailsData,
    });

    reports.push(payrollReport);
  }

  if (reports.length > 1) {
    await generatePDF({
      company,
      orgNumber,
      username,
      // eslint-disable-next-line max-len
      fileName: t`Report Package - ${company} (${orgNumber}), ${accountingPeriod}, ${accountingYear}`,
      isMultipleReport: true,
      metadataInfo: {
        accountingYear: Number.parseInt(accountingYear, 10),
        accountingPeriod: Number.parseInt(accountingPeriod, 10),
        reportType: 'REPORT_PACKAGE',
        companySID,
        companyUUID,
        saveReport: true,
      },
      multipleReports: reports,
      skipDownload,
    });
  } else {
    await generatePDF({
      data: reports[0],
      skipDownload,
      saveReport: true,
    });
  }
};

export default generateMultipleReport;
