import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export const columns = (readOnly) => [
  {
    width: 840,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
    disableSortBy: true,
    cell: {
      type: 'input',
      editable: (item) => item.id && !readOnly,
      inputType: 'text',
      placeholder: t`Add description for deviation here...`,
      disableClick: true,
    },
  },
  {
    width: 260,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'input',
      editable: (item) => item.id && !readOnly,
      inputType: 'number',
      disableClick: true,
    },
  },
  ...(!readOnly
    ? [
        {
          width: 80,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete this row`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
