// eslint-disable-next-line import/prefer-default-export
export function htmlTblCreater(
  type,
  headers,
  exportable,
  headerStyle,
  cellStyle,
  repeatHeader,
) {
  // Construct the html structure for the provided exportable
  let dataset = '<table style="border-collapse: collapse;" width="100%;">';

  // Check if the headers should be repeated
  if (repeatHeader === true) {
    dataset += '<thead>';
  }

  // Check if the provided header is an arry (backward-compatibility for version below 3.3.0 - more info at: https://github.com/gharibi/JsObjExporter/issues/4)
  let columnFlex = 0;
  if (typeof headers[0] !== 'object') {
    // Construct the table headers
    dataset += '<tr>';
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < headers.length; j++) {
      dataset += `<th style="${headerStyle}" >${headers[j]}</th>`;
    }
    dataset += '</tr>';

    // Check if the headers should be repeated
    if (repeatHeader === true) {
      dataset += '</thead>';
    }

    // Construct the body elements
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < exportable.length; j++) {
      dataset += `<tr style="${cellStyle}">`;
      // eslint-disable-next-line no-plusplus
      for (let k = 0; k < Object.keys(exportable[j]).length - 1; k++) {
        // Check if the input string is HTML, if so, do not add the cell tags
        const cellContents = exportable[j][Object.keys(exportable[j])[k]];
        let value = cellContents;
        let styles = '';

        if (typeof cellContents === 'object') {
          value = cellContents.value;
          styles = cellContents.styles;
        }

        if (/<[a-z][\s\S]*>/i.test(value) === true) {
          dataset += value;
        } else {
          // eslint-disable-next-line no-loop-func
          dataset += `<td style="${cellStyle}${styles}" ${(function csvWidth() {
            return type.toLowerCase() === 'csv'
              ? `width="${(headers[k].flex / columnFlex) * 100}%;"`
              : '';
          })()} >${value}</td>`;
        }
      }
      dataset += '</tr>';
    }
    dataset += '</table>';
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < headers.length; i++) {
      // Check if the header contains a flex, otherwise consider flex=1 to have equal sized columns
      columnFlex += (function containsFlex() {
        return 'flex' in headers[i] ? headers[i].flex : 1;
      })();
    }

    // Construct the table headers
    dataset += '<tr>';
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < headers.length; j++) {
      dataset += `<th style="${headerStyle}${headers[j].style}" width="${
        (headers[j].flex / columnFlex) * 100
      }%;" >${headers[j].alias}</th>`;
    }
    dataset += '</tr>';

    // Check if the headers should be repeated
    if (repeatHeader === true) {
      dataset += '</thead>';
    }

    // Construct the body elements
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < exportable.length; j++) {
      dataset += `<tr style="${cellStyle}">`;
      // Loop through the header items to show only the items associated with a header
      // eslint-disable-next-line no-plusplus
      for (let k = 0; k < headers.length; k++) {
        // Check if the input string is HTML, if so, do not add the cell tags
        const cellContents = exportable[j][headers[k].name];
        let value = cellContents;
        let styles = '';

        if (typeof cellContents === 'object') {
          value = cellContents.value;
          styles = cellContents.styles;
        }

        if (/<[a-z][\s\S]*>/i.test(value) === true) {
          dataset += value;
        } else {
          dataset += `<td style="${cellStyle}${styles}" ${(function csvWidth() {
            return type.toLowerCase() === 'csv'
              ? `width="${(headers[k].flex / columnFlex) * 100}%;"`
              : '';
          })()} >${value}</td>`;
        }
      }
      dataset += '</tr>';
    }
  }

  // Return the dataset
  return dataset;
}
