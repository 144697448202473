import React from 'react';

import PayrollReconciliationTable from 'containers/PayrollReconciliationTable';

/**
 * Payroll Reconciliation Page Component
 */
function PayrollReconciliationPage(props) {
  return <PayrollReconciliationTable {...props} />;
}

export default PayrollReconciliationPage;
