import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { Modal, ButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';

import TableHeader from 'containers/TableHeader';
import {
  Table,
  Button,
  Heading,
  Spinner,
  TableRow,
  TableCell,
  ButtonIcon,
} from 'components';

import { resourceCreateRequest } from 'store/actions';
import { bankReconcileCustomApi } from 'services/apihelpers';

import getErrorMessage from 'services/helpers/getErrorMessage';
import getQuery from './getQuery';
import columns from './helpers/previewShape';

function ReconciliationPreviewModal(props) {
  const {
    closeModal,
    tabs,
    companyId,
    accountId,
    predefinedData,
    reconcile,
    reconcileLoading,
  } = props;
  const dispatch = useDispatch();
  const { accountingYear, accountingPeriod } = useParams();

  const [data, setData] = useState({ original: undefined, current: undefined });
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      const query = {
        accountId,
        fromPeriod: 1,
        toPeriod: accountingPeriod,
        year: accountingYear,
      };
      const response = await dispatch(
        resourceCreateRequest(
          `${bankReconcileCustomApi}/${companyId}?${queryString.stringify(
            query,
          )}`,
          getQuery(tabs, companyId, accountId),
        ),
      );

      if (Array.isArray(response)) {
        setData({ original: response, current: response });
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
      setData({ original: undefined, current: undefined });
    } finally {
      setLoading(() => false);
    }
  };

  const handlePairRemove = (index) => {
    setData((prev) => ({
      ...prev,
      current: prev.current
        .slice(0, index)
        .concat(prev.current.slice(index + 1)),
    }));
  };

  const handleReset = () => {
    setData((prev) => ({ ...prev, current: prev.original }));
  };

  const renderGroup = (group, index) => {
    const glRows = group.filter((row) => row.type === 'GL');
    const bankRows = group.filter((row) => row.type === 'BANK');
    const showHeader = index === 0 && !predefinedData;

    return (
      <GridRow>
        <Table
          responsive
          head={showHeader && <TableHeader columns={columns} />}
        >
          {glRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell width={20}>{row.id}</TableCell>
              <TableCell width={50}>{row.description}</TableCell>
              <TableCell width={30} number className="text-right">
                {row.amount}
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <Table
          responsive
          head={showHeader && <TableHeader columns={columns} />}
        >
          {bankRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell width={20}>{row.id}</TableCell>
              <TableCell width={50}>{row.description}</TableCell>
              <TableCell width={30} number className="text-right">
                {row.amount}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </GridRow>
    );
  };

  useEffect(() => {
    if (Array.isArray(predefinedData)) {
      setData({ original: predefinedData, current: predefinedData });
    } else {
      fetchData();
    }
  }, [predefinedData]);

  return (
    <ModalStyles show onEscapeKeyDown={closeModal} bsSize="md">
      <Modal.Header>
        <Header>
          <Heading level={3}>
            <Trans>Reconciliation Preview</Trans>
          </Heading>
          {/* eslint-disable-next-line max-len */}
          {!!predefinedData && data.original?.length !== data.current?.length && (
            <Button kind="info" magnify onClick={handleReset}>
              <Trans>Reset</Trans>
            </Button>
          )}
        </Header>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <GridRow margin>
              <Heading alignment="center" level={3}>
                <Trans>GL</Trans>
              </Heading>
              <Heading alignment="center" level={3}>
                <Trans>Bank</Trans>
              </Heading>
            </GridRow>
            {Array.isArray(data.current) && data.current.length > 0 && (
              <>
                {!!predefinedData && (
                  <WithDeleteContainer>
                    <GridRow>
                      <Table
                        responsive
                        head={<TableHeader columns={columns} />}
                      />
                      <Table
                        responsive
                        head={<TableHeader columns={columns} />}
                      />
                    </GridRow>
                    <div />
                  </WithDeleteContainer>
                )}
                {data.current.map((group, index) =>
                  predefinedData ? (
                    <WithDeleteContainer key={group.id}>
                      <Group>{renderGroup(group, index)}</Group>
                      <RemoveIcon onClick={() => handlePairRemove(index)} />
                    </WithDeleteContainer>
                  ) : (
                    <Group key={group.id}>{renderGroup(group, index)}</Group>
                  ),
                )}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          {Array.isArray(predefinedData) && (
            <Button
              fill
              kind="success"
              magnify
              disabled={!data?.current?.length || reconcileLoading}
              onClick={() => reconcile(data.current)}
            >
              {reconcileLoading ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Reconcile</Trans>
                </>
              )}
            </Button>
          )}
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </ModalStyles>
  );
}

export default ReconciliationPreviewModal;

const WithDeleteContainer = styled.div`
  display: grid;
  grid-template-columns: 97% 2%;
  align-items: center;
  grid-gap: 10px;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: ${({ margin }) => margin && '10px'};

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Group = styled.div`
  width: 100%;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc5b9;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  table {
    margin-bottom: 0;
  }

  table tr td {
    border-top: none !important;
    vertical-align: baseline;
  }

  table tr th {
    border-bottom: 1px solid #ccc5b9;
    vertical-align: middle;
  }
`;

const ModalStyles = styled(Modal)`
  .modal-dialog {
    width: 1200px;

    @media (max-width: 1200px) {
      width: 80%;
    }
  }
`;

const RemoveIcon = styled(BiTrash)`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
