import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { BsPencilFill } from '@react-icons/all-files/bs/BsPencilFill';
import { FaRegTrashAlt } from '@react-icons/all-files/fa/FaRegTrashAlt';

import {
  Form,
  Modal,
  Tooltip,
  ButtonGroup,
  OverlayTrigger,
} from 'react-bootstrap';
import { Button, Card, Field, Heading, ButtonIcon } from 'components';

import userImage from 'assets/images/user.png';

import './style.css';

function CommentsModal({
  comment,
  company,
  comments,
  loading,
  showModal,
  toggleModal,
  user,
  handleChange,
  handleSubmit,
  deleteComment,
  editComment,
  showAllComments,
  commentModalType,
  roleType,
}) {
  const { accountingYear } = useParams();
  const isEmptyCommentChecker =
    (comments &&
      (isEmpty(comments.commentHistoryList) ? (
        <div>
          <h4 style={{ textAlign: 'center' }}>
            {t`Be first to initiate a dialogue`}
          </h4>
        </div>
      ) : (
        ''
      ))) ||
    '';
  /* eslint-enable */

  const period = company?.bimonthly
    ? Math.ceil(+company.currentWorkingPeriodEnd / 2)
    : company?.currentWorkingPeriodEnd;

  const editCommentsToolTip = <Tooltip id="tooltip">{t`Edit Comment`}</Tooltip>;
  const deleteCommentsToolTip = (
    <Tooltip id="tooltip">{t`Delete Comment`}</Tooltip>
  );
  return (
    <Modal
      show={showModal}
      bsSize="lg"
      onEscapeKeyDown={() => toggleModal(1, !isEmptyCommentChecker)}
    >
      <Modal.Header>
        <Heading level={3}>
          <Trans>
            Dialogue, Term - {period}, {accountingYear}
          </Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {isEmptyCommentChecker}
        <Card kind="chat" style={{ boxShadow: 'none' }}>
          <ul className="chat">
            {!isEmpty(comments)
              ? Object.keys(comments.commentHistoryList).map((item) => (
                  <li
                    key={`Cls-${comments.commentHistoryList[item].commentSID}`}
                    className="other"
                  >
                    <div className="avatar">
                      <img src={userImage} alt="crash" />
                    </div>
                    <div className="msg">
                      {comments.commentHistoryList[item].modifiedBy ||
                        comments.commentHistoryList[item].createdBy}{' '}
                      -{' '}
                      {comments.commentHistoryList[item].modifiedDate ||
                      comments.commentHistoryList[item].createdDate
                        ? i18n.date(
                            comments.commentHistoryList[item].modifiedDate ||
                              comments.commentHistoryList[item].createdDate,
                            {
                              year: 'numeric',
                              hour12: false,
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                            },
                          )
                        : ''}
                      <br />
                      {(commentModalType === 'accountLevelComment' ||
                        commentModalType === 'vatComment') &&
                        `${t`Period`}: ${
                          comments.commentHistoryList[item].periodTo
                        }`}
                      <hr />
                      <strong>
                        {`${comments.commentHistoryList[item].comment}`}
                      </strong>
                      <br />
                      <br />
                      {!roleType.isReadOnly &&
                        user.username ===
                          comments.commentHistoryList[item].createdBy && (
                          <ButtonGroup className="pull-right">
                            <OverlayTrigger
                              placement="top"
                              overlay={editCommentsToolTip}
                            >
                              <Button
                                id={1}
                                style={{ paddingRight: '0px' }}
                                onClick={() =>
                                  editComment(
                                    comments.commentHistoryList[item]
                                      .commentSID,
                                  )
                                }
                                size="lg"
                                simple
                                magnify
                              >
                                <ButtonIcon
                                  as={BsPencilFill}
                                  color="white"
                                  marginBottom={-2}
                                />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={deleteCommentsToolTip}
                            >
                              <Button
                                style={{
                                  paddingRight: '0px',
                                  paddingLeft: '20px',
                                }}
                                id={2}
                                onClick={() =>
                                  deleteComment(
                                    comments.commentHistoryList[item]
                                      .commentSID,
                                  )
                                }
                                size="lg"
                                simple
                                magnify
                              >
                                <ButtonIcon
                                  as={FaRegTrashAlt}
                                  marginBottom={-2}
                                  color="black"
                                />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        )}
                    </div>
                  </li>
                ))
              : ''}
          </ul>
        </Card>
        <Form onChange={handleChange}>
          <Field
            type="textarea"
            placeholder={t`Add Review:`}
            name="comment"
            value={comment}
            disabled={loading}
          />
        </Form>
        <br />
      </Modal.Body>
      <Modal.Footer>
        {['accountLevelComment', 'vatComment', 'payrollComment'].includes(
          commentModalType,
        ) && (
          <div className="pull-left">
            <Field
              type="checkbox"
              label={t`Show dialogue for all periods`}
              id="showAllComments"
              name="showAllComments"
              onChange={showAllComments}
            />
          </div>
        )}
        <Button
          fill
          kind="info"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || !comment}
        >
          <Trans>Submit Review</Trans>
        </Button>
        <Button
          fill
          kind="danger"
          magnify
          onClick={() => toggleModal(1, !isEmptyCommentChecker)}
        >
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CommentsModal.propTypes = {
  comment: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showAllComments: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  commentModalType: PropTypes.string.isRequired,
  roleType: PropTypes.shape({}).isRequired,
};

CommentsModal.defaultProps = {
  comment: '',
};

export default CommentsModal;
