import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';

import { Button, Spinner, ButtonIcon } from 'components';
import AutoSuggestInput from 'containers/AutoSuggestInput';

function TemplateSelectionList(props) {
  const {
    data,
    loading,
    disabledPrev,
    disabledNext,
    handleChange,
    selectedAccount,
    handleButtonClick,
  } = props;

  if (loading || isEmpty(data)) {
    return <Spinner />;
  }

  let options = [];

  if (!loading && !isEmpty(data)) {
    options = data.map((item) => ({
      id: item.accountId,
      label: `${item.accountId} - ${item.name || ''}`,
    }));
  }

  if (selectedAccount && !options.find((item) => item.id === selectedAccount)) {
    options.push({ id: selectedAccount, label: '-' });
  }

  return (
    <SelectionListWrapper>
      <Button
        fill
        icon
        kind="danger"
        onClick={() => handleButtonClick('prev')}
        disabled={disabledPrev}
      >
        <ButtonIcon as={BiArrowBack} color="white" marginRight={5} />
        <Trans>Prev</Trans>
      </Button>
      <InputWrapper>
        <AutoSuggestInput
          clearButton
          id="select-account"
          bsSize="small"
          options={options}
          selected={selectedAccount}
          placeholder={t`Please select an account`}
          handleAutoSuggestInputChange={handleChange}
        />
      </InputWrapper>
      <Button
        fill
        icon
        kind="danger"
        onClick={() => handleButtonClick('next')}
        disabled={disabledNext}
      >
        <Trans>Next</Trans>
        <ButtonIcon mirror as={BiArrowBack} color="white" marginLeft={5} />
      </Button>
    </SelectionListWrapper>
  );
}

TemplateSelectionList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  disabledPrev: PropTypes.bool,
  disabledNext: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  selectedAccount: PropTypes.string,
  handleButtonClick: PropTypes.func.isRequired,
};

TemplateSelectionList.defaultProps = {
  data: undefined,
  loading: true,
  disabledPrev: false,
  disabledNext: false,
  selectedAccount: '',
};

export default TemplateSelectionList;

const SelectionListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  width: calc(100% - 180px);
`;
