import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import {
  OverlayTrigger,
  Tooltip,
  FormGroup,
  FormControl,
  Col,
} from 'react-bootstrap';
import { Input, Label, DayPicker, HelpBlock } from 'components';
import styled from 'styled-components';

/**
 * Field component
 */
function Field(compProps) {
  const {
    name,
    size,
    type,
    help,
    error,
    label,
    tooltip,
    invalid,
    feedback,
    children,
    inputGrid,
    validation,
    horizontal,
    inputGridOffset,
    withCheckboxLabel,
    ...props
  } = compProps;
  const validationState = invalid && error ? 'error' : validation;
  const inputProps = { type, name, invalid, label, ...props };
  const gridValue = { md: inputGrid };
  const smOffset =
    type === 'checkbox' || type === 'radio'
      ? { smOffset: inputGridOffset }
      : '';

  const labelComponent =
    horizontal && label && type !== 'checkbox' ? (
      <Col componentClass={Label} sm={2}>
        {label}
      </Col>
    ) : (
      <Label style={{ marginRight: '10px' }}>
        {label}
        {!!tooltip && (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
            placement="bottom"
          >
            <InfoIcon />
          </OverlayTrigger>
        )}
      </Label>
    );
  let inputComponent;

  if (type === 'date') {
    inputComponent = <DayPicker {...inputProps} />;
  } else if (horizontal) {
    inputComponent = (
      <Col {...smOffset} {...gridValue}>
        <Input {...inputProps}>{children}</Input>
      </Col>
    );
  } else {
    inputComponent = <Input {...inputProps}>{children}</Input>;
  }
  const errorComponent = invalid && error && <HelpBlock>{error}</HelpBlock>;
  const helpComponent = help && <HelpBlock>{help}</HelpBlock>;
  const validationComponent = validation && feedback && (
    <FormControl.Feedback />
  );
  let showLabel = true;

  if (type === 'checkbox' && !withCheckboxLabel) {
    showLabel = false;
  }

  return (
    <FormGroup
      style={{ marginBottom: 0 }}
      bsSize={size}
      controlId={props.id || name}
      validationState={validationState}
    >
      {showLabel && label && labelComponent}
      {inputComponent}
      {errorComponent}
      {helpComponent}
      {validationComponent}
    </FormGroup>
  );
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  type: PropTypes.oneOf([
    'text',
    'file',
    'date',
    'email',
    'radio',
    'select',
    'number',
    'textarea',
    'password',
    'checkbox',
  ]),
  help: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  label: PropTypes.string,
  invalid: PropTypes.bool,
  feedback: PropTypes.bool,
  children: PropTypes.node,
  inputGrid: PropTypes.number,
  validation: PropTypes.oneOf(['success', 'warning', 'error', null]),
  horizontal: PropTypes.bool,
  inputGridOffset: PropTypes.number,
  withCheckboxLabel: PropTypes.bool,
};

Field.defaultProps = {
  size: undefined,
  type: 'text',
  help: undefined,
  error: undefined,
  label: undefined,
  invalid: undefined,
  feedback: undefined,
  children: undefined,
  inputGrid: undefined,
  validation: undefined,
  horizontal: false,
  inputGridOffset: undefined,
  withCheckboxLabel: false,
};

export default Field;

const InfoIcon = styled(FaInfoCircle)`
  margin-left: 5px;
  fill: #2c94b0;
`;
