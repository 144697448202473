import { t } from '@lingui/macro';

import { filterByPeriod } from 'services/shapes';

export const accountDetailsFilters = (collections) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'accountId',
      'description',
      'transactionId',
      'taxDescription',
      'accountDescription',
    ],
  },
  {
    id: 'accountId',
    type: 'multiSelect',
    label: t`Account ID`,
    placeholder: t`Please select Account ID`,
    ...collections.accountId,
  },
  {
    id: 'period',
    type: 'multiSelect',
    label: t`Period`,
    options: filterByPeriod(),
    disableSort: true,
    placeholder: t`Please select Period`,
  },
];

export default accountDetailsFilters;
