import client from '@sanity/client';

import { checkIfTestStagingEnv } from 'services/helpers';

export const getDataSet = () => {
  if (checkIfTestStagingEnv(window.location.href) === 'Localhost') {
    return 'local';
  }

  return 'production';
};

export default client({
  useCdn: true,
  dataset: getDataSet(),
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: '2021-08-05',
});
