import { t } from '@lingui/macro';

export const availableUserCompaniesFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['registrationNumber', 'companyName'],
  },
];

export const associatedUserGroupsFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['groupId', 'name'],
  },
];

export default { availableUserCompaniesFilters, associatedUserGroupsFilters };
