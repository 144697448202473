import React from 'react';
import { BasicTemplate } from 'components';
import RegistrationForm from 'containers/RegistrationForm';
/**
 * Registration Page component
 */

function RegistrationPage(props) {
  return (
    <BasicTemplate pageType="register-page" {...props}>
      <RegistrationForm {...props} />
    </BasicTemplate>
  );
}

export default RegistrationPage;
