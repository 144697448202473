import React, { useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  ButtonIcon,
  AddEditVATChildCompany,
  CustomTableWithAutoSettings,
} from 'components';

import {
  resourceDeleteRequest,
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { getVATJointList } from 'services/apihelpers';
import { vatChildCompaniesColumns } from 'services/tableshapes';
import getErrorMessage from 'services/helpers/getErrorMessage';

function VATChildCompaniesTable(props) {
  const { data, loading, isReadOnly, companiesOptions, companyId, refetch } =
    props;

  const [showModal, setShowModal] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const availableCompanies = useMemo(() => {
    if (Array.isArray(data)) {
      const unavailableCompanies = data.map((item) => item.companySid);

      return companiesOptions.filter(
        (item) =>
          !unavailableCompanies.includes(item.id) && item.id !== companyId,
      );
    }

    return companiesOptions;
  }, [data, companiesOptions]);

  const handleAction = async (action, item) => {
    try {
      switch (action) {
        case 'addChildCompany': {
          setShowModal({ id: 'addChildCompany' });
          break;
        }

        case 'editRange': {
          setShowModal({ id: 'addChildCompany', item });
          break;
        }

        case 'delete': {
          await dispatch(
            resourceDeleteRequest(`${getVATJointList}?id=${item.id}`),
          );
          await dispatch(
            resourceListReadSuccess(
              getVATJointList,
              data.filter((row) => row.id !== item.id),
            ),
          );
          break;
        }

        default: {
          break;
        }
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const closeModal = () => {
    setShowModal({});
  };

  const onSubmit = async (childCompany, dateRanges) => {
    setSubmitting(() => true);

    try {
      if (showModal?.item) {
        await dispatch(
          resourceUpdateRequest(getVATJointList, null, {
            id: showModal.item.id,
            dateRanges,
          }),
        );

        const collection = data.map((item) => {
          if (item.id === showModal.item.id) {
            return {
              ...item,
              dateRanges,
            };
          }

          return item;
        });

        await dispatch(resourceListReadSuccess(getVATJointList, collection));
      } else {
        await dispatch(
          resourceCreateRequest(getVATJointList, {
            dateRanges,
            parentCompanySid: companyId,
            childCompanySid: childCompany,
          }),
        );

        refetch();
      }
      closeModal();
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    setSubmitting(() => false);
  };

  return (
    <>
      {showModal?.id && (
        <AddEditVATChildCompany
          {...{
            onSubmit,
            closeModal,
            submitting,
            editMode: !!showModal?.item,
            companies: availableCompanies,
            currentItem: showModal?.item,
            dates: showModal?.item?.dateRanges || [],
          }}
        />
      )}
      <Card id="pdfToPrint" data-testid="pdfToPrint-VATChildCompanies">
        <CardPDFTitle title={t`Joint Registered Companies`} />
        <div className="container-fluid" style={{ padding: 0 }}>
          <CustomTableWithAutoSettings
            {...{
              data,
              loading,
              handleAction,
              minHeight: (data?.length || 0) * 35,
              columns: vatChildCompaniesColumns(),
              tableId: 'VATChildCompanies',
              loadingId: 'vatCode',
              dynamicRowHeight: true,
              headerConfigs: {
                exportFileName: t`VAT Joint Registered Companies`,
                extraButtons: !isReadOnly && [
                  {
                    id: 'addChildCompany',
                    title: t`Add Joint Registered Companie`,
                    icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                    buttonProps: {
                      size: 'sm',
                      kind: 'default',
                      fill: false,
                      magnify: true,
                      disabled: !availableCompanies.length,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </Card>
    </>
  );
}

export default VATChildCompaniesTable;
