import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { calculateRowHeight } from 'services/helpers';
import { payrollWithheldTaxColumns } from 'services/tableshapes';

function PayrollWithheldTax(props) {
  const { roleType, handleAction, withheldTaxData, actionSubmitting } = props;
  const { rows, loading } = withheldTaxData;

  const screenWidth = useCurrentWidth();
  const minHeight = useMemo(
    () => calculateRowHeight(screenWidth),
    [screenWidth],
  );

  if (roleType.isReadOnly) return null;

  return (
    <CustomTableWithAutoSettings
      {...{
        data: rows,
        loading,
        minHeight: rows.length * minHeight || minHeight,
        hideFilters: true,
        handleAction,
        actionSubmitting,
        columns: payrollWithheldTaxColumns(),
        loadingId: 'id',
        tableId: 'payrollWithheldTax',
        headerConfigs: {
          exportFileName: t`Payroll Withheld Tax`,
        },
      }}
    />
  );
}

export default PayrollWithheldTax;
