import React, { Component, createRef } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { FaSortUp } from '@react-icons/all-files/fa/FaSortUp';
import { FaSortDown } from '@react-icons/all-files/fa/FaSortDown';

import { TableRow, TableCell } from 'components';

class TableHeader extends Component {
  static propTypes = {
    /** @type {func} toRoute */
    toRoute: PropTypes.func,
    /** @type {shape} search */
    search: PropTypes.shape({
      /** @type {string} sortByField */
      sortByField: PropTypes.string,
      /** @type {string} sortBy */
      sortBy: PropTypes.string,
    }),
    /** @type {shape} history */
    history: PropTypes.shape({}),
    /** @type {shape} columns */
    columns: PropTypes.func.isRequired,
    /** @type {string} tableName */
    tableName: PropTypes.string,
  };

  static defaultProps = {
    tableName: '',
    toRoute: undefined,
    history: undefined,
    search: undefined,
  };

  state = {
    pin: false,
  };

  headerRef = createRef();

  pinnedHeaderRef = createRef();

  componentDidMount() {
    this.scrollingContent = document.getElementById('scrollingContent');
    this.navbar = document.getElementById('navbar');

    if (this.scrollingContent) {
      // eslint-disable-next-line max-len
      this.scrollingTable =
        this.headerRef.current.parentNode.parentNode.parentNode.parentNode;
      this.scrollingTable.addEventListener('scroll', this.handleTableScroll);
      this.scrollingContent.addEventListener('scroll', this.handleScroll);
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    if (this.scrollingContent) {
      this.scrollingTable.removeEventListener('scroll', this.handleTableScroll);
      this.scrollingContent.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleResize = () => {
    // this will automatically update width for the pinned header
    if (this.state.pin) {
      this.forceUpdate();
    }
  };

  handleTableScroll = (e) => {
    if (this.state.pin) {
      this.pinnedHeaderRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  handleScroll = () => {
    const scrollToTopFromElement =
      this.headerRef.current.getBoundingClientRect().top -
      this.navbar.offsetHeight;

    const pin = scrollToTopFromElement <= 0;

    if (pin !== this.state.pin) {
      this.setState(() => ({ pin }));
    }
  };

  handleFieldSorting = (sortByField) => {
    const { history, toRoute, search, tableName, id } = this.props;
    const sortBy = search[`sortBy${tableName}`] === 'Asc' ? 'Desc' : 'Asc';
    const newSearch = {
      ...search,
      [`sortByField${tableName}`]: sortByField,
      [`sortBy${tableName}`]: sortBy,
    };

    history.push(toRoute(newSearch, id));
  };

  renderCell = (rowHead, index, pinned) => {
    const { search, tableName } = this.props;
    let pinnedStyles = {};
    const sortIcon =
      search?.[`sortBy${tableName}`]?.toLowerCase() === 'asc' ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      );

    if (pinned) {
      const child = this.headerRef.current.children[index];

      if (child) {
        const width = child.offsetWidth;

        pinnedStyles = { width: `${width}px`, minWidth: `${width}px` };
      }
    }

    return (
      <TableCell
        heading
        key={`tableHead-${tableName}-${rowHead.id}-${rowHead.value}`}
        onClick={
          rowHead.sortable ? () => this.handleFieldSorting(rowHead.value) : null
        }
        style={pinned ? pinnedStyles : {}}
        strong={rowHead.strong}
        sortable={rowHead.sortable}
        className={rowHead.className}
      >
        {rowHead.sortable ? (
          <>
            {rowHead.value === search[`sortByField${tableName}`] ? (
              <strong key="strong-item">{rowHead.label}</strong>
            ) : (
              rowHead.label
            )}
            <SortWrapper
              key={`sort-icon-${search[`sortBy${tableName}`]}-${
                search[`sortByField${tableName}`]
              }`}
            >
              {rowHead.value === search[`sortByField${tableName}`] ? (
                sortIcon
              ) : (
                <FaSortUp />
              )}
            </SortWrapper>
          </>
        ) : (
          rowHead.label
        )}
      </TableCell>
    );
  };

  getContent = (pinned) => {
    const { columns, search } = this.props;
    let pinnedStyles = {};

    if (pinned) {
      pinnedStyles = {
        top: `${this.navbar.offsetHeight}px`,
        // eslint-disable-next-line max-len
        width: `${this.headerRef.current.parentNode.parentNode.parentNode.offsetWidth}px`,
      };
    }

    return (
      <TableRow
        heading
        style={pinned ? pinnedStyles : {}}
        innerRef={pinned ? this.pinnedHeaderRef : this.headerRef}
        className={cx({ pinned })}
      >
        {columns(search)
          .filter((item) => item.enabled)
          .map((row, index) => this.renderCell(row, index, pinned))}
      </TableRow>
    );
  };

  /* eslint-disable react/no-arrow-function-lifecycle */ // FIXME
  render = () => (
    <>
      {this.getContent()}
      {this.state.pin && this.getContent(true)}
    </>
  );
}
/* eslint-enable */

export default TableHeader;

const SortWrapper = styled.span`
  padding-left: 10px;
  font-size: 12px;
`;
