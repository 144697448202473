const getQuery = (tabs, accountId, orderId) => ({
  accountId,
  description: '',
  orderId,
  configParts: tabs.map((item) => {
    const newItem = { ...item };

    if (newItem.leftSelectedType === 'TEXT') {
      newItem.regexLeft = null;
    } else if (newItem.leftSelectedType === 'REGEX') {
      newItem.textMatchLeft = null;
    } else {
      newItem.regexLeft = null;
      newItem.textMatchLeft = null;
    }

    if (newItem.rightSelectedType === 'TEXT') {
      newItem.regexRight = null;
    } else if (newItem.rightSelectedType === 'REGEX') {
      newItem.textMatchRight = null;
    } else {
      newItem.regexRight = null;
      newItem.textMatchRight = null;
    }

    return newItem;
  }),
});

export default getQuery;
