import { t } from '@lingui/macro';

/**
 * Report Info Grid
 */
export const columns = (hideXML) => [
  {
    id: 1,
    label: t`Report Period`,
    value: 'reportPeriod',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 2,
    label: t`Sender`,
    value: 'sender',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 3,
    label: t`ID`,
    value: 'id',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 4,
    label: t`Delivery Date`,
    value: 'deliveryDate',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  !hideXML && {
    id: 5,
    label: t`XML`,
    value: 'xml',
    sortable: false,
    className: 'text-center',
    enabled: true,
  },
];

export default { columns };
