import React, { useEffect, useState } from 'react';
import { pending, rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { CopyCompanySettingsModal } from 'components';

import { fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  copyAccountMappingApi,
  getActiveCompanyByOrganizationApi,
} from 'services/apihelpers';

function CopyCompanySettingsModalContainer(props) {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [selectedCompanyToCopy, setSelectedCompanyToCopy] = useState([]);
  const [selectedCompaniesToChange, setSelectedCompaniesToChange] = useState(
    [],
  );
  const companies = useSelector(
    (state) =>
      fromResource.getList(state, getActiveCompanyByOrganizationApi)
        .validCompanies,
  );
  const failed = useSelector((state) =>
    rejected(state, `${getActiveCompanyByOrganizationApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getActiveCompanyByOrganizationApi}ListRead`),
  );

  const onSubmit = async () => {
    if (step === 0) {
      setStep(1);
      return;
    }

    await dispatch(
      resourceCreateRequest(copyAccountMappingApi, {
        fromCompanyId: selectedCompanyToCopy[0].id,
        toCompanyIds: selectedCompaniesToChange.map((c) => c.id),
      }),
    );

    closeModal(false, true);
  };
  const handleChange = (values, type) => {
    if (type === 'companyToCopy') {
      setSelectedCompanyToCopy(values);
    } else if (type === 'companiesToChange') {
      setSelectedCompaniesToChange(values);
    }
  };
  const fetchData = () => {
    dispatch(resourceListReadRequest(getActiveCompanyByOrganizationApi));
  };

  useEffect(fetchData, []);

  return (
    <CopyCompanySettingsModal
      {...{
        step,
        failed,
        loading,
        companies,
        closeModal,
        selectedCompanyToCopy,
        selectedCompaniesToChange,
        onSubmit,
        handleChange,
      }}
    />
  );
}

export default CopyCompanySettingsModalContainer;
