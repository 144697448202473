import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { ButtonGroup } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  CustomTableWithAutoSettings,
} from 'components';

import {
  vacationPaySSColumns,
  vacationPayBasisColumns,
  vacationPayBasisCalculationColumns,
} from 'services/tableshapes';
import { useCurrentWidth } from 'services/hooks';
import { calculateRowHeight } from 'services/helpers';

function PayrollReconciliationVacationPayTable(props) {
  const {
    rows,
    params,
    glBaseLoading,
    calculationLoading,
    zonesLoading,
    roleType,
    toggleModal,
    handleAction,
    activePeriods,
    payrollApproved,
    actionSubmitting,
    handleDeviationAction,
    deviationRowSubmitting,
  } = props;
  const { firstView, secondView, thirdView } = rows;

  const { accountingYear: year } = params;

  const screenWidth = useCurrentWidth();
  const minHeight = useMemo(
    () => calculateRowHeight(screenWidth),
    [screenWidth],
  );

  const vacationPayContent = (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Basis Calculation</Trans>
      </Heading>
      <TableHolder>
        <CustomTableWithAutoSettings
          {...{
            data: secondView,
            loading: calculationLoading || zonesLoading,
            minHeight: secondView.length * minHeight,
            handleAction,
            hideFilters: true,
            actionSubmitting,
            columns: vacationPayBasisCalculationColumns({
              readOnly: roleType?.isReadOnly,
              approved: payrollApproved,
            }),
            tableId: 'payrollVPContent',
            loadingId: 'id',
            headerConfigs: {
              exportFileName: t`Payroll Vacation Pay - ${year}, ${activePeriods}`, // eslint-disable-line max-len
            },
          }}
        />
      </TableHolder>
    </>
  );

  const vacationPaySSContent = (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Vacation Pay - Social Security</Trans>
      </Heading>
      <TableHolder>
        <CustomTableWithAutoSettings
          {...{
            data: thirdView,
            loading: zonesLoading,
            handleAction,
            minHeight: thirdView.length * minHeight,
            hideFilters: true,
            columns: vacationPaySSColumns(
              roleType?.isReadOnly ?? false,
              payrollApproved,
            ),
            tableId: 'payrollVPSocialSecurity',
            headerConfigs: {
              // eslint-disable-next-line max-len
              exportFileName: t`Payroll Vacation Pay(Social Security) - ${year}, ${activePeriods}`,
            },
          }}
        />
      </TableHolder>
    </>
  );

  const vacationPayAccountsContent = (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Vacation Pay from General Ledger</Trans>
      </Heading>
      <TableHolder>
        <CustomTableWithAutoSettings
          {...{
            data: firstView,
            loading: glBaseLoading,
            handleAction,
            minHeight:
              firstView.length * minHeight > 300
                ? 300
                : firstView.length * minHeight,
            hideFilters: true,
            totalName: t`SUM Vacation pay basis:`,
            columns: vacationPayBasisColumns(),
            tableId: 'payrollVPAccountsContent',
            totalTitleClassName: 'flex-right',
            headerConfigs: {
              // eslint-disable-next-line max-len
              exportFileName: t`Payroll Vacation Pay(Accounts) - ${year}, ${activePeriods}`,
            },
          }}
        />
      </TableHolder>
    </>
  );

  return (
    <>
      {!roleType?.isReadOnly && (
        <ButtonHolder>
          <ButtonGroup bsSize="small">
            <Button
              disabled={payrollApproved}
              onClick={() => handleDeviationAction('addRegular')}
            >
              {deviationRowSubmitting ? (
                <Spinner size="md" type="white" />
              ) : (
                <Trans>Add deviation row</Trans>
              )}
            </Button>
            <Button
              disabled={payrollApproved}
              onClick={() => handleDeviationAction('addList')}
            >
              {deviationRowSubmitting ? (
                <Spinner size="md" type="white" />
              ) : (
                <Trans>Add deviation row to list</Trans>
              )}
            </Button>
            <Button
              disabled={payrollApproved}
              onClick={() => handleDeviationAction('addZone')}
            >
              {deviationRowSubmitting ? (
                <Spinner size="md" type="white" />
              ) : (
                <Trans>Add deviation row to zones</Trans>
              )}
            </Button>
            <Button
              disabled={
                payrollApproved ||
                glBaseLoading ||
                zonesLoading ||
                calculationLoading
              }
              onClick={() => toggleModal('pay_rows')}
            >
              {glBaseLoading || zonesLoading || calculationLoading ? (
                <Spinner size="md" />
              ) : (
                <Trans>Update main percentage and zone</Trans>
              )}
            </Button>
          </ButtonGroup>
        </ButtonHolder>
      )}
      {vacationPayAccountsContent}
      {vacationPayContent}
      {vacationPaySSContent}
    </>
  );
}

export default PayrollReconciliationVacationPayTable;

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TableHolder = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
