import _isEmpty from 'lodash-es/isEmpty';
import { t } from '@lingui/macro';

export const vacationPayFormatter = (vacationPayGLBase, vacationPay) => {
  const firstView = [];
  const secondView = [];
  const thirdView = [];

  if (Array.isArray(vacationPayGLBase?.data)) {
    firstView.push(...vacationPayGLBase.data);
  }

  if (Array.isArray(vacationPay?.calculation?.vpElements?.standard)) {
    secondView.push(
      ...vacationPay.calculation.vpElements.standard.map((item) => ({
        ...item,
        hideActions: item.vpBaseAmount === 0 || !item.isEditable,
      })),
    );
  }
  if (vacationPay?.calculation?.vpElements?.sumUnderSixty) {
    secondView.push({
      ...vacationPay.calculation.vpElements.sumUnderSixty,
      subtitle: {
        exported: true,
      },
    });
  }
  if (Array.isArray(vacationPay?.calculation?.vpElements?.overSixty)) {
    secondView.push(
      ...vacationPay.calculation.vpElements.overSixty.map((item) => ({
        ...item,
        hideActions: item.vpBaseAmount === 0 || !item.isEditable,
      })),
    );
  }
  if (Array.isArray(vacationPay?.calculation?.vpElements?.lastYear)) {
    secondView.push(
      ...vacationPay.calculation.vpElements.lastYear.map((item) => ({
        ...item,
        hideActions: !item.isEditable,
      })),
    );
  }

  if (Array.isArray(vacationPay?.calculation?.totalRows)) {
    const items = [...vacationPay.calculation.totalRows];
    const lastItem = items.pop();

    secondView.push(
      ...items.map((item) => ({
        amount: item.amount,
        vpBaseAmount: item.description,
        hideActions: true,
        subtitle: {
          exported: true,
        },
      })),
    );

    if (Array.isArray(vacationPay?.calculation?.deviations)) {
      secondView.push(
        ...vacationPay.calculation.deviations.map((item) => ({
          id: item.id,
          customDeviation: true,
          vpBaseAmount: item.description,
          amount: item.amount,
          placeholder: {
            vpBaseAmount: t`Add description for deviation here...`,
          },
          inputType: {
            vpBaseAmount: 'text',
          },
        })),
      );

      if (vacationPay.calculation.deviations.length && lastItem.amount) {
        secondView.push({
          amount: lastItem.amount,
          vpBaseAmount: lastItem.description,
          hideActions: true,
          subtitle: {
            exported: true,
          },
        });
      }
    }
  }

  if (vacationPay?.calculation?.feriepengeListe) {
    secondView.push({ hideActions: true });
    secondView.push({
      id: vacationPay.calculation.feriepengeListe.id,
      vpBaseAmount: vacationPay.calculation.feriepengeListe.vpElement,
      amount: vacationPay.calculation.feriepengeListe.amount,
      readOnly: true,
      vacationPayList: true,
      subtitle: { exported: true },
    });
  }

  if (Array.isArray(vacationPay?.calculation?.totalRowsFeriepengeListe)) {
    const items = [...vacationPay.calculation.totalRowsFeriepengeListe];
    const lastItem = items.pop();

    secondView.push(
      ...items.map((item) => ({
        amount: item.amount,
        vpBaseAmount: item.description,
        hideActions: true,
        subtitle: {
          exported: true,
        },
      })),
    );

    if (Array.isArray(vacationPay?.calculation?.deviationsFeriepengeListe)) {
      secondView.push(
        ...vacationPay.calculation.deviationsFeriepengeListe.map((item) => ({
          id: item.id,
          customDeviation: true,
          vpBaseAmount: item.description,
          amount: item.amount,
          placeholder: {
            vpBaseAmount: t`Add description for deviation here...`,
          },
          inputType: {
            vpBaseAmount: 'text',
          },
        })),
      );

      if (
        vacationPay.calculation.deviationsFeriepengeListe.length &&
        lastItem.amount
      ) {
        secondView.push({
          amount: lastItem.amount,
          vpBaseAmount: lastItem.description,
          hideActions: true,
          subtitle: {
            exported: true,
          },
        });
      }
    }
  }

  if (!_isEmpty(vacationPay.zones)) {
    thirdView.push({
      ...vacationPay.zones.mainZone,
      rate: vacationPay.zones.mainZone.rate * 100,
      isEditable: false,
      hideActions: true,
    });
    if (vacationPay.zones.otherZones) {
      thirdView.push(
        ...vacationPay.zones.otherZones.map((item) => ({
          ...item,
          rate: item.rate * 100,
          isEditable: true,
          hideActions: item.baseSocialSecurity === 0,
        })),
      );
    }
    if (vacationPay.zones.zoneSumBase && vacationPay.zones.zoneSumCalculated) {
      thirdView.push({
        zone: t`Sum`,
        rate: '',
        baseSocialSecurity: vacationPay.zones.zoneSumBase,
        calculatedSocialSecurity: vacationPay.zones.zoneSumCalculated,
        subtitle: { exported: true },
      });
    }
    if (vacationPay.zones.allocatedVacationPaySocialSecurity) {
      thirdView.push({
        zone: '',
        rate: '',
        baseSocialSecurity: t`Allocated Vacation Pay Social Security`,
        calculatedSocialSecurity:
          vacationPay.zones.allocatedVacationPaySocialSecurity,
        subtitle: { exported: true },
      });
    }
    if (vacationPay.zones.difference) {
      thirdView.push({
        zone: '',
        rate: '',
        baseSocialSecurity: t`Explained Difference SS`,
        calculatedSocialSecurity: vacationPay.zones.difference,
        subtitle: { exported: true },
      });
    }
    if (Array.isArray(vacationPay.zones.deviations)) {
      thirdView.push(
        ...vacationPay.zones.deviations.map((item) => ({
          id: item.id,
          zone: item.description,
          rate: item.percentage * 100,
          baseSocialSecurity: item.amount,
          calculatedSocialSecurity: item.calculatedAmount,
          customDeviation: true,
        })),
      );
    }
    if (vacationPay.zones.unexplainedDifference) {
      thirdView.push({
        zone: '',
        rate: '',
        baseSocialSecurity: t`Unexplained Difference`,
        calculatedSocialSecurity: vacationPay.zones.unexplainedDifference,
        subtitle: { exported: true },
      });
    }
  }

  return { firstView, secondView, thirdView };
};

export default vacationPayFormatter;
