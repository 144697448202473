import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AccrualsOriginModal } from 'components';

import { setAccrualsOriginApi } from 'services/apihelpers';
import { resourceUpdateRequest } from 'store/actions';

function AccrualsOriginModalContainer(props) {
  const {
    optionsList,
    toggleModal,
    reconciliationId,
    partialReconciliationId,
    selectedOptions: _selectedOptions,
  } = props;
  const dispatch = useDispatch();
  const submitting = useSelector((state) =>
    pending(state, `${setAccrualsOriginApi}Update`),
  );
  const [selectedOptions, setSelectedOptions] = useState(_selectedOptions);

  const handleSubmit = async () => {
    await dispatch(
      resourceUpdateRequest(setAccrualsOriginApi, null, {
        recordIds: selectedOptions,
        partialReconciliationId,
        reconciliationId,
      }),
    );
    toggleModal(false, true);
  };
  const handleRowChange = (event) => {
    const { target } = event;
    const { id } = target;
    let newState = [...selectedOptions];

    if (target.checked) {
      newState.push(id);
    } else {
      newState = newState.filter((el) => el !== id);
    }
    setSelectedOptions(newState);
  };

  return (
    <AccrualsOriginModal
      {...{
        submitting,
        toggleModal,
        optionsList,
        selectedOptions,
        reconciliationId,
        partialReconciliationId,
        handleSubmit,
        handleRowChange,
      }}
    />
  );
}

AccrualsOriginModalContainer.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.shape({})),
  toggleModal: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
};

AccrualsOriginModalContainer.defaultProps = {
  optionsList: [],
  selectedOptions: [],
};

export default AccrualsOriginModalContainer;
