import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { GroupCompanyTable } from 'components';
import AddEditGroupCompanyModal from 'containers/AddEditGroupCompanyModal';

import { fromResource } from 'store/selectors';
import { resourceListReadSuccess } from 'store/resource/actions';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  toGroupUserDetailed,
  toGroupCompanyDetailed,
} from 'services/routehelpers';
import {
  companyGroupSecretApi,
  deleteGroupCompanyApi,
  getGroupCompanyListApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function GroupCompanyContainer({ roleType }) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const data = useSelector((state) =>
    fromResource.getList(state, getGroupCompanyListApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupCompanyListApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(resourceListReadRequest(getGroupCompanyListApi));
  };
  const getSearch = () => {
    const search = location
      ? queryString.parse(location.search, { parseBooleans: true })
      : {};

    return {
      tabEntry: search.tabEntry || 'company',
      reportMode: search.reportMode || false,
    };
  };
  const search = getSearch();
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const handleDeleteGroup = async (item) => {
    try {
      _setActionSubmitting(item.groupId, true);

      await dispatch(
        resourceDeleteRequest(
          `${deleteGroupCompanyApi}?${queryString.stringify({
            groupId: item.groupId,
          })}`,
        ),
      );

      const collection = data.filter((el) => el.groupId !== item.groupId);

      dispatch(resourceListReadSuccess(getGroupCompanyListApi, collection));

      _setActionSubmitting(item.groupId, false);
    } catch (error) {
      _setActionSubmitting(item.groupId, false);
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const handleDetailedRedirect = (id, type) => {
    if (type === 'user') {
      history.push(toGroupUserDetailed({}, id));
    } else {
      history.push(toGroupCompanyDetailed({}, id));
    }
  };

  const changeSecretStatus = async (company) => {
    const { groupId, secret } = company;

    _setActionSubmitting(groupId, true);

    try {
      if (secret) {
        await dispatch(
          resourceDeleteRequest(
            `${companyGroupSecretApi}?${queryString.stringify({
              groupId,
            })}`,
          ),
        );
      } else {
        await dispatch(
          resourceCreateRequest(companyGroupSecretApi, { id: groupId }),
        );
      }

      const newData = data.map((item) =>
        item.groupId === groupId ? { ...item, secret: !secret } : item,
      );
      await dispatch(resourceListReadSuccess(getGroupCompanyListApi, newData));

      toast.success(t`Secret field updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      _setActionSubmitting(groupId, false);
    }
  };

  const handleAction = async (action, props, column) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'add': {
          setModalProps({ type: 'add' });
          break;
        }

        case 'edit': {
          setModalProps({ type: 'edit', item: props });
          break;
        }

        case 'delete': {
          await handleDeleteGroup(props);
          break;
        }

        case 'cellClick': {
          if (column === 'secret') {
            await changeSecretStatus(props);
          } else {
            handleDetailedRedirect(props.groupId, 'company');
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };
  const renderModal = () => {
    let content;

    switch (modalProps.type) {
      case 'add':
      case 'edit': {
        content = (
          <AddEditGroupCompanyModal
            item={modalProps.item}
            roleType={roleType}
            closeModal={closeModal}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  useEffect(fetchData, [JSON.stringify(params)]);

  return (
    <>
      {renderModal()}
      <GroupCompanyTable
        {...{
          data,
          search,
          loading,
          roleType,
          actionSubmitting,
          title: t`Company Group`,
          handleAction,
        }}
      />
    </>
  );
}

export default GroupCompanyContainer;
