import { t } from '@lingui/macro';

/**
 * Opening Balance Registration Table Grid
 */
export const columns = (search) => [
  {
    id: 1,
    label: t`Mapped Transaction ID`,
    value: 'transactionId',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 2,
    label: t`Account`,
    value: 'accountID',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 3,
    label: t`Mapped Transaction Amount`,
    value: 'openingBalance',
    sortable: false,
    className: 'text-right',
    enabled: search.openingBalance || search.showAll,
  },
  {
    id: 4,
    label: t`Acumulated Transaction Amount`,
    value: 'openingBalance',
    sortable: false,
    className: 'text-right',
    enabled: search.openingBalance || search.showAll,
  },
  {
    id: 5,
    label: t`Description`,
    value: 'description',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 6,
    label: t`amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 7,
    label: t`actions`,
    value: 'actions',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
