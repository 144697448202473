import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { HiOutlineRefresh } from '@react-icons/all-files/hi/HiOutlineRefresh';

import { ButtonIcon, CustomTableWithAutoSettings } from 'components';

import { organizationAltinnSetupColumns } from 'services/tableshapes';
import { organizationAltinnPayrollFilter } from 'services/tableFilterShapes';

export function OrganizationVATAltinnSetup(props) {
  const {
    data,
    search,
    loading,
    handleAction,
    actionSubmitting,
    selectCondition,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'companyName',
        desc: false,
      },
    ],
    [],
  );

  return (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        columns: organizationAltinnSetupColumns(search, 'vat'),
        tableId: 'vatAltinnId',
        loadingId: 'companySID',
        headerConfigs: {
          exportFileName: `${t`Altinn Settings`} - ${t`VAT`}`,
        },
        handleAction,
        filterConfigs: organizationAltinnPayrollFilter(),
        reportMode: search.reportMode,
        selectConfigs: {
          selectCondition,
          buttons: [
            {
              id: 'update',
              icon: <ButtonIcon as={HiOutlineRefresh} type="extraButton" />,
              title: t`Fetch VAT Reports`,
              buttonProps: {
                size: 'sm',
                kind: 'info',
                fill: true,
                magnify: true,
              },
            },
          ],
        },
        actionSubmitting,
        initialState: {
          sortBy,
        },
      }}
    />
  );
}

export default OrganizationVATAltinnSetup;
