import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardMyProgressNew, Spinner, Heading } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { fromResource } from 'store/selectors';
import {
  getDashboardStatistics,
  getDashboardCurrentCompany,
  isValidIntegration,
} from 'services/apihelpers';
import {
  toVATReconciliation,
  toAccountReconciliation,
  toPayrollReconciliation,
} from 'services/routehelpers';

function DashboardMyProgressNewContainer(props) {
  const params = useParams();
  const history = useHistory();
  const { accountingYear, accountingPeriod, companyId } = params;

  const [{ loading, error }, setStatus] = useState({
    loading: false,
    error: null,
  });
  const [{ chartLoading, chartError }, setChartStatus] = useState({
    chartLoading: false,
    chartError: null,
  });

  const data = useSelector((state) =>
    fromResource.getList(state, getDashboardCurrentCompany),
  );
  const statistics = useSelector((state) =>
    fromResource.getList(state, getDashboardStatistics),
  );
  const hasValidIntegration = useSelector((state) =>
    fromResource.getList(state, isValidIntegration),
  );

  const dispatch = useDispatch();

  const handleRedirect = (type) => {
    switch (type) {
      case 'general': {
        history.push(
          toAccountReconciliation({
            filterByTemplate: 'GENERAL',
            filterByApprove: 'all',
          }),
        );
        break;
      }
      case 'bank': {
        history.push(
          toAccountReconciliation({
            filterByTemplate: 'BANK',
            filterByApprove: 'all',
          }),
        );
        break;
      }
      case 'simple_ledger': {
        history.push(
          toAccountReconciliation({
            filterByTemplate: 'SIMPLE_LEDGER',
            filterByApprove: 'all',
          }),
        );
        break;
      }
      case 'vat': {
        history.push(toVATReconciliation());
        break;
      }
      case 'payroll': {
        history.push(toPayrollReconciliation());
        break;
      }
      default: {
        break;
      }
    }
  };

  const fetchData = async () => {
    setStatus(() => ({ loading: true, error: null }));
    setChartStatus(() => ({ chartLoading: true, chartError: null }));

    try {
      await dispatch(
        resourceListReadRequest(getDashboardCurrentCompany, {
          period: accountingPeriod,
          year: +accountingYear,
        }),
      );
    } catch (e) {
      setStatus((state) => ({ ...state, error: e }));
    } finally {
      setStatus((state) => ({ ...state, loading: false }));
    }

    try {
      await dispatch(
        resourceListReadRequest(getDashboardStatistics, {
          year: +accountingYear,
        }),
      );
    } catch (e) {
      setChartStatus((state) => ({ ...state, chartError: e }));
    } finally {
      setChartStatus((state) => ({ ...state, chartLoading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId, accountingYear, accountingPeriod]);

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Heading level={3}>
          <Trans>Something went wrong! Please try again later.</Trans>
        </Heading>
      </Container>
    );
  }

  return (
    <DashboardMyProgressNew
      data={data}
      statistics={statistics}
      chartError={chartError}
      chartLoading={chartLoading}
      handleRedirect={handleRedirect}
      hasValidIntegration={hasValidIntegration?.status ?? true}
      {...props}
    />
  );
}

export default DashboardMyProgressNewContainer;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;
