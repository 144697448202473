import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';

import { Label, Spinner } from 'components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '@lingui/macro';

import 'react-bootstrap-typeahead/css/Typeahead.css';

/**
 * AutoSuggestInput component
 */
function AutoSuggestInput(props) {
  const {
    size,
    label,
    height,
    setRef,
    loading,
    disabled,
    selected,
    onChange,
    reference,
    validateRequired,
    inputValidateColor,
    vismaNxt,
    ...restProps
  } = props;
  const options = Array.isArray(props.options)
    ? props.options.map((option) => ({
        ...option,
        label: option.label || '',
      }))
    : [];
  let defaultSelectedItems = [];
  let selectedOption;
  if (selected) {
    if (!Array.isArray(selected)) {
      selectedOption = [selected];
      defaultSelectedItems = selectedOption.map((val) =>
        options.find((v) => !!(val === v || val === v.id)),
      );
    }
  }

  const alreadyActivatedNXT = () =>
    t`This company is already set up in Accountflow. You must disable the existing company before you can activate it as a Visma Business NXT entity.`; // eslint-disable-line max-len

  return (
    <TypeaheadHolder height={height}>
      {!!label && <Label>{label}</Label>}
      {loading && (
        <SpinnerHolder withLabel={!!label}>
          <Spinner />
        </SpinnerHolder>
      )}
      <Typeahead
        ref={setRef}
        onChange={onChange}
        disabled={disabled || loading}
        selected={!Array.isArray(selected) ? defaultSelectedItems : selected}
        {...{
          options,
          bsSize: size,
        }}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem
                disabled={result.disabled}
                option={result}
                position={index}
                key={result.id || result.label}
              >
                {result.label}
                <div className="pull-right">
                  {vismaNxt && result.disabled && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-disabled-${result.id}`}>
                          {alreadyActivatedNXT()}
                        </Tooltip>
                      }
                    >
                      <FaInfoCircle />
                    </OverlayTrigger>
                  )}
                </div>
              </MenuItem>
            ))}
          </Menu>
        )}
        {...restProps}
      />
      {validateRequired && inputValidateColor && (
        <StyledSpan>Required field</StyledSpan>
      )}
    </TypeaheadHolder>
  );
}

AutoSuggestInput.propTypes = {
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  label: PropTypes.string,
  height: PropTypes.number,
  setRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  onChange: PropTypes.func,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  validateRequired: PropTypes.bool,
  inputValidateColor: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
    ]),
  ),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  emptyLabel: PropTypes.string,
  placeholder: PropTypes.string,
  clearButton: PropTypes.bool,
  highlightOnlyResult: PropTypes.bool,
};

AutoSuggestInput.defaultProps = {
  size: undefined,
  label: undefined,
  height: undefined,
  setRef: undefined,
  selected: null,
  onChange: undefined,
  validateRequired: false,
  inputValidateColor: true,
  options: [],
  multiple: false,
  disabled: false,
  autoFocus: false,
  emptyLabel: undefined,
  placeholder: undefined,
  clearButton: false,
  highlightOnlyResult: true,
};

export default AutoSuggestInput;

const StyledSpan = styled.span`
  color: #a94442;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const TypeaheadHolder = styled.div`
  & .rbt-input-multi {
    height: ${(props) => props.height}px;
    overflow: hidden;
  }
`;

const SpinnerHolder = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 12px;
  width: 100%;
  height: ${({ withLabel }) => (withLabel ? '71%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
