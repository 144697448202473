import React from 'react';
import { t } from '@lingui/macro';
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';
import { BiLink } from '@react-icons/all-files/bi/BiLink';

export const columns = (isReadOnly, hasSplitAmounts) => [
  {
    width: 80,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    disableSortBy: true,
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 60,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: 'flex-center',
    disableSortBy: true,
  },
  {
    width: 150,
    Header: t`VAT Code Description`,
    accessor: 'vatDescription',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 70,
    Header: t`Account`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
    disableSortBy: true,
  },
  {
    width: 80,
    Header: t`Line ID`,
    accessor: 'lineId',
    sortType: 'basic',
    className: '',
    disableSortBy: true,
  },
  {
    width: 100,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  ...(hasSplitAmounts
    ? [
        {
          width: 100,
          Header: t`Split Amount`,
          accessor: 'splitAmount',
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
          },
        },
      ]
    : []),
  {
    width: 80,
    Header: t`Posted Date`,
    accessor: 'date',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 80,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'openModal',
                visible: (item) => item.reconciliationStatus === 'UNMATCHED',
                icon: () => <BiLink />,
                tooltip: t`Manually Reconcile`,
              },
              {
                id: 'unReconcile',
                visible: (item) => item.reconciliationStatus === 'MATCHED',
                icon: () => <BiUnlink />,
                tooltip: t`Unreconcile`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
