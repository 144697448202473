import { t } from '@lingui/macro';

const possibleTypes = () => [t`base`, t`ingoingTax`, t`outgoingTax`]; // eslint-disable-line

export const columns = (_, hasSplitAmounts) => [
  {
    width: 20,
    Header: t`Line ID`,
    accessor: 'lineSid',
    className: '',
  },
  {
    width: 20,
    Header: t`Source Document ID`,
    accessor: 'sourceDocumentId',
    className: '',
    withTooltip: true,
  },
  {
    width: 10,
    Header: t`VAT Code`,
    accessor: 'code',
    className: '',
  },
  {
    width: 20,
    Header: t`Posted Date`,
    accessor: 'postedDate',
    className: '',
    withTooltip: true,
  },
  {
    width: 20,
    Header: t`Reconciliation Date`,
    accessor: 'reconciliationDate',
    className: '',
    withTooltip: true,
  },
  {
    width: 30,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  ...(hasSplitAmounts
    ? [
        {
          width: 30,
          Header: t`Split Amount`,
          accessor: 'splitAmount',
          className: 'flex-right',
          withTotal: true,
          cell: {
            type: 'number',
          },
        },
      ]
    : []),
  {
    width: 20,
    Header: t`Type`,
    accessor: 'type',
    className: '',
    withTooltip: true,
    cell: {
      converter: (value) =>
        t({
          id: value,
          message: value,
        }),
    },
  },
];

export default { columns };
