import { t } from '@lingui/macro';

export const columns = (showDetails) => [
  {
    width: showDetails ? 200 : 250,
    Header: t`Name`,
    accessor: 'accountNumber',
    className: '',
    withTooltip: true,
  },
  ...(showDetails
    ? [
        {
          width: 70,
          Header: t`Period`,
          accessor: 'period',
          className: 'flex-right',
        },
      ]
    : []),
  {
    width: showDetails ? 150 : 170,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
