import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { RiFile2Fill } from '@react-icons/all-files/ri/RiFile2Fill';
import { Modal, Row, Col } from 'react-bootstrap';

import {
  Card,
  Table,
  Field,
  Button,
  Spinner,
  Heading,
  TableRow,
  TableCell,
  ButtonIcon,
} from 'components';

function PayrollReportModal(props) {
  const {
    data,
    loading,
    bimonthly,
    reportMode,
    accumulated,
    handleChange,
    activePeriods,
    accountingYear,
    loadingA07XML = {},
    changePercentage,
    reportInfoHeader,
    reportTotalHeader,
    incomeTypesHeader,
    handleDownloadA07XML,
    toggleChangePercentage,
    handleAccumulatedChange,
    socialSecuritySpecHeader,
    togglePayrollReportModal,
  } = props;

  const headerText = useMemo(() => {
    const title = t`A07 Reports:`;
    const periodLabel = bimonthly ? t`Term` : t`Period`;
    let periodValue = bimonthly ? Math.round(activePeriods / 2) : activePeriods;

    if (accumulated && periodValue !== 1) {
      periodValue = `1-${periodValue}`;
    }

    return `${title} ${periodLabel} ${periodValue}, ${accountingYear}`;
  }, [bimonthly, activePeriods, accumulated, accountingYear]);

  let body;

  if (loading) {
    body = (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  } else if (isEmpty(data?.payrollReportTotals)) {
    body = (
      <Heading alignment="center" level={4}>
        <Trans>No data to display.</Trans>
      </Heading>
    );
  } else {
    const reportTotal = [
      {
        id: 'report-Total-1',
        name: t`Social Security`,
        value: data.payrollReportTotals.socialSecurityTotal,
        enabled: true,
      },
      {
        id: 'report-Total-2',
        name: t`Withheld Tax`,
        value: data.payrollReportTotals.withheldTaxTotal,
        enabled: true,
      },

      {
        id: 'report-Total-3',
        name: t`Income - With Social Security`,
        value: data.payrollReportTotals.incomeWithSocialSecurityTotal,
        enabled: true,
      },
      {
        id: 'report-Total-4',
        name: t`Income - Without Social Security`,
        value: data.payrollReportTotals.incomeWithoutSocialSecurityTotal,
        enabled: true,
      },
      {
        id: 'report-Total-5',
        name: t`Financial Tax`,
        value: data.payrollReportTotals.financialTaxTotal,
        enabled: data.payrollReportTotals.financialTaxTotal !== null,
      },
      {
        id: 'report-Total-6',
        name: t`Total Person Reported`,
        value: data.payrollReportTotals.totalPersonReportedTotal,
        enabled: true,
      },
    ];

    const reportTotalValues = reportTotal.map((item) =>
      item.enabled ? (
        <TableRow key={item.id}>
          <TableCell className="text-left">{item.name}</TableCell>
          <TableCell
            number={item.id !== 'report-Total-7'}
            rounded={item.id !== 'report-Total-7'}
            className="text-right"
          >
            {item.value}
          </TableCell>
        </TableRow>
      ) : (
        ''
      ),
    );

    const socialSecuritySpecCells = !isEmpty(
      data.altinnPayrollSocialSecurityRows,
    )
      ? data.altinnPayrollSocialSecurityRows.map((item) => (
          <TableRow key={item.id}>
            <TableCell className="text-left">{item.soneSS}</TableCell>
            {accumulated && (
              <TableCell className="text-left">{item.period}</TableCell>
            )}
            <TableCell className="text-left">
              {item.socialSecurityName}
            </TableCell>
            <TableCell className="text-left">{item.typeSS}</TableCell>
            <TableCell rounded number className="text-right">
              {item.amountSS}
            </TableCell>
            <TableCell className="text-right">
              {`${item.percentageSS}%`}
            </TableCell>
            <TableCell rounded number className="text-right">
              {item.calculatedAmountSS}
            </TableCell>
          </TableRow>
        ))
      : '';

    let incomeTypesSum = 0;
    let incomeTypesSSSum = 0;
    const incomeTypes = !isEmpty(data.altinnPayrollIncomeRows)
      ? data.altinnPayrollIncomeRows.map((item) => {
          incomeTypesSum += item.amount;
          incomeTypesSSSum += item.calculatedSocialSecurity;

          return (
            <TableRow key={`payrollIncomeTypes-${item.altinnPayrollIncomeSID}`}>
              {accumulated && (
                <TableCell className="text-left">{item.period}</TableCell>
              )}
              <TableCell className="text-left">{item.incomeType}</TableCell>
              <TableCell className="text-left">{item.description}</TableCell>
              <TableCell rounded number className="text-right">
                {item.amount}
              </TableCell>
              <TableCell className="text-right">{item.count}</TableCell>
              <TableCell className="text-right">
                {item.withSocialSecurity ? t`YES` : t`NO`}
              </TableCell>
              <TableCell className="text-right">
                {item.isWithholdingTaxDeductible ? t`YES` : t`NO`}
              </TableCell>
              <TableCell>
                {!changePercentage ? (
                  item.percentage
                ) : (
                  <InputHolder>
                    <Field
                      id={item.altinnPayrollIncomeSID}
                      type="number"
                      size="small"
                      name={item.altinnPayrollIncomeSID}
                      value={item.percentage}
                      onBlur={(e) => handleChange(e, item)}
                      disabled={loading}
                      placeholder={t`Percentage`}
                    />
                  </InputHolder>
                )}
              </TableCell>
              <TableCell rounded number className="text-right">
                {item.calculatedSocialSecurity}
              </TableCell>
            </TableRow>
          );
        })
      : '';

    let incomeTypesFooter;
    let socialSecurityFooter;

    if (!isEmpty(data)) {
      incomeTypesFooter = (
        <tfoot key="incometypes-footer">
          <TableRow>
            <TableCell colSpan={accumulated ? 2 : 1} />
            <TableCell className="text-right">
              <Trans>SUM AMOUNT</Trans>
            </TableCell>
            <TableCell rounded number className="text-right">
              {incomeTypesSum}
            </TableCell>
            <TableCell colSpan="3" />
            <TableCell className="text-right">
              <Trans>SUM SOCIAL SECURITY</Trans>
            </TableCell>
            <TableCell rounded number className="text-right">
              {incomeTypesSSSum}
            </TableCell>
          </TableRow>
        </tfoot>
      );

      socialSecurityFooter = (
        <tfoot key="social-sec-footer">
          <TableRow>
            <TableCell colSpan={accumulated ? 3 : 2} />
            <TableCell className="text-left">
              <Trans>SUM</Trans>
            </TableCell>
            <TableCell rounded number className="text-right">
              {data.payrollReportTotals.socialSecuritySpecificationAmount}
            </TableCell>
            <TableCell className="text-left">
              <Trans>SUM</Trans>
            </TableCell>
            <TableCell rounded number className="text-right">
              {data.payrollReportTotals.socialSecuritySpecificationSum}
            </TableCell>
          </TableRow>
        </tfoot>
      );
    }

    const reportInfo =
      Array.isArray(data?.reportInfoRows) &&
      data.reportInfoRows.map((item) => (
        <TableRow key={item.altinnPayrollSID}>
          <TableCell className="text-left">{item.period}</TableCell>
          <TableCell rounded number className="text-left">
            {item.delivererName}
          </TableCell>
          <TableCell className="text-left">{item.altinnReferanse}</TableCell>
          <TableCell className="text-left">
            {i18n.date(item.deliveryDateTime, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </TableCell>
          {!reportMode && (
            <TableCell className="text-center">
              <Button
                simple
                magnify
                onClick={() =>
                  handleDownloadA07XML(
                    item.altinnReferanse,
                    // eslint-disable-next-line max-len
                    `${item.period} - ${item.delivererName}`,
                  )
                }
                disabled={!item.altinnPayrollSID}
              >
                {loadingA07XML[item.altinnReferanse] ? (
                  <Spinner size="md" />
                ) : (
                  <RiFile2Fill />
                )}
              </Button>
            </TableCell>
          )}
        </TableRow>
      ));

    body = (
      <>
        <Row>
          <Col md={4}>
            <Card
              header={
                <Heading level={6} className="card-title">
                  <Trans>Report Totals</Trans>
                </Heading>
              }
            >
              <Row>
                <Col style={{ overflow: 'overlay' }}>
                  <Table responsive head={reportTotalHeader}>
                    {reportTotalValues}
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={8}>
            <Card
              header={
                <Heading level={6} className="card-title">
                  <Trans> Report Info</Trans>
                </Heading>
              }
            >
              <Row>
                <Col style={{ overflow: 'overlay' }}>
                  <Table responsive head={reportInfoHeader}>
                    {reportInfo}
                  </Table>
                </Col>
              </Row>
            </Card>
            <hr />
          </Col>
          <Col md={8}>
            <Card
              header={
                <Heading level={6} className="card-title">
                  <Trans>Social Security Specification</Trans>
                </Heading>
              }
            >
              <Table
                responsive
                head={socialSecuritySpecHeader}
                customFoot={socialSecurityFooter}
              >
                {socialSecuritySpecCells}
              </Table>
            </Card>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <Card
              header={
                <Heading level={6} className="card-title">
                  <Trans>Income Types</Trans>
                </Heading>
              }
            >
              {!reportMode && (
                <ButtonHolder>
                  <Button magnify size="sm" onClick={toggleChangePercentage}>
                    <Trans>Change percentage</Trans>
                  </Button>
                </ButtonHolder>
              )}
              <Table
                responsive
                customFoot={incomeTypesFooter}
                head={incomeTypesHeader}
              >
                {incomeTypes}
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  return reportMode ? (
    <div>{body}</div>
  ) : (
    <Modal
      show
      bsSize="lg"
      dialogClassName="payroll-modal"
      onEscapeKeyDown={togglePayrollReportModal}
    >
      <Modal.Header>
        <Header>
          <Heading level={3}>{headerText}</Heading>
          <Field
            horizontal
            id="showAccumulated"
            type="checkbox"
            name="showAccumulated"
            label={t`Show accumulated YTD Period`}
            checked={accumulated}
            onChange={handleAccumulatedChange}
          />
        </Header>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#F4F3EF' }}>{body}</Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={togglePayrollReportModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PayrollReportModal.propTypes = {
  data: PropTypes.shape({}),
  company: PropTypes.shape({}).isRequired,
  togglePayrollReportModal: PropTypes.func.isRequired,
  reportInfoHeader: PropTypes.node.isRequired,
  /** @type {node} header */
  reportTotalHeader: PropTypes.node.isRequired,
  socialSecuritySpecHeader: PropTypes.node.isRequired,
  incomeTypesHeader: PropTypes.node.isRequired,
};

PayrollReportModal.defaultProps = {
  data: undefined,
};

export default PayrollReportModal;

const ButtonHolder = styled.div`
  height: 25px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputHolder = styled.div`
  width: 100%;
  max-width: 70px;
`;
