import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Button } from 'components';

function IframeModal(props) {
  const { title, url, show, onHide, ...rest } = props;

  const handleLoad = (e) => {
    console.debug(e); // eslint-disable-line no-console
  };

  return (
    <Modal {...rest} show={show} onHide={onHide} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IFrame
          title={title}
          src={url}
          width="100%"
          height="100%"
          onLoad={handleLoad}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Lukk</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IframeModal;

const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  min-height: 50rem;
`;
