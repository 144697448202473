import React, { useEffect, useState, useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { Modal, ButtonGroup } from 'react-bootstrap';

import { Button, Heading, Spinner, ButtonIcon, Field } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { bankImportSettingsApi } from 'services/apihelpers';

import getErrorMessage from 'services/helpers/getErrorMessage';

function ImportSettingsModal(props) {
  const { closeModal, onSettingsApply } = props;

  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    company: undefined,
    account: undefined,
  });
  const [loading, setLoading] = useState(false);
  const accounts = useMemo(() => {
    if (selectedValues.company) {
      return (
        companies.find(
          (company) => company.companyId === selectedValues.company,
        )?.accountReconConfigs || []
      );
    }

    return [];
  }, [selectedValues.company]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      const response = await dispatch(
        resourceListReadRequest(bankImportSettingsApi),
      );

      if (Array.isArray(response) && response.length) {
        setCompanies(response);
        setSelectedValues(() => ({
          company: response[0].companyId,
          account: response[0].accountReconConfigs[0].accountId,
        }));
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
      setCompanies([]);
      setSelectedValues(() => ({ company: undefined, account: undefined }));
    } finally {
      setLoading(() => false);
    }
  };

  const handleSelectChange = ({ target: { value, id } }) => {
    if (id === 'company') {
      const acc = companies.find((item) => item.companyId === value)
        .accountReconConfigs[0].accountId;

      setSelectedValues(() => ({
        account: acc,
        company: value,
      }));
    } else {
      setSelectedValues((prev) => ({
        ...prev,
        account: value,
      }));
    }
  };

  const handleSave = () => {
    const selectedCompany = companies?.find?.(
      (item) => item.companyId === selectedValues.company,
    );
    const selectedAcc = selectedCompany?.accountReconConfigs?.find?.(
      (acc) => acc.accountId === selectedValues.account,
    );
    onSettingsApply(selectedAcc.reconConfigs.configParts || []);
    closeModal();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Modal.Header>
        <Heading level={3}>
          <Trans>Import Settings</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <Spinner />
        ) : companies?.length ? (
          <>
            <Field
              noBorder
              size="sm"
              name="company"
              type="select"
              value={selectedValues.company}
              label={t`Company`}
              onChange={handleSelectChange}
            >
              {companies.map((company) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </option>
              ))}
            </Field>
            {selectedValues.company && (
              <Field
                noBorder
                size="sm"
                name="account"
                type="select"
                value={selectedValues.account}
                label={t`Account`}
                onChange={handleSelectChange}
              >
                {accounts.map((account) => (
                  <option
                    key={account.reconConfigs.orderId}
                    value={account.accountId}
                  >
                    {account.accountId} - {account.accountDescription}
                  </option>
                ))}
              </Field>
            )}
          </>
        ) : (
          <div>
            <Trans>No available settings was found</Trans>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="success"
            onClick={handleSave}
            disabled={!selectedValues.company || !selectedValues.account}
          >
            <ButtonIcon as={HiCheck} /> <Trans>Apply</Trans>
          </Button>
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportSettingsModal;
