import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiSearch } from '@react-icons/all-files/hi/HiSearch';

import { Field, Button, ButtonIcon } from 'components';

function TableFilterTypingInputGroup(props) {
  const {
    query,
    search,
    entries,
    handleSubmit,
    handleOnKeyDown,
    hideTransactions,
    withPeriodization,
  } = props;

  return (
    <>
      {hideTransactions ? null : (
        <>
          <Field
            size="small"
            name="entries"
            label={t`Entries`}
            onKeyDown={handleOnKeyDown}
            defaultValue={entries}
          />{' '}
          <Button
            fill
            icon
            magnify
            key="entries-filter-button"
            onClick={handleSubmit}
          >
            <ButtonIcon as={HiCheck} />
          </Button>{' '}
        </>
      )}
      <Field
        size="small"
        name="query"
        label={t`Search`}
        onKeyDown={handleOnKeyDown}
        placeholder={t`Enter search criteria`}
        defaultValue={query}
      />{' '}
      <Button
        fill
        icon
        magnify
        key="search-filter-button"
        onClick={handleSubmit}
      >
        <ButtonIcon as={HiSearch} />
      </Button>{' '}
      {withPeriodization && (
        <PeriodizationHolder>
          <Field
            id="hideAccruals"
            key="hideAccruals"
            type="checkbox"
            name="hideAccruals"
            value={!search.hideAccruals}
            label={t`Hide Accruals table`}
          />
        </PeriodizationHolder>
      )}
    </>
  );
}

TableFilterTypingInputGroup.propTypes = {
  query: PropTypes.string.isRequired,
  search: PropTypes.shape({}),
  entries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  hideTransactions: PropTypes.bool,
  withPeriodization: PropTypes.bool,
};

TableFilterTypingInputGroup.defaultProps = {
  search: {},
  hideTransactions: false,
  withPeriodization: false,
};

export default TableFilterTypingInputGroup;

const PeriodizationHolder = styled.span`
  padding-left: 50px;
`;
