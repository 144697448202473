import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';

import { assetTypes } from 'services/shapes';

/**
 * Asset Register Columns
 */

export const columns = (isReadOnly) => [
  {
    width: 200,
    Header: t`Category`,
    accessor: 'categoryName',
    className: '',
    withTooltip: true,
  },
  {
    width: 245,
    Header: t`Asset Name`,
    accessor: 'assetName',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Asset Type`,
    accessor: 'assetType',
    className: '',
    disableSortBy: true,
    cell: {
      converter: (value) =>
        assetTypes().find((asset) => asset.id === value)?.label,
    },
  },
  {
    width: 130,
    Header: t`Date of Purchase`,
    accessor: 'dateOfPurchase',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  {
    width: 130,
    Header: t`Depreciation start`,
    accessor: 'dateOfDepreciationStart',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  {
    width: 130,
    Header: t`Purchase amount`,
    sortType: 'basic',
    accessor: 'purchaseAmount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Adjustments`,
    sortType: 'basic',
    accessor: 'adjustments',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Monthly Depreciation`,
    sortType: 'basic',
    accessor: 'monthlyDepreciation',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Accumulated Depreciation`,
    sortType: 'basic',
    accessor: 'accumulatedDepreciation',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Closing Balance`,
    sortType: 'basic',
    accessor: 'closingBalance',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 85,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'edit',
                icon: () => <GoPencil />,
                tooltip: t`Edit`,
              },
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
