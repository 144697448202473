const prepareData = (glData, bankData, accountingPeriod) => {
  const newGlData = { ...glData };
  const newBankData = { ...bankData };

  const reconIds = {
    gl: {},
    bank: {},
  };

  if (newGlData?.transactions?.length) {
    // eslint-disable-next-line no-param-reassign
    newGlData.transactions = newGlData.transactions.map((item, i) => {
      const { reconciliationId } = item;
      const period = item.approvedIn && +item.approvedIn.slice(5, 7);

      // Check if row is matched in the future
      if (period && period > accountingPeriod) {
        reconIds.gl[reconciliationId] = [
          ...(reconIds.gl[reconciliationId] || []),
          i,
        ];

        return { ...item, grayLine: true };
      }

      return item;
    });
  }

  if (newBankData?.bankTransactions?.length) {
    // eslint-disable-next-line no-param-reassign
    newBankData.bankTransactions = newBankData.bankTransactions.map(
      (item, i) => {
        const { reconciliationId } = item;
        const period = item.approvedIn && +item.approvedIn.slice(5, 7);

        // Check if row is matched in the future
        if (period && period > accountingPeriod) {
          reconIds.bank[reconciliationId] = [
            ...(reconIds.bank[reconciliationId] || []),
            i,
          ];

          return { ...item, grayLine: true };
        }

        return item;
      },
    );
  }

  Array.from(Object.keys(reconIds.gl)).forEach((key) => {
    // Remove many to many matched in the future rows
    if (reconIds.gl[key].length > 1 && reconIds.bank[key]?.length > 1) {
      reconIds.gl[key].forEach((id) => {
        delete newGlData.transactions[id].grayLine;
      });

      reconIds.bank[key].forEach((id) => {
        delete newBankData.bankTransactions[id].grayLine;
      });
    }
  });
  return { bank: newBankData, gl: newGlData };
};

const findTotal = (arr) =>
  arr.reduce(
    (value, item) =>
      !item.reconciled || item.grayLine ? value + item.amount : value,
    0,
  );

const filterAndSort = ({
  type,
  collection,
  accountingPeriod,
  showCurrentMonth,
  setGlTotal = () => {},
  setBankTotal = () => {},
}) => {
  const newData = { ...collection };

  if (type === 'gl' && newData.transactions) {
    newData.transactions = [...newData.transactions];

    newData.transactions = newData.transactions.filter(
      (item) =>
        +item.postedDate.slice(5, 7) >= 1 &&
        +item.postedDate.slice(5, 7) <= +accountingPeriod,
    );

    setGlTotal(findTotal(newData.transactions));

    if (showCurrentMonth) {
      // eslint-disable-next-line no-param-reassign
      newData.transactions = newData.transactions.filter(
        (item) => +accountingPeriod === +item.postedDate.slice(5, 7),
      );
    }
  } else if (type === 'bank' && newData.bankTransactions) {
    newData.bankTransactions = [...newData.bankTransactions];

    newData.bankTransactions = newData.bankTransactions.filter(
      (item) =>
        +item.postedDate.slice(5, 7) >= 1 &&
        +item.postedDate.slice(5, 7) <= +accountingPeriod,
    );

    setBankTotal(findTotal(newData.bankTransactions));

    if (showCurrentMonth) {
      // eslint-disable-next-line no-param-reassign
      newData.bankTransactions = newData.bankTransactions.filter(
        (item) => +accountingPeriod === +item.postedDate.slice(5, 7),
      );
    }
  }

  return newData;
};

export const bankTemplateDataFormatter = (props) => {
  const { data, setGlTotal, setBankTotal, accountingPeriod, showCurrentMonth } =
    props;

  const newData = prepareData(
    data?.glAccounts?.[0] || {},
    data?.bankAccounts?.[0] || {},
    accountingPeriod,
  );

  return {
    bankId: newData?.bank?.id,
    dataGL: filterAndSort({
      type: 'gl',
      setGlTotal,
      accountingPeriod,
      showCurrentMonth,
      collection: newData?.gl || {},
    }),
    dataBank: filterAndSort({
      type: 'bank',
      setBankTotal,
      accountingPeriod,
      showCurrentMonth,
      collection: newData?.bank || {},
    }),
  };
};

export default bankTemplateDataFormatter;
