import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import ErrorCard from '../../molecules/ErrorCard/index';

function ErrorScreen(props) {
  const { message, unrecoverable } = props;

  return (
    <Background>
      <div className="bg" />
      <div className="bg bg2" />
      <div className="bg bg3" />
      <Content>
        <ErrorCard kind="basic" error={message} unrecoverable={unrecoverable} />
      </Content>
    </Background>
  );
}

export default ErrorScreen;

const slide = keyframes`
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
`;

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #29252b;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg {
    animation: ${slide} 4s ease-in-out infinite alternate;

    background-image: linear-gradient(-60deg, #1b191f 50%, #211f26 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
  }

  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 5s;
  }

  .bg3 {
    animation-duration: 6s;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      span {
        color: #84c1ff;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: 400;
      margin-top: 7px;
      padding-bottom: 0;
      margin-bottom: 0;
      color: #a1b1c2;
    }
  }
`;
