import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { CommentsHistoryModal } from 'components';

import {
  resourceListReadRequest,
  resourceListReadSuccess,
  resourceDeleteRequest,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { periodCommentApi, periodCommentAllApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function CommentsHistoryModalContainer(props) {
  const { company, row, params, isReadOnly, loadMainData } = props;

  const [isUpdated, setIsUpdated] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, periodCommentAllApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${periodCommentAllApi}ListRead`),
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleRemovePeriodComment = async (id) => {
    setIsUpdated(true);
    await dispatch(resourceDeleteRequest(`${periodCommentApi}?id=${id}`));

    const updatedData = data.filter((c) => c.id !== id);
    dispatch(resourceListReadSuccess(periodCommentAllApi, updatedData));
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'delete': {
          _setActionSubmitting(item.id, true);
          await handleRemovePeriodComment(item.id);
          _setActionSubmitting(item.id, false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(periodCommentAllApi, {
        companyId: params.companyId,
        accountId: row.accountId,
        periodType: company?.currentPeriodType.toUpperCase(),
      }),
    );
  };

  const toggleModal = () => {
    if (isUpdated) {
      loadMainData();
    }

    props.toggleModal();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommentsHistoryModal
      data={data}
      params={params}
      loading={loading}
      isReadOnly={isReadOnly}
      toggleModal={toggleModal}
      handleAction={handleAction}
      actionSubmitting={actionSubmitting}
    />
  );
}

export default CommentsHistoryModalContainer;
