import React from 'react';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import { Trans } from '@lingui/macro';
import { Chart as ChartComponent } from 'react-chartjs-2';
import styled from 'styled-components/macro';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Spinner, Heading } from 'components';

function MultipleChart(props) {
  const {
    type,
    data,
    error,
    height,
    options,
    loading,
    key = 'chart-container',
    ...restProps
  } = props;

  if (error) {
    return (
      <SpinnerContainer>
        <Heading level={3} className="card-title">
          <Trans>Something went wrong!</Trans>
        </Heading>
      </SpinnerContainer>
    );
  }

  return (
    <Wrapper height={height} key={key} width="100%">
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <ChartComponent
          {...restProps}
          type={type}
          data={data}
          options={options}
          plugins={[ChartDataLabels]}
        />
      )}
    </Wrapper>
  );
}

export default MultipleChart;

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
