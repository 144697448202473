import React from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { hyphenateSync } from 'hyphen/no';
import { hyphenateSync as hyphenateSyncEn } from 'hyphen/en';
import { FaSort } from '@react-icons/all-files/fa/FaSort';
import { TiArrowSortedUp } from '@react-icons/all-files/ti/TiArrowSortedUp';
import { TiArrowSortedDown } from '@react-icons/all-files/ti/TiArrowSortedDown';

import { TWO_K, FOUR_K, LARGE_SCREENS } from 'constants/mediaSizes';

export function CustomTableHeader(props) {
  const {
    grid,
    getRef,
    isModal,
    tableId,
    reportMode,
    calculateCellWidth,
    responsiveRowWidth,
    tableProps: { headerGroups },
  } = props;

  const groups = headerGroups.map((headerGroup) => {
    const headerGroupProps = headerGroup.getHeaderGroupProps({
      style: { width: responsiveRowWidth },
    });

    // eslint-disable-next-line consistent-return
    const getSortIcon = (column) => {
      const className = cx('sort-icon-holder', {
        hidden: column.disableSortBy,
        descOrAsc: !(!column.isSorted && !column.disableSortBy),
      });

      if (!column.isSorted && !column.disableSortBy) {
        return <FaSort className={className} />;
      }

      if (column.isSorted && !column.isSortedDesc) {
        return <TiArrowSortedUp className={className} />;
      }

      if (column.isSorted && column.isSortedDesc) {
        return <TiArrowSortedDown className={className} />;
      }
    };

    return (
      <HeaderHolder
        key={`${headerGroupProps.key}-${tableId}`}
        {...headerGroupProps}
        ref={getRef}
        grid={grid?.id}
        isModal={isModal}
        className="tr"
        id="header-holder"
      >
        {headerGroup.headers.map((column) => {
          const resizerProps = column.getResizerProps();
          const minWidth =
            column.columns &&
            column.columns.reduce((sum, item) => {
              if (item.isVisible) {
                return sum + item.width;
              }
              return sum;
            }, 0);

          const cellWidth = calculateCellWidth(minWidth || column.width);

          const headerProps = {
            ...column.getHeaderProps({
              ...column.getSortByToggleProps(),
              style: { minWidth: cellWidth, width: cellWidth },
            }),
          };

          resizerProps.onClick = (e) => e.stopPropagation();

          let headerText = column.render('Header');

          if (typeof headerText === 'string') {
            headerText =
              i18n.locale === 'nb'
                ? hyphenateSync(headerText)
                : hyphenateSyncEn(headerText);
          }

          return (
            <div
              className={cx('th', column.className, {
                isSorted: column.isSorted,
                isSortable: !column.disableSortBy,
                lowercase: column.lowercase,
              })}
              key={`${headerProps.key}-${tableId}`}
              id={`${headerProps.key}-${tableId}`}
              {...headerProps}
            >
              <div className="th-container">
                {headerText}
                {!reportMode && getSortIcon(column)}
              </div>
              <div
                {...resizerProps}
                className={cx('resizer', {
                  isResizing: column.isResizing,
                })}
              />
            </div>
          );
        })}
      </HeaderHolder>
    );
  });

  return <GroupHolder>{groups}</GroupHolder>;
}

CustomTableHeader.propTypes = {
  grid: PropTypes.shape({}),
  getRef: PropTypes.func,
  tableId: PropTypes.string,
  reportMode: PropTypes.bool,
  tableProps: PropTypes.shape({
    state: PropTypes.shape({}).isRequired,
    headerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

CustomTableHeader.defaultProps = {
  grid: {},
  getRef: undefined,
  tableId: undefined,
  reportMode: false,
};

export default CustomTableHeader;

const GroupHolder = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

const HeaderHolder = styled.div`
  overflow: hidden;
  max-width: 100%;

  .isSortable {
    cursor: pointer;
  }

  .descOrAsc {
    min-width: 10px !important;
  }

  .sort-icon-holder {
    font-size: 12px;
    min-width: 7px;
    margin-left: 5px;

    ${({ isModal, grid }) =>
      !isModal &&
      grid !== 'sm' &&
      `
      @media (min-width: ${TWO_K.min}px) {
        font-size: 12px;
      }

      @media (min-width: ${FOUR_K.min}px) {
        font-size: 14px;
      }
    `}
  }

  i:hover {
    color: #ff8f5e;
  }

  .edit-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    textarea {
      height: 25px;
    }

    i {
      padding-left: 10px;
      margin-right: -10px;
    }
  }

  .flex-center {
    text-align: center;
    justify-content: center;
  }

  .flex-right {
    text-align: right;
    justify-content: flex-end;
  }

  .tr.body-row.clickable {
    cursor: pointer;
  }

  .tr {
    :nth-child(even) {
      background-color: #f5f5f5;
    }

    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th.isSorted svg {
    color: #ff8f5e;
  }

  .th {
    margin: 0;
    padding: 10px 15px;
    display: flex !important;
    overflow: hidden;
    position: relative;
    font-size: 13px;
    flex-wrap: wrap;
    font-weight: 600;
    align-items: center;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    flex-direction: row;
    text-transform: uppercase;

    &.lowercase {
      text-transform: none;
    }

    ${({ isModal, grid }) =>
      !isModal &&
      grid === 'sm' &&
      `
          @media (max-width: ${LARGE_SCREENS.max}px) {
             font-size: 11px;
             padding: 5px;
          }
      `}

    ${({ isModal, grid }) =>
      !isModal &&
      grid !== 'sm' &&
      `
         @media (min-width: ${TWO_K.min}px) {
            font-size: 13px;
            padding: 10px 15px;
          }

          @media (min-width: ${FOUR_K.min}px) {
            font-size: 15px;
            padding: 10px 15px;
          }
      `}

    :last-child {
      border-right: 0;
    }

    input[type='checkbox'] {
      height: 15px;
      margin: 0;
      padding: 0;
    }

    .resizer {
      top: 0;
      right: -5px;
      width: 10px;
      height: 100%;
      position: absolute;
      touch-action: none;
    }
  }

  .th-container {
    display: flex;
    align-items: center;
  }
`;
