import React from 'react';
import styled from 'styled-components/macro';
import { Row } from 'react-bootstrap';
import _isEmpty from 'lodash-es/isEmpty';
import { Trans } from '@lingui/macro';

import { Heading, Spinner } from 'components';

import { formatDate } from 'services/helpers';

function AuditDetailedTable(props) {
  const { data, failed, loading } = props;
  let content;

  const fromAndToFormatting = (item) => {
    const splitItem = item?.split(' ');
    const defaults = ['approved', 'unapproved'];

    let value = item?.split(' : ')?.[1] || item?.split(' : ')?.[0] || item;

    defaults.forEach((def) => {
      if (splitItem?.includes(def)) {
        value = `${def[0].toUpperCase() + def.slice(1)}: ${
          splitItem[splitItem.length - 1]
        }`;
      }
    });

    return value;
  };

  if (loading) {
    content = (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  } else if (failed) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>Something went wrong! Please try again.</Trans>
      </Heading>
    );
  } else if (_isEmpty(data)) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>No data to display.</Trans>
      </Heading>
    );
  } else {
    content = (
      <Content>
        <Heading level={5}>
          <Trans>User Name - </Trans>
          <b> {data.username}</b>
        </Heading>
        <Heading level={5}>
          <Trans>Description - </Trans>
          <b>
            {' '}
            {data?.eventDescription?.split(':')?.[0] || data?.eventDescription}
          </b>
        </Heading>
        <Heading level={5}>
          <Trans>Date - </Trans>
          <b> {formatDate(data.auditDate)}</b>
        </Heading>
        <Heading level={5}>
          <Trans>Changes -</Trans>
        </Heading>
        <ChangesHolder>
          <Heading level={5}>
            <Trans>From: </Trans>
            <b> {data?.changes?.from?.map(fromAndToFormatting)?.join(', ')}</b>
          </Heading>
          <Heading level={5}>
            <Trans>To: </Trans>
            <b> {data?.changes?.to?.map(fromAndToFormatting)?.join(', ')}</b>
          </Heading>
        </ChangesHolder>
      </Content>
    );
  }

  return (
    <Row>
      <ContentHolder>{content}</ContentHolder>
    </Row>
  );
}

export default AuditDetailedTable;

const ContentHolder = styled.div`
  padding-top: 10px;
`;

const Content = styled.div`
  padding: 0 15px;
`;

const ChangesHolder = styled.div`
  padding: 0 15px;
`;
