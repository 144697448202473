import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  CustomMultiSelectInput,
} from 'components';

function AddEditGroupCompaniesModal(props) {
  const {
    item,
    loading,
    onSubmit,
    companies,
    closeModal,
    handleChange,
    selectedCompanies,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>
            {t`Edit`} <b>{item.name}</b> {t`companies`}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <InputHolder>
            <CustomMultiSelectInput
              modal
              id="company"
              label={t`Selected companies to be added to group`}
              loading={loading}
              options={companies}
              selected={selectedCompanies}
              onSelect={handleChange}
              placeholder={t`Please Select Company`}
            />
          </InputHolder>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditGroupCompaniesModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;
