import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import {
  Field,
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { isMappedToAnotherTerm } from 'services/helpers';
import { mapVatTransactionsFilter } from 'services/tableFilterShapes';
import { MapVATTransactionsColumns } from 'services/tableshapes';

function MapVATPaymentTransactionModal(props) {
  const {
    data,
    dates,
    period,
    loading,
    periodType,
    closeModal,
    getRowColor,
    handleAction,
    accountingYear,
    actionSubmitting,
    handleDateChange,
  } = props;

  const validData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.filter(
        (item) =>
          !isMappedToAnotherTerm(item, { accountingYear, period, periodType }),
      );
    }

    return data;
  }, [JSON.stringify(data), accountingYear, period, periodType]);

  const sortBy = useMemo(
    () => [
      {
        id: 'date',
        desc: false,
      },
    ],
    [],
  );

  const content = (
    <TableHolder>
      <CustomTableWithAutoSettings
        {...{
          data: validData,
          loading,
          getRowColor,
          handleAction,
          actionSubmitting,
          isModal: true,
          tableId: 'mapVatTransactions',
          columns: MapVATTransactionsColumns(),
          loadingId: 'transactionId',
          reportMode: false,
          filterConfigs: mapVatTransactionsFilter(),
          headerConfigs: {
            exportFileName: t`Map VAT Transactions`,
          },
          initialState: {
            sortBy,
          },
        }}
      />
    </TableHolder>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal} animation={false}>
      <Modal.Header>
        <HeaderContainer>
          <Heading level={3}>
            <Trans>Map VAT Transactions</Trans>
          </Heading>
          <FieldsHolder>
            <FieldHolder padding="right">
              <Field
                id="from"
                required
                max={dates.to}
                size="sm"
                type="date"
                value={dates.from}
                onChange={handleDateChange}
              />
            </FieldHolder>
            <span>to</span>
            <FieldHolder padding="left">
              <Field
                id="to"
                required
                min={dates.from}
                size="sm"
                type="date"
                value={dates.to}
                onChange={handleDateChange}
              />
            </FieldHolder>
          </FieldsHolder>
        </HeaderContainer>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill magnify kind="danger" onClick={closeModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MapVATPaymentTransactionModal;

MapVATPaymentTransactionModal.propTypes = {
  row: PropTypes.shape({}),
  year: PropTypes.string,
  data: PropTypes.arrayOf({}),
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  getRowColor: PropTypes.func,
  handleAction: PropTypes.func,
  handleShowAll: PropTypes.func,
  getTotalRowColor: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

MapVATPaymentTransactionModal.defaultProps = {
  row: {},
  year: undefined,
  data: [],
  loading: undefined,
  toggleModal: undefined,
  getRowColor: undefined,
  handleAction: () => {},
  handleShowAll: undefined,
  getTotalRowColor: undefined,
  actionSubmitting: {},
};

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: 1200px) {
      width: 1150px;
    }

    @media (min-width: 1366px) {
      width: 1250px;
    }

    width: 90vw;
  }
`;

const TableHolder = styled.div`
  margin-bottom: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FieldHolder = styled.div`
  padding-${(props) => props.padding}: 5px;
`;
