import { t } from '@lingui/macro';

const filterRows = (item, term) => item.term <= term;

export const outgoingBalanceFormatter = ({
  data,
  term,
  glTitle,
  subTabEntry,
  paymentTitle,
}) => {
  let settlementsRowsAltinnSum = 0;
  let settlementsRowsGLSum = 0;

  const closingBalances = data?.settlementsClosingBalances?.find(
    (el) => el.type === subTabEntry && el.term === term,
  );

  data?.payrollSettlements
    ?.filter((item) => filterRows(item, term))
    ?.forEach((item) => {
      const settlementItem =
        item.settlements.find(
          (settlement) => settlement.type === subTabEntry,
        ) || {};

      const isLastTerm = item.term === term;

      if (item.term !== 0) {
        settlementsRowsAltinnSum += settlementItem.reportedTotal;

        if (!isLastTerm) {
          settlementsRowsGLSum += settlementItem.glTotal;
        }
      }
    });

  return [
    {
      description: paymentTitle,
      amount: settlementsRowsGLSum - settlementsRowsAltinnSum,
    },
    {
      description: glTitle,
      amount: closingBalances?.amount,
    },
    {
      description: t`Deviation:`,
      subtitle: {
        exported: true,
      },
      amount: -(
        settlementsRowsGLSum -
        settlementsRowsAltinnSum -
        (closingBalances?.amount || 0)
      ),
    },
  ];
};

export default outgoingBalanceFormatter;
