import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import { BsFillFileEarmarkTextFill } from '@react-icons/all-files/bs/BsFillFileEarmarkTextFill'; // eslint-disable-line max-len

export function NothingToDisplay(props) {
  const { isModal } = props;

  return (
    <Container isModal={isModal} data-testid="nothing-container">
      <Icon />
      <Description isModal={isModal}>
        <Trans>
          Sorry, <strong>no results</strong> were found
        </Trans>
      </Description>
    </Container>
  );
}

NothingToDisplay.propTypes = {
  isModal: PropTypes.string,
};

NothingToDisplay.defaultProps = {
  isModal: false,
};

export default NothingToDisplay;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (!props.isModal ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.isModal ? '20px' : '100px 20px')};
  margin-top: 10px;
  gap: 10px;
  background-color: ${(props) => props.isModal && '#66615b0f'};
  border-radius: 8px;
`;

const Description = styled.div`
  font-size: ${(props) => (props.isModal ? '14px' : '16px')};
  color: rgb(102 97 91);
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const Icon = styled((props) => <BsFillFileEarmarkTextFill {...props} />)`
  font-size: ${(props) => (props.isModal ? '16px' : '18px')};
  color: rgb(102 97 91);
`;
