import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { VacationPayRowsModal } from 'components';

import { fromCompany } from 'store/selectors';
import { resourceUpdateRequest } from 'store/actions';
import {
  updateVacationPayMainPercentageApi,
  getVacationPayZonesApi,
} from 'services/apihelpers';

function VacationPayRowsModalContainer(props) {
  const {
    show,
    current,
    currentZone,
    period,
    periodType,
    year,
    toggleModal,
    refetch,
    reportId,
  } = props;
  const { currentCompanySID } = useSelector((state) =>
    fromCompany.getCompany(state),
  );
  const percentages = [
    {
      id: 'NORWAY_FOUR_WEEKS_UNDER_SIXTY',
      pct: 0.102,
      label: t`Four Weeks Vacation Pay (Under 60) – 10.2 %`,
    },
    {
      id: 'NORWAY_FIVE_WEEKS_UNDER_SIXTY',
      pct: 0.12,
      label: t`Five Weeks Vacation Pay (Under 60) – 12 %`,
    },
  ];
  const zones = [
    {
      id: 'ZONE_1',
      label: t`Zone 1`,
    },
    {
      id: 'ZONE_1A',
      label: t`Zone 1A`,
    },
    {
      id: 'ZONE_2',
      label: t`Zone 2`,
    },
    {
      id: 'ZONE_3',
      label: t`Zone 3`,
    },
    {
      id: 'ZONE_4',
      label: t`Zone 4`,
    },
    {
      id: 'ZONE_4A',
      label: t`Zone 4A`,
    },
    {
      id: 'ZONE_5',
      label: t`Zone 5`,
    },
  ];
  const dispatch = useDispatch();

  const [mainRow, setMainRow] = useState([percentages[0]]);
  const [zoneRow, setZoneRow] = useState([zones[0]]);

  const onSubmit = async () => {
    const query = {
      periodType,
      period,
      year,
      percentageEnum: mainRow[0].id,
    };
    const zoneQuery = {
      zone: zoneRow[0].id,
    };
    Promise.all([
      await dispatch(
        resourceUpdateRequest(
          updateVacationPayMainPercentageApi,
          currentCompanySID,
          query,
        ),
      ),
      await dispatch(
        resourceUpdateRequest(getVacationPayZonesApi, reportId, zoneQuery),
      ),
    ]);
    toggleModal();
    refetch();
  };
  const handleChange = (e, type) => {
    switch (type) {
      case 'main':
        setMainRow(e);
        break;
      case 'zone':
        setZoneRow(e);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const pct = percentages.find((el) => el.pct === current);
    const zone = zones.find((el) => el.id === currentZone);
    setMainRow([pct]);
    setZoneRow([zone]);
  }, [current, currentZone]);

  return (
    <VacationPayRowsModal
      {...{
        show,
        options: percentages,
        zones,
        mainRow,
        zoneRow,
        toggleModal,
        disabled: null,
        onSubmit,
        handleChange,
      }}
    />
  );
}

VacationPayRowsModalContainer.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

VacationPayRowsModalContainer.defaultProps = {
  show: false,
};

export default VacationPayRowsModalContainer;
