import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PayrollReportedSalaryDetails } from 'components';

import { resourceUpdateRequest } from 'store/actions';
import { editReportedSalaryInput } from 'services/apihelpers';
import { toPayrollReconciliation } from 'services/routehelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function PayrollReportedSalaryDetailsContainer(props) {
  const {
    search,
    company,
    accountingYear,
    payrollApproved,
    payrollApprovedLoading,
    reportedSalaryDetailsData,
    roleType: { isReadOnly },
  } = props;

  const { rows, loading, updateInput, predefinedTotal, additionalTotalRows } =
    reportedSalaryDetailsData;

  const { bimonthly, activePeriods, accumulated } = search;
  const history = useHistory();
  const dispatch = useDispatch();

  const [actionSubmitting, setActionSubmitting] = useState({});

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const editInputField = async (item) => {
    if (+item.value === item.row[item.id]) return;

    _setActionSubmitting(item.row.id, true);

    const query = {
      bimonthly,
      accumulated,
      id: item.row.id,
      period: bimonthly ? Math.ceil(activePeriods / 2) : +activePeriods,
      year: +accountingYear,
      companyId: company.uuid,
      accountId: item.row.account,
      inputC: item.row.inputC,
      inputD: item.row.inputD,
      [item.id]: +item.value,
    };

    await dispatch(resourceUpdateRequest(editReportedSalaryInput, null, query));
    await updateInput(item);

    _setActionSubmitting(item.id, true);
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellSave': {
          await editInputField(item);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    const roundedUpPeriod = bimonthly
      ? Math.ceil(activePeriods / 2) * 2
      : +activePeriods;

    if (bimonthly && roundedUpPeriod !== +activePeriods) {
      history.push(
        toPayrollReconciliation({
          ...search,
          activePeriods: roundedUpPeriod,
        }),
      );
    }
  }, [bimonthly]);

  return (
    <PayrollReportedSalaryDetails
      {...{
        rows,
        search,
        loading,
        isReadOnly,
        handleAction,
        accountingYear,
        predefinedTotal,
        actionSubmitting,
        additionalTotalRows,
        reportedSalaryDetailsData,
        payrollApproved: payrollApprovedLoading || payrollApproved,
      }}
    />
  );
}

export default PayrollReportedSalaryDetailsContainer;
