import React, { useEffect, useState } from 'react';
import _uniqBy from 'lodash-es/uniqBy';
import _isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AddEditGroupCompanyModal } from 'components';

import { fromResource } from 'store/selectors';
import {
  addGroupCompanyApi,
  editGroupCompanyApi,
  getGroupParentCompanyDetailsApi,
} from 'services/apihelpers';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';

function AddEditGroupCompanyModalContainer(props) {
  const { item, closeModal, roleType } = props;
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedCompanyGroups, setSelectedCompanyGroups] = useState([]);
  const data = useSelector((state) =>
    fromResource.getList(state, getGroupParentCompanyDetailsApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupParentCompanyDetailsApi}ListRead`),
  );
  let users;
  let companies;
  let userGroups;
  let companyGroups;
  let originalCompanies;

  const onSubmit = async (formData) => {
    const body = {
      name: formData.name,
      updatedUsers: selectedUsers.map((el) => el.id),
      updatedUserGroups: selectedUserGroups.map((el) => el.id),
      updatedCompanies: selectedCompanies.map((el) => el.id),
      updatedCompanyGroups: selectedCompanyGroups.map((el) => el.id),
    };
    let groupId;
    body.moveUsers = JSON.parse(formData.moveUsers || false);
    body.removeCompaniesFromGroup = JSON.parse(
      formData.removeCompaniesFromGroup || false,
    );

    if (item.groupId) {
      groupId = item.groupId;
      body.groupId = groupId;
      await dispatch(resourceUpdateRequest(editGroupCompanyApi, null, body));
    } else {
      body.usersFromParent = JSON.parse(formData.usersFromParent || false);
      body.companiesFromParent = JSON.parse(
        formData.companiesFromParent || false,
      );
      await dispatch(resourceCreateRequest(addGroupCompanyApi, body));
    }
    closeModal(false, true);
  };
  const handleChange = (values, type) => {
    if (type === 'user') {
      setSelectedUsers(values);
    } else if (type === 'userGroup') {
      setSelectedUserGroups(values);
    } else if (type === 'company') {
      setSelectedCompanies(values);
    } else if (type === 'companyGroups') {
      setSelectedCompanyGroups(values);
    }
  };
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getGroupParentCompanyDetailsApi, {
        groupId: item.groupId,
      }),
    );
  };

  if (_isEmpty(data)) {
    users = [];
    companies = [];
    userGroups = [];
    companyGroups = [];
  } else {
    users = _uniqBy(
      [...(data.addedUsers || []), ...(data.availableUsers || [])],
      'uuid',
    );
    users = users.map((c) => ({
      id: c.uuid,
      label: c.name,
    }));

    userGroups = _uniqBy(
      [...(data.addedUserGroups || []), ...(data.availableUserGroups || [])],
      'groupId',
    );
    userGroups = userGroups.map((c) => ({
      id: c.groupId,
      label: c.name,
    }));

    originalCompanies = _uniqBy(
      [...(data.addedCompanies || []), ...(data.availableCompanies || [])],
      'uuid',
    );
    companies = originalCompanies.map((c) => ({
      id: c.uuid,
      label: `${c.companyName} - ${c?.registrationNumber}`,
    }));

    companyGroups = _uniqBy(
      [
        ...(data.addedCompanyGroups || []),
        ...(data.availableCompanyGroups || []),
      ],
      'groupId',
    );
    companyGroups = companyGroups.map((c) => ({
      id: c.groupId,
      label: c.name,
    }));
  }

  useEffect(fetchData, []);
  useEffect(() => {
    const newCompanies = data?.addedCompanies
      ? data?.addedCompanies.map((c) => ({
          id: c.uuid,
          label: c.companyName,
        }))
      : [];

    if (JSON.stringify(newCompanies) !== JSON.stringify(selectedCompanies)) {
      setSelectedCompanies(newCompanies);
    }
  }, [JSON.stringify(data?.addedCompanies || [])]);
  useEffect(() => {
    const newCompanyGroups = data?.addedCompanyGroups
      ? data?.addedCompanyGroups.map((c) => ({
          id: c.groupId,
          label: c.name,
        }))
      : [];

    if (
      JSON.stringify(newCompanyGroups) !== JSON.stringify(selectedCompanyGroups)
    ) {
      setSelectedCompanyGroups(newCompanyGroups);
    }
  }, [JSON.stringify(data?.addedCompanyGroups || [])]);
  useEffect(() => {
    const newUsers = data?.addedUsers
      ? data?.addedUsers.map((c) => ({
          id: c.uuid,
          label: c.name,
        }))
      : [];

    if (JSON.stringify(newUsers) !== JSON.stringify(selectedUsers)) {
      setSelectedUsers(newUsers);
    }
  }, [JSON.stringify(data?.addedUsers || [])]);
  useEffect(() => {
    const newUserGroups = data?.addedUserGroups
      ? data?.addedUserGroups.map((c) => ({
          id: c.groupId,
          label: c.name,
        }))
      : [];

    if (JSON.stringify(newUserGroups) !== JSON.stringify(selectedUserGroups)) {
      setSelectedUserGroups(newUserGroups);
    }
  }, [JSON.stringify(data?.addedUserGroups || [])]);

  return (
    <AddEditGroupCompanyModal
      {...{
        item,
        users,
        loading,
        roleType,
        companies,
        userGroups,
        closeModal,
        selectedUsers,
        companyGroups,
        originalCompanies,
        selectedCompanies,
        selectedUserGroups,
        setSelectedCompanies,
        selectedCompanyGroups,
        onSubmit,
        handleChange,
      }}
    />
  );
}

AddEditGroupCompanyModalContainer.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
};

AddEditGroupCompanyModalContainer.defaultProps = {
  show: false,
  item: {},
};

export default AddEditGroupCompanyModalContainer;
