import React from 'react';
import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import { Modal as BootstrapModal, ButtonGroup, Col } from 'react-bootstrap';

import { Heading, Button, CustomTable } from 'components';

import {
  matchedTransactionsGLModal,
  matchedTransactionsBankModal,
} from 'services/tableshapes';

import { DESKTOP } from 'constants/mediaSizes';

function ReconciledTransactionsModal(props) {
  const { closeModal, dataForModal, handleUnreconcile } = props;

  const unreconcileTransactions = () => {
    closeModal();
    handleUnreconcile('gl', dataForModal.glTransactions[0].lineId);
  };

  return (
    <Modal show bsSize="xl">
      <Modal.Header>
        <Heading level={3}>Matched Items</Heading>
      </Modal.Header>
      <Modal.Body>
        <Title>
          <Trans>Match ID: {dataForModal.id}</Trans>
        </Title>
        <TableWrapper>
          <Col sm={6}>
            <Header level={4} alignment="center">
              <Trans>GL Transactions</Trans>
            </Header>
            <CustomTable
              {...{
                data: dataForModal.glTransactions,
                isModal: true,
                hideSelection: true,
                hideFilters: true,
                tableId: 'matchedTransactionsGLModal',
                columns: matchedTransactionsGLModal(),
              }}
            />
          </Col>
          <Col sm={6}>
            <Header level={4} alignment="center">
              <Trans>Bank Transactions</Trans>
            </Header>
            <CustomTable
              {...{
                data: dataForModal.bankTransactions,
                isModal: true,
                hideFilters: true,
                hideSelection: true,
                tableId: 'matchedTransactionsBankModal',
                columns: matchedTransactionsBankModal(),
              }}
            />
          </Col>
        </TableWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Group>
          <ButtonGroup>
            <Button
              fill
              kind="danger"
              magnify
              onClick={unreconcileTransactions}
            >
              <Trans>Unmatch Transactions</Trans>
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Group>
      </Modal.Footer>
    </Modal>
  );
}

export default ReconciledTransactionsModal;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1200px;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Group = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const Header = styled((props) => <Heading {...props} />)`
  margin: 0 0 22px;
`;
