import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getPayrollSocialSecurityApi } from 'services/apihelpers';
// eslint-disable-next-line max-len
import { balanceReconciliationFormatter } from 'services/dataFormatters/payroll';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useSocialSecurityData = (props) => {
  const { uuid, bimonthly, accumulated, activePeriods, accountingYear } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data =
    useSelector((state) =>
      fromResource.getList(state, getPayrollSocialSecurityApi),
    )?.[0] || {};

  const rows = useMemo(
    () =>
      balanceReconciliationFormatter({
        data,
        bimonthly,
        accumulated,
        activePeriods,
      }),
    [data, bimonthly, accumulated, activePeriods],
  );

  const fetchData = async () => {
    setLoading(() => true);
    try {
      await dispatch(
        resourceListReadRequest(getPayrollSocialSecurityApi, {
          companyId: uuid,
          year: accountingYear,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [uuid, accountingYear]);

  return { data, loading, fetchData, rows };
};

export default useSocialSecurityData;
