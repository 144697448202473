import React from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

import { Button, Heading, Field, Spinner, ButtonIcon } from 'components';

function AddEditGroupCompaniesModal(props) {
  const {
    lines,
    addLine,
    onSubmit,
    removeLine,
    submitting,
    closeModal,
    handleChange,
  } = props;

  const renderLine = (item, index) => (
    <LineHolder key={`line-item-${item.id}`}>
      <FieldHolder>
        <Field
          size="sm"
          type="number"
          name={`line-item-amount-${item.id}`}
          label={t`Amount`}
          value={item.amount}
          onChange={(e) => handleChange(e.target.value, 'amount', index)}
          placeholder={t`Please enter amount`}
        />
      </FieldHolder>
      <FieldHolder>
        <Field
          size="sm"
          type="date"
          name={`line-item-postedDate-${item.id}`}
          label={t`Bank Date`}
          value={item.postedDate?.slice(0, 10)}
          onChange={(e) => handleChange(e.target.value, 'postedDate', index)}
          placeholder={t`Please enter date`}
        />
      </FieldHolder>
      <FieldHolder>
        <Field
          size="sm"
          type="description"
          name={`line-item-description-${item.id}`}
          label={t`Description`}
          value={item.description}
          onChange={(e) => handleChange(e.target.value, 'description', index)}
          placeholder={t`Please enter description`}
        />
      </FieldHolder>
      <ButtonGroup bsSize="small">
        <Button magnify onClick={() => removeLine(index)}>
          <ButtonIcon as={BiTrash} /> <Trans>Delete line</Trans>
        </Button>
      </ButtonGroup>
    </LineHolder>
  );

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="lg">
      <Modal.Header>
        <Heading level={3}>{t`Add bank lines`}</Heading>
      </Modal.Header>
      <Modal.Body>
        <ButtonHolder>
          <ButtonGroup bsSize="small">
            <Button fill magnify kind="default" onClick={addLine}>
              <ButtonIcon as={HiPlus} /> <Trans>Add line</Trans>
            </Button>
          </ButtonGroup>
        </ButtonHolder>
        {lines.map(renderLine)}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="info"
            onClick={onSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
              </>
            )}
          </Button>
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditGroupCompaniesModal;

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LineHolder = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const FieldHolder = styled.div`
  width: 200px;
  margin-right: 20px;
`;
