import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AltinnConfigModal } from 'components';

import { formatDateByMonth } from 'services/helpers';
import { fromAuth, fromCompany } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';
import {
  altinnFetchApi,
  altinnAuthenticateApi,
  retrieveAltinnSessionApi,
  validateAltinnSessionApi,
  altinnAuthenticatePinApi,
  updateCompanyListAltinnApi,
  fetchVatReportsFromAltinnApi,
  updateCompanyListAltinnVATApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function AltinnConfigModalContainer(props) {
  const {
    mode,
    periods,
    toPeriod,
    bimonthly,
    subTabEntry,
    singlePeriod,
    altinnSignIn,
    toggleWindow,
    selectedCompanyIdRows,
  } = props;
  const { accountingPeriod } = useParams();

  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(1)).toISOString(),
  );
  const [initialState, setInitialState] = useState(true);
  const [sessionValidate, setSessionValidate] = useState(false);
  const [periodSelectPerRow, setPeriodSelectPerRow] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [altinnFetchDetails, setAltinnFetchDetails] = useState(false);
  const [altinnUserCredentials, setAltinnUserCredentials] = useState(false);
  const [initialResponse, setInitialResponse] = useState(null);
  const [authenticateResponse, setAuthenticateResponse] = useState(null);

  const [error, setError] = useState('');
  const [activePeriods, setActivePeriods] = useState(props.activePeriods || 12);

  const [selectedRows, setSelectedRows] = useState([]);
  const [altinnUpdatedDataList, setAltinnUpdatedDataList] = useState([]);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const loading = useSelector((state) =>
    pending(state, `${validateAltinnSessionApi}ListRead`),
  );

  const dispatch = useDispatch();

  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);

  const setData = async () => {
    const { currentCompanySID, currentAccountingYear } = company;

    const query = {
      year: currentAccountingYear,
      companyId: currentCompanySID,
      templateType:
        mode === 'company' || mode === 'organization' ? 'PAYROLL' : 'VAT',
    };

    const _sessionValidate = await dispatch(
      resourceCreateRequest(validateAltinnSessionApi, query),
    );

    setFromDate(formatDateByMonth(currentAccountingYear).toISOString());
    setInitialState(_sessionValidate.sessionActive === false);
    setSessionValidate(_sessionValidate.sessionActive);
    setAltinnUpdatedDataList(_sessionValidate.altinnUpdatedDataList);
  };

  const handleSelectAll = (items) => {
    const selectedRowsItems = new Map();

    items.forEach((row) => {
      const { period } = row;

      selectedRowsItems.set(period, {
        period,
      });
    });

    setSelectedRows(Array.from(selectedRowsItems));
  };

  const handleChange = (item) => {
    const { period } = item;

    const selectedRowsItems = new Map(selectedRows);
    if (selectedRowsItems.has(period)) {
      selectedRowsItems.delete(period);
    } else {
      selectedRowsItems.set(period, {
        period,
      });
    }
    setSelectedRows(Array.from(selectedRowsItems));
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowSelect': {
          if (Array.isArray(item.item)) {
            handleSelectAll(item.item);
          } else if (item.item === 'all') {
            setSelectedRows([]);
          } else {
            handleChange(item.item);
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const updateCompanyListAltinnVAT = (query) =>
    dispatch(resourceCreateRequest(updateCompanyListAltinnVATApi, query));

  const updateCompanyListAltinn = (query) =>
    dispatch(resourceCreateRequest(updateCompanyListAltinnApi, query));

  const onSubmit = async (values) => {
    const { ssn, otp, altinnUserPassword } = values;

    const selectedPeriod =
      mode === 'company' ? activePeriods : accountingPeriod;

    let _authenticateResponse;
    if (initialState) {
      try {
        setAltinnUserCredentials(true);

        const _initialResponse = await dispatch(
          resourceCreateRequest(altinnAuthenticateApi, {
            userSSN: ssn,
            password: altinnUserPassword,
            companyId: company.currentCompanySID,
          }),
        );

        if (_initialResponse.status === 'InvalidCredentials') {
          setInitialResponse(_initialResponse);
          setAltinnUserCredentials(false);
        } else {
          setAltinnUserCredentials(false);
          setInitialState(false);
          setInitialResponse(_initialResponse);
        }
      } catch (e) {
        setAltinnUserCredentials(false);
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    } else {
      const periodList = [];

      if (periodSelectPerRow) {
        const selectedRowItems = new Map(selectedRows);
        selectedRowItems.forEach((item) =>
          periodList.push(parseInt(item.period, 10)),
        );
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < +selectedPeriod; i++) {
          periodList[i] = i + 1;
        }
      }

      try {
        setAltinnFetchDetails(true);

        const generalQuery = {
          fromDate,
          periodList,
          toDate: new Date().toISOString(),
          year: company.currentAccountingYear,
          userSSN: ssn || '',
          password: altinnUserPassword,
          companyId: company.currentCompanySID,
          isForceUpdate: true,
          isActiveSession: !!sessionValidate,
          altinnAuthenticatCode: otp || '',
        };

        if (mode === 'company') {
          await dispatch(
            resourceCreateRequest(altinnAuthenticatePinApi, {
              pin: otp || '',
              userSSN: ssn,
              password: altinnUserPassword,
              companyId: company.currentCompanySID,
              forceUpdate: true,
            }),
          );
          _authenticateResponse = await dispatch(
            resourceCreateRequest(altinnFetchApi, generalQuery),
          );
        } else if (mode === 'organization') {
          const companyIdList = [];
          const periodValues = periods.map((period) => period + 1);
          const selectedCompanyIdRowItems = new Map(selectedCompanyIdRows);
          selectedCompanyIdRowItems.forEach((item) =>
            companyIdList.push(parseInt(item.companyId, 10)),
          );

          const organizationSid = user.organizationSID;
          const query = {
            altinnAuthenticateCode: {
              ...generalQuery,
              periodList: singlePeriod ? [toPeriod] : periodValues,
              organizationSid,
            },
            companyIdList,
          };
          const fetchFunc =
            subTabEntry === 'vat'
              ? updateCompanyListAltinnVAT
              : updateCompanyListAltinn;

          _authenticateResponse = await fetchFunc(query);
        } else if (mode === 'VAT') {
          _authenticateResponse = await dispatch(
            resourceCreateRequest(fetchVatReportsFromAltinnApi, generalQuery),
          );
        }

        if (_authenticateResponse) {
          setAltinnFetchDetails(false);
          setFetchSuccess(true);
          setAuthenticateResponse(_authenticateResponse);
        }
      } catch (e) {
        setFetchSuccess(false);
        setAltinnFetchDetails(false);
        setAuthenticateResponse(_authenticateResponse);
        setError(
          e.response &&
            e.response.headers &&
            e.response.headers.get('Response-Message'),
        );
      }
    }
  };

  const handlePeriodChange = (e) => {
    const _activePeriods = parseInt(e.target.getAttribute('id'), 10);
    setActivePeriods(bimonthly ? _activePeriods * 2 : _activePeriods);
  };

  const handlePeriodSelectPerRow = () => {
    setPeriodSelectPerRow(!periodSelectPerRow);
    setSelectedRows([]);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    if (authenticateResponse?.status) {
      dispatch(
        resourceCreateRequest(retrieveAltinnSessionApi, {
          companySID: company.currentCompanySID,
        }),
      );
    }
  }, [authenticateResponse?.status]);

  return (
    <AltinnConfigModal
      {...{
        mode,
        error,
        company,
        loading,
        bimonthly,
        onSubmit,
        handleAction,
        toggleWindow,
        altinnSignIn,
        initialState,
        fetchSuccess,
        activePeriods,
        initialResponse,
        sessionValidate,
        actionSubmitting,
        altinnFetchDetails,
        periodSelectPerRow,
        handlePeriodChange,
        authenticateResponse,
        altinnUserCredentials,
        altinnUpdatedDataList,
        handlePeriodSelectPerRow,
      }}
    />
  );
}

export default AltinnConfigModalContainer;

AltinnConfigModalContainer.propTypes = {
  mode: PropTypes.string,
  altinnSignIn: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedCompanyIdRows: PropTypes.shape({}).isRequired,
  toPeriod: PropTypes.number,
  singlePeriod: PropTypes.bool,
  toggleWindow: PropTypes.bool.isRequired,
  activePeriods: PropTypes.number,
  bimonthly: PropTypes.bool,
  subTabEntry: PropTypes.string,
};

AltinnConfigModalContainer.defaultProps = {
  mode: 'company',
  toPeriod: 1,
  activePeriods: 12,
  bimonthly: false,
  singlePeriod: false,
  subTabEntry: undefined,
};
