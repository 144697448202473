const initialState = {
  connectionMessage: '',
  downloadProgress: null,
  errorMessage: null,
  hasUpdatedBalance: false,
};

// eslint-disable-next-line default-param-last
const statusUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONNECTION_STATUS':
      return { ...state, connectionMessage: action.payload };
    case 'DOWNLOAD_PROGRESS':
      return { ...state, downloadProgress: action.payload };
    case 'ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'CLEAR_ERROR_MESSAGE':
      return { ...state, errorMessage: null };
    case 'HAS_UPDATED_BALANCE':
      return { ...state, hasUpdatedBalance: true };
    default:
      return state;
  }
};

export default statusUpdateReducer;
