import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { push } from 'redux-first-history';

import { LoadingScreen } from 'components';

import { checkIfTestStagingEnv } from 'services/helpers';
import { fromAuth, fromCompany, fromRoot } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { checkIsUserFromOrg } from 'services/apihelpers';
import { decodeUrl } from 'services/routehelpers';
import { period } from 'services/helpers/periods';
import { loginRequest } from '../store/auth/actions';

import App from './App';

function LoginAgain() {
  const needsLogin = useSelector(fromRoot.needsLogin);
  const { login } = useOidc();

  useEffect(() => {
    if (needsLogin) {
      login();
    }
  }, [needsLogin]);

  return null;
}

function ConnectedApp(props) {
  const dispatch = useDispatch();
  const metaUserData = useSelector(fromAuth.getMetaUserData);
  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const appReady = props.isAppReady;
  const { accessTokenPayload } = useOidcAccessToken();
  const { logout } = useOidc();
  const { pathname } = window.location;
  const [companyUuid, setCompanyUuid] = useState();

  useEffect(() => {
    if (pathname.split('/')[1] === 'client') {
      setCompanyUuid(pathname.split('/')[2]);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !company ||
      !company.currentPeriodType ||
      pathname.split('/')[1] !== 'client'
    )
      return;

    const { step } = period(company.currentPeriodType || 'monthly');
    const currentUrl = decodeUrl(window.location.pathname);
    const adjustedCurrentPeriod = Math.ceil(+currentUrl.period / step) * step;

    if (
      !Number.isNaN(adjustedCurrentPeriod) &&
      adjustedCurrentPeriod !== currentUrl.period
    ) {
      const searchParams = window.location.search;
      const oldUrl = window.location.pathname.split('/');
      const newUrl = [
        ...oldUrl.slice(0, 4),
        adjustedCurrentPeriod.toString(),
        ...oldUrl.slice(5),
      ];
      const oldPath = window.location.pathname + searchParams;
      const newPath = newUrl.join('/') + searchParams;

      if (oldPath !== newPath) dispatch(push(newPath));
    }
  }, [company, pathname, dispatch]);

  /**
   * Logs out the user if they are not a developer and are in a test/staging environment.
   * Does not log out the user if they are in localhost.
   */
  useEffect(() => {
    if (
      ['sandbox'].includes(checkIfTestStagingEnv()) &&
      !accessTokenPayload?.realm_access?.roles.includes('Developers')
    ) {
      logout();
    }
  }, [accessTokenPayload]);

  useEffect(() => {
    if (
      window.location.pathname.split('/')[1] === 'client' &&
      window.location.pathname.split('/')[2]
    ) {
      dispatch(loginRequest(decodeUrl(window.location.pathname)));
    } else {
      dispatch(loginRequest());
    }
  }, []);

  const { boot, shutdown } = useIntercom();

  const checkIfUserFromOrg = async () => {
    try {
      await dispatch(
        resourceListReadRequest(checkIsUserFromOrg, {
          companyId: companyUuid ?? undefined,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  useEffect(() => {
    if (metaUserData?.id) {
      checkIfUserFromOrg();
    }

    if (checkIfTestStagingEnv(window.location.href) || !metaUserData?.id) {
      shutdown();
    } else {
      boot({
        name: metaUserData.displayName,
        email: metaUserData.key,
        customAttributes: {
          user_id: metaUserData.id,
          user_hash: metaUserData.intercomUserHash,
          created_at: metaUserData.createdAtUnixTimestamp,
        },
      });
    }
  }, [metaUserData?.intercomUserHash]);

  if (user?.currentCompanyId === 0) {
    dispatch(push('/select-company'));
  }

  return appReady ? (
    <>
      <LoginAgain />
      <App {...props} />
    </>
  ) : (
    <LoadingScreen />
  );
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  isAppReady: fromRoot.isAppReady(state),
  isTimeout: fromAuth.getTimeoutStatus(state),
});

ConnectedApp.propTypes = {
  children: PropTypes.node,
};

ConnectedApp.defaultProps = {
  children: null,
};

export default connect(mapStateToProps)(ConnectedApp);
