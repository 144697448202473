import React, { useState } from 'react';
import _uniqBy from 'lodash-es/uniqBy';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AddEditGroupUsersModal } from 'components';

import { resourceUpdateRequest } from 'store/actions';
import { setGroupParentUserApi } from 'services/apihelpers';

function AddEditGroupUsersModalContainer(props) {
  const { item, closeModal, refetch } = props;
  const dispatch = useDispatch();
  const addedCompanies = item?.addedUsers
    ? item?.addedUsers.map((c) => ({
        id: c.uuid,
        label: c.name,
      }))
    : [];
  let users = _uniqBy(
    [...(item.addedUsers || []), ...(item.availableUsers || [])],
    'uuid',
  );
  users = users.map((c) => ({
    id: c.uuid,
    label: c.name,
  }));
  const [selectedUsers, setSelectedUsers] = useState(addedCompanies);

  const onSubmit = async () => {
    // set users to the group

    await dispatch(
      resourceUpdateRequest(setGroupParentUserApi, null, {
        groupId: item.groupId,
        createGroup: !item.groupId,
        updatedUsers: selectedUsers.map((el) => el.id),
      }),
    );

    closeModal();
    refetch();
  };
  const handleChange = (values, type) => {
    if (type === 'user') {
      setSelectedUsers(values);
    }
  };

  return (
    <AddEditGroupUsersModal
      {...{
        item,
        users,
        closeModal,
        selectedUsers,
        onSubmit,
        handleChange,
      }}
    />
  );
}

AddEditGroupUsersModalContainer.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
};

AddEditGroupUsersModalContainer.defaultProps = {
  show: false,
  item: {},
};

export default AddEditGroupUsersModalContainer;
