import StackdriverErrorReporter from 'stackdriver-errors-js';

const environment = process.env.BALANCES_ENV;
const key = process.env.ERROR_REPORTING_API_KEY;
const projectId = process.env.GOOGLE_PROJECT_ID;
const version = process.env.BALANCES_VERSION;

// eslint-disable-next-line
let errorHandler;

if (environment && process.env.NODE_ENV === 'production') {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key,
    projectId,
    service: `web-${environment}`,
    version,
  });
} else {
  // eslint-disable-next-line
  errorHandler = { report: console.error };
}

export default errorHandler;
