function commentsQueryGenerator(
  modalName,
  id,
  company = {},
  comment = '',
  user = {},
  showAllComments = false,
) {
  let lineLevelQuery;
  let accountLevelQuery;
  let vatQuery;

  switch (modalName) {
    case 'lineLevelComment':
      lineLevelQuery = {
        getComments: {
          recordId: id,
          companyId: company.currentCompanySID,
        },
        submitComment: {
          companyId: company.currentCompanySID,
          lineDialogue: [
            {
              recordId: id,
              comment,
            },
          ],
        },
      };

      return lineLevelQuery;

    case 'accountLevelComment':
      accountLevelQuery = {
        getComments: {
          account: id,
          companyId: company.currentCompanySID,
          fromPeriod: company.currentWorkingPeriodStart,
          periodType: company.currentPeriodType,
          periodYear: company.currentAccountingYear,
          toPeriod: company.currentWorkingPeriodEnd,
          showAll: showAllComments,
        },
        submitComment: {
          account: id,
          accountDialogue: [
            {
              comment,
            },
          ],
          companyId: company.currentCompanySID,
          fromPeriod: company.currentWorkingPeriodStart,
          periodType: company.currentPeriodType,
          periodYear: company.currentAccountingYear,
          toPeriod: company.currentWorkingPeriodEnd,
        },
      };

      return accountLevelQuery;
    case 'payrollComment':
      accountLevelQuery = {
        getComments: {
          companyId: company.currentCompanySID,
          year: company.currentAccountingYear,
          period: company.currentWorkingPeriodEnd,
          name: user.username,
          showAll: showAllComments,
        },
        submitComment: {
          companyId: company.currentCompanySID,
          name: user.username,
          text: comment,
          year: parseInt(company.currentAccountingYear, 10),
          period: company.currentWorkingPeriodEnd,
        },
      };

      return accountLevelQuery;

    case 'vatComment':
      vatQuery = {
        getComments: {
          companyId: company.currentCompanySID,
          year: company.currentAccountingYear,
          period: company.currentWorkingPeriodEnd,
          name: user.username,
          showAll: showAllComments,
        },
        submitComment: {
          companyId: company.currentCompanySID,
          name: user.username,
          text: comment,
          period: company.currentWorkingPeriodEnd,
          year: parseInt(company.currentAccountingYear, 10),
        },
      };
      return vatQuery;

    default:
      return {};
  }
}

export default commentsQueryGenerator;
