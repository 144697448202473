import { t } from '@lingui/macro';

export const payrollSettingsMappingFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['accountID', 'accountDescription', 'payRollReportItemSID'],
  },
];

export default payrollSettingsMappingFilters;
