import { t } from '@lingui/macro';
import { months } from 'constants/shortNames';
import { simpleLedgerColumns } from 'services/tableshapes';
import { defineFrequencyPeriodTypes } from 'services/helpers';

import { filterByTemplateStatuses, periodTypes } from 'services/shapes';

import useReportGenerator, {
  generatePDF,
} from './generator/useReportGenerator';

const columns = simpleLedgerColumns();

const periodNames = months();

export const getSLReportSettings = ({
  rows,
  loading,
  account,
  getFiles,
  accountInfo,
  accountName,
  approveStatus,
  accountingYear,
  accountingPeriod,
  currentPeriodType,
  tableLevelApproved,
  ...rest
}) => {
  const formattedPeriod = defineFrequencyPeriodTypes(
    currentPeriodType,
    accountingPeriod,
  );

  const periodName = `${periodNames[currentPeriodType]} ${formattedPeriod}`;

  const frequency = periodTypes().find(
    (item) => item.id === accountInfo?.periodType,
  )?.label;
  const template = filterByTemplateStatuses().find(
    (item) => item.id === accountInfo?.accountTemplateCode,
  )?.label;

  const comments = [];

  if (accountInfo?.comment) {
    comments.push({
      title: t`Status Comment`,
      value: accountInfo?.comment,
    });
  }

  if (accountInfo?.periodComment) {
    comments.push({
      title: t`Period Comment`,
      value: accountInfo?.periodComment,
    });
  }

  return {
    ...rest,
    loading,
    getFiles,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      accountId: account,
      reportType: 'ACCOUNTS',
      saveReport: true,
    },
    fileName: `${account} - ${accountName}(${periodName}, ${accountingYear})`,
    isApproved: approveStatus,
    data: [
      [
        {
          accountInfo: true,
          headerMeta: {
            period: `${periodName}, ${accountingYear}`,
            title: ' ',
            approveStatus: approveStatus || tableLevelApproved,
          },
          values: [
            [
              {
                title: t`Template`,
                value: template,
              },
              {
                title: t`Frequency`,
                value: frequency,
              },
              {
                title: t`Assignee`,
                value: accountInfo?.accountHolder,
              },
            ],
            [
              {
                title: t`Year Opening Balance`,
                value: accountInfo?.openingBalance,
                number: true,
              },
              {
                title: t`Period Opening Balance`,
                value: accountInfo?.ingoingBalance,
                number: true,
              },
              {
                title: t`Period Movement`,
                value: accountInfo?.movement,
                number: true,
              },
              {
                title: t`Period Closing Balance`,
                value: accountInfo?.outgoingBalance,
                number: true,
              },
            ],
            ...(comments.length ? [comments] : []),
          ],
        },
        {
          tableLevelApproved,
          period: `${periodName}, ${accountingYear}`,
          rows: rows.filter((row) => typeof row.rowId !== 'number'),
          withGroups: true,
          groupId: 'group',
          title: `${account} - ${accountName}`,
          tableShape: columns,
        },
      ],
    ],
  };
};

export const useSimpleLedgerReportGenerator = (props) =>
  useReportGenerator(getSLReportSettings(props));

export const generateSLReport = async (props) => {
  await generatePDF(getSLReportSettings(props));
};

export default useSimpleLedgerReportGenerator;
