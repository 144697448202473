import { t } from '@lingui/macro';

/**
 * Payroll Settings Mapping Columns
 */
export const columns = ({ reportMode, collections }) => [
  {
    width: 110,
    Header: t`Account`,
    accessor: 'accountID',
    className: '',
  },
  {
    width: reportMode ? 480 : 730,
    Header: t`Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 480 : 600,
    Header: t`Payroll report item`,
    accessor: 'payRollReportItemSID',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      converter: (_, item) => {
        const selectedItem = collections.payRollReportItemSID?.options?.find(
          (acc) => acc.id === item.payRollReportItemSID,
        );

        if (selectedItem) {
          return selectedItem.label;
        }

        return '';
      },
      editable: !reportMode,
      disableClick: true,
      fieldOptions: {
        id: 'payRollReportItemSID',
        single: true,
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Payroll report item`,
        ...collections.payRollReportItemSID,
      },
    },
  },
];

export default columns;
