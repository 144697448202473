import { t } from '@lingui/macro';

/**
 * User Preview Columns
 */
export const AvailableUserCompaniesColumns = ({ reportMode }) => [
  {
    width: reportMode ? 240 : 360,
    Header: t`Registration number`,
    accessor: 'registrationNumber',
    className: '',
  },
  {
    width: reportMode ? 380 : 600,
    Header: t`Name`,
    accessor: 'companyName',
    className: '',
    withTooltip: true,
  },
];

export const AssociatedUserGroupsColumns = ({ reportMode }) => [
  {
    width: reportMode ? 240 : 360,
    Header: t`Group ID`,
    accessor: 'groupId',
    sortType: 'basic',
    className: '',
  },
  {
    width: reportMode ? 380 : 600,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: true,
  },
];

export default {
  AvailableUserCompaniesColumns,
  AssociatedUserGroupsColumns,
};
