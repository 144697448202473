import cx from 'classnames';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fromAuth } from 'store/selectors';
import { getUrlWithPrefilledParams } from 'services/routehelpers';

export function AlgoliaHit(props) {
  const { hit, onClose, selected } = props;
  const history = useHistory();
  const user = useSelector(fromAuth.getUser);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && selected) {
      history.push(hit.link);
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Link
      to={getUrlWithPrefilledParams(hit.link)}
      onClick={onClose}
      className={cx({ selected })}
    >
      {hit.name[user.languageCode]}
    </Link>
  );
}

AlgoliaHit.propTypes = {
  hit: PropTypes.shape({}),
  onClose: PropTypes.func,
  selected: PropTypes.bool,
};

AlgoliaHit.defaultProps = {
  hit: {},
  onClose: () => {},
  selected: undefined,
};

export default AlgoliaHit;
