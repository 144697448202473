import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { PayrollReportedSalary } from 'components';

import { toPayrollReconciliation } from 'services/routehelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function PayrollReportedSalaryContainer(props) {
  const {
    search,
    activePeriods,
    handleRowClick,
    payrollApproved,
    reportedSalaryData,
    handleCommentChange,
  } = props;
  const history = useHistory();
  const { bimonthly } = search;
  const { loading, rows, updateComment, manual } = reportedSalaryData;

  const [actionSubmitting, setActionSubmitting] = useState({});

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const changeComment = async (row, value) => {
    await handleCommentChange(value, row);
    await updateComment(row, value);
  };

  const handleAction = async (action, item, column) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellClick': {
          if (!item.subtitle) {
            if (['a07Reported', 'glreported', 'deviation'].includes(column)) {
              handleRowClick(item, 1, manual);
            } else {
              handleRowClick(item, 2, manual);
            }
          }
          break;
        }

        case 'cellSave': {
          _setActionSubmitting(item.row.rowNumber, true);

          if (item.row.comment !== item.value) {
            await changeComment(item.row, item.value);
          }

          _setActionSubmitting(item.row.rowNumber, false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    const roundedUpPeriod = bimonthly
      ? Math.ceil(activePeriods / 2) * 2
      : +activePeriods;

    if (bimonthly && roundedUpPeriod !== +activePeriods) {
      history.push(
        toPayrollReconciliation({
          ...search,
          activePeriods: roundedUpPeriod,
        }),
      );
    }
  }, [bimonthly]);

  return (
    <PayrollReportedSalary
      {...{
        rows,
        manual,
        loading,
        handleAction,
        payrollApproved,
        actionSubmitting,
      }}
    />
  );
}

export default PayrollReportedSalaryContainer;
