import React from 'react';
import { BasicTemplate } from 'components';
import InvalidateLinkForm from 'containers/InvalidateLinkForm';

/**
 * Reset Password Page component
 */
function InvalidateLinkPage(props) {
  return (
    <BasicTemplate {...props}>
      <InvalidateLinkForm {...props} />
    </BasicTemplate>
  );
}

export default InvalidateLinkPage;
