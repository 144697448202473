import React from 'react';
import AccountReconciliationTable from 'containers/AccountReconciliationTable';

/**
 * Account Reconciliation Page Component
 */
function AccountReconciliationPage(props) {
  return <AccountReconciliationTable {...props} />;
}
export default AccountReconciliationPage;
