import React from 'react';
import styled from 'styled-components/macro';
import BlockContent from '@sanity/block-content-to-react';
import { MdClose } from '@react-icons/all-files/md/MdClose';

import { getDataSet } from 'services/utils/sanity';

function getContrastYIQ(hexcolor) {
  if (!hexcolor) {
    return '#000';
  }
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}

export default function MaintenanceBanner(props) {
  const { bannerColor, message, handleDismiss } = props;

  return message ? (
    <MaintenanceBannerContainer backgroundColor={bannerColor}>
      <MaintenanceBannerText backgroundColor={bannerColor}>
        <BlockContent
          blocks={message?.body}
          dataset={getDataSet()}
          projectId={process.env.SANITY_PROJECT_ID}
        />
      </MaintenanceBannerText>
      <DismissButton onClick={() => handleDismiss()} type="button">
        <MdClose size={16} color={getContrastYIQ(bannerColor)} />
      </DismissButton>
    </MaintenanceBannerContainer>
  ) : null;
}

const MaintenanceBannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 12px 0 4px 0;
  box-sizing: border-box;
`;

const MaintenanceBannerText = styled.p`
  font-weight: 500;
  color: ${(props) => getContrastYIQ(props.backgroundColor)};
  text-align: center;
  line-height: 1.5;
  margin: 0 10px;
`;

// DismissButton should be at the end of the flex
const DismissButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
`;
