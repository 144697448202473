import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Row, Col } from 'react-bootstrap';
import { matchPath } from 'react-router-dom';

import Sidebar from 'containers/Sidebar';
import ErrorBoundary from 'containers/ErrorBoundary';
import DashboardNavbar from 'containers/DashboardNavbar';
import { RenderRoutes, PageTemplate, Card, Heading } from 'components';

import {
  checkIsUserFromOrg,
  getFeatureUnleashApi,
  retrieveAltinnSessionApi,
  checkIfCompanyIsAvailableApi,
  isBalanceUpdating,
} from 'services/apihelpers';
import { toDashboard, toSelectCompany } from 'services/routehelpers';
import { setUserRoleProperties } from 'services/helpers';
import {
  fromAuth,
  fromCompany,
  fromEvents,
  fromResource,
} from 'store/selectors';
import {
  resourceCreateRequest,
  updateCompanyInfoFromUrl,
  resourceListReadRequest,
} from 'store/actions';

const generateKey = () => Math.random().toString(36).substr(2, 5);

/**
 * Balances page - Post login
 */
class Accountflow extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({}).isRequired,
    company: PropTypes.shape({}),
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    route: PropTypes.shape({}).isRequired,
    noCardBody: PropTypes.bool,
    match: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    company: null,
    noCardBody: false,
  };

  state = {
    generatedKey: generateKey(),
    error: { hasError: false, page: null },
  };

  componentDidMount() {
    if (this.props.company) {
      this.fetchAltinnExpiration();
    }
    this.checkIfCompanyIsAvailable();
    this.checkValidCompany(this.props);
    this.props.getCreateAccrualUnable();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.checkValidCompany(nextProps, true);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { error } = nextState;

    if (error.hasError && error.page !== nextProps.location.pathname) {
      this.setState({
        generatedKey: generateKey(),
        error: { hasError: false, page: null },
      });
    }

    return true;
  }

  setError = (error) => this.setState({ error });

  // eslint-disable-next-line consistent-return
  checkValidCompany = (props, refetch) => {
    const { location, company, history } = props;
    const newUrls = location.pathname.split('/');

    if (newUrls.length < 6 && newUrls[1] !== 'go') {
      const query = queryString.parse(location.search);

      return history.push(toDashboard(query));
    }

    if (typeof refetch === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      refetch =
        !company ||
        company.id !== newUrls[2] ||
        company.accountingYear !== +newUrls[3] ||
        company.currentWorkingPeriodEnd !== +newUrls[4];
    }

    if (refetch) {
      this.props.updateCompanyInfoFromUrl({
        companyUuid: newUrls[2],
        year: newUrls[3],
        period: newUrls[4],
      });
    }
  };

  checkIfCompanyIsAvailable = async () => {
    const { history, location, checkIfCompanyIsAvailable } = this.props;
    const newUrls = location.pathname.split('/');
    const companyId = newUrls[2];

    if (companyId) {
      try {
        await checkIfCompanyIsAvailable({ companyId });
      } catch (e) {
        if (e?.response?.status === 400) {
          history.push(toSelectCompany());
        }
      }
    }
  };

  fetchAltinnExpiration = () => {
    const { company, retrieveAltinnSession } = this.props;

    retrieveAltinnSession({
      companySID: company.currentCompanySID,
    });
  };

  render() {
    const {
      user,
      history,
      company,
      isUserFromOrg,
      altinnExpiration,
      isUpdating,
      isAlreadyUpdating,
      route: { routes },
      location: { pathname },
    } = this.props;
    const { generatedKey } = this.state;
    let currentRoute = {};

    const isCompanyDataUpdating =
      (isUpdating && isUpdating !== 'Failed' && isUpdating !== 'Processed') ||
      isAlreadyUpdating ||
      false;

    if (!company) {
      return false;
    }

    const findComponent = (currentRoutes, path) => {
      currentRoutes.forEach((route) => {
        const obj = matchPath(path, route);

        if (obj && obj.isExact) {
          currentRoute = route;
          currentRoute.params = obj.params;
        } else if (route.routes) {
          findComponent(route.routes, path);
        }
      });
    };

    findComponent(routes, pathname);

    const additionalProps = {};
    const { title, helmetTitle, noCardBody } = currentRoute;

    additionalProps.roleType = setUserRoleProperties(
      user,
      isUserFromOrg,
      isCompanyDataUpdating,
    );
    additionalProps.altinnExpiration = altinnExpiration;
    additionalProps.handleTabSelect = (key, toRoute, query = {}) => {
      history.push(toRoute({ ...query, tabEntry: key }));
    };

    const pageBody = noCardBody ? (
      <RenderRoutes
        routes={routes}
        props={{ ...this.props, ...additionalProps }}
      />
    ) : (
      <Card
        header={
          <Heading level={3} className="card-title">
            {title}
          </Heading>
        }
      >
        <RenderRoutes
          routes={routes}
          props={{ ...this.props, ...additionalProps }}
        />
      </Card>
    );

    return (
      <PageTemplate
        {...this.props}
        {...additionalProps}
        navbar={<DashboardNavbar {...this.props} {...additionalProps} />}
        sidebar={<Sidebar {...this.props} {...additionalProps} />}
        helmetTitle={helmetTitle}
      >
        <Row>
          <ErrorBoundary
            kind="balances"
            key={generatedKey}
            history={history}
            setError={this.setError}
          >
            <Col md={12}>{pageBody}</Col>
          </ErrorBoundary>
        </Row>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  altinnExpiration:
    fromResource.getList(state, retrieveAltinnSessionApi)[0] || {},
  isUserFromOrg: fromResource.getList(state, checkIsUserFromOrg).status,
  isUpdating: fromEvents.getDownloadProgress(state)?.status,
  isAlreadyUpdating: fromResource.getList(state, isBalanceUpdating).status,
});

const mapDispatchToProps = (dispatch) => ({
  updateCompanyInfoFromUrl: (data) => dispatch(updateCompanyInfoFromUrl(data)),
  getCreateAccrualUnable: () =>
    dispatch(
      resourceListReadRequest(`${getFeatureUnleashApi}/accruals-suggestions`),
    ),
  retrieveAltinnSession: (query) =>
    dispatch(resourceCreateRequest(retrieveAltinnSessionApi, { ...query })),
  checkIfCompanyIsAvailable: (query) =>
    dispatch(resourceListReadRequest(checkIfCompanyIsAvailableApi, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accountflow);
