import { useEffect, useState, useMemo } from 'react';
import _cloneDeep from 'lodash-es/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';

import {
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { a07Formatter } from 'services/dataFormatters/payroll';
import { payRollReportApi } from 'services/apihelpers';

export const useA07Data = (props) => {
  const {
    bimonthly,
    accumulated,
    activePeriods,
    accountingYear,
    currentCompanySID,
  } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, payRollReportApi),
  );
  const rows = useMemo(() => a07Formatter(data), [data]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(payRollReportApi, {
          bimonthly,
          accumulated,
          year: +accountingYear,
          companyId: currentCompanySID,
          period: bimonthly ? Math.ceil(activePeriods / 2) : activePeriods,
        }),
      );
    } catch (e) {
      dispatch(resourceListReadSuccess(payRollReportApi, {}));
    } finally {
      setLoading(() => false);
    }
  };

  const updatePercentage = (value, altinnPayrollIncomeSID) => {
    const newData = _cloneDeep(data);
    newData.altinnPayrollIncomeRows = newData.altinnPayrollIncomeRows.map(
      (item) => {
        if (item.altinnPayrollIncomeSID === altinnPayrollIncomeSID) {
          return { ...item, percentage: value };
        }

        return item;
      },
    );

    dispatch(resourceListReadSuccess(payRollReportApi, newData));
  };

  useEffect(() => {
    fetchData();
  }, [
    bimonthly,
    accumulated,
    activePeriods,
    accountingYear,
    currentCompanySID,
  ]);

  return { data, rows, loading, updatePercentage, fetchData };
};

export default useA07Data;
