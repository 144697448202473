import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings, ButtonIcon } from 'components';

import { vatSettingsCodesColumns } from 'services/tableshapes';
import { hiddenOptions } from 'services/shapes';
import { vatSettingsCodesFilters } from 'services/tableFilterShapes';

const legends = () => [
  {
    color: 'default-bg',
    title: t`Default configuration`,
  },
];

function VATSettingsCodesTable(props) {
  const {
    data,
    loading,
    saftCodes,
    isReadOnly,
    handleAction,
    getRowColor,
    actionSubmitting,
  } = props;

  const sortBy = useMemo(
    () => [
      {
        id: 'saftCode',
        desc: true,
      },
    ],
    [],
  );
  const globalFilter = useMemo(
    () => ({
      hidden: hiddenOptions().filter((item) => item.id === false),
    }),
    [],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-VATSettings">
      <CardPDFTitle title={t`VAT Settings`} />
      <div className="container-fluid" style={{ padding: 0 }}>
        <CustomTableWithAutoSettings
          {...{
            data,
            loading,
            handleAction,
            minHeight: 500,
            actionSubmitting,
            columns: vatSettingsCodesColumns(isReadOnly, saftCodes),
            tableId: 'VATSettingsCodes',
            loadingId: 'code',
            getRowColor,
            enableGroups: true,
            legends: legends(),
            filterConfigs: vatSettingsCodesFilters(),
            initialState: {
              sortBy,
              globalFilter,
            },
            headerConfigs: {
              exportFileName: t`VAT Settings`,
              extraButtons: [
                {
                  id: 'addItem',
                  icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                  title: t`Add Item`,
                  buttonProps: {
                    size: 'sm',
                    kind: 'default',
                    fill: false,
                    magnify: true,
                    disabled: false,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </Card>
  );
}

export default VATSettingsCodesTable;
