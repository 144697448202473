import { t } from '@lingui/macro';

import {
  vatOverviewColumns,
  VATReportNewerColumns,
  vatOutgoingBalanceColumns,
} from 'services/tableshapes';
import useReportGenerator from './generator/useReportGenerator';

const periodTypes = {
  BIMONTHLY: t`Bi-Monthly`,
  MONTHLY: t`Monthly`,
  YEARLY: t`Yearly`,
};

const getOverviewPeriod = (year, period, periodType) => {
  if (periodType === 'BIMONTHLY') {
    return t`Term ${period}, ${year}`;
  }

  if (periodType === 'YEARLY') {
    return t`Yearly 1, ${year}`;
  }

  return t`Period ${period}, ${year}`;
};

export const getVATReportSettings = ({
  showAll,
  getFiles,
  company,
  /* companyUUID, */
  orgNumber,
  accumulated = true,
  activePeriods,
  accountingYear,
  vatOverviewData,
  vatReportData,
  outgoingBalanceData,
}) => {
  const term = Math.ceil(+activePeriods / 2);
  const period = t`Term ${term}, ${accountingYear}`;
  const loading =
    vatOverviewData.loading ||
    vatReportData.loading ||
    outgoingBalanceData.loading;

  const shapes = {
    vatReport: VATReportNewerColumns(),
    outgoingBalance: vatOutgoingBalanceColumns(false, true),
  };

  return {
    loading,
    getFiles,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: term * 2,
      reportType: 'VAT',
    },
    folderName: t`vat`,
    fileName: t`${company} (${orgNumber}) VAT Report - ${accountingYear}`,
    data: [
      ...vatOverviewData.reportData.map((item) => [
        {
          period: getOverviewPeriod(
            accountingYear,
            item.limitPeriods,
            item.periodType,
          ),
          rows: item.tableData.rows,
          withGroups: true,
          checkboxes: {
            [t`Show all VAT-rows`]: showAll,
          },
          tableLevelApproved:
            item.tableData.rows?.[1]?.[`period${item.limitPeriods}`]?.status,
          groupId: 'uiGroupId',
          title: t`VAT Overview (${periodTypes[item.periodType]})`,
          additionalTotalRows: item.tableData.additionalTotalRows,
          tableShape: vatOverviewColumns(
            accountingYear,
            item.periodType,
            item.limitPeriods,
            accumulated,
          ),
        },
      ]),
      ...vatReportData.reportData.map((item) => [
        {
          period: getOverviewPeriod(
            accountingYear,
            item.period,
            item.periodType,
          ),
          rows: item.tableData.rows,
          meta: item.tableData.metaData,
          tableLevelApproved: item.isApproved,
          withGroups: true,
          withTotal: true,
          groupId: 'vatCodeSaft',
          title: t`VAT Report (${periodTypes[item.periodType]})`,
          tableShape: shapes.vatReport,
        },
      ]),
      [
        {
          period,
          withoutStatus: true,
          rows: outgoingBalanceData.rows,
          title: t`Outgoing Balance`,
          tableShape: shapes.outgoingBalance,
        },
      ],
    ],
  };
};

export const useVATReportGenerator = (props) =>
  useReportGenerator(getVATReportSettings(props));

export default useVATReportGenerator;
