import React, { useMemo } from 'react';
import Lottie from 'react-lottie';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import _isEmpty from 'lodash-es/isEmpty';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { searchSelection, formatNumber } from 'services/helpers';

import { DESKTOP, LARGE_SCREENS, FOUR_K } from 'constants/mediaSizes';

import animationData from 'assets/animations/not_found.json';

function DashboardTable(props) {
  const {
    name,
    data,
    search = '',
    withTotal,
    height,
    columns,
    additional,
    searchFields,
    withSearch,
  } = props;

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    if (!search || _isEmpty(searchFields)) return data;

    return data.filter((item) =>
      searchFields.reduce(
        (value, nextItem) =>
          value || item[nextItem].toLowerCase().includes(search.toLowerCase()),
        false,
      ),
    );
  }, [data, search, searchFields]);

  const tableHeight = useMemo(() => {
    if (height) return height;

    if (filteredData.length === 0) {
      return 80;
    }

    if (filteredData.length <= 6) {
      return filteredData.length * 40;
    }

    return undefined;
  }, [height, filteredData.length]);

  return (
    <Table>
      <TableRow className="header">
        {columns.map((column, i) => (
          <TableCell
            key={`table-${name}-header-${i}`}
            width={column.width}
            textAlign={column.align}
          >
            {column.title}
          </TableCell>
        ))}
      </TableRow>
      <TableBody>
        <PerfectScrollbar style={{ height: tableHeight }}>
          {!_isEmpty(filteredData) ? (
            filteredData.map((item, i) => (
              <TableRow key={`table-row-${name}-${i}-${item.id}`}>
                {columns.map((column, index) => {
                  let className;
                  let value =
                    typeof column?.cell?.converter === 'function'
                      ? column?.cell?.converter(item)
                      : item[column.id];

                  if (column?.type === 'number') {
                    const formattedNumber = formatNumber(value);
                    value = formattedNumber?.formatted;
                    className = formattedNumber?.className;
                  }

                  return (
                    <TableCell
                      key={`cell-${i}-${index}`}
                      width={column.width}
                      textAlign={column.align}
                    >
                      {withSearch && column.search ? (
                        <CellContainer>
                          {searchSelection(value, search)}
                        </CellContainer>
                      ) : (
                        <CellContainer
                          className={className}
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <NoRows>
              <div>
                <Lottie
                  style={{ margin: 0, padding: 0 }}
                  width={60}
                  height={60}
                  options={{
                    loop: false,
                    animationData,
                    autoplay: true,
                  }}
                />
                <Trans>
                  Sorry, <strong>no results</strong> were found
                </Trans>
              </div>
            </NoRows>
          )}
        </PerfectScrollbar>
      </TableBody>
      {!_isEmpty(additional) && (
        <TableRow className="footer additional">
          {columns.map((column, i) => (
            <TableCell
              width={column.width}
              textAlign={column.align}
              key={`table-additional-${name}-${i}`}
            >
              {additional[column.id]}
            </TableCell>
          ))}
        </TableRow>
      )}
      {withTotal && (
        <TableRow className="footer">
          {columns.map((column, i) => {
            if (i === 0) {
              return (
                <TableCell key={`table-sum-${name}`} width={column.width}>
                  <Trans>SUM:</Trans>
                </TableCell>
              );
            }

            if (column.withTotal) {
              const sum =
                typeof column?.cell?.totalConverter === 'function'
                  ? column.cell.totalConverter(filteredData)
                  : Math.round(
                      filteredData?.reduce(
                        (value, item) => value + +item[column.id],
                        0,
                      ),
                    );

              return (
                <TableCell
                  width={column.width}
                  textAlign={column.align}
                  key={`table-sum-${name}-${i}`}
                >
                  {sum}
                </TableCell>
              );
            }

            return (
              <TableCell key={`table-sum-${name}-${i}`} width={column.width} />
            );
          })}
        </TableRow>
      )}
    </Table>
  );
}

export default React.memo(DashboardTable);

const Table = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 12px;
  }

  @media (max-width: 1080px) {
    font-size: 11px;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
  }
`;

const TableBody = styled.div`
  flex-basis: 100%;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(47, 58, 77, 0.02);
  border-radius: 5px;
  color: #2f3a4d;
  padding: 0.5em 1.78em;
  margin: 0.57em 0;

  &.header {
    background: #2f3a4d;
    color: white;
    padding: 0.57em 1.78em;
    border-radius: 5px;
    font-weight: 600;
    flex-basis: fit-content;
  }

  &.footer {
    margin-top: 0.71em;
    border: 0.2em solid #2f3a4d;
    color: #2f3a4d;
    border-radius: 5px;
    padding: 0.35em 1.78em;
    font-weight: 700;
    flex-basis: fit-content;
    background: white;
  }

  &.footer.additional {
    margin-bottom: 0;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 13px;
    padding: 0.5em 1em;

    &.header {
      padding: 0.57em 1em;
    }

    &.footer {
      padding: 0.35em 1em;
    }
  }

  @media (min-width: ${FOUR_K.min}px) {
    padding: 0.7em 1.4em;

    &.header {
      padding: 0.7em 1.4em;
    }

    &.footer {
      padding: 0.5em 1.4em;
    }
  }
`;

const TableCell = styled.div`
  line-height: 1.1em;
  width: ${({ width }) => `${width}%`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const NoRows = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  color: rgba(47, 58, 77, 0.73);

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  strong {
    color: #2f3a4d !important;
  }
`;

const CellContainer = styled.div`
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
