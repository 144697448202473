import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import _get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { Row, Col } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';

import { Button, Spinner, Paragraph, ReactFormField } from 'components';

import { periodTypes } from 'services/shapes';
import {
  float,
  minInt,
  maxInt,
  integer,
  required,
  minFloat,
} from 'services/validation';
import { periodFilterButtonsFill, periodSelectors } from 'services/helpers';

const setInitialValues = (values) => {
  const formValues = {};
  formValues.user =
    values.accountHolder !== null ? values.accountHolder.uuid : '';
  formValues.companyname = values?.currentCompanyName || '';
  formValues.registrationnumber = values.currentCompanyRegistrationNumber;
  formValues.periodnumber = periodFilterButtonsFill(
    Array.from(Array(values.currentWorkingPeriodEnd)),
    values.currentPeriodType,
    false,
  );
  formValues.periodtypes = values.currentPeriodType;
  formValues.username = values.accountSystemUsername;
  formValues.country = values.countryCode;
  formValues.vatReportingFrequency = _get(
    values,
    'companySettings.vatReportFrequency.vatReportFrequencyId',
    '',
  );
  formValues.arbitraryamount = values.arbitraryAmount;
  formValues.payrollEnabled = _get(
    values,
    'companySettings.payrollEnabled',
    false,
  );
  formValues.vatEnabled = _get(values, 'companySettings.vatEnabled', false);
  formValues.assetsEnabled = _get(
    values,
    'companySettings.assetsEnabled',
    false,
  );

  return formValues;
};

function CompanySettings(props) {
  const {
    failed,
    company,
    loading,
    roleType,
    onSubmit,
    countries,
    handleXero,
    frequencyList,
    xeroRefreshURL,
    periodNumberMax,
    userListLoading,
    handleEaccounting,
    userListByCompany,
    loadingCountryList,
    handleFilterChange,
    loadingFrequencyList,
    eAccountingRefreshURL,
    loadingXeroRefreshURL,
    loadingEAccountingRefreshURL,
  } = props;
  // TODO: Move this into Failed loading component
  if (failed) {
    return (
      <Paragraph>
        <Trans>Something went wrong! Please try again.</Trans>
      </Paragraph>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const requestsLoading = useMemo(
    () => loadingCountryList || loadingFrequencyList || userListLoading,
    [loadingCountryList, loadingFrequencyList, userListLoading],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const defaultValues = useMemo(
    () => ({
      ...setInitialValues(company),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(company)],
  );

  const {
    Form,
    values,
    setFieldValue,
    meta: { canSubmit, isTouched },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({ onSubmit, defaultValues });
  const disabled = loading || !canSubmit || !isTouched || requestsLoading;

  const periodTypesOptions = periodTypes().map((item) => (
    <option key={`periodTypesOptions-${item.id}`} value={item.id}>
      {item.label}
    </option>
  ));

  let userOptions;
  if (!isEmpty(userListByCompany)) {
    userOptions = [
      <option key="selectOptionDefaultAssignedUser" value="" disabled>
        {t`Please Select Assigned User`}
      </option>,
      userListByCompany.availableUsers.map((item) => (
        <option key={`userList-${item.uuid}`} value={item.uuid}>
          {item.name}
        </option>
      )),
    ];
  }

  let countryOptions;
  if (!loadingCountryList && !isEmpty(countries)) {
    countryOptions = [
      <option key="selectOptionDefaultCountry" value="" disabled>
        {t`Please Select The Country`}
      </option>,
      Object.values(countries).map((item) => (
        <option key={`countryList-${item.countrySID}`} value={item.countryCode}>
          {item.countryName}
        </option>
      )),
    ];
  }

  let frequencyOptions;

  if (!loadingFrequencyList && !isEmpty(frequencyList)) {
    frequencyOptions = [
      <option key="selectOptionDefaultFrequency" value="" disabled>
        {t`Please Select The Frequency`}
      </option>,
      frequencyList.map((item) => (
        <option
          key={`freqencyList-${item.vatReportFrequencyId}`}
          value={item.vatReportFrequencyId}
        >
          {item.frequency}
        </option>
      )),
    ];
  }

  const handlePeriodTypesChange = (e) => {
    handleFilterChange(e);
    const { total } = periodSelectors(e.target.value);

    setFieldValue(
      'periodnumber',
      total < values.periodnumber ? total : values.periodnumber,
    );
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <ReactFormField
            noBorder
            name="companyname"
            size="small"
            type="text"
            label={t`Company Name`}
            validate={[required]}
            placeholder={t`Please Enter Company Name`}
          />
          <ReactFormField
            noBorder
            name="registrationnumber"
            size="small"
            type="text"
            label={t`Registration Number`}
            validate={[required]}
            placeholder={t`Please Enter Registration Number`}
          />
          <ReactFormField
            noBorder
            name="periodtypes"
            size="small"
            type="select"
            label={t`Period Types`}
            onChange={handlePeriodTypesChange}
          >
            {periodTypesOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            min="1"
            max={periodNumberMax}
            name="periodnumber"
            size="small"
            type="number"
            label={t`Period Number`}
            validate={[
              required,
              integer,
              minInt(1),
              maxInt(periodSelectors(values.periodtypes).total),
            ]}
            placeholder={t`Please Enter The Period Number`}
          />
          <ReactFormField
            noBorder
            size="small"
            type="string"
            name="arbitraryamount"
            label={t`Reconciliation Arbitrary Amount`}
            validate={[required, float, minFloat(0)]}
            placeholder={t`Please Enter The Arbitrary Amount`}
          />
        </Col>
        <Col md={6}>
          <ReactFormField
            noBorder
            name="user"
            size="small"
            type="select"
            label={t`Assigned User`}
            loading={userListLoading || isEmpty(userListByCompany)}
            disabled={!roleType.isAdmin}
          >
            {userOptions}
          </ReactFormField>

          <ReactFormField
            noBorder
            name="country"
            size="small"
            type="select"
            loading={loadingCountryList || isEmpty(countries)}
            label={t`Country`}
          >
            {countryOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            name="vatReportingFrequency"
            size="small"
            type="select"
            loading={loadingFrequencyList || isEmpty(frequencyList)}
            label={t`VAT Reporting Frequency`}
          >
            {frequencyOptions}
          </ReactFormField>
          <ReactFormField
            disabled
            size="small"
            type="checkbox"
            id="isMainCompany"
            name="isMainCompany"
            label={t`Is Main Company`}
            defaultChecked={company.isMainCompany === true}
          />
          {company.companySettings && (
            <>
              <ReactFormField
                id="payrollEnabled"
                name="payrollEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Payroll`}
              />
              <ReactFormField
                id="vatEnabled"
                name="vatEnabled"
                size="small"
                type="checkbox"
                label={t`Enable VAT`}
              />
              <ReactFormField
                id="assetsEnabled"
                name="assetsEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Assets`}
              />
            </>
          )}
        </Col>
      </Row>
      <ButtonsHolder>
        <ButtonHolder>
          <Button fill wide type="submit" disabled={disabled}>
            {loading ? <Spinner type="white" size="md" /> : <Trans>Save</Trans>}
          </Button>
        </ButtonHolder>
        {company.accountSystem === 'EACCOUNTING' && (
          <ButtonHolder>
            <Button
              fill
              wide
              kind="info"
              onClick={handleEaccounting}
              disabled={!eAccountingRefreshURL}
            >
              {loadingEAccountingRefreshURL || requestsLoading ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Refresh EAccounting</Trans>
              )}
            </Button>
          </ButtonHolder>
        )}
        {company.accountSystem === 'XERO' && (
          <ButtonHolder>
            <Button
              fill
              wide
              kind="info"
              onClick={handleXero}
              disabled={!xeroRefreshURL}
            >
              {loadingXeroRefreshURL ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Refresh Xero</Trans>
              )}
            </Button>
          </ButtonHolder>
        )}
      </ButtonsHolder>
    </Form>
  );
}

CompanySettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      countrySID: PropTypes.number,
      countryName: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    PropTypes.shape({}),
  ),
  data: PropTypes.shape({}).isRequired,
  userListByCompany: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  periodNumberMax: PropTypes.number.isRequired,
  loadingCountryList: PropTypes.bool,
  tripletexCompanies: PropTypes.shape({}).isRequired,
  frequencyList: PropTypes.shape({}).isRequired,
  loadingFrequencyList: PropTypes.bool,
  roleType: PropTypes.shape({}).isRequired,
  /** @type {shape} company */
  company: PropTypes.shape({
    isMainCompany: PropTypes.bool.isRequired,
  }).isRequired,
  /** @type {bool} failed */
  failed: PropTypes.bool,
};

CompanySettings.defaultProps = {
  failed: false,
  loading: false,
  countries: [{}],
  loadingCountryList: true,
  loadingFrequencyList: false,
};

export default CompanySettings;

const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonHolder = styled.div`
  margin: 5px;
`;
