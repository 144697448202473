import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AddBankRowsModal } from 'components';
import { postBankLinesApi } from 'services/apihelpers';
import { resourceCreateRequest } from 'store/actions';

const defaultLine = {
  amount: 0,
  postedDate: new Date().toISOString(),
  description: '',
};

function AddBankRowsModalContainer(props) {
  const { data = [], closeModal, bankAccountId } = props;

  const dispatch = useDispatch();
  const [lines, setLines] = useState([...data]);
  const submitting = useSelector((state) =>
    pending(state, `${postBankLinesApi}Create`),
  );

  const addLine = () => {
    setLines([...lines, { ...defaultLine, id: `custom-${lines.length}` }]);
  };
  const removeLine = (index) => {
    const newLines = [...lines];

    newLines.splice(index, 1);

    setLines([...newLines]);
  };
  const handleChange = (value, name, index) => {
    const newLines = [...lines];

    newLines[index][name] = value;

    setLines([...newLines]);
  };
  const onSubmit = async () => {
    try {
      await dispatch(
        resourceCreateRequest(
          postBankLinesApi,
          lines.map((line) => ({
            bankAccountId,
            id: line.bankAccountId ? line.id : null,
            amount: +line.amount,
            postedDate:
              line.postedDate.length > 10
                ? line.postedDate
                : `${line.postedDate}T00:00:00`,
            description: line.description,
          })),
        ),
      );
      closeModal(false, true);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    closeModal(false, true);
  };

  return (
    <AddBankRowsModal
      {...{
        lines,
        submitting,
        addLine,
        onSubmit,
        removeLine,
        closeModal,
        handleChange,
      }}
    />
  );
}

AddBankRowsModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

AddBankRowsModalContainer.defaultProps = {};

export default AddBankRowsModalContainer;
