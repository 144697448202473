import React, { useState } from 'react';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
  VATOutgoingBalanceModal,
  VATReconciliationOutgoingBalanceTable,
} from 'components';

import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceDeleteRequest,
} from 'store/actions';
import {
  addVATSettlementsDeviationApi,
  editVATSettlementsDeviationApi,
  deleteVATSettlementsDeviationApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function VATReconciliationOutgoingBalanceTableContainer(props) {
  const { outgoingBalanceData, roleType, activePeriods } = props;
  const { rows, loading, modalData, fetchDeviations } = outgoingBalanceData;

  const params = useParams();
  const dispatch = useDispatch();

  const deviationRowSubmitting = useSelector((state) =>
    pending(state, `${addVATSettlementsDeviationApi}Create`),
  );

  const [modal, setModal] = useState('');
  const [actionSubmitting, setActionSubmitting] = useState({});

  const term = Math.round(activePeriods / 2);

  const handleDeviationAction = async (action, item, value) => {
    if (action === 'add') {
      await dispatch(
        resourceCreateRequest(addVATSettlementsDeviationApi, {
          term,
          year: params.accountingYear,
          companyId: params.companyId,
        }),
      );
    } else if (action === 'edit') {
      await dispatch(
        resourceUpdateRequest(editVATSettlementsDeviationApi, null, {
          id: item.id,
          amount: 0.0,
          ...item,
          ...value,
        }),
      );
    } else if (action === 'delete') {
      await dispatch(
        resourceDeleteRequest(
          `${deleteVATSettlementsDeviationApi}?id=${item.id}`,
        ),
      );
    }

    fetchDeviations();
  };
  const openModal = (type) => setModal(type);
  const closeModal = () => setModal('');

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, values) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'add': {
          await handleDeviationAction('add');
          break;
        }

        case 'openModal': {
          openModal('gl');
          break;
        }

        case 'cellSave': {
          // eslint-disable-next-line eqeqeq
          if (values.row[values.id] != values.value) {
            await handleDeviationAction('edit', values.row, {
              [values.id]: values.value,
            });
          }
          break;
        }

        case 'delete': {
          await handleDeviationAction('delete', values);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const renderModal = () => {
    let modalToRender;

    switch (modal) {
      case 'gl': {
        modalToRender = (
          <VATOutgoingBalanceModal
            {...{
              term,
              modalData,
              closeModal,
              handleAction,
              actionSubmitting,
            }}
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    return modalToRender;
  };

  return (
    <>
      {renderModal()}
      <VATReconciliationOutgoingBalanceTable
        {...{
          term,
          rows,
          params,
          loading,
          roleType,
          handleAction,
          actionSubmitting,
          deviationRowSubmitting,
        }}
      />
    </>
  );
}

export default VATReconciliationOutgoingBalanceTableContainer;
