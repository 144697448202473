import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import { ButtonIcon, CustomTableWithAutoSettings } from 'components';

import { assetReconciliationOverviewColumns } from 'services/tableshapes';
import { assetReconciliationOverviewFilters } from 'services/tableFilterShapes';

function AssetReconciliationOverview(props) {
  const {
    data,
    title,
    search,
    loading,
    roleType,
    handleAction,
    hiddenColumns,
    actionSubmitting,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'assetCategoryName',
        desc: false,
      },
    ],
    [],
  );

  return (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        handleAction,
        actionSubmitting,
        columns: assetReconciliationOverviewColumns(roleType.isReadOnly),
        tableId: 'assetReconciliationOverview',
        loadingId: 'assetCategoryId',
        reportMode: search.reportMode,
        filterConfigs: assetReconciliationOverviewFilters(),
        headerConfigs: {
          exportFileName: title,
          extraButtons: !roleType.isReadOnly && [
            {
              id: 'addCategory',
              icon: <ButtonIcon as={FaPlus} type="extraButton" />,
              title: t`Add category`,
              buttonProps: {
                size: 'sm',
                kind: 'default',
                fill: false,
                magnify: true,
              },
            },
          ],
        },
        initialState: {
          sortBy,
          hiddenColumns,
        },
      }}
    />
  );
}

export default AssetReconciliationOverview;
