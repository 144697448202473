import React, { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs, TableIconGroup, PeriodFilterButtons } from 'components';

import CommentsModal from 'containers/CommentsModal';
import FileUploadModal from 'containers/FileUploadModal';

import VATOverview from 'containers/VATOverview';
import VATReconciliationTable from 'containers/VATReconciliationTable';
import VATReconciliationOutgoingBalanceTable from 'containers/VATReconciliationOutgoingBalanceTable'; // eslint-disable-line max-len
import VATManualReconcileTable from 'containers/VATManualReconcileTable'; // eslint-disable-line max-len
import VATControlTable from 'containers/VATControl';

import { fromResource, fromAuth } from 'store/selectors';
import {
  toVATReconciliation,
  toReconciliationSettings,
} from 'services/routehelpers';
import { useVATReportGenerator } from 'services/hooks/reports';
import { vatReportIconShape } from 'services/TableIconGroupShapes';
import {
  useVATReportData,
  useVATOverviewData,
  useVATOutgoingBalanceData,
  useVATControlData,
} from 'services/hooks/vat';
import {
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import {
  commentsRouteInitializer,
  getTermFromCompany,
  periodSelectorValueInitialize,
} from 'services/helpers';
import {
  getFileListApi,
  uploadedFilesApi,
  uploadBankTransactionApi,
} from 'services/apihelpers';

/**
 * VAT Reconciliation Page Component
 */

function VATReconciliationPage(props) {
  const { roleType, company, history } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { accountingYear, companyId } = params;
  const {
    currentCompanySID,
    currentCompanyName,
    currentCompanyRegistrationNumber,
    uuid: companyUUID,
  } = company;

  const [showAll, setShowAll] = useState(false);
  const [toggleDialogueModal, setToggleDialogueModal] = useState(false);
  const [toggleFileUploadModal, setToggleFileUploadModal] = useState(false);

  const { location, handleTabSelect } = props;
  const files = useSelector((state) =>
    fromResource.getList(state, uploadedFilesApi),
  );

  const user = useSelector((state) => fromAuth.getUser(state));

  const getSearch = () => {
    const search = queryString.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });

    const result = {
      tabEntry: search.tabEntry || 1,
      glPeriods: search.glPeriods || company.currentWorkingPeriodEnd || 1,
      dialogMode: search.dialogMode || false,
      reportMode: search.reportMode || false,
      accumulated: search.accumulated !== undefined ? search.accumulated : true,
      activePeriods:
        search.activePeriods || company.currentWorkingPeriodEnd || 1,
      frequency: search.frequency || 'BIMONTHLY',
    };

    return result;
  };
  const search = getSearch();
  const { tabEntry, reportMode, accumulated, activePeriods, frequency } =
    search;
  const [reportPeriods, setReportPeriods] = useState(activePeriods);

  const componentSetupValues = {
    commentsRoutes: commentsRouteInitializer('vatComment', {}),
    commentModalType: 'vatComment',
  };

  const onTabSelect = (key) =>
    handleTabSelect(key, (data) =>
      toVATReconciliation({ frequency, activePeriods, accumulated, ...data }),
    );

  const toggleTransactionModalFunction = (id, uploadedFiles) => {
    if (id === 'toggleDialogueModal') {
      setToggleDialogueModal((toggle) => !toggle);
    }
    if (id === 'toggleFileUploadModal') {
      setToggleFileUploadModal((toggle) => !toggle);

      if (toggleFileUploadModal) {
        const newFiles = [
          {
            accountId: null,
            fileNames: uploadedFiles?.map((item) => item.fileName),
          },
        ];

        dispatch(resourceListReadSuccess(uploadedFilesApi, newFiles));
      }
    }
  };

  const tooltip = (items) => {
    const slicedArray = items.slice(0, 5);

    return slicedArray?.map((item, i) =>
      i < slicedArray.length - 1 ? (
        <React.Fragment key={i}>
          {item} <br />
        </React.Fragment>
      ) : (
        item
      ),
    );
  };

  const additionalData = useMemo(() => {
    if (Array.isArray(files) && files?.length) {
      return (
        files[0]?.fileNames?.length && {
          uploadFiles: {
            badge: files[0].fileNames.length,
            tooltip: tooltip(files[0].fileNames),
          },
        }
      );
    }

    return null;
  }, [JSON.stringify(files)]);

  const handleIconGroup = (id) => {
    switch (id) {
      case 1:
        toggleTransactionModalFunction('toggleDialogueModal');
        break;
      case 2:
        toggleTransactionModalFunction('toggleFileUploadModal');
        break;
      default:
        break;
    }
  };

  const handlePeriodChange = (event, periodType) => {
    const { target } = event;
    const period = parseInt(target.getAttribute('id'), 10);
    const periodEnd = periodSelectorValueInitialize(periodType, period);
    const periods = Array.from(Array(periodEnd || 1).keys());

    history.push(
      toVATReconciliation({
        ...getSearch(),
        activePeriods: periods.length,
      }),
    );
  };

  const handleFrequencyChange = (event) => {
    history.push(
      toVATReconciliation({
        ...getSearch(),
        frequency: event.target.value,
      }),
    );
  };

  const getFiles = async () => {
    try {
      const response = await dispatch(
        resourceListReadRequest(getFileListApi, {
          account: '',
          showAll: true,
          toPeriod: activePeriods || 12,
          companyId: currentCompanySID,
          fromPeriod: 1,
          periodType: company.currentPeriodType,
          periodYear: accountingYear,
          fileCategory: 'VAT',
        }),
      );

      if (response?.transactionFileList) {
        return response.transactionFileList.map((item) => ({
          key: item.fileKey,
          name: item.fileName,
        }));
      }
    } catch {
      return [];
    }

    return [];
  };

  const handleAccumulatedChange = () => {
    history.push(
      toVATReconciliation({
        ...search,
        accumulated: !accumulated,
      }),
    );
  };

  const vatOverviewData = useVATOverviewData({
    showAll,
    companyId,
    accumulated,
    activePeriods,
    accountingYear,
    currentCompanySID,
  });
  const vatReportData = useVATReportData({
    accountingYear,
    activePeriods,
    fetchVATOverview: vatOverviewData.fetchData,
  });
  const outgoingBalanceData = useVATOutgoingBalanceData({
    companyId,
    activePeriods,
    accountingYear,
  });

  const { generatePDF, disabled, pdfGenerating, Preview } =
    useVATReportGenerator({
      getFiles,
      showAll,
      accumulated,
      activePeriods,
      accountingYear,
      company: currentCompanyName,
      orgNumber: currentCompanyRegistrationNumber,

      vatOverviewData,
      vatReportData,
      outgoingBalanceData,
    });

  const vatOverviewReportData = useVATOverviewData({
    showAll: true,
    companyId,
    accumulated: false,
    activePeriods: reportPeriods,
    accountingYear,
    currentCompanySID,
  });
  const vatReportReportData = useVATReportData({
    accountingYear,
    activePeriods: reportPeriods,
    fetchVATOverview: vatOverviewReportData.fetchData,
  });
  const outgoingBalanceReportData = useVATOutgoingBalanceData({
    companyId,
    activePeriods: reportPeriods,
    accountingYear,
  });

  const { loading, approveAndGeneratePDF } = useVATReportGenerator({
    getFiles,
    showAll: true,
    accumulated: false,
    activePeriods: reportPeriods,
    accountingYear,
    company: currentCompanyName,
    orgNumber: currentCompanyRegistrationNumber,

    vatOverviewData: vatOverviewReportData,
    vatReportData: vatReportReportData,
    outgoingBalanceData: outgoingBalanceReportData,
  });

  const vatControlData = useVATControlData({
    companySid: currentCompanySID,
    accountingYear,
    activePeriods,
    accumulated,
    frequency,
  });

  const refetchAll = async () => {
    Promise.all([
      await vatOverviewData.fetchData(),
      await vatReportData.fetchData(),
      await outgoingBalanceData.fetchData(),
      await vatControlData.fetchData(),
    ]);
  };

  const tabs = [
    {
      title: t`VAT Control Overview`,
      eventKey: 1,
      component: (
        <VATControlTable
          {...{
            roleType,
            params,
            loading,
            vatControlData,
            activePeriods,
            accumulated,
            handleFrequencyChange,
            handlePeriodChange,
            handleAccumulatedChange,
            periodType: search.frequency,
          }}
        />
      ),
    },
    {
      title: t`Year Overview`,
      eventKey: 2,
      component: (
        <VATOverview
          {...{
            showAll,
            disabled,
            roleType,
            setShowAll,
            companyUUID,
            accumulated,
            reportPeriods,
            setReportPeriods,
            loading,
            approveAndGeneratePDF,
            generatePDF,
            pdfGenerating,
            activePeriods,
            accountingYear,
            vatOverviewData,
            handlePeriodChange,
            currentCompanyName,
            handleAccumulatedChange,
            currentCompanyRegistrationNumber,
            refetchAll,
          }}
        />
      ),
    },
    {
      title: t`Report`,
      eventKey: 3,
      component: (
        <VATReconciliationTable
          {...{
            company,
            history,
            roleType,
            disabled,
            approveAndGeneratePDF,
            generatePDF,
            pdfGenerating,
            activePeriods,
            vatReportData,
            handlePeriodChange,
            refetchAll,
          }}
        />
      ),
    },
    {
      title: t`VAT Transactions`,
      eventKey: 4,
      notify: vatControlData?.hasDeviation,
      component: (
        <VATManualReconcileTable
          {...{
            roleType,
          }}
        />
      ),
    },
    {
      title: t`Outgoing Balance`,
      eventKey: 5,
      component: (
        <VATReconciliationOutgoingBalanceTable
          {...{ outgoingBalanceData, roleType, activePeriods }}
        />
      ),
    },
  ];

  const fetchFilesNumber = async () => {
    const query = {
      companyId,
      year: accountingYear,
      period: +activePeriods || getTermFromCompany(company),
      fileCategory: 'VAT',
    };

    await dispatch(resourceListReadRequest(uploadedFilesApi, query));
  };

  useEffect(() => {
    fetchFilesNumber();
  }, [activePeriods, companyId, accountingYear]);

  return (
    <>
      {toggleDialogueModal && (
        <CommentsModal
          showModal={toggleDialogueModal}
          {...{
            user,
            roleType,
            componentSetupValues,
            company: {
              ...company,
              bimonthly: true,
              currentWorkingPeriodEnd: activePeriods,
            },
          }}
          toggleModal={() =>
            toggleTransactionModalFunction('toggleDialogueModal')
          }
        />
      )}
      {toggleFileUploadModal && (
        <FileUploadModal
          showModal
          {...{
            user,
            history,
            roleType,
            search: {},
            account: '',
            toRoute: toVATReconciliation,
            fileCategory: 'VAT',
            company: {
              ...company,
              bimonthly: true,
              currentWorkingPeriodEnd: activePeriods,
            },
          }}
          modalTitle={t`VAT File Upload`}
          toggleModal={(_, __, ___, _files) =>
            toggleTransactionModalFunction('toggleFileUploadModal', _files)
          }
          uploadFilesTo={uploadBankTransactionApi}
        />
      )}
      <RightButtons>
        {[2, 3].includes(tabEntry) ? Preview() : null}
        {!reportMode && ![1, 4].includes(tabEntry) && (
          <ExtraButtons>
            <TableIconGroup
              {...{
                roleType,
                additionalData,
                handleIconGroup,
                shape: vatReportIconShape,
              }}
            />
          </ExtraButtons>
        )}
        {![1, 2, 3, 4].includes(tabEntry) && (
          <PeriodFilterButtons
            size="small"
            periodType="biMonthly"
            singlePeriod={!accumulated}
            activePeriods={Array.from(Array(+activePeriods).keys())}
            handleFilterChange={(event, periodType) =>
              handlePeriodChange(event, periodType)
            }
          />
        )}
      </RightButtons>
      <Tabs
        tabs={tabs}
        activeKey={tabEntry}
        onSelect={onTabSelect}
        config={toReconciliationSettings({
          tabEntry: 'vat-settings',
        })}
      />
    </>
  );
}

export default VATReconciliationPage;

const RightButtons = styled.div`
  top: 85px;
  right: 35px;
  z-index: 5;
  display: flex;
  align-items: center;
  position: absolute;
`;

const ExtraButtons = styled.div`
  margin-right: 10px;
  display: flex;
`;
