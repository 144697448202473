import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { ButtonGroup, Modal, Col, Row } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { email, passwordPatternCMA, required } from 'services/validation';

function CMAUserAddEditModal(props) {
  const { onSubmit, closeModal, cmaUserCreating } = props;
  const {
    Form,
    meta: { canSubmit },
  } = useForm({ onSubmit });

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Form>
        <Modal.Header>
          <Heading level={3}>
            <Trans>Add ConnectMyApps User</Trans>
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <ReactFormField
                name="firstName"
                label={t`First Name`}
                validate={required}
                placeholder={t`Enter first name`}
              />
              <ReactFormField
                name="lastName"
                label={t`Last Name`}
                validate={required}
                placeholder={t`Enter last name`}
              />
              <ReactFormField
                name="company"
                label={t`Company`}
                validate={required}
                placeholder={t`Enter company`}
              />
              <ReactFormField
                type="email"
                name="email"
                label={t`Email`}
                validate={[required, email]}
                placeholder={t`Enter Email address`}
                autoComplete="off"
              />
              <ReactFormField
                type="password"
                name="password"
                label={t`Password`}
                validate={[required, passwordPatternCMA]}
                placeholder={t`Enter password`}
                autoComplete="off"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              type="submit"
              kind="success"
              disabled={!canSubmit}
            >
              {cmaUserCreating ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Create</Trans>
              )}
            </Button>
            <Button fill magnify kind="danger" onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CMAUserAddEditModal;

CMAUserAddEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cmaUserCreating: PropTypes.bool,
};

CMAUserAddEditModal.defaultProps = {
  cmaUserCreating: false,
};
