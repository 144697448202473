export const CLEAR_STORE = 'CLEAR_STORE';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const APP_READY = 'APP_READY';
export const NEEDS_LOGIN = 'NEEDS_LOGIN';

export const clearStore = () => ({ type: CLEAR_STORE });

export const sidebarToggle = (action) => ({
  type: SIDEBAR_TOGGLE,
  payload: action,
});

export const appReady = (action) => ({ type: APP_READY, payload: action });

export const needsLogin = () => ({ type: NEEDS_LOGIN });
