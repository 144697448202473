import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

import {
  Field,
  Button,
  Heading,
  ButtonIcon,
  PeriodFilterButtons,
  CustomTableWithAutoSettings,
} from 'components';

import {
  MapPayrollTransactionsColumns,
  mapVatTransactionsTotal,
} from 'services/tableshapes';
import { mapPayrollTransactionsFilter } from 'services/tableFilterShapes';

function MapPayrollTransactionModal(props) {
  const {
    row,
    data,
    type,
    loading,
    showMore,
    handleAction,
    actionSubmitting,
    toggleModal,
    getRowColor,
    handleShowAll,
    openingBalance,
    getTotalRowColor,
    handlePeriodChange,
    selectedActivePeriods,
  } = props;

  const titleReport = t`Map Payroll Report Transactions`;
  const showOtherPeriods = t`Show other periods`;
  const titleSettlements = t`Map Payroll Settlements Transactions`;

  const filterByTerm = (el) =>
    type === 'report' && !showMore
      ? +el.period === +selectedActivePeriods
      : true;

  const dataToShow = useMemo(() => {
    const defaultData = type === 'report' ? data?.settlementLines : data;

    return Array.isArray(defaultData)
      ? defaultData
          .map((item) => {
            let disabled =
              item.mappingDetails.mapped &&
              item.mappingDetails.term !== row.term;

            if (type === 'report') {
              disabled =
                item.mappingDetails.mapped &&
                item.mappingDetails.term !== +selectedActivePeriods;
            }

            return {
              ...item,
              disabled,
              mappedTo: item?.mappingDetails?.term || '-',
            };
          })
          .filter(filterByTerm)
      : [];
  }, [type, JSON.stringify(data), selectedActivePeriods, JSON.stringify(row)]);

  const mapped = useMemo(() => {
    let value = 0;

    if (Array.isArray(dataToShow)) {
      value = dataToShow
        .filter((item) => item.mappingDetails.mapped)
        ?.filter((item) =>
          type === 'report'
            ? item.mappingDetails.term === +selectedActivePeriods
            : item.mappingDetails.term === row.term,
        )
        ?.reduce((sum, item) => sum + item.amount, 0);
    }

    return value;
  }, [JSON.stringify(dataToShow), JSON.stringify(row), selectedActivePeriods]);

  const total = useMemo(
    () =>
      (type === 'report'
        ? data?.totalsDtos?.find(
            (item) => item.period === +selectedActivePeriods,
          )?.total
        : row.settlement.reportedTotal) || 0,
    [type, JSON.stringify(data), JSON.stringify(row), selectedActivePeriods],
  );

  const totalData = useMemo(
    () => [
      {
        title: t`Total`,
        accessor: 'total',
        value: total,
      },
      {
        title: t`Mapped`,
        accessor: 'mapped',
        value: mapped,
      },
      {
        title: t`Deviation`,
        accessor: 'deviation',
        value: type === 'report' ? -(total - mapped) : total - mapped,
      },
    ],
    [total, mapped, type],
  );
  const content = (
    <>
      <CustomTableWithAutoSettings
        {...{
          loading,
          getRowColor,
          handleAction,
          actionSubmitting,
          data: dataToShow,
          isModal: true,
          tableId: 'mapPayrollReportTransactions',
          columns: MapPayrollTransactionsColumns(),
          loadingId: 'transactionId',
          reportMode: false,
          filterConfigs: mapPayrollTransactionsFilter(),
          headerConfigs: {
            exportFileName: t`Map Payroll Report Transactions`,
          },
        }}
      />
      {!openingBalance && (
        <CustomTableWithAutoSettings
          {...{
            loading,
            actionSubmitting,
            data: totalData,
            isModal: true,
            tableId: 'mapPayrollReportTransactionsTotal',
            columns: mapVatTransactionsTotal(true),
            hideFilters: true,
            getRowColor: getTotalRowColor,
          }}
        />
      )}
    </>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <Heading level={3}>
          {type === 'report' ? titleReport : titleSettlements}
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <FiltersHolder>
          <Field
            horizontal
            id="show_more"
            name="show_more"
            type="checkbox"
            label={showOtherPeriods}
            checked={showMore}
            onChange={handleShowAll}
          />
          {type === 'report' && (
            <PeriodFilterButtons
              singlePeriod
              size="small"
              periodType="monthly"
              activePeriods={Array.from(Array(selectedActivePeriods).keys())}
              handleFilterChange={handlePeriodChange}
            />
          )}
        </FiltersHolder>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button fill magnify kind="danger" onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MapPayrollTransactionModal;

const FiltersHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
