import { t } from '@lingui/macro';
import _isEmpty from 'lodash-es/isEmpty';

// eslint-disable-next-line default-param-last
const calculateSumOfGroup = (group, accountNumber, groupId = 2, row) => {
  const values = group.reduce((result, nextItem) => {
    const {
      sum = 0,
      inputC = 0,
      inputD = 0,
      amountLeft = 0,
      amountRight = 0,
    } = result;

    return {
      groupId,
      accountNumber,

      amountLeft: amountLeft + +nextItem.amountLeft,
      inputC: inputC + +nextItem.inputC,
      inputD: inputD + +nextItem.inputD,
      sum: sum + +nextItem.sum,
      amountRight: amountRight + +nextItem.amountRight,

      subsum: true,
      subtitle: {
        exported: true,
      },
    };
  }, {});

  if (row === '5') {
    values.amountLeft = '';
    values.inputC = '';
    values.inputD = '';
    values.sum = '';
  } else if (row === '6') {
    values.amountLeft = '';
    values.sum = '';
  }

  return values;
};

const filterByPeriod = (list, accumulated, bimonthly, activePeriods) => {
  const rows = [];
  let predefinedTotal = {};

  list.forEach((item) => {
    if (item.lines.length) {
      const filteredByPeriod = item.lines
        .filter((line) => {
          if ((accumulated && bimonthly) || bimonthly) {
            return line.period === Math.ceil(activePeriods / 2);
          }

          return line.period === +activePeriods;
        })
        .map((line) => {
          if (item.row === '6') {
            return {
              ...line,
              groupId: 1,
              row: item.row,
              amountLeft: undefined,
              sum: undefined,
            };
          }
          return { ...line, groupId: 1, row: item.row };
        });

      if (filteredByPeriod.length) {
        rows.push(
          ...filteredByPeriod,
          calculateSumOfGroup(filteredByPeriod, item.rowName, null, item.row),
        );
      }
    }
  });

  if (rows.length) {
    predefinedTotal = calculateSumOfGroup(
      rows.filter((item) => item.subsum),
      t`TOTAL SUM:`,
      1,
    );
  }

  return { rows, predefinedTotal };
};

export const reportedSalaryDetailsFormatter = ({
  data,
  bimonthly,
  accumulated,
  activePeriods,
}) => {
  const additionalTotalRows = [
    {
      accountNumber: t`Total A07:`,
      amountLeft: 0,
      inputC: '',
      inputD: '',
      sum: 0,
      amountRight: 0,
    },
    {
      accountNumber: t`Deviation:`,
      amountLeft: 0,
      inputC: '',
      inputD: '',
      sum: 0,
      amountRight: 0,
    },
  ];

  function getValueForPeriod(input, period) {
    const periodStr = String(period);
    let value = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of input) {
      if (item.hasOwnProperty(periodStr)) {
        value = item[periodStr];
        break;
      }
    }
    return value;
  }

  if (!_isEmpty(data) && data?.voList?.length) {
    const { voList, a07All = [], a07WithSocSec = [] } = data;

    let all = 0;
    let withSocSec = 0;

    if (accumulated) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= activePeriods; i++) {
        all += getValueForPeriod(a07All, i);
        withSocSec += getValueForPeriod(a07WithSocSec, i);
      }
    } else if (bimonthly) {
      all =
        getValueForPeriod(a07All, activePeriods) +
        getValueForPeriod(a07All, activePeriods - 1);
      withSocSec =
        getValueForPeriod(a07WithSocSec, activePeriods) +
        getValueForPeriod(a07WithSocSec, activePeriods - 1);
    } else {
      all = getValueForPeriod(a07All, activePeriods);
      withSocSec = getValueForPeriod(a07WithSocSec, activePeriods);
    }

    const { rows, predefinedTotal } = filterByPeriod(
      voList,
      accumulated,
      bimonthly,
      activePeriods,
    );

    additionalTotalRows[0].amountLeft = all;
    additionalTotalRows[0].sum = all;
    additionalTotalRows[0].amountRight = withSocSec;

    additionalTotalRows[1].amountLeft = +(
      (predefinedTotal.amountLeft || 0) - all
    ).toFixed(2);
    additionalTotalRows[1].sum = +((predefinedTotal.sum || 0) - all).toFixed(2);
    additionalTotalRows[1].amountRight = +(
      (predefinedTotal.amountRight || 0) - withSocSec
    ).toFixed(2);

    return { rows, predefinedTotal, additionalTotalRows };
  }

  return { rows: [], predefinedTotal: {}, additionalTotalRows };
};

export default reportedSalaryDetailsFormatter;
