export const getColorMode = (value) => {
  let mode = value;

  if (!mode) {
    mode = 'AUTO';
  }

  if (mode !== 'AUTO') {
    return mode;
  }

  const hours = new Date().getHours();
  const isDayTime = hours > 7 && hours < 18;

  return isDayTime ? 'light' : 'dark';
};

export default getColorMode;
