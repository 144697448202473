import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';
import { Modal, ModalGateway } from 'react-images';

import { Doc, PDF, Excel } from 'components';

export function FileOverview(props) {
  const { item, open, close } = props;
  let component;

  if (open) {
    if (item.url.includes('application/pdf')) {
      component = <PDF {...props} />;
    } else if (
      item.url.includes('application/msword') ||
      item.url.includes('officedocument.wordprocessingml.document')
    ) {
      component = <Doc {...props} />;
    } else if (item.url.includes('application/vnd')) {
      return open && <Excel {...props} />;
    } else if (item.url.includes('image/')) {
      return (
        <Viewer
          visible
          zIndex={2000}
          images={[{ src: props.item.url, alt: '' }]}
          onClose={close}
          scalable={false}
          changeable={false}
        />
      );
    } else {
      component = (
        <div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="pdfButtonsHolder" onClick={close}>
            <div className="pdfClose">{t`Close`}</div>
          </div>
          <div className="file-not-supported">
            {t`File type not supported or was deleted`}
          </div>
        </div>
      );
    }
  }

  return (
    <ModalGateway>
      {open && <Modal onClose={close}>{component}</Modal>}
    </ModalGateway>
  );
}

FileOverview.propTypes = {
  item: PropTypes.shape({}),
  open: PropTypes.bool,
  close: PropTypes.func,
};

FileOverview.defaultProps = {
  item: {},
  open: false,
  close: () => {},
};

export default FileOverview;
