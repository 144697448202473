import { t } from '@lingui/macro';
import {
  generalTemplateColumns,
  generalTemplateAccrualColumns,
} from 'services/tableshapes';
import { months } from 'constants/shortNames';
import { glLegends } from 'constants/legends';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { getGLRowColor, getGLAccrualsRowColor } from 'services/helpers/tables';

import {
  filterByTemplateStatuses,
  periodTypes,
  viewFinishedAccrualsOptions,
  recordFilterList,
} from 'services/shapes';

import {
  accrualsDefaultValues,
  reconcileDefaultValues,
} from 'constants/reportSettings';
import useReportGenerator, {
  generatePDF,
} from './generator/useReportGenerator';

// eslint-disable-next-line max-len
import getSummary from '../../../components/molecules/AccountDrilldownInfo/getSummary';

const periodNames = months();

export const getGLReportSettings = ({
  data,
  loading,
  getFiles,
  accountInfo,
  accrualsData,
  approveStatus,
  currentAccount,
  accountingYear,
  reconcileFilters = reconcileDefaultValues,
  accrualsFilters = accrualsDefaultValues,
  accountingPeriod,
  currentPeriodType,
  tableLevelApproved,
  saveReport,
  ...rest
}) => {
  const { accountId, name } = currentAccount || {};

  const formattedPeriod = defineFrequencyPeriodTypes(
    currentPeriodType,
    accountingPeriod,
  );

  const periodName = `${periodNames[currentPeriodType]} ${formattedPeriod}`;

  const reconcileStatuses = Object.keys(reconcileFilters).filter(
    (key) => reconcileFilters[key],
  );
  const accrualsStatuses = Object.keys(accrualsFilters).filter(
    (key) => accrualsFilters[key],
  );

  const _accrualsFilters = viewFinishedAccrualsOptions()
    .filter((item) => accrualsStatuses.includes(item.id))
    .map((item) => item.label)
    .join(', ');

  const _reconcileFilters = recordFilterList()
    .filter((item) => reconcileStatuses.includes(item.id))
    .map((item) => item.label)
    .join(', ');

  const accrualsRows = Array.isArray(accrualsData)
    ? accrualsData.filter((item) => {
        if (!accrualsStatuses.length) {
          return false;
        }

        if (
          accrualsStatuses.includes('withoutFinishedAccruals') &&
          !!item.estimatedRemainingMonths
        ) {
          return true;
        }

        return (
          accrualsStatuses.includes('withFinishedAccruals') &&
          !item.estimatedRemainingMonths
        );
      })
    : [];
  const reconcileRows = Array.isArray(data)
    ? data.filter((item) =>
        reconcileStatuses.includes(item.reconciliationStatus),
      )
    : [];

  const legends = glLegends();

  const frequency = periodTypes().find(
    (item) => item.id === accountInfo?.periodType,
  )?.label;
  const template = filterByTemplateStatuses().find(
    (item) => item.id === accountInfo?.accountTemplateCode,
  )?.label;

  const summary = getSummary({
    outgoingBalance: accountInfo?.outgoingBalance,
    accrualsTransactions: accrualsData,
    reconcileTransactions: data,
  });

  const comments = [];

  if (accountInfo?.comment) {
    comments.push({
      title: t`Status Comment`,
      value: accountInfo?.comment,
    });
  }

  if (accountInfo?.periodComment) {
    comments.push({
      title: t`Period Comment`,
      value: accountInfo?.periodComment,
    });
  }

  return {
    ...rest,
    loading,
    getFiles,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      accountId,
      reportType: 'ACCOUNTS',
      saveReport: true,
    },
    fileName: `${accountId} - ${name} (${periodName}, ${accountingYear})`,
    isApproved: approveStatus,
    data: [
      [
        {
          accountInfo: true,
          headerMeta: {
            period: `${periodName}, ${accountingYear}`,
            title: ' ',
            approveStatus: approveStatus || tableLevelApproved,
          },
          values: [
            [
              {
                title: t`Template`,
                value: template,
              },
              {
                title: t`Frequency`,
                value: frequency,
              },
              {
                title: t`Assignee`,
                value: accountInfo?.accountHolder,
              },
            ],
            [
              {
                title: t`Year Opening Balance`,
                value: accountInfo?.openingBalance,
                number: true,
              },
              {
                title: t`Period Opening Balance`,
                value: accountInfo?.ingoingBalance,
                number: true,
              },
              {
                title: t`Period Movement`,
                value: accountInfo?.movement,
                number: true,
              },
              {
                title: t`Period Closing Balance`,
                value: accountInfo?.outgoingBalance,
                number: true,
              },
            ],
            [
              {
                title: t`Sum of open posts`,
                value: summary?.sumOpenPostsValues,
                number: true,
              },
              {
                title: t`Sum Accrued amounts`,
                value: summary?.accrualsSumValues,
                number: true,
              },
              {
                title: t`Sum Approved amounts`,
                value: summary?.approvedSumValues,
                number: true,
              },
              {
                title: t`Deviation to outgoing balance`,
                value: summary?.totalSumValues,
                number: true,
              },
            ],
            ...(comments.length ? [comments] : []),
          ],
        },
        {
          filters: _accrualsFilters,
          period: `${periodName}, ${accountingYear}`,
          rows: accrualsRows,
          title: t`${accountId} - ${name} (Accruals)`,
          tableShape: generalTemplateAccrualColumns(),
          withTotal: true,
          legends: legends.slice(0, 1),
          getRowColor: (row) =>
            getGLAccrualsRowColor(row, accountingPeriod, accountingYear),
        },
        {
          filters: _reconcileFilters,
          period: !accrualsRows?.length
            ? `${periodName}, ${accountingYear}`
            : null,
          rows: reconcileRows,
          title: t`${accountId} - ${name} (Reconcile)`,
          tableShape: generalTemplateColumns(),
          withTotal: true,
          legends: [legends[3]],
          getRowColor: getGLRowColor,
        },
      ],
    ],
  };
};

export const useGeneralLedgerReportGenerator = (props) =>
  useReportGenerator(getGLReportSettings(props));

export const generateGLReport = async (props) => {
  await generatePDF(getGLReportSettings(props));
};

export default useGeneralLedgerReportGenerator;
