import { useEffect, useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import {
  resourceUpdateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { getPayrollReportedSalary, isManualApi } from 'services/apihelpers';
import { reportedSalaryFormatter } from 'services/dataFormatters/payroll';
import getErrorMessage from 'services/helpers/getErrorMessage';

const findAndReplaceComment = (list, rowNumber, comment) =>
  list?.map((item) => {
    const el = { ...item };

    if (rowNumber === item.rowNumber) {
      el.comment = comment;
    }

    return el;
  });

export const useReportedSalaryData = (props) => {
  const {
    uuid,
    bimonthly,
    accumulated,
    onlyNumbers,
    activePeriods,
    accountingYear,
    handleCheckboxChange,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getPayrollReportedSalary),
  );
  const rows = useMemo(
    () => reportedSalaryFormatter(data, bimonthly, accumulated, onlyNumbers),
    [data, bimonthly, accumulated, onlyNumbers],
  );

  const manual = useMemo(() => data?.manual || false, [data]);

  const updateRSDataInRedux = useCallback(async (collection) => {
    await dispatch(
      resourceListReadSuccess(getPayrollReportedSalary, collection),
    );
  }, []);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getPayrollReportedSalary, {
          companyId: uuid,
          year: +accountingYear,
          period: +activePeriods,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  const updateManual = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceUpdateRequest(isManualApi, null, {
          uuid,
          isManual: !manual,
        }),
      );
      await updateRSDataInRedux({ ...data, manual: !manual });

      if (manual && onlyNumbers) {
        handleCheckboxChange({ target: { checked: false } }, 'onlyNumbers');
      } else if (!manual && !onlyNumbers) {
        handleCheckboxChange({ target: { checked: true } }, 'onlyNumbers');
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      setLoading(() => false);
    }
  };

  const updateComment = async (row, value) => {
    const { rowNumber } = row;

    const vo = findAndReplaceComment(data?.vo, rowNumber, value);
    const manualVo = findAndReplaceComment(data?.manualVo, rowNumber, value);

    const collection = { vo, manualVo, manual };

    await updateRSDataInRedux(collection);
  };

  useEffect(() => {
    fetchData();
  }, [uuid, accountingYear, activePeriods]);

  return {
    rows,
    manual,
    loading,
    updateManual,
    updateComment,
  };
};

export default useReportedSalaryData;
