import React from 'react';
import { Trans } from '@lingui/macro';
import styled, { keyframes } from 'styled-components/macro';

function LoadingScreen() {
  return (
    <Background>
      <div className="bg" />
      <div className="bg bg2" />
      <div className="bg bg3" />
      <Content>
        <Logo
          id="e07a0995-8298-4605-b034-959a154cf9b4"
          data-name="b661ff7f-78bf-4e14-a42f-3c9e4670362f"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 285.55 215.77"
        >
          <title>Symbol</title>
          <path
            d={`M142.32,215.67.55,144.78a1,1,0,0,1-.43-1.35A1,1,0,
          0,1,.55,143L142.32,72.11a1,1,0,0,1,.9,0L285,143a1,1,0,0,
          1,.44,1.34,1,1,0,0,1-.44.44L143.22,215.67A1,1,0,0,1,142.32,
          215.67Z`}
            fill="#84c1ff"
          />
          <path
            d={`M142.32,179.67.57,108.79a1,1,0,0,1-.46-1.34A1,1,0,0,
          1,.57,107L142.32,36.11a1,1,0,0,1,.9,0L285,107a1,1,0,0,1,
          .47,1.33,1,1,0,0,1-.47.47L143.22,179.67A1,1,0,0,1,142.32,179.67Z`}
            fill="#add6ff"
          />
          <path
            d={`M142.32,143.67.56,72.79A1,1,0,0,1,.1,71.45,1,1,0,0,1,
          .56,71L142.32.11a1,1,0,0,1,.9,0L285,71a1,1,0,0,1,.47,1.33,
          1,1,0,0,1-.47.47L143.22,143.67A1,1,0,0,1,142.32,143.67Z`}
            fill="#d6eaff"
          />
        </Logo>
        <div className="text">
          <h1>
            <Trans>
              Preparing your workplace<span>.</span>
            </Trans>
          </h1>
          <p>
            <Trans>We are setting Accountflow up for you!</Trans>
          </p>
        </div>
      </Content>
    </Background>
  );
}

export default LoadingScreen;

const floating = (y) => keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(${y}px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const rotating = keyframes`
  0%,
  30% {
    transform: rotate(0deg);
  }
  60%,
  100% {
    transform: rotate(360deg);
  }
`;

const slide = keyframes`
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
`;

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #29252b;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg {
    animation: ${slide} 4s ease-in-out infinite alternate;

    background-image: linear-gradient(-60deg, #1b191f 50%, #211f26 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
  }

  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 5s;
  }

  .bg3 {
    animation-duration: 6s;
  }
`;

const Logo = styled.svg`
  width: 190px;
  overflow: visible !important;

  animation: ${rotating} 3s linear infinite;

  path:nth-of-type(3) {
    animation: ${() => floating(-16)} 2s ease-in-out infinite;
  }

  path:nth-of-type(2) {
    animation: ${() => floating(-8)} 2s ease-in-out infinite;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      span {
        color: #84c1ff;
      }
    }

    p {
      font-size: 16px;
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: 400;
      margin-top: 7px;
      padding-bottom: 0;
      margin-bottom: 0;
      color: #a1b1c2;
    }
  }
`;
