import { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';
import isEmpty from 'lodash-es/isEmpty';
import _cloneDeep from 'lodash-es/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';

import { vatOverviewFormatter } from 'services/dataFormatters/vat';
import { fromResource, fromAuth } from 'store/selectors';
import {
  postVATCommentApi,
  deleteVATCommentApi,
  setVATApproveApi,
  getVATOverviewApi,
  getVatDetailsNewer,
  getReportVersionsApi,
  updateVATCommentApi,
} from 'services/apihelpers';
import {
  resourceUpdateRequest,
  resourceDeleteRequest,
  resourceCreateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { periodFilterButtonsFill } from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const vatOverviewReportDataFormatter = (
  data,
  activePeriods,
  showAll,
  accumulated,
) => {
  if (!Array.isArray(data) || !data?.length) {
    return [];
  }

  return data.map((item) => {
    const _limitPeriods =
      item.periodType === 'YEARLY'
        ? 1
        : periodFilterButtonsFill(
            Array.from(Array(+activePeriods).keys()),
            item.periodType,
          );

    return {
      periodType: item.periodType,
      limitPeriods: _limitPeriods,
      tableData: vatOverviewFormatter({
        showAll,
        data: item,
        accumulated,
        limitPeriods: _limitPeriods,
      }),
    };
  });
};

export const useVATOverviewData = (props) => {
  const {
    showAll,
    companyId,
    accumulated,
    activePeriods,
    accountingYear,
    currentCompanySID,
    reportPeriods,
  } = props;
  const dispatch = useDispatch();

  const [frequencies, setFrequencies] = useState([]);
  const [frequency, setFrequency] = useState('BIMONTHLY');

  const user = useSelector(fromAuth.getUser);
  const data = useSelector((state) =>
    fromResource.getList(state, getVATOverviewApi),
  );

  const reports = useSelector((state) =>
    fromResource.getList(state, getReportVersionsApi),
  );

  const limitPeriods = useMemo(() => {
    const _limitPeriods = periodFilterButtonsFill(
      Array.from(Array(activePeriods).keys()),
      frequency,
    );

    return frequency === 'YEARLY' ? 1 : _limitPeriods;
  }, [activePeriods, frequency]);

  const tableData = useMemo(() => {
    if (!Array.isArray(data) || !data?.length) {
      return vatOverviewFormatter({
        data: {},
        showAll,
        accumulated,
        limitPeriods,
        reports: reports?.VAT || {},
      });
    }

    return vatOverviewFormatter({
      showAll,
      accumulated,
      limitPeriods,
      data: data.find((item) => item.periodType === frequency),
      reports: reports?.VAT || {},
    });
  }, [
    data,
    limitPeriods,
    frequency,
    showAll,
    accumulated,
    reports,
    reportPeriods,
  ]);

  const reportData = useMemo(
    () =>
      vatOverviewReportDataFormatter(data, activePeriods, showAll, accumulated),
    [data, activePeriods, showAll, accumulated, reportPeriods],
  );

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getVATOverviewApi, {
          year: accountingYear,
          companyId: currentCompanySID,
        }),
      );
      await dispatch(
        resourceListReadRequest(getReportVersionsApi, {
          accountingYear,
          companyUUID: companyId,
          reportType: 'VAT',
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  const approveTerm = async (period, oldValue) => {
    let newId;

    try {
      if (!oldValue) {
        // POST
        newId = await dispatch(
          resourceCreateRequest(setVATApproveApi, {
            period,
            periodType: frequency,
            year: +accountingYear,
            companyId: currentCompanySID,
            uuid: user?.uuid,
          }),
        );
      } else {
        // DELETE
        await dispatch(
          resourceDeleteRequest(
            // eslint-disable-next-line max-len
            `${setVATApproveApi}?period=${period}&year=${+accountingYear}&companyId=${currentCompanySID}&periodType=${frequency}&uuid=${
              user?.uuid
            }`,
          ),
        );
      }

      const currentFrequencyData = _cloneDeep(
        data.find((item) => item.periodType === frequency),
      );
      const statuses =
        currentFrequencyData.additionalRows.approvedStatus.statuses.map(
          (item) =>
            item.period === period
              ? {
                  ...item,
                  status: !oldValue,
                  id: oldValue ? null : newId,
                  approver: user?.name,
                  time: new Date().toLocaleString('en-US', {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }),
                }
              : item,
        );

      const newData = data.map((item) => {
        if (item.periodType === frequency) {
          return {
            ...item,
            additionalRows: {
              ...item.additionalRows,
              approvedStatus: {
                ...item.additionalRows.approvedStatus,
                statuses,
              },
            },
          };
        }

        return item;
      });

      await dispatch(resourceListReadSuccess(getVATOverviewApi, newData));
      await dispatch(
        resourceListReadRequest(getVatDetailsNewer, {
          year: accountingYear,
          companyId: currentCompanySID,
        }),
      );
      if (!oldValue) {
        setTimeout(() => {
          dispatch(
            resourceListReadRequest(getReportVersionsApi, {
              accountingYear,
              companyUUID: companyId,
              reportType: 'VAT',
            }),
          );
        }, 1000);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const updateComment = async (type, item, value) => {
    const { vatComments, vatComment } = item;

    const dataClone = _cloneDeep(data);
    const currentFrequencyData = dataClone.find(
      (dataItem) => dataItem.periodType === frequency,
    );
    const parent = _get(currentFrequencyData, item.parentPath, {});
    const { rows } = parent;

    let newRows;

    try {
      if (type === 'delete') {
        await dispatch(
          resourceDeleteRequest(
            // eslint-disable-next-line max-len
            `${deleteVATCommentApi}?commentId=${vatComment.id}&companyId=${currentCompanySID}`,
          ),
        );

        newRows = rows.map((row) => {
          if (row.vatCode === item.vatCode) {
            return {
              ...row,
              vatComments: vatComments.filter(
                (comment) => comment.id !== vatComment.id,
              ),
            };
          }

          return row;
        });
      } else if (type === 'create') {
        const query = {
          companySid: currentCompanySID,
          groupId: parent.groupId,
          vatCodeId: item.vatCodeId,
          period: limitPeriods,
          periodType: frequency,
          comment: value,
          year: accountingYear,
        };

        const response = await dispatch(
          resourceCreateRequest(postVATCommentApi, query),
        );

        newRows = rows.map((row) => {
          if (row.vatCode === item.vatCode) {
            return {
              ...row,
              vatComments: Array.isArray(vatComments)
                ? [...vatComments, response]
                : [response],
            };
          }

          return row;
        });
      } else if (type === 'update') {
        await dispatch(
          resourceUpdateRequest(
            // eslint-disable-next-line max-len
            `${updateVATCommentApi}?commentId=${vatComment.id}&companyId=${currentCompanySID}`,
            null,
            {
              comment: value,
            },
          ),
        );

        newRows = rows.map((row) => {
          if (row.vatCode === item.vatCode) {
            return {
              ...row,
              vatComments: vatComments.map((comment) =>
                comment.id === vatComment.id
                  ? { ...comment, comment: value }
                  : comment,
              ),
            };
          }

          return row;
        });
      }

      _set(currentFrequencyData, item.parentPath, {
        ...parent,
        rows: newRows,
      });

      const newData = data.map((dataItem) => {
        if (dataItem.periodType === frequency) {
          return currentFrequencyData;
        }

        return dataItem;
      });

      dispatch(resourceListReadSuccess(getVATOverviewApi, newData));
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    fetchData();
  }, [accountingYear, currentCompanySID]);

  useEffect(() => {
    if (isEmpty(data)) {
      setFrequency(undefined);
      setFrequencies([]);
    } else {
      const _frequencies = data.map((item) => item.periodType);
      const _frequency = _frequencies[0];

      setFrequency((prevState) =>
        _frequencies.includes(prevState) ? prevState : _frequency,
      );
      setFrequencies(_frequencies);
    }
  }, [data]);

  return {
    loading,
    tableData,
    fetchData,
    reportData,
    frequency,
    approveTerm,
    limitPeriods,
    frequencies,
    setFrequency,
    updateComment,
  };
};

export default useVATOverviewData;
