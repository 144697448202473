export const downloadProgress = (payload) => ({
  type: 'DOWNLOAD_PROGRESS',
  payload,
});

export const errorMessage = (payload) => ({
  type: 'ERROR_MESSAGE',
  payload,
});

export const clearErrorMessage = () => ({
  type: 'CLEAR_ERROR_MESSAGE',
});

export const connectionStatus = (payload) => ({
  type: 'CONNECTION_STATUS',
  payload,
});

export const startSse = () => ({
  type: 'START_SSE',
});

export const hasUpdatedBalance = () => ({
  type: 'HAS_UPDATED_BALANCE',
});
