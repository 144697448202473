import { t } from '@lingui/macro';

const months = () => ({
  MONTHLY: t`P`,
  BIMONTHLY: t`T`,
  QUARTERLY: t`Q`,
  TERTIARY: t`T`,
  HALFYEARLY: t`HY`,
  YEARLY: t`Y`,
  undefined: t`T`,
});

const frequencyStep = {
  MONTHLY: 1,
  BIMONTHLY: 2,
  QUARTERLY: 3,
  TERTIARY: 4,
  HALFYEARLY: 6,
  YEARLY: 12,
};

const getNames = (year, type, limitPeriods, accumulated = true) => {
  const result = [];
  const step = frequencyStep[type];
  const translatedMonths = months();

  if (!accumulated) {
    return [`${translatedMonths[type]}${limitPeriods}`];
  }

  for (let i = 0; i < 12; i += step) {
    result.push(`${translatedMonths[type]}${result.length + 1}`);
  }

  return result.slice(0, limitPeriods);
};

export const columns = (year, frequency, limitPeriods, accumulated) => [
  {
    width: 50,
    Header: ' ',
    accessor: 'vatCode',
    reportWidth: 30,
    disableSortBy: true,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 280,
    Header: ' ',
    reportWidth: accumulated ? 150 : 300,
    accessor: 'vatDescription',
    withTooltip: true,
    disableSortBy: true,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 80,
    Header: t`Rate`,
    accessor: 'ratePercentage',
    className: '',
    reportWidth: 40,
    disableSortBy: true,
    cell: {
      disableClick: true,
    },
  },
  ...getNames(year, frequency, limitPeriods, accumulated).map(
    (item, index) => ({
      width: 120,
      Header: item,
      accessor: `period${accumulated ? index + 1 : limitPeriods}`,
      className: 'flex-right',
      disableSortBy: true,
      predefinedTotal: `period${accumulated ? index + 1 : limitPeriods}`,
      cell: {
        type: (row) => row.type || 'number',
        rounded: true,
        disableClick: true,
      },
    }),
  ),
  ...(accumulated
    ? [
        {
          width: 140,
          Header: t`VAT-Report Sum`,
          accessor: 'reportedSum',
          className: 'flex-right',
          disableSortBy: true,
          predefinedTotal: 'reportedSum',
          cell: {
            type: 'number',
            rounded: true,
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 140,
    Header: accumulated
      ? t`Accumulated GL Sum`
      : t`${months()[frequency]}${limitPeriods} - General Ledger`,
    accessor: 'glSum',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'glSum',
    cell: {
      type: 'number',
      rounded: true,
      disableClick: true,
    },
  },
  {
    width: 140,
    Header: t`Difference`,
    accessor: 'difference',
    className: 'flex-right',
    predefinedTotal: 'difference',
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
      disableClick: true,
    },
  },
  {
    width: 170,
    Header: t`Comment`,
    accessor: 'comment',
    className: 'flex-left',
    disableSortBy: true,
    cell: {
      type: 'textarea',
      editable: (row) => typeof row.vatCode === 'string',
      placeholder: t`Add comment:`,
      disableClick: true,
    },
  },
];

export default { columns };
