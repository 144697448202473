import React from 'react';
import { useParams } from 'react-router-dom';

import { VATControlDrilldownTable } from 'components';

function VATControlDrilldownTableContainer(props) {
  const {
    accountId,
    roleType,
    params,
    vatControlDrilldownData,
    activePeriods,
    accumulated,
    handlePeriodChange,
    periodType,
    vatCode,
  } = props;
  const { accountingYear } = useParams();
  const { loading, rows } = vatControlDrilldownData;

  return (
    <VATControlDrilldownTable
      year={accountingYear}
      {...{
        accountId,
        periodType,
        roleType,
        params,
        loading,
        vatControlDrilldownData,
        activePeriods,
        accumulated,
        handlePeriodChange,
        tableData: rows,
        vatCode,
      }}
    />
  );
}

export default VATControlDrilldownTableContainer;
