import { t } from '@lingui/macro';

/**
 * Report Total Grid
 */
export const columns = () => [
  {
    id: 1,
    label: t`Type`,
    value: 'type',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 2,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: '',
    enabled: true,
  },
];

export default { columns };
