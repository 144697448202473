import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Trans, t, plural } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import { Button, Heading, ButtonIcon, CustomTable } from 'components';

import { formatNumber } from 'services/helpers';
import { VATUnmapperLinesColumns } from 'services/tableshapes';
import { vatUnmappedLinesFilters } from 'services/tableFilterShapes';

import { DESKTOP, TABLET } from 'constants/mediaSizes';
import { filterByPeriod, filterHideZeroVATRows } from 'services/shapes';

function VATUnmappedLinesModal(props) {
  const {
    data,
    term,
    year,
    loading,
    closeModal,
    handleAction,
    actionSubmitting,
  } = props;

  const globalFilter = useMemo(
    () => ({
      period: filterByPeriod().filter(
        (period) => period.id === +term * 2 || period.id === +term * 2 - 1,
      ),
      vatCode: filterHideZeroVATRows().filter((item) => item.id === 'hide'),
    }),
    [term],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'period',
        desc: false,
      },
    ],
    [],
  );

  const converter = (selectedRows) => {
    let str = '';

    str += plural(selectedRows.length, {
      zero: 'No rows selected',
      one: `${selectedRows.length} row selected`,
      other: `${selectedRows.length} rows selected`,
    });

    str += t` → Total: ${
      formatNumber(
        selectedRows.reduce((sum, row) => sum + row.original.amount, 0),
      ).formatted
    }`;

    return str;
  };

  const content = (
    <CustomTable
      {...{
        data,
        loading,
        handleAction,
        actionSubmitting,
        isModal: true,
        tableId: 'vatReportUnmappedLines',
        columns: VATUnmapperLinesColumns(),
        loadingId: 'transactionId',
        filterConfigs: vatUnmappedLinesFilters(),
        headerConfigs: {
          exportFileName: t`VAT Report Unmapped Lines - ${year}, ${term}`,
        },
        selectConfigs: {
          converter,
        },
        initialState: {
          sortBy,
          globalFilter,
        },
      }}
    />
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>
            VAT Report Unmapped Lines - {year}, {term}
          </Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={closeModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VATUnmappedLinesModal;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: 1260px) {
      width: 1200px;
    }

    @media (min-width: ${DESKTOP.max}px) {
      width: 1350px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
