import React from 'react';
import { t } from '@lingui/macro';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { sortBooleanCustomTable } from 'services/helpers';

/**
 * Group Company Grid
 */
export const columns = ({ reportMode }, isSuperOrSystemAdmin) => [
  {
    width: 70,
    Header: t`ID`,
    accessor: 'groupId',
    className: 'flex-center',
  },
  {
    width: reportMode ? 400 : 585,
    Header: t`Group Name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: 100,
    Header: t`Company count`,
    accessor: 'companyCount',
    className: 'flex-right',
  },
  {
    width: reportMode ? 400 : 500,
    Header: t`Associated user Groups`,
    accessor: 'userGroupList',
    className: '',
    withTooltip: !reportMode,
    disableSortBy: true,
    cell: {
      converter: (value) => value.map((u) => u.name).join(', '),
    },
  },
  {
    width: 100,
    Header: t`User Count`,
    accessor: 'userCount',
    className: 'flex-right',
  },
  ...(isSuperOrSystemAdmin
    ? [
        {
          width: 100,
          Header: t`Secret`,
          sortType: sortBooleanCustomTable,
          accessor: 'secret',
          className: 'flex-center',
          cell: {
            type: 'boolean',
          },
        },
      ]
    : []),
  {
    width: 85,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'edit',
          icon: () => <GoPencil />,
          tooltip: t`Edit`,
        },
        {
          id: 'delete',
          icon: () => <BiTrash />,
          tooltip: t`Delete`,
        },
      ],
    },
  },
];

export default columns;
