import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';
import { rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner, Heading, Button } from 'components';

import { setXero } from 'services/apihelpers';
import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { toAdminCompanySettings, toDashboard } from 'services/routehelpers';

function Xero(props) {
  const { history, location } = props;
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const search = queryString.parse(location.search);
  const response = useSelector((state) => fromResource.getList(state, setXero));
  const failed = useSelector((state) => rejected(state, `${setXero}ListRead`));

  const handleDashboardRedirect = () => {
    history.replace(toDashboard());
  };

  useEffect(() => {
    if (response?.companyId) {
      history.replace(
        toAdminCompanySettings({ companyIdToEdit: response.companyId }),
      );
    } else if (response?.error) {
      setError(response?.error?.message || response?.error);
    }
  }, [JSON.stringify(response)]);
  useEffect(() => {
    dispatch(resourceListReadRequest(setXero, search));
  }, []);

  return (
    <Container>
      {failed || error ? (
        <>
          <Heading
            kind="danger"
            level={3}
            style={{ maxWidth: '500px' }}
            alignment="center"
          >
            {error || t`Something went wrong! Please try again later.`}
          </Heading>
          <Button
            fill
            wide
            type="submit"
            kind="success"
            onClick={handleDashboardRedirect}
          >
            <Trans>Go to dashboard</Trans>
          </Button>
        </>
      ) : (
        <Spinner type="normal" />
      )}
    </Container>
  );
}

export default Xero;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
