import { t } from '@lingui/macro';

import {
  Home,
  Viia,
  Xero,
  Reports,
  Accountflow,
  Settings,
  AuditPage,
  SanityPage,
  EAccounting,
  XeroRefresh,
  DashboardPage,
  Reconciliation,
  DocumentFlowPage,
  RegistrationPage,
  AuditDetailedPage,
  GroupSettingsPage,
  SelectCompanyPage,
  InvalidateLinkPage,
  EAccountingRefresh,
  ReportsOverviewPage,
  GeneralSettingsPage,
  GroupUserDetailedPage,
  VATReconciliationPage,
  AdminUserSettingsPage,
  GroupCompanyDetailedPage,
  AdminCompanySettingsPage,
  AccountDetailsReportPage,
  OrganizationSettingsPage,
  AssetsReconciliationPage,
  AccountReconciliationPage,
  ReconciliationSettingsPage,
  RegistrationValidationPage,
  VATReconciliationDetailedPage,
  OpeningBalanceRegistrationPage,
  VATReconciliationControlPage,
  ErrorPage,
  RedirectHelperPage,
} from 'components';

import ReconciliationPage from 'containers/ReconciliationPage';
import PayrollReconciliationPage from 'containers/PayrollReconciliationPage';

import * as PAGE_PATH from './constants';

export default () => [
  {
    path: PAGE_PATH.HOME_PAGE_PATH,
    title: t`Home`,
    component: Home,
    helmetTitle: t`Home`,
    routes: [
      {
        path: PAGE_PATH.VIIA_INTEGRATION_PAGE_PATH,
        title: t`Viia`,
        exact: true,
        component: Viia,
        helmetTitle: t`Viia`,
      },
      {
        path: PAGE_PATH.EACCOUNTING_INTEGRATION_PAGE_PATH,
        title: t`EAccounting`,
        exact: true,
        component: EAccounting,
        helmetTitle: t`EAccounting`,
      },
      {
        path: PAGE_PATH.XERO_INTEGRATION_PAGE_PATH,
        title: t`Xero`,
        exact: true,
        component: Xero,
        helmetTitle: t`Accountflow | Xero`,
      },
      {
        path: PAGE_PATH.XERO_REFRESH_PAGE_PATH,
        title: t`Xero Refresh`,
        exact: true,
        component: XeroRefresh,
        helmetTitle: t`Accountflow | Xero Refresh`,
      },
      {
        path: PAGE_PATH.EACCOUNTING_REFRESH_PAGE_PATH,
        title: t`EAccounting Refresh`,
        exact: true,
        component: EAccountingRefresh,
        helmetTitle: t`EAccounting Refresh`,
      },
      {
        path: PAGE_PATH.SELECT_COMPANY_PAGE_PATH,
        title: t`Select Company`,
        exact: true,
        component: SelectCompanyPage,
        helmetTitle: t`Select Company`,
      },
      {
        path: PAGE_PATH.TERMS_OF_SERVICE,
        title: t`Terms of Service`,
        exact: true,
        component: SanityPage,
        helmetTitle: t`Terms of Service`,
      },
      {
        path: PAGE_PATH.PRIVACY_STATEMENT,
        title: t`Privacy Statement`,
        exact: true,
        component: SanityPage,
        helmetTitle: t`Privacy Statement`,
      },
      {
        path: PAGE_PATH.REGISTRATION_VALIDATION_PAGE_PATH,
        title: t`Validate Registration`,
        component: RegistrationValidationPage,
        helmetTitle: t`Validate Registration`,
      },
      {
        path: PAGE_PATH.INVALIDATE_LINK_PAGE_PATH,
        title: t`Invalidate Link`,
        component: InvalidateLinkPage,
        helmetTitle: t`Invalidate Link`,
      },
      {
        path: PAGE_PATH.REGISTER_PAGE_PATH,
        title: t`Registration`,
        component: RegistrationPage,
        helmetTitle: t`Registration`,
      },
      {
        path: PAGE_PATH.ERROR_SCREEN_PATH,
        title: t`Error`,
        component: ErrorPage,
        helmetTitle: t`Error`,
      },
      {
        path: PAGE_PATH.REDIRECT_TO_PAGE,
        title: '',
        component: RedirectHelperPage,
        helmetTitle: '',
      },
      {
        path: PAGE_PATH.ACCOUNTFLOW_PAGE_PATH,
        title: t`Accountflow`,
        component: Accountflow,
        helmetTitle: t`Home`,
        routes: [
          {
            path: PAGE_PATH.DASHBOARD_PAGE_PATH,
            exact: true,
            title: t`Dashboard`,
            component: DashboardPage,
            noCardBody: true,
            helmetTitle: t`Dashboard`,
          },
          {
            path: PAGE_PATH.AUDIT_PAGE_PATH,
            exact: true,
            title: t`Audit`,
            component: AuditPage,
            helmetTitle: t`Audit`,
          },
          {
            path: PAGE_PATH.FILES_PAGE_PATH,
            exact: true,
            title: t`Files`,
            component: DocumentFlowPage,
            helmetTitle: t`Files`,
          },
          {
            path: `${PAGE_PATH.AUDIT_PAGE_PATH}/:auditId/:auditCatId`,
            exact: true,
            title: t`Audit Detailed`,
            component: AuditDetailedPage,
            helmetTitle: t`Audit Detailed`,
          },
          {
            path: PAGE_PATH.REPORTS_PAGE_PATH,
            title: t`Reports`,
            component: Reports,
            helmetTitle: t`Reports`,
            routes: [
              {
                path: PAGE_PATH.REPORTS_OVERVIEW_PAGE_PATH,
                exact: true,
                title: t`Trial Balance`,
                component: ReportsOverviewPage,
                noCardBody: true,
                helmetTitle: t`Reports | Trial Balance`,
              },
              {
                path: PAGE_PATH.ACCOUNT_DETAILS_REPORT_PAGE_PATH,
                title: t`Account Details`,
                component: AccountDetailsReportPage,
                noCardBody: true,
                helmetTitle: t`Reports | Account Details`,
              },
            ],
          },
          {
            path: PAGE_PATH.SETTINGS_PAGE_PATH,
            title: t`Settings`,
            component: Settings,
            helmetTitle: t`Settings`,
            routes: [
              {
                path: PAGE_PATH.GENERAL_SETTINGS_PAGE_PATH,
                exact: true,
                title: t`General`,
                component: GeneralSettingsPage,
                noCardBody: true,
                helmetTitle: t`Settings | General`,
              },
              {
                path: PAGE_PATH.RECONCILIATION_SETTINGS_PAGE_PATH,
                title: t`Reconciliation`,
                component: ReconciliationSettingsPage,
                noCardBody: true,
                helmetTitle: t`Settings | Reconciliation`,
              },
              {
                path: PAGE_PATH.ADMIN_USER_SETTINGS_PAGE_PATH,
                exact: true,
                title: t`User`,
                adminOnly: true,
                component: AdminUserSettingsPage,
                noCardBody: true,
                helmetTitle: t`Settings | User`,
              },
              {
                path: PAGE_PATH.ADMIN_COMPANY_SETTINGS_PAGE_PATH,
                exact: true,
                title: t`Company`,
                component: AdminCompanySettingsPage,
                adminOnly: true,
                noCardBody: true,
                helmetTitle: t`Settings | Company`,
              },
              {
                path: PAGE_PATH.ADMIN_ORGANISATION_SETTINGS_PAGE_PATH,
                exact: true,
                title: t`Organization Administration`,
                component: OrganizationSettingsPage,
                adminOnly: true,
                noCardBody: true,
                helmetTitle: t`Settings | Organization Administration`,
              },
              {
                path: PAGE_PATH.GROUP_SETTINGS_PAGE_PATH,
                exact: true,
                title: t`Group`,
                component: GroupSettingsPage,
                adminOnly: true,
                noCardBody: true,
                helmetTitle: t`Settings | Group`,
              },
              {
                path: `${PAGE_PATH.GROUP_SETTINGS_COMPANY_PAGE_PATH}/:id`,
                exact: true,
                title: t`Company Groups Details View`,
                component: GroupCompanyDetailedPage,
                adminOnly: true,
                noCardBody: false,
                helmetTitle: t`Settings | Company Groups Details View`,
              },
              {
                path: `${PAGE_PATH.GROUP_SETTINGS_USER_PAGE_PATH}/:id`,
                exact: true,
                title: t`User Groups Details View`,
                component: GroupUserDetailedPage,
                adminOnly: true,
                noCardBody: false,
                helmetTitle: t`Settings | User Groups Details View`,
              },
            ],
          },
          {
            path: PAGE_PATH.RECONCILIATION_PAGE_PATH,
            title: t`Reconciliation`,
            component: Reconciliation,
            helmetTitle: t`Reconciliation`,
            routes: [
              {
                path: PAGE_PATH.ACCOUNT_RECONCILIATION_PAGE_PATH,
                title: t`Account`,
                component: ReconciliationPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | Account`,
              },
              {
                path: PAGE_PATH.PAYROLL_RECONCILIATION_PAGE_PATH,
                exact: true,
                title: t`Payroll`,
                component: PayrollReconciliationPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | Payroll`,
              },
              {
                path: PAGE_PATH.VAT_RECONCILIATION_PAGE_PATH,
                exact: true,
                title: t`VAT`,
                component: VATReconciliationPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | VAT`,
              },
              {
                path: `${PAGE_PATH.VAT_RECONCILIATION_PAGE_PATH}/:id`,
                exact: true,
                title: t`VAT Detailed`,
                component: VATReconciliationDetailedPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | VAT Detailed`,
              },
              {
                // eslint-disable-next-line max-len
                path: `${PAGE_PATH.VAT_RECONCILIATION_CONTROL_PAGE_PATH}/:accountId`,
                exact: true,
                title: t`VAT Control Detailed`,
                component: VATReconciliationControlPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | VAT Control Detailed`,
              },
              {
                path: PAGE_PATH.OPENING_BALANCE_PAGE_PATH,
                title: t`Opening Balance Registration`,
                component: OpeningBalanceRegistrationPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | Opening Balance Registration`,
              },
              {
                path: PAGE_PATH.OVERVIEW_RECONCILIATION_PAGE_PATH,
                exact: true,
                title: t`Account Overview`,
                component: AccountReconciliationPage,
                helmetTitle: t`Reconciliation | Account Overview`,
              },
              {
                path: PAGE_PATH.ASSETS_RECONCILIATION_PAGE_PATH,
                exact: true,
                title: t`Assets`,
                component: AssetsReconciliationPage,
                noCardBody: true,
                helmetTitle: t`Reconciliation | Assets`,
              },
            ],
          },
        ],
      },
    ],
  },
];
