import { t } from '@lingui/macro';

import { filterHideZeroVATRows, filterByPeriod } from 'services/shapes';

export const vatUnmappedLinesFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'period',
      'lineId',
      'vatCode',
      'vatRate',
      'accountId',
      'description',
      'transactionId',
      'vatDescription',
    ],
  },
  {
    id: 'vatCode',
    type: 'singleSelect',
    label: t`Hide VAT Code 0`,
    options: filterHideZeroVATRows(),
    disableSort: true,
    placeholder: t`Please select a hiding`,
  },
  {
    id: 'period',
    type: 'multiSelect',
    label: t`Period`,
    options: filterByPeriod(),
    disableSort: true,
    placeholder: t`Please select Period`,
  },
  {
    id: 'postedDate',
    type: 'date',
    label: t`Posted Date`,
  },
  {
    id: 'amount',
    type: 'range',
    label: t`Amount`,
  },
];

export default { vatUnmappedLinesFilters };
