import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import _isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import { fromResource } from 'store/selectors';
import {
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { getSLAccountDetails } from 'services/apihelpers';
import { simpleLedgerDataFormatter } from 'services/dataFormatters/accounts';

export const useSimpleLedgerData = (props) => {
  const {
    account,
    companyId,
    deviationRows,
    accountingYear,
    accountingPeriod,
    currentPeriodType,
  } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, `${getSLAccountDetails}/${account}`),
  );

  const reconciliationSID = data?.simpleLedger?.reconciliationSID;

  const rows = useMemo(() => {
    if (_isEmpty(data?.simpleLedger)) {
      return [];
    }

    return simpleLedgerDataFormatter(data?.simpleLedger, deviationRows);
  }, [data, deviationRows]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(`${getSLAccountDetails}/${account}`, {
          companyId,
          year: accountingYear,
          period: accountingPeriod,
          periodType: currentPeriodType.toUpperCase(),
          fromPeriod: 1,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      setLoading(() => false);
    }
  };

  const changeBalanceRedux = async (value) => {
    await dispatch(
      resourceListReadSuccess(`${getSLAccountDetails}/${account}`, {
        ...data,
        simpleLedger: {
          ...data?.simpleLedger,
          balanceOfBankStatement: value,
        },
      }),
    );
  };

  const deleteRowRedux = async (deviationSID) => {
    const deviationList = data?.deviationList || [];

    // UPDATING State without deleted row
    await dispatch(
      resourceListReadSuccess(`${getSLAccountDetails}/${account}`, {
        ...data,
        simpleLedger: {
          ...data?.simpleLedger,
          deviationList: deviationList.filter(
            (row) => row.deviationSID !== deviationSID,
          ),
        },
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, [account, companyId, accountingYear, accountingPeriod, currentPeriodType]);

  return {
    rows,
    data,
    loading,
    fetchData,
    deleteRowRedux,
    reconciliationSID,
    changeBalanceRedux,
    accountInfo: data?.account,
  };
};

export default useSimpleLedgerData;
