import { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

function BuildInfo() {
  const [sequence, setSequence] = useState([]);

  const KONAMI_CODE = useMemo(
    () => [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
      'Enter',
    ],
    [],
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      const updatedSequence = [...sequence, e.key];
      if (updatedSequence.length > KONAMI_CODE.length) {
        updatedSequence.shift();
      }
      setSequence(updatedSequence);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [sequence, KONAMI_CODE]);

  useEffect(() => {
    if (sequence.join('') === KONAMI_CODE.join('')) {
      const date = new Date(__BUILD_TIME__);
      const shortDate = date.toLocaleString();
      toast(
        // eslint-disable-next-line max-len
        `Commit ID: ${__COMMIT_HASH__}\nBuild date: ${shortDate}\nBranch name: ${__BRANCH_NAME__}`,
        {
          icon: '🐛',
          duration: 10000,
        },
      );
    }
  }, [sequence, KONAMI_CODE]);

  return null;
}

export default BuildInfo;
