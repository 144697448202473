import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { BiPlus } from '@react-icons/all-files/bi/BiPlus';
import { BiPencil } from '@react-icons/all-files/bi/BiPencil';
import { toPercentage } from 'services/helpers';

export const columns = (isReadOnly) => [
  {
    width: 100,
    Header: t`VAT Code`,
    accessor: 'code',
    className: '',
  },
  {
    width: 100,
    Header: t`SAF-T Code`,
    accessor: 'saftCode',
    className: '',
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Incoming %`,
    accessor: 'inTaxPercentage',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => `${toPercentage(value).toString()} %`,
    },
  },
  {
    width: 100,
    Header: t`Outgoing %`,
    accessor: 'outTaxPercentage',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => `${toPercentage(value).toString()} %`,
    },
  },
  {
    width: 100,
    Header: t`Incoming Account`,
    accessor: 'inTaxAccount',
    className: '',
  },
  {
    width: 100,
    Header: t`Outgoing Account`,
    accessor: 'outTaxAccount',
    className: '',
  },
  {
    width: 100,
    Header: t`Incoming Pro-Rata %`,
    accessor: 'inTaxProRataPercentage',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => `${toPercentage(value).toString()} %`,
    },
  },
  {
    width: 100,
    Header: t`Outgoing Pro-Rata %`,
    accessor: 'outTaxProRataPercentage',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => `${toPercentage(value).toString()} %`,
    },
  },
  {
    width: 50,
    Header: t`Reverse Charge Calculation`,
    accessor: 'reverseChargeCalculation',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  {
    width: 100,
    Header: t`Effective Start Date`,
    accessor: 'effectiveStartDate',
    className: '',
    cell: {
      type: 'date',
      converter: (value) => {
        if (value && value.includes('1900-01-01')) {
          return '';
        }
        return value;
      },
    },
  },
  {
    width: 100,
    Header: t`Effective End Date`,
    accessor: 'effectiveEndDate',
    className: '',
    cell: {
      type: 'date',
      converter: (value) => {
        if (value && value.includes('2100-01-01')) {
          return '';
        }
        return value;
      },
    },
  },
  {
    width: 50,
    Header: t`Hidden`,
    accessor: 'hidden',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  ...(isReadOnly
    ? []
    : [
        {
          width: 80,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'addRange',
                visible: (item) => item.historyIndex === 0,
                icon: () => <BiPlus />,
                tooltip: t`Add Range`,
              },
              {
                id: 'editItem',
                icon: () => <BiPencil />,
                tooltip: t`Edit`,
              },
              {
                id: 'delete',
                visible: (item) => !item.lines,
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ],
          },
        },
      ]),
];

export default { columns };
