import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { CreateManualReportModal } from 'components';

import {
  getPayrollReportedSalary,
  manualPayrollReport,
} from 'services/apihelpers';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from 'store/actions';
import { getTranslatedPayrollReportElements } from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function CreateManualReportModalContainer(props) {
  const { activePeriods, toggleModal } = props;

  const dispatch = useDispatch();
  const params = useParams();

  const [lines, setLines] = useState([]);
  const [names, setNames] = useState([]);
  const [changed, setChanged] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const loading = useSelector((state) =>
    pending(state, `${manualPayrollReport}ListRead`),
  );

  const { accountingYear, companyId } = params;

  const fetchData = async () => {
    const response = await dispatch(
      resourceListReadRequest(manualPayrollReport, {
        uuid: companyId,
        year: accountingYear,
      }),
    );

    setNames(response?.elementNames || []);
    setLines(response?.vo || []);
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  // eslint-disable-next-line consistent-return
  const addLine = async () => {
    if (!names.length) {
      return toast.error(t`No available Element Names`);
    }

    const firstElement = names[0];
    const { code } = firstElement;
    const { name, originalName } =
      getTranslatedPayrollReportElements(firstElement);

    const query = {
      code,
      elementName: name,
      elementNameOriginal: originalName,
      period: +activePeriods,
      year: accountingYear,
      amount: 0,
      withSocialSecurity: false,
      uuid: companyId,
    };

    try {
      const newLine = await dispatch(
        resourceCreateRequest(manualPayrollReport, query),
      );
      setLines([...lines, newLine]);
      setChanged(true);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const updateRow = async (item) => {
    const { row, id, value } = item;

    if (id === 'amount' && +value === row.amount) {
      return;
    }

    const updatedValue = ['period', 'elementName'].includes(id)
      ? value[0].label
      : value;

    const query = {
      ...row,
      [id]: ['amount', 'period'].includes(id) ? +updatedValue : updatedValue,
    };

    if (id === 'elementName') {
      names.forEach((nm) => {
        const { name, originalName } = getTranslatedPayrollReportElements(nm);
        if (name === value[0].label) {
          query.elementName = name;
          query.elementNameOriginal = originalName;
          query.code = nm.code;
        }
      });
    }

    _setActionSubmitting(row.id, true);

    try {
      await dispatch(resourceUpdateRequest(manualPayrollReport, null, query));
      setLines(lines.map((line) => (line.id === query.id ? query : line)));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    setChanged(true);
    _setActionSubmitting(row.id, false);
  };

  const deleteLine = async (item) => {
    const { id } = item;

    _setActionSubmitting(id, true);

    try {
      await dispatch(resourceDeleteRequest(`${manualPayrollReport}?id=${id}`));
      setLines(lines.filter((line) => line.id !== id));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    setChanged(true);
    _setActionSubmitting(id, false);
  };

  const closeModal = () => {
    if (changed) {
      try {
        dispatch(
          resourceListReadRequest(getPayrollReportedSalary, {
            companyId,
            year: +accountingYear,
            period: +activePeriods,
          }),
        );
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    }

    toggleModal();
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'addLine': {
          await addLine();
          break;
        }

        case 'cellSave': {
          await updateRow(item);
          break;
        }

        case 'cellClick': {
          await updateRow({
            row: item,
            id: 'withSocialSecurity',
            value: !item.withSocialSecurity,
          });
          break;
        }

        case 'delete': {
          await deleteLine(item);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CreateManualReportModal
      {...props}
      lines={lines}
      names={names}
      loading={loading}
      closeModal={closeModal}
      handleAction={handleAction}
      actionSubmitting={actionSubmitting}
    />
  );
}

export default CreateManualReportModalContainer;
