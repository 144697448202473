export const initialState = {
  connectionMessage: null,
  downloadProgress: null,
  errorMessage: null,
  hasUpdatedBalance: false,
};

export const getDownloadProgress = (state = initialState) =>
  state.downloadProgress;

export const getSSEError = (state = initialState) => state.errorMessage;

export const isBalanceUpdated = (state = initialState) =>
  state.hasUpdatedBalance;

export const isUpdating = (state = initialState) =>
  state.downloadProgress.status !== 'Processed' ||
  state.downloadProgress.status !== 'Failed';
