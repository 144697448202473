import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 100,
    Header: t`Type`,
    accessor: 'name',
    className: '',
  },
  {
    width: 100,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'value',
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
