// eslint-disable-next-line import/prefer-default-export
export const initialState = {
  theme: 'light',
  sidebarToggle: false,
  appReady: false,
  needsLogin: false,
};

export const getSidebarToggleUpdate = (state = initialState) =>
  state.sidebarToggle || initialState.sidebarToggle;

export const isAppReady = (state = initialState) =>
  state.appReady || initialState.appReady;

export const needsLogin = (state = initialState) =>
  state.needsLogin || initialState.needsLogin;
