export const MOBILE = {
  min: 320,
  max: 480,
};

export const TABLET = {
  min: 481,
  max: 768,
};

export const DESKTOP = {
  min: 1025,
  max: 1367,
  maxHeight: 719,
};

export const LARGE_SCREENS = {
  min: 1600,
  max: 1920,
};

export const TWO_K = {
  min: 2048,
};

export const FOUR_K = {
  min: 3840,
};
