import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RenderRoutes } from 'components';

import { toHome, toDashboard, toSelectCompany } from 'services/routehelpers';
import { fromAuth } from 'store/selectors';

export function Home(props) {
  const {
    history,
    location,
    route: { routes },
    match: { isExact },
  } = props;

  const user = useSelector(fromAuth.getUser);

  if (location.pathname === '/authentication/callback') {
    const { callbackPath } = JSON.parse(
      localStorage.getItem('oidc.login.default') || '{}',
    );
    if (callbackPath) {
      history.push(callbackPath);
    } else if (user?.currentCompanyId !== 0) {
      history.push(toDashboard());
    } else {
      history.push(toSelectCompany());
    }
    return false;
  }

  if (isExact) {
    if (user?.currentCompanyId !== 0) {
      history.push(toHome());
    } else {
      history.push(toSelectCompany());
    }
    return false;
  }

  return <RenderRoutes routes={routes} props={props} />;
}

export default Home;

Home.propTypes = {
  user: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  route: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
};

Home.defaultProps = {
  user: undefined,
};
