import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardGroups } from 'components';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import {
  getDashboardGroups,
  getDashboardUserGroups,
  getDashboardCompanyGroups,
  getDashboardCompanyCompaniesGroups,
} from 'services/apihelpers';

export function DashboardGroupsContainer() {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const getSearch = () => {
    const search = queryString.parse(location.search, {
      parseBooleans: true,
    });

    return {
      tabEntry: search.tabEntry || 'groups',
      reportMode: search.reportMode || false,
    };
  };
  const search = getSearch();
  const availableGroups = useSelector((state) =>
    fromResource.getList(state, getDashboardGroups),
  );
  const users = useSelector((state) =>
    fromResource.getList(
      state,
      selectedGroups[0]?.type === 'user'
        ? getDashboardUserGroups
        : getDashboardCompanyGroups,
    ),
  );
  const companies = useSelector((state) =>
    fromResource.getList(state, getDashboardCompanyCompaniesGroups),
  );
  // const availableCompanies = useSelector((state) =>
  //   fromResource.getList(state, getDashboardAvailableCompanies),
  // );
  const loadingUsers = useSelector((state) =>
    pending(
      state,
      `${
        selectedGroups[0]?.type === 'user'
          ? getDashboardUserGroups
          : getDashboardCompanyGroups
      }ListRead`,
    ),
  );
  const loadingCompanies = useSelector((state) =>
    pending(state, `${getDashboardCompanyCompaniesGroups}ListRead`),
  );
  // const loadingAvailableCompanies = useSelector((state) =>
  //   pending(state, `${getDashboardAvailableCompanies}ListRead`),
  // );
  const loadingGroups = useSelector((state) =>
    pending(state, `${getDashboardGroups}ListRead`),
  );
  const handleChange = (values) => {
    setSelectedGroups(values);
  };
  const availableGroupsToSelect = [];

  // availableGroups?.availableUserGroups?.forEach((item) => {
  //   availableGroupsToSelect.push({
  //     id: item.groupId,
  //     type: 'user',
  //     label: `User group - ${item.name}`,
  //   });
  // });

  availableGroups?.availableCompanyGroups?.forEach((item) => {
    availableGroupsToSelect.push({
      id: item.groupId,
      type: 'company',
      label: `Company group - ${item.name}`,
    });
  });

  useEffect(() => {
    setSelectedGroups([]);
    dispatch(resourceListReadRequest(getDashboardGroups));
  }, [params.companyId]);
  useEffect(() => {
    if (selectedGroups.length) {
      const selectedItem = selectedGroups[0];

      if (selectedItem?.type === 'user') {
        dispatch(
          resourceListReadRequest(getDashboardUserGroups, {
            userGroupId: selectedItem.id,
          }),
        );
      } else {
        dispatch(
          resourceListReadRequest(getDashboardCompanyGroups, {
            companyGroup: selectedItem.id,
          }),
        );
        dispatch(
          resourceListReadRequest(getDashboardCompanyCompaniesGroups, {
            companyGroup: selectedItem.id,
            year: +params.accountingYear,
            period: +params.accountingPeriod,
          }),
        );
        // dispatch(
        //   resourceListReadRequest(getDashboardAvailableCompanies, {
        //     companyGroup: selectedItem.id,
        //   }),
        // );
      }
    }
  }, [
    params.accountingYear,
    params.accountingPeriod,
    JSON.stringify(selectedGroups),
  ]);

  return (
    <DashboardGroups
      {...{
        users,
        search,
        companies,
        loadingUsers,
        loadingGroups,
        selectedGroups,
        loadingCompanies,
        // availableCompanies,
        availableGroupsToSelect,
        // loadingAvailableCompanies,
        handleChange,
      }}
    />
  );
}

export default DashboardGroupsContainer;
