import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { PayrollSocialSecurityModal } from 'components';

import { resourceCreateRequest } from 'store/actions';
import { setPayrollSSPercentageApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export function PayrollSocialSecurityModalContainer(props) {
  const { refetch } = props;
  const dispatch = useDispatch();

  const [changePercentage, setChangePercentage] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const handleChange = async (percentage, socialSecurityId) => {
    try {
      await dispatch(
        resourceCreateRequest(setPayrollSSPercentageApi, {
          percentage,
          socialSecurityId,
        }),
      );

      if (typeof refetch === 'function') {
        refetch();
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const toggleChangePercentage = () => setChangePercentage(!changePercentage);

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, _props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellSave': {
          await handleChange(+_props.value, _props.row.socialSecurityId);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <PayrollSocialSecurityModal
      {...{
        ...props,
        handleAction,
        actionSubmitting,
        changePercentage,
        handleChange,
        toggleChangePercentage,
      }}
    />
  );
}

export default PayrollSocialSecurityModalContainer;
