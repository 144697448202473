import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import { ButtonIcon, CustomTableWithAutoSettings } from 'components';

import { assetRegisterColumns } from 'services/tableshapes';
import { assetRegisterFilters } from 'services/tableFilterShapes';

function AssetRegister(props) {
  const {
    data,
    title,
    search,
    loading,
    roleType,
    collections,
    handleAction,
    actionSubmitting,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'category',
        desc: false,
      },
    ],
    [],
  );
  const urlFilters = useMemo(
    () =>
      search.assetCategory
        ? {
            assetCategoryId: Array.isArray(search.assetCategory)
              ? search.assetCategory
              : [search.assetCategory],
          }
        : {},
    [JSON.stringify(search.assetCategory)],
  );

  return (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        urlFilters,
        handleAction,
        actionSubmitting,
        columns: assetRegisterColumns(roleType.isReadOnly),
        tableId: 'assetRegister',
        loadingId: 'assetId',
        reportMode: search.reportMode,
        filterConfigs: assetRegisterFilters({ collections }),
        headerConfigs: {
          exportFileName: title,
          extraButtons: !roleType.isReadOnly && [
            {
              id: 'add',
              icon: <ButtonIcon as={FaPlus} type="extraButton" />,
              title: t`Add Asset`,
              buttonProps: {
                size: 'sm',
                kind: 'default',
                fill: false,
                magnify: true,
              },
            },
          ],
        },
        initialState: {
          sortBy,
        },
      }}
    />
  );
}

export default AssetRegister;
