import { saveAs } from 'file-saver';
import { htmlTblCreater } from './el';

function exportObject2Doc(
  headers,
  exportable,
  fileName,
  headerStyle,
  cellStyle,
  repeatHeader,
) {
  // Create the html structured dataset
  const dataset = htmlTblCreater(
    'doc',
    headers,
    exportable,
    headerStyle,
    cellStyle,
    repeatHeader,
  );
  const htmlString = `<html><body>${dataset}</body></html>`;

  // Create the bytes
  const bytes = new Uint8Array(htmlString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < htmlString.length; i++) {
    bytes[i] = htmlString.charCodeAt(i);
  }

  // Convert the contents to blob
  const blob = new Blob([bytes], { type: 'text/html' });

  // Save the file as doc
  saveAs(blob, `${fileName}.doc`);
}

export default {
  export: (params) => {
    exportObject2Doc(
      params.headers,
      params.exportable,
      params.fileName,
      params.headerStyle,
      params.cellStyle,
      params.repeatHeader,
    );
  },
};
