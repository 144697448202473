import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { Spinner } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { toReconciliationSettings } from 'services/routehelpers';
import { setOpenBankingLoginResponseApi } from 'services/apihelpers';

export class Viia extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }).isRequired,
  };

  componentDidMount() {
    this.setOpenBanking();
  }

  setOpenBanking = async () => {
    const {
      history,
      location,
      setOpenBankingLoginResponse,
      match: { params },
    } = this.props;
    const search = location ? queryString.parse(location.search) : {};

    await setOpenBankingLoginResponse(search, params);
    history.replace(
      toReconciliationSettings({
        tabEntry: 'bank-settings',
        openBanking: true,
      }),
    );
  };

  render() {
    return (
      <Container>
        <Spinner type="normal" />
      </Container>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    setOpenBankingLoginResponse: (query, params) =>
      dispatch(
        resourceListReadRequest(
          `${setOpenBankingLoginResponseApi}/${
            params.type.toLowerCase() === 'first'
              ? 'first-time-viia-flow/'
              : 'initialize-viia-flow/'
          }${params.id}`,
          {
            ...query,
          },
        ),
      ),
  })),
  withRouter,
)(Viia);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
