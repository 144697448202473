import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { required } from 'services/validation';
import { sortCollection } from 'services/helpers';

function BankAddEditModal(props) {
  const {
    onSubmit,
    submitting,
    closeModal,
    handleSelectChange,
    openBankingLinkLoading,
    openBankingAccountList,
    openBankingAccountLoading,
    handleOpenBankingLogin,
    openBankingAccountError,
  } = props;

  const {
    Form,
    setFieldValue,
    values: currentValues,
  } = useForm({
    onSubmit,
  });
  const disabled =
    !currentValues.name ||
    !currentValues.bankAccount ||
    !currentValues.accountNumber;
  const setAutoFill = (data) => {
    setFieldValue('name', data.accountName);
    setFieldValue('accountNumber', data.bban);
  };
  const login = t`Login`;
  const rejoin = t`Rejoin`;
  const openBankingButton = openBankingAccountError ? login : rejoin;
  const getBankLabel = (item) =>
    `${item.accountName} - ${item.bban} - ${item.username}`;

  const bankAccount = useMemo(
    () =>
      !isEmpty(openBankingAccountList)
        ? sortCollection(
            openBankingAccountList.map((item) => ({
              value: item.viiaId,
              label: getBankLabel(item),
            })),
            'Asc',
            'label',
          )
        : [],
    [JSON.stringify(openBankingAccountList)],
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Form>
        <Modal.Header>
          <HeaderHolder>
            <Button fill kind="success" onClick={handleOpenBankingLogin}>
              {openBankingLinkLoading ? (
                <Spinner type="white" size="md" />
              ) : (
                openBankingButton
              )}
            </Button>
          </HeaderHolder>
        </Modal.Header>
        <Modal.Body>
          {openBankingAccountError ? (
            <Heading level={4} alignment="center">
              <Trans>Please Login to proceed</Trans>
            </Heading>
          ) : (
            <FieldsHolder>
              <ReactFormField
                noBorder
                feedback
                name="bankAccount"
                size="sm"
                type="autoSuggest"
                label={t`Bank account`}
                options={bankAccount}
                loading={openBankingAccountLoading}
                validate={[required]}
                onChange={(e) => handleSelectChange(e, setAutoFill)}
              />
              <ReactFormField
                noBorder
                feedback
                name="name"
                size="sm"
                label={t`Name`}
                validate={[required]}
                loading={openBankingAccountLoading}
                placeholder={t`Enter name`}
              />
              <ReactFormField
                noBorder
                feedback
                name="accountNumber"
                size="sm"
                label={t`Account Number`}
                validate={[required]}
                loading={openBankingAccountLoading}
                placeholder={t`Enter account number`}
              />
            </FieldsHolder>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!openBankingAccountError && (
            <Button
              fill
              kind="success"
              type="submit"
              disabled={disabled || submitting}
            >
              {submitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          )}
          <Button fill magnify kind="danger" onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

BankAddEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openBankingAccountList: PropTypes.oneOf([
    PropTypes.arrayOf(),
    PropTypes.shape({}),
  ]).isRequired,
  handleOpenBankingLogin: PropTypes.func.isRequired,
  openBankingLinkLoading: PropTypes.bool.isRequired,
  openBankingAccountError: PropTypes.bool.isRequired,
};

export default BankAddEditModal;

const HeaderHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const FieldsHolder = styled.div``;
