import { t } from '@lingui/macro';

const filterRows = (item, term) => item.term <= term;

export const paymentReconciliationFormatter = ({ data, subTabEntry, term }) => {
  const items = [];

  let settlementsRowsAltinnSum = 0;
  let settlementsRowsGLSum = 0;

  const openingBalances = data?.settlementsOpeningBalances?.find(
    (el) => el.type === subTabEntry,
  );

  data?.payrollSettlements
    ?.filter((item) => filterRows(item, term))
    ?.forEach((item) => {
      const settlementItem =
        item.settlements.find(
          (settlement) => settlement.type === subTabEntry,
        ) || {};

      const isLastTerm = item.term === term;

      if (item.term !== 0) {
        settlementsRowsAltinnSum += settlementItem.reportedTotal;

        if (!isLastTerm) {
          settlementsRowsGLSum += settlementItem.glTotal;
        }
      }

      let gl = isLastTerm ? '' : settlementItem.glTotal;
      let deviation = isLastTerm
        ? ''
        : -(settlementItem.glTotal - settlementItem.reportedTotal);

      if (item.term === 0) {
        gl = openingBalances?.amount ? -openingBalances.amount : 0;
        deviation += !openingBalances?.amount ? 0 : -openingBalances.amount;
      }

      items.push({
        name: item.term === 0 ? settlementItem.name : t`Term - ${item.term}`,
        reportedTotal: [0].includes(item.term)
          ? ''
          : settlementItem.reportedTotal,
        gl,
        deviation,
        hiddenButton: isLastTerm,
        originalItem: item,
        settlementItem,
      });
    });

  const predefinedTotal = {
    name: 'Total:',
    reportedTotal: settlementsRowsAltinnSum,
    gl: settlementsRowsGLSum,
    deviation: Number(-(settlementsRowsGLSum - settlementsRowsAltinnSum)),
  };

  return { items, predefinedTotal };
};

export default paymentReconciliationFormatter;
