export const dashboardChartOptions = (screenSize, approved, controlled) => {
  const defaults = {
    ticksFontSize: 11,
    legendFontSize: 12,
    datalabelsFontSize: 10,
    legendBoxWidth: 7,
    ticksPadding: 10,
  };

  // FULL HD
  if (screenSize >= 1920 && screenSize < 2048) {
    defaults.ticksFontSize = 12;
    defaults.legendFontSize = 12;
    defaults.datalabelsFontSize = 11;
    defaults.legendBoxWidth = 8;
    defaults.ticksPadding = 12;
    // 2K
  } else if (screenSize >= 2048 && screenSize < 3840) {
    defaults.ticksFontSize = 13;
    defaults.legendFontSize = 13;
    defaults.datalabelsFontSize = 12;
    defaults.legendBoxWidth = 8;
    defaults.ticksPadding = 12;

    // 4K
  } else if (screenSize >= 3840) {
    defaults.ticksFontSize = 17;
    defaults.legendFontSize = 17;
    defaults.datalabelsFontSize = 14;
    defaults.legendBoxWidth = 10;
    defaults.ticksPadding = 16;
  }

  return {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: {
        top: defaults.paddingTop,
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          font: {
            size: defaults.ticksFontSize,
          },
          padding: defaults.ticksPadding,
          color: '#b7bdc6',
        },
      },
      y: {
        grid: {
          drawBorder: false,
          showLabelBackdrop: false,
          borderDash: [3, 20],
          drawTicks: false,
          color: '#e7e6e6',
        },
        ticks: {
          font: {
            size: defaults.ticksFontSize,
          },
          padding: defaults.ticksPadding,
          callback: (label) => `${label}%`,
          color: '#b7bdc6',
        },
      },
    },

    plugins: {
      tooltip: {
        padding: 10,
        callbacks: {
          label: (context) => {
            const {
              dataIndex,
              dataset: { label, backgroundColor },
            } = context;

            const value =
              backgroundColor === '#34ba84'
                ? approved[dataIndex]
                : controlled[dataIndex];

            return `${label}: ${value}`;
          },
          // footer: () => 'Sebastian Jara - 50% \nDaniel Jara - 50%',
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: defaults.legendBoxWidth,
          font: {
            weight: '600',
            size: defaults.legendFontSize,
          },
          color: '#2f3a4d',
        },
      },

      datalabels: {
        color: '#616161',
        formatter: (value) => `${value}%`,
        align: 'top',
        anchor: 'end',
        offset: 3,
        font: {
          weight: 'bold',
          size: defaults.datalabelsFontSize,
        },
      },
    },
  };
};

export default { dashboardChartOptions };
