import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AddEditAssetModal } from 'components';

import { fromResource } from 'store/selectors';
import { months, depreciationEventTypes } from 'services/shapes';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  putAssetLinearApi,
  putAssetBalanceApi,
  postAssetLinearApi,
  postAssetBalanceApi,
  getAssetCategoriesApi,
  getAssetTransactionsApi,
} from 'services/apihelpers';

function AddEditAssetModalContainer(props) {
  const { item, closeModal } = props;
  const [depreciationEvents, setDepreciationEvents] = useState(
    item.depreciationEvents || [],
  );
  const params = useParams();
  const dispatch = useDispatch();
  const assetCategories = useSelector((state) =>
    fromResource.getList(state, getAssetCategoriesApi),
  );

  const editedAssetCategories = Array.isArray(assetCategories)
    ? assetCategories.map((category) => {
        const accountIds = category.accounts.map(
          (account) => account.accountId,
        );
        let accountsString = '';

        if (accountIds.length > 1) {
          accountsString = t` – Accounts ${accountIds.join(', ')}`;
        } else if (accountIds.length === 1) {
          accountsString = t` – Account ${accountIds[0]}`;
        }

        // Create a new category object with the updated assetCategoryName
        return {
          ...category,
          assetCategoryName: category.assetCategoryName + accountsString,
        };
      })
    : [];

  const assetTransactions = useSelector((state) =>
    fromResource.getList(state, getAssetTransactionsApi),
  );

  const onSubmit = async (formData) => {
    const postUrl =
      formData.assetType === 'BALANCE'
        ? postAssetBalanceApi
        : postAssetLinearApi;
    const updateUrl =
      formData.assetType === 'BALANCE' ? putAssetBalanceApi : putAssetLinearApi;
    const body = {
      depreciationEvents,
      lineIds: formData.lineIds || [],
      companyId: params.companyId,
      assetName: formData.assetName,
      baseAmount: +formData.baseAmount,
      dateOfPurchase: formData.dateOfPurchase,
      purchaseAmount: +formData.baseAmount,
      assetCategoryId: formData.assetCategoryId,
      dateOfDepreciationStart: formData.dateOfDepreciationStart,
    };

    if (formData.assetType === 'BALANCE') {
      body.percentage = +formData.percentage;
      body.terminationLimit = +formData.terminationLimit;
    } else {
      body.periodsToDepreciate = formData.periodsToDepreciate;
      body.depreciationFrequency = formData.depreciationFrequency;
    }

    try {
      if (!item.assetId) {
        await dispatch(resourceCreateRequest(postUrl, body));

        toast.success(t`Asset created successfully`);
      } else {
        body.assetId = item.assetId;

        await dispatch(resourceUpdateRequest(updateUrl, null, body));

        toast.success(t`Asset updated successfully`);
      }

      closeModal(false, true);
    } catch (err) {
      toast.error(
        err?.response?.headers?.get('Response-Message') || err?.message,
      );
    }
  };
  const fetchTransactions = (assetCategoryId) => {
    dispatch(
      resourceListReadRequest(getAssetTransactionsApi, {
        assetCategoryId,
      }),
    );
  };
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getAssetCategoriesApi, {
        companyId: params.companyId,
      }),
    );

    if (item.assetCategoryId) {
      fetchTransactions(item.assetCategoryId);
    }
  };
  const addDepreciationEvent = () => {
    const date = new Date();

    setDepreciationEvents([
      ...depreciationEvents,
      {
        year: date.getFullYear(),
        month: months()[date.getMonth()].id,
        amount: 0,
        depreciationEventType: depreciationEventTypes()[0].id,
      },
    ]);
  };
  const removeDepreciationEvent = (indexToRemove) => {
    setDepreciationEvents(
      depreciationEvents.filter((_, index) => index !== indexToRemove),
    );
  };
  const handleDepreciationEventChange = (e, index) => {
    const newArr = depreciationEvents.map((event) => ({ ...event }));

    if (typeof +e.target.value === 'number' && !Number.isNaN(+e.target.value)) {
      newArr[index][e.target.id] = +e.target.value;
    } else {
      newArr[index][e.target.id] = e.target.value;
    }

    setDepreciationEvents(newArr);
  };

  useEffect(fetchData, []);

  return (
    <AddEditAssetModal
      {...{
        item,
        closeModal,
        assetCategories: editedAssetCategories,
        assetTransactions,
        depreciationEvents,
        onSubmit,
        fetchTransactions,
        addDepreciationEvent,
        removeDepreciationEvent,
        handleDepreciationEventChange,
      }}
    />
  );
}

AddEditAssetModalContainer.propTypes = {
  item: PropTypes.shape({}),
  closeModal: PropTypes.func.isRequired,
};

AddEditAssetModalContainer.defaultProps = {
  item: {},
};

export default AddEditAssetModalContainer;
