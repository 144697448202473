import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components/macro';

import { TABLET, DESKTOP, LARGE_SCREENS, FOUR_K } from 'constants/mediaSizes';

function LottieBox(props) {
  const { width, animation, direction, title, status } = props;

  return (
    <Container direction={direction}>
      <LottieAnimation
        width={width}
        style={{ margin: 0 }}
        height="auto"
        options={{
          loop: false,
          autoplay: true,
          animationData: animation,
        }}
      />
      <Text direction={direction}>
        <Title>{title}</Title>
        <Status>{status}</Status>
      </Text>
    </Container>
  );
}

export default React.memo(LottieBox);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: center;
  justify-content: ${({ direction }) =>
    direction === 'column' ? 'center' : 'space-between'};

  @media (max-width: ${TABLET.max}px) {
    padding: 10px 0;
  }

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 12px;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 13px;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
  }
`;

const LottieAnimation = styled(Lottie)`
  margin: 0;
  height: auto;
`;

const Text = styled.div`
  display: flex;
  align-items: ${({ direction }) =>
    direction === 'column' ? 'center' : 'flex-start'};
  flex-direction: column;
  margin-top: 0.7em;

  @media (max-width: ${DESKTOP.max}px) {
    margin-top: 0.8em;
  }
`;

const Title = styled.p`
  padding: 0;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 1.21em;
  font-weight: bold;
  letter-spacing: 0.7px;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 1.15em;
  }
`;

const Status = styled.p`
  margin: 0;
  padding: 0;

  font-size: 0.92em;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  text-align: center;

  color: #c1c1c1;
`;
