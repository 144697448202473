import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

import {
  formatNumber,
  getTranslatedPayrollZoneElement,
} from 'services/helpers/index';

/**
 * Vacation Pay Zones Grid
 */
export const columns = (reportMode, approved, readOnly) => [
  {
    width: reportMode ? 360 : 500,
    Header: t`Tax Zone`,
    accessor: 'zone',
    disableSortBy: true,
    className: '',
    cell: {
      type: 'input',
      converter: (value) =>
        value ? `${getTranslatedPayrollZoneElement(value)}` : '',
      editable: (item) => item.id && item.customDeviation && !reportMode,
      disabled: approved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      inputType: 'text',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 220 : 240,
    Header: t`Percentage`,
    accessor: 'rate',
    disableSortBy: true,
    withTooltip: !reportMode,
    className: 'flex-right',
    cell: {
      type: 'input',
      converter: (value) =>
        value !== '' ? `${formatNumber(value).formatted} %` : '',
      editable: (item) => item.id && item.customDeviation && !reportMode,
      disabled: approved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      inputType: 'number',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 360 : 500,
    Header: t`Base amount SS`,
    accessor: 'baseSocialSecurity',
    disableSortBy: true,
    withTooltip: !reportMode,
    className: 'flex-right',
    cell: {
      type: 'input',
      editable: (item) =>
        item.isEditable ||
        (item.id && !reportMode && !item.readOnly) ||
        (item.id && item.customDeviation && !reportMode),
      disabled: approved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      inputType: 'number',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 360 : 500,
    Header: t`Social Security Amount`,
    sortType: 'basic',
    accessor: 'calculatedSocialSecurity',
    disableSortBy: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  ...(!approved && !readOnly
    ? [
        {
          width: 80,
          hidden: reportMode,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete this row`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
