import { t } from '@lingui/macro';

const filterByPeriod = ({ item, bimonthly, accumulated, activePeriods }) => {
  if (accumulated) {
    return +item.period <= +activePeriods;
  }

  if (bimonthly) {
    return [activePeriods - 1, +activePeriods].includes(+item.period);
  }

  return +item.period === +activePeriods;
};

export const balanceReconciliationFormatter = ({
  data = {},
  bimonthly,
  accumulated,
  activePeriods,
}) => {
  const ssre =
    data?.reportedElements
      ?.filter((item) =>
        filterByPeriod({ item, accumulated, bimonthly, activePeriods }),
      )
      .reduce((sum, row) => sum + row.amount * (row.percentage / 100), 0) || 0;

  const ssgl =
    data?.glAccounts?.reduce(
      (sum, row) =>
        sum +
        row.lines
          .filter((item) =>
            filterByPeriod({ item, accumulated, bimonthly, activePeriods }),
          )
          .reduce((lineSum, line) => lineSum + line.amount, 0),
      0,
    ) || 0;

  return [
    {
      ssre,
      ssgl,
      deviation: ssre - ssgl,
      description: t`Social Security`,
    },
  ];
};

export default balanceReconciliationFormatter;
