import React, { useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { MoveGroupCompaniesModal } from 'components';

import { resourceDeleteRequest, resourceUpdateRequest } from 'store/actions';
import {
  deleteGroupCompaniesApi,
  setCompanyGroupCompaniesApi,
} from 'services/apihelpers';

function MoveGroupCompaniesModalContainer(props) {
  const { item, data, closeModal, refetch } = props;
  const dispatch = useDispatch();
  const companyGroups = (data.availableCompanyGroups || []).map((c) => ({
    id: c.groupId,
    label: c.name,
  }));
  const [selectedCompanyGroups, setSelectedCompanyGroups] = useState([]);

  const onSubmit = async () => {
    await dispatch(
      resourceDeleteRequest(
        `${deleteGroupCompaniesApi}?${queryString.stringify({
          groupId: data.groupId,
          companyId: item.companyId,
        })}`,
      ),
    );

    if (selectedCompanyGroups.length) {
      await dispatch(
        resourceUpdateRequest(setCompanyGroupCompaniesApi, null, {
          companyId: item.companyId,
          companyGroupIds: selectedCompanyGroups.map((c) => c.id),
        }),
      );
    }

    closeModal();
    refetch();
  };
  const handleChange = (values, type) => {
    if (type === 'companyGroups') {
      setSelectedCompanyGroups(values);
    }
  };

  return (
    <MoveGroupCompaniesModal
      {...{
        item,
        closeModal,
        companyGroups,
        selectedCompanyGroups,
        onSubmit,
        handleChange,
      }}
    />
  );
}

export default MoveGroupCompaniesModalContainer;
