import React from 'react';
import { t } from '@lingui/macro';
import { MdOutlineLink } from '@react-icons/all-files/md/MdOutlineLink';

export const columns = () => [
  {
    width: 100,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: '',
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 80,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'mapItem',
          icon: () => <MdOutlineLink />,
          tooltip: t`Map`,
        },
      ],
    },
  },
];

export default { columns };
