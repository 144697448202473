import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { toPercentage } from 'services/helpers/index';

/**
 * Vacation Pay Basis Grid
 */
export const columns = ({ reportMode, readOnly, approved }) => [
  {
    width: reportMode ? 280 : 400,
    Header: t`Vacation pay element`,
    accessor: 'vpElement',
    disableSortBy: true,
    withTooltip: !reportMode,
    className: '',
  },
  {
    width: 120,
    Header: t`Basis %`,
    accessor: 'percentage',
    disableSortBy: true,
    className: '',
    cell: {
      converter: (value) => (value ? `${toPercentage(value)} %` : ''),
    },
  },
  {
    width: 230,
    Header: t`Vacation Pay Base Amount`,
    accessor: 'vpBaseAmount',
    disableSortBy: true,
    className: 'flex-right',
    cell: {
      type: 'input',
      editable: (item) => item.id && !reportMode && !readOnly && !item.readOnly,
      disabled: approved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      inputType: 'number',
      disableClick: true,
    },
  },
  {
    width: 120,
    Header: t`Amount`,
    accessor: 'amount',
    disableSortBy: true,
    className: 'flex-right',
    cell: {
      type: 'input',
      editable: (item) =>
        (item.id && item.customDeviation && !reportMode && !readOnly) ||
        (item.id && !reportMode && !readOnly && item.vacationPayList),
      disabled: approved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      inputType: 'number',
      disableClick: true,
    },
  },
  ...(!readOnly && !approved
    ? [
        {
          width: 80,
          hidden: reportMode,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete this row`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
