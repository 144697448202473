import React from 'react';
import { t } from '@lingui/macro';
import { MdOutlineLinkOff } from '@react-icons/all-files/md/MdOutlineLinkOff';
import { MdOutlineLink } from '@react-icons/all-files/md/MdOutlineLink';
import { RiCalendarCheckLine } from '@react-icons/all-files/ri/RiCalendarCheckLine'; // eslint-disable-line max-len

/**
 * Bank Template GL Grid
 */
export const columns = ({ isReadOnly }) => [
  ...(!isReadOnly
    ? [
        {
          width: 40,
          accessor: 'selection',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'selection',
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 140,
    Header: t`Value Date`,
    accessor: 'postedDate',
    className: 'flex-left',
    cell: {
      type: 'date',
    },
  },
  {
    width: 105,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    withTooltip: true,
  },
  {
    width: 130,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Amount`,
    accessor: 'amount',
    sortType: 'basic',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 85,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'reconciledTransactions',
                visible: (item) => !!item.reconciled,
                icon: () => <MdOutlineLink />,
                tooltip: t`View reconciliation`,
              },
              {
                id: 'unreconcile',
                visible: (item) => !!item.reconciled,
                icon: () => <MdOutlineLinkOff />,
                tooltip: t`Unreconcile`,
              },
              {
                id: 'approvedIn',
                visible: (item) => item?.grayLine,
                icon: () => <RiCalendarCheckLine />,
                tooltip: (item) => item?.approvedIn.slice(0, 7),
              },
            ],
          },
        },
      ]
    : []),
  {
    width: 100,
    Header: t`Match ID`,
    accessor: 'reconciliationId',
    className: 'flex-right',
    cell: {
      converter: (value) => value || '',
    },
  },
];

export default { columns };
