import { t } from '@lingui/macro';

import { filterByRole, filterByActivatedStatuses } from 'services/shapes';

export const adminUsersSettingsFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['name', 'loginUsername', 'contactNo'],
  },
  {
    id: 'roleName',
    type: 'multiSelect',
    label: t`Role`,
    options: filterByRole(),
    disableSort: true,
    placeholder: t`Please select Role`,
  },
  {
    id: 'recordStatus',
    type: 'multiSelect',
    label: t`Activated`,
    options: filterByActivatedStatuses(),
    disableSort: true,
    placeholder: t`Please select Activated`,
  },
];

export default adminUsersSettingsFilters;
