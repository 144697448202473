import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { ButtonGroup } from 'react-bootstrap';

import { Logo, Button, Heading } from 'components';

export function CardPDFTitle(props) {
  const { title, params, company, reportMode } = props;

  return reportMode ? (
    <>
      <TitleHolder>
        <LogoHolder>
          <Logo />
        </LogoHolder>
        <TitleRightPart>
          <ButtonHolder>
            <ButtonGroup bsSize="small">
              <Button>
                <Trans>Accounting Year - {params.accountingYear}</Trans>
              </Button>
            </ButtonGroup>
          </ButtonHolder>
          <ButtonHolder>
            <ButtonGroup bsSize="small">
              <Button kind="info">
                <Trans>Accounting Period - {params.accountingPeriod}</Trans>
              </Button>
            </ButtonGroup>
          </ButtonHolder>
          <ButtonGroup bsSize="small">
            <Button kind="success">{company.currentCompanyName}</Button>
          </ButtonGroup>
        </TitleRightPart>
      </TitleHolder>
      {!!title && (
        <Heading strong level={4} alignment="center">
          {title}
        </Heading>
      )}
    </>
  ) : (
    <Heading level={3} className="card-title">
      {title}
    </Heading>
  );
}

export default CardPDFTitle;

CardPDFTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  params: PropTypes.shape({}),
  company: PropTypes.shape({}),
  reportMode: PropTypes.bool,
};

CardPDFTitle.defaultProps = {
  params: {},
  company: {},
  reportMode: false,
};

const LogoHolder = styled.div`
  width: 30%;
  margin-right: 20px;
`;

const TitleHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleRightPart = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonHolder = styled.div`
  margin: 0 5px;
`;
