import React from 'react';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings } from 'components';

import { payrollReportedSalaryDetailsColumns } from 'services/tableshapes';

function PayrollReportedSalaryDetails(props) {
  const {
    rows,
    search,
    loading,
    isReadOnly,
    handleAction,
    accountingYear,
    predefinedTotal,
    payrollApproved,
    actionSubmitting,
    additionalTotalRows,
  } = props;
  const { bimonthly, activePeriods, reportMode } = search;

  const period = bimonthly ? Math.ceil(+activePeriods / 2) : activePeriods;

  return (
    <CustomTableWithAutoSettings
      {...{
        loading,
        data: rows,
        reportMode,
        handleAction,
        actionSubmitting,
        hideFilters: true,
        enableGroups: true,
        loadingId: 'id',
        tableId: 'reportedSalaryDetails',
        groupId: 'groupId',
        totalName: t`TOTAL SUM:`,
        additionalTotalRows,
        predefinedTotal,
        columns: payrollReportedSalaryDetailsColumns({
          reportMode,
          isReadOnly,
          payrollApproved,
        }),
        headerConfigs: {
          // eslint-disable-next-line max-len
          exportFileName: t`Reported Salary Details - ${accountingYear}, ${period}`,
        },
      }}
    />
  );
}

export default PayrollReportedSalaryDetails;
