import React from 'react';
import { t } from '@lingui/macro';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';

export const customTableBodyTableProps = (action, visible) => ({
  page: [
    {
      getToggleRowSelectedProps: () => ({ onChange: jest.fn() }),
      getRowProps: () => {},
      toggleRowSelected: jest.fn(),
      isSelected: true,
      id: '4',
      original: {
        name: 'Other assets',
        accountId: '1086',
        date: '12/11/2019',
      },
      cells: [
        {
          render: () => '1086',
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            width: 410,
            Header: 'Other',
            withTooltip: true,
            id: 'accountId',
            cell: {
              type: 'number',
              disableClick: true,
              action,
              icon: () => <HiCheck />,
            },
          },
          value: '1086',
        },
        {
          render: () => 'Other assets',
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            width: 410,
            Header: 'Description',
            withTooltip: true,
            id: 'name',
            cell: {
              converter: () => 'Description',
            },
          },
          value: 'Other assets',
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            id: 'date',
            cell: {
              type: 'date',
            },
          },
          value: '12/31/2019',
        },
      ],
      values: {
        accountId: '1085',
        name: 'Other intangible assets',
      },
    },
    {
      getToggleRowSelectedProps: () => ({ onChange: jest.fn() }),
      getRowProps: () => {},
      toggleRowSelected: jest.fn(),
      isSelected: false,
      id: '3',
      original: {
        name: 'Other intangible assets',
        accountId: '1085',
        trash: true,
      },
      cells: [
        {
          render: () => '1085',
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            width: 410,
            Header: 'Description',
            withTooltip: true,
            id: 'accountId',
          },
          value: '1085',
        },
        {
          render: () => 'Other intangible assets',
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            width: 410,
            Header: 'Description',
            withTooltip: true,
            id: 'name',
          },
          value: 'Other intangible assets',
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            cell: {
              type: 'input',
              editable: true,
              inputType: 'text',
              placeholder: 'Add comment:',
              disableClick: true,
            },
            id: 'comment',
          },
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            Header: 'Status',
            cell: {
              type: 'boolean',
            },
            id: 'approved',
          },
          value: true,
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            Header: 'Selection',
            cell: {
              type: 'selection',
            },
            id: 'sel',
          },
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            cell: {
              type: 'actions',
              disableClick: true,
              actions: [
                {
                  id: 'dialog',
                  icon: () => <HiCheck />,
                  tooltip: 'Open dialogue',
                  accessor: 'hasDialogue',
                  visible,
                },
                {
                  id: 'trash',
                  icon: () => <HiCheck />,
                  tooltip: 'Trash',
                  accessor: 'hasTrash',
                  visible: 'trash',
                },
              ],
            },
            id: 'actions',
          },
        },
      ],
      values: {
        accountId: '1085',
        name: 'Other intangible assets',
      },
    },
    {
      getToggleRowSelectedProps: () => ({ onChange: jest.fn() }),
      getRowProps: () => {},
      toggleRowSelected: jest.fn(),
      isSelected: false,
      id: '5',
      original: {
        name: 'Other',
        accountId: '1087',
      },
      cells: [
        {
          render: () => '1087',
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            width: 410,
            Header: 'Description',
            withTooltip: true,
            id: 'accountId',
          },
          value: '1087',
        },
        {
          getCellProps: () => ({ onClick: () => {} }),
          column: {
            cell: {
              type: 'actions',
              disableClick: true,
              actions: [
                {
                  id: 'dialog',
                  icon: () => <HiCheck />,
                  tooltip: 'Open dialogue',
                  accessor: 'hasDialogue',
                },
                {
                  id: 'trash',
                  icon: () => <HiCheck />,
                  tooltip: 'Trash',
                  accessor: 'hasTrash',
                },
                {
                  id: 'add',
                  icon: () => <HiCheck />,
                  tooltip: 'Add',
                  accessor: 'hasAdd',
                },
                {
                  id: 'save',
                  icon: () => <HiCheck />,
                  tooltip: 'Save',
                  accessor: 'hasSave',
                },
              ],
            },
            id: 'actions',
          },
        },
      ],
      values: {
        accountId: '1087',
        name: 'Other',
      },
    },
  ],
  columns: [
    {
      Header: 'ID',
    },
    {
      Header: 'Description',
    },
  ],
  prepareRow: () => {},
  getTableBodyProps: () => {},
});

export const filterConfings = () => [
  {
    id: 'accountTemplateCode',
    label: 'Templates',
    type: 'multiSelect',
    options: [
      { id: 'BANK', label: 'Bank' },
      { id: 'GENERAL', label: 'General Ledger' },
    ],
  },
  {
    id: 'acc',
    type: 'multiSelect',
    options: [{ id: [], label: 'Bank' }],
  },
  {
    id: 'input',
    label: 'Search',
    type: 'input',
    placeholder: 'Type to search',
    fieldsToSearch: ['accountNumber', 'openingBalance'],
    size: 'sm',
  },
  {
    id: 'range',
    label: 'Range',
    type: 'range',
  },
  {
    id: 'bool',
    label: 'Bool',
    type: 'boolean',
  },
  {
    id: 'none',
    type: 'none',
  },
];

const frequencyStep = {
  monthly: 1,
  biMonthly: 2,
  quarterly: 3,
  tertiary: 4,
  halfYearly: 6,
  yearly: 12,
};

const months = () => ({
  monthly: t`P`,
  biMonthly: t`T`,
  quarterly: t`Q`,
  tertiary: t`T`,
  halfYearly: t`HY`,
  yearly: t`Y`,
});

const getNames = (year, type) => {
  const result = [];
  const step = frequencyStep[type];
  const translatedMonths = months();

  for (let i = 0; i < 12; i += step) {
    result.push(`${translatedMonths[type]}${result.length + 1}`);
  }

  return result;
};

export const labels = (year, periodType) => getNames(year, periodType);
