import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  ButtonGroup,
  OverlayTrigger,
  Tooltip as BTooltip,
} from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import { IoMdSettings } from '@react-icons/all-files/io/IoMdSettings';
import { GoCloudUpload } from '@react-icons/all-files/go/GoCloudUpload';
import { MdUpdate } from '@react-icons/all-files/md/MdUpdate';
import { AiOutlineFilePdf } from '@react-icons/all-files/ai/AiOutlineFilePdf';

import { Button, Spinner, ButtonIcon } from 'components';

import FileUploadModal from 'containers/FileUploadModal';
import ReconciledListPreviewModal from 'containers/ReconciledListPreviewModal';

function TableModals(props) {
  const {
    user,
    data,
    search,
    history,
    toRoute,
    company,
    account,
    roleType,
    toggleModal,
    onModalClose,
    pdfGenerating,
    openConfigModal,
    refreshParent,
    onAutoReconcile,
    filesShortPreview,
    enablePDFDownload,
    handleDownloadPDF,
    handleAutoReconcile,
    enableAutoReconcile,
    enableDocumentUpload,
    handleDocumentUpload,
    loadingAutoReconcile,
    handleStatementUpload,
    enableBankTemplateModals,
  } = props;

  if (roleType.isReadOnly) return null;

  const renderTooltip = () => {
    const slicedArray = filesShortPreview.slice(0, 5);

    return slicedArray?.map((item, i) =>
      i < slicedArray.length - 1 ? (
        <React.Fragment key={i}>
          {item} <br />
        </React.Fragment>
      ) : (
        item
      ),
    );
  };

  return (
    <>
      <ButtonGroup>
        {!roleType.isReadOnly &&
          (enableAutoReconcile || enableBankTemplateModals) && (
            <Button
              kind="info"
              size="sm"
              onClick={() => {
                if (typeof onAutoReconcile === 'function') {
                  onAutoReconcile();
                } else {
                  toggleModal('handleAutoReconcile');
                }
              }}
            >
              {loadingAutoReconcile ? (
                <Spinner type="primary" size="md" />
              ) : (
                <>
                  <ButtonIcon as={MdUpdate} /> <Trans>Auto Reconcile</Trans>
                </>
              )}
            </Button>
          )}
        {enableBankTemplateModals && (
          <Button kind="info" size="sm" onClick={openConfigModal}>
            <ButtonIcon as={IoMdSettings} />{' '}
            <Trans>Reconciliation Settings</Trans>
          </Button>
        )}
        {enableDocumentUpload && (
          <Button
            kind="info"
            size="sm"
            onClick={() => toggleModal('handleDocumentUpload')}
          >
            <>
              <ButtonIcon as={GoCloudUpload} /> <Trans>Upload document</Trans>
              {filesShortPreview && filesShortPreview.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<BTooltip id="tooltip">{renderTooltip()}</BTooltip>}
                >
                  <NumberFiles>{filesShortPreview.length}</NumberFiles>
                </OverlayTrigger>
              )}
            </>
          </Button>
        )}
        {enablePDFDownload && (
          <Button kind="info" size="sm" onClick={handleDownloadPDF}>
            {pdfGenerating ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                <ButtonIcon as={AiOutlineFilePdf} /> <Trans>Download PDF</Trans>
              </>
            )}
          </Button>
        )}
      </ButtonGroup>
      {handleAutoReconcile && enableAutoReconcile && (
        <ReconciledListPreviewModal
          account={data.accountID}
          company={company}
          showModal={handleAutoReconcile}
          template={data.accountTemplateCode}
          toggleModal={() => toggleModal('handleAutoReconcile')}
          onReconcileClose={onModalClose}
          roleType={roleType}
        />
      )}
      {handleDocumentUpload && (
        <FileUploadModal
          {...{
            user,
            company,
            account,
            fileCategory: 'ACCOUNT',
            roleType,
            refreshParent,
          }}
          modalTitle={
            enableBankTemplateModals
              ? t`Bank Template Documents - ${account}`
              : t`General Template Documents - ${account}`
          }
          showModal={handleDocumentUpload}
          toggleModal={(_, __, acc, files) =>
            toggleModal('handleDocumentUpload', acc, files)
          }
          onFileUploadClose={onModalClose}
        />
      )}
      {enableBankTemplateModals && handleStatementUpload && (
        <FileUploadModal
          {...{
            user,
            company,
            account,
            history,
            search,
            toRoute,
            fileCategory: 'BANK_STATEMENT',
            roleType,
            refreshParent,
          }}
          modalTitle={t`Bank Template Statements Import`}
          showModal={handleStatementUpload}
          toggleModal={() => toggleModal('handleStatementUpload')}
          uploadFilesTo="v1/secure/bank-transaction"
          onFileUploadClose={onModalClose}
        />
      )}
    </>
  );
}
TableModals.propTypes = {
  /** @type {shape} history Router object */
  history: PropTypes.shape({}),
  /** @type {shape} location Router object */
  search: PropTypes.shape({}),
  /** @type {shape} data */
  data: PropTypes.shape({}),
  /** @type {shape} company */
  company: PropTypes.shape({}).isRequired,
  /** @type {shape} user */
  user: PropTypes.shape({}).isRequired,
  /** @type {func} toRoute */
  toRoute: PropTypes.func,
  /** @type {func} toggleModal */
  toggleModal: PropTypes.func.isRequired,
  /** @type {func} onModalClose */
  onModalClose: PropTypes.func.isRequired,
  /** @type {bool} account */
  account: PropTypes.string.isRequired,
  /** @type {bool} handleAutoReconcile */
  handleAutoReconcile: PropTypes.bool,
  /** @type {bool} enableAutoReconcile */
  enableAutoReconcile: PropTypes.bool,
  /** @type {bool} enableBankTemplateModals */
  enableBankTemplateModals: PropTypes.bool,
  /** @type {bool} handleDocumentUpload */
  handleDocumentUpload: PropTypes.bool,
  /** @type {bool} handleStatementUpload */
  handleStatementUpload: PropTypes.bool,
  /** @type {bool} enableDocumentUpload */
  enableDocumentUpload: PropTypes.bool,
  /** @type {shape} roleType */
  roleType: PropTypes.shape({}).isRequired,
};

TableModals.defaultProps = {
  history: undefined,
  search: undefined,
  data: undefined,
  toRoute: undefined,
  handleAutoReconcile: false,
  enableAutoReconcile: false,
  enableBankTemplateModals: false,
  handleDocumentUpload: false,
  handleStatementUpload: false,
  enableDocumentUpload: false,
};

export default TableModals;

const NumberFiles = styled.p`
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  background-color: #7ac29a;
  border-radius: 50%;
  font-size: 10px;
  padding: 3px 7px;
  color: white;
`;
