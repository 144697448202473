import React, { useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';

import { IoMdHelpCircle } from '@react-icons/all-files/io/IoMdHelpCircle';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { ButtonGroup } from 'react-bootstrap';

import { Button } from 'components';

import BalanceDataModal from 'containers/BalanceDataModal';
import ReleaseNoteModal from 'containers/ReleaseNoteModal';
import ChangeCompanyYear from 'containers/ChangeCompanyYear';
import ChangeCompanyModal from 'containers/ChangeCompanyModal';
// import DeadlineCalendarModal from 'containers/DeadlineCalendarModal';

import getErrorMessage from 'services/helpers/getErrorMessage';
import { lastOpenReleaseDateApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/resource/actions';

import client from 'services/utils/sanity';

function CollapsableMenuGroup(props) {
  const { user } = props;
  const dispatch = useDispatch();

  const [releaseNote, setReleaseNote] = useState(null);

  const fetchData = async () => {
    client
      .fetch(
        `*[_type == "release" && language == "${
          user?.languageCode || i18n.locale
        }"] {
        date,
        body,
        title,
        publish,
        language,
        _updatedAt,
      }`,
      )
      .then((data) => {
        if (data?.length > 0) {
          setReleaseNote(data[0]);
        }
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      });

    try {
      await dispatch(resourceListReadRequest(lastOpenReleaseDateApi));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const openHelpPage = () =>
    window.open(
      'https://help.accountflow.com/',
      '_blank',
      'noopener,noreferrer',
    );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ButtonsContainer>
      {/* <DeadlineCalendarModal /> */}
      <ButtonGroup bsSize="small">
        <HelpButton fill onClick={openHelpPage} title={t`Help`}>
          <HelpIcon />
        </HelpButton>
      </ButtonGroup>
      {releaseNote && releaseNote?.publish && (
        <ReleaseNoteModal {...props} releaseNote={releaseNote} />
      )}
      <ChangeCompanyYear {...props} />
      <BalanceDataModal {...props} />
      <ChangeCompanyModal {...props} />
    </ButtonsContainer>
  );
}

export default CollapsableMenuGroup;

const HelpIcon = styled((props) => <IoMdHelpCircle {...props} />)`
  font-size: 18px;
  margin-bottom: -4px;
`;

const HelpButton = styled(Button)`
  background: #b1b1b1 !important;
  border-color: #b1b1b1 !important;
  padding: 4.5px 8px !important;

  :hover {
    background: #737373 !important;
    border-color: #737373 !important;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 18px;
  gap: 5px;

  @media (max-width: 992px) {
    justify-content: flex-start;
    padding-top: 0;
    position: absolute;
    left: 10px;
    top: 22px;
  }

  @media (max-width: 500px) {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      display: none !important;
    }
  }
`;
