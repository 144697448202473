import algoliaSearch from 'algoliasearch';

const client = algoliaSearch(
  process.env.ALGOLIA_APPLICATION_ID,
  process.env.ALGOLIA_ADMIN_API_KEY,
);

export const algoliaIndex = client.initIndex(
  process.env.BALANCES_ENV || 'local',
);

export default algoliaIndex;
