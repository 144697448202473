import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal, Row } from 'react-bootstrap';

import {
  Field,
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import {
  PayrollDrilldownGLColumns,
  PayrollDrilldownAltinnColumns,
} from 'services/tableshapes';
import { DESKTOP, TABLET } from 'constants/mediaSizes';
import { formatNumber, makeAggregated } from 'services/helpers';

function PayrollDrilldownModal(props) {
  const {
    row,
    data,
    period,
    loading,
    bimonthly,
    accumulated,
    showDetails,
    toggleModal,
    toggleShowDetails,
  } = props;

  const memoizedData = useMemo(() => {
    const newData = {
      altinn: [],
      gl: [],
    };

    if (data?.altinnColumn?.lines?.length) {
      newData.altinn = data?.altinnColumn?.lines?.filter(
        (line) => !!line.amount,
      );
    }

    if (data?.glColumn?.lines?.length) {
      newData.gl = data?.glColumn?.lines?.filter((line) => !!line.amount);
    }

    if (!showDetails) {
      newData.altinn = makeAggregated(newData.altinn, 'name');
      newData.gl = makeAggregated(newData.gl, 'accountNumber');
    }

    return newData;
  }, [JSON.stringify(data), showDetails]);

  const content = (
    <>
      <GlobalContainer>
        <TableContainer width={50}>
          <Heading alignment="center" level={5}>
            {row?.manual ? 'Skat Grunnlag' : <Trans>Altinn Base</Trans>}
          </Heading>
          <CustomTableWithAutoSettings
            {...{
              loading,
              isModal: true,
              data: memoizedData?.altinn,
              columns: PayrollDrilldownAltinnColumns(showDetails),
              tableId: 'payrollDrillDownAltinn',
              loadingId: 'description',
              reportMode: false,
              hideFilters: true,
              headerConfigs: {
                exportFileName: row?.manual ? 'Skat Grunnlag' : t`Altinn Base`,
              },
            }}
          />
        </TableContainer>
        <TableContainer width={50}>
          <Heading alignment="center" level={5}>
            <Trans>GL Base</Trans>
          </Heading>
          <CustomTableWithAutoSettings
            {...{
              loading,
              isModal: true,
              data: memoizedData?.gl,
              columns: PayrollDrilldownGLColumns(showDetails),
              tableId: 'payrollDrillDownGl',
              loadingId: 'accountNumber',
              reportMode: false,
              hideFilters: true,
              headerConfigs: {
                exportFileName: t`GL Base`,
              },
            }}
          />
        </TableContainer>
      </GlobalContainer>
      <Row>
        <Heading alignment="right" level={6} style={{ marginRight: '15px' }}>
          <Trans>Deviation</Trans>{' '}
          {formatNumber(data.difference, true).formatted}
        </Heading>
      </Row>
    </>
  );

  const termLabel = t`Term`;
  const periodLabel = t`Period`;

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>
            Payroll Drilldown - {bimonthly ? termLabel : periodLabel}{' '}
            {accumulated ? '1 - ' : ''}
            {period}
          </Trans>
        </Heading>
        <Heading level={4}>{row.payRollType}</Heading>
        <Field
          id="showDetails"
          type="checkbox"
          name="showDetails"
          label={t`Show element details`}
          checked={showDetails}
          onChange={toggleShowDetails}
        />
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill magnify kind="danger" onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PayrollDrilldownModal;

const GlobalContainer = styled.div`
  width: 100%;
  padding: 0 0 10px;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TableContainer = styled.div`
  width: ${({ width }) => `${width}%`};
`;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1200px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
