import React, { useMemo } from 'react';
import _cloneDeep from 'lodash-es/cloneDeep';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Field, Label, PeriodFilterButtons } from 'components';

import {
  periodTypes,
  recordFilterList,
  filterByReconciledMatchedTransaction,
  viewFinishedAccrualsOptions,
} from 'services/shapes';

function ReportSettings(props) {
  const { type, state, onChange } = props;

  const generalPartAllowed = ['ALL', 'GENERAL'].includes(type);
  const bankPartAllowed = ['ALL', 'BANK'].includes(type);
  const trialBalancePartAllowed = ['ALL', 'TRIAL'].includes(type);

  const predefinedOptions = useMemo(
    () => ({
      bank: filterByReconciledMatchedTransaction(),
      generalAccruals: viewFinishedAccrualsOptions(),
      generalReconcile: recordFilterList(),
      frequencies: periodTypes(),
    }),
    [],
  );

  const onStateChange = (id, optionId) => {
    onChange((prevState) => {
      const currentItem = _cloneDeep(prevState?.[id]);
      currentItem[optionId] = !currentItem?.[optionId];

      return { ...prevState, [id]: currentItem };
    });
  };

  const onSelectChange = (id, value) => {
    onChange((prevState) => ({ ...prevState, [id]: value }));
  };

  const getItem = (title, id, options) => (
    <SettingsItem>
      <Label>{title}</Label>
      {options.map((option) => (
        <Field
          horizontal
          key={`${id}-${option.id}`}
          id={`${id}-${option.id}`}
          type="checkbox"
          name={`${id}-${option.id}`}
          label={option.label}
          checked={state?.[id]?.[option.id]}
          onChange={() => onStateChange(id, option.id)}
        />
      ))}
    </SettingsItem>
  );

  const handlePeriodChange = (e) => {
    onChange((prevState) => ({
      ...prevState,
      period: parseInt(e.target.getAttribute('id'), 10),
    }));
  };

  const toggleSinglePeriod = () => {
    onChange((prevState) => ({
      ...prevState,
      singlePeriod: !state?.singlePeriod,
    }));
  };

  return (
    <Wrapper>
      {bankPartAllowed && getItem(t`Bank`, 'bank', predefinedOptions.bank)}
      {generalPartAllowed &&
        getItem(
          t`General Ledger: Reconcile`,
          'generalReconcile',
          predefinedOptions.generalReconcile,
        )}
      {generalPartAllowed &&
        getItem(
          t`General Ledger: Accruals`,
          'generalAccruals',
          predefinedOptions.generalAccruals,
        )}
      {trialBalancePartAllowed && (
        <SettingsItem>
          <Label>
            <Trans>Trial Balance: Frequency</Trans>
          </Label>
          <Field
            id="trial-balance-frequency"
            type="select"
            name="trial-balance-frequency"
            value={state?.trialBalance}
            onChange={(e) => onSelectChange('trialBalance', e.target.value)}
          >
            {predefinedOptions.frequencies.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </Field>
        </SettingsItem>
      )}
      {type === 'ALL' && (
        <SettingsItem selector>
          <Label>
            <Trans>Files</Trans>
          </Label>
          <PeriodFilterButtons
            size="small"
            periodType="monthly"
            singlePeriod={state?.singlePeriod}
            activePeriods={Array.from(Array(state?.period).keys())}
            handleFilterChange={handlePeriodChange}
          />
          <Field
            horizontal
            id="files-single"
            type="checkbox"
            name="files-single"
            label={t`Single Period`}
            checked={state?.singlePeriod}
            onChange={toggleSinglePeriod}
          />
        </SettingsItem>
      )}
    </Wrapper>
  );
}

export default ReportSettings;

const Wrapper = styled.div`
  align-self: stretch;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`;

const SettingsItem = styled.div`
  width: 100%;
  padding: ${({ selector }) => (selector ? 10 : 15)}px 10px 10px;
  border: 1px lightgray solid;
  border-radius: 5px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  .btn-group {
    display: flex;

    button {
      width: fit-content !important;
      padding: 4px 8px !important;
    }
  }

  & > label {
    padding: 0 5px;
    background: white;
    position: absolute;
    top: -12px;
    left: 5px;
    font-size: 14px !important;
  }

  & > .form-group {
    width: 100%;
  }
`;
