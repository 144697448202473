import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { Progress } from 'react-sweet-progress';
import { RiBankFill } from '@react-icons/all-files/ri/RiBankFill';
import { RiBook2Fill } from '@react-icons/all-files/ri/RiBook2Fill';
import { RiDatabase2Fill } from '@react-icons/all-files/ri/RiDatabase2Fill';

import { DESKTOP, LARGE_SCREENS, TWO_K, FOUR_K } from 'constants/mediaSizes';

const gradients = [
  ['#ffb92d', '#ec6d44', '#f5805a'],
  ['#6592da', '#46c5f1', '#7eaaf0'],
  ['#5547b9', '#875ec0', '#5547b9'],
];

const templates = (companyProgress) => {
  const {
    glTotalAccounts: glTotal = 0,
    glApprovedAccounts: glApproved = 0,

    bankTotalAccounts: bankTotal = 0,
    bankApprovedAccounts: bankApproved = 0,

    simpleLedgerTotalAccounts: SLTotal = 0,
    simpleLedgerApprovedAccounts: SLApproved = 0,
  } = companyProgress;

  const glPercent = Math.ceil((glApproved / glTotal) * 100);
  const SLPercent = Math.ceil((SLApproved / SLTotal) * 100);
  const bankPercent = Math.ceil((bankApproved / bankTotal) * 100);

  return [
    {
      type: 'general',
      name: t`General Ledger`,
      value: `${glApproved}/${glTotal}`,
      percent: Number.isNaN(glPercent) ? 0 : glPercent,
      icon: <RiDatabase2Fill />,
    },
    {
      type: 'simple_ledger',
      name: t`Simple Ledger`,
      value: `${SLApproved}/${SLTotal}`,
      percent: Number.isNaN(SLPercent) ? 0 : SLPercent,
      icon: <RiBook2Fill />,
    },
    ...(bankTotal === 0
      ? []
      : [
          {
            type: 'bank',
            name: t`Bank`,
            value: `${bankApproved}/${bankTotal}`,
            percent: Number.isNaN(bankPercent) ? 0 : bankPercent,
            icon: <RiBankFill />,
          },
        ]),
  ];
};

function DashboardTemplates(props) {
  const { direction, companyProgress, handleRedirect } = props;

  return (
    <Container direction={direction}>
      {templates(companyProgress).map((template, i) => (
        <VerticalBoxItem
          key={`template-${i}`}
          start={gradients[i][0]}
          mid={gradients[i][1]}
          end={gradients[i][2]}
        >
          <TemplateContainer onClick={() => handleRedirect(template.type)}>
            <Info>
              <MainText>
                <TemplateIcon>{template.icon}</TemplateIcon>
                <TemplateInfo>
                  <strong>{template.name}</strong>
                  <p>{template.value}</p>
                </TemplateInfo>
              </MainText>
              <Percentage>{template.percent}%</Percentage>
            </Info>
            <LineProgressBar percent={template.percent} />
          </TemplateContainer>
        </VerticalBoxItem>
      ))}
    </Container>
  );
}

export default React.memo(DashboardTemplates);

const Percentage = styled.div`
  text-shadow: rgb(0 0 0 / 10%) 0 0 12px;
  animation: appear 1s ease;
  font-weight: 600;
`;

const Info = styled.div`
  margin-bottom: 0.2em;
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

const MainText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4em;
  animation: appear 1s ease;

  p {
    font-weight: 700;
  }

  @keyframes appear {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const TemplateIcon = styled.div`
  margin-right: 0.5em;
  font-size: 1.4em;
  font-weight: 400;
  border-radius: 5px;
  text-shadow: rgb(0 0 0 / 10%) 0 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) and (max-width: ${DESKTOP.max}px) {
    display: none;
  }
`;

const TemplateInfo = styled.div`
  strong {
    color: white;
    text-shadow: rgb(0 0 0 / 20%) 0 0 12px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1em;
  }

  p {
    color: white;
    font-size: 0.9em;
    text-shadow: rgb(0 0 0 / 20%) 0 0 12px;
    margin: 0;
    padding: 0;
  }
`;

const TemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.6em 1.7em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: opacity 2s ease;

  @media (max-width: ${DESKTOP.max}px) {
    padding: 1.3em;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LineProgressBar = styled(Progress)`
  .react-sweet-progress-line {
    background-color: rgba(255, 255, 255, 0.16) !important;
    height: 0.5em;
  }

  .react-sweet-progress-line-inner,
  .react-sweet-progress-line-inner-status-active {
    background-color: white !important;
    height: 0.5em !important;
    min-height: 0.5em;
  }

  .react-sweet-progress-symbol {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: stretch;
  justify-content: space-between;
  font-size: 14px;
  gap: 0.7em;
  flex: 1;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 12px;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 12.8px;
    gap: 0.76em;
  }

  @media (min-width: ${TWO_K.min}px) {
    gap: 0.76em;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
    gap: 1em;
  }
`;

const VerticalBoxItem = styled.div`
  flex-basis: 100%;
  display: flex;
  ${({ start, mid, end }) =>
    start
      ? `
        background: ${start};
        background: -webkit-linear-gradient(to right, ${start}, ${mid}, ${end});
        background: linear-gradient(-45deg, ${start}, ${mid}, ${end});
        background-size: 200% 200%;
        animation: gradient 5s ease infinite;
      `
      : `background: white`};

  border-radius: 5px;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;

  :hover {
    transform: scale(1.05);
  }

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
