import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 120,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    disableSortBy: true,
  },
  {
    width: 120,
    Header: t`Company`,
    accessor: 'companyName',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 100,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: 'flex-right',
    disableSortBy: true,
  },
  {
    width: 250,
    Header: t`VAT Code Description`,
    accessor: 'vatCodeDescription',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 130,
    Header: t`Account ID`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
    disableSortBy: true,
  },
  {
    width: 130,
    Header: t`Line ID`,
    accessor: 'lineId',
    sortType: 'basic',
    className: '',
    disableSortBy: true,
  },
  {
    width: 140,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Posted Date`,
    accessor: 'postedDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
];

export default { columns };
