import React from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs } from 'components';
import DashboardGroups from 'containers/DashboardGroups';
import DashboardMyProgressNew from 'containers/DashboardMyProgressNew';

import { toDashboard } from 'services/routehelpers';

/**
 * Dashboard Page
 */

function DashboardPage(props) {
  const { location, handleTabSelect } = props;
  const { tabEntry = 'my-progress' } = queryString.parse(location.search);
  const onTabSelect = (key) => handleTabSelect(key, toDashboard);

  const tabs = [
    {
      title: t`My Progress`,
      eventKey: 'my-progress',
      component: <DashboardMyProgressNew {...props} />,
    },
    {
      title: t`Groups`,
      eventKey: 'groups',
      component: <DashboardGroups {...props} />,
    },
  ];

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default DashboardPage;
