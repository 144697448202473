import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { plural } from '@lingui/macro';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import {
  MenuItem,
  DropdownButton,
  OverlayTrigger,
  Tooltip as BTooltip,
} from 'react-bootstrap';

import {
  Button,
  Toggle,
  Spinner,
  ButtonIcon,
  CustomDropdown,
  CustomMultiSelectInput,
} from 'components';

import { MOBILE, DESKTOP, LARGE_SCREENS } from 'constants/mediaSizes';

export function CustomTableSelect(props) {
  const {
    tableId,
    tableProps,
    handleAction,
    selectConfigs,
    actionSubmitting,
    setSelectedRowIds,
  } = props;

  const {
    converter,
    fields = [],
    buttons = [],
    dropdowns = [],
    dropdownsWithCheckboxes = [],
  } = selectConfigs;

  const { selectedFlatRows } = tableProps;
  const [disabled, setDisabled] = useState(true);
  const [selectState, setSelectState] = useState({});
  const [clearSearch, setClearSearch] = useState(false);

  const handleSelect = (value, id) => {
    const newState = { ...selectState, [id]: value };

    if (!value.length) {
      delete newState[id];
    }

    setDisabled(!Object.keys(newState).length);
    setSelectState(newState);
  };
  const handleToggleChange = (id) => {
    const newData = { ...selectState, [id]: !selectState[id] };

    setSelectState(newData);
    setDisabled(false);
  };
  const clearSelection = () => {
    setSelectedRowIds({});
    handleAction('rowSelect', {
      tableId,
      item: 'all',
      selected: false,
    });
  };
  const renderButton = (item) => {
    const submitting = actionSubmitting[item.id];
    const { tooltip } = item;

    const handleButtonClick = async () => {
      if (item.clearSelected) {
        clearSelection();
      } else if (item.id === 'clearState') {
        setDisabled(true);
        setSelectState({});
      }

      await handleAction(
        item.id,
        selectedFlatRows.map((el) => el.original),
        selectState,
      );
      setClearSearch(true);
      if (!item.clearSelected) {
        clearSelection();
      }
    };
    let buttonDisabled =
      ((item.id === 'saveState' || item.id === 'clearState') && disabled) ||
      submitting ||
      item.buttonProps.disabled;

    if (item.minSelected && !submitting) {
      buttonDisabled = item.minSelected > selectedFlatRows.length;
    }

    return (
      <>
        <Button
          key={`table-select-button-${tableId}-${item.id}`}
          {...item.buttonProps}
          onClick={handleButtonClick}
          disabled={buttonDisabled}
        >
          {submitting ? (
            <Spinner size="md" />
          ) : (
            <>
              {!!item.icon && item.icon}
              {typeof item.getTitle === 'function'
                ? item.getTitle(selectedFlatRows)
                : item.title}
            </>
          )}
        </Button>
        {!!tooltip && (
          <OverlayTrigger
            overlay={<BTooltip id="tooltip">{tooltip}</BTooltip>}
            placement="bottom"
          >
            <div>
              <ButtonIcon as={FaInfoCircle} color="white" marginLeft={4} />
            </div>
          </OverlayTrigger>
        )}
      </>
    );
  };

  const renderDropdown = (item = {}) => {
    const { options = [], title, icon } = item;
    const submitting = actionSubmitting[item.id];

    const handleButtonClick = async (value) => {
      await handleAction(
        item.id,
        selectedFlatRows.map((el) => el.original),
        value,
      );
      clearSelection();
    };

    return (
      <DropdownButton
        className="btn-fill"
        bsStyle="success"
        pullRight
        title={
          submitting ? (
            <SpinnerWrapper>
              <Spinner size="sm" type="white" />
            </SpinnerWrapper>
          ) : (
            <>
              {!!icon && icon}
              {title}
            </>
          )
        }
        bsSize="small"
        onSelect={handleButtonClick}
        disabled={item.buttonProps.disabled || submitting}
      >
        {options.map((option) => (
          <MenuItem eventKey={option.id} key={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  };

  const renderCustomDropdown = (item = {}) => {
    const { id, icon, title, checkboxes } = item;
    const submitting = actionSubmitting[item.id];

    const handleButtonClick = async (checkboxesState, filters) => {
      await handleAction(
        id,
        selectedFlatRows.map((el) => el.original),
        checkboxesState,
        filters,
      );
      clearSelection();
    };

    return (
      <CustomDropdown
        {...{
          icon,
          title,
          submitting,
          checkboxes,
          handleButtonClick,
          disabled: item.disabled,
        }}
      />
    );
  };

  const renderField = () => {
    const booleans = [];
    const singleSelects = [];

    fields.forEach((item) => {
      switch (item.type) {
        case 'singleSelect': {
          singleSelects.push(
            <FieldHolder key={`table-select-field-${tableId}-${item.id}`}>
              <CustomMultiSelectInput
                selected={selectState[item.id] || []}
                onSelect={handleSelect}
                clearSearch={clearSearch}
                setClearSearch={setClearSearch}
                {...item}
              />
            </FieldHolder>,
          );
          break;
        }

        case 'boolean': {
          booleans.push(
            <ToggleHolder>
              <Toggle
                key={`table-select-field-${tableId}-${item.id}`}
                value={selectState[item.id]}
                onClick={handleToggleChange}
                labelPosition="top"
                {...item}
              />
            </ToggleHolder>,
          );
          break;
        }

        default: {
          break;
        }
      }
    });

    return (
      <>
        {singleSelects.length ? (
          <SingleSelectsContainer>
            {singleSelects.map((item) => item)}
          </SingleSelectsContainer>
        ) : null}
        {booleans.length ? (
          <BooleansContainer>{booleans.map((item) => item)}</BooleansContainer>
        ) : null}
      </>
    );
  };
  let height = 0;

  if (selectedFlatRows.length) {
    height = 'auto';
  }

  useEffect(() => {
    if (!selectedFlatRows.length) {
      setDisabled(true);
      setSelectState({});
    }
  }, [selectedFlatRows.length]);

  return (
    <SelectedHolder
      height={height}
      selected={selectedFlatRows.length}
      fieldsLength={fields.length}
    >
      <SelectedLeftPart>
        <TextHolder>
          {typeof converter === 'function'
            ? converter(selectedFlatRows)
            : plural(selectedFlatRows.length, {
                zero: 'No rows selected',
                one: `${selectedFlatRows.length} row selected`,
                other: `${selectedFlatRows.length} rows selected`,
              })}
        </TextHolder>
        {fields.length ? <FieldsHolder>{renderField()}</FieldsHolder> : null}
      </SelectedLeftPart>
      <ButtonsHolder>
        {dropdownsWithCheckboxes?.map?.(renderCustomDropdown)}
        {buttons.map(renderButton)}
        {dropdowns.map(renderDropdown)}
      </ButtonsHolder>
    </SelectedHolder>
  );
}

CustomTableSelect.propTypes = {
  tableId: PropTypes.string,
  tableProps: PropTypes.shape({}).isRequired,
  handleAction: PropTypes.func.isRequired,
  selectConfigs: PropTypes.shape({}),
  actionSubmitting: PropTypes.shape({}),
};

CustomTableSelect.defaultProps = {
  tableId: undefined,
  selectConfigs: {},
  actionSubmitting: {},
};

export default CustomTableSelect;

const SelectedHolder = styled.div`
  width: 100%;
  color: white;
  height: ${(props) => props.height};
  display: flex;
  padding: ${(props) => (props.selected ? '10px 20px' : '0 20px')};
  overflow: ${(props) => (props.height ? 'visible' : 'hidden')};
  transition: height ease 0.2s;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 10px;
  justify-content: space-between;
  background-color: #7a9e9e;

  @media (max-width: ${LARGE_SCREENS.max}px) {
    gap: 15px;
    flex-direction: ${(props) => props.fieldsLength && 'column'};
  }

  @media (max-width: ${MOBILE.max}px) {
    flex-direction: column;
  }
`;

const SelectedLeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${LARGE_SCREENS.min}px) {
    gap: 10px;
    flex-direction: column;
  }
`;

const FieldsHolder = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }

  i {
    color: black;
  }

  @media (max-width: ${LARGE_SCREENS.min}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const SingleSelectsContainer = styled.div`
  display: flex;

  @media (max-width: ${DESKTOP.max}px) {
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
  }
`;

const BooleansContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ToggleHolder = styled.div`
  & + & {
    margin-left: 20px;

    @media (max-width: ${MOBILE.max}px) {
      margin-left: 10px;
    }
  }

  @media (max-width: ${MOBILE.max}px) {
    font-size: 1.1rem !important;
  }
`;

const TextHolder = styled.div`
  margin-right: 20px;

  @media (max-width: ${LARGE_SCREENS.min}px) {
    margin-right: 0;
  }
`;

const FieldHolder = styled.div`
  min-width: 155px;
  max-width: 155px;

  & + & {
    margin-left: 20px;

    @media (max-width: ${DESKTOP.max}px) {
      margin-left: 0;
    }
  }

  @media (max-width: ${DESKTOP.min}px) {
    min-width: auto;
    max-width: none;
    width: 45%;
  }
`;

const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button,
  .btn-group {
    margin-left: 10px;

    i {
      margin-right: 5px;
    }
  }
`;

const SpinnerWrapper = styled.div`
  display: inline-block !important;
  margin-right: 5px;
`;
