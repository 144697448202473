import { t } from '@lingui/macro';

/**
 * VAT Reconciliation Detailed Grid
 */
export const columns = (reportMode) => [
  {
    width: 130,
    Header: t`Account ID`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
  },
  {
    width: reportMode ? 240 : 310,
    Header: t`Account Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 60 : 100,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: reportMode ? 100 : 130,
    Header: t`Date`,
    accessor: 'date',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: reportMode ? 80 : 160,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
  },
  {
    width: reportMode ? 220 : 330,
    Header: t`Text`,
    accessor: 'description',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 180 : 280,
    Header: t`VAT code`,
    accessor: 'vatCode',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 80 : 140,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
