import React from 'react';

import {
  BalanceReconciliation,
  OutgoingBalance,
  PaymentReconciliation,
} from 'components';

function PayrollSocialSecurity(props) {
  const {
    roleType,
    toggleModal,
    PRandOBdata,
    handleSSRowClick,
    dataSocialSecurity,
  } = props;
  const { rows, loading } = PRandOBdata;
  const { ssPaymentReconciliation, ssOutgoingBalance } = rows;

  return (
    <>
      <BalanceReconciliation
        {...{
          handleSSRowClick,
          dataSocialSecurity,
        }}
      />
      <PaymentReconciliation
        data={ssPaymentReconciliation}
        {...{
          loading,
          roleType,
          toggleModal,
          tableId: 'payrollSSPaymentReconciliation',
        }}
      />
      <OutgoingBalance
        data={ssOutgoingBalance}
        {...{
          loading,
          tableId: 'payrollSSOutgoingBalance',
        }}
      />
    </>
  );
}

export default PayrollSocialSecurity;
