import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { BsDistributeVertical } from '@react-icons/all-files/bs/BsDistributeVertical'; // eslint-disable-line max-len
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';

// This line ensures Lingui keeps track of the strings used in this file.
const possibleTypes = () => [t`base`, t`ingoingTax`, t`outgoingTax`]; // eslint-disable-line

export const columns = () => [
  {
    width: 60,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'selection',
      disableClick: true,
      isVisible: (row) => row.reconciliationStatus === 'UNMATCHED',
    },
  },
  {
    width: 70,
    Header: t`Line ID`,
    accessor: 'lineId',
    className: 'flex-right',
    disableSortBy: true,
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 60,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: 'flex-center',
    disableSortBy: true,
  },
  {
    width: 160,
    Header: t`VAT Code Description`,
    accessor: 'vatDescription',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 70,
    Header: t`Account`,
    accessor: 'accountId',
    sortType: 'basic',
    className: 'flex-center',
    disableSortBy: true,
  },
  {
    width: 120,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 120,
    Header: t`Split Amount`,
    accessor: 'splitAmount',
    className: 'flex-right',
    withTotal: false,
    disableSortBy: true,
    cell: {
      type: 'input',
      inputType: 'number',
      editable: (row) =>
        row.splitAmount !== undefined &&
        row.reconciliationStatus === 'UNMATCHED',
      disableClick: true,
    },
  },
  {
    width: 80,
    Header: t`Posted Date`,
    accessor: 'date',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  {
    width: 140,
    Header: t`Line Type`,
    accessor: 'type',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      editable: (row) => row.reconciliationStatus === 'UNMATCHED',
      disableClick: true,
      converter: (value) =>
        t({
          id: value,
          message: value,
        }),
      fieldOptions: {
        id: 'type',
        single: true,
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Line type...`,
        options: [
          {
            id: 'base',
            label: t`Base`,
          },
          {
            id: 'ingoingTax',
            label: t`Incoming`,
          },
          {
            id: 'outgoingTax',
            label: t`Outgoing`,
          },
        ],
      },
    },
  },
  {
    width: 80,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'splitLine',
          icon: () => <BsDistributeVertical />,
          tooltip: t`Split line`,
          visible: (item) =>
            (item.type !== '' || item.type !== undefined) &&
            item.reconciliationStatus === 'UNMATCHED',
        },
        {
          id: 'deleteSplitLine',
          icon: () => <BiTrash />,
          tooltip: t`Delete split line`,
          visible: (item) =>
            item.reconciliationStatus === 'UNMATCHED' &&
            item.splitAmount !== undefined,
        },
        {
          id: 'unReconcile',
          visible: (item) => item.reconciliationStatus === 'MATCHED',
          icon: () => <BiUnlink />,
          tooltip: t`Unreconcile`,
        },
      ],
    },
  },
];

export default { columns };
