import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const CustomScrollbar = React.forwardRef((props, ref) => (
  <Scrollbar {...props} ref={ref} />
));

CustomScrollbar.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.exact({
      current: PropTypes.object,
    }),
  ]),
  horizontal: PropTypes.bool,
};

CustomScrollbar.defaultProps = {
  as: undefined,
  ref: undefined,
  horizontal: false,
};

export default CustomScrollbar;

const Scrollbar = styled.div`
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 3px solid #fafafa;
    transition: background-color 0.2s;
    ${(props) => props.horizontal && 'border-right: 10px solid #fafafa;'}

    &:hover {
      background-color: #818181;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;
