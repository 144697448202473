import { initialState } from './selectors';
import { CLEAR_STORE, SIDEBAR_TOGGLE, APP_READY, NEEDS_LOGIN } from './actions';

/* eslint-disable default-param-last */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: payload,
      };
    case APP_READY:
      return {
        ...state,
        appReady: payload,
      };
    case NEEDS_LOGIN:
      return {
        ...state,
        needsLogin: true,
      };
    case CLEAR_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
