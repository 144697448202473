import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { Row, Col } from 'react-bootstrap';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { IoMdBrush } from '@react-icons/all-files/io/IoMdBrush';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  Heading,
  ButtonIcon,
  CustomTableSelect,
  CustomTableWithAutoSettings,
} from 'components';

import { useCurrentWidth } from 'services/hooks';
import {
  formatNumber,
  detectTopContentModeWhen2Tables,
} from 'services/helpers';
import {
  openingBalanceOBColumns,
  openingBalanceUBColumns,
} from 'services/tableshapes';

export function OpeningBalanceMap(props) {
  const {
    account,
    data,
    search,
    loading,
    ubVSobData,
    getRowColor,
    selectedRows,
    handleAction,
    selectCondition,
    actionSubmitting,
    clearSelectedIds,
    topSelectConverter,
    getManualSelectFuncOB,
    getManualSelectFuncCB,
  } = props;
  const screenWidth = useCurrentWidth();

  const fixedSize = useMemo(
    () => detectTopContentModeWhen2Tables(screenWidth, 0),
    [screenWidth],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'lineId',
        desc: false,
      },
    ],
    [],
  );

  return (
    <Card>
      <CardPDFTitle
        title={<Trans>Map Opening Balance for Account {account}</Trans>}
      />
      <Heading level={6} className="card-sub-title">
        <Trans>OB: {formatNumber(ubVSobData.ob, false).formatted}</Trans>
      </Heading>
      <Heading level={6} className="card-sub-title">
        <Trans>UB: {formatNumber(ubVSobData.ub, false).formatted}</Trans>
      </Heading>
      <Heading level={6} className="card-sub-title">
        <Trans>
          Deviation: {formatNumber(ubVSobData.deviation, false).formatted}
        </Trans>
      </Heading>
      {!search.reportMode && (
        <CustomTableSelect
          {...{
            setSelectedRowIds: clearSelectedIds,
            handleAction,
            actionSubmitting,
            tableProps: {
              selectedFlatRows: [
                ...(selectedRows.OB || []),
                ...(selectedRows.UB || []),
              ],
            },
            selectConfigs: {
              converter: topSelectConverter,
              buttons: [
                {
                  id: 'reconcile',
                  icon: <ButtonIcon as={HiCheck} marginRight={5} />,
                  title: t`Reconcile`,
                  clearSelected: true,
                  buttonProps: {
                    size: 'sm',
                    kind: 'success',
                    fill: true,
                    magnify: true,
                    disabled:
                      !selectedRows.OB.length || !selectedRows.UB.length,
                  },
                },
              ],
            },
          }}
        />
      )}
      <Row>
        <Col sm={12} md={6}>
          <CustomTableWithAutoSettings
            {...{
              loading,
              fixedSize,
              getRowColor,
              handleAction,
              actionSubmitting,
              data: data?.ob || [],
              columns: openingBalanceOBColumns(search),
              tableId: 'OB',
              loadingId: 'lineId',
              tableTitle: t`OB`,
              reportMode: search.reportMode,
              headerConfigs: {
                exportFileName: t`OB`,
              },
              getManualSelectFunc: getManualSelectFuncOB,
              selectConfigs: {
                selectCondition,
                buttons: [
                  {
                    id: 'cancel',
                    icon: <ButtonIcon as={IoMdBrush} marginRight={5} />,
                    title: t`Clear`,
                    clearSelected: true,
                    buttonProps: {
                      size: 'sm',
                      kind: '',
                      fill: true,
                      magnify: true,
                    },
                  },
                ],
              },
              initialState: {
                sortBy,
              },
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <CustomTableWithAutoSettings
            {...{
              loading,
              fixedSize,
              getRowColor,
              handleAction,
              actionSubmitting,
              data: [...(data?.ub || []), ...(data?.ubAccrual || [])],
              columns: openingBalanceUBColumns(search),
              tableId: 'UB',
              loadingId: 'lineId',
              tableTitle: t`CB`,
              reportMode: search.reportMode,
              headerConfigs: {
                exportFileName: t`CB`,
              },
              getManualSelectFunc: getManualSelectFuncCB,
              selectConfigs: {
                buttons: [
                  {
                    id: 'cancel',
                    icon: <ButtonIcon as={IoMdBrush} marginRight={5} />,
                    title: t`Clear`,
                    clearSelected: true,
                    buttonProps: {
                      size: 'sm',
                      kind: '',
                      fill: true,
                      magnify: true,
                    },
                  },
                ],
              },
              initialState: {
                sortBy,
              },
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default OpeningBalanceMap;
