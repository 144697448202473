import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import Dropzone from 'react-dropzone';
import { DndProvider } from 'react-dnd';
import { t, Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import { FiSave } from '@react-icons/all-files/fi/FiSave';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { GoCloudDownload } from '@react-icons/all-files/go/GoCloudDownload';
import { MdOutlineRemoveRedEye } from '@react-icons/all-files/md/MdOutlineRemoveRedEye'; // eslint-disable-line max-len
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Modal,
  ButtonGroup,
  Tooltip,
  OverlayTrigger,
  Grid as DropzoneWrapper,
} from 'react-bootstrap';

import {
  Table,
  Field,
  Button,
  Spinner,
  Heading,
  TableRow,
  TableCell,
  Paragraph,
  ButtonIcon,
  DraggableItem,
} from 'components';

import { fileSizeFormatter } from 'services/helpers';

const getColor = (action) => {
  if (action.isDragAccept) {
    return '#00e676';
  }
  if (action.isFileTooLarge) {
    return '#ff1744';
  }
  if (action.isDragActive) {
    return '#2196f3';
  }
  if (action.rejectedFiles?.length > 0 && !action.isFileTooLarge) {
    return '#ff1744';
  }
  return '#eeeeee';
};

function FileUploadModal({
  account,
  company,
  roleType,
  showModal,
  removeFile,
  isUploading,
  uploadFiles,
  toggleModal,
  previewFile,
  sortedFiles,
  showAllFiles,
  fileCategory,
  uploadedFiles,
  setFileName,
  setSortedFiles,
  changeFileName,
  changeFilesOrder,
  viewUploadedFiles,
  changeOrderLoading,
  enableDisableNameChange,
  ...props
}) {
  const { accountingYear } = useParams();
  const sorted = JSON.stringify(sortedFiles) !== JSON.stringify(uploadedFiles);
  const maxSize = 52428800;

  const period = company?.bimonthly
    ? Math.ceil(+company.currentWorkingPeriodEnd / 2)
    : company?.currentWorkingPeriodEnd;

  const openPreviewFile = (file) => {
    setFileName(file.fileName);
    previewFile(file);
  };

  const renderFilesList = () => {
    const editFilesToolTip = <Tooltip id="tooltip">{t`Edit Filename`}</Tooltip>;
    const deleteFilesToolTip = <Tooltip id="tooltip">{t`Delete File`}</Tooltip>;
    const downloadFile = <Tooltip id="tooltip">{t`Download File`}</Tooltip>;
    const previewFilesToolTip = (
      <Tooltip id="tooltip">{t`Preview File`}</Tooltip>
    );

    const onEditFiles = sortedFiles.filter((item) => item.enableEdit);
    const Wrapper = !onEditFiles.length ? DraggableItem : TableRow;

    const extraProps = (index, file) => ({
      index,
      id: file.transactionFileSID,
      items: sortedFiles,
      changeState: setSortedFiles,
      component: 'row',
    });

    return (
      <>
        <Table
          head={
            <TableRow>
              {fileCategory === 'ACCOUNT' && (
                <>
                  <TableCell heading>
                    <Trans>Period</Trans>
                  </TableCell>
                  <TableCell heading>
                    <Trans>Year</Trans>
                  </TableCell>
                </>
              )}
              <TableCell heading>
                <Trans>Filename</Trans>
              </TableCell>
              <TableCell heading>
                <Trans>Uploaded By</Trans>
              </TableCell>
              <TableCell heading>
                <Trans>Time</Trans>
              </TableCell>
              <TableCell heading>
                <Trans>File size</Trans>
              </TableCell>
              <TableCell heading className="text-right">
                <Trans>Action</Trans>
              </TableCell>
            </TableRow>
          }
          responsive
        >
          {/* eslint-disable default-param-last */}
          {sortedFiles.map((file = {}, index) => {
            const supportedFileExtension =
              file.fileType?.includes('application/pdf') ||
              file.fileType?.includes('application/msword') ||
              file.fileType?.includes('application/vnd') ||
              (file.fileType?.includes('application/octet-stream') &&
                file.fileName?.includes('.docx')) ||
              file.fileType?.includes('image/');

            return (
              <Wrapper
                key={`fileUpload-${file.transactionFileSID}-${
                  file.name || file.fileName
                }`}
                {...extraProps(index, file)}
              >
                {fileCategory === 'ACCOUNT' && (
                  <>
                    <TableCell>{file.periodTo}</TableCell>
                    <TableCell>{file.periodYear}</TableCell>
                  </>
                )}
                <TableCell>
                  {file.showProgress && <Spinner type="white" />}
                  <Field
                    defaultValue={file.name || file.fileName}
                    name={index + (file.name || file.fileName)}
                    noBorder={!file.enableEdit}
                    style={
                      !file.enableEdit
                        ? {
                            background: 'transparent',
                            minWidth: '300px',
                            cursor: 'pointer',
                          }
                        : { minWidth: '300px' }
                    }
                    disabled={!file.enableEdit}
                    onChange={(event) => {
                      changeFileName(event, file.transactionFileSID);
                    }}
                  />
                </TableCell>
                <TableCell>{file.createdBy}</TableCell>
                <TableCell date dateTime>
                  {file.createdDate}
                </TableCell>
                <TableCell>
                  {fileSizeFormatter(file.size || file.fileSize)}
                </TableCell>
                <TableCell className="text-right">
                  <ButtonGroup>
                    {/* eslint-disable-next-line max-len */}
                    {fileCategory !== 'BANK_STATEMENT' && !roleType.isReadOnly && (
                      <OverlayTrigger
                        placement="top"
                        overlay={editFilesToolTip}
                      >
                        <IconHolder
                          size="lg"
                          simple
                          magnify
                          id={1}
                          onClick={() => {
                            enableDisableNameChange(file.transactionFileSID);
                          }}
                          data-testid={`edit-icon-${index}`}
                        >
                          <EditIcon as={GoPencil} />
                        </IconHolder>
                      </OverlayTrigger>
                    )}
                    {!roleType.isReadOnly && (
                      <OverlayTrigger
                        placement="top"
                        overlay={deleteFilesToolTip}
                      >
                        <IconHolder
                          id={2}
                          simple
                          magnify
                          size="lg"
                          onClick={() => {
                            removeFile(file.transactionFileSID, file.fileKey);
                          }}
                          data-testid={`delete-icon-${index}`}
                        >
                          <DeleteIcon as={BiTrash} />
                        </IconHolder>
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger placement="top" overlay={downloadFile}>
                      <IconHolder
                        id={3}
                        simple
                        magnify
                        size="lg"
                        onClick={() => {
                          viewUploadedFiles(
                            file.fileKey,
                            file.name || file.fileName,
                          );
                        }}
                        data-testid={`download-icon-${index}`}
                      >
                        <DownloadIcon as={GoCloudDownload} />
                      </IconHolder>
                    </OverlayTrigger>
                    {supportedFileExtension && (
                      <OverlayTrigger
                        placement="top"
                        overlay={previewFilesToolTip}
                      >
                        <IconHolder
                          id={2}
                          simple
                          magnify
                          size="lg"
                          onClick={() => openPreviewFile(file)}
                          data-testid={`preview-icon-${index}`}
                        >
                          <PreviewIcon as={MdOutlineRemoveRedEye} />
                        </IconHolder>
                      </OverlayTrigger>
                    )}
                  </ButtonGroup>
                </TableCell>
              </Wrapper>
            );
          })}
        </Table>
        <hr />
      </>
    );
  };

  const renderFileUploadDropZone = (
    <Dropzone
      {...props}
      minSize={0}
      maxSize={maxSize}
      onDrop={uploadFiles}
      disabled={isUploading}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        rejectedFiles,
      }) => {
        const isFileTooLarge =
          rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;
        return (
          <DropzoneWrapper>
            <Container
              {...getRootProps({
                isDragActive,
                isDragReject,
                isDragAccept,
                isFileTooLarge,
                rejectedFiles,
              })}
            >
              <input {...getInputProps()} />
              {isUploading && <Spinner />}
              {!isDragActive && !isUploading && (
                <Trans>Click here or drop a file to upload!</Trans>
              )}
              {isDragActive && !isDragReject && (
                <Trans>Drop it like it is hot!</Trans>
              )}
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  <Trans>File is too large.</Trans>
                </div>
              )}
              {rejectedFiles?.length > 0 && !isFileTooLarge && (
                <div className="text-danger mt-2">
                  <Trans>File type not accepted, sorry!</Trans>
                </div>
              )}
            </Container>
          </DropzoneWrapper>
        );
      }}
    </Dropzone>
  );
  const withFiles = !isEmpty(uploadedFiles);

  const renderModalHeader = (
    <Header>
      <Heading level={3}>
        <Trans>
          Upload Files, Term - {period}, {accountingYear}
        </Trans>
      </Heading>
      {uploadedFiles && uploadedFiles.length !== 0 && !roleType.isReadOnly && (
        <ButtonGroup bsSize="small">
          <Button
            kind="info"
            magnify
            fill
            disabled={!sorted}
            onClick={changeFilesOrder}
          >
            {changeOrderLoading ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                <ButtonIcon as={FiSave} /> <Trans>Save new order</Trans>
              </>
            )}
          </Button>
        </ButtonGroup>
      )}
    </Header>
  );

  const renderModalFooter = (
    <Modal.Footer>
      {fileCategory === 'ACCOUNT' && (
        <div className="pull-left">
          <Field
            type="checkbox"
            label={t`Show files for all periods`}
            id="showAll"
            name="showAll"
            onChange={showAllFiles}
          />
        </div>
      )}
      <ButtonGroup bsSize="small">
        <Button
          kind="danger"
          fill
          magnify
          onClick={(e) => toggleModal(e, withFiles, account, uploadedFiles)}
        >
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </ButtonGroup>
    </Modal.Footer>
  );

  return (
    <Modal
      show={showModal}
      bsSize="xl"
      onEscapeKeyDown={(e) => toggleModal(e, withFiles, account, uploadedFiles)}
    >
      <Modal.Header>{renderModalHeader}</Modal.Header>
      <Modal.Body>
        {uploadedFiles && uploadedFiles.length !== 0 ? (
          <DndProvider backend={HTML5Backend}>{renderFilesList()}</DndProvider>
        ) : (
          <Paragraph>
            <Trans>No files uploaded...</Trans>
          </Paragraph>
        )}
        {!roleType.isReadOnly && renderFileUploadDropZone}
      </Modal.Body>
      {renderModalFooter}
    </Modal>
  );
}

FileUploadModal.propTypes = {
  roleType: PropTypes.shape({}).isRequired,
  showModal: PropTypes.bool,
  removeFile: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  uploadFiles: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  previewFile: PropTypes.func.isRequired,
  sortedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  showAllFiles: PropTypes.func.isRequired,
  fileCategory: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  setSortedFiles: PropTypes.func.isRequired,
  changeFileName: PropTypes.func.isRequired,
  changeFilesOrder: PropTypes.func.isRequired,
  viewUploadedFiles: PropTypes.func.isRequired,
  changeOrderLoading: PropTypes.bool,
  enableDisableNameChange: PropTypes.func.isRequired,
};

FileUploadModal.defaultProps = {
  showModal: false,
  isUploading: false,
  uploadedFiles: [],
  sortedFiles: [],
  changeOrderLoading: false,
};

export default FileUploadModal;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(colorProps) => getColor(colorProps)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
`;

const IconHolder = styled((props) => <Button {...props} />)`
  padding: 13px 0 13px 20px !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditIcon = styled.i`
  color: orange;
  cursor: pointer;
  font-size: 20px;
`;

const DeleteIcon = styled.i`
  color: red;
  cursor: pointer;
  font-size: 20px;
`;

const DownloadIcon = styled.i`
  color: green;
  cursor: pointer;
  font-size: 20px;
`;

const PreviewIcon = styled.i`
  color: blue;
  cursor: pointer;
  font-size: 20px;
`;
