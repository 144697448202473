import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { withheldTaxFormatter } from 'services/dataFormatters/payroll';
import { getPayrollMappableReportsRowsApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useWithheldTaxData = (props) => {
  const { bimonthly, accountingYear, currentCompanySID, activePeriods } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getPayrollMappableReportsRowsApi),
  );

  const period = useMemo(
    () => (bimonthly ? Math.ceil(+activePeriods / 2) : activePeriods),
    [bimonthly, activePeriods],
  );

  const rows = useMemo(() => withheldTaxFormatter(data), [data]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getPayrollMappableReportsRowsApi, {
          period,
          bimonthly,
          year: accountingYear,
          companyId: currentCompanySID,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [period, bimonthly, accountingYear, currentCompanySID]);

  return { rows, loading };
};

export default useWithheldTaxData;
