import React from 'react';
import { t } from '@lingui/macro';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings } from 'components';

import { VATControlDrilldownColumns } from 'services/tableshapes';
import { vatControlDrilldownFilters } from 'services/tableFilterShapes/VATControlDrilldown'; // eslint-disable-line max-len

function VATControlDrilldownTable(props) {
  const { tableData, loading, roleType, vatCode, accountId } = props;
  const title = t`VAT Control for Code ${vatCode} — Account ${accountId}`;

  const hasSplitAmounts = Array.isArray(tableData)
    ? tableData.some((item) => item.splitAmount !== null)
    : false;

  return (
    <Card
      id="pdfToPrint"
      data-testid="pdfToPrint-vatSpecificationControl"
      header={<CardPDFTitle title={title} />}
    >
      <CustomTableWithAutoSettings
        {...{
          data: tableData,
          loadingId: 'accountId',
          tableId: 'vatSpecificationControlDrilldown',
          loading,
          columns: VATControlDrilldownColumns(
            roleType.isReadOnly,
            hasSplitAmounts,
          ),
          filterConfigs: vatControlDrilldownFilters(),
          withTotal: true,
          headerConfigs: {
            exportFileName: title,
          },
        }}
      />
    </Card>
  );
}

export default VATControlDrilldownTable;
