/* eslint-disable import/no-import-module-exports  */
import React from 'react';
import ReactGA from 'react-ga4';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { Router } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';
import { OidcProvider, OidcSecure } from '@axa-fr/react-oidc';
import { t } from '@lingui/macro';

import I18nLoader from './services/utils/i18n';
import store, { history } from './store/configure';
import { config } from './config';

import GlobalTheme from './styles/ThemeProvider';
import LoadingScreen from './components/atoms/LoadingScreen';
import ConnectedApp from './components/ConnectedApp';
import ErrorScreen from './components/atoms/ErrorScreen';

import 'assets/css/bootstrap.min.css';
import 'assets/sass/paper-dashboard.scss';
import 'assets/css/fonts.css';
import 'assets/css/custom.css';
import 'react-sweet-progress/lib/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-day-picker/dist/style.css';
import 'reactjs-popup/dist/index.css';

// Only track in production environment
if (config.isProdEnv) {
  ReactGA.initialize(config.gaTrackingID, {
    debug: config.isDev,
    titleCase: false,
  });
}
// TODO: Fetch these from config instead of process.env directly
const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID || '',
  process.env.ALGOLIA_SEARCH_CLIENT || '',
);

const onEvent = (_configurationName, eventName) => {
  if (eventName === 'loginCallbackAsync_error') {
    window.history.replaceState({}, '', '/');
  }
};
const customHistory = () => ({
  replaceState: (url) => {
    history
      .replace({
        pathname: url,
      })
      .then(() => {
        window.dispatchEvent(new Event('popstate'));
      });
  },
});

const renderApp = () => (
  <React.StrictMode>
    <Provider store={store}>
      <I18nLoader>
        <OidcProvider
          customHistory={customHistory}
          configuration={config.oidc}
          onEvent={onEvent}
          callbackSuccessComponent={LoadingScreen}
          authenticatingComponent={() => <div />}
          authenticatingErrorComponent={() => (
            <ErrorScreen message={t`An authentication error occured.`} />
          )}
          notAuthenticatedComponent={() => (
            <ErrorScreen message={t`You are not authenticated.`} />
          )}
          sessionLostComponent={() => (
            <ErrorScreen message={t`Session timed out.`} />
          )}
          loadingComponent={LoadingScreen}
        >
          <Router history={history} basename="/">
            <HelmetProvider>
              <InstantSearch
                indexName={process.env.BALANCES_ENV || 'local'}
                searchClient={searchClient}
              >
                <IntercomProvider appId="gldv675w">
                  <GlobalTheme>
                    <OidcSecure>
                      <ConnectedApp />
                    </OidcSecure>
                  </GlobalTheme>
                </IntercomProvider>
              </InstantSearch>
            </HelmetProvider>
          </Router>
        </OidcProvider>
      </I18nLoader>
    </Provider>
  </React.StrictMode>
);

const app = document.getElementById('app');
const root = createRoot(app);

root.render(renderApp());
