import React from 'react';
import { t } from '@lingui/macro';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';

/**
 * Trial Balance Report Grid
 */
export const columns = ({ reportMode, tableState: { globalFilter } }) => {
  let extraCols = [];
  const valueKey = globalFilter?.accumulatedCustom
    ? 'accumulatedValue'
    : 'value';
  const isMonthly = globalFilter?.periodTypeCustom?.[0]?.id === 'monthly';

  switch (globalFilter?.periodTypeCustom?.[0]?.id) {
    case 'monthly': {
      extraCols = [
        {
          width: reportMode ? 65 : 170,
          Header: t`P1`,
          sortType: 'basic',
          accessor: `${valueKey}p1`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P2`,
          sortType: 'basic',
          accessor: `${valueKey}p2`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY2',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 2,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P3`,
          sortType: 'basic',
          accessor: `${valueKey}p3`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY3',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 3,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P4`,
          sortType: 'basic',
          accessor: `${valueKey}p4`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY4',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 4,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P5`,
          sortType: 'basic',
          accessor: `${valueKey}p5`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY5',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 5,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P6`,
          sortType: 'basic',
          accessor: `${valueKey}p6`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY6',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 6,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P7`,
          sortType: 'basic',
          accessor: `${valueKey}p7`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY7',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 7,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P8`,
          sortType: 'basic',
          accessor: `${valueKey}p8`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY8',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 8,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P9`,
          sortType: 'basic',
          accessor: `${valueKey}p9`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY9',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 9,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P10`,
          sortType: 'basic',
          accessor: `${valueKey}p10`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY10',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 10,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P11`,
          sortType: 'basic',
          accessor: `${valueKey}p11`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY11',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 11,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 65 : 170,
          Header: t`P12`,
          sortType: 'basic',
          accessor: `${valueKey}p12`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'MONTHLY12',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'MONTHLY' && el.period === 12,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    case 'biMonthly': {
      extraCols = [
        {
          width: reportMode ? 100 : 170,
          Header: t`B1`,
          sortType: 'basic',
          accessor: `${valueKey}b1`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 100 : 170,
          Header: t`B2`,
          sortType: 'basic',
          accessor: `${valueKey}b2`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY2',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 2,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 100 : 170,
          Header: t`B3`,
          sortType: 'basic',
          accessor: `${valueKey}b3`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY3',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 3,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 100 : 170,
          Header: t`B4`,
          sortType: 'basic',
          accessor: `${valueKey}b4`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY4',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 4,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 100 : 170,
          Header: t`B5`,
          sortType: 'basic',
          accessor: `${valueKey}b5`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY5',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 5,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 80 : 170,
          Header: t`B6`,
          sortType: 'basic',
          accessor: `${valueKey}b6`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'BIMONTHLY6',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'BIMONTHLY' && el.period === 6,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    case 'quarterly': {
      extraCols = [
        {
          width: reportMode ? 150 : 195,
          Header: t`Q1`,
          sortType: 'basic',
          accessor: `${valueKey}q1`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'QUARTERLY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'QUARTERLY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 150 : 195,
          Header: t`Q2`,
          sortType: 'basic',
          accessor: `${valueKey}q2`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'QUARTERLY2',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'QUARTERLY' && el.period === 2,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 150 : 195,
          Header: t`Q3`,
          sortType: 'basic',
          accessor: `${valueKey}q3`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'QUARTERLY3',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'QUARTERLY' && el.period === 3,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 150 : 195,
          Header: t`Q4`,
          sortType: 'basic',
          accessor: `${valueKey}q4`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'QUARTERLY4',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'QUARTERLY' && el.period === 4,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    case 'tertiary': {
      extraCols = [
        {
          width: reportMode ? 200 : 260,
          Header: t`T1`,
          sortType: 'basic',
          accessor: `${valueKey}t1`,
          className: 'flex-right',
          withTotal: true,
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'TERTIARY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'TERTIARY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 200 : 260,
          Header: t`T2`,
          sortType: 'basic',
          accessor: `${valueKey}t2`,
          withTotal: true,
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'TERTIARY2',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'TERTIARY' && el.period === 2,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 200 : 260,
          Header: t`T3`,
          sortType: 'basic',
          accessor: `${valueKey}t3`,
          withTotal: true,
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'TERTIARY3',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'TERTIARY' && el.period === 3,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    case 'halfYearly': {
      extraCols = [
        {
          width: reportMode ? 300 : 390,
          Header: t`HY1`,
          sortType: 'basic',
          accessor: `${valueKey}hy1`,
          withTotal: true,
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'HALF_YEARLY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'HALF_YEARLY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
        {
          width: reportMode ? 300 : 390,
          Header: t`HY2`,
          sortType: 'basic',
          accessor: `${valueKey}hy2`,
          withTotal: true,
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'HALF_YEARLY2',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'HALF_YEARLY' && el.period === 2,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    case 'yearly': {
      extraCols = [
        {
          width: reportMode ? 150 : 195,
          Header: t`Y1`,
          sortType: 'basic',
          accessor: `${valueKey}y1`,
          withTotal: true,
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            type: 'number',
            icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
            action: reportMode ? false : 'YEARLY1',
            converter: (_, item) =>
              item?.values?.find(
                (el) => el.periodType === 'YEARLY' && el.period === 1,
              )?.[valueKey],
            disableClick: true,
          },
        },
      ];
      break;
    }

    default: {
      break;
    }
  }

  return [
    {
      // eslint-disable-next-line no-nested-ternary
      width: reportMode ? (isMonthly ? 30 : 70) : 70,
      Header: t`ID`,
      accessor: 'accountNumber',
      className: 'flex-center',
      reportWidth: 50,
    },
    {
      // eslint-disable-next-line no-nested-ternary
      width: reportMode ? (isMonthly ? 80 : 150) : 260,
      Header: t`Description`,
      accessor: 'accountDescription',
      className: '',
      withTooltip: !reportMode,
    },
    {
      // eslint-disable-next-line no-nested-ternary
      width: reportMode ? (isMonthly ? 80 : 120) : 170,
      Header: t`Opening balance`,
      sortType: 'basic',
      accessor: 'openingBalance',
      withTotal: true,
      className: 'flex-right',
      cell: {
        type: 'number',
        icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
        action: reportMode ? false : 'p0',
        disableClick: true,
      },
    },
    ...extraCols,
    {
      // eslint-disable-next-line no-nested-ternary
      width: reportMode ? (isMonthly ? 80 : 120) : 170,
      Header: t`Total`,
      sortType: 'basic',
      accessor: 'total',
      withTotal: true,
      className: 'flex-right',
      cell: {
        type: 'number',
        icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
        action: reportMode ? false : 'all',
        disableClick: true,
      },
    },
  ];
};

export default columns;
