import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { FaPaste } from '@react-icons/all-files/fa/FaPaste';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
  CustomMultiSelectInput,
} from 'components';

import { required } from 'services/validation';

function AddEditGroupCompanyModal(props) {
  const {
    item,
    users,
    loading,
    roleType,
    onSubmit,
    companies,
    userGroups,
    closeModal,
    handleChange,
    selectedUsers,
    selectedCompanies,
    selectedUserGroups,
    originalCompanies,
    setSelectedCompanies,
  } = props;
  const {
    Form,
    setFieldValue,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });
  const [textFieldValue, setTextFieldValue] = useState('');
  const [showAdditionalField, setShowAdditionalField] = useState(false);

  const addTitle = t`Add Company Group`;
  const editTitle = t`Edit Company Group`;

  const toggleField = () => setShowAdditionalField((state) => !state);

  const pasteCompanies = () => {
    if (textFieldValue) {
      const newCompanies = textFieldValue.split('\n');
      const newSelectedCompanies = [];

      newCompanies?.forEach((company) => {
        const existedCompany = originalCompanies?.find(
          (i) => i.companyName === company.trim(),
        );

        if (existedCompany) {
          const existedSelectedCompany = selectedCompanies.find(
            (i) => i.id === existedCompany.uuid,
          );

          if (!existedSelectedCompany) {
            const preparedCompany = companies.find(
              (i) => i.id === existedCompany.uuid,
            );
            newSelectedCompanies.push(preparedCompany);
          }
        }
      });

      setSelectedCompanies(() => [
        ...selectedCompanies,
        ...newSelectedCompanies,
      ]);
    }

    setTextFieldValue(() => '');
    setFieldValue('clipboard', '');
    setShowAdditionalField(false);
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>{item.groupId ? editTitle : addTitle}</Heading>
        </Modal.Header>
        <Modal.Body>
          <ReactFormField
            noBorder
            size="small"
            name="name"
            label={t`Group name`}
            validate={[required]}
            placeholder={t`Please Enter Group name`}
            autoComplete="search"
          />
          <FlexHolder>
            <FlexItem width={90}>
              <CustomMultiSelectInput
                modal
                id="company"
                label={t`Selected companies to be added to group`}
                loading={loading}
                options={companies}
                selected={selectedCompanies}
                onSelect={handleChange}
                placeholder={t`Please Select Company`}
              />
            </FlexItem>
            <FlexItem width={10}>
              <ButtonGroup bsSize="small">
                <Button fill magnify onClick={toggleField}>
                  {showAdditionalField ? (
                    <ButtonIcon as={IoClose} />
                  ) : (
                    <ButtonIcon as={FaPaste} fontSize={15} />
                  )}
                </Button>
              </ButtonGroup>
            </FlexItem>
          </FlexHolder>
          {showAdditionalField && (
            <InputHolder>
              <ReactFormField
                noBorder
                type="textarea"
                size="small"
                style={{ height: 100, marginTop: -15 }}
                name="clipboard"
                placeholder={t`Please Paste a List of Companies here`}
                value={textFieldValue}
                onChange={(e) => setTextFieldValue(e.target.value)}
              />
              <ButtonGroup bsSize="small">
                <Button
                  fill
                  onClick={pasteCompanies}
                  disabled={!textFieldValue}
                >
                  <Trans>Select all from list</Trans>
                </Button>
              </ButtonGroup>
            </InputHolder>
          )}
          <InputHolder>
            <CustomMultiSelectInput
              modal
              id="user"
              label={t`Selected users to be added to group`}
              loading={loading}
              options={users}
              selected={selectedUsers}
              onSelect={handleChange}
              placeholder={t`Please Select User`}
            />
          </InputHolder>
          <InputHolder>
            <CustomMultiSelectInput
              modal
              id="userGroup"
              label={t`Selected user groups to be added to group`}
              loading={loading}
              options={userGroups}
              selected={selectedUserGroups}
              onSelect={handleChange}
              placeholder={t`Please Select User Groups`}
            />
          </InputHolder>
          {!item.groupId && (
            <>
              {(roleType.isSuperAdmin || roleType.isSystemAdmin) && (
                <ReactFormField
                  noBorder
                  id="companiesFromParent"
                  size="small"
                  type="checkbox"
                  name="companiesFromParent"
                  label={t`Add companies from parent group`}
                />
              )}
              <ReactFormField
                noBorder
                id="usersFromParent"
                type="checkbox"
                size="small"
                name="usersFromParent"
                label={t`Add users from parent group`}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditGroupCompanyModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;

const FlexHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 15px;
`;

const FlexItem = styled.div`
  width: ${({ width }) => `${width}%`};

  .btn-group {
    width: 100% !important;
  }

  button {
    width: 100% !important;
  }
`;
