import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import BlockContent from '@sanity/block-content-to-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, Button, Heading, Spinner, Field } from 'components';

import { getDataSet } from 'services/utils/sanity';

import { DESKTOP } from 'constants/mediaSizes';

export function Sanity(props) {
  const { icon, agree, terms, title, loading, setAgree, checkbox } = props;

  const cardHeading = (
    <HeadingCustom level={3} className="card-title">
      {icon}
      <b>{title}</b>
    </HeadingCustom>
  );

  const cardFooter = (
    <Footer>
      <Field
        horizontal
        id="approved"
        type="checkbox"
        name="approved"
        value={agree}
        onChange={() => setAgree((prev) => !prev)}
        label={checkbox}
      />
      <Button
        fill
        wide
        type="submit"
        kind="primary-purple"
        tabIndex={0}
        disabled={!agree}
      >
        <Trans>Next</Trans>
      </Button>
    </Footer>
  );

  return (
    <Card
      headerBorder
      footerBorder
      kind="login"
      footer={cardFooter}
      header={cardHeading}
    >
      <CardInner>
        <PerfectScrollbarStyles>
          {loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              {terms.map((p) => (
                <BodyTerms
                  key={`term-${p.title}`}
                  blocks={p.body}
                  dataset={getDataSet()}
                  projectId={process.env.SANITY_PROJECT_ID}
                />
              ))}
            </>
          )}
        </PerfectScrollbarStyles>
      </CardInner>
    </Card>
  );
}

export default Sanity;

Sanity.propTypes = {
  icon: PropTypes.string,
  agree: PropTypes.bool,
  title: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  setAgree: PropTypes.func,
  checkbox: PropTypes.string,
};

Sanity.defaultProps = {
  icon: undefined,
  terms: [],
  agree: false,
  title: undefined,
  loading: true,
  setAgree: undefined,
  checkbox: undefined,
};

const HeadingCustom = styled((props) => <Heading {...props} />)`
  text-align: left;
  color: #484541 !important;
  margin-top: -10px !important;
  display: flex;
  padding: 0 20px !important;
  align-items: center;
`;

const Footer = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${DESKTOP.min}px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const CardInner = styled.div`
  margin: 0 20px;
`;

const PerfectScrollbarStyles = styled((props) => (
  <PerfectScrollbar {...props} />
))`
  height: 35vh;

  @media (max-height: ${DESKTOP.maxHeight}px) {
    height: 30vh;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BodyTerms = styled((props) => <BlockContent {...props} />)`
  p,
  li {
    color: #828282;
    font-size: 16px;

    a {
      color: #6971fd;
      font-weight: bold;
    }

    strong {
      color: #5d5d5d;
    }
  }

  li {
    list-style: none;

    &::before {
      content: '\\2022';
      color: #6971fd;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;
