import React from 'react';

/**
 * Caption component
 */
function Caption({ ...props }) {
  return <caption {...props} />;
}

export default Caption;
