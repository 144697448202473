import React, { useEffect, useState } from 'react';
import _uniqBy from 'lodash-es/uniqBy';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { t, plural } from '@lingui/macro';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { OpeningBalanceMap } from 'components';

import { fromResource } from 'store/selectors';
import { formatNumber } from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  getUBOBDataApi,
  putUBOBMatchApi,
  deleteUBOBBreakApi,
  getUBvsOBStatusApi,
} from 'services/apihelpers';
import {
  resourceDeleteRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';

function OpeningBalanceMapContainer() {
  const [selectedRows, setSelectedRows] = useState({ OB: [], UB: [] });
  const [manualSelectFunc, setManualSelectFunc] = useState({
    ob: null,
    cb: null,
  });
  const [actionSubmitting, setActionSubmitting] = useState({});
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const getSearch = () => {
    const search = queryString.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });

    return {
      account: search.account || '',
      tabEntry: search.tabEntry || 'map',
      reportMode: search.reportMode || false,
    };
  };
  const search = getSearch();

  const data = useSelector((state) =>
    fromResource.getList(state, getUBOBDataApi),
  );
  const ubVSobData = useSelector((state) =>
    fromResource.getList(state, getUBvsOBStatusApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getUBOBDataApi}ListRead`),
  );

  const getManualSelectFuncOB = (func) => {
    setManualSelectFunc((state) => ({ ...state, ob: func }));
  };
  const getManualSelectFuncCB = (func) => {
    setManualSelectFunc((state) => ({ ...state, cb: func }));
  };
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getUBOBDataApi, {
        accountId: search.account,
      }),
    );
    dispatch(
      resourceListReadRequest(getUBvsOBStatusApi, {
        accountId: search.account,
      }),
    );
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const _setSelectedRows = (props) => {
    const { tableId, item, selected } = props;

    if (!tableId) return;

    const newRows = { ...selectedRows };

    if (!newRows[tableId]) {
      newRows[tableId] = [];
    }

    if (item === 'all') {
      if (!selected) {
        newRows[tableId] = [];
      }
    } else if (Array.isArray(item)) {
      newRows[tableId] = [...item];
    } else if (selected) {
      const index = newRows[tableId].findIndex(
        (el) => el.lineId === item.lineId,
      );

      if (index === -1) {
        newRows[tableId] = [...newRows[tableId], { ...item }];
      }
    } else {
      newRows[tableId] = [...newRows[tableId]];

      const index = newRows[tableId].findIndex(
        (el) => el.lineId === item.lineId,
      );

      if (index >= 0) {
        newRows[tableId].splice(index, 1);
      }
    }

    if (newRows[tableId].length) {
      newRows[tableId] = _uniqBy(newRows[tableId], 'lineId');
    }

    setSelectedRows(newRows);
  };

  const clearSelectedIds = () => {
    manualSelectFunc.ob([]);
    manualSelectFunc.cb([]);
  };

  const reconcile = async () => {
    const newActionSubmitting = { ...actionSubmitting };

    selectedRows.OB.forEach((item) => {
      newActionSubmitting[item.lineId] = true;
    });
    selectedRows.UB.forEach((item) => {
      newActionSubmitting[item.lineId] = true;
    });

    setActionSubmitting(newActionSubmitting);

    const ubIds = [];

    selectedRows.UB.forEach((item) => {
      if (Array.isArray(item.lineId)) {
        ubIds.push(...item.lineId);
      } else {
        ubIds.push(item.lineId);
      }
    });

    await dispatch(
      resourceUpdateRequest(putUBOBMatchApi, null, {
        obIds: selectedRows.OB.map((item) => item.lineId),
        ubIds,
      }),
    );

    selectedRows.OB.forEach((item) => {
      newActionSubmitting[item.lineId] = false;
    });
    selectedRows.UB.forEach((item) => {
      newActionSubmitting[item.lineId] = false;
    });

    setActionSubmitting(newActionSubmitting);
    setSelectedRows({ OB: [], UB: [] });
    fetchData();
  };
  const breakReconcile = async (item, tableId) => {
    _setActionSubmitting(item.lineId, true);

    await dispatch(
      resourceDeleteRequest(
        `${deleteUBOBBreakApi}?${queryString.stringify({
          ob: tableId === 'OB',
          lineId: item.lineId,
        })}`,
      ),
    );

    _setActionSubmitting(item.lineId, false);

    fetchData();
  };
  const getRowColor = (row) => {
    let bgColorClass = '';

    if (row.isReconciled) {
      bgColorClass = 'success';
    }

    return bgColorClass;
  };
  const handleAction = async (action, props, tableId) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowSelect': {
          _setSelectedRows(props);
          break;
        }

        case 'reconcile': {
          await reconcile();
          break;
        }

        case 'break': {
          await breakReconcile(props, tableId);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const topSelectConverter = () => {
    let str = '';
    const selectedOB = selectedRows.OB.reduce(
      (sum, row) => sum + row.amount,
      0,
    );
    const selectedUB = selectedRows.UB.reduce(
      (sum, row) => sum + row.amount,
      0,
    );

    str += plural(selectedRows.OB.length, {
      zero: 'No OB rows selected',
      one: `${selectedRows.OB.length} OB row selected`,
      other: `${selectedRows.OB.length} OB rows selected`,
    });

    str += ' - ';

    str += plural(selectedRows.UB.length, {
      zero: 'No CB rows selected',
      one: `${selectedRows.UB.length} CB row selected`,
      other: `${selectedRows.UB.length} CB rows selected`,
    });

    str += ' → ';

    str += t`Deviation: ${formatNumber(-selectedOB + selectedUB).formatted}`;

    return str;
  };

  useEffect(() => {
    fetchData();
  }, [search.account, JSON.stringify(params)]);

  return (
    <OpeningBalanceMap
      {...{
        account: search.account,
        data,
        search,
        loading,
        ubVSobData,
        selectedRows,
        actionSubmitting,
        getRowColor,
        handleAction,
        clearSelectedIds,
        topSelectConverter,
        getManualSelectFuncOB,
        getManualSelectFuncCB,
      }}
    />
  );
}

export default OpeningBalanceMapContainer;
