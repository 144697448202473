import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import b64toBlob from 'b64-to-blob';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal } from 'react-bootstrap';

import { Button, ButtonIcon, CustomTable } from 'components';

import { excelRenderer } from 'services/helpers';

function Excel(props) {
  const { item, close, title } = props;
  const { blobImage, mimeType } = item;

  const [sheets, setSheets] = useState([]);
  const [activeSheet, setActiveSheet] = useState(null);

  const generateTableShapes = useCallback(
    (columns) =>
      columns.map((column) => ({
        width: 1000 / columns.length,
        Header: column,
        accessor: column,
        withTooltip: true,
      })),
    [],
  );

  const generateRows = useCallback(
    (sheetRows, columns) =>
      sheetRows.map((row) => {
        const newRow = {};

        columns.forEach((column, index) => {
          newRow[column] = row[index];
        });

        return newRow;
      }),
    [],
  );

  const generateTableFilters = useCallback(
    (columns) => ({
      id: 'search',
      size: 'sm',
      type: 'input',
      label: t`Search`,
      placeholder: t`Type to search`,
      fieldsToSearch: columns,
    }),
    [],
  );

  const tableData = useMemo(() => {
    const data = [];
    const columns = [];
    const filterConfigs = [];

    if (!activeSheet) return null;

    const sheet = sheets.find((s) => s.header === activeSheet);
    const sheetColumns = sheet.cols.map((col) => col.name);

    columns.push(...generateTableShapes(sheetColumns));
    filterConfigs.push(generateTableFilters(sheetColumns));
    data.push(...generateRows(sheet.rows, sheetColumns));

    return { data, columns, filterConfigs };
  }, [activeSheet]);
  const { data, columns, filterConfigs } = tableData || {};

  useEffect(() => {
    const blob = b64toBlob(blobImage, mimeType);

    excelRenderer(blob, (e, response) => {
      if (e) {
        setSheets([]);
        setActiveSheet(null);

        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      } else {
        setSheets(response);
        setActiveSheet(response?.length ? response[0].header : null);
      }
    });
  }, [blobImage, mimeType]);

  const changeActiveSheet = useCallback((e) => setActiveSheet(e.target.id), []);

  return (
    <Modal show bsSize="xl" onClose={close}>
      <Modal.Header>
        <Header>{title}</Header>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SubTitle>
            <Trans>Sheets:</Trans>
          </SubTitle>
          <WrapperInfo>
            {sheets.map((sheet) => (
              <InfoBlock
                id={sheet.header}
                key={sheet.header}
                active={activeSheet === sheet.header}
                onClick={changeActiveSheet}
              >
                {sheet.header}
              </InfoBlock>
            ))}
          </WrapperInfo>
        </div>
        <div className="container-fluid" style={{ padding: 0 }}>
          {tableData ? (
            <CustomTable
              {...{
                data,
                columns,
                filterConfigs,
                isModal: true,
              }}
            />
          ) : (
            <Message>
              <Trans>Document is empty</Trans>
            </Message>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={close}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Header = styled.div`
  font-size: 24px;
`;

const SubTitle = styled.h2`
  padding: 0;
  margin: 0 0 10px;
  font-size: 18px;
`;

const WrapperInfo = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 15px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.active === true ? '#75747a' : '#f5f5f5'};
  color: ${(props) => (props.active === true ? '#f5f5f5' : '#75747a')};
  border-radius: 15px;
  font-size: 14px;
  padding: 5px 15px;
  &:hover {
    cursor: pointer;
    background-color: #75747a;
    color: #f5f5f5;
  }
  div:first-child {
    overflow-wrap: break-word;
    width: 90%;
  }
`;

const Message = styled.p`
  color: gray;
  display: block;
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
`;

export default Excel;

Excel.propTypes = {
  item: PropTypes.shape({
    mimeType: PropTypes.string,
    blobImage: PropTypes.string,
  }),
  close: PropTypes.func,
};

Excel.defaultProps = {
  item: {},
  close: () => {},
};
