import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';

import {
  VATReportNewer,
  VATUnmappedLinesModal,
  Altinn3Popup,
} from 'components';

import {
  toReconciliationSettings,
  toVATReconciliationDetailed,
} from 'services/routehelpers';
import { resourceCreateRequest } from 'store/actions';
import {
  autoReconcileVATApi,
  undoAutoReconcileVATApi,
} from 'services/apihelpers';
import { fromCompany } from 'store/selectors';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { t } from '@lingui/macro';

function VATReconciliationTableContainer(props) {
  const {
    history,
    roleType,
    disabled,
    approveAndGeneratePDF,
    generatePDF,
    pdfGenerating,
    vatReportData,
    activePeriods,
    handlePeriodChange,
    refetchAll,
  } = props;
  const dispatch = useDispatch();
  const { accountingYear } = useParams();
  const {
    period,
    loading,
    tableData,
    frequency,
    isApproved,
    frequencies,
    approverInfo,
    setFrequency,
    unmappedLines,
    handleApproveChange,
  } = vatReportData;

  const [actionSubmitting, setActionSubmitting] = useState({});
  const [showUnmappedModal, setShowUnmappedModal] = useState(false);

  const { currentCompanySID } = useSelector(fromCompany.getCompany);

  const { openPopup } = Altinn3Popup({
    title: 'Altinn Innlogging',
    url: '/integrations/altinn3/id-porten?service=vat-reports',
    onHide: () => {
      refetchAll();
    },
  });

  const autoReconcileLoading = useSelector(
    (state) =>
      pending(state, `${autoReconcileVATApi}Create`) ||
      pending(state, `${undoAutoReconcileVATApi}Create`),
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAuthenticate = async () => {
    _setActionSubmitting('authenticate', true);

    openPopup();

    _setActionSubmitting('authenticate', false);
  };

  const handleNewerDrilldownClick = (type, item) => {
    history.push(
      toVATReconciliationDetailed(
        {
          term: period,
          // accumulated,
          frequency,
          mode: 'newer',
          rowsType: type,
        },
        item.id,
      ),
    );
  };

  const handleAutoReconcile = async (action) => {
    try {
      _setActionSubmitting('autoReconcile', true);

      if (action === false) {
        await dispatch(
          resourceCreateRequest(undoAutoReconcileVATApi, {
            year: accountingYear,
            companySid: currentCompanySID,
          }),
        );
        await refetchAll();
        toast.success(t`VAT reconciliation undone successfully`);
      } else {
        await dispatch(
          resourceCreateRequest(autoReconcileVATApi, {
            year: accountingYear,
            companySid: currentCompanySID,
          }),
        );
        await refetchAll();
        toast.success(t`VAT reconciliation completed successfully`);
      }
      _setActionSubmitting('autoReconcile', false);
    } catch (e) {
      _setActionSubmitting('autoReconcile', false);
      toast.error(getErrorMessage(e));
    }
  };

  const handleAction = async (type, action) => {
    try {
      _setActionSubmitting(type, true);

      switch (type) {
        case 'authenticate': {
          await handleAuthenticate();
          break;
        }
        case 'generatePDF': {
          await generatePDF({ skipDownload: false, saveReport: false });
          break;
        }
        case 'approve': {
          if (action === true) {
            await approveAndGeneratePDF({
              skipDownload: true,
              saveReport: true,
            });
            toast.success(t`Report generated successfully`);
          }
          await handleApproveChange(action);
          break;
        }
        case 'baseClick': {
          handleNewerDrilldownClick('BASE', action);
          break;
        }
        case 'reverseClick': {
          handleNewerDrilldownClick('REVERSE', action);
          break;
        }
        case 'taxClick': {
          handleNewerDrilldownClick('TAX', action);
          break;
        }
        case 'showUnmappedLines': {
          setShowUnmappedModal(true);
          break;
        }
        case 'goToSettings': {
          history.push(
            toReconciliationSettings({
              tabEntry: 'vat-settings',
            }),
          );
          break;
        }
        case 'autoReconcile': {
          await handleAutoReconcile(action);
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(type, false);
    } catch (e) {
      _setActionSubmitting(type, false);
      toast.error(getErrorMessage(e));
    }
  };

  const handleFrequencyChange = (e) => {
    const newFrequency = e.target.value;
    setFrequency(newFrequency);
  };

  const closeUnmappedModal = () => setShowUnmappedModal(false);

  return (
    <>
      {showUnmappedModal && (
        <VATUnmappedLinesModal
          data={unmappedLines}
          year={accountingYear}
          closeModal={closeUnmappedModal}
          {...{
            term: period,
            loading,
            handleAction,
            actionSubmitting,
          }}
        />
      )}
      <VATReportNewer
        year={accountingYear}
        {...{
          loading,
          roleType,
          disabled,
          frequency,
          tableData,
          isApproved,
          frequencies,
          approverInfo,
          approveAndGeneratePDF,
          pdfGenerating,
          // accumulated,
          handleAction,
          unmappedLines,
          activePeriods,
          actionSubmitting,
          handlePeriodChange,
          handleFrequencyChange,
          autoReconcileLoading,
          // handleAccumulatedChange,
        }}
      />
    </>
  );
}

export default VATReconciliationTableContainer;
