import { t } from '@lingui/macro';

/**
 * Accruals Origin Table Grid
 */
export const columns = () => [
  {
    id: 1,
    label: '',
    value: '',
    sortable: false,
    className: 'text-center',
    enabled: true,
  },
  {
    id: 2,
    label: t`Transaction ID`,
    value: 'transactionId',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 3,
    label: t`Period`,
    value: 'period',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 4,
    label: t`Description`,
    value: 'description',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 5,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
