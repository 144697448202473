import { t } from '@lingui/macro';

export const vatReportNewerDetailedFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'transactionId',
      'vatCode',
      'vatCodeDescription',
      'accountId',
      'lineId',
    ],
  },
];

export default { vatReportNewerDetailedFilters };
