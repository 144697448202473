import React, { useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import styled from 'styled-components/macro';

import {
  Card,
  Field,
  Label,
  Spinner,
  ButtonIcon,
  PeriodFilterButtons,
  CustomTableWithAutoSettings,
} from 'components';

import CardPDFTitle from 'containers/CardPDFTitle';

// import { periodTypes } from 'services/shapes';
import { vatOverviewColumns } from 'services/tableshapes';
import { vatOverviewFilters } from 'services/tableFilterShapes';

const values = {
  BIMONTHLY: t`Bi-Monthly`,
  YEARLY: t`Yearly`,
  MONTHLY: t`Monthly`,
};

const periodTypes = {
  QUARTERLY: 'quarterly',
  BIMONTHLY: 'biMonthly',
  MONTHLY: 'monthly',
};

function VATOverview(props) {
  const {
    loading,
    showAll,
    disabled,
    roleType,
    tableData,
    frequencies,
    frequency,
    accumulated,
    handleAction,
    limitPeriods,
    pdfGenerating,
    activePeriods,
    accountingYear,
    actionSubmitting,
    handlePeriodChange,
    handleCheckboxChange,
    handleAccumulatedChange,
    handleFrequencyChange,
  } = props;

  const { rows, additionalTotalRows } = tableData;

  const arrayedActivePeriods = useMemo(
    () => Array.from(Array(activePeriods).keys()),
    [activePeriods],
  );

  return (
    <Card
      id="pdfToPrint"
      data-testid="pdfToPrint-vatOverview"
      header={
        <CardPDFTitle title={<Trans>VAT Overview - {accountingYear}</Trans>} />
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <MetaData>
            <PeriodContainer>
              {frequencies?.length > 1 && (
                <InfoBlock>
                  <Title>
                    <Trans>Frequency:</Trans>
                  </Title>
                  <Field
                    name="selectedReport"
                    type="select"
                    onChange={handleFrequencyChange}
                    defaultValue={frequency}
                  >
                    {frequencies.map((item) => (
                      <option key={`frequency-${item}`} value={item}>
                        {values[item] || item}
                      </option>
                    ))}
                  </Field>
                </InfoBlock>
              )}
              {frequency !== 'YEARLY' && !!frequency && (
                <PeriodFilterButtons
                  size="small"
                  singlePeriod={!accumulated}
                  periodType={periodTypes[frequency]}
                  activePeriods={arrayedActivePeriods}
                  handleFilterChange={handlePeriodChange}
                />
              )}
            </PeriodContainer>
            <PeriodContainer>
              <Field
                horizontal
                id="accumulated"
                type="checkbox"
                name="accumulated"
                label={t`Show single period`}
                checked={!accumulated}
                onChange={handleAccumulatedChange}
              />
              <Field
                horizontal
                id="showAll"
                type="checkbox"
                name="showAll"
                label={t`Show all VAT-rows`}
                checked={showAll}
                onChange={handleCheckboxChange}
              />
            </PeriodContainer>
          </MetaData>
          <CustomTableWithAutoSettings
            {...{
              data: rows,
              loading,
              handleAction,
              actionSubmitting,
              tableId: 'vatOverview',
              loadingId: 'id',
              groupId: 'uiGroupId',
              enableGroups: true,
              withCellClick: true,
              additionalTotalRows,
              totalColumnIndex: 1,
              withoutMainTotal: true,
              columns: vatOverviewColumns(
                accountingYear,
                frequency,
                limitPeriods,
                accumulated,
              ),
              filterConfigs: vatOverviewFilters(),
              headerConfigs: {
                // eslint-disable-next-line max-len
                exportFileName: t`VAT Overview - ${accountingYear}, ${limitPeriods}`,
                extraButtons: roleType.isReadOnly
                  ? [
                      {
                        id: 'generatePDF',
                        title: t`Generate PDF Report`,
                        icon: <ButtonIcon as={FaFilePdf} type="extraButton" />,
                        buttonProps: {
                          size: 'sm',
                          kind: 'default',
                          fill: false,
                          magnify: true,
                          disabled: pdfGenerating || disabled,
                        },
                      },
                    ]
                  : [
                      {
                        id: 'generatePDF',
                        title: t`Generate PDF Report`,
                        icon: <ButtonIcon as={FaFilePdf} type="extraButton" />,
                        buttonProps: {
                          size: 'sm',
                          kind: 'default',
                          fill: false,
                          magnify: true,
                          disabled: pdfGenerating || disabled,
                        },
                      },
                      {
                        id: 'authenticate',
                        title: t`Fetch VAT returns`,
                        buttonProps: {
                          size: 'sm',
                          kind: 'info',
                          fill: false,
                          magnify: true,
                        },
                      },
                    ],
              },
            }}
          />
        </>
      )}
    </Card>
  );
}

export default VATOverview;

const MetaData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .form-group {
    display: flex;
    align-items: center;
  }

  label {
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    margin-bottom: 0;
  }

  select {
    background: white;
    margin-top: 2px;
  }
`;

const PeriodContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-right: 8px;

  select {
    height: 22px;
    padding: 1px 6px;
    font-size: 12px;
  }
`;

const Title = styled(Label)`
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 5px;
`;
