import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { Modal, Row, Col } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';

import {
  Button,
  Spinner,
  Heading,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { colorModes } from 'services/shapes';
import { defineRoles } from 'services/helpers';
import {
  match,
  email,
  isMobile,
  required,
  minLength,
  passwordPattern,
} from 'services/validation';

function AdminUserSettingsModal(props) {
  const {
    onSubmit,
    roleList,
    submitting,
    closeModal,
    modalAction,
    autoGenerate,
    defaultValues,
    userGroupsList,
    getLanguageList,
    generatePassword,
    userGroupsLoading,
    isUserGroupRequired,
  } = props;
  const {
    Form,
    meta: { canSubmit, isTouched },
  } = useForm({ onSubmit, defaultValues });
  const disabled = submitting || !canSubmit || !isTouched || userGroupsLoading;

  let role;
  if (!isEmpty(roleList)) {
    role = [
      <option key="selectOptionDefaultRole" value="" disabled>
        {t`Please Select Your Role`}
      </option>,
      Object.values(roleList).map((item) => (
        <option key={`roleList-${item.roleName}`} value={item.roleName}>
          {defineRoles([item.roleName])}
        </option>
      )),
    ];
  }

  let languageOptions;
  if (!isEmpty(getLanguageList)) {
    languageOptions = [
      <option key="selectOptionDefaultLanguage" value="" disabled>
        {t`Please Select Your Language`}
      </option>,
      Object.values(getLanguageList).map((item) => (
        <option
          key={`languageList-${item.languageCode}`}
          value={item.languageCode}
        >
          {item.language}
        </option>
      )),
    ];
  }

  let userGroupsOptions;
  if (!isEmpty(userGroupsList)) {
    userGroupsOptions = userGroupsList.map((item) => ({
      value: item.groupId,
      label: item.name || item.groupId.toString(),
    }));
  }
  const colorModeOptions = [
    <option key="selectOptionDefaultTheme" value="" disabled>
      {t`Please Select Your Theme`}
    </option>,
    colorModes().map((item) => (
      <option key={`selectOptionTheme-${item.id}`} value={item.id}>
        {item.label}
      </option>
    )),
  ];

  const modalActionText = modalAction ? (
    <Trans>Edit User</Trans>
  ) : (
    <Trans>Add User</Trans>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Form>
        <Modal.Header>
          <Heading level={3}>{modalActionText}</Heading>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <ReactFormField
                noBorder
                name="name"
                size="small"
                label={t`Name`}
                placeholder={t`Please Enter Your Name`}
              />
              <ReactFormField
                noBorder
                name="username"
                type="email"
                size="small"
                label={t`Username`}
                validate={[email, required]}
                placeholder={t`Please Enter Your Email`}
                autoComplete="off"
              />
              {!autoGenerate && (
                <div>
                  <ReactFormField
                    noBorder
                    name="password"
                    size="small"
                    type="password"
                    label={t`Password`}
                    validate={
                      modalAction
                        ? [passwordPattern]
                        : [required, passwordPattern]
                    }
                    validatePristine={!autoGenerate}
                    placeholder={t`Please Enter Your Password`}
                    autoComplete="off"
                  />
                  <ReactFormField
                    noBorder
                    name="retypePassword"
                    size="small"
                    type="password"
                    label={t`Retype Password`}
                    validate={[match('password'), minLength(8)]}
                    placeholder={t`Please Retype Your Password`}
                    autoComplete="off"
                    validatePristine={!modalAction}
                  />
                </div>
              )}
              <ReactFormField
                noBorder
                size="small"
                type="checkbox"
                id="generatePassword"
                name="generatePassword"
                label={t`Generate Password`}
                onChange={generatePassword}
              />
            </Col>
            <Col md={6}>
              <ReactFormField
                noBorder
                name="contactNo"
                size="small"
                label={t`Contact Number`}
                validate={[isMobile]}
                placeholder={t`Please Enter Your Contact Number`}
              />
              <ReactFormField
                noBorder
                name="language"
                type="select"
                size="small"
                label={t`Language`}
                validate={[required]}
              >
                {languageOptions}
              </ReactFormField>
              <ReactFormField
                noBorder
                size="sm"
                type="select"
                name="colorMode"
                label={t`Theme`}
              >
                {colorModeOptions}
              </ReactFormField>
              <ReactFormField
                noBorder
                name="roleName"
                size="small"
                type="select"
                label={t`Role`}
                validate={[required]}
                defaultValue="selectOptionDefault"
              >
                {role}
              </ReactFormField>
              <ReactFormField
                noBorder
                multiple
                name="userGroupIds"
                size="small"
                type="autoSuggest"
                label={t`User Groups`}
                loading={userGroupsLoading}
                options={userGroupsOptions}
                validate={!!isUserGroupRequired.status && [required]}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button fill kind="success" disabled={disabled} magnify type="submit">
            {submitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                {modalAction ? (
                  <ButtonIcon as={HiCheck} />
                ) : (
                  <ButtonIcon as={HiPlus} />
                )}{' '}
                {modalAction ? t`Save` : t`Create`}
              </>
            )}
          </Button>
          <Button fill magnify kind="danger" onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdminUserSettingsModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  autoGenerate: PropTypes.bool,
  generatePassword: PropTypes.func.isRequired,
  modalAction: PropTypes.bool,
  submitting: PropTypes.bool,
  roleList: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({}))]),
  getLanguageList: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({}))]),
};
AdminUserSettingsModal.defaultProps = {
  roleList: undefined,
  submitting: false,
  modalAction: false,
  autoGenerate: false,
  getLanguageList: undefined,
};

export default AdminUserSettingsModal;
