import { t } from '@lingui/macro';

export const columns = (reportMode) => [
  {
    width: reportMode ? 520 : 780,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 160 : 230,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
