import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

import { CustomMultiSelectInput, Field, Label, Toggle } from 'components';

import { formatDate, formatNumber } from 'services/helpers';

export function CustomTableFilters(props) {
  const { tableId, filterOpen, tableProps, filterConfigs, toggleFilters } =
    props;

  const {
    setGlobalFilter,
    state: { globalFilter },
  } = tableProps;
  const handleInputChange = (e) => {
    const newFilters = { ...globalFilter };

    if (e.target.value) {
      newFilters[e.target.id] = e.target.value;
    } else {
      delete newFilters[e.target.id];
    }

    setGlobalFilter(newFilters);
  };
  const handleRangeInputChange = (e, fieldId) => {
    const newFilters = { ...globalFilter };

    if (e.target.value) {
      newFilters[fieldId] = {
        ...newFilters[fieldId],
        [e.target.id]: e.target.value,
      };
    } else {
      delete newFilters[fieldId][e.target.id];
    }

    if (!Object.keys(newFilters[fieldId]).length) {
      delete newFilters[fieldId];
    }

    setGlobalFilter(newFilters);
  };
  const handleSelect = (value, name) => {
    const newFilters = { ...globalFilter, [name]: value };

    if (!value.length) {
      delete newFilters[name];
    }

    setGlobalFilter(newFilters);
  };
  const handleBooleanChange = (name) => {
    const newFilters = { ...globalFilter, [name]: !globalFilter[name] };

    setGlobalFilter(newFilters);
  };
  const renderFilter = (item) => {
    let content;

    switch (item.type) {
      case 'number':
      case 'input': {
        content = (
          <Field
            {...item}
            value={globalFilter[item.id] || ''}
            onChange={handleInputChange}
          />
        );
        break;
      }
      case 'range': {
        content = (
          <RangeHolder>
            <Label>{item.label}</Label>
            <FieldsHolder>
              <FieldHolder padding="right">
                <Field
                  id="from"
                  size="sm"
                  type="number"
                  value={globalFilter[item.id]?.from || ''}
                  onChange={(e) => handleRangeInputChange(e, item.id)}
                  placeholder={t`From`}
                />
              </FieldHolder>
              <FieldHolder padding="left">
                <Field
                  id="to"
                  size="sm"
                  type="number"
                  value={globalFilter[item.id]?.to || ''}
                  onChange={(e) => handleRangeInputChange(e, item.id)}
                  placeholder={t`To`}
                />
              </FieldHolder>
            </FieldsHolder>
          </RangeHolder>
        );
        break;
      }
      case 'multiSelect': {
        content = (
          <CustomMultiSelectHolder>
            <CustomMultiSelectInput
              selected={globalFilter[item.id] || []}
              onSelect={handleSelect}
              {...item}
            />
          </CustomMultiSelectHolder>
        );
        break;
      }
      case 'singleSelect': {
        content = (
          <CustomMultiSelectHolder>
            <CustomMultiSelectInput
              single
              selected={globalFilter[item.id] || []}
              onSelect={handleSelect}
              {...item}
            />
          </CustomMultiSelectHolder>
        );
        break;
      }
      case 'boolean': {
        content = (
          <Toggle
            value={globalFilter[item.id]}
            onClick={handleBooleanChange}
            labelPosition="top"
            {...item}
          />
        );
        break;
      }
      case 'date': {
        content = (
          <RangeHolder>
            <Label>{item.label}</Label>
            <FieldsHolder>
              <FieldHolder padding="right">
                <Field
                  id="from"
                  max={globalFilter[item.id]?.to}
                  size="sm"
                  type="date"
                  value={globalFilter[item.id]?.from || null}
                  onChange={(e) => handleRangeInputChange(e, item.id)}
                />
              </FieldHolder>
              <span>to</span>
              <FieldHolder padding="left">
                <Field
                  id="to"
                  min={globalFilter[item.id]?.from}
                  size="sm"
                  type="date"
                  value={globalFilter[item.id]?.to || null}
                  onChange={(e) => handleRangeInputChange(e, item.id)}
                />
              </FieldHolder>
            </FieldsHolder>
          </RangeHolder>
        );
        break;
      }
      default: {
        break;
      }
    }

    return (
      <FilterItem key={`table-filter-field-${tableId}-${item.id}`}>
        {content}
      </FilterItem>
    );
  };
  const getFilterValue = (value) => {
    let previewValue = value;

    if (Array.isArray(value)) {
      previewValue = value.map((item) => item.label).join(', ');
    } else if (typeof value === 'object') {
      // Number range
      if (!Number.isNaN(+value.from) || !Number.isNaN(+value.to)) {
        previewValue = `${
          value.from ? formatNumber(+value.from).formatted : ''
        } - ${value.to ? formatNumber(+value.to).formatted : ''}`;

        // Date range
      } else if (value.from || value.to) {
        previewValue = `${value.from ? formatDate(value.from) : ''} - ${
          value.to ? formatDate(value.to) : ''
        }`;
      }
    }

    return previewValue;
  };
  const removeFilter = (e, id) => {
    e.stopPropagation();

    const newFilters = { ...globalFilter };

    delete newFilters[id];

    setGlobalFilter(newFilters);
  };
  const renderAppliedFilter = (item) => {
    if (!globalFilter[item.id]) {
      return false;
    }

    return (
      <FilterPreview
        key={`table-filter-applied-${tableId}-${item.id}`}
        onClick={toggleFilters}
      >
        <LeftBlock>
          <PreviewLabel>{item.label}</PreviewLabel>
          <PreviewValue>{getFilterValue(globalFilter[item.id])}</PreviewValue>
        </LeftBlock>
        {!item.required ? (
          <Icon
            as={IoClose}
            onClick={(e) => removeFilter(e, item.id)}
            data-testid="icon-close"
          />
        ) : null}
      </FilterPreview>
    );
  };

  return (
    <ContentWrapper>
      {filterConfigs
        .filter((item) => item.id !== 'search')
        .map(filterOpen ? renderFilter : renderAppliedFilter)}
    </ContentWrapper>
  );
}

CustomTableFilters.propTypes = {
  tableId: PropTypes.string,
  filters: PropTypes.shape({}),
  filterOpen: PropTypes.bool,
  tableProps: {
    globalFilter: PropTypes.shape({}),
  },
  filterConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  toggleFilters: PropTypes.func.isRequired,
};

CustomTableFilters.defaultProps = {
  tableId: '',
  filters: {},
  filterOpen: false,
  tableProps: {
    globalFilter: {},
  },
  filterConfigs: [],
};

export default CustomTableFilters;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;

const FilterItem = styled.div`
  margin: 10px;

  input {
    width: 100%;
    padding: 10px;
    font-size: 12px;
  }
`;

const RangeHolder = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const FieldsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FieldHolder = styled.div`
  padding-${(props) => props.padding}: 5px;
`;

const FilterPreview = styled.div`
  margin: 5px;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  border-radius: 15px;
  flex-direction: row;
  background-color: #f5f5f5;
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewLabel = styled.div`
  font-size: 11px;
  font-weight: bold;
`;

const PreviewValue = styled.div`
  font-size: 12px;
`;

const Icon = styled.i`
  top: 0;
  left: 5px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  margin-left: 5px;
`;

const CustomMultiSelectHolder = styled.div`
  width: 155px;
`;
