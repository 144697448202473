import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import _map from 'lodash-es/map';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash-es/isEmpty';
import ResizeObserver from 'resize-observer-polyfill';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  useTable,
  useSortBy,
  useRowSelect,
  usePagination,
  useBlockLayout,
  useGlobalFilter,
  useResizeColumns,
} from 'react-table';

import {
  Spinner,
  Heading,
  LegendList,
  AnimateAlert,
  CustomScrollbar,
  CustomTableBody,
  CustomTableTotal,
  NothingToDisplay,
  CustomTableSelect,
  CustomTableHeader,
  CustomTableFilters,
  CustomTableTopContent,
} from 'components';

import {
  PAGE_VIEW as pageView,
  PAGE_SIZES as pageSizes,
  DEFAULT_COL,
} from 'constants/table';
import { useCurrentWidth } from 'services/hooks';
import {
  getGlobalSearch,
  calculateRowHeight,
  responsiveCellWidth,
} from 'services/helpers';

import { TWO_K, FOUR_K, LARGE_SCREENS } from 'constants/mediaSizes';

export function CustomTable(props) {
  const {
    height,
    loading,
    tableId,
    isModal,
    groupId,
    legends = [],
    withTitle,
    getSumRow,
    loadingId,
    minHeight,
    totalName,
    fixedSize,
    tableTitle,
    reportMode,
    getRowColor,
    hideFilters,
    enableGroups,
    withRowClick,
    initialState,
    handleAction,
    hideSelection,
    withCellClick,
    filterConfigs,
    selectConfigs,
    headerConfigs,
    tableSettings,
    predefinedTotal,
    actionSubmitting,
    dynamicRowHeight,
    withoutMainTotal,
    totalColumnIndex,
    getManualSelectFunc,
    totalTitleClassName,
    updateTableSettings,
    additionalTotalRows,
    isStaticTableHeight,
    dontClearSelection,
  } = props;
  const PAGE_VIEW = pageView();
  const PAGE_SIZES = pageSizes();

  const [grid, setGrid] = useState(PAGE_VIEW[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [tableHeight, setTableHeight] = useState(height);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [deleteItemCache, setDeleteItemCache] = useState(null);
  const [showWarningAlert, setShowWarningAlert] = useState(false);

  const refs = [];
  let { data } = props;
  let tableProps;
  let serverTableState = {};
  let showResetSettings = false;

  if (tableSettings?.id) {
    serverTableState = JSON.parse(tableSettings?.tableState);
    showResetSettings =
      tableSettings.initialTableState !== tableSettings.tableState;

    if (hideFilters && !reportMode) {
      serverTableState.hiddenColumns = [];
    }
  }

  if (!Array.isArray(data)) {
    data = [];
  }

  const [filterOpen, setFilterOpen] = useState(false);
  const toggleFilters = () => setFilterOpen(!filterOpen);
  const tableRef = useRef();
  const wrapperRef = useRef();
  const prevDataLengthRef = useRef();
  const defaultColumn = useMemo(() => DEFAULT_COL, []);
  const memoData = useMemo(() => data, [JSON.stringify(data)]);
  const globalFilterFunc = useMemo(
    () => (rows, columnIds, globalFilterValue) =>
      rows.filter((row) => {
        let valid = true;

        _map(globalFilterValue, (value, key) => {
          if (valid) {
            if (key.includes('Custom')) {
              valid = true;
            } else if (typeof value === 'boolean') {
              // For Toggle filters

              valid = !!row.original[key] === value;
            } else if (Array.isArray(value) && value.length) {
              // For MultiSelect filters

              if (key === 'accumulated') {
                valid =
                  (value[0].id === 'accumulated' && row.original[key]) ||
                  (value[0].id === 'withoutAccumulated' && !row.original[key]);
              } else if (key === 'comment') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'withComments' && !!row.original[key]) ||
                    (value[0].id === 'withoutComments' && !row.original[key]);
                }
              } else if (key === 'reconciled') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'MATCHED' && row.original[key]) ||
                    (value[0].id === 'UNMATCHED' && !row.original[key]);
                }
              } else if (key === 'secret') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'available' && !row.original[key]) ||
                    (value[0].id === 'not_available' && !!row.original[key]);
                }
              } else if (key === 'estimatedRemainingMonths') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'withFinishedAccruals' &&
                      !row.original[key]) ||
                    (value[0].id === 'withoutFinishedAccruals' &&
                      !!row.original[key]);
                }
              } else if (key === 'approved') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'approved' && row.original[key]) ||
                    (value[0].id === 'unApproved' && !row.original[key]);
                }
              } else if (key === 'vatCode') {
                if (value[0].id === 'show') {
                  valid = true;
                } else {
                  valid = row.original[key] !== '0';
                }
              } else if (key === 'controlled') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'controlled' && row.original[key]) ||
                    (value[0].id === 'unControlled' && !row.original[key]);
                }
              } else if (key === 'recordStatus') {
                if (value.length === 2) {
                  valid = true;
                } else {
                  valid =
                    (value[0].id === 'activated' && row.original[key]) ||
                    (value[0].id === 'deactivated' && !row.original[key]);
                }
              } else if (Array.isArray(row.original[key])) {
                valid = !!value.find((val) =>
                  row.original[key].includes(val.id),
                );
              } else {
                valid = !!value.find((val) => val.id === row.original[key]);
              }
            } else if (key === 'search') {
              // For Search filters

              const fieldsToSearch = filterConfigs?.find(
                (item) => item.id === key,
              )?.fieldsToSearch;

              if (Array.isArray(fieldsToSearch)) {
                let isValid = false;

                fieldsToSearch.forEach((field) => {
                  if (!isValid) {
                    if (typeof field === 'string') {
                      if (
                        typeof row.original[field] === 'string' ||
                        typeof row.original[field] === 'number'
                      ) {
                        // Check for normal values
                        isValid = row.original[field]
                          ?.toString()
                          ?.match(getGlobalSearch(value));

                        // Check for converted values
                        const column = tableProps?.columns?.filter(
                          (item) => item?.id === field,
                        );

                        if (
                          column?.length &&
                          typeof column?.[0]?.cell?.converter === 'function'
                        ) {
                          isValid =
                            isValid ||
                            column[0].cell
                              .converter(row.original[field], row.original)
                              ?.toString()
                              ?.match(getGlobalSearch(value));
                        }
                      } else if (Array.isArray(row.original[field])) {
                        isValid = row.original[field].reduce((_value, item) => {
                          const subValid = item
                            ?.toString()
                            ?.match(getGlobalSearch(value));

                          return subValid || _value;
                        }, false);
                      }
                    } else if (typeof field === 'function') {
                      isValid = field({}, row.original)
                        ?.toString()
                        ?.match(getGlobalSearch(value));
                    }
                  }
                });

                valid = isValid;
              }
            } else if (!Number.isNaN(+value.from) || !Number.isNaN(+value.to)) {
              let validTo = true;
              let validFrom = true;

              if (value.from) {
                validFrom = row.original[key] >= value.from;
              }

              if (value.to) {
                validTo = row.original[key] <= value.to;
              }

              valid = validFrom && validTo;

              // Date range
            } else if (value.from || value.to) {
              let validTo = true;
              let validFrom = true;
              const dateWithoutTime = row.original[key]?.split('T')?.[0];

              if (value.from) {
                validFrom = new Date(dateWithoutTime) >= new Date(value.from);
              }

              if (value.to) {
                validTo = new Date(dateWithoutTime) <= new Date(value.to);
              }

              valid = validFrom && validTo;
            } else if (typeof row.original[key] === 'number') {
              valid = row.original[key] === +value;
            } else {
              valid = row.original[key] === value;
            }
          }
        });

        return valid;
      }),
    [],
  );
  const useControlledState = (state) =>
    useMemo(
      () => ({
        ...state,
        grid,
        selectedRowIds,
      }),
      [selectedRowIds, grid, state],
    );

  const prepareColumns = (columns) =>
    columns.map((col) => {
      const newCol = { ...col };

      /* eslint-disable react/no-unstable-nested-components */
      if (newCol.accessor === 'selection') {
        newCol.Header = function Header({ getToggleAllRowsSelectedProps }) {
          const handleChange = (e) => {
            e.stopPropagation();
            let rowsToChange = [];
            const selectedIds = {};
            const listOfUnselected = [];

            tableProps.rows.forEach((item) => {
              if (selectAll) {
                if (item.isSelected) {
                  listOfUnselected.push({ ...item.original });
                }

                rowsToChange = 'all';
              } else {
                let selectAllowed = true;

                if (typeof selectConfigs?.selectCondition === 'function') {
                  selectAllowed = selectConfigs?.selectCondition(
                    item.original,
                    true, // if needs to select all
                    item === tableProps.rows[tableProps.rows.length - 1], // if the last item
                  );
                }

                if (selectAllowed) {
                  selectedIds[item.id] = true;
                  rowsToChange.push({ ...item.original });
                }
              }
            });

            if (selectAll) {
              setSelectedRowIds({});
            } else {
              setSelectedRowIds(selectedIds);
            }
            setSelectAll(!selectAll);

            tableProps.handleAction('rowSelect', {
              tableId,
              listOfUnselected,
              item: rowsToChange,
              selected: !selectAll,
            });
          };

          return (
            <div>
              <input
                type="checkbox"
                {...getToggleAllRowsSelectedProps()}
                checked={selectAll}
                onChange={handleChange}
              />
            </div>
          );
        };
      }

      if (newCol.columns) {
        newCol.columns = prepareColumns(newCol.columns);
      }

      return newCol;
    });
  const columns = useMemo(
    () => prepareColumns(props.columns),
    [
      JSON.stringify(props.columns),
      selectAll,
      JSON.stringify(serverTableState?.hiddenColumns),
    ],
  );
  const initialHiddenColumns = columns
    .filter((item) => item.hidden)
    .map((item) => item.accessor);

  const resetSettings = () => {
    handleAction('tableStateChange', {});
    updateTableSettings({}, true);
  };

  tableProps = useTable(
    {
      columns,
      defaultColumn,
      data: memoData,
      useControlledState,
      globalFilter: globalFilterFunc,
      initialState: {
        grid: PAGE_VIEW[0],
        pageSize: PAGE_SIZES[PAGE_SIZES.length - 1].id,
        hiddenColumns: initialHiddenColumns,
        globalFilter: {},
        ...initialState,
        ...serverTableState,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
  );
  tableProps.handleAction = handleAction;
  const { getTableProps, selectedFlatRows } = tableProps;

  const withTotal = !!props.columns.find(
    (col) =>
      col.withTotal ||
      col.predefinedTotal ||
      (selectedFlatRows?.length && col.withSelectedRowsTotal),
  );

  const getRef = (ref) => {
    refs.push(ref);
  };
  const rowWidth = tableProps.visibleColumns.reduce(
    (a, currentValue) => a + currentValue.width,
    0,
  );
  const screenWidth = useCurrentWidth();
  const defaultRowHeight = useMemo(
    () => calculateRowHeight(screenWidth, isModal, grid),
    [screenWidth, grid],
  );

  const responsiveRowWidth = useMemo(
    () =>
      grid.id === 'sm'
        ? tableRef?.current?.clientWidth
        : Math.max(rowWidth, tableRef?.current?.clientWidth),
    [screenWidth, rowWidth, tableRef?.current?.clientWidth, grid.id],
  );

  const calculateCellWidth = (cellWidth) =>
    responsiveCellWidth({ responsiveRowWidth, rowWidth, cellWidth, grid });

  const handleTableScroll = (e) => {
    refs.forEach((ref) => {
      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.scrollLeft = e.target.scrollLeft;
      }
    });
  };

  const getLoading = useCallback(
    () =>
      isModal ? (
        <SpinnerHolder>
          <Spinner />
        </SpinnerHolder>
      ) : (
        <SkeletonTheme color="#F0EEEE" highlightColor="#d6d6d6">
          {Array.from({ length: PAGE_SIZES[0].id }, (e, i) => i).map((item) => (
            <Skeleton
              key={`${item}-${tableId}-skeleton`}
              width={responsiveRowWidth}
              style={{ marginBottom: '1px' }}
              height={35}
            />
          ))}
        </SkeletonTheme>
      ),
    [isModal, tableId, responsiveRowWidth],
  );
  const setTableHeightFunc = () => {
    let newTableHeight =
      window.innerHeight -
      (tableRef?.current?.getBoundingClientRect().y || 0) -
      60;

    if (height) {
      newTableHeight = height;
    }

    if (
      newTableHeight >
        tableProps.globalFilteredRows.length * defaultRowHeight ||
      isModal
    ) {
      newTableHeight = tableProps.globalFilteredRows.length * defaultRowHeight;
    }

    if (height && isStaticTableHeight) {
      newTableHeight = height;
    }

    if (newTableHeight !== tableHeight) {
      setTableHeight(newTableHeight);
    }
  };

  const manualSelectFunc = (selectedItems, field) => {
    const ids = {};

    if (Array.isArray(tableProps.page) && Array.isArray(selectedItems)) {
      tableProps.page.forEach((row) => {
        const { original } = row;
        const selected = selectedItems.includes(original[field]);

        if (selected) {
          ids[row.id] = true;
        }
      });

      setSelectedRowIds(ids);
    }
  };

  useEffect(() => {
    if (typeof getManualSelectFunc === 'function') {
      getManualSelectFunc(manualSelectFunc);
    }
  }, []);

  useEffect(() => {
    tableRef?.current?.addEventListener('scroll', handleTableScroll);
    const resizeObserver = new ResizeObserver(setTableHeightFunc);

    resizeObserver.observe(wrapperRef?.current);

    return () => {
      resizeObserver.disconnect();
      tableRef?.current?.removeEventListener('scroll', handleTableScroll);
    };
  }, [defaultRowHeight]);
  useEffect(() => {
    handleAction('tableStateChange', tableProps.state);
    if (typeof updateTableSettings === 'function') {
      updateTableSettings(tableProps.state);
    }
  }, [JSON.stringify(tableProps.state)]);
  useEffect(() => {
    setTableHeightFunc();
  }, [height, tableProps?.globalFilteredRows?.length, defaultRowHeight]);
  useEffect(() => {
    const { grid: gridFromServer } = serverTableState;
    if (gridFromServer?.id && gridFromServer.id !== grid.id) {
      const gridToSet = PAGE_VIEW.filter(
        (item) => item.id === gridFromServer.id,
      );

      if (gridToSet.length) setGrid(gridToSet[0]);
    }
  }, [serverTableState.grid?.id]);

  useEffect(() => {
    const currentDataLength = data.length;
    const prevDataLength = prevDataLengthRef.current;

    // Update the ref with the current length for the next render cycle
    prevDataLengthRef.current = currentDataLength;

    // Check conditions for clearing selectedRowIds
    if (
      !_isEmpty(selectedRowIds) &&
      (!dontClearSelection ||
        (dontClearSelection &&
          prevDataLength !== undefined &&
          currentDataLength !== prevDataLength))
    ) {
      setSelectedRowIds({});
      if (typeof handleAction === 'function') {
        handleAction('rowSelect', {
          tableId,
          item: 'all',
          selected: false,
        });
      }
    }
  }, [JSON.stringify(data)]);

  const toggleShowWarningAlert = (metaData) => {
    setDeleteItemCache(metaData);
    setShowWarningAlert(!showWarningAlert);
  };

  const onDeleteConfirm = () => {
    if (deleteItemCache?.row) {
      handleAction('delete', deleteItemCache.row, deleteItemCache.tableId);
    }

    setShowWarningAlert(false);
  };

  return (
    <div ref={wrapperRef} data-testid={`table-container-${tableId}`}>
      {!reportMode && showWarningAlert && (
        <AnimateAlert
          show
          showCancel
          type="warning"
          title="Warning"
          onCancel={toggleShowWarningAlert}
          onConfirm={onDeleteConfirm}
          confirmBtnText={t`Yes`}
          cancelBtnBsStyle="info"
          confirmBtnBsStyle="danger"
        >
          <Trans>Please confirm that you want to delete this item!</Trans>
        </AnimateAlert>
      )}
      {(!reportMode || withTitle) && !!tableTitle && (
        <Heading alignment="center" level={3}>
          {tableTitle}
        </Heading>
      )}
      {!reportMode && !hideSelection && (
        <CustomTableSelect
          {...{
            tableId,
            tableProps,
            handleAction,
            selectConfigs,
            actionSubmitting,
            setSelectedRowIds,
          }}
        />
      )}
      {!reportMode && (
        <CustomTableTopContent
          {...{
            grid,
            tableId,
            isModal,
            setGrid,
            columns,
            fixedSize,
            filterOpen,
            tableProps,
            hideFilters,
            handleAction,
            selectConfigs,
            filterConfigs,
            headerConfigs,
            resetSettings,
            toggleFilters,
            actionSubmitting,
            showResetSettings,
          }}
        />
      )}
      {!reportMode && !hideFilters && (
        <CustomTableFilters
          {...{
            tableId,
            reportMode,
            tableProps,
            filterOpen,
            filterConfigs,
            toggleFilters,
          }}
        />
      )}
      {!loading && !tableProps?.flatRows?.length && isModal ? (
        <NothingToDisplay isModal={isModal} />
      ) : (
        <>
          <CustomTableHeader
            {...{
              grid,
              getRef,
              isModal,
              tableId,
              reportMode,
              tableProps,
              calculateCellWidth,
              responsiveRowWidth,
            }}
          />
          {!reportMode && <LegendList collection={legends} />}
          <Styles ref={tableRef} grid={grid?.id} horizontal isModal={isModal}>
            <div {...getTableProps()} className="table">
              {loading ? (
                getLoading()
              ) : (
                <CustomTableBody
                  {...{
                    grid,
                    tableId,
                    isModal,
                    groupId,
                    loadingId,
                    tableProps,
                    reportMode,
                    tableHeight,
                    getRowColor,
                    enableGroups,
                    withRowClick,
                    handleAction,
                    selectConfigs,
                    withCellClick,
                    selectedRowIds,
                    actionSubmitting,
                    dynamicRowHeight,
                    defaultRowHeight,
                    setSelectedRowIds,
                    calculateCellWidth,
                    responsiveRowWidth,
                    toggleShowWarningAlert,
                    minHeight: isModal ? defaultRowHeight : minHeight,
                  }}
                />
              )}
            </div>
          </Styles>
          {!reportMode && withTotal && (
            <CustomTableTotal
              {...{
                grid,
                getRef,
                tableId,
                isModal,
                totalName,
                reportMode,
                tableProps,
                handleAction,
                predefinedTotal,
                totalColumnIndex,
                withoutMainTotal,
                calculateCellWidth,
                responsiveRowWidth,
                additionalTotalRows,
                totalTitleClassName,
              }}
            />
          )}
        </>
      )}
      {typeof getSumRow === 'function' &&
        getSumRow(tableProps.globalFilteredRows)}
    </div>
  );
}

export default CustomTable;

CustomTable.propTypes = {
  height: PropTypes.number,
  minHeight: PropTypes.number,
  totalColumnIndex: PropTypes.number,

  loading: PropTypes.bool,
  isModal: PropTypes.bool,
  withTitle: PropTypes.bool,
  reportMode: PropTypes.bool,
  hideFilters: PropTypes.bool,
  withRowClick: PropTypes.bool,
  enableGroups: PropTypes.bool,
  withCellClick: PropTypes.bool,
  hideSelection: PropTypes.bool,
  withDragSelect: PropTypes.bool,
  dynamicRowHeight: PropTypes.bool,
  withoutMainTotal: PropTypes.bool,
  isStaticTableHeight: PropTypes.bool,

  tableId: PropTypes.string,
  groupId: PropTypes.string,
  totalName: PropTypes.string,
  fixedSize: PropTypes.string,
  loadingId: PropTypes.string,
  tableTitle: PropTypes.string,
  totalTitleClassName: PropTypes.string,

  getSumRow: PropTypes.func,
  getRowColor: PropTypes.func,
  handleAction: PropTypes.func,
  updateTableSettings: PropTypes.func,
  getManualSelectFunc: PropTypes.func,

  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  legends: PropTypes.arrayOf(PropTypes.shape({})),
  filterConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  additionalTotalRows: PropTypes.arrayOf(PropTypes.shape({})),

  initialState: PropTypes.shape({}),
  headerConfigs: PropTypes.shape({}),
  selectConfigs: PropTypes.shape({}),
  tableSettings: PropTypes.shape({}),
  predefinedTotal: PropTypes.shape({}),
  actionSubmitting: PropTypes.shape({}),
  dontClearSelection: PropTypes.bool,
};

CustomTable.defaultProps = {
  data: [],
  height: 0,
  loading: false,
  tableId: '',
  groupId: undefined,
  legends: [],
  columns: [],
  isModal: false,
  withTitle: false,
  minHeight: 300,
  totalName: undefined,
  getSumRow: undefined,
  fixedSize: undefined,
  loadingId: 'accountId',
  tableTitle: '',
  reportMode: false,
  hideFilters: false,
  withRowClick: false,
  enableGroups: false,
  initialState: {},
  hideSelection: false,
  withCellClick: false,
  totalColumnIndex: 0,
  withoutMainTotal: false,
  isStaticTableHeight: false,
  tableSettings: {},
  filterConfigs: [],
  selectConfigs: {},
  headerConfigs: {},
  withDragSelect: false,
  predefinedTotal: {},
  dynamicRowHeight: undefined,
  actionSubmitting: {},
  totalTitleClassName: undefined,
  getManualSelectFunc: undefined,
  additionalTotalRows: undefined,
  getRowColor: () => '',
  handleAction: () => {},
  updateTableSettings: () => {},
  dontClearSelection: false,
};

const Styles = styled(CustomScrollbar)`
  width: 100%;
  overflow: auto;
  position: relative;

  input {
    padding: 0 10px;
  }

  .table {
    display: inline-block;
    margin-bottom: 0;
    border-spacing: 0;
    vertical-align: middle;

    .sort-icon-holder {
      font-size: 12px;
      margin-left: 5px;
    }

    i:hover {
      color: #ff8f5e;
    }

    .flex-center {
      text-align: center;
      justify-content: center;
    }

    .flex-right {
      text-align: right;
      justify-content: flex-end;
    }

    .absolute-right {
      right: 15px;
      position: absolute;
    }

    .tr.body-row {
      :hover {
        background-color: #d6d6d6;
      }
    }

    .tr.body-row.clickable {
      cursor: pointer;
    }

    .tr.withBG {
      background-color: #f5f5f5;
    }

    .td {
      margin: 0;
      padding: 5px 15px;
      display: flex !important;
      position: relative;
      flex-wrap: wrap;
      font-size: 14px;
      align-items: center;
      border-bottom: 1px solid #dedede;

      ${({ isModal, grid }) =>
        !isModal &&
        grid === 'sm' &&
        `
          @media (max-width: ${LARGE_SCREENS.max}px) {
            padding: 0 5px;
            overflow-x: clip;
            overflow-y: visible;
            flex-wrap: nowrap;
            font-size: 12px;
          }
      `}

      ${({ isModal, grid }) =>
        !isModal &&
        grid !== 'sm' &&
        `
          @media (min-width: ${TWO_K.min}px) {
            font-size: 14px;
            padding: 5px 15px;
          }

          @media (min-width: ${FOUR_K.min}px) {
            padding: 5px 15px;
            font-size: 15px;
          }
      `}

      :last-child {
        padding: ${(props) =>
          props.grid === 'sm' ? '0 15px 0 5px' : '5px 15px'};
        border-right: 0;
      }

      input[type='checkbox'] {
        height: 15px;
        margin: 0;
        padding: 0;
      }

      button {
        padding: 0 5px !important;

        @media (max-width: ${LARGE_SCREENS.max}px) {
          padding: ${({ grid }) =>
            grid === 'sm' ? '0 3px' : '0 5px'} !important;
        }
      }

      button.additional-padding {
        padding: 1px 10px !important;
      }

      button.action {
        margin-left: 5px;
        margin-right: -10px;

        @media (max-width: ${LARGE_SCREENS.max}px) {
          margin-top: ${({ grid }) => (grid === 'sm' ? '-4px' : '0')};
          margin-left: ${({ grid }) => (grid === 'sm' ? '0' : '5px')};
          margin-right: ${({ grid }) => (grid === 'sm' ? '-6px' : '-10px')};
        }
      }
    }

    .td.disableClick {
      cursor: default;
    }

    .td.strong {
      font-weight: bold;
    }

    .td.clickable {
      cursor: pointer;
    }

    .td.hideHorizontalGrid {
      border-bottom: 0;
    }

    .td.hasFixedIconRight {
      padding-right: 35px;
    }
  }
`;

const SpinnerHolder = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
`;
