import React from 'react';
import { t } from '@lingui/macro';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings } from 'components';

import { vatSettingsUnmappedColumns } from 'services/tableshapes';

function VATSettingsUnmappedTable(props) {
  const { unmappedData: data, loading, handleAction, actionSubmitting } = props;

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-VATSettingsUnmapped">
      <CardPDFTitle title={t`Unmapped Items`} />
      <div className="container-fluid" style={{ padding: 0 }}>
        <CustomTableWithAutoSettings
          {...{
            data,
            loading,
            handleAction,
            minHeight: (data?.length || 0) * 20,
            actionSubmitting,
            hideFilters: true,
            columns: vatSettingsUnmappedColumns(),
            tableId: 'VATSettingsUnmapped',
            loadingId: 'vatCode',
            headerConfigs: {
              exportFileName: t`Unmapped Items`,
            },
          }}
        />
      </div>
    </Card>
  );
}

export default VATSettingsUnmappedTable;
