import React from 'react';
import { t } from '@lingui/macro';
import { MdInsertComment } from '@react-icons/all-files/md/MdInsertComment';
import { FaCloudDownloadAlt } from '@react-icons/all-files/fa/FaCloudDownloadAlt'; // eslint-disable-line max-len

const style = { marginBottom: -2, fontSize: 14 };

export const iconGroup = (roleType, additionalData = {}) => ({
  colValue: 6,
  separable: true,
  separableId: 3,
  buttonShapes: {
    firstGroup: [
      {
        id: 2,
        isEnabled: true,
        size: 'small',
        fill: true,
        magnify: true,
        kind: 'primary',
        wide: false,
        style: { marginLeft: '8px' },
        icon: <MdInsertComment style={style} />,
        label: t`Dialogue`,
      },
      {
        id: 3,
        isEnabled: !roleType.isReadOnly,
        size: 'small',
        fill: true,
        magnify: true,
        kind: 'primary',
        wide: false,
        style: { marginLeft: '8px' },
        icon: <FaCloudDownloadAlt style={style} />,
        label: t`Upload Files`,
        additionalData: additionalData?.uploadFiles,
      },
    ],
  },
});
export default { iconGroup };
