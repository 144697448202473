import { t } from '@lingui/macro';

export const vatOverviewFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['vatCode', 'vatDescription', 'ratePecentage'],
  },
];

export default { vatOverviewFilters };
