import { t } from '@lingui/macro';

export const columns = (accumulated) => [
  ...(accumulated
    ? [
        {
          width: 60,
          Header: t`Period`,
          accessor: 'period',
          className: '',
        },
      ]
    : []),
  {
    width: 150,
    Header: t`Income`,
    accessor: 'type',
    className: '',
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 60,
    Header: t`Count`,
    accessor: 'count',
    className: 'flex-right',
  },
  {
    width: 80,
    Header: t`With Social Security`,
    accessor: 'withSocSec',
    className: 'flex-right',
    cell: {
      converter: (value) => (value ? t`YES` : t`NO`),
    },
  },
  {
    width: 80,
    Header: t`Withholding Tax`,
    accessor: 'withWithholdTax',
    className: 'flex-right',
    cell: {
      converter: (value) => (value ? t`YES` : t`NO`),
    },
  },
  {
    width: 90,
    Header: t`Percentage`,
    accessor: 'percentage',
    className: '',
  },
  {
    width: 150,
    Header: t`Calculated Social Security`,
    sortType: 'basic',
    accessor: 'amountSocSec',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
