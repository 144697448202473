import { createGlobalStyle } from 'styled-components/macro';

// TODO: finalize CSS resets & general light/dark mode configs
const GlobalStyle = createGlobalStyle`
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
