import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { ButtonIcon } from 'components';

export const columns = (isReadOnly) => [
  {
    width: 100,
    Header: t`ID`,
    accessor: 'companySid',
    sortType: 'basic',
    className: 'flex-center',
  },
  {
    width: 250,
    Header: t`Name`,
    accessor: 'name',
    className: '',
  },
  {
    width: 250,
    Header: t`Registration Number`,
    accessor: 'organisationNumber',
    className: '',
    withTooltip: true,
  },
  {
    width: 400,
    Header: t`Date ranges`,
    accessor: 'dateRanges',
    className: '',
    disableSortBy: true,
    cell: {
      converter: (value) =>
        Array.isArray(value) && !!value?.length
          ? value.map((item) => `${item.fromDate} - ${item.toDate}`).join(', ')
          : t`No added date ranges`,
      icon: () => <ButtonIcon as={GoPencil} type="link" />,
      buttonClassName: 'absolute-right',
      action: 'editRange',
    },
  },
  ...(isReadOnly
    ? []
    : [
        {
          width: 100,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ],
          },
        },
      ]),
];

export default { columns };
