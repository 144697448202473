import React from 'react';
import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { ButtonGroup, Modal } from 'react-bootstrap';

import { Button, Heading, ButtonIcon } from 'components';

function PreviewGroupUsersModal(props) {
  const { item = { users: [] }, closeModal } = props;

  const userSort = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Modal.Header>
        <Heading level={3}>
          {item.name}
          {' - '}
          <Trans>Users</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {[...item.users].sort(userSort).map((u) => (
          <ItemHolder key={u.uuid}>{u.name}</ItemHolder>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default PreviewGroupUsersModal;

const ItemHolder = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;
