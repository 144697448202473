import { t } from '@lingui/macro';

import {
  assetRegisterColumns,
  assetReconciliationOverviewColumns,
} from 'services/tableshapes';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import useReportGenerator from './generator/useReportGenerator';

const periodNames = {
  monthly: t`Period`,
  biMonthly: t`Term`,
  yearly: t`Y`,
  tertiary: t`T`,
  quarterly: t`Q`,
};

const getPeriodName = (periodType, period, year) => {
  const formattedPeriod = `${defineFrequencyPeriodTypes(
    periodType,
    period,
  )}, ${year}`;

  const spacer = ['monthly', 'biMonthly'].includes(periodType) ? ' ' : '';

  return `${periodNames[periodType]}${spacer}${formattedPeriod}`;
};

export const getAssetsReportSettings = ({
  data,
  loading,
  company,
  categories,
  orgNumber,
  accountingYear,
  currentPeriodType,
  loadingCategories,
  accountingPeriod,
}) => {
  const period = getPeriodName(
    currentPeriodType,
    accountingPeriod,
    accountingYear,
  );
  const _loading = loading || loadingCategories;

  const shapes = {
    overview: assetReconciliationOverviewColumns(),
    register: assetRegisterColumns(),
  };

  return {
    loading: _loading,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      reportType: 'ASSETS',
    },
    folderName: t`assets`,
    fileName: t`${company} (${orgNumber}) Assets - ${accountingYear}`,
    data: [
      [
        {
          period,
          withoutStatus: true,
          rows: categories,
          title: t`Reconciliation Overview`,
          tableShape: shapes.overview,
        },
      ],
      [
        {
          period,
          withoutStatus: true,
          rows: data,
          title: t({ id: 'register.noun', message: 'Register' }),
          tableShape: shapes.register,
        },
      ],
    ],
  };
};

export const useAssetsReportGenerator = (props) =>
  useReportGenerator(getAssetsReportSettings(props));

export default useAssetsReportGenerator;
