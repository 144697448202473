import React, { useEffect } from 'react';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs } from 'components';
import AssetRegister from 'containers/AssetRegister';
import AssetReconciliationOverview from 'containers/AssetReconciliationOverview'; // eslint-disable-line

import { toAssets } from 'services/routehelpers';
import { useAssetsReportGenerator } from 'services/hooks/reports';
import { resourceListReadRequest } from 'store/actions';
import { fromCompany, fromResource } from 'store/selectors';
import { getAssetReconciliationApi, getAssetsApi } from 'services/apihelpers';

/**
 * Assets Reconciliation Page Component
 */

function AssetsReconciliationPage(props) {
  const { location, handleTabSelect, roleType } = props;
  const { tabEntry = 'reconciliation-overview' } = queryString.parse(
    location.search,
  );
  const dispatch = useDispatch();
  const { companyId, accountingYear, accountingPeriod } = useParams();
  const onTabSelect = (key) => handleTabSelect(key, (data) => toAssets(data));

  const company = useSelector(fromCompany.getCompany);
  const data = useSelector((state) =>
    fromResource.getList(state, getAssetsApi),
  );
  const categories = useSelector((state) =>
    fromResource.getList(state, getAssetReconciliationApi),
  );

  const loading = useSelector((state) =>
    pending(state, `${getAssetsApi}ListRead`),
  );
  const loadingCategories = useSelector((state) =>
    pending(state, `${getAssetReconciliationApi}ListRead`),
  );

  const {
    currentPeriodType,
    currentCompanyName,
    currentCompanyRegistrationNumber,
  } = company;

  const { generatePDF, disabled, pdfGenerating, Preview } =
    useAssetsReportGenerator({
      data,
      loading,
      categories,
      accountingYear,
      accountingPeriod,
      loadingCategories,
      currentPeriodType,
      company: currentCompanyName,
      orgNumber: currentCompanyRegistrationNumber,
    });

  const fetchData = () => {
    const query = {
      companyId,
      year: accountingYear,
      period: accountingPeriod,
    };

    dispatch(resourceListReadRequest(getAssetsApi, query));
    dispatch(resourceListReadRequest(getAssetReconciliationApi, query));
  };

  const tabs = [
    {
      title: t`Reconciliation Overview`,
      eventKey: 'reconciliation-overview',
      component: (
        <AssetReconciliationOverview
          {...{
            Preview,
            roleType,
            fetchData,
            disabled,
            generatePDF,
            pdfGenerating,
            data: categories,
            loading: loadingCategories,
          }}
        />
      ),
    },
    {
      title: t({ id: 'register.noun', message: 'Register' }),
      eventKey: 'register',
      component: (
        <AssetRegister
          {...{
            data,
            Preview,
            loading,
            roleType,
            fetchData,
            categories,
            disabled,
            generatePDF,
            pdfGenerating,
            loadingCategories,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [companyId, accountingYear, accountingPeriod]);

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default AssetsReconciliationPage;
