import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import checkBoxAnim from 'assets/animations/checkbox.json';

function MultiSelect(props) {
  const {
    name,
    kind,
    wide,
    fill,
    icon,
    size,
    block,
    title,
    simple,
    rotate,
    options,
    checked,
    magnify,
    onSelect,
    disabled,
    moveLeft,
    className,
    moveRight,
    wrapperClassName,
  } = props;
  const [open, setOpen] = useState(false);
  const [applied, setApplied] = useState(false);
  const [selectedValues, setSelectedValues] = useState(checked);
  const changed = JSON.stringify(selectedValues) !== JSON.stringify(checked);

  useEffect(() => {
    if (!open && !applied && changed) {
      setSelectedValues(checked);
    }
  }, [open, applied, changed]);

  const handleToggle = (state) => {
    setOpen(state);
  };
  const handleSelect = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    setApplied(false);
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  const handleApply = () => {
    setApplied(true);
    onSelect({ target: { name, value: selectedValues } });
    document.getElementById(name).click();
  };

  const classes = classNames(
    '',
    {
      'btn-wd': wide,
      'btn-fill': fill,
      'btn-icon': icon,
      'btn-block': block,
      'btn-simple': simple,
      'btn-rotate': rotate,
      'btn-magnify': magnify,
      'btn-move-left': moveLeft,
      'btn-move-right': moveRight,
    },
    className,
  );
  const applyTop = options.length > 9 ? 369 : options.length * 41;

  return (
    <DropDownWrapper className={wrapperClassName}>
      <DropdownButton
        id={name}
        title={title}
        block={block}
        bsSize={size}
        bsStyle={kind}
        onToggle={handleToggle}
        className={classes}
        disabled={disabled}
      >
        {options &&
          options.map((item) => {
            const selected = selectedValues.includes(item.value || item.id);
            return (
              <MenuItem
                disabled
                key={item.value || item.id}
                onClick={(e) => handleSelect(e, item.value || item.id)}
                eventKey={item.value || item.id}
              >
                <ItemHolder>
                  {item.label}
                  <div>
                    <Lottie
                      speed={3}
                      width="20px"
                      height="20px"
                      direction={selected ? 1 : -1}
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: checkBoxAnim,
                      }}
                    />
                  </div>
                </ItemHolder>
              </MenuItem>
            );
          })}
      </DropdownButton>
      <ApplyButton
        top={applyTop + 46}
        height={changed && open ? 41 : 0}
        onClick={handleApply}
      >
        <Trans>Apply</Trans>
      </ApplyButton>
    </DropDownWrapper>
  );
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  kind: PropTypes.string,
  wide: PropTypes.bool,
  fill: PropTypes.bool,
  icon: PropTypes.bool,
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  block: PropTypes.bool,
  title: PropTypes.string.isRequired,
  simple: PropTypes.bool,
  rotate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checked: PropTypes.arrayOf(PropTypes.shape({})),
  magnify: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  moveLeft: PropTypes.bool,
  className: PropTypes.string,
  moveRight: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

MultiSelect.defaultProps = {
  kind: undefined,
  wide: false,
  fill: false,
  icon: false,
  size: 'small',
  block: false,
  simple: false,
  disabled: false,
  rotate: false,
  checked: [],
  magnify: false,
  moveLeft: false,
  className: undefined,
  moveRight: false,
  wrapperClassName: undefined,
};

export default MultiSelect;

const ItemHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropDownWrapper = styled.div`
  position: relative;
`;

const ApplyButton = styled.div`
  top: ${(props) => props.top}px;
  left: 0;
  color: white;
  cursor: pointer;
  height: ${(props) => props.height}px;
  z-index: 1;
  display: flex;
  padding: 0 60px;
  overflow: hidden;
  position: absolute;
  transition: all ease 0.5s;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: #484541;
`;
