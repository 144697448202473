import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 60,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 160,
    Header: t`Bank Reference ID`,
    accessor: 'bankAccountId',
    className: 'flex-left',
  },
  {
    width: 250,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 160,
    Header: t`Bank Date`,
    accessor: 'postedDate',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: 140,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
