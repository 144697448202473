import React from 'react';
import { Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, ButtonGroup } from 'react-bootstrap';

import { Heading, Button, Spinner, ButtonIcon } from 'components';

function AltinnExpireModal(props) {
  const { show, loading, handleStop, handleClose, altinnExpireIn } = props;

  return (
    <Modal show={show} onEscapeKeyDown={handleClose}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>{`Altinn will expire in ${altinnExpireIn} minutes`}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Trans>Would you like to stop your Altinn session?</Trans>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button fill wide kind="info" onClick={handleStop}>
            {loading ? <Spinner type="white" size="md" /> : <Trans>Stop</Trans>}
          </Button>
          <Button fill magnify kind="danger" onClick={handleClose}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default AltinnExpireModal;
