import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { PageTemplate } from 'components';
import LoginNavbar from 'containers/LoginNavbar';
import ErrorBoundary from 'containers/ErrorBoundary';
import styled from 'styled-components/macro';

const md = {
  'register-page': 6,
  sanity: 8,
  other: 4,
};

const mdOffset = {
  'register-page': 3,
  sanity: 2,
  other: 4,
};

const StyledDiv = styled.div`
  margin-top: ${(props) => (props.addMargin ? '15%' : '0')};
  ${(props) =>
    props.fullPage &&
    `
    height: 100vh;
    display: flex;
    align-items: center;
  `};
`;

/**
 * Basic Template component - Pre login, full page
 */
function BasicTemplate(props) {
  const { route, noLogin, children, pageType } = props;
  const additionalProps = {};

  if (route?.helmetTitle) {
    additionalProps.helmetTitle = route.helmetTitle;
  }

  return (
    <PageTemplate
      fullPage
      navbar={<LoginNavbar noLogin={noLogin} />}
      pageType={pageType}
      {...additionalProps}
      {...props}
    >
      <div className="container">
        <Row>
          <StyledDiv
            addMargin={pageType === 'register-page'}
            fullPage={pageType === 'sanity'}
          >
            <ErrorBoundary kind="basic">
              <Col
                md={md[pageType] || md.other}
                sm={6}
                mdOffset={mdOffset[pageType] || mdOffset.other}
                smOffset={3}
              >
                {children}
              </Col>
            </ErrorBoundary>
          </StyledDiv>
        </Row>
      </div>
    </PageTemplate>
  );
}

BasicTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  pageType: PropTypes.string,
};

BasicTemplate.defaultProps = {
  pageType: 'login-page',
};

export default BasicTemplate;
