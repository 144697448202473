import React from 'react';
import styled from 'styled-components/macro';

export function Beta(props) {
  return <BetaHolder>{props.text}</BetaHolder>;
}

export default Beta;

const BetaHolder = styled.div`
  position: absolute;
  z-index: 100;
  top: -13px;
  right: 4px;
  text-transform: uppercase;
  background-color: #eb9428;
  padding: 1px 7px;
  display: inline-block;
  margin-left: 20px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
`;
