import React, { Component } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import ConfigureMFAModal from 'containers/ConfigureMFAModal';
import { UserSettings } from 'components';

import { fromAuth, fromResource } from 'store/selectors';
import {
  resourceUpdateRequest,
  resourceListReadRequest,
  updateCurrentUserSuccess,
} from 'store/actions';
import {
  getLanguageListApi,
  updateCurentUserApi,
  getFeatureUnleashApi,
} from 'services/apihelpers';

class UserSettingsContainer extends Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({}),
    }),
    getAccountingSystemList: PropTypes.func.isRequired,
    getCountryList: PropTypes.func.isRequired,
    postCompanySettings: PropTypes.func.isRequired,
    getLanguages: PropTypes.func.isRequired,
    initializeForm: PropTypes.func.isRequired,
    company: PropTypes.shape({}).isRequired,
    accountingSystemList: PropTypes.shape({}).isRequired,
    onCompanyInfoUpdate: PropTypes.func.isRequired,
    companyDetails: PropTypes.shape({}).isRequired,
    updateCurrentUser: PropTypes.func.isRequired,
    postUserSettings: PropTypes.func.isRequired,
    user: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    location: undefined,
  };

  constructor(props) {
    super(props);

    const setInitialValues = (values) => {
      const formValues = {};

      formValues.name = values.name;
      formValues.entries = values.entries;
      formValues.username = values.username;
      formValues.contactNo = values.contactNo;
      formValues.languageCode = values.languageCode;
      formValues.userPassword = '';
      formValues.confirmPassword = '';
      formValues.newPdf = values.newPdf ?? true;
      formValues.colorMode = values.colorMode ?? 'AUTO';

      return formValues;
    };

    this.defaultValues = setInitialValues(props.user);
  }

  state = {
    modal: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { getLanguages } = this.props;

    try {
      await getLanguages();
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  handleSubmit = async (credentials) => {
    const { postUserSettings, updateCurrentUser } = this.props;
    const query = {
      name: credentials.name,
      newPdf: credentials.newPdf || false,
      entries: credentials.entries,
      colorMode: credentials.colorMode || 'AUTO',
      contactNo: credentials.contactNo,
      languageCode: credentials.languageCode,
    };

    if (credentials.userPassword) {
      query.password = credentials.userPassword;
    }

    try {
      const newUser = await postUserSettings(query);

      updateCurrentUser(newUser);

      toast.success(t`User settings updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  openMFA = () => this.setState({ modal: 'mfa' });

  closeModal = () => this.setState({ modal: '' });

  renderModal = () => {
    let modalToRender = '';

    switch (this.state.modal) {
      case 'mfa': {
        modalToRender = (
          <ConfigureMFAModal type="user" closeModal={this.closeModal} />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  render() {
    return (
      <>
        {this.renderModal()}
        <UserSettings
          openMFA={this.openMFA}
          onSubmit={this.handleSubmit}
          defaultValues={this.defaultValues}
          {...this.props}
          {...this.state}
        />
      </>
    );
  }
}

export default connect(
  (state) => {
    const user = fromAuth.getUser(state);

    return {
      user,
      submitting: pending(state, `${updateCurentUserApi}Create`),
      getLanguageList: fromResource.getList(state, getLanguageListApi),
      showMFA: fromResource.getList(state, `${getFeatureUnleashApi}/mfa`)
        .status,
    };
  },
  (dispatch) => ({
    postUserSettings: (query) =>
      dispatch(
        resourceUpdateRequest(updateCurentUserApi, null, {
          ...query,
        }),
      ),
    getLanguages: () => dispatch(resourceListReadRequest(getLanguageListApi)),
    updateCurrentUser: (response) =>
      dispatch(updateCurrentUserSuccess(response)),
  }),
)(UserSettingsContainer);
