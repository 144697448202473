import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { AssetRegister, GenerateReportButton, Card } from 'components';
import AddEditAssetModal from 'containers/AddEditAssetModal';

import { resourceListReadSuccess } from 'store/resource/actions';
import { resourceDeleteRequest } from 'store/actions';
import { getAssetsApi, deleteAssetApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import CardPDFTitle from './CardPDFTitle';

export function AssetReconciliationOverviewContainer(props) {
  const {
    data,
    loading,
    Preview,
    disabled,
    roleType,
    fetchData,
    categories,
    generatePDF,
    pdfGenerating,
    loadingCategories,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const getSearch = () => {
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      tabEntry: search.tabEntry || 'register',
      reportMode: search.reportMode || false,
      assetCategory: search.assetCategory
        ? JSON.parse(search.assetCategory)
        : null,
    };
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const deleteAsset = async (row) => {
    _setActionSubmitting(row.assetId, true);

    try {
      await dispatch(
        resourceDeleteRequest(
          `${deleteAssetApi}?${queryString.stringify({
            assetId: row.assetId,
          })}`,
        ),
      );

      const collection = [...data];
      const index = collection.findIndex((el) => el.assetId === row.assetId);

      if (index >= 0) {
        collection.splice(index, 1);

        dispatch(resourceListReadSuccess(getAssetsApi, collection));
      }

      _setActionSubmitting(row.assetId, false);
      toast.success(t`Asset deleted successfully`);
    } catch (error) {
      _setActionSubmitting(row.assetId, false);
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const handleAction = async (action, _props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'add': {
          setModalProps({ type: 'add' });
          break;
        }

        case 'edit': {
          setModalProps({ type: 'edit', item: _props });
          break;
        }

        case 'delete': {
          await deleteAsset(_props);

          break;
        }

        default: {
          break;
        }
      }
      if (action !== 'tableStateChange') {
        document.activeElement.blur();
      }
      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };
  const renderModal = () => {
    let content;

    switch (modalProps.type) {
      case 'add':
      case 'edit': {
        content = (
          <AddEditAssetModal item={modalProps.item} closeModal={closeModal} />
        );
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  const search = getSearch();
  const collections = {
    assetCategoryId: {
      options: Array.isArray(categories)
        ? categories.map((el) => ({
            id: el.assetCategoryId,
            label: el.assetCategoryName,
          }))
        : [],
      loading: loadingCategories,
    },
  };

  return (
    <>
      {renderModal()}
      <Card id="pdfToPrint" data-testid="pdfToPrint-assetRegister">
        <CardPDFTitle
          title={
            <Header>
              <Trans>Asset Reconciliation Overview</Trans>
              <ButtonsHolder>
                <GenerateReportButton
                  disabled={disabled}
                  pdfGenerating={pdfGenerating}
                  generatePDF={generatePDF}
                  skipDownload={false}
                  saveReport={false}
                />
                {Preview()}
              </ButtonsHolder>
            </Header>
          }
        />
        <AssetRegister
          {...{
            data,
            search,
            loading,
            roleType,
            collections,
            actionSubmitting,
            title: t`Asset Register`,
            handleAction,
          }}
        />
      </Card>
    </>
  );
}

export default AssetReconciliationOverviewContainer;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
