import { t } from '@lingui/macro';

const getPercentage = (values) => {
  const [value, total] = values;
  let perc = 0;

  if (total === 0 && value) {
    perc = 100;
  } else if (value) {
    perc = Number(((value / total) * 100).toFixed(1));
  }

  if (perc > 100) {
    perc = 100;
  }

  return perc;
};

export const columns = () => [
  {
    title: t`User`,
    width: '22',
    id: 'employee',
    search: true,
  },
  {
    title: t`Role`,
    width: '16',
    id: 'position',
    search: true,
  },
  {
    title: t`Assigned`,
    width: '13',
    align: 'right',
    id: 'assigned',
    withTotal: true,
    cell: {
      totalConverter: (data) => {
        const result = data.reduce((value, item) => value + item.assigned, 0);
        return `${result ? 100 : 0}% (${result})`;
      },
    },
  },
  {
    title: t`Approved`,
    width: '15',
    align: 'right',
    id: 'reconciled',
    withTotal: true,
    cell: {
      totalConverter: (data) => {
        const result = data.reduce(
          (value, item) => [
            value[0] + item.reconciled,
            value[1] + item.assigned,
          ],
          [0, 0],
        );
        const perc = getPercentage(result);

        return `${perc}% (${result[0]})`;
      },
    },
  },
  {
    title: t`Approved by others`,
    width: '15',
    align: 'right',
    id: 'reconciledByOthers',
    withTotal: true,
    cell: {
      totalConverter: (data) => {
        const result = data.reduce(
          (value, item) => [
            value[0] + item.reconciledByOthers,
            value[1] + item.assigned,
          ],
          [0, 0],
        );
        const perc = getPercentage(result);

        return `${perc}% (${result[0]})`;
      },
    },
  },
  {
    title: t`Controlled`,
    width: '15',
    align: 'right',
    id: 'controlled',
    withTotal: true,
    cell: {
      totalConverter: (data) => {
        const result = data.reduce(
          (value, item) => [
            value[0] + item.controlled,
            value[1] + item.assigned,
          ],
          [0, 0],
        );

        const perc = getPercentage(result);

        return `${perc}% (${result[0]})`;
      },
    },
  },
];

export default { columns };
