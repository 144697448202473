/* eslint-disable max-len */
/* Normal routes */

export const HOME_PAGE_PATH = '/';
export const SELECT_COMPANY_PAGE_PATH = `${HOME_PAGE_PATH}select-company`;
export const REGISTER_PAGE_PATH = `${HOME_PAGE_PATH}register`;
export const ACCOUNTFLOW_PAGE_PATH = `${HOME_PAGE_PATH}client/:companyId?/:accountingYear?/:accountingPeriod?`;
export const REDIRECT_PAGE_PATH = `${HOME_PAGE_PATH}redirect`;
export const INVALIDATE_LINK_PAGE_PATH = `${HOME_PAGE_PATH}invalidate-link`;
export const REGISTRATION_VALIDATION_PAGE_PATH = `${HOME_PAGE_PATH}confirm-registration`;
export const TERMS_OF_SERVICE = `${HOME_PAGE_PATH}terms-of-service`;
export const PRIVACY_STATEMENT = `${HOME_PAGE_PATH}privacy-statement`;
export const ERROR_SCREEN_PATH = `${HOME_PAGE_PATH}error`;
export const REDIRECT_TO_PAGE = `${HOME_PAGE_PATH}go`;

/* Balances root routes */

export const AUDIT_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/audit`;
export const FILES_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/files`;
export const REPORTS_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/reports`;
export const SETTINGS_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/settings`;
export const RECONCILIATION_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/reconciliation`;

/* Authenticated routes */

export const DASHBOARD_PAGE_PATH = `${ACCOUNTFLOW_PAGE_PATH}/dashboard`;

/* Account Reconciliation Routes */

export const OPENING_BALANCE_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/opening-balance`;
export const VAT_RECONCILIATION_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/vat`;
export const VAT_RECONCILIATION_CONTROL_PAGE_PATH = `${VAT_RECONCILIATION_PAGE_PATH}/control`;
export const ASSETS_RECONCILIATION_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/assets`;
export const ACCOUNT_RECONCILIATION_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/account`;
export const PAYROLL_RECONCILIATION_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/payroll`;
export const OVERVIEW_RECONCILIATION_PAGE_PATH = `${RECONCILIATION_PAGE_PATH}/overview`;

/* Reports Routes */

export const GENERATE_REPORT_PAGE_PATH = `${REPORTS_PAGE_PATH}/generate-reports`;
export const REPORTS_OVERVIEW_PAGE_PATH = `${REPORTS_PAGE_PATH}/trial-balance`;
export const ACCOUNT_DETAILS_REPORT_PAGE_PATH = `${REPORTS_PAGE_PATH}/accounts-details`;
export const GENERATE_REPORT_VIEWER_PAGE_PATH = `${REPORTS_PAGE_PATH}/printable-reports`;

export const GROUP_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/group-settings`;
export const GROUP_SETTINGS_COMPANY_PAGE_PATH = `${GROUP_SETTINGS_PAGE_PATH}/company`;
export const GROUP_SETTINGS_USER_PAGE_PATH = `${GROUP_SETTINGS_PAGE_PATH}/user`;
export const GENERAL_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/general-settings`;
export const ADMIN_USER_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/user-admin`;
export const ADMIN_COMPANY_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/company-admin`;
export const RECONCILIATION_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/reconciliation-settings`;
export const ADMIN_ORGANISATION_SETTINGS_PAGE_PATH = `${SETTINGS_PAGE_PATH}/organisation-admin`;

/* Redirect routes */

export const VIIA_INTEGRATION_PAGE_PATH = `${REDIRECT_PAGE_PATH}/viia/:type?/:id?`;
export const XERO_REFRESH_PAGE_PATH = `${REDIRECT_PAGE_PATH}/xero/callback/refresh`;
export const XERO_INTEGRATION_PAGE_PATH = `${REDIRECT_PAGE_PATH}/xero/callback/first`;
export const EACCOUNTING_REFRESH_PAGE_PATH = `${REDIRECT_PAGE_PATH}/eaccounting/callback/refresh`;
export const EACCOUNTING_INTEGRATION_PAGE_PATH = `${REDIRECT_PAGE_PATH}/eaccounting/callback/first`;
