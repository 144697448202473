import { t } from '@lingui/macro';

export const columns = (reportMode) => [
  {
    width: reportMode ? 200 : 310,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 160 : 240,
    Header: t`Altinn`,
    sortType: 'basic',
    accessor: 'ssre',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 160 : 230,
    Header: t`Gl`,
    sortType: 'basic',
    accessor: 'ssgl',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 160 : 230,
    Header: t`Deviation`,
    sortType: 'basic',
    accessor: 'deviation',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
