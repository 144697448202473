import { t } from '@lingui/macro';

export const columns = ({ reportMode, payrollApproved, isReadOnly }) => [
  {
    width: reportMode ? 220 : 300,
    Header: t`Name`,
    accessor: 'accountNumber',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`General Ledger Reported`,
    sortType: 'basic',
    accessor: 'amountLeft',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'amountLeft',
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 180 : 250,
    Header: t`Supplements for expensed salaries, etc. in previous years, which
    have been paid in the past year`,
    lowercase: true,
    sortType: 'basic',
    accessor: 'inputC',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'inputC',
    cell: {
      type: 'input',
      inputType: 'number',
      editable: (row) => !row.subtitle && !reportMode && !row.disableEdit,
      disabled: payrollApproved || isReadOnly,
    },
  },
  {
    width: reportMode ? 180 : 240,
    Header: t`Deductions for accrued, unpaid salaries, etc. in recent years that
    haven't been reported`,
    lowercase: true,
    sortType: 'basic',
    accessor: 'inputD',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'inputD',
    cell: {
      type: 'input',
      inputType: 'number',
      editable: (row) => !row.subtitle && !reportMode && !row.disableEdit,
      disabled: payrollApproved || isReadOnly,
    },
  },
  {
    width: reportMode ? 120 : 160,
    Header: t`Total services subject to disclosure`,
    sortType: 'basic',
    accessor: 'sum',
    lowercase: true,
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'sum',
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 180,
    Header: t`General Ledger Social Security Duty`,
    sortType: 'basic',
    accessor: 'amountRight',
    className: 'flex-right',
    predefinedTotal: 'amountRight',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
