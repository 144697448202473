import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AccrualsInfoModal } from 'components';

import { fromResource } from 'store/selectors';
import { getAccrualInfo } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';

function AccrualsInfoModalContainer(props) {
  const { item, toggleModal } = props;
  const [actionSubmitting, setActionSubmitting] = useState({});
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getAccrualInfo),
  );
  const loading = useSelector((state) =>
    pending(state, `${getAccrualInfo}ListRead`),
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = (action) => {
    _setActionSubmitting(action, false);
  };

  useEffect(() => {
    dispatch(
      resourceListReadRequest(getAccrualInfo, {
        transactionIds: item.origin?.map((el) => el.transactionId),
      }),
    );
  }, [JSON.stringify(item)]);

  return (
    <AccrualsInfoModal
      {...{
        item,
        data,
        loading,
        toggleModal,
        handleAction,
        actionSubmitting,
      }}
    />
  );
}

AccrualsInfoModalContainer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default AccrualsInfoModalContainer;
