import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { NavItem as BootStrapNavItem } from 'react-bootstrap';

/**
 * Navigation Item component
 */
function NavItem(props) {
  const { to } = props;
  if (to) {
    return (
      <LinkContainer to={to}>
        <BootStrapNavItem {...props} />
      </LinkContainer>
    );
  }
  return <BootStrapNavItem {...props} />;
}

NavItem.propTypes = {
  to: PropTypes.string,
};

NavItem.defaultProps = {
  to: undefined,
};

export default NavItem;
