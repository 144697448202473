import React from 'react';
import { AiFillDelete } from '@react-icons/all-files/ai/AiFillDelete';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { IoIosSave } from '@react-icons/all-files/io/IoIosSave';
import { t } from '@lingui/macro';

const style = { marginBottom: -2, fontSize: 14 };

export const iconGroup = (roleType) => ({
  colValue: 6,
  separable: true,
  separableId: 3,
  buttonShapes: {
    firstGroup: [
      {
        id: 1,
        isEnabled: !roleType.isReadOnly,
        size: 'small',
        fill: true,
        magnify: true,
        kind: 'primary',
        wide: false,
        style: { marginLeft: '8px' },
        icon: <FaPlus style={style} />,
        label: t`Add Rows`,
      },
      {
        id: 2,
        isEnabled: !roleType.isReadOnly,
        size: 'small',
        fill: true,
        magnify: true,
        kind: 'success',
        wide: false,
        style: { marginLeft: '8px' },
        icon: <IoIosSave style={style} />,
        label: t`Save Rows`,
      },
      {
        id: 3,
        isEnabled: !roleType.isReadOnly,
        size: 'small',
        fill: true,
        kind: 'danger',
        magnify: true,
        wide: false,
        style: { marginLeft: '8px' },
        icon: <AiFillDelete style={style} />,
        label: t`Delete All Rows`,
      },
    ],

    secondGroup: [],
  },
});
export default { iconGroup };
