const getBankSummary = ({
  glTotal,
  bankTotal,
  bookedBalance,
  outgoingBalance,
  accountingPeriod,
}) => {
  const outgoingValues =
    typeof outgoingBalance === 'number' && !Number.isNaN(outgoingBalance)
      ? outgoingBalance
      : 0;

  const outgoingBankValues =
    typeof bookedBalance?.[accountingPeriod] === 'number'
      ? bookedBalance[accountingPeriod]
      : 0;

  const deviation = outgoingValues - outgoingBankValues;
  const totalGL = glTotal || 0;
  const totalBank = bankTotal || 0;

  const unexplainedDeviation = deviation - totalGL + totalBank;

  return {
    totalGL,
    totalBank,
    deviation,
    unexplainedDeviation,
    outgoingBankValues,
  };
};

export default getBankSummary;
