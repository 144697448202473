import React from 'react';
import styled from 'styled-components';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TitleYearDropdown(props) {
  const { years, year, handleAction, title } = props;
  return (
    <>
      <span>{title}</span>
      {years && years.length > 0 && (
        <>
          <span>&nbsp;–&nbsp;</span>
          <CustomDropDown
            title={year}
            bsSize="large"
            className="btn btn-fill btn-magnify"
          >
            {years.map((item) => (
              <MenuItem
                key={item}
                value={item}
                active={item === +year || false}
                onSelect={() => {
                  handleAction('changeYear', item);
                }}
              >
                {item}
              </MenuItem>
            ))}
          </CustomDropDown>
        </>
      )}
    </>
  );
}

const CustomDropDown = styled(DropdownButton)`
  border: 0;
`;

TitleYearDropdown.propTypes = {
  years: PropTypes.arrayOf(PropTypes.string),
  year: PropTypes.string,
  handleAction: PropTypes.func,
  title: PropTypes.string,
};

TitleYearDropdown.defaultProps = {
  years: [],
  year: '',
  handleAction: () => {},
  title: '',
};

export default TitleYearDropdown;
