import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components/macro';
import CountUp from 'react-countup';
import { t, Trans } from '@lingui/macro';
import { Col, Row } from 'react-bootstrap';
import { VictoryPie, VictoryAnimation, VictoryLabel } from 'victory';

import { Heading, Spinner } from 'components';

import { TABLET, DESKTOP } from 'constants/mediaSizes';

import crossAnimation from 'assets/animations/cross.json';
import successAnimation from 'assets/animations/success_check.json';

const getPieChart = (props) => {
  const { total, title, subTitle, approved, titleColor, onItemClick } = props;
  let percent = approved && total ? +((approved * 100) / total).toFixed(0) : 0;

  percent = percent > 100 ? 100 : percent;

  const pieData = [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];

  return (
    <ChartHolder withCursor={!!onItemClick} onClick={onItemClick}>
      <TitleHolder color={titleColor}>{title}</TitleHolder>
      <SubTitleHolder>
        {subTitle || (
          <>
            <CountUp end={approved} start={0} duration={1} separator=", " />/
            <CountUp end={total} start={0} duration={1} separator=", " />
          </>
        )}
      </SubTitleHolder>
      <svg viewBox="0 0 400 500" width="100%" height="100%">
        <VictoryPie
          data={pieData}
          width={400}
          height={300}
          labels={() => null}
          animate={{ duration: 1000 }}
          standalone={false}
          innerRadius={110}
          style={{
            data: {
              fill: ({ datum }) => {
                let color = 'red';

                if (datum.y > 50) {
                  color = 'green';
                } else if (datum.y > 30) {
                  color = 'yellow';
                }

                return datum.x === 1 ? color : 'white';
              },
            },
          }}
        />
        <VictoryAnimation duration={1000} data={{ data: pieData, percent }}>
          {() => (
            <VictoryLabel
              x={200}
              y={150}
              text={`${percent}%`}
              style={{ fontSize: 45, fill: 'white' }}
              textAnchor="middle"
              verticalAnchor="middle"
            />
          )}
        </VictoryAnimation>
      </svg>
    </ChartHolder>
  );
};
const getLottieAnimation = ({ title, loading, active, onItemClick }) => (
  <ChartHolder withCursor={!!onItemClick} onClick={onItemClick}>
    <TitleHolder>{title}</TitleHolder>
    <AnimationHolder>
      {loading ? (
        <Spinner type="white" />
      ) : (
        <Lottie
          width="55%"
          height="55%"
          options={{
            loop: false,
            autoplay: true,
            animationData: active ? successAnimation : crossAnimation,
          }}
        />
      )}
    </AnimationHolder>
  </ChartHolder>
);

export function DashboardMyProgress(props) {
  const { data, loading, company, userData, handleRedirect } = props;
  const { companySettings } = company;

  const smallBoxBootstrapWidth =
    4 -
    (companySettings?.vatEnabled ? 1 : 0) -
    (companySettings?.payrollEnabled ? 1 : 0);

  if (loading) {
    return (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  }

  return (
    <Row>
      <Col sm={2} md={2} lg={2} />
      <Col sm={8} md={8} lg={8}>
        {getPieChart({
          total: userData?.totalAccounts || 0,
          approved: userData?.approvedAccounts || 0,
          title: (
            <>
              <Trans>All accounts that I am the account holder for:</Trans>{' '}
              <CountUp
                end={userData?.totalAccounts || 0}
                start={0}
                duration={1}
                separator=", "
              />
            </>
          ),
          subTitle: (
            <>
              <Trans>
                Distributed on{' '}
                <CountUp
                  end={userData?.amountOfCompanies || 0}
                  start={0}
                  duration={1}
                  separator=", "
                />{' '}
                companies
              </Trans>
              <br />
              <Trans>Approved:</Trans>{' '}
              <CountUp
                end={userData?.approvedAccounts || 0}
                start={0}
                duration={1}
                separator=", "
              />
              /
              <CountUp
                end={userData?.totalAccounts || 0}
                start={0}
                duration={1}
                separator=", "
              />
            </>
          ),
        })}
      </Col>
      <Col sm={2} md={2} lg={2} />
      <Col md={12}>
        <Heading level={3} alignment="center">
          {data[0]?.name || ''}
        </Heading>
      </Col>
      <Col sm={6} md={4} lg={smallBoxBootstrapWidth}>
        {getPieChart({
          title: t`General`,
          total: data[0]?.glTotalAccounts || 0,
          approved: data[0]?.glApprovedAccounts || 0,
          onItemClick: () => handleRedirect('general'),
        })}
      </Col>
      <Col sm={6} md={4} lg={smallBoxBootstrapWidth}>
        {getPieChart({
          title: t`Bank`,
          total: data[0]?.bankTotalAccounts || 0,
          approved: data[0]?.bankApprovedAccounts || 0,
          onItemClick: () => handleRedirect('bank'),
        })}
      </Col>
      <Col sm={6} md={4} lg={smallBoxBootstrapWidth}>
        {getPieChart({
          title: t`Simple Ledger`,
          total: data[0]?.simpleLedgerTotalAccounts || 0,
          approved: data[0]?.simpleLedgerApprovedAccounts || 0,
          onItemClick: () => handleRedirect('simple_ledger'),
        })}
      </Col>
      {companySettings?.vatEnabled && (
        <Col sm={6} md={4} lg={smallBoxBootstrapWidth}>
          {getLottieAnimation({
            loading,
            title: t`VAT`,
            active: data[0]?.vat || false,
            onItemClick: () => handleRedirect('vat'),
          })}
        </Col>
      )}
      {companySettings?.payrollEnabled && (
        <Col sm={6} md={4} lg={smallBoxBootstrapWidth}>
          {getLottieAnimation({
            loading,
            title: t`Payroll`,
            active: data[0]?.payroll || false,
            onItemClick: () => handleRedirect('payroll'),
          })}
        </Col>
      )}
    </Row>
  );
}

export default DashboardMyProgress;

const TitleHolder = styled.div`
  color: ${(props) => props.color || '#ffffff'};
  font-size: 23px;
  text-align: center;

  @media (max-height: ${TABLET.max}px) {
    font-size: 20px;
  }

  @media (max-height: ${DESKTOP.maxHeight}px) {
    font-size: 18px;
  }
`;
const SubTitleHolder = styled.div`
  color: black;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;

  @media (max-height: ${TABLET.max}px) {
    font-size: 18px;
  }

  @media (max-height: ${DESKTOP.maxHeight}px) {
    font-size: 16px;
  }
`;

const AnimationHolder = styled.div`
  height: calc(100% - 63px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartHolder = styled.div`
  height: 30vh;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
  background-color: #7a9e9f;
  ${(props) => (props.withCursor ? 'cursor: pointer;' : '')}
`;
