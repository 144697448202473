import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 140,
    Header: t`Account ID`,
    accessor: 'accountId',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Account Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 120,
    Header: t`Period`,
    accessor: 'period',
    className: '',
  },
  {
    width: 160,
    Header: t`Value Date`,
    accessor: 'postedDate',
    className: 'flex-right',
  },
  {
    width: 160,
    Header: t`Trans. ID`,
    accessor: 'transactionId',
    className: '',
    withTooltip: true,
  },
  {
    width: 180,
    Header: t`Text`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 160,
    Header: t`Vat code`,
    accessor: 'taxDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 160,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
