import React from 'react';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';

import VATControlDrilldownTable from 'containers/VATControlDrilldownTable';

import { useVATControlDrilldownData } from 'services/hooks/vat';

/**
 * VAT Reconciliation Control Page Component
 */

function VATReconciliationControlPage(props) {
  const { roleType, company } = props;
  const params = useParams();
  const { accountingYear, accountId } = params;
  const { currentCompanySID } = company;

  const { location } = props;

  const getSearch = () => {
    const search = queryString.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });

    const result = {
      accumulated: search.accumulated !== undefined ? search.accumulated : true,
      activePeriods:
        search.activePeriods || company.currentWorkingPeriodEnd || 1,
      frequency: search.frequency || 'BIMONTHLY',
      vatCode: search.vatCode || '',
    };

    return result;
  };
  const search = getSearch();
  const { vatCode, accumulated, activePeriods, frequency } = search;

  const vatControlDrilldownData = useVATControlDrilldownData({
    companySid: currentCompanySID,
    accountId,
    accountingYear,
    activePeriods,
    accumulated,
    frequency,
    vatCode,
  });

  return (
    <VATControlDrilldownTable
      {...{
        accountId,
        roleType,
        params,
        vatControlDrilldownData,
        activePeriods,
        accumulated,
        periodType: search.frequency,
        vatCode,
      }}
    />
  );
}

export default VATReconciliationControlPage;
