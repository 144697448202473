import React, { Component } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';

import { CompanySettings } from 'components';

import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import {
  companyInfoRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  periodSelectors,
  injectCompanyDataToURL,
  periodSelectorValueInitialize,
} from 'services/helpers';
import {
  reconcileApi,
  getCountryListApi,
  getXeroRefreshURLApi,
  getCompanyDetailedApi,
  systemIntegrationSetupApi,
  getEAccountingRefreshURLApi,
  getVatReportFrequencyListApi,
} from 'services/apihelpers';

class CompanySettingsContainer extends Component {
  static propTypes = {
    /** @type {shape} user */
    user: PropTypes.shape({ roleNames: PropTypes.arrayOf(PropTypes.shape({})) })
      .isRequired,

    location: PropTypes.shape({
      state: PropTypes.shape({}),
    }),
    getAccountingSystemList: PropTypes.func.isRequired,
    verifyTripletexToken: PropTypes.func.isRequired,
    getCountryList: PropTypes.func.isRequired,
    postCompanySettings: PropTypes.func.isRequired,
    initializeForm: PropTypes.func.isRequired,
    accountingSystemList: PropTypes.shape({}).isRequired,
    userListByCompany: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tripletexCompanies: PropTypes.shape({}).isRequired,
    countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onCompanyInfoUpdate: PropTypes.func.isRequired,
    getUserListByCompany: PropTypes.func.isRequired,
    getVatReportFrequencyList: PropTypes.func.isRequired,
    companyDetails: PropTypes.shape({}),
    loadingAccountingSystemList: PropTypes.bool,
    frequencyList: PropTypes.shape({}).isRequired,
    loadingFrequencyList: PropTypes.bool,
    loadingCountryList: PropTypes.bool,
    validatingToken: PropTypes.bool,
    validatingXledgerDetails: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    roleType: PropTypes.shape({}).isRequired,
    /** @type {shape} company */
    company: PropTypes.shape({
      accountSystem: PropTypes.string.isRequired,
      currentCompanySID: PropTypes.number.isRequired,
      arbitraryAmount: PropTypes.number.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    location: undefined,
    companyDetails: {},
    loadingAccountingSystemList: true,
    loadingFrequencyList: false,
    loadingCountryList: true,
    validatingXledgerDetails: false,
    validatingToken: false,
  };

  state = {
    accountingSystem: undefined,
  };

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps) {
    const { location, company } = this.props;

    if (
      nextProps.location.search !== location.search ||
      nextProps.company.countryCode !== company.countryCode ||
      nextProps.company.currentCompanySID !== company.currentCompanySID
    ) {
      this.fetchData(nextProps);
    }

    return true;
  }

  fetchData = async (props) => {
    const {
      company,
      getCountryList,
      getXeroRefreshURL,
      getUserListByCompany,
      getEAccountingRefreshURL,
      getVatReportFrequencyList,
      match: { params },
    } = props || this.props;

    try {
      await getCountryList();
      await getVatReportFrequencyList({ countryCode: company.countryCode });
      await getUserListByCompany({ companyId: company.currentCompanySID });

      if (company.accountSystem === 'EACCOUNTING') {
        await getEAccountingRefreshURL({ companyId: params.companyId });
      } else if (company.accountSystem === 'XERO') {
        await getXeroRefreshURL({ companyId: params.companyId });
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  handleFilterChange = (event) => {
    const { target } = event;
    const { value } = target;
    this.setState({
      periodType: value,
      periodNumberMax: periodSelectors(value).total,
    });
  };

  handleSubmit = async (credentials) => {
    const { periodType } = this.state;
    const {
      company,
      history,
      location,
      postCompanySettings,
      onCompanyInfoUpdate,
      match: { params },
    } = this.props;
    const usedPeriodType = periodType || credentials.periodtypes;

    const workingPeriodEnd = periodSelectorValueInitialize(
      usedPeriodType,
      credentials.periodnumber,
    );

    const tabsEnableValues = {
      payrollEnabled:
        credentials.payrollEnabled === 'true' ||
        credentials.payrollEnabled === true,
      vatEnabled:
        credentials.vatEnabled === 'true' || credentials.vatEnabled === true,
      assetsEnabled:
        credentials.assetsEnabled === 'true' ||
        credentials.assetsEnabled === true,
    };

    const query = {
      currentAccountingYear:
        company.currentAccountingYear || params?.accountingYear,
      currentCompanyName: credentials?.companyname || '',
      accountHolderUserUuid: credentials.user || '',
      currentCompanyRegistrationNumber: credentials.registrationnumber,
      companySID: company.currentCompanySID,
      periodType: usedPeriodType,
      periodNo: workingPeriodEnd,
      arbitraryAmount: credentials.arbitraryamount,
      vatReportFrequencyId: credentials.vatReportingFrequency,
      countryCode: credentials.country,
      ...tabsEnableValues,
    };

    try {
      await postCompanySettings(query);

      if (workingPeriodEnd === company.currentWorkingPeriodEnd) {
        onCompanyInfoUpdate();
      } else {
        history.push(
          injectCompanyDataToURL(location, {
            id: company.uuid,
            accountingYear: company.currentAccountingYear,
            currentWorkingPeriodEnd: workingPeriodEnd,
          }),
        );
      }
      toast.success(t`Company settings updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  handleEaccounting = () => {
    window.location.href = this.props.eAccountingRefreshURL;
  };

  handleXero = () => {
    window.location.href = this.props.xeroRefreshURL;
  };

  render() {
    const {
      company,
      loading,
      roleType,
      countryList,
      frequencyList,
      userListLoading,
      userListByCompany,
      loadingCountryList,
      loadingFrequencyList,
    } = this.props;
    const { response, periodNumberMax, accountingSystem } = this.state;

    return (
      <CompanySettings
        onSubmit={this.handleSubmit}
        countries={countryList}
        handleXero={this.handleXero}
        handleEaccounting={this.handleEaccounting}
        handleFilterChange={this.handleFilterChange}
        periodNumberMax={
          periodNumberMax ||
          (periodSelectors(company.periodType) &&
            periodSelectors(company.periodType).total)
        }
        {...this.props}
        {...{
          company,
          loading,
          roleType,
          response,
          frequencyList,
          userListLoading,
          accountingSystem,
          userListByCompany,
          loadingCountryList,
          loadingFrequencyList,
        }}
      />
    );
  }
}

export default connect(
  (state) => ({
    user: fromAuth.getUser(state),
    userListByCompany: fromResource.getList(state, getCompanyDetailedApi),
    frequencyList: fromResource.getList(state, getVatReportFrequencyListApi),
    countryList: fromResource.getList(state, getCountryListApi),
    company: fromCompany.getCompany(state),
    eAccountingRefreshURL: fromResource.getList(
      state,
      getEAccountingRefreshURLApi,
    )?.url,
    xeroRefreshURL: fromResource.getList(state, getXeroRefreshURLApi)?.url,
    loading: pending(state, `${systemIntegrationSetupApi}Update`),
    userListLoading: pending(state, `${getCompanyDetailedApi}ListRead`),
    loadingCountryList: pending(state, `${getCountryListApi}ListRead`),
    loadingFrequencyList: pending(
      state,
      `${getVatReportFrequencyListApi}ListRead`,
    ),
    loadingXeroRefreshURL: pending(
      state,
      `${getEAccountingRefreshURLApi}ListRead`,
    ),
    loadingEAccountingRefreshURL: pending(
      state,
      `${getEAccountingRefreshURLApi}ListRead`,
    ),
    onReconcileLoading: pending(state, `${reconcileApi}ListRead`),
    onReconcileFailed: rejected(state, `${reconcileApi}ListRead`),
  }),
  (dispatch) => ({
    getCountryList: () => dispatch(resourceListReadRequest(getCountryListApi)),
    getVatReportFrequencyList: (query) =>
      dispatch(resourceListReadRequest(getVatReportFrequencyListApi, query)),
    getEAccountingRefreshURL: (query) =>
      dispatch(resourceListReadRequest(getEAccountingRefreshURLApi, query)),
    getXeroRefreshURL: (query) =>
      dispatch(resourceListReadRequest(getXeroRefreshURLApi, query)),
    postCompanySettings: (query) =>
      dispatch(resourceUpdateRequest(systemIntegrationSetupApi, null, query)),
    getUserListByCompany: (query) =>
      dispatch(resourceListReadRequest(getCompanyDetailedApi, query)),
    onCompanyInfoUpdate: () => dispatch(companyInfoRequest()),
  }),
)(CompanySettingsContainer);
