import React from 'react';
import PropTypes from 'prop-types';

import { RenderRoutes } from 'components';

export function Reports(props) {
  const {
    route: { routes },
  } = props;

  return <RenderRoutes routes={routes} props={props} />;
}

export default Reports;

Reports.propTypes = {
  route: PropTypes.shape({}).isRequired,
};
