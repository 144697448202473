import React from 'react';
import PropTypes from 'prop-types';

import AuditTable from 'containers/AuditTable';

export function AuditPage(props) {
  return <AuditTable {...props} />;
}

export default AuditPage;

AuditPage.propTypes = {
  route: PropTypes.shape({}).isRequired,
};
