const report = 'report-api';
const version = 'v1';
const secureBase = 'api/';
const services = 'services/';
const secureBasePath = `${secureBase}${version}/`;

/* eslint-disable max-len */
const endpoints = {
  addDeviationApi: `${version}/secure/addDeviation`,
  deleteDeviationApi: `${version}/secure/deleteDeviation`,
  adminCreateUserApi: `${version}/admin/createUser`,
  adminEditApi: `${version}/admin/editUser`,
  adminUpdateUserStatus: `${version}/admin/updateUserStatus`,
  confirmRegistrationApi: `${version}/confirmRegistration?`,
  createCompanyApi: `${version}/secure/admin/company`,
  currentCompanyDetailsApi: `${version}/secure/current-company-details`,
  currentUserDetailsApi: `${version}/currentUserDetails`,
  deleteFileApi: `${version}/secure/deleteFile`,
  deleteCommentApi: `${version}/secure/deleteComment`,
  editCurrentCommentApi: `${version}/secure/editComment`,
  getPayrollReportedSalary: `${version}/secure/new-payroll-overview`,
  manualPayrollReport: `${version}/secure/report-element-input`,
  isManualApi: `${version}/secure/is-manual`,
  getReportedSalaryDetails: `${version}/secure/reported-salary-details`,
  editReportedSalaryInput: `${version}/secure/reported-salary-details/input-fields`,
  getAccountingSystemListApi: `${version}/secure/getAccountingSystemList`,
  getActiveCompanyByOrganizationApi: `${version}/secure/admin/active-company-by-organization`,
  changeCompanyApi: `${version}/secure/change-company`,
  getCompanyByOrganizationApi: `${version}/secure/admin/company-by-organization`,
  getCountryListApi: `${version}/secure/getCountryList`,
  getCountryListDetailsApi: `${version}/getCountryListDetails`,
  getFileListApi: `${version}/secure/getFileList`,
  putNewFilesOrder: `${version}/secure/update-file-order`,
  allFiles: `${secureBasePath}files`,
  uploadedFilesApi: `${secureBasePath}files/accounts`,
  getGeneralLedgerDataApi: `${version}/secure/report/getGeneralLedgerData`,
  getLanguageListApi: `${version}/secure/getLanguageList`,
  getReconciliationGLAccountListApi: `${version}/secure/getReconciliationGLAccountList`,
  getRoleListApi: `${version}/getRoleListByCompanyId`,
  getStandardAccountListApi: `${version}/secure/getStandardAccountList`,
  getTransactionDialogueApi: `${version}/secure/getTransactionDialogue`,
  getTransactionSummaryTemplateApi: `${version}/secure/getTransactionSummaryTemplate`,
  multipleFileUploadApi: `${version}/secure/multipleFileUpload`,
  reconcileApi: `${version}/secure/reconcile`,
  registerCompanyApi: `${version}/registerCompany`,
  renameFileApi: `${version}/secure/renameFile`,
  invalidateResetPasswordLinkApi: `${version}/password/invalidateLink`,
  systemIntegrationSetupApi: `${version}/secure/admin/system-integration-setup`,
  getTotalAutoGLReconcileApi: `${version}/secure/get-gl-suggestions`,
  setTotalAutoGLReconcileApi: `${version}/secure/new-total-auto-gl-reconcile`,
  unLinkPartialReconciliationApi: `${version}/secure/unLinkPartialReconciliation`,
  unLinkReconciliationApi: `${version}/secure/unLinkReconciliation`,
  putCompanyCommentApi: `${version}/secure/company-comment`,
  updateCompaniesStatusApi: `${version}/secure/companies-status`,
  updateCurentUserApi: `${version}/admin/updateCurrentUser`,
  updateReconcileSubLegerApi: `${version}/secure/updateReconcileSubLeger`,
  updateTransactionCommentApi: `${version}/secure/updateTransactionComment`,
  updateTransactionCommentCreateApi: `${version}/secure/updateTransactionComment`,
  validateRegisterCompanyApi: `${version}/validateRegisterCompany`,
  verifyPowerOfficeTokenApi: `${version}/secure/verify-power-office-integration`,
  reValidatePowerOfficeTokenApi: `${version}/secure/revalidate-power-office-token`,
  verifyXledgerAccountApi: `${version}/secure/verify-xledger-account`,
  addAccountCommentApi: `${version}/secure/addAccountComment`,
  getAccountDialogueListApi: `${version}/secure/getAccountDialogue`,
  savePartialTransactionApi: `${version}/secure/savePartialTransaction`,
  getPartialTransactionApi: `${version}/secure/getPartialTransaction`,
  deletePartialTransactionApi: `${version}/secure/deletePartialTransaction`,
  deletePartialTransactionListApi: `${version}/secure/deletePartialTransactionList`,
  editPartialTransactionApi: `${version}/secure/editPartialTransaction`,
  payRollPaymentSettingApi: `${version}/secure/payRollPaymentSetting`,
  getPayRollPaymentTypeListApi: `${version}/secure/getPayRollPaymentTypeList`,
  updatePayRollSettingApi: `${version}/secure/updatePayRollSetting`,
  viewFileApi: `${version}/secure/viewFile`,
  verifyTwentyFourSevenApi: `${version}/secure/verify-twenty-four-seven`,
  payRollReportApi: `${version}/secure/payRollReport`,
  validateAltinnSessionApi: `${version}/secure/validateAltinnSession`,
  saveReconciliationUserCommentApi: `${version}/secure/saveReconciliationUserComment`,
  reValidateTwentyFourSevenApi: `${version}/secure/revalidate-twenty-four-seven`,
  reValidateXledgrAccountApi: `${version}/secure/revalidate-xledger-account`,
  saveAccountUserCommentApi: `${version}/secure/account-user-comment`,
  uploadBankTransactionApi: `${version}/secure/bank-transaction`,
  getOrganizationsByUserApi: `${version}/secure/superAdmin/getOrganizationsByUser`,
  getOrganizationAltinnAvailabilityApi: `${version}/secure/organization-altinn-availability`,
  editOrganizationBySidApi: `${version}/secure/superAdmin/editOrganizationBySid`,
  fetchVatReportsFromAltinnApi: `${version}/secure/fetchVatReportsFromAltinn`,
  getVatReportFrequencyListApi: `${version}/secure/getVatReportFrequencyList`,
  getCompanyAccountPayrollListApi: `${version}/secure/getCompanyAccountPayrollList`,
  payRollTaxPaymentSettingApi: `${version}/secure/payRollTaxPaymentSetting`,
  updatePayRollTaxPaymentSettingApi: `${version}/secure/updatePayRollTaxPaymentSetting`,
  updateApprovalStatusPayrollReportApi: `${version}/secure/update-approval-status-payroll-report`,
  isPayrollApprovedApi: `${secureBasePath}progress/payroll/approved`,
  addPayrollCommentApi: `${version}/secure/payroll-comment`,
  updatePayrollCommentApi: `${version}/secure/payroll-comment`,
  deletePayrollCommentApi: `${version}/secure/payroll-comment`,
  getPayrollDialogueApi: `${version}/secure/payroll-dialogue`,
  vatCommentApi: `${version}/secure/vat-comment`,
  getVatDialogueApi: `${version}/secure/vat-dialogue`,
  updateReconcileStatusApi: `${version}/secure/reconcile-status`,

  /*
   * ------  New path structure from below ----
   */
  countries: `${secureBasePath}countries`,
  authenticate: `${secureBasePath}authenticate`,
  user: `${secureBasePath}user`,
  getOpenBankingLoginApi: `${secureBasePath}open-banking/login`,
  getOpenBankingAccountsApi: `${secureBasePath}open-banking/accounts`,
  setOpenBankingLoginResponseApi: `${secureBasePath}viia-authentication`,
  cancelAltinnSessionApi: `${secureBasePath}altinn/cancel`,
  retrieveAltinnSessionApi: `${secureBasePath}altinn/retrieve`,
  createCMAUserApi: `${secureBasePath}connect-my-apps/user`,
  userExtraDataApi: `${version}/user-details`,
  lastOpenReleaseDateApi: `${version}/last-open-release-note`,
  checkVismaIntegrationStatusApi: `${secureBasePath}connect-my-apps/visma/status`,
  checkCMAUserStatusApi: `${secureBasePath}connect-my-apps/user/status`,
  setupVismaBusinessApi: `${secureBasePath}visma-business/setup`,
  getVismaBusinessClientsApi: `${secureBasePath}visma-business/clients`,
  getVatDetailsNewer: `${services}vat/reports`,
  getVATJointList: `${services}vat/joint-registration`,
  getVATCodesNew: `${services}vat/vat-codes/v2`,
  getVATCodesHistoryApi: `${services}vat/vat-codes/v2/history`,
  setVATApproveApi: `${services}vat/approve`,
  getVATUnmappedCodes: `${services}vat/vat-codes/unmapped/v2`,
  getVATOverviewApi: `${services}vat/overview`,
  getVATSaftCodesApi: `${services}vat/saft-codes`,
  postVATCommentApi: `${services}vat/vat-comments/create-vat-comment`,
  deleteVATCommentApi: `${services}vat/vat-comments/delete-vat-comment`,
  updateVATCommentApi: `${services}vat/vat-comments/update-vat-comment`,
  getVatSettlementsApi: `${services}vat/settlements`,
  getVatSettlementsLinesApi: `${services}vat/settlement/lines`,
  vatSettlementsMapApi: `${services}vat/settlement/map`,
  vatSettlementsUnmapApi: `${services}vat/settlement/unmap`,
  altinnAuthenticateApi: `${secureBasePath}altinn/authenticate`,
  altinnAuthenticatePinApi: `${secureBasePath}altinn/authenticate/pin`,
  altinnFetchApi: `${secureBasePath}altinn/fetch`,
  getPayrollMappableReportsRowsApi: `${secureBasePath}payroll/transaction-mapping/rows`,
  getPayrollReportsTransactionsApi: `${secureBasePath}payroll/transaction-mapping/transactions`,
  setPayrollReportsTransactionsMapApi: `${secureBasePath}payroll/transaction-mapping/map`,
  deletePayrollReportsTransactionsMapApi: `${secureBasePath}payroll/transaction-mapping/unmap`,
  getPayrollReportDrilldownApi: `${secureBasePath}payroll/drilldown`,
  getPayrollSettlementsApi: `${secureBasePath}payroll/settlements`,
  getPayrollSettlementsTransactionsApi: `${secureBasePath}payroll/settlements/lines/company`,
  setPayrollSettlementsTransactionsMapApi: `${secureBasePath}payroll/settlements/map`,
  deletePayrollSettlementsTransactionsMapApi: `${secureBasePath}payroll/settlements/unmap`,
  companyAPI: `${version}/secure/admin/company`,
  savePayrollCommentApi: `${version}/secure/update-payroll-comment`,
  getPayrollOpeningBalanceApi: `${secureBasePath}payroll/settlements/lines/opening-balance/company`,
  setPayrollSettlementsOpeningBalanceTransactionsMapApi: `${secureBasePath}payroll/settlements/map/opening-balance`,
  getA07XMLApi: `${version}/secure/a07/xml`,
  getPayrollAltinnDataByOrganizationApi: `${version}/secure/getAltinnDataByOrganization`,
  updateCompanyListAltinnApi: `${version}/secure/updateCompanyListAltinn`,
  updateCompanyListAltinnVATApi: `${version}/secure/fetchVatReportsFromAltinnCompanyList`,
  addBankAccountApi: `${secureBasePath}bank/bank-account`,
  removeBankAccountApi: `${secureBasePath}bank/bank-account`,
  getBankAccountsApi: `${secureBasePath}bank/bank-account/map/bank-accounts`,
  getGLAccountsApi: `${secureBasePath}bank/bank-account/map/gl-accounts`,
  mapBankAccountApi: `${secureBasePath}bank/bank-account/map`,
  unMapBankAccountApi: `${secureBasePath}bank/bank-account/unmap`,
  getOpenBankingTransactionsApi: `${secureBasePath}open-banking/accounts`,
  getBankReconcileDataApi: `${secureBasePath}bank/view`,
  setAutoReconcileApi: `${secureBasePath}bank/reconcile/auto`,
  autoReconcileV2Api: `${secureBasePath}bank/reconcile/autov2`,
  setReconcileApi: `${secureBasePath}bank/reconcile`,
  setUnreconcileBankApi: `${secureBasePath}bank/reconcile/break/bank-transaction`,
  setUnreconcileGLApi: `${secureBasePath}bank/reconcile/break/line`,
  bankReconcileSettingsApi: `${secureBasePath}bank/reconcile/configs`,
  bankReconcileCustomApi: `${secureBasePath}bank/reconcile/custom`,
  bankImportSettingsApi: `${secureBasePath}bank/reconcile/configs/organizations`,
  getAuditApi: `${secureBasePath}audit-event/overview`,
  getAuditDetailsApi: `${secureBasePath}audit-event/overview/details`,
  checkXledgerOrganizationUserApi: `${secureBasePath}xledger-user/has`,
  checkXledgerUserUseApi: `${secureBasePath}xledger-user/use`,
  getPayrollSocialSecurityApi: `${secureBasePath}payroll/social-security`,
  getPayrollVacationPayData: `${secureBasePath}payroll/vacation-pay`,
  getPayrollVacationPayDataNew: `${services}payroll/reconciliation/vacation-pay`,
  getTrialBalanceApi: `${secureBasePath}trial-balance`,
  accountsControlApi: `${secureBasePath}control`,
  periodCommentApi: `${secureBasePath}period-comment`,
  checkIfCompanyIsAvailableApi: `${secureBasePath}access/user-company`,
  periodCommentAllApi: `${secureBasePath}period-comment/all`,
  getReconciliationGLAccountsFastApi: `${secureBasePath}trial-balance/accounts-overview`,
  getAccrualsApi: `${secureBasePath}accruals`,
  setAccrualsOriginApi: `${secureBasePath}accruals/toggle-origin`,
  setAccrualsCommentApi: `${secureBasePath}accruals/comment`,
  getGeneralLedgerTransactionApi: `${secureBasePath}reconciliation-accounts`,
  getMultipleAccountsDrilldownsApi: `${secureBasePath}reconciliation/accounts/all`,
  getGLAccountDetails: `${secureBasePath}reconciliation/accounts/gl`,
  getSLAccountDetails: `${secureBasePath}reconciliation/accounts/sl`,
  getBankAccountDetails: `${secureBasePath}reconciliation/accounts/bank`,
  addGroupCompanyApi: `${secureBasePath}group/company/create`,
  editGroupCompanyApi: `${secureBasePath}group/company/edit`,
  deleteGroupCompanyApi: `${secureBasePath}group/company`,
  getGroupCompanyListApi: `${secureBasePath}group/group-details`,
  companyGroupSecretApi: `${secureBasePath}secret/company-group`,
  getGroupParentCompanyDetailsApi: `${secureBasePath}group/company/lists`,
  getGroupCompanyAssociatedApi: `${secureBasePath}group/company/associated-groups`,
  setGroupParentCompanyApi: `${secureBasePath}group/company/companies`,
  setCompanyGroupCompaniesApi: `${secureBasePath}group/company/company`,
  userGroupSecretApi: `${secureBasePath}secret/user-group`,
  deleteGroupCompaniesApi: `${secureBasePath}group/company/companies`,
  getGroupUserListApi: `${secureBasePath}group/user/details`,
  addGroupUserApi: `${secureBasePath}group/user/create`,
  editGroupUserApi: `${secureBasePath}group/user/edit`,
  deleteGroupUserApi: `${secureBasePath}group/user`,
  editCompanyApi: `${secureBasePath}edit/company`,
  secretCompanyApi: `${secureBasePath}secret/company`,
  updateXledgerApi: `${secureBasePath}edit/xledger`,
  updatePowerOfficeApi: `${secureBasePath}edit/power-office`,
  update24SOApi: `${secureBasePath}edit/twenty-four-seven`,
  updateVismaClient: `${version}/secure/update-visma-business`,
  setGroupParentUserApi: `${secureBasePath}group/user/users`,
  getGroupParentUserDetailsApi: `${secureBasePath}group/user/lists`,
  getFeatureUnleashApi: `${secureBasePath}unleash/is-enabled`,
  getGroupUserAssociatedApi: `${secureBasePath}group/user/associated-groups`,
  deleteGroupUsersApi: `${secureBasePath}group/user/users`,
  setUserGroupUsersApi: `${secureBasePath}group/user/user`,
  getVacationPayDefaultRowsApi: `${secureBasePath}payroll/vacation-pay/default-rows`,
  setVacationPayDefaultRowsApi: `${secureBasePath}payroll/vacation-pay/row-defaults`,
  setVacationPayDefaultMainRowApi: `${secureBasePath}payroll/vacation-pay/main-element-default`,
  deleteVacationPayDefaultRowApi: `${secureBasePath}payroll/vacation-pay/row`,
  updateVacationPayDefaultRowApi: `${secureBasePath}payroll/vacation-pay/update-row`,
  addDeviationRowApi: `${secureBasePath}payroll/vacation-pay/add-deviation`,
  editDeviationRowApi: `${secureBasePath}payroll/vacation-pay/edit-deviation`,
  deleteDeviationRowApi: `${secureBasePath}payroll/vacation-pay/delete-deviation`,
  getFilesFromTransactionLevel: `${secureBasePath}trial-balance/line-files`,
  getVATSettlementsTotalApi: `${secureBasePath}vat/settlements/total`,
  getVATSettlementsDeviationApi: `${secureBasePath}vat/settlements/deviation`,
  addVATSettlementsDeviationApi: `${secureBasePath}vat/settlements/deviation`,
  editVATSettlementsDeviationApi: `${secureBasePath}vat/settlements/deviation`,
  deleteVATSettlementsDeviationApi: `${secureBasePath}vat/settlements/deviation`,
  copyAccountMappingApi: `${secureBasePath}admin/settings/copy-account-mapping`,
  getUserDetailedApi: `${secureBasePath}group/available-companies`,
  getCompanyDetailedApi: `${secureBasePath}group/available-users`,
  validateOrganizationNumberApi: `${version}/secure/is-available-organization-number`,
  checkIsUserFromOrg: `${secureBasePath}access/is-user-from-org`,
  checkHasTripletexOrganizationApi: `${secureBasePath}tripletex-organization/has`,
  getTripletexOrganizationTokenApi: `${secureBasePath}tripletex-organization/organization-token`,
  checkMFAStillValidApi: `${secureBasePath}mfa-still-valid`,
  setMFASettingsApi: `${secureBasePath}mfa-settings`,
  setMFAOrganizationSettingsApi: `${secureBasePath}mfa-settings/organization`,
  sendMFAPinApi: `${secureBasePath}get-or-update-token`,
  checkMFAPinApi: `${secureBasePath}settings/authenticate-code`,
  getAccountsMappingOverviewApi: `${secureBasePath}trial-balance/accounts-mapping-overview`,
  getAccountsNotMappedApi: `${secureBasePath}trial-balance/accounts-not-mapped`,
  saveBulkAccountSettingsApi: `${version}/secure/batch-update-account-settings`,
  getAccountDetailsApi: `${secureBasePath}trial-balance/transactions`,
  getUsersApi: `${version}/admin/users`,
  getAccrualInfo: `${secureBasePath}trial-balance/transactions/transaction-id`,
  setPayrollSSPercentageApi: `${secureBasePath}payroll/social-security/percentage`,
  getVATControlTransactionsApi: `${services}vat/transactions-control/transactions`,
  getVATControlTransactionsDrillDownApi: `${services}vat/transactions-control/transactions/drilldown`,
  getDashboardMyProgress: `${secureBasePath}new-progress/current-user`,
  getDashboardStatistics: `${secureBasePath}new-progress/company-statistics`,
  getDashboardCurrentCompany: `${secureBasePath}new-progress/current-company`,
  getDashboardGroups: `${secureBasePath}group/available-groups`,
  getDashboardUserGroups: `${secureBasePath}new-progress/users-usergroup`,
  getDashboardCompanyGroups: `${secureBasePath}new-progress/users-companygroup`,
  getDashboardAvailableCompanies: `${secureBasePath}new-progress/available-companies`,
  getDashboardCompanyCompaniesGroups: `${secureBasePath}new-progress/companies-companygroup`,
  getEaccountingLoginUrlApi: `${secureBasePath}eaccounting/url/first`,
  getXeroLoginUrlApi: `${secureBasePath}xero/url/first`,
  setEAccounting: `${secureBasePath}eaccounting/callback/first`,
  setXero: `${secureBasePath}xero/callback/first`,
  getXeroRefreshURLApi: `${secureBasePath}xero/url/regular`,
  setXeroRefreshApi: `${secureBasePath}xero/callback`,
  getEAccountingRefreshURLApi: `${secureBasePath}eaccounting/url/regular`,
  setEAccountingRefreshApi: `${secureBasePath}eaccounting/callback`,
  updateCrossYearApi: `${version}/secure/update-cross-year`,
  deactivateCrossYearApi: `${version}/secure/deactivate-cross-year`,
  getIsCrossYearApi: `${version}/secure/is-cross-year`,
  getCompanyByIdApi: `${secureBasePath}company`,
  approveZeroMovementAccountsApi: `${secureBasePath}approve-zero-movement-accounts`,
  updateAccountApproveStatusApi: `${secureBasePath}update-account-approval`,
  getIsUserGroupRequiredApi: `${secureBasePath}group/is-user-group-required`,
  getIsCompanyGroupRequiredApi: `${secureBasePath}group/is-company-group-required`,
  getOBMatchedStatusApi: `${version}/secure/is-ob-matched`,
  getUBvsOBStatusApi: `${version}/secure/ub-vs-ob`,
  getUBOBDataApi: `${version}/secure/ub-ob-data`,
  putUBOBMatchApi: `${version}/secure/match-ub-ob`,
  deleteUBOBBreakApi: `${version}/secure/break-ub-ob`,
  postBankLinesApi: `${secureBasePath}bank/bank-lines`,
  putBankLineApi: `${secureBasePath}bank/bank-line`,
  deleteBankLineApi: `${secureBasePath}bank/bank-line`,
  getAssetReconciliationApi: `${secureBasePath}assets/reconciliation`,
  getAssetAccountsApi: `${secureBasePath}assets/accounts`,
  getAssetCategoriesApi: `${secureBasePath}assets/asset-categories`,
  getAssetDefaultCategories: `${secureBasePath}assets/asset-category/defaults`,
  getAssetCategoryApi: `${secureBasePath}assets/asset-category`,
  putAssetCategoryApi: `${secureBasePath}assets/asset-category`,
  postAssetCategoryApi: `${secureBasePath}assets/asset-category`,
  deleteAssetCategoryApi: `${secureBasePath}assets/asset-category`,
  getAssetsApi: `${secureBasePath}assets/asset`,
  deleteAssetApi: `${secureBasePath}assets/asset`,
  postAssetBalanceApi: `${secureBasePath}assets/asset/balance`,
  postAssetLinearApi: `${secureBasePath}assets/asset/linear`,
  putAssetBalanceApi: `${secureBasePath}assets/asset/balance`,
  putAssetLinearApi: `${secureBasePath}assets/asset/linear`,
  getAssetTransactionsApi: `${secureBasePath}assets/transactions`,
  getTableSettingsApi: `${secureBasePath}table-settings`,
  postTableSettingsApi: `${secureBasePath}table-settings`,
  putTableSettingsApi: `${secureBasePath}table-settings`,
  getAccountsListApi: `${secureBasePath}utility/accounts`,
  getTrialBalancePeriodYearsApi: `${secureBasePath}trial-balance/period-years`,
  generatePDFReportApi: `${report}/generate-pdf`,
  getReportVersionsApi: `${report}/saved-reports`,
  downloadReportApi: `${report}/download-report`,
  getLatestReportApi: `${report}/saved-reports/latest`,
  getVATControlApi: `${services}vat/control`,
  getVATControlDrilldownApi: `${services}vat/control/drilldown`,
  autoReconcileVATApi: `${services}vat/auto-reconcile`,
  undoAutoReconcileVATApi: `${services}vat/break-reconciliation`,
  getVATReconciliationsApi: `${services}vat/vat-reconciliation`,
  getVATDeviationsApi: `${services}vat/vat-reconciliation/has`,
  /**
   * Client Manager
   */
  getCMCompanySystem: `${services}clientmanager/company-system`,
  companyUpdateStream: `${services}clientmanager/event-stream`,
  getDataSetsCM: `${services}clientmanager/data-sets`,
  updateBalanceCM: `${services}clientmanager/balance-update`,
  isBalanceUpdating: `${services}clientmanager/is-updating`,
  createVismaNXTCompany: `${services}clientmanager/vismanxt/create-company`,
  getAvailableVismaNXTCompanies: `${services}clientmanager/vismanxt/available-companies`,
  isOrgVismaNXTEnabled: `${services}clientmanager/vismanxt/is-enabled`,
  isValidIntegration: `${services}clientmanager/is-valid-integration`,
  validateTripletexTokenApi: `${services}clientmanager/tripletex/verify-tripletex-token`,
  reValidateTripletexTokenApi: `${services}clientmanager/tripletex/revalidate-tripletex-token`,
  updateTripletexApi: `${services}clientmanager/tripletex/edit`,
  createTripletexCompanyApi: `${services}clientmanager/tripletex/create-company`,

  /**
   * Payroll Service
   */
  getVacationPayGLBaseApi: `${services}payroll/vacation-pay/general-ledger-base`,
  getVacationPayOtherTablesApi: `${services}payroll/reconciliation/vacation-pay`,
  getVacationPayCalculationApi: `${services}payroll/vacation-pay/calculation`,
  vacationPayDeviationApi: `${services}payroll/vacation-pay/deviation`,
  editAdditionalRowApi: `${services}payroll/vacation-pay/additional-row`,
  updateVacationPayMainPercentageApi: `${services}payroll/vacation-pay/percentages`,
  getVacationPayZonesApi: `${services}payroll/vacation-pay/zones`,
  editAdditionalZoneApi: `${services}payroll/vacation-pay/zones/row`,
  /**
   * Calendar
   */
  getCalendarApi: `${services}calendarfeed/`,
};

module.exports = endpoints;
