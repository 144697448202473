import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  Tabs,
  OrganizationVATAltinnSetup,
  OrganizationPayrollAltinnSetup,
} from 'components';

function OrganizationAltinnSetup(props) {
  const { subTabEntry, handleAction } = props;

  const tabs = [
    {
      title: t`Payroll`,
      eventKey: 'payroll',
      component: <OrganizationPayrollAltinnSetup {...props} />,
    },
    {
      title: t`VAT`,
      eventKey: 'vat',
      component: <OrganizationVATAltinnSetup {...props} />,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      activeKey={subTabEntry}
      onSelect={(key) => handleAction('tabChange', key)}
    />
  );
}

OrganizationAltinnSetup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  subTabEntry: PropTypes.string,
  search: PropTypes.shape({}),
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
  selectCondition: PropTypes.func,
};

OrganizationAltinnSetup.defaultProps = {
  data: [],
  loading: true,
  subTabEntry: 'payroll',
  search: {},
  handleAction: () => {},
  actionSubmitting: {},
  selectCondition: () => {},
};

export default OrganizationAltinnSetup;
