import { React } from 'react';
import { t } from '@lingui/macro';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';

import { Field } from 'components';

function MainRulesContainer(props) {
  const {
    name,
    text,
    regex,
    textSelected,
    regexSelected,
    onInputChange,
    onCheckboxChange,
  } = props;

  const side = name === 'GL' ? 'Left' : 'Right';
  const checkboxName =
    side === 'Left' ? 'leftSelectedType' : 'rightSelectedType';

  const textTooltip = t`Filters by text strings, full or partial string,
  case sensitive. By default, text input will look for matching part in
  the description. Example: "This is the Searched word", text input "Searched",
  then it will find the transaction with the same text string.`;

  // eslint-disable-next-line max-len
  const regexTooltip = t`Filter by regular expression, tool used for pattern matching and manipulating strings of text.`;

  const handleCheckboxChange = (e) => {
    const {
      currentTarget: { id },
    } = e;
    const type = id.includes('regex') ? 'REGEX' : 'TEXT';
    const extraFields = {};

    if (type === 'REGEX' && regexSelected) {
      extraFields[checkboxName] = null;
      extraFields[`regex${side}`] = null;
    }

    if (type === 'TEXT' && textSelected) {
      extraFields[checkboxName] = null;
      extraFields[`textMatch${side}`] = null;
    }

    onCheckboxChange(checkboxName, type, extraFields);
  };

  return (
    <SubContainer>
      <span>{name}</span>
      <InputHolder>
        <CheckboxHolder>
          <Field
            id={`regexCheckbox${side}`}
            size="sm"
            type="checkbox"
            label={t`Regex`}
            checked={regexSelected}
            onChange={handleCheckboxChange}
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{regexTooltip}</Tooltip>}
            placement="bottom"
          >
            <FaInfoCircle />
          </OverlayTrigger>
        </CheckboxHolder>
        <Field
          id={`regex${side}`}
          size="sm"
          type="text"
          disabled={!regexSelected}
          value={regex || ''}
          onChange={onInputChange}
        />
      </InputHolder>
      <InputHolder>
        <CheckboxHolder>
          <Field
            id={`textCheckbox${side}`}
            size="sm"
            type="checkbox"
            label={t`Text`}
            checked={textSelected}
            onChange={handleCheckboxChange}
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{textTooltip}</Tooltip>}
            placement="bottom"
          >
            <FaInfoCircle />
          </OverlayTrigger>
        </CheckboxHolder>
        <Field
          id={`textMatch${side}`}
          size="sm"
          type="text"
          value={text || ''}
          disabled={!textSelected}
          onChange={onInputChange}
        />
      </InputHolder>
    </SubContainer>
  );
}

export default MainRulesContainer;

const SubContainer = styled.div`
  position: relative;
  padding: 15px 15px 10px;
  border-radius: 10px;
  border: 1px solid #cccbc7;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  span {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background: white;
    font-weight: 600;
    font-size: 12px;
  }
`;

const InputHolder = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 30% calc(70% - 10px);
  gap: 10px;
`;

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    fill: #2c94b0;
  }
`;
