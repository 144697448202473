import React, { Component } from 'react';
import PropTypes from 'prop-types';
import errorHandler from 'services/utils/errorHandlerUtility';

import { ErrorCard } from 'components';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { setError, history } = this.props;

    if (typeof setError === 'function' && history?.location?.pathname) {
      setError({
        hasError: true,
        page: history.location.pathname,
      });
    }

    this.setState({ error, errorInfo });
    errorHandler.report(error);
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children, kind } = this.props;

    if (hasError) {
      return <ErrorCard {...{ error, errorInfo, kind }} />;
    }

    return children;
  }
}

export default ErrorBoundary;
