import React from 'react';
import { t } from '@lingui/macro';
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle';
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';
import { GoListOrdered } from '@react-icons/all-files/go/GoListOrdered';

export const partialReconciliationIdConverter = (_, item) =>
  item.partialReconciliationId || item.reconciliationId || '';
export const originIDConverter = (_, item) =>
  item.origin?.map((el) => el.sourceDocumentId)?.join(', ') || '';
export const originAmountConvert = (_, item) =>
  item.origin?.reduce((sum, b) => sum + b.amount, 0) || '';

/**
 * General Accruals Table Grid
 */
export const columns = () => [
  {
    width: 85,
    Header: t`Select`,
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 100,
    Header: t`Partial Match ID`,
    accessor: 'partialReconciliationId',
    className: 'flex-center',
    cell: {
      converter: partialReconciliationIdConverter,
    },
  },
  {
    width: 150,
    Header: t`Origin Transaction ID`,
    accessor: 'originID',
    className: 'flex-left',
    withTooltip: true,
    cell: {
      converter: originIDConverter,
    },
  },
  {
    width: 150,
    Header: t`Text`,
    accessor: 'description',
    className: 'flex-left',
    withTooltip: true,
  },
  {
    width: 230,
    Header: t`Comment`,
    accessor: 'comment',
    className: 'flex-left',
    cell: {
      type: 'textarea',
      editable: true,
      placeholder: t`Add comment:`,
      disableClick: true,
    },
  },
  {
    width: 150,
    Header: t`Origin Amount`,
    sortType: 'basic',
    accessor: 'originAmount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
      converter: originAmountConvert,
    },
  },
  {
    width: 150,
    Header: t`Remaining amount`,
    sortType: 'basic',
    accessor: 'sumRemaining',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 80,
    Header: t`Start month`,
    accessor: 'startMonth',
    className: '',
  },
  {
    width: 80,
    Header: t`Start year`,
    accessor: 'startYear',
    className: '',
  },
  {
    width: 110,
    Header: t`Number of transactions`,
    accessor: 'numberOfTransactions',
    className: 'flex-right',
  },
  {
    width: 140,
    Header: t`Estimated Remaining Months`,
    accessor: 'estimatedRemainingMonths',
    className: '',
  },
  {
    width: 110,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'viewDetails',
          icon: () => <BiInfoCircle />,
          tooltip: t`View Details`,
        },
        {
          id: 'selectOrigin',
          icon: () => <GoListOrdered />,
          tooltip: t`Select origin`,
        },
        {
          id: 'unlink',
          icon: () => <BiUnlink />,
          tooltip: t`Unlink`,
        },
      ],
    },
  },
];

export default { columns };
