import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { vacationPayFormatter } from 'services/dataFormatters/payroll';
import { fromAuth, fromResource } from 'store/selectors';
import {
  getVacationPayGLBaseApi,
  getVacationPayCalculationApi,
  editAdditionalRowApi,
  editAdditionalZoneApi,
  getVacationPayZonesApi,
} from 'services/apihelpers';
import { resourceListReadRequest, resourceUpdateRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
} from 'store/resource/actions';

export const useVacationPayData = (props) => {
  const { bimonthly, activePeriods, accountingYear, currentCompanySID } = props;

  const dispatch = useDispatch();

  const [glBaseLoading, setGLBaseLoading] = useState(false);
  const [calculationLoading, setCalculationLoading] = useState(false);

  const vacationPayGLBase = useSelector((state) =>
    fromResource.getList(state, getVacationPayGLBaseApi),
  );

  const vacationPay = useSelector((state) =>
    fromResource.getList(state, getVacationPayCalculationApi),
  );

  const user = useSelector((state) => fromAuth.getUser(state));

  const term = useMemo(() => Math.ceil(+activePeriods / 2), [activePeriods]);
  const period = useMemo(
    () => (bimonthly ? term : activePeriods),
    [bimonthly, term, activePeriods],
  );

  const zoneReportId = vacationPay?.zones?.reportId || 0;

  const currentZone = vacationPay?.zones?.mainZone?.zone || 'ZONE_1';

  const rows = useMemo(
    () => vacationPayFormatter(vacationPayGLBase, vacationPay),
    [vacationPayGLBase, vacationPay],
  );

  const currentMainPercentage =
    vacationPay?.calculation?.vpElements?.standard[0]?.percentage || 0;

  const fetchData = async ({
    skipGLBase = false,
    skipCalculations = false,
  } = {}) => {
    const options = {
      companyId: currentCompanySID,
      period,
      year: accountingYear,
      lang: user.languageCode || 'en',
      periodType: bimonthly ? 'BIMONTHLY' : 'MONTHLY',
    };

    const actions = [];

    if (!skipCalculations) {
      setCalculationLoading(() => true);
      actions.push(
        dispatch(
          resourceListReadRequest(getVacationPayCalculationApi, options),
        ),
      );
    }
    if (!skipGLBase) {
      setGLBaseLoading(() => true);
      actions.push(
        dispatch(resourceListReadRequest(getVacationPayGLBaseApi, options)),
      );
    }

    try {
      await Promise.all(actions);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setCalculationLoading(() => false);
      setGLBaseLoading(() => false);
    }
  };

  const handleAmountSave = async (id, amount, type, zoneId = null) => {
    if (type === 'ZONE') {
      if (id === null) {
        try {
          await dispatch(
            resourceCreateRequest(
              `${getVacationPayZonesApi}/${zoneReportId}/row`,
              {
                zone: zoneId,
                amount,
              },
            ),
          );
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        return;
      }
      try {
        await dispatch(
          resourceUpdateRequest(editAdditionalZoneApi, id, { amount }),
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
      return;
    }
    try {
      await dispatch(
        resourceUpdateRequest(editAdditionalRowApi, id, { amount }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleDelete = async (row, type) => {
    if (type === 'ZONE') {
      try {
        await dispatch(
          resourceDeleteRequest(`${editAdditionalZoneApi}/${row.id}`),
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
      return;
    }
    try {
      await dispatch(
        resourceUpdateRequest(editAdditionalRowApi, row.id, { amount: 0 }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const refetch = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [currentCompanySID, accountingYear, term]);

  return {
    rows,
    glBaseLoading,
    calculationLoading,
    zonesLoading: calculationLoading,
    currentMainPercentage,
    currentZone,
    zoneReportId,
    refetch,
    fetchData,
    handleDelete,
    handleAmountSave,
  };
};

export default useVacationPayData;
