import { t } from '@lingui/macro';
import { sortVatGroupTypes } from 'services/helpers';

export const columns = () => [
  {
    width: 30,
    Header: t`Company Name`,
    accessor: 'companyName',
    className: '',
    withTooltip: true,
  },
  {
    width: 60,
    Header: t`Group Type`,
    accessor: 'groupType',
    sortType: sortVatGroupTypes,
    withTooltip: true,
  },
  {
    width: 15,
    Header: t`Account`,
    accessor: 'accountId',
    className: '',
    cell: {
      type: 'number',
      action: () => 'toDrilldown',
    },
  },
  {
    width: 60,
    Header: t`Account Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 20,
    Header: t`VAT Code`,
    accessor: 'code',
    className: '',
  },
  {
    width: 20,
    Header: t`Incoming %`,
    accessor: 'ingoingTaxRate',
    className: '',
    cell: {
      type: 'number',
      converter: (value) => {
        const multiplied = value * 100;
        const roundedValue = multiplied.toFixed(3);
        const parsedValue = parseFloat(roundedValue);
        return parsedValue.toString() || parsedValue === 0
          ? `${parsedValue.toString()} %`
          : '';
      },
    },
  },
  {
    width: 20,
    Header: t`Incoming Pro-Rata %`,
    accessor: 'ingoingProRataTaxRate',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => {
        const multiplied = value * 100;
        const roundedValue = multiplied.toFixed(3);
        const parsedValue = parseFloat(roundedValue);
        return parsedValue.toString() || parsedValue === 0
          ? `${parsedValue.toString()} %`
          : '';
      },
    },
  },
  {
    width: 20,
    Header: t`Outgoing %`,
    accessor: 'outgoingTaxRate',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => {
        const multiplied = value * 100;
        const roundedValue = multiplied.toFixed(3);
        const parsedValue = parseFloat(roundedValue);
        return parsedValue.toString() || parsedValue === 0
          ? `${parsedValue.toString()} %`
          : '';
      },
    },
  },
  {
    width: 20,
    Header: t`Outgoing Pro-Rata %`,
    accessor: 'outgoingProRataTaxRate',
    className: '',
    cell: {
      type: 'input',
      inputType: 'number',
      converter: (value) => {
        const multiplied = value * 100;
        const roundedValue = multiplied.toFixed(3);
        const parsedValue = parseFloat(roundedValue);
        return parsedValue.toString() || parsedValue === 0
          ? `${parsedValue.toString()} %`
          : '';
      },
    },
  },
  {
    width: 35,
    Header: t`Base Amount`,
    sortType: 'basic',
    accessor: 'baseAmount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 35,
    Header: t`Incoming Tax Amount`,
    sortType: 'basic',
    accessor: 'ingoingTaxAmount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 35,
    Header: t`Outgoing Tax Amount`,
    sortType: 'basic',
    accessor: 'outgoingTaxAmount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
