import { t } from '@lingui/macro';

import {
  a07ReportInfoColumns,
  vacationPaySSColumns,
  a07IncomeTypesColumns,
  payrollOutgoingBalance,
  a07ReportTotalsColumns,
  vacationPayBasisColumns,
  payrollWithheldTaxColumns,
  payrollReportedSalaryColumns,
  payrollBalanceReconciliation,
  payrollPaymentReconciliation,
  a07SocialSecuritySpecColumns,
  vacationPayBasisCalculationColumns,
  payrollReportedSalaryDetailsColumns,
} from 'services/tableshapes';

import useReportGenerator from './generator/useReportGenerator';

export const getPayrollReportSettings = ({
  company,
  getFiles,
  orgNumber,
  bimonthly,
  accumulated,
  onlyNumbers,
  activePeriods,
  accountingYear,
  payrollApproved,
  a07Data,
  PRandOBdata,
  withheldTaxData,
  vacationPayData,
  dataSocialSecurity,
  reportedSalaryData,
  reportedSalaryDetailsData,
}) => {
  const loading =
    a07Data.loading ||
    PRandOBdata.loading ||
    vacationPayData.glBaseLoading ||
    vacationPayData.calculationLoading ||
    vacationPayData.zonesLoading ||
    withheldTaxData.loading ||
    dataSocialSecurity.loading ||
    reportedSalaryData.loading ||
    reportedSalaryDetailsData.loading;

  const shapes = {
    reportedSalaryDetails: payrollReportedSalaryDetailsColumns({}),
    balanceReconciliation: payrollBalanceReconciliation(),
    paymentReconciliation: payrollPaymentReconciliation(true, true),
    outgoingBalance: payrollOutgoingBalance(),
    withheldTax: payrollWithheldTaxColumns(),
    vacationPayBasis: vacationPayBasisColumns(),
    vacationPayBasisCalculation: vacationPayBasisCalculationColumns({}),
    vacationPaySS: vacationPaySSColumns(),
    a07ReportTotals: a07ReportTotalsColumns(),
    a07SocialSecuritySpec: a07SocialSecuritySpecColumns(accumulated),
    a07ReportInfo: a07ReportInfoColumns(),
    a07IncomeTypes: a07IncomeTypesColumns(accumulated),
  };

  const reportedSalaryShape = payrollReportedSalaryColumns({
    manual: reportedSalaryData.manual,
  });

  const term = Math.ceil(+activePeriods / 2);

  const name = bimonthly ? t`Term` : t`Period`;
  let period = bimonthly ? term : +activePeriods;

  if (accumulated && period !== 1) {
    period = `1-${period}`;
  }

  const periodLabel = {
    // Can be monthly, bimonthly, accumulated, not accumulated
    general: `${name} ${period}, ${accountingYear}`,
    // Can be monthly, bimonthly
    tax: `${name} ${bimonthly ? term : +activePeriods}, ${accountingYear}`,
    // Can be only bimonthly
    vacationPay: `${t`Term`} ${term}, ${accountingYear}`,
  };

  return {
    loading,
    getFiles,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(activePeriods, 10),
      reportType: 'PAYROLL',
    },
    folderName: t`payroll`,
    fileName: t`${company} (${orgNumber}) Payroll Report - ${accountingYear}`,
    isApproved: payrollApproved,
    data: [
      // Page 1: Reported Salary
      [
        {
          period: periodLabel.general,
          rows: reportedSalaryData.rows,
          withTotal: true,
          checkboxes: {
            [t`Show manual input`]: reportedSalaryData.manual,
            [t`Show only rows with numbers`]: onlyNumbers,
          },
          title: t`Reported Salary`,
          tableShape: reportedSalaryShape,
        },
      ],
      // Page 2: Reported Salary Details
      [
        {
          period: periodLabel.general,
          withGroups: true,
          rows: reportedSalaryDetailsData.rows,
          title: t`Reported Salary Details`,
          tableShape: shapes.reportedSalaryDetails,
          additionalTotalRows: reportedSalaryDetailsData.additionalTotalRows,
          predefinedTotal: reportedSalaryDetailsData.predefinedTotal,
        },
      ],
      // Page 3: Social Security
      [
        {
          period: periodLabel.general,
          rows: dataSocialSecurity.rows,
          title: t`Balance Reconciliation`,
          tableShape: shapes.balanceReconciliation,
        },
        {
          rows: PRandOBdata.rows.ssPaymentReconciliation.items,
          predefinedTotal:
            PRandOBdata.rows.ssPaymentReconciliation.predefinedTotal,
          title: t`Social Security - Payment Reconciliation`,
          tableShape: shapes.paymentReconciliation,
        },
        {
          rows: PRandOBdata.rows.ssOutgoingBalance,
          title: t`Social Security - Outgoing Balance`,
          tableShape: shapes.outgoingBalance,
        },
      ],
      // Page 4: Withheld Tax
      [
        {
          period: periodLabel.tax,
          rows: withheldTaxData.rows,
          title: t`Withheld Tax`,
          tableShape: shapes.withheldTax,
        },
        {
          rows: PRandOBdata.rows.wtPaymentReconciliation.items,
          predefinedTotal:
            PRandOBdata.rows.wtPaymentReconciliation.predefinedTotal,
          title: t`Withheld Tax - Payment Reconciliation`,
          tableShape: shapes.paymentReconciliation,
        },
        {
          rows: PRandOBdata.rows.wtOutgoingBalance,
          title: t`Withheld Tax - Outgoing Balance`,
          tableShape: shapes.outgoingBalance,
        },
      ],
      // Page 5: Financial Tax
      [
        {
          period: periodLabel.tax,
          rows: PRandOBdata.rows.ftPaymentReconciliation.items,
          predefinedTotal:
            PRandOBdata.rows.ftPaymentReconciliation.predefinedTotal,
          title: t`Financial Tax - Payment Reconciliation`,
          tableShape: shapes.paymentReconciliation,
        },
        {
          rows: PRandOBdata.rows.ftOutgoingBalance,
          title: t`Financial Tax - Outgoing Balance`,
          tableShape: shapes.outgoingBalance,
        },
      ],
      // Page 6: Vacation Pay
      [
        {
          period: periodLabel.vacationPay,
          withTotal: true,
          totalName: t`SUM Vacation pay basis:`,
          rows: vacationPayData.rows.firstView,
          title: t`Vacation Pay from General Ledger`,
          tableShape: shapes.vacationPayBasis,
        },
        {
          rows: vacationPayData.rows.secondView,
          title: t`Basis Calculation`,
          tableShape: shapes.vacationPayBasisCalculation,
        },
        {
          rows: vacationPayData.rows.thirdView,
          title: t`Vacation Pay - Social Security`,
          tableShape: shapes.vacationPaySS,
        },
      ],
      [
        {
          rows: a07Data.rows.reportTotal,
          title: t`A07 Reports - Report Totals`,
          tableShape: shapes.a07ReportTotals,
          period: periodLabel.general,
        },
        {
          withTotal: true,
          rows: a07Data.rows.socialSecuritySpecs,
          title: t`A07 Reports - Social Security Specification`,
          tableShape: shapes.a07SocialSecuritySpec,
        },
        {
          rows: a07Data.rows.reportInfo,
          title: t`A07 Reports - Report Info`,
          tableShape: shapes.a07ReportInfo,
        },
        {
          withTotal: true,
          rows: a07Data.rows.incomeTypes,
          title: t`A07 Reports - Income Types`,
          tableShape: shapes.a07IncomeTypes,
        },
      ],
    ],
  };
};

export const usePayrollReportGenerator = (props) =>
  useReportGenerator(getPayrollReportSettings(props));

export default usePayrollReportGenerator;
