import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';

import CardPDFTitle from 'containers/CardPDFTitle';
import { ButtonIcon, CustomTableWithAutoSettings } from 'components';

import { payrollSettingsMappingColumns } from 'services/tableshapes';
import { payrollSettingsMappingFilters } from 'services/tableFilterShapes';

function PayrollSettingsMapping(props) {
  const {
    data,
    title,
    search,
    loading,
    collections,
    handleAction,
    actionSubmitting,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'accountID',
        desc: false,
      },
    ],
    [],
  );

  return (
    <div id="pdfToPrint" data-testid="pdfToPrint-payrollSettingsMapping">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          columns: payrollSettingsMappingColumns({ ...search, collections }),
          tableId: 'payrollSettingsMapping',
          loadingId: 'accountID',
          reportMode: search.reportMode,
          filterConfigs: payrollSettingsMappingFilters(),
          headerConfigs: {
            exportFileName: title,
            extraButtons: [
              {
                id: 'copySettings',
                icon: <ButtonIcon as={FaCopy} type="extraButton" />,
                title: t`Copy company settings`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
          initialState: {
            sortBy,
          },
        }}
      />
    </div>
  );
}

export default PayrollSettingsMapping;
