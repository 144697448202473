import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaSave } from '@react-icons/all-files/fa/FaSave';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

import {
  Card,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { simpleLedgerColumns } from 'services/tableshapes';
import { simpleLedgerFilters } from 'services/tableFilterShapes';

/**
 * Simple Ledger Template component
 */
function SimpleLedgerTemplate({
  rows,
  title,
  loading,
  roleType,
  handleAction,
  deviationRows,
  actionSubmitting,
}) {
  const getRowColor = (row) => row.editable && 'selected';

  return (
    <Card>
      <Heading level={4} alignment="center">
        {t`Simple Ledger Template`}
      </Heading>
      <div>
        <CustomTableWithAutoSettings
          {...{
            data: rows,
            loading,
            getRowColor,
            handleAction,
            actionSubmitting,
            tableId: 'simpleLedgerTemplate',
            groupId: 'group',
            loadingId: 'row',
            enableGroups: true,
            columns: simpleLedgerColumns(roleType.isReadOnly),
            minHeight: rows.length * 60,
            headerConfigs: {
              exportFileName: title,
              extraButtons: [
                {
                  id: 'add',
                  icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                  title: t`Add Row`,
                  buttonProps: {
                    size: 'sm',
                    kind: 'default',
                    fill: false,
                    magnify: true,
                  },
                },
                {
                  id: 'save',
                  icon: <ButtonIcon as={FaSave} type="extraButton" />,
                  title: t`Save Rows`,
                  buttonProps: {
                    disabled: !deviationRows.length || roleType.isReadOnly,
                    size: 'sm',
                    kind: 'success',
                    fill: false,
                    magnify: true,
                  },
                },
                {
                  id: 'clear',
                  icon: <ButtonIcon as={BiTrash} type="extraButton" />,
                  title: t`Clear All Rows`,
                  buttonProps: {
                    disabled: !deviationRows.length || roleType.isReadOnly,
                    size: 'sm',
                    kind: 'danger',
                    fill: false,
                    magnify: true,
                  },
                },
              ],
            },
            filterConfigs: simpleLedgerFilters(),
          }}
        />
      </div>
    </Card>
  );
}

SimpleLedgerTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  roleType: PropTypes.shape({}).isRequired,
  handleAction: PropTypes.func.isRequired,
  deviationRows: PropTypes.arrayOf(PropTypes.shape({})),
  actionSubmitting: PropTypes.shape({}).isRequired,
};

SimpleLedgerTemplate.defaultProps = {
  loading: true,
  deviationRows: [],
};

export default SimpleLedgerTemplate;
