import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { CardPDFTitle } from 'components';

import { fromCompany } from 'store/selectors';

export function CardPDFTitleContainer(props) {
  const { title, defaultNoTitle } = props;
  const params = useParams();
  const location = useLocation();
  const { reportMode } = queryString.parse(location.search, {
    parseBooleans: true,
  });
  const company = useSelector((state) => fromCompany.getCompany(state));

  if (!reportMode && defaultNoTitle) {
    return false;
  }

  return (
    <CardPDFTitle
      {...{
        title,
        params,
        company,
        reportMode,
      }}
    />
  );
}

export default CardPDFTitleContainer;

CardPDFTitleContainer.propTypes = {
  title: PropTypes.shape({}),
  defaultNoTitle: PropTypes.bool,
};

CardPDFTitleContainer.defaultProps = {
  title: '',
  defaultNoTitle: false,
};
