import React from 'react';
import styled from 'styled-components/macro';
import { t } from '@lingui/macro';
import { IoMdCalendar } from '@react-icons/all-files/io/IoMdCalendar';

import { ButtonGroup, Button } from 'react-bootstrap';

export default function DeadlineCalendarModal() {
  return (
    <ButtonGroup bsSize="small">
      <HelpButton
        fill
        onClick={() => alert('soon')}
        title={t`Deadline Calendar`}
      >
        <CalIcon />
      </HelpButton>
    </ButtonGroup>
  );
}
const CalIcon = styled((props) => <IoMdCalendar {...props} />)`
  font-size: 18px;
  margin-bottom: -4px;
  color: #fff;
`;

const HelpButton = styled(Button)`
  background: #b1b1b1 !important;
  border-color: #b1b1b1 !important;
  padding: 4.5px 8px !important;

  :hover {
    background: #737373 !important;
    border-color: #737373 !important;
  }
`;
