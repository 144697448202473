import React from 'react';

import { OutgoingBalance, PaymentReconciliation } from 'components';

function PayrollFinancialTax(props) {
  const { roleType, toggleModal, PRandOBdata } = props;
  const { rows, loading } = PRandOBdata;
  const { ftPaymentReconciliation, ftOutgoingBalance } = rows;

  return (
    <>
      <PaymentReconciliation
        data={ftPaymentReconciliation}
        {...{
          loading,
          roleType,
          toggleModal,
          tableId: 'payrollFTPPaymentReconciliation',
        }}
      />
      <OutgoingBalance
        data={ftOutgoingBalance}
        {...{
          loading,
          tableId: 'payrollFTPOutgoingBalance',
        }}
      />
    </>
  );
}

export default PayrollFinancialTax;
