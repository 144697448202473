import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { resourceListReadRequest } from 'store/actions';
import { getBankAccountDetails } from 'services/apihelpers';
import { fromResource } from 'store/selectors';
import { bankTemplateDataFormatter } from 'services/dataFormatters/accounts';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useBankTemplateData = (props) => {
  const {
    account,
    companyId,
    setGlTotal,
    setBankTotal,
    accountingYear,
    showCurrentMonth,
    accountingPeriod,
    currentPeriodType,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, `${getBankAccountDetails}/${account}`),
  );

  const { dataGL, dataBank, bankId } = useMemo(
    () =>
      bankTemplateDataFormatter({
        setGlTotal,
        setBankTotal,
        showCurrentMonth,
        accountingPeriod,
        data: data?.bankReconView,
      }),
    [data, showCurrentMonth, accountingPeriod],
  );

  const fetchData = async () => {
    setLoading(() => true);

    try {
      // Fetch BANK data
      await dispatch(
        resourceListReadRequest(`${getBankAccountDetails}/${account}`, {
          companyId,
          year: accountingYear,
          period: accountingPeriod,
          periodType: currentPeriodType.toUpperCase(),
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account, companyId, accountingYear, accountingPeriod, currentPeriodType]);

  return {
    data,
    dataGL,
    bankId,
    loading,
    dataBank,
    fetchData,
    accountInfo: data?.account,
    bookedBalance: data?.bankReconView?.bookedBalance,
  };
};

export default useBankTemplateData;
