import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { RiDatabase2Fill } from '@react-icons/all-files/ri/RiDatabase2Fill';
import { RiCalendarCheckLine } from '@react-icons/all-files/ri/RiCalendarCheckLine'; // eslint-disable-line max-len
import { RiCheckboxCircleFill } from '@react-icons/all-files/ri/RiCheckboxCircleFill'; // eslint-disable-line max-len

import { DESKTOP, LARGE_SCREENS, FOUR_K } from 'constants/mediaSizes';

import altinnLogo from 'assets/images/altinn.svg';

const colors = ['#34ba84', '#f78851', '#0890fe'];

// eslint-disable-next-line default-param-last
const items = (lastActivity = {}, accountingSystem) => {
  const { approved, retrievedFromAccountingSystem, retrievedFromAltinn } =
    lastActivity;

  const approvedTimestamp =
    (approved?.date &&
      approved?.time &&
      new Date(`${approved.date}T${approved.time}Z`)) ||
    null;
  const retrievedFromAccountingSystemTimestamp =
    (retrievedFromAccountingSystem?.date &&
      retrievedFromAccountingSystem?.time &&
      new Date(
        // eslint-disable-next-line max-len
        `${retrievedFromAccountingSystem.date}T${retrievedFromAccountingSystem.time}Z`,
      )) ||
    null;
  const retrievedFromAltinnTimestamp =
    (retrievedFromAltinn?.date &&
      retrievedFromAltinn?.time &&
      new Date(
        // eslint-disable-next-line max-len
        `${retrievedFromAltinn.date}T${retrievedFromAltinn.time}Z`,
      )) ||
    null;

  return [
    {
      icon: <RiCheckboxCircleFill className="activity-icon" />,
      title: t`Last account approved:`,
      timestamp: approvedTimestamp,
      date: approved?.date,
      author: approved?.approvedBy,
      time: approved?.time?.slice(0, 5),
      hasValues: approved?.approvedBy,
    },
    {
      icon: <RiDatabase2Fill className="activity-icon" />,
      title: t`Data retrieved from ${accountingSystem}:`,
      timestamp: retrievedFromAccountingSystemTimestamp,
      date: retrievedFromAccountingSystem?.date,
      author: retrievedFromAccountingSystem?.retrievedBy,
      time: retrievedFromAccountingSystem?.time?.slice(0, 5),
      hasValues: retrievedFromAccountingSystem?.retrievedBy,
    },
    {
      icon: <Altinn src={altinnLogo} alt="" />,
      title: t`Data retrieved from Altinn:`,
      timestamp: retrievedFromAltinnTimestamp,
      date: retrievedFromAltinn?.date,
      author: retrievedFromAltinn?.retrievedBy,
      time: retrievedFromAltinn?.time?.slice(0, 5),
      hasValues: retrievedFromAltinn?.retrievedBy,
    },
  ];
};

function DashboardLastActivity(props) {
  const { accountingSystem, lastActivity } = props;

  return (
    <Container>
      {items(lastActivity, accountingSystem).map((item, i) => (
        <Item key={`activity-${i}`} delay={`${(i + 1) * 0.3}s`}>
          <ActivityItem>
            <ActivityItemMain>
              <ActivityIcon background={colors[i]}>{item.icon}</ActivityIcon>
              <ActivityDescription>
                <SubTitle>{item.title}</SubTitle>
                <Author>
                  <DateBlock>
                    {item.hasValues ? (
                      <>
                        <RiCalendarCheckLine />
                        <div className="approved-date">
                          {item.timestamp?.toLocaleDateString([], {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          })}{' '}
                          {t`by`} {item.author}
                        </div>
                      </>
                    ) : (
                      <div className="approved-date">{t`No data found`}</div>
                    )}
                  </DateBlock>
                </Author>
              </ActivityDescription>
            </ActivityItemMain>
            {item.hasValues && (
              <Time color={colors[i]}>
                {item.timestamp?.toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Time>
            )}
          </ActivityItem>
        </Item>
      ))}
    </Container>
  );
}

export default React.memo(DashboardLastActivity);

const ActivityItem = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  width: 100%;
  color: #585858;
  align-items: center;
  padding: 0.5em 0.7em;
  border-radius: 5px;

  @media (max-width: ${DESKTOP.max}px) {
    padding: 0.6em 0.7em;
  }
`;

const ActivityItemMain = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ActivityIcon = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  font-size: 1.25em;
  margin-right: 0.8em;
  color: white;
  background: ${({ background }) => background};

  .activity-icon {
    font-size: 1.3em !important;
  }

  @media (max-width: ${DESKTOP.max}px) {
    width: 2.08em;
    height: 2.08em;
    font-size: 1.2em;
    margin-right: 0.7em;
  }
`;

const ActivityDescription = styled.div`
  width: calc(100% - 3em);
  overflow: hidden;
`;

const Time = styled.span`
  font-weight: 700;
  padding: 0.4em 1.15em 0.4em 1.4em;
  font-size: 1.14em;
  letter-spacing: 0.2px;
  border-radius: 20px;
  color: #2f3a4d;
  position: relative;
  text-align: right;

  &:after {
    position: absolute;
    content: '';
    width: 0.6em;
    height: 0.6em;
    background-color: ${({ color }) => color};
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 1.1em;
    padding: 0.4em 1em;
  }
`;

const DateBlock = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.14em;
  overflow: hidden;

  .approved-date {
    overflow: hidden;
    line-height: 1.1em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  svg {
    font-size: 1.2em;
    margin-right: 0.15em;
  }
`;

const Author = styled.div`
  color: rgb(191 191 191);
  font-size: 1em;
  display: flex;
  align-items: center;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 0.95em;
  }
`;

const SubTitle = styled.h6`
  margin: 2px 0 3px;
  padding: 0;
  color: #2f3a4d;
  text-transform: none;
  font-size: 1.07em;
  font-weight: bold;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 1.04em;
    margin: 1px 0 2px;
  }
`;

const Altinn = styled.img`
  width: 1.1em;

  @media (max-width: ${DESKTOP.max}px) {
    width: 1em;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1.15vh;
  flex: 1;

  @media (max-width: ${DESKTOP.max}px) {
    gap: 1vh;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    gap: 1.1vh;
  }
`;

const Item = styled.div`
  flex-basis: 100%;
  display: flex;
  animation: move ${({ delay }) => delay} ease-in;
  background: white;

  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;
  font-size: 14px;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 13px;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 13.4px;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
  }

  @keyframes move {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
