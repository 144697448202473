import { t } from '@lingui/macro';

import { filterByGlTypes, periodTypes } from 'services/shapes';

export const trialBalanceReportFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['accountDescription', 'accountNumber'],
  },
  {
    id: 'accountCategory',
    type: 'multiSelect',
    label: t`Categories`,
    options: filterByGlTypes(),
    disableSort: true,
    placeholder: t`Please select Category`,
  },
  {
    id: 'periodTypeCustom',
    type: 'multiSelect',
    label: t`Period Type`,
    single: true,
    options: periodTypes(),
    disableSort: true,
    placeholder: t`Please select Period Type`,
  },
  {
    id: 'accumulatedCustom',
    type: 'boolean',
    label: t`Accumulated`,
  },
];

export default trialBalanceReportFilters;
