import React, { useState, useEffect, useMemo } from 'react';
import _isEqual from 'lodash-es/isEqual';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import cx from 'classnames';
import { ButtonGroup, Modal } from 'react-bootstrap';

import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import styled from 'styled-components';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  ButtonIcon,
  Heading,
  Button,
  Dropdown,
  Field,
  Spinner,
  Paragraph,
  DraggableItem,
} from 'components';

import {
  resourceListReadRequest,
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceDeleteRequest,
} from 'store/actions';
import { bankReconcileSettingsApi } from 'services/apihelpers';
import { fromResource } from 'store/selectors';

import getErrorMessage from 'services/helpers/getErrorMessage';

import MainRulesContainer from './MainRulesContainer';
import ReconciliationPreviewModal from './PreviewModal';
import ImportSettingsModal from './ImportSettingsModal';
import getQuery from './getQuery';

const options = [
  {
    id: 'ONE_TO_ONE',
    title: t`One to one`,
    tooltip: t`Matching General Ledger and Bank transactions, on a
    single General Ledger transaction that equals a single Bank
    transaction. Example: (GL) 149.98 = 149.98 (Bank)`,
  },
  {
    id: 'ONE_TO_MANY',
    title: t`One to many`,
    tooltip: t`Matching General Ledger and Bank transactions, on a
    single General Ledger transaction that equals multiple Bank
    transactions. Example: (GL) 149.98 = 79.98 + 70 (Bank)`,
  },
  {
    id: 'MANY_TO_ONE',
    title: t`Many to one`,
    tooltip: t`Matching General Ledger and Bank transactions, on multiple
    General Ledger transactions that equals a single Bank transaction. 
    Example: (GL) 79.98 + 70 = 149.98 (Bank)`,
  },
  {
    id: 'MANY_TO_MANY',
    title: t`Many to many`,
    tooltip: t`Matching General Ledger and Bank transactions, on multiple
    General Ledger transactions, that equals multiple Bank transactions.
    Example: (GL) 79.98 + 70 = 49.98 + 30 + 70 (Bank)`,
  },
];

function CreateRuleSetModal(props) {
  const { toggleModal, companyId, accountId } = props;
  const dispatch = useDispatch();

  const [isPreviewOpened, setPreviewOpened] = useState(false);
  const [showImportSettings, setShowImportSettings] = useState(false);
  const [loadings, setLoadings] = useState({ fetch: false, save: false });
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const tooltips = useMemo(
    () => ({
      priority: t`The priority number on filter rule, in filter rule chain`,
      name: t`Name of the filter rule`,
      // eslint-disable-next-line max-len
      description: t`Description of the filter rule. Use this to explain how and for what purpose the filter rule is used for.`,
      dateRange: t`Finds transaction within the set date range, in amount of +
      days ahead. Example: A General Ledger transaction was registered in 01.01,
      and we assume that the corresponding Bank transaction could appear on the
      dates 01.01, 02.01 or 03.01. Then, input in Date Range is = 3 days.`,
      offset: t`Finds transaction starting from Offset within used Date Range.
      Example: A General Ledger transaction was on 01.01, and we assume that
      is could appear in bank in 2 weeks, combined with Date Range =  3 days,
      on the dates 14.01, 15.01, 16.01. Then, input in Offset is = 14 days,
      and input in Date Range value =  3 days.`,
    }),
    [],
  );

  const globalLoading = loadings.fetch || loadings.save;

  const data = useSelector((state) =>
    fromResource.getList(state, bankReconcileSettingsApi),
  );
  const activeRule = useMemo(() => {
    if (activeTab && tabs) {
      return tabs.find((tab) => tab.configId === activeTab);
    }

    return null;
  }, [activeTab, tabs]);

  const activeRulePriority = tabs.indexOf(activeRule) + 1;
  const activeRuleType = useMemo(() => {
    if (activeRule) {
      return options.find(
        (item) => item.id === activeRule.reconciliationFilterType,
      )?.title;
    }

    return null;
  }, [activeRule]);

  const leftTextSelected = activeRule?.leftSelectedType === 'TEXT';
  const leftRegexSelected = activeRule?.leftSelectedType === 'REGEX';
  const rightTextSelected = activeRule?.rightSelectedType === 'TEXT';
  const rightRegexSelected = activeRule?.rightSelectedType === 'REGEX';

  const previewDisabled = useMemo(
    () =>
      tabs.some((item) => {
        const {
          offset,
          dateRange,
          regexRight,
          regexLeft,
          isDaterange,
          isDaterangeDate,
          rightSelectedType,
          leftSelectedType,
          textMatchLeft,
          textMatchRight,
          reconciliationFilterType,
        } = item;

        if (
          reconciliationFilterType !== 'ONE_TO_ONE' &&
          !(isDaterange || isDaterangeDate)
        ) {
          return true;
        }

        const rightRegexEmpty = rightSelectedType === 'REGEX' && !regexRight;
        const leftRegexEmpty = leftSelectedType === 'REGEX' && !regexLeft;

        const leftTextIncorrect = leftSelectedType === 'TEXT' && !textMatchLeft;

        const rightTextIncorrect =
          rightSelectedType === 'TEXT' && !textMatchRight;

        const incorrectDateRange =
          typeof dateRange !== 'number' || dateRange < 0;
        const incorrectOffset = typeof offset !== 'number' || offset < 0;

        return (
          rightRegexEmpty ||
          leftRegexEmpty ||
          leftTextIncorrect ||
          rightTextIncorrect ||
          incorrectDateRange ||
          incorrectOffset
        );
      }) || globalLoading,
    [tabs, globalLoading],
  );

  const disabled = useMemo(() => {
    if (_isEqual(data?.configParts, tabs)) {
      return true;
    }

    return previewDisabled;
  }, [data, tabs, previewDisabled]);

  const onItemClick = (tab) => {
    const id = Math.floor(Math.random() * Date.now()).toString(16);

    setTabs([
      ...tabs,
      {
        configId: id,
        reconciliationFilterType: tab.id,
        name: t`Rule ${tabs.length + 1}`,
        dateRange: 3,
        offset: 0,
        regexLeft: null,
        regexRight: null,
        textMatchLeft: null,
        textMatchRight: null,
        description: null,
        rightSelectedType: null,
        leftSelectedType: null,
        isDaterange: false,
        isDaterangeDate: false,
      },
    ]);

    setActiveTab(id);
  };

  const fetchConfigs = async () => {
    setLoadings((prev) => ({ ...prev, fetch: true }));

    try {
      await dispatch(
        resourceListReadRequest(bankReconcileSettingsApi, {
          companyId,
          accountId,
        }),
      );
    } catch (e) {
      // .error(getErrorMessage(e));
    } finally {
      setLoadings((prev) => ({ ...prev, fetch: false }));
    }
  };

  const onInputChange = (e) => {
    const { id } = e.currentTarget;
    const { value } = e.target;

    const isNumberField = ['dateRange', 'offset'].includes(id);

    setTabs((prevState) =>
      prevState.map((item) => {
        if (item.configId === activeTab) {
          return {
            ...item,
            [id]: isNumberField && value ? +value : value,
          };
        }

        return item;
      }),
    );
  };

  const onCheckboxChange = (id, value, extraFields = {}) => {
    const rangeFields = ['isDaterange', 'isDaterangeDate'];

    setTabs((prevState) =>
      prevState.map((item) => {
        if (item.configId === activeTab) {
          return {
            ...item,
            [id]: rangeFields.includes(id) ? !item[id] : value,
            ...extraFields,
          };
        }
        return item;
      }),
    );
  };

  const handleRuleRemove = (id) => {
    const newTabs = tabs.filter((item) => item.configId !== id);
    setTabs(newTabs);

    if (id === activeTab) {
      setActiveTab(newTabs.length ? newTabs[0].configId : null);
    }
  };

  const onSave = async () => {
    setLoadings((prev) => ({ ...prev, save: true }));
    try {
      if (!tabs.length) {
        await dispatch(
          resourceDeleteRequest(`${bankReconcileSettingsApi}/${data?.orderId}`),
        );
      } else if (data?.companyId && data?.accountId) {
        await dispatch(
          resourceUpdateRequest(
            `${bankReconcileSettingsApi}/${companyId}`,
            null,
            getQuery(tabs, accountId, data?.orderId),
          ),
        );
      } else {
        await dispatch(
          resourceCreateRequest(
            `${bankReconcileSettingsApi}/${companyId}`,
            getQuery(tabs, accountId),
          ),
        );
      }

      toggleModal();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoadings((prev) => ({ ...prev, save: false }));
    }
  };

  const handlePriorityChange = (e) => {
    const {
      target: { value },
    } = e;

    if (+value !== activeRulePriority) {
      const newTabs = [...tabs];

      const currentIndex = activeRulePriority - 1;
      const indexToMove = +value - 1;

      newTabs.splice(currentIndex, 1);
      newTabs.splice(indexToMove, 0, activeRule);

      setTabs(newTabs);
    }
  };

  const onSettingsApply = (configParts) => {
    setTabs(configParts);
    setActiveTab(configParts[0].configId);
  };

  useEffect(() => {
    fetchConfigs();
  }, [companyId, accountId]);

  useEffect(() => {
    if (data?.configParts?.length) {
      setTabs(data.configParts);
      setActiveTab(data.configParts[0].configId);
      return;
    }

    setTabs([]);
    setActiveTab(null);
  }, [data]);

  return (
    <>
      <ModalStyles
        show={!isPreviewOpened && !showImportSettings}
        onEscapeKeyDown={() => toggleModal()}
      >
        <Modal.Header>
          <Header>
            <Heading level={3}>
              <Trans>Reconciliation Settings</Trans>
            </Heading>
            <div>
              <Dropdown
                id="rules-dropdown"
                options={options}
                disabled={globalLoading}
                title={t`+ Add rule`}
                onItemClick={onItemClick}
              />
              <Button
                magnify
                style={{ marginLeft: 5 }}
                onClick={() => setShowImportSettings(true)}
              >
                <Trans>Import Settings</Trans>
              </Button>
            </div>
          </Header>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: 25, paddingRight: 25 }}>
          {loadings.fetch ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <>
              {tabs.length ? (
                <Header>
                  <DndProvider backend={HTML5Backend}>
                    <Tabs>
                      {tabs.map((tab, index) => (
                        <DraggableItem
                          id={tab.configId}
                          items={tabs}
                          index={index}
                          changeState={setTabs}
                          key={`item-${tab.configId}`}
                        >
                          <TabItemContainer key={tab.configId}>
                            <TabItem
                              onClick={() => setActiveTab(tab.configId)}
                              className={cx({
                                active: activeTab === tab.configId,
                              })}
                            >
                              <p className="priority">{index + 1}</p>
                              <span>{tab.name || '-'}</span>
                            </TabItem>
                            <BiTrash
                              onClick={() => handleRuleRemove(tab.configId)}
                            />
                          </TabItemContainer>
                        </DraggableItem>
                      ))}
                    </Tabs>
                  </DndProvider>
                </Header>
              ) : (
                <Paragraph alignment="center">
                  <Trans>No rules were added yet</Trans>
                </Paragraph>
              )}
              {activeTab && activeRule && (
                <TabBody>
                  <div>
                    <b>Type:</b> {activeRuleType}
                  </div>
                  <Field
                    noBorder
                    size="sm"
                    name="priority"
                    type="select"
                    tooltip={tooltips.priority}
                    value={activeRulePriority}
                    label={t`Priority`}
                    onChange={handlePriorityChange}
                  >
                    {Array.from(Array(tabs.length).keys()).map((item) => (
                      <option value={item + 1} key={`option-${item + 1}`}>
                        {item + 1}
                      </option>
                    ))}
                  </Field>
                  <Field
                    id="name"
                    size="sm"
                    type="text"
                    label={t`Name`}
                    tooltip={tooltips.name}
                    value={activeRule.name || ''}
                    onChange={onInputChange}
                    placeholder={t`Name`}
                  />
                  <Field
                    id="description"
                    size="sm"
                    type="text"
                    label={t`Description`}
                    tooltip={tooltips.description}
                    value={activeRule.description || ''}
                    onChange={onInputChange}
                    placeholder={t`Rule description`}
                  />
                  <DifferentValuesHolder>
                    <MainRulesContainer
                      name="GL"
                      text={activeRule.textMatchLeft}
                      regex={activeRule.regexLeft}
                      textSelected={leftTextSelected}
                      regexSelected={leftRegexSelected}
                      onInputChange={onInputChange}
                      onCheckboxChange={onCheckboxChange}
                    />
                    <MainRulesContainer
                      name="Bank"
                      text={activeRule.textMatchRight}
                      regex={activeRule.regexRight}
                      textSelected={rightTextSelected}
                      regexSelected={rightRegexSelected}
                      onInputChange={onInputChange}
                      onCheckboxChange={onCheckboxChange}
                    />
                  </DifferentValuesHolder>
                  <DifferentValuesHolder>
                    <Field
                      id="dateRange"
                      size="sm"
                      type="number"
                      label={t`Date Range (in days)`}
                      value={activeRule.dateRange}
                      noFormat
                      tooltip={tooltips.dateRange}
                      onChange={onInputChange}
                      placeholder={t`Date Range (in days)`}
                    />
                    <Field
                      id="offset"
                      size="sm"
                      type="number"
                      noFormat
                      label={t`Offset`}
                      tooltip={tooltips.offset}
                      value={activeRule.offset}
                      onChange={onInputChange}
                      placeholder={t`Offset`}
                    />
                  </DifferentValuesHolder>
                  {activeRule.reconciliationFilterType !== 'ONE_TO_ONE' && (
                    <FilterFieldsHolder>
                      <Field
                        id="isDaterange"
                        size="sm"
                        type="checkbox"
                        label={t`Group by Date Range`}
                        checked={activeRule.isDaterange}
                        onChange={() => onCheckboxChange('isDaterange')}
                      />
                      <Field
                        id="isDaterangeDate"
                        size="sm"
                        type="checkbox"
                        label={t`Group by Exact Transaction Date`}
                        checked={activeRule.isDaterangeDate}
                        onChange={() => onCheckboxChange('isDaterangeDate')}
                      />
                    </FilterFieldsHolder>
                  )}
                </TabBody>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="success"
              type="submit"
              onClick={onSave}
              disabled={disabled}
            >
              {loadings.save ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} />
                  {t`Save`}
                </>
              )}
            </Button>
            <Button
              fill
              magnify
              kind="info"
              onClick={() => setPreviewOpened(true)}
              disabled={previewDisabled || !tabs.length}
            >
              <ButtonIcon as={FaRegEye} /> <Trans>Preview</Trans>
            </Button>
            <Button fill kind="danger" magnify onClick={() => toggleModal()}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </ModalStyles>
      {isPreviewOpened && (
        <ReconciliationPreviewModal
          tabs={tabs}
          companyId={companyId}
          accountId={accountId}
          closeModal={() => setPreviewOpened(false)}
        />
      )}
      {showImportSettings && (
        <ImportSettingsModal
          closeModal={() => setShowImportSettings(false)}
          onSettingsApply={onSettingsApply}
        />
      )}
    </>
  );
}

export default CreateRuleSetModal;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 10px 0;
  }
`;

const Tabs = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const TabItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TabItem = styled.div`
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 15px;
  background: #f3f3f3;
  color: #878787;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  flex: 1;
  vertical-align: middle;
  display: flex;
  align-items: center;
  gap: 5px;

  .priority {
    background: #878787;
    color: white;
    border-radius: 50%;
    padding: 1px 5px;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0;
    display: inline-block;
  }

  span {
    display: inline-block;
    max-width: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  &:not(.active):hover {
    background: #878787;
    color: white;

    .priority {
      background: white;
      color: #878787;
    }
  }

  &.active {
    background: #454545;
    color: white;
    cursor: default;

    .priority {
      background: white;
      color: #454545;
    }
  }
`;

const TabBody = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const DifferentValuesHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  margin-top: 10px;
`;

const FilterFieldsHolder = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const SpinnerWrapper = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalStyles = styled(Modal)`
  .modal-dialog {
    width: 800px;

    @media (max-width: 800px) {
      width: 90%;
    }
  }
`;
