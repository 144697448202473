import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Table Row component
 */
const TableRow = React.forwardRef((props, ref) => {
  const { kind, style, heading, children, innerRef, className, ...rest } =
    props;
  // Custom Bootstrap contextual classes
  const location = useLocation();
  const { reportMode } = queryString.parse(location.search, {
    parseBooleans: true,
  });
  const styleProps = heading
    ? {
        height: reportMode ? '20px' : '65px',
        ...style,
      }
    : style;

  if (innerRef) {
    // eslint-disable-next-line no-param-reassign
    rest.ref = innerRef;
  }

  return (
    <tr
      ref={ref}
      style={styleProps}
      className={classNames('table-row', kind, className)}
      {...rest}
    >
      {children}
    </tr>
  );
});

TableRow.propTypes = {
  kind: PropTypes.oneOf([
    'info',
    'danger',
    'active',
    'success',
    'warning',
    'darkgray',
  ]),
  style: PropTypes.shape({}),
  heading: PropTypes.bool,
  children: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
};

TableRow.defaultProps = {
  kind: undefined,
  style: {},
  heading: false,
  children: undefined,
  innerRef: undefined,
  className: undefined,
};

export default TableRow;
