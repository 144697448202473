import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@lingui/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { AdminUserSettingsModal } from 'components';

import { fromCompany, fromResource } from 'store/selectors';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  adminEditApi,
  getRoleListApi,
  getLanguageListApi,
  adminCreateUserApi,
  getGroupUserListApi,
  getIsUserGroupRequiredApi,
} from 'services/apihelpers';

function AdminUserSettingsContainer(props) {
  const { payload, closeModal, modalAction } = props;

  const [autoGenerate, setAutoGenerate] = useState(false);

  const dispatch = useDispatch();

  const company = useSelector((state) => fromCompany.getCompany(state));
  const roleList = useSelector((state) =>
    fromResource.getList(state, getRoleListApi),
  );
  const userGroupsList = useSelector((state) =>
    fromResource.getList(state, getGroupUserListApi),
  );
  const getLanguageList = useSelector((state) =>
    fromResource.getList(state, getLanguageListApi),
  );
  const isUserGroupRequired = useSelector((state) =>
    fromResource.getList(state, getIsUserGroupRequiredApi),
  );
  const submitting = useSelector(
    (state) =>
      pending(state, `${adminEditApi}Create`) ||
      pending(state, `${adminCreateUserApi}Create`),
  );
  const userGroupsLoading = useSelector((state) =>
    pending(state, `${getGroupUserListApi}ListRead`),
  );

  const fetchData = () => {
    try {
      dispatch(
        resourceListReadRequest(getRoleListApi, {
          companyId: company.currentCompanySID,
        }),
      );
      dispatch(resourceListReadRequest(getLanguageListApi));
      dispatch(resourceListReadRequest(getGroupUserListApi));
      dispatch(resourceListReadRequest(getIsUserGroupRequiredApi));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const generatePassword = () => {
    setAutoGenerate((prevState) => !prevState);
  };

  const onSubmit = async (formData) => {
    const query = {
      name: formData.name,
      newPdf: formData.newPdf || false,
      roleName: formData.roleName,
      uuid: modalAction ? payload.uuid : undefined,
      entries: formData.userEntries,
      password: autoGenerate ? '' : formData.password,
      colorMode: formData.colorMode || 'AUTO',
      contactNo: formData.contactNo,
      languageCode: formData.language,
      userGroupIds: formData.userGroupIds || [],
      loginUsername: formData.username,
      retypePassword: formData.retypePassword,
      organizationSID: company.organizationId,
      isGeneratePassword: autoGenerate,
    };

    try {
      if (modalAction === false) {
        await dispatch(resourceCreateRequest(adminCreateUserApi, query));

        toast.success(t`User created successfully`);
      } else {
        if (!query.password) {
          delete query.password;
        }

        await dispatch(resourceUpdateRequest(adminEditApi, null, query));

        toast.success(t`User updated successfully`);
      }
      closeModal(false, true);
    } catch (error) {
      if (error?.response?.headers?.get('Response-Message') === 'Conflict') {
        toast.error(t`User already exists`);
      } else {
        toast.error(
          error?.response?.headers?.get('Response-Message') || error?.message,
        );
      }
    }
  };

  const defaultValues = useMemo(() => {
    const formValues = {
      newPdf: true,
      colorMode: 'AUTO',
    };

    if (!isEmpty(payload)) {
      const role = payload.roleName;

      formValues.name = payload.name;
      formValues.username = payload.loginUsername;
      formValues.contactNo = payload.contactNo;
      formValues.language =
        !isEmpty(payload.languageCode) || payload.languageCode !== null
          ? payload.languageCode
          : '3';
      formValues.roleName = role;
      formValues.userEntries = payload.entries;
      formValues.userGroupIds = payload.userGroupIds;
      formValues.password = '';
      formValues.retypePassword = '';
      formValues.newPdf = payload.newPdf ?? true;
      formValues.colorMode = payload.colorMode ?? 'AUTO';
    }

    return formValues;
  }, [payload]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminUserSettingsModal
      {...{
        onSubmit,
        roleList,
        submitting,
        closeModal,
        modalAction,
        autoGenerate,
        defaultValues,
        userGroupsList,
        getLanguageList,
        generatePassword,
        userGroupsLoading,
        isUserGroupRequired,
      }}
    />
  );
}

export default AdminUserSettingsContainer;

AdminUserSettingsContainer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
  company: PropTypes.shape({}).isRequired,
  payload: PropTypes.shape({}),
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modalAction: PropTypes.bool,
  getLanguageList: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({}))]),
  roleList: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({}))]),
};

AdminUserSettingsContainer.defaultProps = {
  location: undefined,
  loading: false,
  modalAction: false,
  payload: undefined,
  getLanguageList: undefined,
  roleList: undefined,
};
