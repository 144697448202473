import { t } from '@lingui/macro';
import queryString from 'query-string';

import { api } from 'services/api';
import {
  validateRegisterCompanyApi,
  validateOrganizationNumberApi,
} from 'services/apihelpers';

export const asyncValidate = async (values) => {
  const fieldNames = Object.keys(values).pop();
  let fieldValue;
  if (fieldNames === 'twentyFourSeven') {
    fieldValue = JSON.parse(values[fieldNames]).clientCompanyName;
  } else if (fieldNames === 'tripletexcompanies') {
    fieldValue = JSON.parse(values[fieldNames]).tripleTexCompanyName;
  } else {
    fieldValue = values[fieldNames];
  }
  const request = api.create();

  const response = await request.get(
    `/${validateRegisterCompanyApi}?${queryString.stringify({
      fieldName: fieldNames,
      fieldValue,
    })}`,
  );
  if (response.status === '422') {
    throw new Error(response.statusText);
  }
};

export const asyncValidateRegNumber = async (regNr) => {
  const request = api.create();

  const response = await request.get(
    `/${validateOrganizationNumberApi}?${queryString.stringify({
      regNr,
    })}`,
  );

  return !response.status && t`Registration Number already taken`;
};
