import React from 'react';
import PropTypes from 'prop-types';

import { Card, Heading, Paragraph, Button, Link } from 'components';

function RegistrationValidation({ invalidToken, submitting, invalid, signIn }) {
  const cardHeading = (
    <Heading level={3} className="card-title">
      Registration Validation
    </Heading>
  );
  const cardFooter = (
    <div>
      {!invalidToken && (
        <Button fill wide disabled={submitting || invalid} onClick={signIn}>
          Sign In
        </Button>
      )}
      {invalidToken && (
        <div className="forgot">
          <Link to="/">Back to login page</Link>
        </div>
      )}
    </div>
  );

  return (
    <Card kind="login" header={cardHeading} footer={cardFooter} footerCenter>
      {!invalidToken && (
        <Paragraph alignment="center" className="description">
          Your email has been verfied. Please Sign in.
        </Paragraph>
      )}

      {invalidToken && (
        <Paragraph alignment="center" className="description">
          The token has expired.
        </Paragraph>
      )}
    </Card>
  );
}

RegistrationValidation.propTypes = {
  invalidToken: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  signIn: PropTypes.func.isRequired,
};

RegistrationValidation.defaultProps = {
  submitting: false,
  invalidToken: false,
  invalid: undefined,
};

export default RegistrationValidation;
