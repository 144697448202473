import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { useForm } from 'react-form';
import styled from 'styled-components/macro';
import _debounce from 'lodash-es/debounce';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { Modal, Col, Row } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';

import {
  Button,
  Heading,
  Spinner,
  Paragraph,
  ButtonIcon,
  ReactFormField,
  AccountingSystem,
} from 'components';

import {
  validateTripletexTokenApi,
  reValidateTripletexTokenApi,
} from 'services/apihelpers';
import { periodTypes } from 'services/shapes';
import { required } from 'services/validation';
import { sortCollection } from 'services/helpers';
import { resourceListReadSuccess } from 'store/actions';
import { asyncValidateRegNumber } from 'services/asyncValidation';

const titles = () => ({
  edit: t`Edit Company`,
  add: t`Add Company`,
});

function AdminCompanySettingsModal(props) {
  const {
    payload,
    loading,
    roleType,
    onSubmit,
    countries,
    closeModal,
    modalAction,
    defaultValues,
    frequencyList,
    useXledgerUser,
    companyGroupsList,
    userListByCompany,
    isValidIntegration,
    changeValidatedValue,
    isCompanyGroupRequired,
    getTripletexOrganizationToken,
    organizationAltinnAvailability,

    // LOADINGS while data fetches
    getUsersLoading,
    getGroupsLoading,
    getCountriesLoading,
    loadingFrequencyList,
    isGroupRequiredLoading,
    getVismaClientsLoading,
    checkXledgerUseLoading,
    checkVismaStatusLoading,
    getAccountingSystemsLoading,
    checkXledgerOrganizationLoading,
  } = props;
  const dispatch = useDispatch();
  const [regNumberIsTaken, setRegNumberIsTaken] = useState(false);
  const [showPeriodTypeChangeWarning, setShowPeriodTypeChangeWarning] =
    useState(false);

  const fetchLoading =
    getUsersLoading ||
    getGroupsLoading ||
    getCountriesLoading ||
    loadingFrequencyList ||
    isGroupRequiredLoading ||
    getVismaClientsLoading ||
    checkXledgerUseLoading ||
    checkVismaStatusLoading ||
    getAccountingSystemsLoading ||
    checkXledgerOrganizationLoading;

  const {
    Form,
    setFieldValue,
    values: currentValues,
    meta: { canSubmit, isTouched, isSubmitting },
  } = useForm({ onSubmit, defaultValues });

  const {
    altinnUsername,
    altinnPassword,
    accountingsystem,
    useOrganizationCredentials,
  } = currentValues;

  const { edit, add } = titles();

  const altinnPasswordRequired =
    altinnUsername && altinnUsername !== defaultValues.altinnUsername;
  const altinnUsernameRequired = !!altinnPassword;

  const handleVismaClientSelect = (value) => {
    props.handleVismaClientSelect(value);
    // this code will set names to the fields into form automatically.
    const selectedItem = value[0];

    if (!modalAction && selectedItem) {
      setFieldValue('companyname', selectedItem.clientName);

      if (selectedItem.organizationNumber) {
        setFieldValue(
          'registrationNumber',
          selectedItem.organizationNumber.replace(/\s/g, ''),
        );
      }
    }
  };
  const handleTripletexClientSelect = (value) => {
    if (value.length) {
      const selectedValue = JSON.parse(value[0].id);

      if (selectedValue.tripleTexCompanyName) {
        setFieldValue('companyname', selectedValue.tripleTexCompanyName);
      }

      if (selectedValue.tripleTexOrganizationNumber) {
        setFieldValue(
          'registrationNumber',
          selectedValue.tripleTexOrganizationNumber,
        );
      }

      setFieldValue('tripletexcompanies', value[0].id);
    } else {
      setFieldValue('tripletexcompanies', null);
    }
  };

  const handle24SevenClientSelect = (value) => {
    if (value.length) {
      setFieldValue('twentyFourSeven', value[0].id);
    } else {
      setFieldValue('twentyFourSeven', null);
    }
  };

  const handleOrganizationToken = async (e) => {
    if (e.target.value === 'true') {
      const token = await getTripletexOrganizationToken();

      setFieldValue('token', token);
    } else {
      setFieldValue('token', '');
    }
    dispatch(resourceListReadSuccess(validateTripletexTokenApi, null));
    dispatch(resourceListReadSuccess(reValidateTripletexTokenApi, null));
  };

  const periodTypesOptions = useMemo(
    () =>
      periodTypes().map((item) => (
        <option key={`periodTypesOptions-${item.id}`} value={item.id}>
          {item.label}
        </option>
      )),
    [],
  );

  const countryOptions = useMemo(() => {
    const options = [
      <option key="selectOptionDefaultCountry" value="" disabled>
        {t`Please Select The Country`}
      </option>,
    ];

    if (!isEmpty(countries)) {
      options.push(
        ...Object.values(countries).map((item) => (
          <option
            key={`countryList-${item.countrySID}`}
            value={item.countryCode}
          >
            {item.countryName}
          </option>
        )),
      );
    }

    return options;
  }, [JSON.stringify(countries)]);

  const userOptions = useMemo(
    () =>
      !isEmpty(userListByCompany)
        ? sortCollection(
            userListByCompany
              .filter((item) => item.recordStatus)
              .map((item) => ({
                value: item.uuid,
                label: item.name,
              })),
            'Asc',
            'label',
          )
        : [],
    [JSON.stringify(userListByCompany)],
  );

  const handlePeriodTypeChange = (e) => {
    // If the form is in edit mode and the user changes the period type, show a warning
    if (modalAction && e.target.value !== payload.periodType) {
      setShowPeriodTypeChangeWarning(true);
    } else {
      setShowPeriodTypeChangeWarning(false);
    }
  };

  const frequencyOptions = useMemo(() => {
    const options = [
      <option key="selectOptionDefaultFrequency" value="" disabled>
        {t`Please Select The Frequency`}
      </option>,
    ];

    if (!isEmpty(frequencyList)) {
      options.push(
        ...frequencyList.map((item) => (
          <option
            key={`freqencyList-${item.vatReportFrequencyId}`}
            value={item.vatReportFrequencyId}
          >
            {item.frequency}
          </option>
        )),
      );
    }

    return options;
  }, [JSON.stringify(frequencyList)]);

  const companyGroupsOptions = useMemo(
    () =>
      !isEmpty(companyGroupsList)
        ? companyGroupsList.map((item) => ({
            value: item.groupId,
            label: item.name,
          }))
        : [],
    [JSON.stringify(companyGroupsList)],
  );

  const baseDisabled = loading || fetchLoading || !canSubmit || !isTouched;
  const useBaseDisabled = ['ConnectMyApps', 'EACCOUNTING'].includes(
    accountingsystem,
  );

  const disabled =
    modalAction || useBaseDisabled
      ? baseDisabled
      : baseDisabled || !isValidIntegration;

  const helpingMessage = () => {
    if (loading || fetchLoading || isSubmitting) return;

    if (!canSubmit) {
      // eslint-disable-next-line consistent-return
      return t`All required fields must be filled`;
    }

    if (
      !modalAction &&
      !isValidIntegration &&
      accountingsystem !== 'EACCOUNTING'
    ) {
      // eslint-disable-next-line consistent-return
      return t`Integrations needs to be validated`;
    }

    // eslint-disable-next-line consistent-return
    return '';
  };

  useEffect(() => {
    if (useXledgerUser) {
      setFieldValue('useXledgerOrganizationUser', useXledgerUser);
    }
  }, [useXledgerUser]);

  const checkRegistrationNumber = (e) =>
    e.target.value !== defaultValues.registrationNumber &&
    _debounce(async (value) => {
      const warning = await asyncValidateRegNumber(value);
      setRegNumberIsTaken(warning);
    }, 800)(e.target.value);

  useEffect(() => {
    changeValidatedValue(false);
  }, [
    currentValues?.useXledgerOrganizationUser,
    currentValues?.username,
    currentValues?.token,
    currentValues?.password,
    currentValues?.entityCode,
    currentValues?.useOrganizationToken,
  ]);

  const loadingBody = (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );

  const body = (
    <Modal.Body>
      <Row>
        <Col md="6">
          <ReactFormField
            noBorder
            feedback
            name="companyname"
            size="small"
            label={t`Company Name`}
            validate={[required]}
            placeholder={t`Enter Company Name`}
            autoComplete="search"
          />
          <ReactFormField
            noBorder
            feedback
            name="registrationNumber"
            size="small"
            label={t`Registration Number`}
            validate={[required]}
            onChange={checkRegistrationNumber}
            placeholder={t`Enter the Registration Number`}
            autoComplete="search"
          />
          {regNumberIsTaken && (
            <Warning kind="warning">{regNumberIsTaken}</Warning>
          )}
          <ReactFormField
            noBorder
            feedback
            name="periodtypes"
            size="small"
            type="select"
            label={t`Period Types`}
            onChange={handlePeriodTypeChange}
          >
            {periodTypesOptions}
          </ReactFormField>
          {showPeriodTypeChangeWarning && (
            <Warning kind="warning">
              <Trans>
                Warning: Changing the period type will reset accounts&apos;
                frequencies. Are you sure you want to proceed?
              </Trans>
            </Warning>
          )}
          <ReactFormField
            noBorder
            feedback
            name="country"
            size="small"
            type="select"
            label={t`Country`}
          >
            {countryOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            feedback
            name="user"
            size="sm"
            type="autoSuggest"
            label={t`Assigned User`}
            options={userOptions}
            validate={[required]}
          />
          <ReactFormField
            noBorder
            feedback
            name="adminVATReportingFrequency"
            size="small"
            type="select"
            label={t`VAT Reporting Frequency`}
          >
            {frequencyOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            multiple
            size="small"
            name="companyGroupIds"
            type="autoSuggest"
            label={t`Company Groups`}
            options={companyGroupsOptions}
            validate={isCompanyGroupRequired?.status ? [required] : undefined}
          />
          <ReactFormField
            disabled
            id="isMainCompany"
            name="isMainCompany"
            size="small"
            type="checkbox"
            label={t`Is Main Company`}
            defaultChecked={payload?.isMainCompany}
          />
          {roleType?.isAdmin && (
            <>
              <ReactFormField
                id="payrollEnabled"
                name="payrollEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Payroll`}
              />
              <ReactFormField
                id="vatEnabled"
                name="vatEnabled"
                size="small"
                type="checkbox"
                label={t`Enable VAT`}
              />
              <ReactFormField
                id="assetsEnabled"
                name="assetsEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Assets`}
              />
            </>
          )}
        </Col>
        <Col md={6}>
          <AccountingSystem
            {...props}
            {...{
              currentValues,
              setFieldValue,
              handleOrganizationToken,
              handleVismaClientSelect,
              handle24SevenClientSelect,
              handleTripletexClientSelect,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div>
            <hr />
            <Heading level={4}>
              <Trans> Altinn Integration </Trans>
            </Heading>
            <ReactFormField
              id="useOrganizationCredentials"
              size="small"
              type="checkbox"
              name="useOrganizationCredentials"
              label={t`Use Organization Altinn Credentials`}
              disabled={!organizationAltinnAvailability}
            />
            {useOrganizationCredentials?.toString() !== 'true' && (
              <>
                <ReactFormField
                  noBorder
                  feedback
                  size="small"
                  name="altinnUsername"
                  type="text"
                  label={t`Altinn System ID`}
                  validate={altinnUsernameRequired ? [required] : undefined}
                  placeholder={t`Please Enter Altinn System ID`}
                  autoComplete="off"
                />
                <ReactFormField
                  feedback
                  noBorder
                  size="small"
                  name="altinnPassword"
                  type="password"
                  label={t`Altinn System Password`}
                  validate={altinnPasswordRequired ? [required] : undefined}
                  placeholder={t`Please Enter Altinn System Password`}
                  autoComplete="new-password"
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </Modal.Body>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Form>
        <Modal.Header>
          <Heading level={3}>{modalAction ? edit : add}</Heading>
        </Modal.Header>
        {fetchLoading ? loadingBody : body}
        <Footer>
          {(!canSubmit || !isValidIntegration) && (
            <HelpingMessage kind="danger">{helpingMessage()}</HelpingMessage>
          )}
          <Button fill kind="success" magnify type="submit" disabled={disabled}>
            {loading ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                {modalAction ? (
                  <ButtonIcon as={HiCheck} />
                ) : (
                  <ButtonIcon as={HiPlus} />
                )}{' '}
                <Trans>Save</Trans>
              </>
            )}
          </Button>
          <Button fill magnify kind="danger" onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
}

export default AdminCompanySettingsModal;

AdminCompanySettingsModal.propTypes = {
  payload: PropTypes.shape({}),
  loading: PropTypes.bool,
  roleType: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeModal: PropTypes.func.isRequired,
  modalAction: PropTypes.bool.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  frequencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  useXledgerUser: PropTypes.bool,
  companyGroupsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userListByCompany: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isValidIntegration: PropTypes.bool.isRequired,
  changeValidatedValue: PropTypes.func.isRequired,
  isCompanyGroupRequired: PropTypes.bool.isRequired,
  getTripletexOrganizationToken: PropTypes.func.isRequired,
  organizationAltinnAvailability: PropTypes.bool.isRequired,

  getUsersLoading: PropTypes.bool,
  getGroupsLoading: PropTypes.bool,
  getCountriesLoading: PropTypes.bool,
  loadingFrequencyList: PropTypes.bool,
  isGroupRequiredLoading: PropTypes.bool,
  getVismaClientsLoading: PropTypes.bool,
  checkXledgerUseLoading: PropTypes.bool,
  checkVismaStatusLoading: PropTypes.bool,
  getAccountingSystemsLoading: PropTypes.bool,
  checkXledgerOrganizationLoading: PropTypes.bool,
};

AdminCompanySettingsModal.defaultProps = {
  payload: {},
  useXledgerUser: undefined,
  loading: undefined,
  getUsersLoading: undefined,
  getGroupsLoading: undefined,
  getCountriesLoading: undefined,
  loadingFrequencyList: undefined,
  isGroupRequiredLoading: undefined,
  getVismaClientsLoading: undefined,
  checkXledgerUseLoading: undefined,
  checkVismaStatusLoading: undefined,
  getAccountingSystemsLoading: undefined,
  checkXledgerOrganizationLoading: undefined,
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const HelpingMessage = styled((props) => <Paragraph {...props} />)`
  margin: 0 20px 0 0 !important;
`;

const Footer = styled((props) => <Modal.Footer {...props} />)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Warning = styled((props) => <Paragraph {...props} />)`
  font-size: 14px;
  margin-top: -5px !important;
`;
