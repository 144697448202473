import React, { useState, useEffect, useMemo } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { BankSettings } from 'components';
import BankAddEditModal from 'containers/BankAddEditModal';

import { resourceListReadSuccess } from 'store/resource/actions';
import { toReconciliationSettings } from 'services/routehelpers';
import { fromResource } from 'store/selectors';
import {
  getGLAccountsApi,
  mapBankAccountApi,
  getBankAccountsApi,
  unMapBankAccountApi,
  removeBankAccountApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  resourceUpdateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';

function BankSettingsContainer(props) {
  const {
    history,
    location,
    match: {
      params: { companyId, accountingYear },
    },
  } = props;
  const { openBanking } = queryString.parse(location.search);

  const dispatch = useDispatch();

  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const dataUrl = useMemo(
    () => `${getBankAccountsApi}/${companyId}`,
    [companyId],
  );
  const data = useSelector((state) => fromResource.getList(state, dataUrl));
  const loading = useSelector((state) => pending(state, dataUrl));
  const loadingGLAccountsList = useSelector((state) =>
    pending(state, `${getGLAccountsApi}/${companyId}/${accountingYear}`),
  );
  const glAccountsList = useSelector((state) =>
    fromResource.getList(
      state,
      `${getGLAccountsApi}/${companyId}/${accountingYear}`,
    ),
  );

  const fetchData = () => {
    try {
      dispatch(resourceListReadRequest(dataUrl));
      dispatch(
        resourceListReadRequest(
          `${getGLAccountsApi}/${companyId}/${accountingYear}`,
        ),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const _setActionSubmittings = (action, submitting) => {
    setActionSubmitting((prevState) => ({
      ...prevState,
      [action]: submitting,
    }));
  };

  const unMapBankAccount = async (item) => {
    try {
      _setActionSubmittings(item.id, true);

      await dispatch(
        resourceUpdateRequest(`${unMapBankAccountApi}/${companyId}`, null, {
          bankAccountId: item.id,
        }),
      );

      const collection = data.map((el) => {
        const newEl = { ...el };

        if (newEl.id === item.id) {
          newEl.mappedGlAccountId = null;
        }

        return newEl;
      });

      dispatch(resourceListReadSuccess(dataUrl, collection));

      _setActionSubmittings(item.id, false);
    } catch (e) {
      _setActionSubmittings(item.id, false);
      toast.error(getErrorMessage(e));
    }
  };

  const toggleModal = (modal = {}) => {
    if (Object.keys(modal).length === 0) {
      setModalProps({});
      fetchData();
    }
    setModalProps(modal);
  };

  const renderModal = () => {
    const { type } = modalProps;
    let modalToRender = '';

    switch (type) {
      case 'addAccount': {
        modalToRender = (
          <BankAddEditModal companyId={companyId} closeModal={toggleModal} />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  const deleteAccount = async (item) => {
    try {
      _setActionSubmittings(item.id, true);

      await dispatch(
        resourceDeleteRequest(`${removeBankAccountApi}/${item.id}`),
      );

      const collection = data.filter((el) => el.id !== item.id);

      dispatch(resourceListReadSuccess(dataUrl, collection));

      _setActionSubmittings(item.id, false);
    } catch (e) {
      _setActionSubmittings(item.id, false);
      toast.error(getErrorMessage(e));
    }
  };

  const saveRowChange = async ({ row, value }) => {
    try {
      _setActionSubmittings(row.id, true);

      await dispatch(
        resourceUpdateRequest(`${mapBankAccountApi}/${companyId}`, null, {
          accountId: value[0].id,
          bankAccountId: row.id,
        }),
      );

      const collection = data.map((el) => {
        const newEl = { ...el };

        if (newEl.id === row.id) {
          newEl.mappedGlAccountId = value[0].id;
        }

        return newEl;
      });

      dispatch(resourceListReadSuccess(dataUrl, collection));

      _setActionSubmittings(row.id, false);
    } catch (e) {
      _setActionSubmittings(row.id, false);
      toast.error(getErrorMessage(e));
    }
  };

  const getSearch = () => {
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      tabEntry: 'bank-settings',
      reportMode: search.reportMode || false,
    };
  };

  const handleAction = async (action, info) => {
    try {
      _setActionSubmittings(action, true);

      switch (action) {
        case 'cellSave': {
          await saveRowChange(info);
          break;
        }

        case 'addAccount': {
          toggleModal({ type: 'addAccount' });
          break;
        }

        case 'unlink': {
          await unMapBankAccount(info);
          break;
        }

        case 'delete': {
          await deleteAccount(info);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmittings(action, false);
    } catch (e) {
      _setActionSubmittings(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const search = useMemo(() => getSearch(), [location.search]);

  const collections = useMemo(
    () => ({
      mappedGlAccountId: {
        options: Array.isArray(glAccountsList)
          ? glAccountsList.map((el) => ({
              id: el.accountId,
              label: el.description
                ? `${el.accountId} - ${el.description}`
                : `${el.accountId} `,
              disabled:
                Array.isArray(data) &&
                data.find((d) => d.mappedGlAccountId === el.accountId),
            }))
          : [],
        loading: loadingGLAccountsList,
      },
    }),
    [data, glAccountsList, loadingGLAccountsList],
  );

  useEffect(() => {
    fetchData();

    if (openBanking === 'true') {
      const searching = getSearch();

      toggleModal({ type: 'addAccount' });

      history.replace(toReconciliationSettings(searching));
    }
  }, []);

  return (
    <>
      {renderModal()}
      <BankSettings
        {...{
          data,
          search,
          loading,
          collections,
          actionSubmitting,
          title: t`Bank Settings`,
          handleAction,
        }}
      />
    </>
  );
}

export default BankSettingsContainer;
