import React, { useEffect, useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import {
  VATSettingsCodesTable,
  AddEditVATSettingsModal,
  VATChildCompaniesTable,
  VATSettingsUnmappedTable,
} from 'components';

import { fromResource } from 'store/selectors';
import {
  getVATJointList,
  getVATCodesNew,
  getVATSaftCodesApi,
  getVATUnmappedCodes,
  getCompanyByOrganizationApi,
  getVATCodesHistoryApi,
} from 'services/apihelpers';
import {
  resourceDeleteRequest,
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { vatSettingsCodesFormatter } from 'services/dataFormatters/vat/vatSettingsCodesFormatter'; // eslint-disable-line max-len
import { fromPercentage } from '../services/helpers/index';

export function VATSettingsNewContainer(props) {
  const { company, roleType } = props;
  const { isReadOnly } = roleType;
  const {
    organizationId,
    currentCompanySID: companyId,
    currentAccountingYear: year,
  } = company;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [jointLoading, setJointLoading] = useState(false);
  const [showModal, setShowModal] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [showProRata, setShowProRata] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getVATCodesNew),
  );
  const jointList = useSelector((state) =>
    fromResource.getList(state, getVATJointList),
  );
  const unmappedData = useSelector((state) =>
    fromResource.getList(state, getVATUnmappedCodes),
  );
  const companies = useSelector((state) =>
    fromResource.getList(state, getCompanyByOrganizationApi),
  );

  const saftCodes = useSelector((state) =>
    fromResource.getList(state, getVATSaftCodesApi),
  );

  const companiesOptions = useMemo(() => {
    if (Array.isArray(companies) && companies.length) {
      return companies.map((item) => ({
        id: item.companyId,
        label: item.name,
      }));
    }

    return [];
  }, [companies]);

  const fetchCodes = async () => {
    await dispatch(
      resourceListReadRequest(getVATCodesNew, {
        companySid: companyId,
      }),
    );
  };

  const fetchUnmappedLines = async () => {
    await dispatch(
      resourceListReadRequest(getVATUnmappedCodes, {
        year,
        companyId,
      }),
    );
  };

  const fetchSaftCodes = async () => {
    try {
      await dispatch(resourceListReadRequest(getVATSaftCodesApi));
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await fetchCodes();
      await fetchUnmappedLines();
      setShowModal({});
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting((state) => ({
      ...state,
      [action]: submitting,
    }));
  };

  const deleteItem = async (vatCodeRuleHistorySid) => {
    _setActionSubmitting(vatCodeRuleHistorySid, true);

    const mainCodeFromHistory = data.find((item) =>
      item.history.find(
        (historyItem) =>
          historyItem.vatCodeRuleHistorySid === vatCodeRuleHistorySid,
      ),
    );

    if (mainCodeFromHistory.history.length === 1) {
      await dispatch(
        resourceDeleteRequest(
          // eslint-disable-next-line max-len
          `${getVATCodesNew}?vatCodeRuleSid=${mainCodeFromHistory.vatCodeRuleSid}`,
        ),
      );
      dispatch(
        resourceListReadSuccess(
          getVATCodesNew,
          ...data.filter(
            (item) =>
              item.vatCodeRuleSid !== mainCodeFromHistory.vatCodeRuleSid,
          ),
        ),
      );
      _setActionSubmitting(vatCodeRuleHistorySid, false);
      fetchUnmappedLines();
      return;
    }

    await dispatch(
      resourceDeleteRequest(
        // eslint-disable-next-line max-len
        `${getVATCodesHistoryApi}?vatCodeRuleHistorySid=${vatCodeRuleHistorySid}`,
      ),
    );
    dispatch(
      resourceListReadSuccess(
        getVATCodesNew,
        // Filter out the history item from the VAT codes item that is being deleted
        ...data.map((item) => ({
          ...item,
          history: item.history.filter(
            (historyItem) =>
              historyItem.vatCodeRuleHistorySid !== vatCodeRuleHistorySid,
          ),
        })),
      ),
    );

    _setActionSubmitting(vatCodeRuleHistorySid, false);

    fetchUnmappedLines();
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'addItem': {
          setShowModal({ vatCodeRuleSid: 0, mode: 'add' });
          break;
        }

        case 'delete': {
          deleteItem(item.vatCodeRuleHistorySid);
          break;
        }

        case 'editItem': {
          setShowModal({ ...item, mode: 'edit' });
          break;
        }

        case 'mapItem': {
          setShowModal({ vatCodeRuleSid: 0, code: item, mode: 'map' });
          break;
        }

        case 'addRange': {
          setShowModal({ ...item, mode: 'addRange' });
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const closeModal = () => {
    setShowModal({});
    setShowProRata(false);
  };

  const onSubmit = async (item, values) => {
    const {
      vatCode,
      saftCode,
      accounts,
      percentage,
      description,
      reverseAccounts,
      reversePercentage,
      proRataPercentage,
      proRataReversePercentage,
      effectiveStartDate,
      effectiveStartDateIsInfinite,
      effectiveEndDate,
      effectiveEndDateIsInfinite,
      hidden,
      reverseChargeCalculation,
      mode,
    } = values;

    switch (mode) {
      case 'add':
      case 'map': {
        const query = {
          companySid: companyId,
          code: vatCode,
          saftCode,
          description,
          inTaxAccount: accounts,
          outTaxAccount: reverseAccounts,
          hidden: JSON.parse(hidden),
          reverseChargeCalculation: JSON.parse(reverseChargeCalculation),
          history: [
            {
              inTaxPercentage: fromPercentage(percentage),
              outTaxPercentage: fromPercentage(reversePercentage),
              inTaxProRataPercentage: fromPercentage(proRataPercentage),
              outTaxProRataPercentage: fromPercentage(proRataReversePercentage),
              effectiveStartDate: JSON.parse(effectiveStartDateIsInfinite)
                ? '1900-01-01'
                : effectiveStartDate,
              effectiveEndDate: JSON.parse(effectiveEndDateIsInfinite)
                ? '2100-01-01'
                : effectiveEndDate,
            },
          ],
        };

        try {
          await dispatch(resourceCreateRequest(getVATCodesNew, query));
          closeModal();
          fetchData();
          toast.success(t`Settings item was created successfully`);
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        break;
      }

      case 'edit': {
        const itemQuery = {
          ...item.originalObject,
          companySid: companyId,
          code: vatCode,
          saftCode,
          description,
          inTaxAccount: accounts,
          outTaxAccount: reverseAccounts,
          hidden: JSON.parse(hidden),
          reverseChargeCalculation: JSON.parse(reverseChargeCalculation),
          history: item.originalObject.history.map((historyItem) => {
            if (
              historyItem.vatCodeRuleHistorySid === item.vatCodeRuleHistorySid
            ) {
              return {
                ...historyItem,
                inTaxPercentage: +percentage / 100,
                outTaxPercentage: +reversePercentage / 100,
                inTaxProRataPercentage: +proRataPercentage / 100,
                outTaxProRataPercentage: +proRataReversePercentage / 100,
                effectiveStartDate: JSON.parse(effectiveStartDateIsInfinite)
                  ? '1900-01-01'
                  : effectiveStartDate,
                effectiveEndDate: JSON.parse(effectiveEndDateIsInfinite)
                  ? '2100-01-01'
                  : effectiveEndDate,
              };
            }

            return historyItem;
          }),
        };

        try {
          await dispatch(
            resourceUpdateRequest(getVATCodesNew, null, itemQuery),
          );
          closeModal();
          fetchData();
          toast.success(t`Settings item was updated successfully`);
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        break;
      }

      case 'addRange': {
        const historyQuery = {
          ...item.originalObject,
          history: [
            ...item.originalObject.history,
            {
              inTaxPercentage: +percentage / 100,
              outTaxPercentage: +reversePercentage / 100,
              inTaxProRataPercentage: +proRataPercentage / 100,
              outTaxProRataPercentage: +proRataReversePercentage / 100,
              effectiveStartDate: JSON.parse(effectiveStartDateIsInfinite)
                ? '1900-01-01'
                : effectiveStartDate,
              effectiveEndDate: JSON.parse(effectiveEndDateIsInfinite)
                ? '2100-01-01'
                : effectiveEndDate,
            },
          ],
        };

        try {
          await dispatch(
            resourceUpdateRequest(getVATCodesNew, null, historyQuery),
          );
          closeModal();
          fetchData();
          toast.success(t`Settings item was updated successfully`);
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  const fetchCompaniesList = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getCompanyByOrganizationApi, {
          organizationId,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const fetchJointList = async () => {
    setJointLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getVATJointList, {
          companySid: companyId,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    setJointLoading(() => false);
  };

  const getRowColor = (row) => row.id === 0 && 'darkgray';

  useEffect(() => {
    fetchData();
  }, [companyId, year]);

  useEffect(() => {
    fetchSaftCodes();
  }, []);

  useEffect(() => {
    fetchCompaniesList();
  }, [organizationId]);

  useEffect(() => {
    fetchJointList();
  }, [companyId]);

  return (
    <>
      {typeof showModal.vatCodeRuleSid !== 'undefined' && (
        <AddEditVATSettingsModal
          {...{
            item: showModal,
            onSubmit,
            saftCodes,
            closeModal,
            showProRata,
            unmappedData,
            setShowProRata,
            historyItem: showModal.historyIndex,
          }}
        />
      )}
      {Array.isArray(unmappedData) && !!unmappedData.length && (
        <VATSettingsUnmappedTable
          {...{ unmappedData, loading, handleAction, actionSubmitting }}
        />
      )}
      <VATSettingsCodesTable
        {...{
          data: vatSettingsCodesFormatter(data),
          loading,
          saftCodes,
          isReadOnly,
          getRowColor,
          handleAction,
          unmappedData,
          actionSubmitting,
        }}
      />
      <VATChildCompaniesTable
        {...{
          isReadOnly,
          companyId,
          data: jointList,
          actionSubmitting,
          companiesOptions,
          loading: jointLoading,
          refetch: fetchJointList,
        }}
      />
    </>
  );
}

export default VATSettingsNewContainer;
