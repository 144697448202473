import { t } from '@lingui/macro';

/**
 * Account Details Table Grid
 */
export const columns = ({ reportMode }) => [
  {
    width: 60,
    hidden: reportMode,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 70,
    Header: t`ID`,
    accessor: 'accountId',
    className: 'flex-center',
  },
  {
    width: reportMode ? 210 : 290,
    Header: t`Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 80 : 100,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: reportMode ? 100 : 130,
    Header: t`Value Date`,
    accessor: 'postedDate',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: reportMode ? 80 : 110,
    Header: t`Trans. ID`,
    accessor: 'transactionId',
    className: '',
  },
  {
    width: reportMode ? 200 : 300,
    Header: t`Text`,
    accessor: 'description',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 100 : 200,
    Header: t`VAT Code`,
    accessor: 'taxDescription',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 125 : 140,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
