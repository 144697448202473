import { t } from '@lingui/macro';

/**
 * Cross YEar Unlink Table Grid
 */
export const columns = () => [
  {
    id: 1,
    label: t`Source Document ID`,
    value: 'sourceDocumentId',
    sortable: false,
    enabled: true,
  },
  {
    id: 2,
    label: t`Description`,
    value: 'description',
    sortable: false,
    enabled: true,
  },
  {
    id: 3,
    label: t`Period`,
    value: 'period',
    sortable: false,
    enabled: true,
  },
  {
    id: 4,
    label: t`Year`,
    value: 'year',
    sortable: false,
    enabled: true,
  },
];

export default { columns };
