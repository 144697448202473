import {
  periodTypes,
  recordFilterList,
  filterByReconciledMatchedTransaction,
  viewFinishedAccrualsOptions,
} from 'services/shapes';

const bankOptions = filterByReconciledMatchedTransaction();
const accrualsOptions = viewFinishedAccrualsOptions();
const reconcileOptions = recordFilterList();
const frequencies = periodTypes();

export const bankDefaultValues = { [bankOptions[1].id]: true };

export const accrualsDefaultValues = { [accrualsOptions[1].id]: true };

export const reconcileDefaultValues = {
  [reconcileOptions[1].id]: true,
};

export const reportPackageDefaultValues = {
  singlePeriod: false,
  period: 12,
  trialBalance: frequencies[2].id,
  bank: bankDefaultValues,
  generalAccruals: accrualsDefaultValues,
  generalReconcile: reconcileDefaultValues,
};
