import React from 'react';
import { Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Form, Modal } from 'react-bootstrap';

import TableHeader from 'containers/TableHeader';
import {
  Table,
  Field,
  Button,
  Heading,
  Spinner,
  TableRow,
  TableCell,
  ButtonIcon,
} from 'components';

import { accrualsOriginColumns } from 'services/tableshapes';

function AccrualsOriginModal(props) {
  const {
    submitting,
    toggleModal,
    optionsList,
    handleSubmit,
    handleRowChange,
    selectedOptions,
    reconciliationId,
    partialReconciliationId,
  } = props;

  return (
    <Modal show onEscapeKeyDown={toggleModal} bsSize="lg">
      <Modal.Header>
        <Heading level={3}>
          <Trans>Select Accruals origin</Trans>
        </Heading>
        <Heading level={4}>
          <Trans>
            Transaction ID: {reconciliationId || partialReconciliationId}
          </Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Table
            responsive
            head={<TableHeader columns={accrualsOriginColumns} />}
          >
            {optionsList.map((row) => {
              const checked = selectedOptions.includes(row.recordId);

              return (
                <TableRow
                  key={`gLRecordList-${row.lineSID}`}
                  kind={checked && 'success'}
                >
                  <TableCell className="text-center">
                    <Field
                      id={row.recordId}
                      key={`accountList-${row.recordId}`}
                      type="checkbox"
                      checked={checked}
                      onChange={handleRowChange}
                    />
                  </TableCell>
                  <TableCell className="text-left">
                    {row.transactionId}
                  </TableCell>
                  <TableCell className="text-right">{row.period}</TableCell>
                  <TableCell className="text-left">{row.description}</TableCell>
                  <TableCell number className="text-right">
                    {row.amount}
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="info"
            type="submit"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
              </>
            )}
          </Button>
          <Button fill kind="danger" magnify onClick={toggleModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default AccrualsOriginModal;
