import React, { useEffect, useState, useRef } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';

import { AutoSuggestInput } from 'components';

const DEFAULT_HEIGHT = 51;

function AutoSuggestInputContainer(props) {
  const { selected, reference, handleAutoSuggestInputChange } = props;
  let inputRef = useRef();
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [inputValidateColor, setInputValidateColor] = useState(false);

  const onChange = (value) => {
    if (typeof handleAutoSuggestInputChange === 'function') {
      handleAutoSuggestInputChange(value);
    }
    if (typeof setInputValidateColor === 'function') {
      setInputValidateColor(isEmpty(value));
    }
  };
  const setRef = (ref) => {
    inputRef = ref;

    if (typeof reference !== 'undefined') {
      reference.current = ref;
    }
  };

  useEffect(() => {
    if (typeof inputRef.getInput === 'function') {
      const listHolder = inputRef.getInput().parentNode.parentNode;
      const listHeight = listHolder.scrollHeight + 30; // add top parent padding

      if (listHeight !== height && listHeight >= DEFAULT_HEIGHT) {
        setHeight(listHeight);
      }
    }
  }, [JSON.stringify(selected)]);

  return (
    <AutoSuggestInput
      {...{
        ...props,
        height,
        inputValidateColor,
        setRef,
        onChange,
      }}
    />
  );
}

export default AutoSuggestInputContainer;

AutoSuggestInputContainer.propTypes = {
  /** @type {func} handleAutoSuggestInputChange */
  handleAutoSuggestInputChange: PropTypes.func.isRequired,
};
