import React, { Component } from 'react';
import { PayrollReconciliationPage } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromAuth, fromCompany } from 'store/selectors';
import { commentsRouteInitializer } from 'services/helpers';
import FileUploadModal from 'containers/FileUploadModal';
import CommentsModal from 'containers/CommentsModal';
import { toPayrollReconciliation } from 'services/routehelpers';

import { t } from '@lingui/macro';

class PayrollReconciliationTableContainer extends Component {
  static propTypes = {
    /** @type {shape} payrollOverviewData */
    payrollOverviewData: PropTypes.shape({}),
    /** @type {shape} company */
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
      currentWorkingPeriodEnd: PropTypes.number.isRequired,
      currentWorkingPeriodStart: PropTypes.number.isRequired,
    }).isRequired,
    /** @type {shape} user */
    user: PropTypes.shape({}).isRequired,
    /** @type {shape} history Router object */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    /** @type {shape} location Router object */
    location: PropTypes.shape({}).isRequired,
    /** @type {bool} loadingPayrollOverviewData */
    loadingPayrollOverviewData: PropTypes.bool,
    /** @type {bool} failed */
    failed: PropTypes.bool,
    /** @type {func} roleType */
    roleType: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    payrollOverviewData: undefined,
    loadingPayrollOverviewData: false,
    failed: false,
  };

  state = {
    addComments: false,
    payrollReconciliationFileUpload: false,
  };

  toggleModal = (modelName) => {
    this.setState((prevState) => ({
      [modelName]: !prevState[modelName],
    }));
  };

  handleIconGroup = (id) => {
    switch (id) {
      case 1:
        this.toggleModal('addComments');
        break;
      case 2:
        this.toggleModal('payrollReconciliationFileUpload');
        break;
      default:
    }
  };

  render() {
    const { addComments, payrollReconciliationFileUpload } = this.state;
    const { user, company, roleType, history } = this.props;
    const componentSetupValues = {
      commentModalType: 'payrollComment',
      commentsRoutes: commentsRouteInitializer('payrollComment', {}),
    };
    return (
      <>
        {addComments && (
          <CommentsModal
            showModal={addComments}
            {...{ componentSetupValues, user, company, roleType }}
            toggleModal={() => this.toggleModal('addComments')}
          />
        )}
        {payrollReconciliationFileUpload && (
          <FileUploadModal
            {...{
              user,
              company,
              account: '',
              history,
              search: {},
              toRoute: toPayrollReconciliation,
              fileCategory: 'PAYROLL',
              roleType,
            }}
            modalTitle={t`Payroll File Upload`}
            showModal={payrollReconciliationFileUpload}
            toggleModal={() =>
              this.toggleModal('payrollReconciliationFileUpload')
            }
            uploadFilesTo="v1/secure/bank-transaction"
          />
        )}
        <PayrollReconciliationPage
          {...this.props}
          {...{
            handleIconGroup: this.handleIconGroup,
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
  user: fromAuth.getUser(state),
});
export default connect(mapStateToProps)(PayrollReconciliationTableContainer);
