import React, { Component } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';

import { TemplateSelectionList } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { getReconciliationGLAccountsFastApi } from 'services/apihelpers';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import {
  toReconciliation,
  toVATReconciliation,
  toPayrollReconciliation,
} from 'services/routehelpers';

class TemplateSelectionListContainer extends Component {
  static propTypes = {
    /** @type {shape} accountDetailsList */
    accountList: PropTypes.shape({}),
    /** @type {shape} company */
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
    }).isRequired,
    /** @type {shape} user */
    user: PropTypes.shape({
      /** @type {[string, number]} entries */
      entries: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired,
    /** @type {shape} location Router object */
    location: PropTypes.shape({}).isRequired,
    /** @type {shape} history Router object */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    /** @type {bool} loading */
    loading: PropTypes.bool,
  };

  static defaultProps = {
    accountList: undefined,
    loading: true,
  };

  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (props) => {
    const { readAccountList, match, company } = props || this.props;

    try {
      readAccountList({
        year: match?.params?.accountingYear,
        period: defineFrequencyPeriodTypes(
          company?.periodType,
          +match.params.accountingPeriod,
        ),
        companyId: match?.params?.companyId,
        periodType: company.periodType.toUpperCase(),
      });
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  getSearch = (props) => {
    const { location } = props || this.props;
    const search = location ? queryString.parse(location.search) : {};

    return {
      account: search.account || undefined,
    };
  };

  handleChange = (value) => {
    const { history, accountList } = this.props;
    const search = this.getSearch();
    const account = !isEmpty(value) ? value[0].id : undefined;

    let pushTo;
    let selectedItemAccount = { accountTemplate: {} };

    if (account !== search.account) {
      accountList.map((item) => {
        if (item.accountID === account) {
          selectedItemAccount = item;
        }
        return '';
      });

      const newSearch = {
        recordFilterList: ['OPEN', 'APPROVED', 'PARTIAL_RECONCILED'],
        ...search,
        account,
      };

      if (account) {
        const { accountTemplateCode } = selectedItemAccount.accountTemplate;

        if (accountTemplateCode === 'PAYROLL') {
          pushTo = toPayrollReconciliation({ bimonthly: true });
        } else if (accountTemplateCode === 'VAT') {
          pushTo = toVATReconciliation();
        } else {
          pushTo = toReconciliation(newSearch);
        }

        this.setState({ selectedAccount: undefined });
        history.push(pushTo);
      } else {
        this.setState({ selectedAccount: value });
      }
    }
  };

  handleButtonClick = (direction) => {
    const { accountList } = this.props;
    const search = this.getSearch();
    const selectedAccount =
      typeof this.state.selectedAccount !== 'undefined'
        ? this.state.selectedAccount
        : search.account;

    let index = accountList.findIndex(
      (item) => item.accountId === selectedAccount,
    );

    if (direction === 'prev') {
      index -= 1;
    } else if (direction === 'next') {
      index += 1;
    }

    this.handleChange([{ id: accountList[index].accountId }]);
  };

  checkAvailable = (direction) => {
    const { accountList } = this.props;
    const search = this.getSearch();
    const selectedAccount =
      typeof this.state.selectedAccount !== 'undefined'
        ? this.state.selectedAccount
        : search.account;

    let disabled;

    if (!Array.isArray(accountList)) {
      disabled = true;
    } else {
      const index = accountList.findIndex(
        (item) => item.accountId === selectedAccount,
      );

      if (direction === 'prev') {
        disabled = index === 0;
      } else if (direction === 'next') {
        disabled = index === accountList.length - 1;
      }
    }

    return disabled;
  };

  render() {
    const search = this.getSearch();
    const { loading, history, location, accountList } = this.props;
    const selectedAccount =
      typeof this.state.selectedAccount !== 'undefined'
        ? this.state.selectedAccount
        : search.account;

    return (
      <TemplateSelectionList
        data={accountList}
        search={search}
        loading={loading}
        history={history}
        location={location}
        disabledPrev={this.checkAvailable('prev')}
        disabledNext={this.checkAvailable('next')}
        handleChange={this.handleChange}
        selectedAccount={selectedAccount}
        handleButtonClick={this.handleButtonClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  loading: pending(state, `${getReconciliationGLAccountsFastApi}ListRead`),
  accountList: fromResource.getList(state, getReconciliationGLAccountsFastApi),
});
const mapDispatchToProps = (dispatch) => ({
  readAccountList: (query) =>
    dispatch(
      resourceListReadRequest(getReconciliationGLAccountsFastApi, query),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateSelectionListContainer);
