import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from 'components';

class TablePaginationContainer extends Component {
  static propTypes = {
    /** @type {func} toRoute */
    toRoute: PropTypes.func.isRequired,
    /** @type {shape} search */
    search: PropTypes.shape({
      /** @type {[string, number]} entries */
      entries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /** @type {[string, number]} page */
      page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    results: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedRows: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    /** @type {shape} history */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    /** @type {string} tableName */
    tableName: PropTypes.string,
  };

  static defaultProps = {
    tableName: '',
    results: 0,
    selectedRows: [],
  };

  handleChange = (page) => {
    const { id, search, history, toRoute, tableName, selectedRows } =
      this.props;

    if (search[`page${tableName}`] !== `page${tableName}`) {
      let selectedRowsItems;
      if (selectedRows.length > 0) {
        selectedRowsItems = JSON.stringify([...selectedRows]);
      }

      const newSearch = {
        ...search,
        selectedRows: selectedRowsItems,
        [`page${tableName}`]: page,
      };

      history.push(toRoute(newSearch, id));
    }
  };

  render() {
    const { results, search, tableName } = this.props;
    const { entries } = search;

    return (
      <TablePagination
        {...{
          ...this.props,
          entries: parseInt(entries, 10) || 0,
          page: parseInt(search[`page${tableName}`], 10) || 1,
          results: parseInt(results, 10),
          onSelect: this.handleChange,
        }}
      />
    );
  }
}

export default TablePaginationContainer;
