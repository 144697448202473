import { t } from '@lingui/macro';
import { formatDate } from 'services/helpers';

const subtitle = { exported: true };

const getTitleRow = (title, extraProps = {}) => ({
  ...extraProps,
  subtitle,
  uiGroupId: 1,
  vatDescription: title,
});

// Since BE return always accumulated gl values we need to remove value of previous periods from current one
const getGlValue = (glSums, period) => {
  const valuePerCurrentPeriod = glSums.find(
    (item) => item.period === period,
  )?.amount;

  if (period === 1) {
    return valuePerCurrentPeriod;
  }

  const valuePerPreviousPeriod = glSums.find(
    (item) => item.period === period - 1,
  )?.amount;

  return valuePerCurrentPeriod - valuePerPreviousPeriod;
};

export const vatOverviewFormatter = ({
  data,
  reports,
  showAll,
  limitPeriods,
  accumulated = true,
}) => {
  const findValueByPeriod = (item) => item.period === limitPeriods;

  const generateRow = (row, type) => {
    const { differences, glSums, reportedSums, reportedValues, ...rest } = row;
    const finalRow = { ...rest };

    const vatComment = row.vatComments?.find(
      (comment) => comment.period === limitPeriods,
    );
    finalRow.parentPath = type;
    finalRow.id = Math.floor(Math.random() * Date.now()).toString(16);
    finalRow.vatComment = vatComment;
    finalRow.comment = vatComment?.comment;

    Object.keys(reportedValues).forEach((key) => {
      const { period, amount } = reportedValues[key];
      finalRow[`period${period}`] = amount;
    });

    finalRow.reportedSum = reportedSums.find(findValueByPeriod)?.amount;
    finalRow.glSum = accumulated
      ? glSums.find(findValueByPeriod)?.amount
      : getGlValue(glSums, limitPeriods);
    finalRow.difference = accumulated
      ? differences.find(findValueByPeriod)?.amount
      : finalRow[`period${limitPeriods}`] - finalRow.glSum;

    return finalRow;
  };

  const getSumRow = (title, row) => ({
    subtitle,
    vatDescription: title,
    ...generateRow(row),
  });

  const getTotals = (totals) =>
    totals.map((row) => {
      const { description: vatDescription, ...rest } = row;
      return { vatDescription, ...generateRow(rest) };
    });

  const getFilteredRows = (rows, type) => {
    const unFilteredRows = rows.map((row) => generateRow(row, type));

    if (showAll) return unFilteredRows;

    return unFilteredRows.filter((row) => {
      const { vatCode, vatDescription, ratePercentage, ...numberFields } = row;
      const fieldsToFilter = ['glSum', 'difference', 'reportedSum'];

      Object.keys(numberFields).forEach((key) => {
        if (key.includes('period')) {
          const period = +key.slice(6);

          if (period <= limitPeriods) {
            fieldsToFilter.push(key);
          }
        }
      });

      return fieldsToFilter.reduce(
        (value, next) => value || numberFields[next],
        false,
      );
    });
  };

  const rows = [];
  const additionalTotalRows = [];

  if (data?.additionalRows) {
    const { approvedStatus, files } = data.additionalRows;

    if (approvedStatus || files) {
      rows.push(getTitleRow(t`Status`, { disableExport: true }));
    }

    if (approvedStatus) {
      const { title, type, statuses } = approvedStatus;

      const approvedRow = {
        type,
        vatDescription: title,
        id: 'approved',
        disableExport: true,
      };

      statuses.forEach((item) => {
        const { period, ...rest } = item;
        approvedRow[`period${period}`] = { ...rest, period };
        if (reports && Array.isArray(reports)) {
          approvedRow[`period${period}`].reportVersions =
            reports.filter(
              (report) => report.accountingPeriod / 2 === period,
            ) || [];
        }
      });

      rows.push(approvedRow);
    }

    if (files) {
      const { title, type, files: vatFiles } = files;

      const filesRow = {
        type,
        vatDescription: title,
        id: 'files',
        disableExport: true,
      };

      const archievedDataRow = {
        type: 'string',
        vatDescription: t`Archived Time`,
      };

      vatFiles.forEach((item) => {
        const { period, ...rest } = item;
        filesRow[`period${period}`] = { ...rest, period };
        archievedDataRow[`period${period}`] = rest?.xml?.archivedDateTime
          ? formatDate(rest.xml.archivedDateTime, true)
          : '';
      });

      rows.push(filesRow);
      rows.push(archievedDataRow);
    }
  }

  if (data?.base) {
    const { rows: baseRows, title, totalName, ...baseRest } = data.base;
    const {
      rows: salesRows,
      totalName: salesTotalName,
      ...salesRest
    } = data.base.sales;

    const filteredSalesRows = getFilteredRows(salesRows, 'base.sales');
    const filteredBaseRows = getFilteredRows(baseRows, 'base');

    if (filteredSalesRows.length || filteredBaseRows.length) {
      rows.push(getTitleRow(title));

      if (filteredSalesRows.length) {
        rows.push(...filteredSalesRows);
        rows.push(getSumRow(salesTotalName, salesRest));
      }

      if (filteredBaseRows.length) {
        rows.push(...filteredBaseRows);
      }

      rows.push(getSumRow(totalName, baseRest));
    }
  }

  if (data?.outputVAT) {
    const {
      title,
      totalName,
      rows: outputRows,
      ...outputRest
    } = data.outputVAT;

    const filteredRows = getFilteredRows(outputRows, 'outputVAT');

    if (filteredRows.length) {
      rows.push(getTitleRow(title));
      rows.push(...filteredRows);
      rows.push(getSumRow(totalName, outputRest));
    }
  }

  if (data?.inputVAT) {
    const { title, totalName, rows: inputRows, ...inputRest } = data.inputVAT;

    const filteredRows = getFilteredRows(inputRows, 'inputVAT');

    if (filteredRows.length) {
      rows.push(getTitleRow(title));
      rows.push(...filteredRows);
      rows.push(getSumRow(totalName, inputRest));
    }
  }

  if (data?.outputTotals) {
    const { outputTotals, inputTotals, differences } = data;

    additionalTotalRows.push(
      ...getTotals([outputTotals, inputTotals, differences]),
    );
  }

  if (data?.settlements) {
    const { differences, mappedAmounts } = data.settlements;

    const transactionsRow = {};

    const mappedPeriodValues = mappedAmounts.reduce(
      (res, next) => {
        const { transactions } = next;

        res[`period${next.period}`] = next.amount;

        const transaction =
          transactions.length > 1
            ? transactions[transactions.length - 1]
            : transactions[0];

        const { transactionId, transactionDate } = transaction;
        const value = `${transactionId} (${formatDate(transactionDate)})`;

        transactionsRow[`period${next.period}`] =
          transactions.length > 1 ? `${value}...` : value;

        transactionsRow[`period${next.period}_tooltip`] =
          transactions.length > 1 &&
          transactions
            .map(
              (tr) => `${tr.transactionId} (${formatDate(tr.transactionDate)})`,
            )
            .join(', ');

        return res;
      },
      Object.assign(
        ...Array.from({ length: limitPeriods }, (_, i) => ({
          [`period${i + 1}`]: 0,
        })),
      ),
    );

    const mappedDiffValues = differences.reduce((res, next) => {
      res[`period${next.period}`] = next.amount;

      return res;
    }, {});

    additionalTotalRows.push({
      vatDescription: t`Transaction To Pay / Transaction To Receive`,
      tooltip: t`Match Transactions`,
      withAction: (value) => typeof value === 'number' && 'openSettlements',
      ...mappedPeriodValues,
    });

    additionalTotalRows.push({
      vatDescription: t`Difference`,
      ...mappedDiffValues,
    });

    additionalTotalRows.push({
      vatDescription: t`Matched Transaction ID and date`,
      ...transactionsRow,
    });
  }
  return { rows, additionalTotalRows, reports };
};

export default vatOverviewFormatter;
