import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import {
  Card,
  AnimateAlert,
  TableIconGroup,
  OpeningBalanceRegistration,
} from 'components';

import TableHeader from 'containers/TableHeader';
import CardPDFTitle from 'containers/CardPDFTitle';
import TablePagination from 'containers/TablePagination';
import MapTransactionIdModal from 'containers/MapTransactionIdModal';

import { openingBalanceColumns } from 'services/tableshapes';
import { openingBalanceIconShape } from 'services/TableIconGroupShapes';
import { toOpeningBalanceRegistration } from 'services/routehelpers';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import { resourceListReadRequest, resourceCreateRequest } from 'store/actions';
import {
  getGeneralLedgerDataApi,
  getPartialTransactionApi,
  savePartialTransactionApi,
  editPartialTransactionApi,
  deletePartialTransactionApi,
  deletePartialTransactionListApi,
  getReconciliationGLAccountListApi,
} from 'services/apihelpers';

// TODO: This file can surely be refactored...
class OpeningBalanceRegistrationContainer extends Component {
  static propTypes = {
    /** @type {shape} location Router object */
    location: PropTypes.shape({}).isRequired,
    /** @type {shape} history Router object */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    /** @type {bool} loading */
    loading: PropTypes.bool,
    /** @type {shape} user */
    user: PropTypes.shape({}).isRequired,
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
    }).isRequired,
    /** @type {string} account */
    account: PropTypes.string.isRequired,
    /** @type {string} template */
    readAccountList: PropTypes.func.isRequired,
    getPartialTransaction: PropTypes.func.isRequired,
    deletePartialTransaction: PropTypes.func.isRequired,
    editPartialTransaction: PropTypes.func.isRequired,
    savePartialTransaction: PropTypes.func.isRequired,
    /** @type {bool} failed */
    failed: PropTypes.bool,
    /** @type {bool} onSubLedgerLoading */
    onSubLedgerLoading: PropTypes.bool,
    /** @type {bool} onReadDeviationFailed */
    onReadDeviationFailed: PropTypes.bool,
    /** @type {shape} subLedgerDetailsList */
    accountList: PropTypes.shape({}).isRequired,
    /** @type {shape} openingBalanceList */
    openingBalanceList: PropTypes.shape({}).isRequired,
    /** @type {shape} roleType */
    roleType: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    loading: false,
    failed: false,
    onSubLedgerLoading: false,
    onReadDeviationFailed: false,
  };

  state = {
    showWarn: false,
    openingBalanceRegistrationRows: [],
    savedOpeningBalanceRegistrationRows: [],
    selectedRowIds: [],
    editRowIds: [],
    balance: 0,
    toggleMapTransactionsIdModal: false,
    addComments: false,
    editMode: false,
    totalNoOfRecords: 0,
  };

  componentDidMount() {
    const { company } = this.props;

    this.loadTableData(company);
    this.loadOpeningBalanceData();
  }

  shouldComponentUpdate(nextProps) {
    const { location, company } = this.props;

    if (
      nextProps.location.search !== location.search ||
      nextProps.company.currentCompanySID !== company.currentCompanySID ||
      nextProps.company.currentAccountingYear !==
        company.currentAccountingYear ||
      nextProps.company.currentWorkingPeriodEnd !==
        company.currentWorkingPeriodEnd ||
      nextProps.company.currentWorkingPeriodStart !==
        company.currentWorkingPeriodStart
    ) {
      this.loadTableData(nextProps.company, nextProps);
      this.loadOpeningBalanceData(nextProps);
    }

    return true;
  }

  getSearch = (props) => {
    const { location, user } = props || this.props;
    const search = location
      ? queryString.parse(location.search, { parseBooleans: true })
      : {};

    return {
      page: search.page || 1,
      query: search.query || '',
      account: search.account || '',
      entries: search.entries || user.entries || 10,
      tabEntry: search.tabEntry || 'registration',
      reportMode: search.reportMode || false,
      periodType: search.periodType || 'quarterly',
      reconciliationStatus: '',
    };
  };

  /* eslint-disable class-methods-use-this */
  createQuery = (company, search) => ({
    ...search,
    year: company.currentAccountingYear,
    toPeriod: company.currentWorkingPeriodEnd || 12,
    companyId: company.currentCompanySID,
    searchText: search.query,
    fromPeriod: company.currentWorkingPeriodStart || 1,
    accountType: 'balance',
  });

  getSelectedRows = (selectedRows, rowId) => {
    const selectedRowsItems = new Map(selectedRows);
    const { openingBalanceRegistrationRows, editMode, openingBalanceData } =
      this.state;
    const search = this.getSearch();
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
    const savedOpeningBalanceRegistrationRowItems = new Map(openingBalanceData);
    const row = editMode
      ? savedOpeningBalanceRegistrationRowItems.get(rowId)
      : openingBalanceRowItems.get(rowId);

    let openingBalance = 0;
    selectedRowsItems.forEach((value) => {
      if (editMode) {
        if (savedOpeningBalanceRegistrationRowItems.has(rowId)) {
          savedOpeningBalanceRegistrationRowItems.set(rowId, {
            accountID: value.accountId,
            accountIdDescription: value.accountDescription,
            gltransactionId: value.sourceDocumentId,
            amount: value.amount,
            derivativeAmount: value.amount - row.partialAmount,
            description: row.description,
            partialAmount: row.partialAmount,
            partialLineSID: row.partialLineSID,
            originalRecordId: value.recordId,
            originalPartialAmount: row.partialAmount,
            originalDescription: row.description,
            originalGltransactionId: row.gltransactionId,
            originalDerivativeAmount: row.originalDerivativeAmount,
            originalPartialLineSID: row.partialLineSID,
            savedOriginalRecordId: row.originalRecordId,
            originalAccountId: row.accountID,
            originalAccountDescription: row.originalAccountDescription,
            originalAmount: row.originalAmount,
            enableEdit: true,
          });
        }
        this.setState({
          openingBalanceData: Array.from(
            savedOpeningBalanceRegistrationRowItems,
          ),
        });
      } else {
        if (openingBalanceRowItems.has(rowId)) {
          openingBalanceRowItems.set(rowId, {
            rowId,
            accountId: value.accountId,
            accountIdDescription: value.accountDescription,
            openingBalance: value.amount,
            calcOpeningBalance: value.amount,
            transactionId: value.sourceDocumentId,
            recordId: value.recordId,
            description: row.description,
            amount: row.amount,
            acumulatedValue: value.amount,
          });
        }
        openingBalance = value.amount;
        this.setState({
          openingBalanceRegistrationRows: Array.from(openingBalanceRowItems),
          search: {
            ...search,
            openingBalance,
          },
        });
      }
    });
  };

  loadTableData = (company, props) => {
    const { readAccountList } = props || this.props;
    const search = this.getSearch(props || this.props);

    readAccountList(this.createQuery(company, search));
  };

  loadOpeningBalanceData = async (props) => {
    const search = this.getSearch(props || this.props);
    const { getPartialTransaction, company } = props || this.props;
    const { savedOpeningBalanceRegistrationRows } = this.state;
    const savedOpeningBalanceRegistrationRowItems = new Map(
      savedOpeningBalanceRegistrationRows,
    );
    const response = await getPartialTransaction(
      this.createQuery(company, search),
    );

    if (response) {
      response.partialLine.map((item) => {
        savedOpeningBalanceRegistrationRowItems.set(item.partialLineSID, {
          rowId: item.partialLineSID,
          accountID: item.accountID,
          accountIdDescription:
            item.descriptionNOB !== undefined ? item.descriptionNOB : '',
          gltransactionId: item.gltransactionId,
          amount: item.amount,
          derivativeAmount: item.derivativeAmount,
          description: item.description,
          partialAmount: item.partialAmount,
          partialLineSID: item.partialLineSID,
          originalRecordId: item.originalRecordId,
          enableEdit: false,
          originalPartialAmount: item.partialAmount,
          originalDescription: item.description,
          originalGltransactionId: item.gltransactionId,
          originalDerivativeAmount: item.derivativeAmount,
          originalPartialLineSID: item.partialLineSID,
          savedOriginalRecordId: item.originalRecordId,
          originalAccountId: item.accountID,
          originalAccountDescription:
            item.descriptionNOB !== undefined ? item.descriptionNOB : '',
          originalAmount: item.amount,
        });
        return '';
      });
    }
    this.setState({
      openingBalanceData: Array.from(savedOpeningBalanceRegistrationRowItems),
      totalNoOfRecords: response.totalNoOfRecords,
    });
  };

  handleAddRows = () => {
    const { openingBalanceRegistrationRows } = this.state;
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
    if (openingBalanceRowItems.size === 0) {
      openingBalanceRowItems.set(openingBalanceRowItems.size + 1, {
        rowId: openingBalanceRowItems.size + 1,
        accountId: null,
        accountIdDescription: null,
        transactionId: '',
        description: '',
        acumulatedValue: 0,
        calcOpeningBalance: 0,
        amount: 0,
      });
    } else {
      openingBalanceRowItems.set(
        openingBalanceRowItems.get(
          Array.from(openingBalanceRowItems.keys()).pop(),
        ).rowId + 1,
        {
          rowId:
            openingBalanceRowItems.get(
              Array.from(openingBalanceRowItems.keys()).pop(),
            ).rowId + 1,
          accountId: null,
          transactionId: '',
          description: '',
          acumulatedValue: 0,
          calcOpeningBalance: 0,
          amount: 0,
        },
      );
    }

    this.setState({
      openingBalanceRegistrationRows: Array.from(openingBalanceRowItems),
      editMode: false,
    });
  };

  handleDeleteRow = (rowId) => {
    const { openingBalanceRegistrationRows } = this.state;
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);

    openingBalanceRowItems.delete(rowId);

    this.setState({
      openingBalanceRegistrationRows: Array.from(openingBalanceRowItems),
    });
  };

  handleCopyRows = (rowId) => {
    const { openingBalanceRegistrationRows } = this.state;
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
    const row = openingBalanceRowItems.get(rowId);
    if (openingBalanceRowItems.has(rowId + 1)) {
      openingBalanceRowItems.set(
        openingBalanceRowItems.get(
          Array.from(openingBalanceRowItems.keys()).pop(),
        ).rowId + 1,
        {
          rowId:
            openingBalanceRowItems.get(
              Array.from(openingBalanceRowItems.keys()).pop(),
            ).rowId + 1,
          accountId: row.accountId,
          accountIdDescription: row.accountIdDescription,
          transactionId: row.transactionId,
          description: row.description,
          recordId: row.recordId,
          openingBalance: row.openingBalance,
          acumulatedValue: row.acumulatedValue,
          calcOpeningBalance: row.acumulatedValue,
          amount: 0,
        },
      );
    } else {
      openingBalanceRowItems.set(rowId + 1, {
        rowId: rowId + 1,
        accountId: row.accountId,
        transactionId: row.transactionId,
        accountIdDescription: row.accountIdDescription,
        description: row.description,
        recordId: row.recordId,
        openingBalance: row.openingBalance,
        amount: 0,
        acumulatedValue: row.acumulatedValue,
        calcOpeningBalance: row.acumulatedValue,
      });
    }
    this.setState({
      openingBalanceRegistrationRows: Array.from(openingBalanceRowItems),
    });
  };

  handleEditSavedRows = (rowId) => {
    const { openingBalanceData, editRowIds } = this.state;
    const savedOpeningBalanceRegistrationRowItems = new Map(openingBalanceData);
    const editRowIdItems = new Map(editRowIds);
    if (savedOpeningBalanceRegistrationRowItems.has(rowId) && rowId > 0) {
      savedOpeningBalanceRegistrationRowItems.set(rowId, {
        rowId,
        accountID: savedOpeningBalanceRegistrationRowItems.get(rowId).enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId).originalAccountId
          : savedOpeningBalanceRegistrationRowItems.get(rowId).accountID,
        gltransactionId: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalGltransactionId
          : savedOpeningBalanceRegistrationRowItems.get(rowId).gltransactionId,
        accountIdDescription: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalAccountDescription
          : savedOpeningBalanceRegistrationRowItems.get(rowId)
              .accountIdDescription || '',
        amount: savedOpeningBalanceRegistrationRowItems.get(rowId).enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId).originalAmount
          : savedOpeningBalanceRegistrationRowItems.get(rowId).amount,
        derivativeAmount: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalDerivativeAmount
          : savedOpeningBalanceRegistrationRowItems.get(rowId).amount -
            savedOpeningBalanceRegistrationRowItems.get(rowId).partialAmount,
        description: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalDescription
          : savedOpeningBalanceRegistrationRowItems.get(rowId).description,
        partialAmount: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalPartialAmount
          : savedOpeningBalanceRegistrationRowItems.get(rowId).partialAmount,
        partialLineSID: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .originalPartialLineSID
          : savedOpeningBalanceRegistrationRowItems.get(rowId).partialLineSID,
        originalRecordId: savedOpeningBalanceRegistrationRowItems.get(rowId)
          .enableEdit
          ? savedOpeningBalanceRegistrationRowItems.get(rowId)
              .savedOriginalRecordId
          : savedOpeningBalanceRegistrationRowItems.get(rowId).originalRecordId,
        enableEdit:
          !savedOpeningBalanceRegistrationRowItems.get(rowId).enableEdit,
        originalPartialAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalPartialAmount,
        originalDescription:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalDescription,
        originalGltransactionId:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalGltransactionId,
        originalDerivativeAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalDerivativeAmount,
        originalPartialLineSID:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalPartialLineSID,
        savedOriginalRecordId:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .savedOriginalRecordId,
        originalAccountId:
          savedOpeningBalanceRegistrationRowItems.get(rowId).originalAccountId,
        originalAccountDescription:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalAccountDescription,
        originalAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId).originalAmount,
      });
      if (savedOpeningBalanceRegistrationRowItems.get(rowId).enableEdit) {
        editRowIdItems.set(
          rowId,
          savedOpeningBalanceRegistrationRowItems.get(rowId).partialLineSID,
        );
      } else {
        editRowIdItems.delete(rowId);
      }
    }
    this.setState({
      openingBalanceData: Array.from(savedOpeningBalanceRegistrationRowItems),
      editMode: true,
      editRowIds: Array.from(editRowIdItems),
    });
  };

  handleEditSavedRowsChange = (event, rowId = 0) => {
    const { openingBalanceData } = this.state;
    const { target } = event;
    const { value, name } = target;
    const savedOpeningBalanceRegistrationRowItems = new Map(openingBalanceData);

    if (savedOpeningBalanceRegistrationRowItems.has(rowId) && rowId > 0) {
      savedOpeningBalanceRegistrationRowItems.set(rowId, {
        rowId,
        accountID: savedOpeningBalanceRegistrationRowItems.get(rowId).accountID,
        accountIdDescription:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .accountIdDescription || '',
        gltransactionId:
          savedOpeningBalanceRegistrationRowItems.get(rowId).gltransactionId,
        amount: savedOpeningBalanceRegistrationRowItems.get(rowId).amount,
        derivativeAmount:
          name === 'partialAmount'
            ? savedOpeningBalanceRegistrationRowItems.get(rowId).amount - value
            : savedOpeningBalanceRegistrationRowItems.get(rowId).amount -
              savedOpeningBalanceRegistrationRowItems.get(rowId).partialAmount,
        description:
          savedOpeningBalanceRegistrationRowItems.get(rowId).description,
        partialAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId).partialAmount,
        partialLineSID:
          savedOpeningBalanceRegistrationRowItems.get(rowId).partialLineSID,
        originalRecordId:
          savedOpeningBalanceRegistrationRowItems.get(rowId).originalRecordId,
        enableEdit:
          savedOpeningBalanceRegistrationRowItems.get(rowId).enableEdit,
        originalPartialAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalPartialAmount,
        originalDescription:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalDescription,
        originalGltransactionId:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalGltransactionId,
        originalDerivativeAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalDerivativeAmount,
        originalPartialLineSID:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalPartialLineSID,
        savedOriginalRecordId:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .savedOriginalRecordId,
        originalAccountId:
          savedOpeningBalanceRegistrationRowItems.get(rowId).originalAccountId,
        originalAccountDescription:
          savedOpeningBalanceRegistrationRowItems.get(rowId)
            .originalAccountDescription,
        originalAmount:
          savedOpeningBalanceRegistrationRowItems.get(rowId).originalAmount,
        [name]: value,
      });
    }
    this.setState({
      openingBalanceData: Array.from(savedOpeningBalanceRegistrationRowItems),
    });
  };

  /* eslint-disable default-param-last */
  handleChange = async (event, rowId = 0, accountSID) => {
    const { openingBalanceRegistrationRows } = this.state;
    const { target } = event;
    const { value, name } = target;
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
    if (openingBalanceRowItems.has(rowId) && rowId > 0) {
      openingBalanceRowItems.set(rowId, {
        rowId,
        accountSID,
        acumulatedValue:
          name === 'amount'
            ? openingBalanceRowItems.get(rowId).calcOpeningBalance - value
            : openingBalanceRowItems.get(rowId).acumulatedValue,
        calcOpeningBalance:
          openingBalanceRowItems.get(rowId).calcOpeningBalance,
        accountId: openingBalanceRowItems.get(rowId).accountId,
        openingBalance: openingBalanceRowItems.get(rowId).openingBalance,
        recordId: openingBalanceRowItems.get(rowId).recordId,
        transactionId: openingBalanceRowItems.get(rowId).transactionId,
        description: openingBalanceRowItems.get(rowId).description,
        accountIdDescription:
          openingBalanceRowItems.get(rowId).accountIdDescription,
        amount: openingBalanceRowItems.get(rowId).amount,
        [name]: value,
      });

      this.setState({
        openingBalanceRegistrationRows: Array.from(openingBalanceRowItems),
      });
    }
  };

  toggleShowWarn = () => {
    const { showWarn } = this.state;
    this.setState({ showWarn: !showWarn });
  };

  handleDeleteRows = async () => {
    const { company, deletePartialTransactionList } = this.props;
    const { openingBalanceRegistrationRows, openingBalanceData } = this.state;
    const partialLineList = openingBalanceData.map((item) => item[0]);
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);

    await deletePartialTransactionList({
      partialLineList,
      year: company.currentAccountingYear,
      companyId: company.currentCompanySID,
    });

    this.loadOpeningBalanceData();

    if (openingBalanceRowItems.size > 0) {
      this.setState({
        openingBalanceRegistrationRows: [],
      });
    }

    this.toggleShowWarn();
  };

  handleDeleteSavedRow = async (id) => {
    const { company, deletePartialTransaction } = this.props;
    const query = {
      year: company.currentAccountingYear,
      companyId: company.currentCompanySID,
      partialLineSID: id,
    };

    await deletePartialTransaction(query);
    this.loadOpeningBalanceData();
  };

  saveRow = async () => {
    const {
      openingBalanceRegistrationRows,
      openingBalanceData,
      editMode,
      editRowIds,
    } = this.state;
    const { company, editPartialTransaction, savePartialTransaction } =
      this.props;

    const savePartialTransactionDtoList = [];
    const editPartialTransactionDtoList = [];
    const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
    const savedOpeningBalanceRegistrationRowItems = new Map(openingBalanceData);
    const editRowIdItems = new Map(editRowIds);

    if (editMode) {
      editRowIdItems.forEach((item) => {
        editPartialTransactionDtoList.push({
          amount: savedOpeningBalanceRegistrationRowItems.get(item).amount,
          companyId: company.currentCompanySID,
          description:
            savedOpeningBalanceRegistrationRowItems.get(item).description,
          partialAmount: parseFloat(
            savedOpeningBalanceRegistrationRowItems.get(item).partialAmount,
          ),
          partialLineSID:
            savedOpeningBalanceRegistrationRowItems.get(item).partialLineSID,
          recordID:
            savedOpeningBalanceRegistrationRowItems.get(item).originalRecordId,
          year: company.currentAccountingYear,
        });
        savedOpeningBalanceRegistrationRowItems.get();
      });
      const query = {
        editPartialTransactionDtoList,
      };

      await editPartialTransaction(query);
    } else {
      openingBalanceRowItems.forEach((item) => {
        savePartialTransactionDtoList.push({
          recordID: item.recordId,
          description: item.description,
          partialAmount: parseFloat(item.amount),
          amount: item.openingBalance,
          companyId: company.currentCompanySID,
          year: company.currentAccountingYear,
          generalLedgerAccountSID: item.accountId,
        });
      });
      const query = {
        savePartialTransactionDtoList,
      };

      await savePartialTransaction(query);
    }
    this.loadOpeningBalanceData();
    this.setState({
      openingBalanceRegistrationRows: [],
    });
  };

  toggleModal = (modelName, rowId = null) => {
    this.setState((prevState) => ({
      [modelName]: !prevState[modelName],
    }));

    if (rowId !== null) {
      this.setState({ rowId });
    }
  };

  handleIconGroup = (id, reconcileSID, rowId) => {
    switch (id) {
      case 1:
        this.handleAddRows();
        break;
      case 2:
        this.saveRow(reconcileSID);
        break;
      case 3:
        this.toggleShowWarn();
        break;
      case 4:
        this.toggleModal('addComments');
        break;
      case 5:
        this.handleDeleteRow(rowId);
        break;
      case 6:
        this.toggleModal('toggleMapTransactionsIdModal', rowId);
        break;
      case 7:
        this.handleCopyRows(rowId);
        break;
      case 8:
        this.handleDeleteSavedRow(rowId);
        break;
      case 9:
        this.handleEditSavedRows(rowId);

        break;
      default:
    }
  };

  render() {
    const search = this.getSearch();
    const {
      rowId,
      showWarn,
      totalNoOfRecords,
      openingBalanceData,
      toggleMapTransactionsIdModal,
      openingBalanceRegistrationRows,
    } = this.state;
    const {
      user,
      failed,
      company,
      history,
      loading,
      roleType,
      accountList: data,
    } = this.props;
    const commonProps = {
      toRoute: toOpeningBalanceRegistration,
      history,
      search,
    };

    return (
      <Card>
        <CardPDFTitle
          title={
            <Trans>
              Opening Balance Registration for Account {search.account}
            </Trans>
          }
        />
        <AnimateAlert
          showCancel
          closeOnClickOutside
          show={showWarn}
          type="warning"
          title="Warning"
          onCancel={this.toggleShowWarn}
          onConfirm={this.handleDeleteRows}
        >
          <Trans>
            Please confirm that you would like to remove all the rows!!!
          </Trans>
        </AnimateAlert>
        {toggleMapTransactionsIdModal && (
          <MapTransactionIdModal
            {...{
              rowId,
              search,
              openingBalanceRegistrationRows,
              toggleMapTransactionsIdModal,
              account: search.account,
              handleIconGroup: this.handleIconGroup,
              getSelectedRows: this.getSelectedRows,
            }}
          />
        )}
        <Row>
          <Col sm={12} md={6}>
            <ButtonsHolder>
              <TableIconGroup
                {...{
                  handleIconGroup: this.handleIconGroup,
                  shape: openingBalanceIconShape,
                  roleType,
                }}
              />
            </ButtonsHolder>
          </Col>
        </Row>
        <OpeningBalanceRegistration
          handleRowClick={this.handleRowClick}
          handleApproveChange={this.handleApproveChange}
          {...{
            data,
            lineData: openingBalanceData,
            header: (
              <TableHeader
                {...{ columns: openingBalanceColumns, ...commonProps }}
              />
            ),
            previewDataHeader: (
              <TableHeader
                {...{
                  columns: openingBalanceColumns,
                  search: { ...search, showAll: true },
                }}
              />
            ),
            handleReloadTable: this.loadTableData,
            handleIconGroup: this.handleIconGroup,
            handleChange: this.handleChange,
            handleEditSavedRowsChange: this.handleEditSavedRowsChange,
            openingBalanceRegistrationRows,
            search,
            company,
            user,
            loading,
            failed,
            roleType,
          }}
        />
        {!loading && (
          <TablePagination
            {...{
              results: totalNoOfRecords,
              ...commonProps,
            }}
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  accountList: fromResource.getList(state, getReconciliationGLAccountListApi),
  accountDetailsList: fromResource.getList(state, getGeneralLedgerDataApi),
});

const mapDispatchToProps = (dispatch) => ({
  readAccountList: (query) =>
    dispatch(resourceListReadRequest(getReconciliationGLAccountListApi, query)),
  getPartialTransaction: (query) =>
    dispatch(resourceListReadRequest(getPartialTransactionApi, query)),
  deletePartialTransaction: (query) =>
    dispatch(resourceCreateRequest(deletePartialTransactionApi, query)),
  deletePartialTransactionList: (query) =>
    dispatch(resourceCreateRequest(deletePartialTransactionListApi, query)),
  editPartialTransaction: (query) =>
    dispatch(resourceCreateRequest(editPartialTransactionApi, query)),
  savePartialTransaction: (query) =>
    dispatch(resourceCreateRequest(savePartialTransactionApi, query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpeningBalanceRegistrationContainer);

const ButtonsHolder = styled.div`
  display: flex;
  justify-content: flex-end;
`;
