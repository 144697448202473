import { t } from '@lingui/macro';
import structuredClone from '@ungap/structured-clone';

export const reportedSalaryFormatter = (
  data,
  bimonthly,
  accumulated,
  onlyNumbers,
) => {
  let result = [];

  const list = data?.manual ? data.manualVo || [] : data.vo || [];

  if (list.length) {
    result = list.reduce(
      (items, nextItem) => {
        const item = structuredClone(nextItem);

        if (accumulated) {
          item.a07Reported = item.accumulatedA07Reported;
          item.glreported = item.accumulatedGLReported;
          item.a07NoSocSec = item.accumulatedA07NoSocSec;
          item.glsocSec = item.accumulatedGLSocSec;
        } else if (bimonthly) {
          item.a07Reported = item.bimonthlyA07Reported;
          item.glreported = item.bimonthlyGLReported;
          item.a07NoSocSec = item.bimonthlyA07NoSocSec;
          item.glsocSec = item.bimonthlyGLSocSec;
        }

        const withNumber =
          item.a07Reported ||
          item.glreported ||
          item.a07NoSocSec ||
          item.glsocSec;

        if (onlyNumbers && !withNumber) {
          return items;
        }

        return [...items, item];
      },
      [
        {
          reportType: t`BASIS OF EMPLOYER DUTY:`,
          subtitle: true,
        },
      ],
    );
  }

  return result;
};

export default reportedSalaryFormatter;
