import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 100,
    Header: t`Report Period`,
    accessor: 'period',
    className: '',
  },
  {
    width: 100,
    Header: t`Sender`,
    accessor: 'sender',
    className: '',
  },
  {
    width: 100,
    Header: t`ID`,
    accessor: 'id',
    className: '',
  },
  {
    width: 100,
    Header: t`Delivery Date`,
    accessor: 'date',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
];

export default { columns };
