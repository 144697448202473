import { t } from '@lingui/macro';
import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome';

import { toDashboard } from 'services/routehelpers';

export const ERROR_BOX = () => ({
  styles: {
    balances: {
      background: 'rgba(255, 255, 255, 0.4)',
      textColor: 'black',
      boxColor: '#f3f3f3',
      detailsColor: '#cfcfce',
      height: '85vh',
    },
    basic: {
      background: '#6169ed',
      textColor: 'white',
      boxColor: '#6971fd',
      detailsColor: '#a6aafb',
      height: '70vh',
    },
  },
  button: {
    balances: {
      text: t`Go home`,
      icon: AiFillHome,
      redirect: () => toDashboard(),
    },
    basic: {
      text: t`Log out`,
      icon: AiFillHome,
    },
  },
});

export default ERROR_BOX;
