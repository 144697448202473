import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Panel, Paragraph } from 'components';
import isEmpty from 'lodash-es/isEmpty';

function SystemInfoPanel(props) {
  const { loading, systemInfo } = props;
  const { erp_name: accountingSystemName, last_updated: lastUpdated } =
    systemInfo || {};
  return (
    <>
      {!loading && !isEmpty(systemInfo) && (
        <Panel toggle collapsible title={t`Integration Info`}>
          <Paragraph>
            <Trans>Accounting System: {accountingSystemName}</Trans>
          </Paragraph>
        </Panel>
      )}
      {!loading && !isEmpty(systemInfo) && (
        <Paragraph>
          {lastUpdated && typeof lastUpdated === 'string' ? (
            <Trans>
              Last updated:{' '}
              {i18n.date(lastUpdated, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Trans>
          ) : (
            <Trans>Last updated: Never</Trans>
          )}
        </Paragraph>
      )}
    </>
  );
}

SystemInfoPanel.propTypes = {
  loading: PropTypes.bool,
  systemInfo: PropTypes.shape({
    token: PropTypes.string,
    username: PropTypes.string,
    systemEntityId: PropTypes.string,
    lastUpdateDateTime: PropTypes.string,
    accountingSystemName: PropTypes.string,
  }),
};

SystemInfoPanel.defaultProps = {
  loading: true,
  // failed: false,
  systemInfo: {},
};

export default SystemInfoPanel;
