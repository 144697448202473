import React, { Component } from 'react';

import { TableModals } from 'components';

class TableModalsContainer extends Component {
  state = {};

  toggleModal = (modal, account, files) => {
    const { afterCloseModal } = this.props;
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));

    if (typeof afterCloseModal === 'function' && this.state[modal]) {
      afterCloseModal(modal, account, files);
    }
  };

  render() {
    return (
      <TableModals
        {...{
          ...this.state,
          ...this.props,
          toggleModal: this.toggleModal,
        }}
      />
    );
  }
}

export default TableModalsContainer;
