import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { Link } from 'components';

import { isValidDate, formatNumber, formatDate } from 'services/helpers';

/**
 * Table Cell component
 */
function TableCell(props) {
  const {
    to,
    kind,
    date,
    style,
    width,
    strong,
    number,
    italic,
    heading,
    rounded,
    dateTime,
    sortable,
    overflow,
    children,
    noBorder,
    className,
    hideNumber,
    extraChildren,
    ...rest
  } = props;
  // Custom Bootstrap contextual classes

  const location = useLocation();
  const { reportMode } = queryString.parse(location.search, {
    parseBooleans: true,
  });
  let cellVal = '';
  let numberSelect;
  let textColor;
  if (children) {
    cellVal = children;
  }
  if (Number.isFinite(+cellVal) && number) {
    numberSelect = formatNumber(cellVal, rounded);
    cellVal = hideNumber ? '' : numberSelect.formatted;
    textColor = numberSelect.className;
  } else {
    textColor = undefined;
  }

  if (date && isValidDate(cellVal)) {
    cellVal = formatDate(cellVal, dateTime);
  }

  if (to) {
    cellVal = (
      <Link key="link" className={textColor} to={to}>
        {cellVal}
      </Link>
    );
  }

  if (italic) {
    cellVal = (
      <em key="em" style={{ fontStyle: 'italic' }}>
        {cellVal}
      </em>
    );
  }

  const overflowStyle = overflow
    ? {
        maxWidth: '0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    : undefined;

  const headerStyle = heading
    ? {
        margin: '10px 0',
        cursor: sortable ? 'pointer' : undefined,
        fontSize: reportMode ? '10px' : '12.5px',
        textTransform: 'uppercase',
      }
    : {
        fontSize: reportMode ? '10px' : '12.5px',
      };

  const customStyle = {
    padding: '5px',
    fontFeatureSettings: number || date ? 'tnum' : undefined,
    ...style,
    ...headerStyle,
    ...overflowStyle,
  };

  if (noBorder) {
    customStyle[
      `border${noBorder.charAt(0).toUpperCase() + noBorder.slice(1)}`
    ] = 'none';
  }

  if (width) {
    customStyle.width = `${width}%`;
  }

  const classes = classNames(kind, textColor, className);
  const firstChild = strong
    ? React.createElement(
        'strong',
        {
          className: classes,
          style: customStyle,
          key: 'firstChild',
          ...rest,
        },
        cellVal,
      )
    : cellVal;

  return React.createElement(
    heading ? 'th' : 'td',
    {
      style: customStyle,
      className: classes,
      ...rest,
    },
    [firstChild, extraChildren],
  );
}

TableCell.propTypes = {
  to: PropTypes.string,
  kind: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'active']),
  date: PropTypes.bool,
  style: PropTypes.shape({}),
  width: PropTypes.number,
  strong: PropTypes.bool,
  number: PropTypes.bool,
  italic: PropTypes.bool,
  heading: PropTypes.bool,
  rounded: PropTypes.bool,
  dateTime: PropTypes.bool,
  sortable: PropTypes.bool,
  overflow: PropTypes.bool,
  children: PropTypes.node,
  noBorder: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  className: PropTypes.string,
  hideNumber: PropTypes.bool,
  extraChildren: PropTypes.node,
};

TableCell.defaultProps = {
  to: undefined,
  kind: undefined,
  date: false,
  style: {},
  width: undefined,
  strong: false,
  number: false,
  italic: false,
  heading: false,
  rounded: false,
  dateTime: false,
  sortable: false,
  overflow: false,
  children: undefined,
  noBorder: undefined,
  className: undefined,
  hideNumber: false,
  extraChildren: undefined,
};

export default TableCell;
