import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { ButtonGroup } from 'react-bootstrap';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { HiMinus } from '@react-icons/all-files/hi/HiMinus';

import { Button, ButtonIcon } from 'components';

function RowSelectButtons(props) {
  const { handleRowSelect } = props;
  return (
    <ButtonGroup onClick={handleRowSelect}>
      <Button size="sm" id={1}>
        <ButtonIcon as={HiPlus} /> <Trans>Select all rows</Trans>
      </Button>
      <Button size="sm" id={2}>
        <ButtonIcon as={HiMinus} /> <Trans>Deselect all rows</Trans>
      </Button>
    </ButtonGroup>
  );
}

RowSelectButtons.propTypes = {
  handleRowSelect: PropTypes.func.isRequired,
};

export default RowSelectButtons;
