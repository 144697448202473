import React from 'react';
import { BasicTemplate } from 'components';
import RegistrationValidation from 'containers/RegistrationValidation';

/**
 * Registration Validation Page component
 */
function RegistrationValidationPage(props) {
  return (
    <BasicTemplate {...props}>
      <RegistrationValidation {...props} />
    </BasicTemplate>
  );
}

export default RegistrationValidationPage;
