import React, { useMemo } from 'react';
import { VATManualReconcileModal } from 'components';

import {
  getExpectedAmountPostTax,
  getExpectedAmountPreTax,
  getPropertyForDate,
} from 'services/helpers';

export default function VATManualReconcileModalContainer(props) {
  const {
    transaction,
    showModal,
    toggleModal,
    vatCodes,
    getRowColor,
    handleAction,
    vatCodeRuleSid,
    setVatCodeRuleSid,
    reconciliationDate,
    setReconciliationDate,
    actionSubmitting,
    reconciliations,
    isSelectionValid,
    hasReconciliations,
  } = props;

  const currentVatCode =
    vatCodes?.find((item) => item.vatCodeRuleSid === +vatCodeRuleSid) || {};

  const getVatCodeBySid = (sid) =>
    vatCodes.find((item) => item.vatCodeRuleSid === sid) || {};

  const baseLineCalculations = useMemo(() => {
    // get all the base lines from selected rows
    const returnArray = [];
    if (vatCodeRuleSid === '') return returnArray;
    const baseLines = transaction.lines.filter(
      (row) => row.type === 'base' && row.reconciliationStatus === 'UNMATCHED',
    );
    if (baseLines.length === 0) return returnArray;
    baseLines.forEach((baseLine) => {
      returnArray.push({
        lineId: baseLine.lineId,
        amount: baseLine.amount,
        inTax: {
          amount: currentVatCode.reverseChargeCalculation
            ? getExpectedAmountPreTax(
                baseLine.amount,
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'inTaxPercentage',
                ),
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'inTaxProRataPercentage',
                ),
              )
            : getExpectedAmountPostTax(
                baseLine.amount,
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'inTaxPercentage',
                ),
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'inTaxProRataPercentage',
                ),
              ),
          rate: getPropertyForDate(
            reconciliationDate,
            currentVatCode.history,
            'inTaxProRataPercentage',
          ),
        },
        outTax: {
          amount: currentVatCode.reverseChargeCalculation
            ? getExpectedAmountPreTax(
                baseLine.amount,
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'outTaxPercentage',
                ),
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'outTaxProRataPercentage',
                ),
              )
            : getExpectedAmountPostTax(
                baseLine.amount,
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'outTaxPercentage',
                ),
                getPropertyForDate(
                  reconciliationDate,
                  currentVatCode.history,
                  'outTaxProRataPercentage',
                ),
              ),
          rate: getPropertyForDate(
            reconciliationDate,
            currentVatCode.history,
            'outTaxProRataPercentage',
          ),
        },
      });
    });
    return returnArray;
  }, [vatCodeRuleSid, reconciliationDate, transaction.lines]);

  const isReconciliationLineError = (_vatCodeRuleSid, line) => {
    const vatCode = getVatCodeBySid(_vatCodeRuleSid);
    switch (line.type) {
      case 'base': {
        return false;
      }
      case 'ingoingTax': {
        return line.accountId !== vatCode.inTaxAccount;
      }
      case 'outgoingTax': {
        return line.accountId !== vatCode.outTaxAccount;
      }
      default:
        return true;
    }
  };

  const reconciliationDataFormatter = useMemo(() => {
    const returnArray = [];
    // flatten the array's lines, and give each group an incremental ID
    if (reconciliations.length === 0) return returnArray;

    reconciliations.forEach((reconciliation, index) => {
      returnArray.push({
        groupId: index,
        vatCode: reconciliation.vatCodeRuleName,
        type: 'headerLine',
        reconciliationDate: reconciliation.reconciliationDate,
        reconciliationId: reconciliation.reconciliationId,
        // check whether reconciliation.vatCodeRuleSid exists in any of the lines
        error: !reconciliation.lines.some(
          (line) => line.vatCode === reconciliation.vatCodeRuleName,
        ),
      });
      reconciliation.lines.forEach((line) => {
        returnArray.push({
          lineId: line.lineId,
          accountId: line.accountId,
          amount: line.splitAmount || line.amount,
          type: line.type,
          groupId: index,
          reconciliationId: reconciliation.reconciliationId,
          // check whether reconciliation.lines[n].accountId corresponds to the vatCodeRuleSid.inTaxAccount or outTaxAccount
          error:
            isReconciliationLineError(reconciliation.vatCodeRuleSid, line) ||
            line.error,
        });
      });
    });
    return returnArray;
  }, [reconciliations]);

  const getCommittedRowColor = (row) => {
    if (row.error) {
      return 'danger';
    }
    return null;
  };

  const areSplitLinesFullyConsumed = useMemo(() => {
    // get all the split lines from selected rows
    const splitLines = transaction.lines.filter(
      (row) =>
        row.splitAmount !== undefined &&
        row.splitAmount !== null &&
        row.splitAmount !== 0 &&
        row.splitAmount !== '',
    );
    if (splitLines.length === 0) return true;
    // check whether the sum of split lines with the same lineId is equal to the original amount
    const splitLinesWithSameLineId = splitLines.filter(
      (row) => row.lineId === splitLines[0].lineId,
    );
    const splitLinesWithSameLineIdTotal = splitLinesWithSameLineId.reduce(
      (total, row) => total + row.splitAmount,
      0,
    );

    return (
      parseFloat(splitLinesWithSameLineIdTotal).toFixed(2) ===
      parseFloat(splitLinesWithSameLineId[0].amount).toFixed(2)
    );
  }, [transaction.lines]);

  return (
    <VATManualReconcileModal
      {...{
        transaction: transaction.lines,
        transactionId: transaction.transactionId,
        showModal,
        toggleModal,
        handleAction,
        actionSubmitting,
        vatCodes,
        reconciliationDate,
        setReconciliationDate,
        vatCodeRuleSid,
        setVatCodeRuleSid,
        getRowColor,
        getCommittedRowColor,
        baseLineCalculations,
        currentVatCode,
        reconciliations: reconciliationDataFormatter,
        isSelectionValid,
        hasReconciliations,
        areSplitLinesFullyConsumed,
      }}
    />
  );
}
