import React, { useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap';

import { Button, CustomMultiSelectInput, Tooltip } from 'components';

import { LARGE_SCREENS } from 'constants/mediaSizes';
import {
  formatDate,
  formatNumber,
  searchSelection,
  getDateWithoutTimezoneOffset,
} from 'services/helpers';

export function CustomTableEditableCell(props) {
  const { row, cell, grid, search, actions, cellWidth, handleAction } = props;
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState(null);

  const additionalTooltipSpace = actions?.length ? actions.length * 23 : 0;

  const handleChange = (e) => {
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  const stopPropagation = (e) => {
    if (e) {
      e.stopPropagation();
    }
  };
  const toggleEdit = (e) => {
    stopPropagation(e);

    if (
      selected &&
      Array.isArray(cell.row.original[cell.column.cell.fieldOptions?.id])
    ) {
      handleAction(
        'cellSave',
        {
          id: cell.column.cell.fieldOptions?.id,
          value: selected,
          row: cell.row.original,
        },
        props.tableId,
      );
    }

    setEdit(!edit);
  };

  const handleCellClick = (e) => {
    if (edit || cell?.column?.cell?.disabled) {
      return;
    }

    toggleEdit(e);
  };
  const handleActionClick = (e, action) => {
    stopPropagation(e);

    if (typeof handleAction === 'function') {
      handleAction(action, row.original, props.tableId);
    }
  };

  const handleSave = (e) => {
    stopPropagation(e);

    if (typeof handleAction === 'function') {
      handleAction(
        'cellSave',
        {
          id: cell.column.id,
          row: cell.row.original,
          value: e.target.value,
        },
        props.tableId,
      );
    }
    toggleEdit(e);
  };
  const handleSelect = (value, id) => {
    if (Array.isArray(cell.row.original[cell.column.cell.fieldOptions?.id])) {
      setSelected(value);
    } else {
      if (typeof handleAction === 'function') {
        handleAction(
          'cellSave',
          {
            id,
            value,
            row: cell.row.original,
          },
          props.tableId,
        );
      }
      toggleEdit();
    }
  };

  let className = '';
  let displayValue = cell.value;

  if (typeof cell.column.cell?.converter === 'function') {
    displayValue = cell.column.cell.converter(cell.value, cell.row.original);
  } else if (cell.column.cell.inputType === 'number') {
    const formattedVal = formatNumber(cell.value);

    className = formattedVal.className;
    displayValue = formattedVal.formatted;
  }

  if (cell.column.cell.inputType === 'date' && displayValue) {
    displayValue = formatDate(new Date(displayValue));
  }

  if (typeof displayValue === 'string' && search && !edit) {
    displayValue = searchSelection(displayValue, search);
  }

  if (cell?.column?.cell?.disabled && cell?.column?.cell?.disabledTooltip) {
    displayValue = (
      <OverlayTrigger
        overlay={<BTooltip>{cell?.column?.cell?.disabledTooltip}</BTooltip>}
        placement="bottom"
      >
        <div>{displayValue}</div>
      </OverlayTrigger>
    );
  }

  const getEditableComponent = () => {
    let component;

    switch (cell.column.cell.type) {
      case 'input': {
        let value = cell?.value;

        if (cell.column.cell.inputType === 'date' && value) {
          value = getDateWithoutTimezoneOffset(value);
        }

        component = (
          <Input
            autoFocus
            type={
              row?.original?.inputType?.[cell.column?.id] ||
              cell.column.cell.inputType
            }
            onBlur={handleSave}
            onFocus={handleChange}
            onChange={handleChange}
            data-testid="input-edit"
            placeholder={
              cell.column.cell.placeholder ||
              row?.original?.placeholder?.[cell.column?.id]
            }
            defaultValue={value}
          />
        );
        break;
      }

      case 'textarea': {
        component = (
          <Textarea
            autoFocus
            onBlur={handleSave}
            onFocus={handleChange}
            onChange={handleChange}
            placeholder={cell.column.cell.placeholder}
            defaultValue={cell.value}
          />
        );
        break;
      }

      case 'select': {
        const selectedValue = cell.column.cell.fieldOptions?.options?.filter(
          (item) => {
            if (
              Array.isArray(
                cell.row.original[cell.column.cell.fieldOptions?.id],
              )
            ) {
              return cell.row.original[
                cell.column.cell.fieldOptions?.id
              ].includes(item.id);
            }

            return (
              item.id === cell.row.original[cell.column.cell.fieldOptions?.id]
            );
          },
        );

        component = (
          <CustomMultiSelectInput
            grid={grid}
            onBlur={toggleEdit}
            selected={selected || selectedValue}
            onSelect={handleSelect}
            data-testid="select-edit"
            {...(cell.column.cell.fieldOptions || {})}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return <FieldHolder>{component}</FieldHolder>;
  };

  const additionalActions = () =>
    actions.length
      ? actions.map((action) => (
          <OverlayTrigger
            key={`action-${action?.id}-${row?.index}`}
            overlay={<BTooltip>{action.tooltip}</BTooltip>}
            placement="bottom"
          >
            <AdditionalAction
              simple
              magnify
              grid={grid}
              kind="default"
              onClick={(e) => handleActionClick(e, action.id)}
            >
              {action.icon?.()}
            </AdditionalAction>
          </OverlayTrigger>
        ))
      : null;

  return (
    <EditCell
      edit={edit}
      onClick={handleCellClick}
      cellType={cell.column.cell.inputType}
    >
      {edit ? (
        getEditableComponent()
      ) : (
        <>
          <Tooltip
            grid={grid}
            width={cellWidth}
            tooltip={displayValue}
            className={className}
            additionalSpace={22 + additionalTooltipSpace}
          >
            {displayValue}
          </Tooltip>
          <ActionsWrapper>
            <Button
              simple
              magnify
              kind="default"
              disabled={cell?.column?.cell?.disabled}
            >
              <GoPencil />
            </Button>
            {additionalActions()}
          </ActionsWrapper>
        </>
      )}
    </EditCell>
  );
}

CustomTableEditableCell.propTypes = {
  cell: PropTypes.shape({}),
  tableId: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  handleAction: PropTypes.func.isRequired,
};

CustomTableEditableCell.defaultProps = {
  cell: {},
  tableId: undefined,
  actions: [],
};

export default CustomTableEditableCell;

const EditCell = styled.div`
  width: 100%;
  cursor: ${(props) => (props.edit ? 'default' : 'pointer')};
  display: flex;
  flex-direction: ${(props) =>
    props.cellType === 'number' ? 'row-reverse' : 'row'};
  align-items: center;
  justify-content: ${(props) =>
    props.cellType === 'number' ? 'flex-begin' : 'space-between'};
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const AdditionalAction = styled((props) => <Button {...props} />)`
  color: #484541;
  margin: 0;
  cursor: pointer;
  padding: 0 !important;

  svg {
    font-size: 14px !important;

    @media (max-width: ${LARGE_SCREENS.max}px) {
      font-size: ${({ grid }) => (grid === 'sm' ? '12px' : '14px')} !important;
    }
  }
`;

const Textarea = styled.textarea`
  top: -12px;
  left: 0;
  width: 100%;
  height: 25px;
  border: none;
  z-index: 1;
  position: absolute;
  box-shadow: 25px 25px 30px 0px rgba(40, 40, 40, 0.4);
  background-color: white;
`;

const Input = styled.input`
  top: -12px;
  left: 0;
  width: 100%;
  height: 25px;
  border: none;
  z-index: 1;
  position: absolute;
  box-shadow: 25px 25px 30px 0px rgba(40, 40, 40, 0.4);
  background-color: white;
`;

const FieldHolder = styled.div`
  width: 100%;
  position: relative;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
