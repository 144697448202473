import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AddEditAssetCategoryModal } from 'components';

import { fromResource } from 'store/selectors';
import { resourceListReadSuccess } from 'store/resource/actions';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  putAssetCategoryApi,
  getAssetAccountsApi,
  getAssetCategoryApi,
  postAssetCategoryApi,
  getAssetDefaultCategories,
} from 'services/apihelpers';

function AddEditAssetCategoryModalContainer(props) {
  const { item, closeModal } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getAssetCategoryApi),
  );
  const accounts = useSelector((state) =>
    fromResource.getList(state, getAssetAccountsApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getAssetAccountsApi}ListRead`),
  );

  const defaultCategories = useSelector((state) =>
    fromResource.getList(state, getAssetDefaultCategories),
  );

  const onSubmit = async (formData) => {
    const body = {
      accounts: [
        {
          accountId: formData.purchaseValueAccount,
          accountTypes: ['PURCHASE_VALUE'],
        },
      ],
      notInUse: [],
      companyId: params.companyId,
      assetCategoryName: formData.assetCategoryName,
      defaultPercentage: +formData.defaultPercentage,
      defaultPeriodsToDepreciate: +formData.defaultPeriodsToDepreciate,
    };

    if (formData.accumulatedAdjustmentAccount === 'notInUse') {
      body.notInUse.push('ADJUSTMENTS');
      body.accounts[0].accountTypes.push('ADJUSTMENTS');
    } else if (
      formData.purchaseValueAccount === formData.accumulatedAdjustmentAccount
    ) {
      body.accounts[0].accountTypes.push('ADJUSTMENTS');
    } else {
      body.accounts.push({
        accountId: formData.accumulatedAdjustmentAccount,
        accountTypes: ['ADJUSTMENTS'],
      });
    }

    if (formData.accumulatedDepreciationAccount === 'notInUse') {
      body.notInUse.push('DEPRECIATION');
      body.accounts[0].accountTypes.push('DEPRECIATION');
    } else if (
      formData.purchaseValueAccount === formData.accumulatedDepreciationAccount
    ) {
      body.accounts[0].accountTypes.push('DEPRECIATION');
    } else if (
      formData.accumulatedAdjustmentAccount ===
      formData.accumulatedDepreciationAccount
    ) {
      body.accounts[1].accountTypes.push('DEPRECIATION');
    } else {
      body.accounts.push({
        accountId: formData.accumulatedDepreciationAccount,
        accountTypes: ['DEPRECIATION'],
      });
    }

    let toastMessage = t`Asset category created successfully`;

    if (item.assetCategoryId) {
      body.assetCategoryId = item.assetCategoryId;
      toastMessage = t`Asset category updated successfully`;

      await dispatch(resourceUpdateRequest(putAssetCategoryApi, null, body));
    } else {
      await dispatch(resourceCreateRequest(postAssetCategoryApi, body));
    }

    toast.success(toastMessage);

    closeModal(false, true);
  };
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getAssetAccountsApi, {
        companyId: params.companyId,
        assetCategoryId: item.assetCategoryId,
      }),
    );
    dispatch(resourceListReadRequest(getAssetDefaultCategories));
    if (item.assetCategoryId) {
      dispatch(
        resourceListReadRequest(getAssetCategoryApi, {
          assetCategoryId: item.assetCategoryId,
        }),
      );
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resourceListReadSuccess(getAssetCategoryApi, null));
    };
  }, []);

  return (
    <AddEditAssetCategoryModal
      {...{
        item: data || item,
        loading,
        defaultCategories,
        accounts,
        closeModal,
        onSubmit,
      }}
    />
  );
}

AddEditAssetCategoryModalContainer.propTypes = {
  item: PropTypes.shape({}),
  closeModal: PropTypes.func.isRequired,
};

AddEditAssetCategoryModalContainer.defaultProps = {
  item: {},
};

export default AddEditAssetCategoryModalContainer;
