import React from 'react';
import GroupCompanyDetailed from 'containers/GroupCompanyDetailed';

/**
 * Group Company Detailed Page component
 */
function GroupCompanyDetailedPage(props) {
  return <GroupCompanyDetailed {...props} />;
}

export default GroupCompanyDetailedPage;
