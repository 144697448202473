import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

import TableHeader from 'containers/TableHeader';
import { PayrollA07ReportModal } from 'components';

import { fromCompany } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';
import { setPayrollSSPercentageApi } from 'services/apihelpers';
import {
  reportInfoGrid,
  incomeTypesGrid,
  reportTotalsGrid,
  socialSecuritySpecGrid,
} from 'services/tableshapes';

class PayrollReport extends Component {
  static propTypes = {
    accountingYear: PropTypes.number.isRequired,
    recordId: PropTypes.string.isRequired,
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
    }).isRequired,
    getPayrollReport: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
  };

  state = {
    changePercentage: false,
    isPercentageChanged: false,
  };

  handleChange = async (event, line) => {
    try {
      await this.props.setPayrollSSPercentage({
        percentage: event.target.value,
        socialSecurityId: line.altinnPayrollIncomeSID,
      });
      this.props.updatePercentage(
        event.target.value,
        line.altinnPayrollIncomeSID,
      );
      this.setState({ isPercentageChanged: true });
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  toggleChangePercentage = () =>
    this.setState((state) => ({
      changePercentage: !state.changePercentage,
    }));

  closeModal = () => {
    const { togglePayrollReportModal, fetchData, refetch } = this.props;

    if (this.state.isPercentageChanged) {
      refetch();
      fetchData();
    }

    togglePayrollReportModal();
  };

  render() {
    const {
      data,
      loading,
      bimonthly,
      accumulated,
      activePeriods,
      loadingA07XML,
      accountingYear,
      handleDownloadA07XML,
      handleAccumulatedChange,
    } = this.props;
    const { changePercentage } = this.state;

    return (
      <PayrollA07ReportModal
        toggleHide={this.toggleHide}
        {...{
          data,
          loading,
          bimonthly,
          accumulated,
          activePeriods,
          loadingA07XML,
          accountingYear,
          changePercentage,
          handleDownloadA07XML,
          handleAccumulatedChange,
          handleChange: this.handleChange,
          togglePayrollReportModal: this.closeModal,
          reportTotalHeader: <TableHeader {...{ columns: reportTotalsGrid }} />,
          toggleChangePercentage: this.toggleChangePercentage,
          socialSecuritySpecHeader: (
            <TableHeader
              {...{ columns: () => socialSecuritySpecGrid(accumulated) }}
            />
          ),
          reportInfoHeader: <TableHeader {...{ columns: reportInfoGrid }} />,
          incomeTypesHeader: (
            <TableHeader {...{ columns: () => incomeTypesGrid(accumulated) }} />
          ),
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPayrollSSPercentage: (query) =>
    dispatch(resourceCreateRequest(setPayrollSSPercentageApi, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReport);
