import React from 'react';
import cx from 'classnames';
import styled, { keyframes } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Tab, Tabs as TabsRB } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaCog } from '@react-icons/all-files/fa/FaCog';
import { t } from '@lingui/macro';

export function Tabs(props) {
  const { tabs, onSelect, activeKey, config } = props;

  return (
    <>
      <TabsHolder>
        <TabsTabsHolder>
          <TabsRB activeKey={activeKey} onSelect={onSelect}>
            {tabs.map((item) => (
              <Tab
                key={item.key}
                {...item}
                title={
                  item.notify && item.eventKey !== activeKey ? (
                    <>
                      {item.title}
                      <Notify />
                    </>
                  ) : (
                    item.title
                  )
                }
                tabClassName={cx({ beta: item.beta, notify: item.notify })}
              />
            ))}
          </TabsRB>
        </TabsTabsHolder>
        {config && (
          <ConfigButtonHolder>
            <Link to={config} title={t`Settings`}>
              <FaCog color="#66615b" size={24} />
            </Link>
          </ConfigButtonHolder>
        )}
      </TabsHolder>
      {tabs
        .filter((item) => item.eventKey === activeKey && item.component)
        .map((item) => item.component)}
    </>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Tabs;

const TabsHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;

  & > div ul li:first-child {
    border-left: 2px solid #66615b;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  & > div ul li:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  & > div ul li.beta:after {
    top: -20px;
    left: 50%;
    width: 57px;
    color: #fff;
    height: 25px;
    content: 'beta';
    position: absolute;
    font-size: 13px;
    transform: translate(-50%, 0);
    background: #ee8e4a;
    text-align: center;
    line-height: 27px;
    font-weight: bold;
    font-family: sans-serif;
    text-transform: uppercase;
  }

  & > div ul li.active a {
    color: white !important;
  }

  & > div ul li {
    border-top: 2px solid #66615b;
    border-right: 2px solid #66615b;
    border-bottom: 2px solid #66615b;

    &.active {
      background-color: #66615b;
    }

    & > a {
      color: #66615b !important;
      outline: none;

      &::before,
      &::after {
        display: none !important;
      }
    }
  }
`;

const TabsTabsHolder = styled.div`
  margin: auto;
`;

const ConfigButtonHolder = styled.div`
  margin-right: 10px;
`;

const pulseNotify = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0);
  }
`;

const Notify = styled.span`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: orange;
  border-radius: 50%;
  z-index: 100;
  display: inline-block;
  top: 4px;
  right: 2px;
  animation: ${pulseNotify} 2s 10;
`;
