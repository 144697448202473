import React, { useState, useEffect, useMemo } from 'react';
import _map from 'lodash-es/map';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { AccountsTable } from 'components';
import CopyCompanySettingsModal from 'containers/CopyCompanySettingsModal';

import { resourceListReadSuccess } from 'store/resource/actions';
import { fromResource, fromAuth } from 'store/selectors';
import { resourceUpdateRequest, resourceListReadRequest } from 'store/actions';
import {
  toReconciliation,
  toVATReconciliation,
  toPayrollReconciliation,
} from 'services/routehelpers';
import {
  getCompanyDetailedApi,
  getAccountsNotMappedApi,
  getStandardAccountListApi,
  saveBulkAccountSettingsApi,
  getAccountsMappingOverviewApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function AccountsTableContainer(props) {
  const { match, company, history, location, loadingAccountList } = props;
  const { currentCompanySID } = company || {};
  const { accountingYear, companyId } = match?.params || {};

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const user = useSelector((state) => fromAuth.getUser(state));
  const data = useSelector((state) =>
    fromResource.getList(state, getAccountsMappingOverviewApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getAccountsMappingOverviewApi}ListRead`),
  );
  const userListByCompany = useSelector((state) =>
    fromResource.getList(state, getCompanyDetailedApi),
  );
  const loadingUserListByCompany = useSelector((state) =>
    pending(state, `${getCompanyDetailedApi}ListRead`),
  );
  const standardAccountList = useSelector((state) =>
    fromResource.getList(state, getStandardAccountListApi),
  );

  const search = useMemo(() => {
    const searchFromUrl = queryString.parse(location.search, {
      parseBooleans: true,
    });

    return {
      reportMode: searchFromUrl.reportMode || false,
      showAccountsNotMapped: searchFromUrl.showAccountsNotMapped || false,
    };
  }, [location.search]);

  const fetchData = () => {
    // Read Account list
    dispatch(
      resourceListReadRequest(getAccountsMappingOverviewApi, {
        companyId,
        year: accountingYear,
      }),
    );

    // Read Users list
    dispatch(
      resourceListReadRequest(getCompanyDetailedApi, {
        companyId: currentCompanySID,
      }),
    );

    // Read Standard accounts
    dispatch(
      resourceListReadRequest(getStandardAccountListApi, {
        companyId: currentCompanySID,
      }),
    );
  };

  const handleRowClick = (row) => {
    let pushTo;

    if (row.accountTemplateCode === 'PAYROLL') {
      pushTo = toPayrollReconciliation({ bimonthly: true });
    } else if (row.accountTemplateCode === 'VAT') {
      pushTo = toVATReconciliation();
    } else {
      pushTo = toReconciliation({
        account: row.accountId,
      });
    }

    history.push(pushTo);
  };

  const handleBulkRowSave = async (rows, options) => {
    const query = {
      companyId,
      accountIds: rows.map((r) => r.accountId),
    };

    const setLoader = (active) => {
      setActionSubmitting((state) => {
        const _actionSubmitting = { ...state };

        rows.forEach((row) => {
          _actionSubmitting[row.accountId] = active;
        });

        return _actionSubmitting;
      });
    };

    setLoader(true);

    try {
      _map(options, (value, key) => {
        switch (key) {
          case 'standardAccountSid': {
            if (value[0].id) {
              query.standardAccountId = value[0].id;
            }
            break;
          }
          case 'accountTemplateCode': {
            if (value[0].id) {
              query.accountTemplateCode = value[0].id;
            }
            break;
          }

          case 'frequency': {
            if (value[0].id) {
              query.frequencyLevel = value[0].id;
            }
            break;
          }

          case 'reconcileAccount': {
            query.enableAccount = value;
            break;
          }

          case 'documentRequired': {
            query.documentsRequired = value;
            break;
          }

          case 'accountHolderUserUuid': {
            if (value[0].id) {
              query.accountHolderUuid = value[0].id;
              query.accountHolderYear = company.currentAccountingYear;
              query.accountHolderPeriodTo =
                company.currentWorkingPeriodEnd || 12;
              query.accountHolderPeriodFrom =
                company.currentWorkingPeriodStart || 1;
            }
            break;
          }

          default: {
            query[key] = value;
            break;
          }
        }
      });

      await dispatch(
        resourceUpdateRequest(saveBulkAccountSettingsApi, null, query),
      );

      if (typeof options.accountSystemMapped !== 'undefined') {
        dispatch(
          resourceListReadRequest(getAccountsNotMappedApi, {
            companyId,
            year: accountingYear,
          }),
        );
      }

      if (typeof options.reconcileAccount !== 'undefined') {
        fetchData();
      } else {
        const collection = data.map((item) => {
          const el = { ...item };
          const row = rows.find((r) => r.accountId === item.accountId);

          if (row) {
            _map(options, (value, key) => {
              switch (key) {
                case 'frequency':
                case 'accountTemplateCode': {
                  if (value[0].id) {
                    el[key] = value[0].id;
                  }
                  break;
                }

                case 'accountHolderUserUuid': {
                  if (value[0].id) {
                    const accountHolderUser =
                      userListByCompany?.availableUsers?.find(
                        (s) => s.uuid === value[0].id,
                      );

                    el.accountHolderName = accountHolderUser.username;
                    el.accountHolderUserUuid = accountHolderUser.uuid;
                  }
                  break;
                }
                case 'standardAccountSid': {
                  if (value[0].id) {
                    const standardAccount = standardAccountList.find(
                      (s) => s.standardAccountSID === value[0].id,
                    );

                    el.standardAccountId = standardAccount.standardAccountID;
                    el.standardAccountSid = standardAccount.standardAccountSID;
                    el.standardAccountDescription =
                      standardAccount[
                        user.languageCode === 'en'
                          ? 'descriptionENG'
                          : 'descriptionNOB'
                      ];
                  }
                  break;
                }

                default: {
                  el[key] = value;
                  break;
                }
              }
            });
          }

          return el;
        });

        await dispatch(
          resourceListReadSuccess(getAccountsMappingOverviewApi, collection),
        );
      }

      toast.success(t`Accounts updated successfully`);

      setLoader(false);
    } catch (err) {
      setLoader(false);
      throw err;
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({ ...actionSubmitting, [action]: submitting });
  };

  const toggleBooleanValue = async (accountId, field, value) => {
    const fieldNames = {
      reconcileAccount: 'enableAccount',
      documentRequired: 'documentsRequired',
      accountSystemMapped: 'accountSystemMapped',
    };

    const query = {
      companyId,
      accountIds: [accountId],
      [fieldNames[field]]: !value,
    };

    _setActionSubmitting(accountId, true);

    try {
      await dispatch(
        resourceUpdateRequest(saveBulkAccountSettingsApi, null, query),
      );

      const newData = data.map((item) =>
        item.accountId === accountId ? { ...item, [field]: !value } : item,
      );

      await dispatch(
        resourceListReadSuccess(getAccountsMappingOverviewApi, newData),
      );
      toast.success(t`Account updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      _setActionSubmitting(accountId, false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleAction = async (action, item, changes) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellClick': {
          if (
            [
              'reconcileAccount',
              'documentRequired',
              'accountSystemMapped',
            ].includes(changes)
          ) {
            await toggleBooleanValue(item.accountId, changes, item[changes]);
          }
          break;
        }

        case 'saveState': {
          await handleBulkRowSave(item, changes);
          break;
        }

        case 'redirect': {
          handleRowClick(item);
          break;
        }

        case 'cellSave': {
          await handleBulkRowSave([item.row], {
            [item.id]: item.value,
          });
          break;
        }

        case 'copyCompany': {
          setShowModal(true);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      if (!item?.value?.length) {
        return toast.success(t`Nothing to change`);
      }
      toast.error(getErrorMessage(e));
    }
  };

  const closeModal = (_, refetch) => {
    setShowModal(false);

    if (refetch) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyId, accountingYear]);

  const collections = useMemo(
    () => ({
      standardAccountId: {
        options: Array.isArray(standardAccountList)
          ? standardAccountList.map((el) => ({
              id: el.standardAccountSID,
              label: `${el.standardAccountID} - ${
                el[
                  user.languageCode === 'en'
                    ? 'descriptionENG'
                    : 'descriptionNOB'
                ]
              }`,
            }))
          : [],
        loading: loadingAccountList,
      },
      accountHolderUserUuid: {
        options: Array.isArray(userListByCompany?.availableUsers)
          ? userListByCompany.availableUsers.map((el) => ({
              id: el.uuid,
              label: el.name,
            }))
          : [],
        loading: loadingUserListByCompany,
      },
    }),
    [
      userListByCompany,
      loadingAccountList,
      standardAccountList,
      loadingUserListByCompany,
    ],
  );

  return (
    <>
      {showModal && <CopyCompanySettingsModal closeModal={closeModal} />}
      <AccountsTable
        {...{
          data,
          search,
          loading,
          collections,
          handleAction,
          actionSubmitting,
        }}
      />
    </>
  );
}

export default AccountsTableContainer;
