import React from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { DESKTOP } from 'constants/mediaSizes';
import { createManualReportColumns } from 'services/tableshapes';
import { createManualFilters } from 'services/tableFilterShapes';

function CreateReconciliationModal(props) {
  const {
    lines,
    names,
    loading,
    roleType,
    closeModal,
    handleAction,
    actionSubmitting,
  } = props;

  return (
    <ModalStyles show onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Add Report Type Element</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid" style={{ padding: 0 }}>
          <CustomTableWithAutoSettings
            {...{
              data: lines,
              loading,
              columns: createManualReportColumns(names, roleType.isReadOnly),
              tableId: 'createManualReport',
              loadingId: 'id',
              filterConfigs: createManualFilters(),
              withCellClick: true,
              headerConfigs: {
                exportFileName: t`Manual Report Elements`,
                extraButtons: [
                  {
                    id: 'addLine',
                    icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                    title: t`Add Element`,
                    buttonProps: {
                      size: 'sm',
                      disabled: !names.length,
                      kind: 'default',
                      fill: false,
                      magnify: true,
                    },
                  },
                ],
              },
              handleAction,
              actionSubmitting,
              minHeight: 460,
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </ModalStyles>
  );
}

export default CreateReconciliationModal;

const ModalStyles = styled(Modal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: 1200px) {
      width: 1150px;
    }
  }
`;
