import { t } from '@lingui/macro';

export const assetRegisterFilters = ({ collections }) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['id', 'categoryName', 'assetName'],
  },
  {
    id: 'assetCategoryId',
    type: 'multiSelect',
    label: t`Categories`,
    placeholder: t`Please select Category`,
    ...collections.assetCategoryId,
  },
];

export default assetRegisterFilters;
