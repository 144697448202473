import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Paragraph component
 */
function Paragraph(props) {
  const {
    kind,
    lead,
    children,
    alignment,
    className,
    background,
    transformation,
    ...rest
  } = props;
  // Custom Bootstrap contextual classes
  const classes = classNames(
    {
      'text-info': kind === 'info',
      'text-muted': kind === 'muted',
      'text-danger': kind === 'danger',
      'text-primary': kind === 'primary',
      'text-success': kind === 'success',
      'text-warning': kind === 'warning',
      'text-left': alignment === 'left',
      'text-right': alignment === 'right',
      'text-center': alignment === 'center',
      'text-nowrap': alignment === 'nowrap',
      'text-justify': alignment === 'justify',
      'text-lowercase': transformation === 'lowercase',
      'text-uppercase': transformation === 'uppercase',
      'text-capitalize': transformation === 'capitalize',
      'bg-info': background === 'info',
      'bg-danger': background === 'danger',
      'bg-primary': background === 'primary',
      'bg-success': background === 'success',
      'bg-warning': background === 'warning',
      lead,
    },
    className,
  );
  return React.createElement('p', { className: classes, ...rest }, children);
}

Paragraph.propTypes = {
  kind: PropTypes.oneOf([
    'info',
    'muted',
    'danger',
    'success',
    'warning',
    'primary',
  ]),
  lead: PropTypes.bool,
  children: PropTypes.node,
  alignment: PropTypes.oneOf(['left', 'nowrap', 'right', 'center', 'justify']),
  className: PropTypes.string,
  background: PropTypes.oneOf([
    'info',
    'danger',
    'success',
    'warning',
    'primary',
  ]),
  transformation: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),
};

Paragraph.defaultProps = {
  kind: undefined,
  lead: false,
  children: undefined,
  alignment: undefined,
  className: undefined,
  background: undefined,
  transformation: undefined,
};

export default Paragraph;
