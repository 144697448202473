import { t } from '@lingui/macro';

/**
 * Auto Reconcile Table Grid
 */
export const columns = () => [
  {
    id: 1,
    label: '',
    value: '',
    sortable: false,
    enabled: true,
  },
  {
    id: 2,
    label: t`Source Document ID`,
    value: 'sourceDocumentId',
    sortable: false,
    enabled: true,
  },
  {
    id: 3,
    label: t`Description`,
    value: 'description',
    sortable: false,
    enabled: true,
  },
  {
    id: 4,
    label: t`Period`,
    value: 'period',
    sortable: false,
    enabled: true,
  },
  {
    id: 5,
    label: t`Year`,
    value: 'year',
    sortable: false,
    enabled: true,
  },
  {
    id: 6,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 7,
    label: t`Actions`,
    value: '',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
