import React from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Modal, Row, Col } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { AiOutlineDisconnect } from '@react-icons/all-files/ai/AiOutlineDisconnect'; // eslint-disable-line max-len

import TableHeader from 'containers/TableHeader';
import {
  Table,
  Button,
  Heading,
  Spinner,
  TableRow,
  TableCell,
  ButtonIcon,
} from 'components';

import { crossYearUnlinkColumns } from 'services/tableshapes';

function CrossYearUnlinkModal(props) {
  const { data, submitting, toggleModal, handleSubmit } = props;
  let content;

  const renderRow = (item, index) => (
    <TableRow key={`glReconcileRecordList-${item.sourceDocumentId}-${index}`}>
      <TableCell>{item.sourceDocumentId}</TableCell>
      <TableCell>{item.description}</TableCell>
      <TableCell>{item.period}</TableCell>
      <TableCell>{item.year}</TableCell>
    </TableRow>
  );

  if (_isEmpty(data)) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>No data to display.</Trans>
      </Heading>
    );
  } else {
    content = (
      <Table responsive head={<TableHeader columns={crossYearUnlinkColumns} />}>
        {data.map(renderRow)}
      </Table>
    );
  }

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <Heading level={3} alignment="center">
          <Trans>
            These lines will be unlinked by deactivating cross-year. Are you
            sure you want to deactivate cross-year?
          </Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>{content}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button kind="info" onClick={handleSubmit} disabled={submitting}>
          {submitting ? (
            <Spinner size="md" type="info" />
          ) : (
            <>
              <ButtonIcon as={AiOutlineDisconnect} /> <Trans>Submit</Trans>
            </>
          )}
        </Button>
        <Button fill magnify kind="danger" onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CrossYearUnlinkModal.propTypes = {
  data: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CrossYearUnlinkModal.defaultProps = {
  data: undefined,
};

export default CrossYearUnlinkModal;
