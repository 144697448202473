import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  createUltimatePagination,
  ITEM_TYPES,
} from 'react-ultimate-pagination';

/**
 * Pagination component
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
function WrapperComponent({ children }) {
  return <ul className="pagination">{children}</ul>;
}
WrapperComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

function Page({ value, onClick, isActive }) {
  return (
    <li className={isActive ? 'active' : null}>
      <a onClick={onClick} role="button" tabIndex="-1" onKeyDown={onClick}>
        {value}
      </a>{' '}
    </li>
  );
}

function Ellipsis({ onClick }) {
  return (
    <li>
      <a onClick={onClick} role="button" tabIndex="-2" onKeyDown={onClick}>
        ...
      </a>{' '}
    </li>
  );
}
Ellipsis.propTypes = {
  onClick: PropTypes.func.isRequired,
};

Page.propTypes = {
  value: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
Page.defaultProps = {
  isActive: false,
};

function FirstPageLink({ isActive, onClick }) {
  return (
    <li className={isActive ? 'disabled' : null}>
      <a onClick={onClick} role="button" tabIndex="-3" onKeyDown={onClick}>
        &laquo;
      </a>{' '}
    </li>
  );
}
FirstPageLink.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
FirstPageLink.defaultProps = {
  isActive: false,
};

function PreviousPageLink({ onClick, isActive }) {
  return (
    <li className={isActive ? 'disabled' : null}>
      <a onClick={onClick} role="button" tabIndex="-4" onKeyDown={onClick}>
        ‹
      </a>{' '}
    </li>
  );
}
PreviousPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
PreviousPageLink.defaultProps = {
  isActive: false,
};

function NextPageLink({ onClick, isActive }) {
  return (
    <li className={isActive ? 'disabled' : null}>
      <a onClick={onClick} role="button" tabIndex="-5" onKeyDown={onClick}>
        ›
      </a>{' '}
    </li>
  );
}
NextPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
NextPageLink.defaultProps = {
  isActive: false,
};
function LastPageLink({ onClick, isActive }) {
  return (
    <li className={isActive ? 'disabled' : null}>
      <a onClick={onClick} role="button" tabIndex="-6" onKeyDown={onClick}>
        &raquo;
      </a>{' '}
    </li>
  );
}
LastPageLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
LastPageLink.defaultProps = {
  isActive: false,
};

function Pagination(props) {
  const {
    kind,
    size,
    last,
    next,
    prev,
    first,
    items,
    onSelect,
    ellipsis,
    className,
    maxButtons,
    activePage,
    boundaryLinks,
  } = props;
  // Custom Bootstrap classes'
  const classes = classNames(
    'ultimate-pagination',
    'pagination',
    {
      'pagination-info': kind === 'info',
      'pagination-link': kind === 'link',
      'pagination-danger': kind === 'danger',
      'pagination-success': kind === 'success',
      'pagination-warning': kind === 'warning',
      'pagination-default': kind === 'default',
      'pagination-primary': kind === 'primary',
      'pagination-secondary': kind === 'secondary',
    },
    className,
  );

  const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  };

  const UltimatePaginationBootstrap3 = createUltimatePagination({
    itemTypeToComponent,
    WrapperComponent,
  });

  return (
    <UltimatePaginationBootstrap3
      prev={prev}
      next={next}
      last={last}
      first={first}
      bsSize={size}
      bsClass={classes}
      totalPages={items}
      onChange={onSelect}
      maxButtons={maxButtons}
      currentPage={activePage}
      hideEllipsis={!ellipsis}
      boundaryLinks={boundaryLinks}
    />
  );
}

Pagination.propTypes = {
  kind: PropTypes.oneOf([
    'info',
    'link',
    'danger',
    'success',
    'warning',
    'default',
    'primary',
    'secondary',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  last: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  next: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  prev: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  first: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  items: PropTypes.number,
  ellipsis: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  onSelect: PropTypes.func,
  className: PropTypes.string,
  maxButtons: PropTypes.number,
  activePage: PropTypes.number,
  boundaryLinks: PropTypes.bool,
};

Pagination.defaultProps = {
  kind: undefined,
  size: undefined,
  last: true,
  next: true,
  prev: true,
  first: true,
  items: undefined,
  ellipsis: true,
  onSelect: undefined,
  className: undefined,
  maxButtons: 5,
  activePage: 1,
  boundaryLinks: true,
};

export default Pagination;
