import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Card, Heading, Spinner, Paragraph } from 'components';

function InvalidateLinkForm(props) {
  const { loading, failed, noParamsInUrl, success } = props;

  const cardHeading = (
    <Heading level={3} className="card-title">
      <Trans>Invalidate Link</Trans>
    </Heading>
  );

  return (
    <Card kind="login" header={cardHeading} footerCenter>
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
      {success && (
        <Paragraph alignment="center" className="description">
          <Trans>Invalidate link has been submitted!</Trans>
        </Paragraph>
      )}
      {failed && (
        <Paragraph kind="danger" alignment="center">
          <Trans>Something went wrong. Please try to follow link again.</Trans>
        </Paragraph>
      )}
      {noParamsInUrl && (
        <Paragraph kind="danger" alignment="center">
          <Trans>
            No token or username has been supplied. Please try to follow link
            again
          </Trans>
        </Paragraph>
      )}
    </Card>
  );
}

InvalidateLinkForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  noParamsInUrl: PropTypes.bool.isRequired,
};

export default InvalidateLinkForm;
