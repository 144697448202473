import { t } from '@lingui/macro';

const map = t`Map transaction`;
const unMap = t`Unmap transaction`;

/**
 * Map VAT Transactions Grid
 */
export const columns = () => [
  {
    width: 160,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
  },
  {
    width: 160,
    Header: t`Company`,
    accessor: 'companyName',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Account`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
    cell: {
      converter: (value, item) =>
        `${item.accountId} - ${item.accountDescription}`,
    },
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 130,
    Header: t`Date`,
    accessor: 'transactionDate',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: 140,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 170,
    Header: t`Action`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'button',
      titleConverter: (item) =>
        item?.mappedInformation?.isMapped ? unMap : map,
      disableClick: true,
      buttons: [
        {
          id: 'mapTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Map Transaction`,
          magnify: true,
        },
      ],
    },
  },
];

export default { columns };
