import React from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { isBalanceUpdating } from 'services/apihelpers';
import { fromEvents, fromCompany } from 'store/selectors';
import { clearErrorMessage, resourceListReadRequest } from 'store/actions';

function SSEErrorHandler() {
  const sseError = useSelector((state) => fromEvents.getSSEError(state));
  const company = useSelector((state) => fromCompany.getCompany(state));
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (sseError && sseError.status) {
      toast.error(sseError.status);
      dispatch(clearErrorMessage());
      dispatch(
        resourceListReadRequest(isBalanceUpdating, {
          companyId: company.uuid,
          financialYear: +company.currentAccountingYear,
        }),
      );
    }
  }, [sseError]);

  return null;
}

export default SSEErrorHandler;
