import { t } from '@lingui/macro';

// eslint-disable-next-line import/prefer-default-export
export const glLegends = () => [
  {
    color: 'default-bg',
    title: t`Matched in the future`,
  },
  {
    color: 'color-blue-bg',
    title: t`Partially Reconciled`,
  },
  {
    color: 'info-bg',
    title: t`Reconciled`,
  },
  {
    color: 'warning-bg',
    title: t`Approved`,
  },
];

export const bankLegends = () => [
  {
    color: 'default-bg',
    title: t`Matched in the future`,
  },
  {
    color: 'info-bg',
    title: t`Reconciled`,
  },
];
