import React, { Component } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { MdUpdate } from '@react-icons/all-files/md/MdUpdate';
import { ButtonGroup } from 'react-bootstrap';
import { pending, rejected } from 'redux-saga-thunk';

import {
  BalanceDataModal,
  Button,
  ButtonIcon,
  Spinner,
  SSEErrorHandler,
} from 'components';

import {
  getCMCompanySystem,
  updateBalanceCM,
  isBalanceUpdating,
  isValidIntegration,
} from 'services/apihelpers';
import {
  fromAuth,
  fromCompany,
  fromEvents,
  fromResource,
} from 'store/selectors';
import {
  periodSelectors,
  periodFilterButtonsFill,
  periodSelectorValueInitialize,
} from 'services/helpers';
import { resourceListReadRequest, resourceCreateRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

import { trackEvent } from '../components/Analytics';

function UpdateButton({
  company,
  downloadError,
  isAlreadyUpdating,
  downloadProgress,
  hasValidIntegration,
  title,
}) {
  switch (true) {
    case downloadError:
    case isAlreadyUpdating:
    case downloadProgress !== null &&
      downloadProgress?.status !== 'Failed' &&
      downloadProgress?.status !== 'Processed' &&
      (downloadProgress?.period_year === +company.currentAccountingYear ||
        downloadProgress?.accountingYear === +company.currentAccountingYear):
      return <Spinner type="white" size="md" title={title} />;
    case !hasValidIntegration:
      return (
        <span className="badge badge-danger" title={title}>
          !
        </span>
      );
    default:
      return <ButtonIcon as={MdUpdate} fontSize={18} title={title} />;
  }
}

class BalanceDataModalContainer extends Component {
  static propTypes = {
    /** @type {shape} location Router object */
    location: PropTypes.shape({}).isRequired,
    /** @type {shape} history Router object */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number,
      currentWorkingPeriodEnd: PropTypes.number,
      currentAccountingYear: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }).isRequired,
    user: PropTypes.shape({}).isRequired,
    failed: PropTypes.bool,
    /** @type {shape} roleType */
    roleType: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    failed: false,
  };

  state = {
    toPeriod: this.props.company.currentWorkingPeriodEnd || 12,
    showModal: false,
    fromPeriod: 1,
    singlePeriod: false,
    periods: Array.from(
      Array(this.props.company.currentWorkingPeriodEnd || 12).keys(),
    ),
    autoReload: false,
  };

  componentDidMount() {
    const {
      accountingSystem,
      loadAccountingSystem,
      getIsAlreadyUpdating,
      getIsValidIntegration,
      company,
    } = this.props;

    const autoReload = localStorage.getItem('autoReload') === 'true';
    this.setState({ autoReload });

    if (!accountingSystem.isLoading && !accountingSystem.data.length) {
      loadAccountingSystem(company.uuid, company.currentAccountingYear);
    }
    getIsAlreadyUpdating(company.uuid, company.currentAccountingYear);
    getIsValidIntegration(company.uuid);
  }

  shouldComponentUpdate(nextProps) {
    const {
      company,
      loadAccountingSystem,
      getIsAlreadyUpdating,
      getIsValidIntegration,
    } = this.props;

    if (
      nextProps.company?.currentCompanySID !== company?.currentCompanySID ||
      nextProps.company?.currentAccountingYear !==
        company?.currentAccountingYear ||
      nextProps.company?.currentWorkingPeriodEnd !==
        company?.currentWorkingPeriodEnd ||
      nextProps.company?.currentWorkingPeriodStart !==
        company?.currentWorkingPeriodStart
    ) {
      this.setState({
        toPeriod: nextProps.company?.currentWorkingPeriodEnd || 1,
        periods: Array.from(
          Array(nextProps.company?.currentWorkingPeriodEnd || 12).keys(),
        ),
      });
      loadAccountingSystem(
        nextProps.company.uuid,
        nextProps.company.currentAccountingYear,
      );
      getIsAlreadyUpdating(
        nextProps.company.uuid,
        nextProps.company.currentAccountingYear,
      );
      getIsValidIntegration(nextProps.company.uuid);
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.autoReload) return;
    if (
      prevProps.downloadProgress?.status !== 'Processed' &&
      this.props.downloadProgress?.status === 'Processed' &&
      (this.props.downloadProgress?.period_year ===
        +this.props.company.currentAccountingYear ||
        this.props.downloadProgress?.accountingYear ===
          +this.props.company.currentAccountingYear)
    ) {
      window.location.reload(true);
    }
  }

  toggleHide = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  toggleAutoReload = () => {
    this.setState((prevState) => {
      const newAutoReload = !prevState.autoReload;

      // Update localStorage
      localStorage.setItem('autoReload', newAutoReload);

      return { autoReload: newAutoReload };
    });
  };

  handleChange = (event, periodType) => {
    const { target } = event;
    const { singlePeriod, toPeriod, showAll } = this.state;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : parseInt(target.getAttribute('id'), 10);
    const { name } = target;

    const workingPeriodEnd = periodSelectorValueInitialize(periodType, value);
    const period = periodSelectors(periodType);

    if (name === 'singlePeriod') {
      const end = showAll ? toPeriod : toPeriod - period.range;
      this.setState({
        [name]: value,
        periods: Array.from(Array(toPeriod).keys()),
        toPeriod,
        fromPeriod: value ? end : 1,
      });
    } else if (name === 'showAll') {
      const activePeriod = periodFilterButtonsFill(
        Array.from(Array(toPeriod).keys()),
        periodType,
      );
      const end = value ? toPeriod : activePeriod * period.end;
      this.setState({
        [name]: value,
        periods: Array.from(Array(end).keys()),
        toPeriod: end,
        fromPeriod: singlePeriod ? end - period.range : 1,
      });
    } else {
      this.setState({
        periods: Array.from(Array(workingPeriodEnd).keys()),
        toPeriod: workingPeriodEnd,
        fromPeriod: singlePeriod ? workingPeriodEnd - period.range : 1,
      });
    }
  };

  handleSubmitUpdate = async () => {
    const { fromPeriod, toPeriod } = this.state;
    const { company, clientManagerUpdate, accountingSystem } = this.props;
    const data = { fromPeriod, toPeriod };

    const companyId = company.uuid;
    const year = company.currentAccountingYear;
    const _accountingSystem = accountingSystem.data || {
      erp_name: 'NO SYSTEM',
      erp_system_id: '00000000-0000-0000-0000-000000000000',
    };

    try {
      switch (_accountingSystem.erp_system_id) {
        case 'a4aedeac-89d1-4b06-973e-04d3bba29f9a': // File import
        case '5ab301b6-8388-48aa-ad26-214240d8069e': // SAF-T import
        case '00000000-0000-0000-0000-000000000000': {
          this.toggleHide();
          toast.error(
            // eslint-disable-next-line max-len
            t`You can not update balances for this system from here. Consult with Accountflow to get more information.`,
          );
          break;
        }
        default: {
          await clientManagerUpdate({
            companyId,
            financialYear: +year,
            erpSystemId: _accountingSystem.erp_system_id,
            jobType: 1,
            ...data,
          });
          trackEvent({
            category: 'Company',
            label: 'Balance Update',
            action: `SUCCESS: User updated company balance for
            ${year} period ${fromPeriod}-${toPeriod}`,
            value: companyId,
          });
          toast.success(t`A request for updating balances has been sent`);
          break;
        }
      }
    } catch (e) {
      this.toggleHide();
      trackEvent({
        category: 'Company',
        label: 'Balance Update',
        action: `FAIL: User failed updating company balance for
          ${year} period ${fromPeriod}-${toPeriod}`,
        value: companyId,
      });
      toast.error(getErrorMessage(e));
    }
  };

  render() {
    const { periods, showAll, toPeriod, showModal, fromPeriod, singlePeriod } =
      this.state;
    const {
      company,
      roleType,
      failed,
      accountingSystem,
      downloadProgress,
      isAlreadyUpdating,
      downloadError,
      hasUpdatedBalance,
      hasValidIntegration,
    } = this.props;

    return (
      <>
        <SSEErrorHandler />
        <ButtonGroup bsSize="small">
          <Button fill kind="primary" onClick={this.toggleHide}>
            <UpdateButton
              {...{
                company,
                downloadError,
                isAlreadyUpdating,
                downloadProgress,
                hasValidIntegration,
              }}
              title={t`Update Balance`}
            />
          </Button>
        </ButtonGroup>
        {showModal && (
          <BalanceDataModal
            toggleHide={this.toggleHide}
            handleChange={this.handleChange}
            handleSubmitUpdate={this.handleSubmitUpdate}
            toggleAutoReload={this.toggleAutoReload}
            {...{
              failed,
              company,
              showAll,
              periods,
              roleType,
              toPeriod,
              showModal,
              fromPeriod,
              singlePeriod,
              accountingSystem,
              downloadProgress,
              isAlreadyUpdating,
              hasUpdatedBalance,
              autoReload: this.state.autoReload,
              hasValidIntegration,
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
  user: fromAuth.getUser(state),
  accountingSystem: {
    data: fromResource.getList(state, getCMCompanySystem),
    isLoading: pending(state, `${getCMCompanySystem}ListRead`),
    isError: rejected(state, `${getCMCompanySystem}ListRead`),
  },
  downloadProgress: fromEvents.getDownloadProgress(state),
  isAlreadyUpdating: fromResource.getList(state, isBalanceUpdating).status,
  downloadError: fromEvents.getSSEError(state),
  hasUpdatedBalance: fromEvents.isBalanceUpdated(state),
  hasValidIntegration:
    fromResource.getList(state, isValidIntegration).status ?? true,
});

const mapDispatchToProps = (dispatch) => ({
  loadAccountingSystem: (companyId, year) =>
    dispatch(
      resourceListReadRequest(getCMCompanySystem, {
        companyId,
        year,
      }),
    ),
  clientManagerUpdate: (query) =>
    dispatch(
      resourceCreateRequest(updateBalanceCM, {
        ...query,
      }),
    ),
  getIsAlreadyUpdating: (companyId, financialYear) =>
    dispatch(
      resourceListReadRequest(isBalanceUpdating, {
        companyId,
        financialYear,
      }),
    ),
  getIsValidIntegration: (companyId) =>
    dispatch(
      resourceListReadRequest(isValidIntegration, {
        companyId,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BalanceDataModalContainer);
