import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import {
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { getReportedSalaryDetails } from 'services/apihelpers';
// eslint-disable-next-line max-len
import { reportedSalaryDetailsFormatter } from 'services/dataFormatters/payroll';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useReportedSalaryDetailsData = (props) => {
  const { bimonthly, activePeriods, accountingYear, accumulated, uuid } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getReportedSalaryDetails),
  );

  const { rows, predefinedTotal, additionalTotalRows } = useMemo(
    () =>
      reportedSalaryDetailsFormatter({
        data,
        bimonthly,
        accumulated,
        activePeriods,
      }),
    [data, activePeriods, bimonthly, accumulated],
  );

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getReportedSalaryDetails, {
          uuid,
          bimonthly,
          accumulated,
          year: +accountingYear,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  const updateInput = async (item) => {
    setLoading(() => true);

    const newVoList = data.voList.map((vRows) => {
      if (vRows.row === item.row.row) {
        const newLines = vRows.lines.map((line) => {
          if (
            line.id === item.row.id &&
            line.period === item.row.period &&
            line.account === item.row.account
          ) {
            const result = { ...line, [item.id]: +item.value };
            return {
              ...result,
              sum: result.amountLeft + result.inputC - result.inputD,
              amountRight:
                result.initialAmountRight + result.inputC - result.inputD,
            };
          }

          return line;
        });

        return { ...vRows, lines: newLines };
      }

      return vRows;
    });

    await dispatch(
      resourceListReadSuccess(getReportedSalaryDetails, {
        voList: newVoList,
        a07WithSocSec: data.a07WithSocSec || undefined,
        a07All: data.a07All || undefined,
      }),
    );

    setLoading(() => false);
  };

  useEffect(() => {
    fetchData();
  }, [bimonthly, accountingYear, accumulated, uuid]);

  return {
    rows,
    loading,
    updateInput,
    predefinedTotal,
    additionalTotalRows,
  };
};

export default useReportedSalaryDetailsData;
