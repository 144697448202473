import { t } from '@lingui/macro';

export const columns = (showPeriodSelectPerRow) => [
  ...(showPeriodSelectPerRow
    ? [
        {
          width: 50,
          accessor: 'selection',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'selection',
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 100,
    Header: t`Period`,
    accessor: 'period',
    className: '',
  },
  {
    width: 150,
    Header: t`Last Update Timestamp`,
    accessor: 'modifiedDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'datetime',
      converter: (item) => item?.modifiedDate || t`Never`,
    },
  },
  {
    width: 100,
    Header: t`Status`,
    accessor: 'status',
    className: '',
  },
];

export default { columns };
