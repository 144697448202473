import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import structuredClone from '@ungap/structured-clone';

import { PayrollReconciliationVacationPayTable } from 'components';

import { vacationPayDeviationApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { fromPercentage } from 'services/helpers';
import {
  resourceUpdateRequest,
  resourceCreateRequest,
  resourceDeleteRequest,
} from 'store/actions';
import { fromCompany } from 'store/selectors';

export function PayrollReconciliationVacationPayTableContainer(props) {
  const {
    term,
    roleType,
    toggleModal,
    activePeriods,
    vacationPayData,
    payrollApproved,
  } = props;
  const {
    rows,
    glBaseLoading,
    calculationLoading,
    zonesLoading,
    fetchData,
    handleDelete,
    handleAmountSave,
  } = vacationPayData;

  const [actionSubmitting, setActionSubmitting] = useState({});

  const params = useParams();
  const dispatch = useDispatch();

  const { currentCompanySID } = useSelector((state) =>
    fromCompany.getCompany(state),
  );

  const deviationRowSubmitting = useSelector((state) =>
    pending(state, `${vacationPayDeviationApi}Create`),
  );

  const handleDeviationAction = async (action, item, value) => {
    switch (action) {
      case 'addRegular': {
        await dispatch(
          resourceCreateRequest(vacationPayDeviationApi, {
            year: params.accountingYear,
            period: term,
            periodType: 'BIMONTHLY', // TODO: change to actual period type
            companySid: currentCompanySID,
            type: 'REGULAR',
          }),
        );

        await fetchData({ skipGLBase: true });
        break;
      }

      case 'addList': {
        await dispatch(
          resourceCreateRequest(vacationPayDeviationApi, {
            year: params.accountingYear,
            period: term,
            periodType: 'BIMONTHLY', // TODO: change to actual period type
            companySid: currentCompanySID,
            type: 'LIST',
          }),
        );

        await fetchData({ skipGLBase: true });
        break;
      }

      case 'addZone': {
        await dispatch(
          resourceCreateRequest(vacationPayDeviationApi, {
            year: +params.accountingYear,
            period: term,
            periodType: 'BIMONTHLY', // TODO: change to actual period type
            companySid: currentCompanySID,
            percentage: 0,
            type: 'ZONE',
          }),
        );

        await fetchData({ skipGLBase: true });
        break;
      }

      case 'edit': {
        const updatedItem = structuredClone(item);
        if (updatedItem?.percentage) {
          updatedItem.percentage = +fromPercentage(updatedItem.percentage);
        }
        if (value?.percentage) {
          updatedItem.percentage = +fromPercentage(value.percentage);
        }
        await dispatch(
          resourceUpdateRequest(vacationPayDeviationApi, item.id, {
            id: item.id,
            amount: 0.0,
            ...updatedItem,
            ...(value.percentage ? [] : value),
          }),
        );
        break;
      }

      case 'delete': {
        await dispatch(
          resourceDeleteRequest(`${vacationPayDeviationApi}/${item.id}`),
        );
        break;
      }

      default: {
        break;
      }
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellSave': {
          _setActionSubmitting(item?.row?.id, true);

          if (item?.row?.customDeviation) {
            const row = {
              id: item.row.id,
              amount: item.row.amount || item.row.baseSocialSecurity,
              description: item.row.vpBaseAmount || item.row.zone,
              percentage: item.row.rate ?? undefined,
            };

            if (item?.id === 'vpBaseAmount') {
              await handleDeviationAction('edit', row, {
                description: item.value,
              });
              await fetchData({ skipGLBase: true });
            } else if (typeof item.row.zone !== 'undefined') {
              switch (item?.id) {
                case 'zone':
                  await handleDeviationAction('edit', row, {
                    description: item.value,
                  });
                  break;
                case 'rate':
                  await handleDeviationAction('edit', row, {
                    percentage: +item.value,
                  });
                  break;
                case 'baseSocialSecurity':
                  await handleDeviationAction('edit', row, {
                    amount: +item.value,
                  });
                  break;
                default:
                  break;
              }
              await fetchData({ skipGLBase: true });
            } else {
              await handleDeviationAction('edit', row, {
                amount: +item.value,
              });
              await fetchData({ skipGLBase: true });
            }
          } else if (typeof item?.row?.zone !== 'undefined') {
            await handleAmountSave(
              item?.row?.id,
              +item.value,
              'ZONE',
              item.row.zone,
            );
            await fetchData({ skipGLBase: true });
          } else {
            await handleAmountSave(item?.row?.id, +item.value);
            await fetchData({ skipGLBase: true });
          }

          _setActionSubmitting(item?.row?.id, false);
          break;
        }

        case 'delete': {
          _setActionSubmitting(item.id, true);

          if (item?.customDeviation) {
            await handleDeviationAction('delete', item);
          } else if (item?.zone) {
            await handleDelete(item, 'ZONE');
          } else {
            await handleDelete(item);
          }
          fetchData();

          _setActionSubmitting(item.id, false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <PayrollReconciliationVacationPayTable
      {...{
        rows,
        params,
        glBaseLoading,
        calculationLoading,
        zonesLoading,
        roleType,
        toggleModal,
        handleAction,
        activePeriods,
        payrollApproved,
        actionSubmitting,
        deviationRowSubmitting,
        handleDeviationAction,
      }}
    />
  );
}

export default PayrollReconciliationVacationPayTableContainer;
