import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';
import { Tabs, Tab } from 'react-bootstrap';

import { Card, Heading } from 'components';
import CompanySettings from 'containers/CompanySettings';
import UserSettings from 'containers/UserSettings';

import { toGeneralSettings } from 'services/routehelpers';

/**
 * General Settings Page component
 */
function GeneralSettingsPage(props) {
  const { roleType, location, handleTabSelect } = props;
  const { tabEntry = roleType.isAuditor ? 2 : 1 } = queryString.parse(
    location.search,
    {
      parseNumbers: true,
    },
  );

  return (
    <Tabs
      onSelect={(key) => handleTabSelect(key, toGeneralSettings)}
      activeKey={tabEntry}
      id="general-settings"
    >
      {!roleType.isAuditor && (
        <Tab eventKey={1} title={t`Company Settings`}>
          <Card
            kind="register"
            header={
              <Heading level={3} className="card-title">
                <Trans>Company Settings</Trans>
              </Heading>
            }
          >
            {tabEntry === 1 && <CompanySettings {...props} />}
          </Card>
        </Tab>
      )}
      <Tab eventKey={2} title={t`User Settings`}>
        <Card
          kind="register"
          header={
            <Heading level={3} className="card-title">
              <Trans>User Settings</Trans>
            </Heading>
          }
        >
          {tabEntry === 2 && <UserSettings {...props} />}
        </Card>
      </Tab>
    </Tabs>
  );
}

GeneralSettingsPage.propTypes = {
  /** @type {shape} roleType */
  roleType: PropTypes.shape({}).isRequired,
  /** @type func */
};

export default GeneralSettingsPage;
