import React, { useEffect, useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { pending } from 'redux-saga-thunk';
import { useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Spinner } from 'components';

import { fromResource } from 'store/selectors';
import {
  getTableSettingsApi,
  putTableSettingsApi,
  postTableSettingsApi,
} from 'services/apihelpers';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';

const CustomTable = lazy(() => import('../CustomTable'));

export function CustomTableWithAutoSettings(props) {
  const { tableId, customFilters } = props;
  const [urlFilters, setUrlFilters] = useState(undefined);

  const dispatch = useDispatch();
  let tableSettings = useSelector((state) =>
    fromResource.getList(state, `${getTableSettingsApi}/${tableId}`),
  );
  const loadingSettings = useSelector((state) =>
    pending(state, `${getTableSettingsApi}/${tableId}ListRead`),
  );
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(`${getTableSettingsApi}/${tableId}`, {
        tableId,
      }),
    );
  };
  const updateTableSettings = useAsyncDebounce(
    async ({ selectedRowIds, ...newState }, reset) => {
      const newTableState = JSON.stringify(newState);
      const data = {
        tableId,
        tableState: newTableState,
      };

      if (tableSettings?.id) {
        if (tableSettings?.tableState !== newTableState) {
          data.id = tableSettings.id;
          data.initialTableState = tableSettings.initialTableState;

          if (
            reset ||
            !Object.keys(JSON.parse(tableSettings.initialTableState || '{}'))
              .length
          ) {
            data.initialTableState = data.tableState;
            setUrlFilters(undefined);
          }

          await dispatch(
            resourceUpdateRequest(putTableSettingsApi, null, data),
          );

          if (reset) {
            fetchData();
          } else {
            dispatch(
              resourceListReadSuccess(
                `${getTableSettingsApi}/${tableId}`,
                data,
              ),
            );
          }
        }
      } else if (!loadingSettings) {
        data.initialTableState = data.tableState;

        await dispatch(resourceCreateRequest(postTableSettingsApi, data));

        dispatch(
          resourceListReadSuccess(`${getTableSettingsApi}/${tableId}`, data),
        );
      }
    },
    500,
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (props.urlFilters) {
      setUrlFilters(props.urlFilters);
    }
  }, [props.urlFilters]);

  if (customFilters && tableSettings?.tableId) {
    tableSettings = { ...tableSettings };

    tableSettings.tableState = JSON.parse(tableSettings.tableState);

    if (tableSettings.tableState.globalFilter) {
      const customFiltersKeys = Array.from(Object.keys(customFilters));

      const newGlobalFilter = { ...tableSettings.tableState.globalFilter };
      Array.from(Object.keys(tableSettings.tableState.globalFilter)).forEach(
        (key) => {
          // Check if filter is custom
          if (customFiltersKeys.includes(key)) {
            // Leave only filters whose values are in the customFilters array
            const fineValues = tableSettings.tableState.globalFilter[
              key
            ]?.filter(
              (item) =>
                customFilters[key].filter((i) => i.id === item.id).length,
            );

            if (fineValues.length) {
              newGlobalFilter[key] = fineValues;
            } else {
              delete newGlobalFilter[key];
            }
          }
        },
      );

      tableSettings.tableState.globalFilter = { ...newGlobalFilter };
    }

    tableSettings.tableState = JSON.stringify(tableSettings.tableState);
  }

  if (loadingSettings) {
    return (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  }

  if (urlFilters && tableSettings?.tableId) {
    tableSettings = { ...tableSettings };

    tableSettings.tableState = JSON.parse(tableSettings.tableState);

    if (!tableSettings.tableState.globalFilter) {
      tableSettings.tableState.globalFilter = {};
    }

    tableSettings.tableState.globalFilter = {
      ...tableSettings.tableState.globalFilter,
      ...urlFilters,
    };

    tableSettings.tableState = JSON.stringify(tableSettings.tableState);
  }

  return (
    <Suspense fallback={<Spinner />}>
      {(!tableSettings?.tableId || tableSettings?.tableId === tableId) && (
        <CustomTable
          {...{
            ...props,
            tableSettings,
            updateTableSettings,
          }}
        />
      )}
    </Suspense>
  );
}

CustomTableWithAutoSettings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.number,
  legends: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  tableId: PropTypes.string,
  getSumRow: PropTypes.func,
  withTitle: PropTypes.bool,
  minHeight: PropTypes.number,
  loadingId: PropTypes.string,
  tableTitle: PropTypes.string,
  reportMode: PropTypes.bool,
  getRowColor: PropTypes.func,
  withRowClick: PropTypes.bool,
  initialState: PropTypes.shape({}),
  handleAction: PropTypes.func,
  customFilters: PropTypes.shape({}),
  filterConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  selectConfigs: PropTypes.shape({}),
  headerConfigs: PropTypes.shape({}),
  withDragSelect: PropTypes.bool,
  actionSubmitting: PropTypes.shape({}),
  dontClearSelection: PropTypes.bool,
};

CustomTableWithAutoSettings.defaultProps = {
  data: [],
  height: 0,
  columns: [],
  loading: false,
  tableId: '',
  legends: [],
  withTitle: false,
  minHeight: 300,
  loadingId: 'accountId',
  getSumRow: undefined,
  tableTitle: '',
  reportMode: false,
  getRowColor: () => '',
  withRowClick: false,
  initialState: {},
  handleAction: () => {},
  filterConfigs: [],
  selectConfigs: {},
  headerConfigs: {},
  customFilters: undefined,
  withDragSelect: false,
  actionSubmitting: {},
  dontClearSelection: false,
};

export default CustomTableWithAutoSettings;
