import React from 'react';
import PropTypes from 'prop-types';

import AuditDetailedTable from 'containers/AuditDetailedTable';

export function AuditDetailedPage(props) {
  return <AuditDetailedTable {...props} />;
}

export default AuditDetailedPage;

AuditDetailedPage.propTypes = {
  route: PropTypes.shape({}).isRequired,
};
