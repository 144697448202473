import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { sort } from 'fast-sort';

import { Card, Heading, CustomTableWithAutoSettings } from 'components';

import { VATManualReconcileColumns } from 'services/tableshapes';
import { vatManualReconcileFilters } from 'services/tableFilterShapes';
import { filterByReconciledMatchedTransaction } from 'services/shapes';

export default function VATManualReconcileTable(props) {
  const { data, loading, handleAction, getRowColor, roleType } = props;

  const globalFilter = useMemo(
    () => ({
      reconciliationStatus: filterByReconciledMatchedTransaction().filter(
        (item) => item.id === 'UNMATCHED',
      ),
    }),
    [],
  );

  const filteredData = useMemo(() => {
    const rows = [];

    // Check if data is not empty and is an array
    if (Array.isArray(data) && data.length > 0) {
      // Sort by transactionId
      const sortedData = sort(data).asc([
        (row) => row.date,
        (row) => row.transactionId,
        (row) => row.accountId,
      ]);

      const transactionIdToGroupIdMap = new Map();
      let nextGroupId = 0;

      // Loop through the sorted data array
      sortedData.forEach((row) => {
        const { transactionId } = row;

        // If the transactionId is new, assign a new groupId
        if (!transactionIdToGroupIdMap.has(transactionId)) {
          transactionIdToGroupIdMap.set(transactionId, nextGroupId);
          nextGroupId += 1;
        }

        // Fetch the groupId for this transactionId
        const groupId = transactionIdToGroupIdMap.get(transactionId);

        // Push the row with the groupId
        rows.push({ ...row, groupId });
      });
    }

    return rows;
  }, [JSON.stringify(data)]);

  const hasSplitAmounts = Array.isArray(data)
    ? data.some((item) => item.splitAmount !== null)
    : false;

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        data: filteredData,
        handleAction,
        loadingId: 'accountId',
        tableId: 'vatManualReconcileTable',
        enableGroups: true,
        groupId: 'groupId',
        getRowColor,
        filterConfigs: vatManualReconcileFilters(),
        columns: VATManualReconcileColumns(
          roleType.isReadOnly,
          hasSplitAmounts,
        ),
        headerConfigs: {
          exportFileName: t`VAT Deviations`,
        },
        initialState: {
          globalFilter,
        },
      }}
    />
  );

  return (
    <Card
      header={
        <HeaderContainer>
          <Heading level={3} className="card-title">
            <Trans>VAT Transactions</Trans>
          </Heading>
        </HeaderContainer>
      }
    >
      {content}
    </Card>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
