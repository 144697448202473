import React, { useRef } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { AiFillFilePdf } from '@react-icons/all-files/ai/AiFillFilePdf';

import { Modal as BootstrapModal } from 'react-bootstrap';

import { Button, Heading, ButtonIcon, Spinner } from 'components';
import { DESKTOP } from 'constants/mediaSizes';

import { LOGO, CHECKBOX, SUCCESS, DISABLED } from './constants';
import reportStyles from './styles';

const usePreviewElement = (api) => {
  const PreviewRef = useRef();
  const PreviewApiRef = useRef();

  PreviewApiRef.current = api;

  if (!PreviewRef.current) {
    PreviewRef.current = function Preview() {
      const { disabled, showPreview, generateHTML, togglePreview } =
        PreviewApiRef.current;

      return (
        <Container>
          <RelativeButton
            size="sm"
            kind="danger"
            bage="New"
            disabled={disabled}
            onClick={togglePreview}
          >
            <ButtonIcon as={AiFillFilePdf} marginRight={5} />
            <Trans>Open PDF Preview</Trans>
            {disabled && <Spinner button size="sm" />}
          </RelativeButton>
          {showPreview && (
            <Modal show bsSize="xl">
              <Modal.Header>
                <div className="header-wrapper">
                  <Heading level={3}>
                    <Trans>PDF Preview</Trans>
                  </Heading>
                  <Button fill kind="danger" magnify onClick={togglePreview}>
                    <Trans>Close</Trans>
                  </Button>
                </div>
              </Modal.Header>
              <Modal.Body>
                <PreviewStyles
                  styles={reportStyles}
                  dangerouslySetInnerHTML={{
                    __html: generateHTML()
                      ?.replaceAll('<logo />', LOGO)
                      .replaceAll('<success />', SUCCESS)
                      .replaceAll('<disabled />', DISABLED),
                  }}
                />
              </Modal.Body>
            </Modal>
          )}
        </Container>
      );
    };
  }

  return PreviewRef.current;
};

export default usePreviewElement;

const PreviewStyles = styled.div`
  ${({ styles }) => styles.replaceAll('<checkbox-icon />', CHECKBOX)}
`;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1150px;
    }
  }

  .with-page-break {
    padding-bottom: 150px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .no-data-header {
    padding-top: 150px;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Container = styled.div``;

const RelativeButton = styled(Button)`
  position: relative;
  display: flex;
`;
