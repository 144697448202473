import { t } from '@lingui/macro';

export const reportVersionsFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'accountingYear',
      'accountingPeriod',
      'createdBy',
      'accountId',
    ],
  },
];

export default reportVersionsFilters;
