import React, { useMemo, useEffect } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { AccountDetailsTable } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { fromCompany, fromResource } from 'store/selectors';
import { getAccountDetailsApi, getAccountsListApi } from 'services/apihelpers';

function AccountDetailsTableContainer(props) {
  const {
    location,
    match: {
      params: { accountingYear, companyId },
    },
  } = props;

  const dispatch = useDispatch();

  const data = useSelector((state) =>
    fromResource.getList(state, getAccountDetailsApi),
  );
  const company = useSelector((state) => fromCompany.getCompany(state));
  const loading = useSelector((state) =>
    pending(state, `${getAccountDetailsApi}ListRead`),
  );
  const accounts = useSelector((state) =>
    fromResource.getList(state, getAccountsListApi),
  );
  const loadingAccounts = useSelector((state) =>
    pending(state, `${getAccountsListApi}ListRead`),
  );

  const readAccountDetailsList = (query) =>
    dispatch(resourceListReadRequest(getAccountDetailsApi, query));
  const getAccountsList = (query) =>
    dispatch(resourceListReadRequest(getAccountsListApi, query));

  const getSearch = () => {
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      mode: search.mode,
      period: search.period || 'all',
      account: search.account,
      periods: search.periods ? JSON.parse(search.periods) : null,
      accountId: search.accountId ? JSON.parse(search.accountId) : null,
      reportMode: search.reportMode || false,
      accumulated: search.accumulated || false,
    };
  };

  const search = useMemo(() => getSearch(), [location.search]);
  const { account, accumulated, period } = search;

  const fetchData = async () => {
    let intervalType = search.period;
    if (intervalType === 'p0') {
      intervalType = 'OPENING_BALANCE';
    } else if (intervalType === 'all') {
      intervalType = 'TOTAL';
    }

    await readAccountDetailsList({
      companyId,
      accumulated,
      intervalType,
      accountId: account,
      year: accountingYear,
    });
    await getAccountsList({
      companyId,
      year: accountingYear,
    });
  };

  const collections = useMemo(
    () => ({
      accountId: {
        options: Array.isArray(accounts)
          ? accounts?.map((el) => ({
              id: el.accountId,
              label: el.accountId,
            }))
          : [],
        loading: loadingAccounts,
      },
    }),
    [accounts, loadingAccounts],
  );

  useEffect(() => {
    fetchData();
  }, [accountingYear, companyId, account, accumulated, period]);

  return (
    <AccountDetailsTable
      {...{
        data,
        search,
        company,
        loading,
        collections,
      }}
    />
  );
}

export default AccountDetailsTableContainer;
