import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { AltinnExpireModal } from 'components';

import { fromCompany } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';
import {
  cancelAltinnSessionApi,
  retrieveAltinnSessionApi,
} from 'services/apihelpers';

function AltinnExpireModalContainer(props) {
  const { handleClose } = props;

  const dispatch = useDispatch();

  const company = useSelector((state) => fromCompany.getCompany(state));
  const loading = useSelector((state) =>
    pending(state, `${cancelAltinnSessionApi}Create`),
  );

  const handleStop = async () => {
    await dispatch(
      resourceCreateRequest(cancelAltinnSessionApi, {
        companySID: company.currentCompanySID,
      }),
    );
    await dispatch(
      resourceCreateRequest(retrieveAltinnSessionApi, {
        companySID: company.currentCompanySID,
      }),
    );
    handleClose();
  };

  return (
    <AltinnExpireModal {...props} loading={loading} handleStop={handleStop} />
  );
}

export default AltinnExpireModalContainer;
