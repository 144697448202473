import React, { useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';

import { Card, Heading, CustomTableWithAutoSettings, Field } from 'components';

import { VATNewerDetailedColumns } from 'services/tableshapes';
import { vatReportNewerDetailedFilters } from 'services/tableFilterShapes';

function VATNewerDetailed(props) {
  const { data, frequency, loading, type, id, term, accumulated } = props;
  const [showAllTypes, setShowAllTypes] = useState(false);

  const filteredData = useMemo(() => {
    const rows = [];

    if (
      Array.isArray(data?.[frequency]?.data) &&
      !_isEmpty(data?.[frequency]?.data) &&
      term !== undefined
    ) {
      const currentReport = data[frequency].data.find(
        (item) => +item.term === +term,
      );

      const currentRow = currentReport?.[
        accumulated === 'true' ? 'accumulatedRows' : 'rows'
      ]?.find((row) => row.id === +id);

      if (!_isEmpty(currentRow)) {
        const filteredByType = !showAllTypes
          ? currentRow.glTransactions.filter(
              (transaction) =>
                !!transaction.glLines.filter((line) => line.type === type)
                  .length,
            )
          : currentRow.glTransactions;

        let groupId = 0;

        filteredByType?.forEach((row) => {
          const { glLines, ...rest } = row;

          const lines = showAllTypes
            ? glLines
            : glLines.filter((line) => line.type === type);

          let mainRow = { ...rest };

          // If only line then join it with title row
          if (lines.length === 1) {
            mainRow = { ...mainRow, ...lines[0] };
          }

          // Main Row
          rows.push({ ...mainRow, groupId });

          // Reset Group id
          groupId = !groupId;

          if (lines.length > 1) {
            // Push sub lines
            rows.push(...lines.map((line) => ({ ...line, groupId })));
            // Reset group id
            groupId = !groupId;
          }
        });
      }
    }

    return rows;
  }, [JSON.stringify(data), frequency, showAllTypes, term, accumulated]);

  const onShowAllChange = () => setShowAllTypes(!showAllTypes);

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        data: filteredData,
        loadingId: 'accountId',
        tableId: 'vatNewerDetailed',
        enableGroups: true,
        groupId: 'groupId',
        filterConfigs: vatReportNewerDetailedFilters(),
        columns: VATNewerDetailedColumns(),
        headerConfigs: {
          exportFileName: t`VAT Detailed`,
        },
      }}
    />
  );

  return (
    <Card
      header={
        <HeaderContainer>
          <Heading level={3} className="card-title">
            <Trans>VAT Detailed</Trans>
          </Heading>
          <Field
            horizontal
            id="allTypes"
            type="checkbox"
            name="allTypes"
            label={t`Show all line types`}
            checked={showAllTypes}
            onChange={onShowAllChange}
          />
        </HeaderContainer>
      }
    >
      {content}
    </Card>
  );
}

export default VATNewerDetailed;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
