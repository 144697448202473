import { t } from '@lingui/macro';

export const columns = (editable) => [
  {
    width: editable ? 150 : 160,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    disableSortBy: true,
    withTooltip: true,
  },
  {
    width: editable ? 170 : 190,
    Header: t`Type`,
    accessor: 'type',
    className: '',
    disableSortBy: true,
    withTooltip: true,
  },
  {
    width: 60,
    Header: t`Period`,
    accessor: 'period',
    disableSortBy: true,
    className: 'flex-right',
  },
  {
    width: 60,
    Header: t`Zone`,
    accessor: 'zone',
    disableSortBy: true,
    className: 'flex-right',
  },
  {
    width: 100,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    disableSortBy: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: editable ? 105 : 75,
    Header: t`%`,
    accessor: 'percentage',
    className: '',
    disableSortBy: true,
    withTooltip: true,
    cell: {
      type: 'input',
      inputType: 'number',
      editable,
      converter: (value) => `${value} %`,
    },
  },
  {
    width: 120,
    Header: t`Calculated`,
    sortType: 'basic',
    accessor: 'calculated',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      converter: (_, line) => line.amount * (line.percentage / 100),
      type: 'number',
    },
  },
];

export default { columns };
