import React from 'react';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings } from 'components';

import { AuditColumns } from 'services/tableshapes';
import { auditReportsFilter } from 'services/tableFilterShapes';

function AuditTable(props) {
  const { data, search, loading, handleAction, actionSubmitting } = props;

  return (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        columns: AuditColumns(search),
        tableId: 'auditReport',
        headerConfigs: {
          exportFileName: `${t`Reports`} - ${t`Audit`}`,
        },
        loadingId: 'auditId',
        handleAction,
        withRowClick: true,
        filterConfigs: auditReportsFilter(),
        reportMode: search.reportMode,
        actionSubmitting,
      }}
    />
  );
}

export default AuditTable;
