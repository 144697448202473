import { t } from '@lingui/macro';

import { generateUniqueFilterOptions } from 'services/helpers';

import {
  periodTypes,
  filterByAccountingSystem,
  filterByActivatedStatuses,
  filterByAvailabilityStatuses,
} from 'services/shapes';

export const adminCompanySettingsFilters = (data) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'name',
      'periodNo',
      'companyId',
      'periodType',
      'accountSystem',
      'accountHolderName',
      'registrationNumber',
    ],
  },
  {
    id: 'periodType',
    type: 'multiSelect',
    label: t`Period Type`,
    options: periodTypes(),
    disableSort: true,
    placeholder: t`Please select Period Type`,
  },
  {
    id: 'periodNo',
    size: 'sm',
    type: 'number',
    label: t`Period Number`,
    placeholder: t`Type Period Number`,
  },
  {
    id: 'accountSystem',
    type: 'multiSelect',
    label: t`Accounting System`,
    options: filterByAccountingSystem(),
    disableSort: true,
    placeholder: t`Please select Accounting System`,
  },
  {
    id: 'recordStatus',
    type: 'multiSelect',
    label: t`Activated`,
    options: filterByActivatedStatuses(),
    disableSort: true,
    placeholder: t`Please select Activated`,
  },
  {
    id: 'secret',
    type: 'multiSelect',
    label: t`Availability`,
    options: filterByAvailabilityStatuses(),
    disableSort: true,
    placeholder: t`Please select Availability`,
  },
  {
    id: 'accountHolderName',
    type: 'multiSelect',
    label: t`Assignee`,
    options: generateUniqueFilterOptions(data, 'accountHolderName'),
    disableSort: true,
    placeholder: t`Please select Assignee`,
  },
];

export default adminCompanySettingsFilters;
