import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings, Heading } from 'components';

import { groupCompanyDetailedColumns } from 'services/tableshapes';
import { settingsCompanyDetailedFilters } from 'services/tableFilterShapes';

function GroupCompanyDetailedTable(props) {
  const {
    data,
    group,
    search,
    loading,
    roleType,
    handleAction,
    actionSubmitting,
  } = props;

  return (
    <>
      <Heading level={3}>{group?.name}</Heading>
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          loadingId: 'companyId',
          dynamicRowHeight: true,
          reportMode: search.reportMode,
          tableId: 'groupCompanyDetailed',
          filterConfigs: settingsCompanyDetailedFilters(),
          withCellClick: true,
          columns: groupCompanyDetailedColumns(
            search,
            handleAction,
            roleType?.isSystemAdmin || roleType?.isSuperAdmin,
          ),
          headerConfigs: {
            exportFileName: `${group?.name} - ${t`Company Groups Details`}`,
            extraButtons: [
              {
                id: 'add',
                title: t`Update group companies`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
        }}
      />
    </>
  );
}

GroupCompanyDetailedTable.propTypes = {
  data: PropTypes.shape({}),
  group: PropTypes.shape({}),
  search: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

GroupCompanyDetailedTable.defaultProps = {
  data: [],
  group: {},
  search: {},
  loading: false,
  handleAction: () => {},
  actionSubmitting: {},
};

export default GroupCompanyDetailedTable;
