import React from 'react';
import { t } from '@lingui/macro';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';

export const columns = () => [
  {
    width: 100,
    Header: t`VAT Code`,
    accessor: 'vatCodeSaft',
  },
  {
    width: 240,
    Header: t`VAT Code Description`,
    accessor: 'vatCodeDescription',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 160,
    Header: t`Specification`,
    accessor: 'specification',
    className: '',
    withTooltip: true,
    disableSortBy: true,
  },
  {
    width: 180,
    Header: t`VAT Code Accounting System`,
    accessor: 'vatCodeAccountingSystem',
    withTooltip: true,
    className: '',
    disableSortBy: true,
  },
  {
    width: 100,
    Header: t`VAT Rate`,
    accessor: 'vatRate',
    className: '',
    disableSortBy: true,
  },
  {
    width: 140,
    Header: t`VAT-Report Taxbase`,
    accessor: 'vatReturnBaseAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
    },
  },
  {
    width: 200,
    Header: t`VAT GL Taxbase`,
    accessor: 'glBaseAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
      icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
      action: (item) => item.glBase !== null && 'baseClick',
    },
  },
  {
    width: 140,
    Header: t`Taxbase Difference`,
    accessor: 'baseDeviation',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
    },
  },
  {
    width: 140,
    Header: t`VAT-Report VAT`,
    accessor: 'vatReturnTaxAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
    },
  },
  {
    width: 140,
    Header: t`GL VAT`,
    accessor: 'glTaxAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
      icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
      action: (item) => (item?.reverseRow ? 'reverseClick' : 'taxClick'),
    },
  },
  {
    width: 140,
    Header: t`VAT Difference`,
    accessor: 'taxDeviation',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      rounded: true,
    },
  },
];

export default { columns };
