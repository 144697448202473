import React from 'react';
import styled from 'styled-components/macro';
import _isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Modal, Row, Col } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { AiOutlineReload } from '@react-icons/all-files/ai/AiOutlineReload';

import {
  Table,
  Field,
  Button,
  Toggle,
  Heading,
  Spinner,
  TableRow,
  TableCell,
  ButtonIcon,
} from 'components';

function ReconciledListPreviewModal(props) {
  const {
    data,
    header,
    failed,
    loading,
    account,
    roleType,
    showModal,
    submitted,
    submitting,
    toggleModal,
    hiddenItems,
    expandAllRows,
    deselectedItems,
    toggleOpenedItems,
    handleAutoReconcile,
    handleExpandAllRows,
    handleReconcileChange,
  } = props;
  let content;
  const sortRows = (a, b) => {
    const nameA = a.sourceDocumentId?.toUpperCase();
    const nameB = b.sourceDocumentId?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };
  const renderRow = (item, index) => {
    const firstItem = item[0];
    const lastItem = item.filter(
      (line) =>
        !line.sourceDocumentId.includes(firstItem.sourceDocumentId.toString()),
    )[0];
    const doubleCondition =
      firstItem.sourceDocumentId.valueOf() ===
        lastItem?.sourceDocumentId.valueOf() || !lastItem;
    const opened = !hiddenItems.includes(firstItem.sourceDocumentId);

    const rows = [
      <TableRow
        key={`glReconcileRecordList-${firstItem.sourceDocumentId}-${index}`}
      >
        <TableCell>
          <Field
            id={`${firstItem.sourceDocumentId}-reconcile-parent`}
            name="reconcile-parent"
            type="checkbox"
            value={firstItem.sourceDocumentId}
            checked={!deselectedItems.includes(firstItem.sourceDocumentId)}
            onChange={handleReconcileChange}
          />
        </TableCell>
        <TableCell>
          {doubleCondition
            ? firstItem.sourceDocumentId
            : `${lastItem?.sourceDocumentId},
            ${firstItem.sourceDocumentId}`}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell number className="text-right">
          {item.reduce((sum, b) => sum + b.amount, 0)}
        </TableCell>
        <TableCell className="text-right">
          <Button
            simple
            magnify
            kind="default"
            onClick={() => toggleOpenedItems(firstItem.sourceDocumentId)}
          >
            {opened ? (
              <ButtonIcon as={IoIosArrowUp} />
            ) : (
              <ButtonIcon as={IoIosArrowDown} />
            )}
          </Button>
        </TableCell>
      </TableRow>,
    ];

    if (opened) {
      rows.push(
        ...item.sort(sortRows).map((el) => (
          <TableRow
            // eslint-disable-next-line max-len
            key={`glReconcileRecordList-${el.lineId}`}
          >
            <TableCell>
              <Field
                id={`${el.lineId}-reconcile-child`}
                name="reconcile-child"
                type="checkbox"
                value={el.lineId}
                checked={!deselectedItems.includes(el.lineId)}
                onChange={handleReconcileChange}
              />
            </TableCell>
            <TableCell>{!doubleCondition ? el.sourceDocumentId : ''}</TableCell>
            <TableCell>{el.description}</TableCell>
            <TableCell>{el.period}</TableCell>
            <TableCell>{el.year}</TableCell>
            <TableCell number className="text-right">
              {el.amount}
            </TableCell>
            <TableCell />
          </TableRow>
        )),
      );
    }

    return rows;
  };

  if (loading) {
    content = (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  } else if (failed) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>Something went wrong! Please try again.</Trans>
      </Heading>
    );
  } else if (_isEmpty(data?.suggestions)) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>No data to display.</Trans>
      </Heading>
    );
  } else {
    content = (
      <>
        <TopHolder>
          <Toggle
            value={expandAllRows}
            label={t`Expand all rows`}
            onClick={handleExpandAllRows}
          />
        </TopHolder>
        <Table responsive head={header}>
          {data.suggestions.map(renderRow)}
        </Table>
      </>
    );
  }

  return (
    <Modal
      show={showModal}
      bsSize="lg"
      onEscapeKeyDown={() => toggleModal('handleAutoReconcile')}
    >
      <Modal.Header>
        <Heading level={3}>
          <Trans>Reconciled Details: {account}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>{content}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!roleType.isReadOnly && (
          <Button
            kind="info"
            onClick={handleAutoReconcile}
            disabled={failed || submitting}
          >
            {submitting ? (
              <Spinner size="md" type="info" />
            ) : (
              <>
                <ButtonIcon as={AiOutlineReload} />{' '}
                <Trans>Approve Suggestion</Trans>
              </>
            )}
          </Button>
        )}
        <Button
          fill
          magnify
          kind={submitted ? 'success' : 'danger'}
          disabled={loading || submitting}
          onClick={() => toggleModal('handleAutoReconcile')}
        >
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReconciledListPreviewModal.propTypes = {
  data: PropTypes.shape({}),
  header: PropTypes.node.isRequired,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  account: PropTypes.string,
  submitted: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  handleAutoReconcile: PropTypes.func.isRequired,
};

ReconciledListPreviewModal.defaultProps = {
  data: undefined,
  failed: false,
  account: null,
  loading: false,
  submitted: false,
  submitting: false,
};

export default ReconciledListPreviewModal;

const TopHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
