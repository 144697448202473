import { t } from '@lingui/macro';

/**
 * Map Transaction Id Grid
 */
export const columns = () => [
  {
    id: 1,
    label: t`Map Transactions`,
    value: '',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 2,
    label: t`Account`,
    value: '',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 3,
    label: t`Period`,
    value: '',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 4,
    label: t`Value Date`,
    value: '',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 5,
    label: t`Trans. ID`,
    value: '',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 6,
    label: t`Text`,
    value: '',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 7,
    label: t`Amount`,
    value: '',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
