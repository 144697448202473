import React, { useState, useMemo, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import _isEmpty from 'lodash-es/isEmpty';
import { useDispatch } from 'react-redux';
import _debounce from 'lodash-es/debounce';

import { FaSearch } from '@react-icons/all-files/fa/FaSearch';

import {
  LottieBox,
  MultipleChart,
  DashboardMain,
  DashboardTable,
  DashboardTemplates,
  DashboardLastActivity,
} from 'components';

import { filterByRole } from 'services/shapes';
import { labels } from 'services/utils/testMockData';
import { putCompanyCommentApi } from 'services/apihelpers';
import { dashboardChartData } from 'services/chartShapes';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { dashboardChartOptions } from 'services/chartOptions';
import { useCurrentWidth, useCurrentHeight } from 'services/hooks';

import { dashboardEmployeeStatusColumns } from 'services/tableshapes';

import { resourceUpdateRequest } from 'store/actions';
import { PERIOD_TYPES_LENGTHS } from 'constants/general';
import { DESKTOP, LARGE_SCREENS, FOUR_K } from 'constants/mediaSizes';

import enabled from 'assets/animations/enabled.json';
import disabled from 'assets/animations/disabled.json';
import { toAdminCompanySettings } from '../../../services/routehelpers/index';
import Link from '../../atoms/Link/index';

const mainBoxSmallSizes = ['100%', '74%', '54%'];
const mainBoxSizes = ['65.66%', '52%', '40%'];
const mainBoxSizesFullHd = ['66%', '48%', '48%'];
const mainBox2KSizes = ['45.5%', '34.5%', '28%'];

const statusBoxTitle = (value) => (value ? t`Approved` : t`Not Approved`);

export function DashboardMyProgressNew(props) {
  const {
    company,
    match,
    data,
    handleRedirect,
    statistics,
    chartLoading,
    chartError,
    hasValidIntegration,
  } = props;
  const dispatch = useDispatch();

  const { currentCompanyName, currentPeriodType } = company;
  const { params } = match;
  const {
    lastActivity,
    employeeStatus,
    companyComment,
    accountingSystem,
    overAllCompanyProgress,
    companyProgress = {},
  } = data;
  const { payrollApproved, vatApproved } = companyProgress;

  const { vatEnabled, payrollEnabled } = company.companySettings;
  const vatORpayroll = [vatEnabled, payrollEnabled].filter(Boolean).length;

  const [searchValue, setSearch] = useState('');
  const [comment, setComment] = useState();
  const transformedPeriod = defineFrequencyPeriodTypes(
    currentPeriodType,
    params.accountingPeriod,
  );

  const screenSize = useCurrentWidth();
  const screenHeight = useCurrentHeight();

  const { chartData, options } = useMemo(() => {
    const defaultValues = Array.from(
      { length: PERIOD_TYPES_LENGTHS[currentPeriodType] || 12 },
      () => 0,
    );
    const xLabels = labels(params.accountingYear, currentPeriodType);

    if (_isEmpty(statistics) || _isEmpty(statistics?.monthly)) {
      return {
        chartData: dashboardChartData(
          screenSize,
          xLabels,
          defaultValues,
          defaultValues,
        ),
        options: dashboardChartOptions(
          screenSize,
          defaultValues,
          defaultValues,
        ),
      };
    }

    const approvedNumbers = [];
    const controlledNumbers = [];
    const approvedPercentages = [];
    const controlledPercentages = [];

    statistics.monthly.forEach((item) => {
      const { approved, controlled, accounts } = item;

      approvedNumbers.push(approved);
      controlledNumbers.push(controlled);
      approvedPercentages.push(
        approved ? parseFloat(((approved / accounts) * 100).toFixed(1)) : 0,
      );
      controlledPercentages.push(
        controlled ? parseFloat(((controlled / accounts) * 100).toFixed(1)) : 0,
      );
    });

    return {
      chartData: dashboardChartData(
        screenSize,
        xLabels,
        approvedPercentages,
        controlledPercentages,
      ),
      options: dashboardChartOptions(
        screenSize,
        approvedNumbers,
        controlledNumbers,
      ),
    };
  }, [screenSize, statistics, params.accountingYear, currentPeriodType]);

  const employeeData = useMemo(() => {
    if (_isEmpty(employeeStatus || _isEmpty(employeeStatus?.employeesData))) {
      return { data: [], additional: {} };
    }

    const { employeesData, unAssigned } = employeeStatus;

    return {
      data: employeesData.map((item) => {
        const { position, ...rest } = item;

        const positionName = filterByRole().find((pos) => pos.id === position);

        return {
          ...rest,
          id: rest.employee,
          position: positionName?.label || '-',
        };
      }),
      additional: { employee: t`Unassigned Accounts:`, assigned: unAssigned },
    };
  }, [employeeStatus]);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onCompanyCommentChange = (e) => {
    setComment(e.target.value);
  };

  const saveCompanyComment = useCallback(
    _debounce(async (value) => {
      try {
        await dispatch(
          resourceUpdateRequest(putCompanyCommentApi, null, {
            companyComment: value,
            uuid: params.companyId,
          }),
        );
        toast.success(t`Company Comment was successfully changed`);
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    }, 800),
    [],
  );

  useEffect(() => {
    if (comment !== undefined && comment !== companyComment) {
      saveCompanyComment(comment);
    }
  }, [comment]);

  useEffect(() => {
    if (companyComment) {
      setComment(companyComment);
    }
  }, [companyComment]);

  const employeeStatusBox = (height = '32vh', padding = '0.5em') => (
    <>
      <Title>
        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Trans>Users' Status</Trans>
        </p>
        <SearchContainer>
          <Search
            type="text"
            value={searchValue}
            onChange={onSearch}
            placeholder={t`Search`}
          />
          <FaSearch />
        </SearchContainer>
      </Title>
      <BoxInner pTop={padding}>
        <DashboardTable
          withTotal
          withSearch
          height={height}
          search={searchValue}
          name="employeeStatus"
          data={employeeData.data}
          additional={employeeData.additional}
          searchFields={['employee', 'position']}
          columns={dashboardEmployeeStatusColumns()}
        />
      </BoxInner>
    </>
  );

  const chartSection = (
    // eslint-disable-next-line default-param-last
    height = '42vh',
    key,
    vPad = '1em',
    lPad = '1.3em',
    rPad = '1.7em',
  ) => (
    <>
      <Title>
        <p>
          <Trans>Approved / Controlled over several periods</Trans>
        </p>
      </Title>
      <BoxInner pTop={vPad} pLeft={lPad} pRight={rPad} pBottom={vPad}>
        <MultipleChart
          height={height}
          type="bar"
          key={key}
          data={chartData}
          options={options}
          error={chartError}
          loading={chartLoading}
        />
      </BoxInner>
    </>
  );

  const mainSection = (
    <>
      <Title noMargin noLeftPadding justify="flex-start" mBottom="1em">
        <p>{currentCompanyName}</p>
        <TitleSpan>
          <Trans>
            Period - {transformedPeriod}, {params.accountingYear}
          </Trans>
        </TitleSpan>
      </Title>
      <DashboardMain
        // 0 - Small, 1 - Middle, 2 - Large
        size={vatORpayroll}
        company={company}
        overallCompanyProgress={overAllCompanyProgress}
      />
    </>
  );

  const summarySection = (
    <>
      <Title noMargin noLeftPadding mBottom="1em">
        <p>
          <Trans>Summary</Trans>
        </p>
      </Title>
      <VerticalBox>
        {vatEnabled && (
          <VerticalBoxItem onClick={() => handleRedirect('vat')}>
            <LottieBox
              title={t`VAT`}
              direction="column"
              width={vatApproved ? '5.4em' : '5em'}
              status={statusBoxTitle(vatApproved)}
              animation={vatApproved ? enabled : disabled}
            />
          </VerticalBoxItem>
        )}
        {payrollEnabled && (
          <VerticalBoxItem onClick={() => handleRedirect('payroll')}>
            <LottieBox
              title={t`Payroll`}
              direction="column"
              width={payrollApproved ? '5.4em' : '5em'}
              status={statusBoxTitle(payrollApproved)}
              animation={payrollApproved ? enabled : disabled}
            />
          </VerticalBoxItem>
        )}
      </VerticalBox>
    </>
  );

  const lastActivitySection = (
    <>
      <Title noMargin noLeftPadding mBottom="1em">
        <p>
          <Trans>Last Activity</Trans>
        </p>
      </Title>
      <DashboardLastActivity
        lastActivity={lastActivity}
        accountingSystem={accountingSystem}
      />
    </>
  );

  const commentSection = (padding = '1.05em') => (
    <>
      <Title noMargin noLeftPadding mBottom="0.8em">
        <p>
          <Trans>Company Comment</Trans>
        </p>
      </Title>
      <BoxInner
        pTop={padding}
        pBottom={padding}
        pLeft={padding}
        pRight={padding}
        background="white"
      >
        <TextArea
          spellcheck="false"
          data-gramm="false"
          value={comment}
          placeholder={t`Type company comment here...`}
          onChange={onCompanyCommentChange}
        />
      </BoxInner>
    </>
  );

  const templatesSection = (direction) => (
    <>
      <Title noMargin noLeftPadding mBottom="1em">
        <p>
          <Trans>Template Statistics</Trans>
        </p>
      </Title>
      <DashboardTemplates
        direction={direction}
        handleRedirect={handleRedirect}
        companyProgress={companyProgress}
      />
    </>
  );

  const mobileDashboard = (
    <>
      <Row mTop="0">
        <Box>{mainSection}</Box>
      </Row>
      {vatORpayroll > 0 && (
        <Row>
          <Box>{summarySection}</Box>
        </Row>
      )}
      <Row>
        <Box>{lastActivitySection}</Box>
      </Row>
      <Row>
        <Box>{commentSection('0.8em')}</Box>
      </Row>
      <Row>
        <Box>{templatesSection('column')}</Box>
      </Row>
      <Row>
        <Box white boxShadow>
          {employeeStatusBox(null, '0')}
        </Box>
      </Row>
      {currentPeriodType !== 'yearly' && (
        <Row>
          <Box white boxShadow>
            {chartSection('290px')}
          </Box>
        </Row>
      )}
    </>
  );

  const smallLaptopDashboard = (
    <>
      <Row mTop="0">
        <Box width={mainBoxSmallSizes[vatORpayroll]}>{mainSection}</Box>
        {vatORpayroll > 0 && (
          <Box width={vatORpayroll === 1 ? '25%' : '45%'}>{summarySection}</Box>
        )}
      </Row>
      <Row>
        <Box width="54%">{commentSection('0.8em')}</Box>
        <Box width="45%">{lastActivitySection}</Box>
      </Row>
      <Row>
        <Box>{templatesSection()}</Box>
      </Row>
      <Row>
        <Box white boxShadow>
          {employeeStatusBox(null, '0')}
        </Box>
      </Row>
      {currentPeriodType !== 'yearly' && (
        <Row>
          <Box white boxShadow>
            {chartSection('290px')}
          </Box>
        </Row>
      )}
    </>
  );

  const laptopDashboard = (
    <>
      <Row mTop="0">
        <Box width={mainBoxSizes[vatORpayroll]}>{mainSection}</Box>
        {vatORpayroll > 0 && (
          <Box width={vatORpayroll === 1 ? '13%' : '24.5%'}>
            {summarySection}
          </Box>
        )}
        <Box width="33%">{lastActivitySection}</Box>
      </Row>
      <Row>
        <Box width="66%">{templatesSection()}</Box>
        <Box width="33%">{commentSection('0.8em')}</Box>
      </Row>
      <Row>
        <Box white boxShadow>
          {employeeStatusBox(null, '0')}
        </Box>
      </Row>
      {currentPeriodType !== 'yearly' && (
        <Row>
          <Box white boxShadow>
            {chartSection('290px')}
          </Box>
        </Row>
      )}
    </>
  );

  const fullHDDashboard = (
    <>
      <Row mTop="0.3em">
        <Box width={mainBoxSizesFullHd[vatORpayroll]}>{mainSection}</Box>
        {vatORpayroll > 0 && (
          <Box width={vatORpayroll === 1 ? '17%' : '21.18%'}>
            {summarySection}
          </Box>
        )}
        <Box width={[1, 0].includes(vatORpayroll) ? '33%' : '29%'}>
          {lastActivitySection}
        </Box>
      </Row>
      <Row>
        <Box width="48%">{commentSection()}</Box>
        <Box width="51%">{templatesSection()}</Box>
      </Row>
      <Row>
        {currentPeriodType === 'yearly' ? (
          <Box white boxShadow>
            {employeeStatusBox('27vh')}
          </Box>
        ) : (
          <>
            <Box width="48%" white boxShadow>
              {chartSection('40vh')}
            </Box>
            <Box width="51%" white boxShadow>
              {employeeStatusBox('27vh')}
            </Box>
          </>
        )}
      </Row>
    </>
  );

  const twoKDashboard = (
    <>
      <Row mTop="0.5em">
        <Box width={mainBox2KSizes[vatORpayroll]}>{mainSection}</Box>
        {vatORpayroll > 0 && (
          <Box width={vatORpayroll === 1 ? '10%' : '16.9%'}>
            {summarySection}
          </Box>
        )}
        <Box width="29%">{commentSection()}</Box>
        <Box width="24%">{lastActivitySection}</Box>
      </Row>
      <Row mTop="3em">
        {currentPeriodType === 'yearly' ? (
          <Box>
            <Box width="45.5%">{templatesSection()}</Box>
            <Box white boxShadow mTop="1.5em">
              {employeeStatusBox('28vh')}
            </Box>
          </Box>
        ) : (
          <>
            <Box width="45.5%">
              <Box>{templatesSection()}</Box>
              <Box white boxShadow mTop="1.5em">
                {employeeStatusBox('28vh')}
              </Box>
            </Box>
            <Box white boxShadow width="53.8%">
              {chartSection('100%', screenHeight, '2.2em', '1.7em', '2.5em')}
            </Box>
          </>
        )}
      </Row>
    </>
  );

  const fourKDashboard = (
    <>
      <Row mTop="2em">
        <Box width={mainBox2KSizes[vatORpayroll]}>{mainSection}</Box>
        {vatORpayroll > 0 && (
          <Box width={vatORpayroll === 1 ? '10%' : '16.9%'}>
            {summarySection}
          </Box>
        )}
        <Box width="29%">{commentSection()}</Box>
        <Box width="24%">{lastActivitySection}</Box>
      </Row>
      <Row mTop="4em">
        {currentPeriodType === 'yearly' ? (
          <Box>
            <Box width="45.5%">{templatesSection()}</Box>
            <Box white boxShadow mTop="1.5em">
              {employeeStatusBox('40vh')}
            </Box>
          </Box>
        ) : (
          <>
            <Box width="45.5%">
              <Box>{templatesSection()}</Box>
              <Box white boxShadow mTop="1.5em">
                {employeeStatusBox('40vh')}
              </Box>
            </Box>
            <Box width="53.8%" white boxShadow>
              {chartSection('100%', screenHeight, '2.2em', '1.7em', '2.5em')}
            </Box>
          </>
        )}
      </Row>
    </>
  );

  const p = ({ children }) => (
    <span style={{ color: 'black' }}>{children}</span>
  );

  return (
    <Container>
      {!hasValidIntegration && (
        <div className="alert alert-warning" role="alert">
          <Trans component={p}>
            The integration with the accounting system of{' '}
            {company.currentCompanyName} is not valid.{' '}
            <Link
              to={() =>
                toAdminCompanySettings({
                  companyIdToEdit: company.currentCompanySID,
                })
              }
              onClick={() => {}}
              style={{ color: 'black', textDecoration: 'underline' }}
            >
              Check your company&apos;s settings.
            </Link>
          </Trans>
        </div>
      )}
      {screenSize <= 768 && mobileDashboard}
      {screenSize > 768 && screenSize <= 1280 && smallLaptopDashboard}
      {screenSize > 1280 && screenSize <= 1366 && laptopDashboard}
      {screenSize < 2560 && screenSize > 1366 && fullHDDashboard}
      {screenSize >= 2560 && screenSize < 3840 && twoKDashboard}
      {screenSize >= 3840 && fourKDashboard}
    </Container>
  );
}

export default DashboardMyProgressNew;

const VerticalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 0.75em;
  width: 100%;
  height: 100%;
`;

const VerticalBoxItem = styled.div`
  flex-basis: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  transition: all 0.2s;

  :hover {
    transform: scale(1.05);
  }
`;

const SearchContainer = styled.div`
  width: 30%;
  position: relative;

  svg {
    position: absolute;
    left: 0.71em;
    top: 50%;
    transform: translateY(-50%);
    width: 0.85em;
  }
`;

const Search = styled.input`
  background-color: rgba(47, 58, 77, 0.06);
  width: 100%;
  font-size: 0.85em;
  font-weight: 500;
  padding: 0.35em 0.7em 0.35em 2.5em;
  border-radius: 5px;

  &::placeholder {
    color: rgba(47, 58, 77, 0.37);
  }

  &:focus {
    border: 2px solid #2f3a4d;
    padding-left: 2.3em;
  }

  @media (max-width: ${DESKTOP.max}px) {
    padding: 0.25em 0.7em 0.25em 2.5em;
  }

  @media (min-width: ${FOUR_K.min}px) {
    padding: 1.4em 0.7em 1.4em 2.5em;
  }
`;

const TitleSpan = styled.span`
  font-weight: 600;
  padding: 0.14em 1em;
  line-height: 1.4;
  border-radius: 25px;
  margin-left: 0.6em;
  color: white;
  font-size: 0.92em;
  letter-spacing: 0.2px;
  background: #ba3434;
  min-width: fit-content;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 1.05em;
  border-radius: 5px;
  color: #6a6666;
  outline: none;
  resize: none;
  line-height: 1.5;
  border: none;
  background: rgba(236, 236, 236, 0.58);

  ::-webkit-scrollbar {
    display: none;
  }

  ::placeholder {
    color: #cbc5c5;
  }

  @media (max-width: ${DESKTOP.max}px) {
    padding: 0.4em 0.7em;
  }
`;

const Container = styled.div`
  font-family: 'Roboto', 'Helvetica', Arial, sans-serif;
  margin: 1em;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (min-width: ${FOUR_K.min}px) {
    margin: 1em 2em;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  margin-top: ${({ mTop }) => mTop || '1.5em'};

  @media (min-width: ${FOUR_K.min}px) {
    margin-top: ${({ mTop }) => mTop || '2em'};
  }
`;

const Box = styled.div`
  width: ${({ width }) => width || '100%'};
  background-color: ${({ white }) => white && 'white'};
  margin-top: ${({ mTop }) => mTop && mTop};
  height: ${({ height }) => height && height};
  box-shadow: ${({ boxShadow }) =>
    boxShadow && `rgba(149, 157, 165, 0.1) 0px 8px 24px`};
  border-radius: 5px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ direction }) => direction === 'row' && 'space-between'};

  font-size: 14px;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 12px;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 13px;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
  }
`;

const BoxInner = styled.div`
  display: flex;
  flex: 1;
  border-radius: 5px;
  background: ${({ background }) => background || 'transparent'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  padding: ${({ pTop, pRight, pBottom, pLeft }) =>
    `${pTop || '1.6em'} ${pRight || '1.6em'} ${pBottom || '1.6em'} ${
      pLeft || '1.6em'
    }`};
`;

const Title = styled.div`
  font-weight: 700;
  margin-top: ${({ noMargin }) => !noMargin && '1.4em'};
  margin-bottom: ${({ mBottom }) => mBottom && mBottom};
  color: #2f3a4d;
  padding-right: ${({ noMargin }) => !noMargin && '1.6em'};
  padding-left: ${({ noLeftPadding }) => (noLeftPadding ? 0 : '1.6em')};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    font-size: 1.25em;
  }

  @media (max-width: ${DESKTOP.max}px) {
    margin-bottom: 0.55em;
  }

  @media (min-width: 2560px) {
    margin-top: ${({ noMargin }) => !noMargin && '2em'};
    padding-right: ${({ noMargin }) => !noMargin && '2em'};
    padding-left: ${({ noLeftPadding }) => (noLeftPadding ? 0 : '2em')};
  }

  @media (min-width: ${FOUR_K.min}px) {
    p {
      font-size: 1.35em;
    }
  }
`;
