import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';
import { pending } from 'redux-saga-thunk';

import { CommentsModal, AnimateAlert } from 'components';

import commentsQueryGenerator from 'services/queryhelpers';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';

function CommentsModalContainer(props) {
  const {
    user,
    company,
    roleType,
    showModal,
    toggleModal,
    componentSetupValues,
  } = props;

  const { commentsRoutes, commentModalType } = componentSetupValues;
  const {
    getCommentsApi,
    deleteCommentApi,
    createCommentsApi,
    commentsCreateThunkApi,
    editCurrentCommentsApi,
  } = commentsRoutes;

  const [showAll, setShowAll] = useState(false);
  const [commentsData, setCommentsData] = useState({
    comment: '',
    isEditComment: false,
    alertProps: {
      show: false,
    },
  });

  const dispatch = useDispatch();

  const loading = useSelector((state) =>
    pending(state, commentsCreateThunkApi),
  );

  const handleChange = (event) => {
    const { target } = event;
    const { value, name } = target;

    setCommentsData((prevState) => ({ ...prevState, [name]: value }));
  };

  const editComment = (commentSID) => {
    const { comments } = commentsData;
    const { commentHistoryList } = comments;
    const editableComment = [];
    Object.keys(commentHistoryList).map((item) => {
      if (commentHistoryList[item].commentSID === commentSID) {
        editableComment.push(commentHistoryList[item].comment);
      }

      return '';
    });
    setCommentsData((prevState) => ({
      ...prevState,
      isEditComment: true,
      comment: editableComment,
      commentSID,
    }));
  };

  const getComments = async () => {
    const query = commentsQueryGenerator(
      componentSetupValues.commentModalType,
      componentSetupValues.recordId,
      company,
      '',
      user,
      showAll,
    );

    const response = await dispatch(
      resourceListReadRequest(getCommentsApi, query.getComments),
    );

    if (response) {
      setCommentsData((prevState) => ({ ...prevState, comments: response }));
    }
  };

  const showAllComments = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : false;
    setShowAll(value);
  };

  const handleSubmit = async () => {
    const { comment, isEditComment, commentSID } = commentsData;

    const companyId = company.currentCompanySID;
    const editQuery = {
      comment,
      commentSID,
      companyId,
    };
    if (!isEditComment) {
      const query = commentsQueryGenerator(
        componentSetupValues.commentModalType,
        componentSetupValues.recordId,
        company,
        comment,
        user,
      );

      try {
        await dispatch(
          resourceCreateRequest(createCommentsApi, query.submitComment),
        );
        setCommentsData((prevState) => ({ ...prevState, comment: '' }));
        getComments();
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    } else if (
      componentSetupValues.commentModalType !== 'payrollComment' &&
      componentSetupValues.commentModalType !== 'vatComment'
    ) {
      try {
        await dispatch(
          resourceUpdateRequest(editCurrentCommentsApi, null, editQuery),
        );
        setCommentsData((prevState) => ({
          ...prevState,
          comment: '',
          isEditComment: false,
        }));
        getComments();
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    } else {
      try {
        await dispatch(
          resourceUpdateRequest(
            // eslint-disable-next-line max-len
            `${editCurrentCommentsApi}/${commentSID}/${comment}?${queryString.stringify(
              {
                ...editQuery,
                commentId: commentSID,
                text: comment,
              },
            )}`,
          ),
        );
        setCommentsData((prevState) => ({
          ...prevState,
          comment: '',
          isEditComment: false,
        }));
        getComments();
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    }
  };

  const hideAlert = () =>
    setCommentsData((prevState) => ({
      ...prevState,
      alertProps: { show: false },
    }));

  const deleteComment = async (commentSID) => {
    try {
      if (
        commentModalType !== 'payrollComment' &&
        commentModalType !== 'vatComment'
      ) {
        await dispatch(
          resourceDeleteRequest(deleteCommentApi, null, {
            commentSID,
            companyId: company.currentCompanySID,
          }),
        );
        getComments();
      } else {
        await dispatch(
          resourceDeleteRequest(
            `${deleteCommentApi}/${commentSID}?${queryString.stringify({
              commentId: commentSID,
              companyId: company.currentCompanySID,
            })}`,
          ),
        );
        getComments();
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const handleDeleteComment = (commentSID) => {
    setCommentsData((prevState) => ({
      ...prevState,
      alertProps: {
        show: true,
        type: 'warning',
        title: t`Warning`,
        onCancel: hideAlert,
        onConfirm: () => {
          hideAlert();
          deleteComment(commentSID);
        },
        children: <Trans>Are you sure you want to delete this comment?</Trans>,
        showCancel: true,
        cancelBtnText: t`Close`,
        confirmBtnText: t`Delete`,
        closeOnClickOutside: true,
      },
    }));
  };

  useEffect(() => {
    getComments();
  }, [showAll]);

  return (
    <>
      <AnimateAlert {...commentsData.alertProps} />
      <CommentsModal
        editComment={editComment}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        deleteComment={handleDeleteComment}
        showAllComments={showAllComments}
        {...{
          user,
          company,
          loading,
          roleType,
          showModal,
          toggleModal,
          commentModalType,
          comment: commentsData.comment,
          comments: commentsData.comments,
        }}
      />
    </>
  );
}

export default CommentsModalContainer;

CommentsModalContainer.propTypes = {
  recordId: PropTypes.string.isRequired,
  company: PropTypes.shape({
    data: {
      currentCompanySID: PropTypes.number.isRequired,
    },
  }).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  editCurrentComment: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  componentSetupValues: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  roleType: PropTypes.shape({}).isRequired,
};

CommentsModalContainer.defaultProps = {
  loading: false,
  failed: false,
};
