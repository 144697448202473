import React from 'react';
import { BasicTemplate } from 'components';
import SelectCompany from 'containers/SelectCompany';

export function SelectCompanyPage(props) {
  return (
    <BasicTemplate noLogin {...props}>
      <SelectCompany {...props} />
    </BasicTemplate>
  );
}

export default SelectCompanyPage;
