import { useMemo, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getVATControlApi, getVATDeviationsApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { vatGroupTypeOptions } from 'services/shapes/index';

export const useVATControlData = (props) => {
  const { accountingYear, companySid, activePeriods, accumulated, frequency } =
    props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const rows = useSelector((state) =>
    fromResource.getList(state, getVATControlApi),
  );

  const hasDeviation =
    useSelector((state) => fromResource.getList(state, getVATDeviationsApi))
      .status || false;

  const fetchRows = async () => {
    await dispatch(
      resourceListReadRequest(getVATControlApi, {
        companySid,
        year: accountingYear,
        periodType: frequency,
        period: frequency === 'YEARLY' ? 12 : activePeriods,
        accumulated,
      }),
    );
  };

  const preparedData = useMemo(() => {
    if (!Array.isArray(rows)) return [];
    return rows.map((row) => ({
      ...row,
      groupType:
        vatGroupTypeOptions().find((option) => option.tableId === row.groupType)
          ?.label || '',
    }));
  }, [rows]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await fetchRows();
      setTimeout(() => {
        dispatch(
          resourceListReadRequest(getVATDeviationsApi, {
            companyId: companySid,
            year: accountingYear,
          }),
        );
      }, 1000);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companySid, accountingYear, accumulated, activePeriods, frequency]);

  return { rows: preparedData, loading, fetchData, hasDeviation };
};

export default useVATControlData;
