import { t } from '@lingui/macro';

import { filterByTemplateStatuses } from 'services/shapes';

export const reconciliationRulesFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['name', 'description', 'template', 'accounts'],
  },
  {
    id: 'template',
    type: 'multiSelect',
    label: t`Templates`,
    options: filterByTemplateStatuses(),
    disableSort: true,
    placeholder: t`Please select Template`,
  },
];

export default reconciliationRulesFilters;
