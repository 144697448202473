import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 70,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 70,
    Header: t`ID`,
    accessor: 'accountId',
    className: '',
  },
  {
    width: 120,
    Header: t`Line ID`,
    accessor: 'lineId',
    className: '',
    withTooltip: true,
  },
  {
    width: 110,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    withTooltip: true,
  },
  {
    width: 160,
    Header: t`Company`,
    accessor: 'companyName',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: 'flex-right',
  },
  {
    width: 70,
    Header: t`Rate`,
    accessor: 'vatRate',
  },
  {
    width: 220,
    Header: t`VAT Description`,
    accessor: 'vatDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 180,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 80,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 120,
    Header: t`Date`,
    accessor: 'postedDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  {
    width: 120,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
