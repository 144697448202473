import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { IoMdSettings } from '@react-icons/all-files/io/IoMdSettings';
import { MdOutlineSort } from '@react-icons/all-files/md/MdOutlineSort';
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  Label,
  Field,
  Button,
  Spinner,
  ButtonIcon,
  PeriodFilterButtons,
  CustomTableWithAutoSettings,
} from 'components';

import { VATReportNewerColumns } from 'services/tableshapes';
import { vatReportNewerFilters } from 'services/tableFilterShapes';

const periodTypes = {
  BIMONTHLY: 'biMonthly',
  MONTHLY: 'monthly',
};

const values = {
  BIMONTHLY: t`Bi-Monthly`,
  YEARLY: t`Yearly`,
  MONTHLY: t`Monthly`,
};

function VATReportNewer(props) {
  const {
    year,
    loading,
    roleType,
    disabled,
    tableData,
    isApproved,
    frequency,
    frequencies,
    approverInfo,
    pdfGenerating,
    activePeriods,
    // accumulated,
    handleAction,
    unmappedLines,
    actionSubmitting,
    handlePeriodChange,
    handleFrequencyChange,
    // handleAccumulatedChange,
    autoReconcileLoading,
  } = props;

  const buttonText = useMemo(
    () => (isApproved ? t`Unapprove` : t`Approve`),
    [isApproved],
  );

  const { rows, metaData, isReportAvailable } = tableData;

  const sortBy = useMemo(
    () => [
      {
        id: 'vatCodeSaft',
        desc: false,
      },
    ],
    [],
  );

  // eslint-disable-next-line no-nested-ternary
  const buttonKind = loading ? '' : isApproved ? 'danger' : 'success';

  return (
    <Card
      id="pdfToPrint"
      data-testid="pdfToPrint-vatReportNewTable"
      header={<CardPDFTitle title={<Trans>VAT Report, {year}</Trans>} />}
    >
      <CheckHolder>
        {/* <Field */}
        {/*   horizontal */}
        {/*   id="showAccumulated" */}
        {/*   type="checkbox" */}
        {/*   name="showAccumulated" */}
        {/*   label={t`Show accumulated YTD Period`} */}
        {/*   checked={accumulated} */}
        {/*   onChange={handleAccumulatedChange} */}
        {/* /> */}
        {frequency !== 'YEARLY' && !!frequency && (
          <PeriodFilterButtons
            size="small"
            singlePeriod
            periodType={periodTypes[frequency]}
            activePeriods={Array.from(Array(+activePeriods).keys())}
            handleFilterChange={handlePeriodChange}
          />
        )}
        {frequencies?.length > 1 && (
          <InfoBlock>
            <Title>
              <Trans>Frequency:</Trans>
            </Title>
            <Field
              name="selectedReport"
              type="select"
              defaultValue={frequency}
              onChange={handleFrequencyChange}
            >
              {frequencies.map((item) => (
                <option key={`frequency-${item}`} value={item}>
                  {values[item] || item}
                </option>
              ))}
            </Field>
          </InfoBlock>
        )}

        {isReportAvailable && approverInfo && !loading && (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{approverInfo}</Tooltip>}
            placement="bottom"
          >
            <InfoIcon />
          </OverlayTrigger>
        )}
        {isReportAvailable && (
          <Button
            fill
            size="sm"
            disabled={roleType.isReadOnly || loading}
            kind={buttonKind}
            onClick={() => handleAction('approve', !isApproved)}
          >
            {loading ? <Spinner type="white" btn /> : buttonText}
          </Button>
        )}
        {!loading && (
          <>
            <Button
              fill
              size="sm"
              disabled={
                roleType.isReadOnly || autoReconcileLoading || isApproved
              }
              kind="info"
              onClick={() => handleAction('autoReconcile', true)}
            >
              {autoReconcileLoading ? (
                <Spinner type="white" btn />
              ) : (
                <Trans>Auto-Reconcile</Trans>
              )}
            </Button>
            <Button
              size="sm"
              disabled={
                roleType.isReadOnly || autoReconcileLoading || isApproved
              }
              kind="info"
              onClick={() => handleAction('autoReconcile', false)}
            >
              {autoReconcileLoading ? (
                <Spinner btn />
              ) : (
                <Trans>Undo reconciliation</Trans>
              )}
            </Button>
          </>
        )}
      </CheckHolder>
      <>
        <MetaData>
          {!!metaData?.length && (
            <Items>
              {metaData.map((item, i) => (
                <InfoBlock key={`item-${i}-${item._value}`}>
                  <Title>{item.title}</Title>
                  <Value>{item._value}</Value>
                </InfoBlock>
              ))}
            </Items>
          )}
        </MetaData>
        <CustomTableWithAutoSettings
          data={rows}
          tableId="vatReportNewerReport"
          {...{
            loading,
            handleAction,
            actionSubmitting,
            columns: VATReportNewerColumns(),
            loadingId: 'vatCodeSaft',
            enableGroups: true,
            filterConfigs: vatReportNewerFilters(),
            initialState: { sortBy },
            headerConfigs: {
              exportFileName: `${t`VAT Report`} - ${year}`,
              extraButtons: [
                {
                  id: 'generatePDF',
                  title: t`Generate PDF Report`,
                  icon: <ButtonIcon as={FaFilePdf} type="extraButton" />,
                  buttonProps: {
                    size: 'sm',
                    kind: 'default',
                    fill: false,
                    magnify: true,
                    disabled: pdfGenerating || disabled,
                  },
                },
                ...(!roleType.isReadOnly
                  ? [
                      {
                        id: 'authenticate',
                        title: t`Fetch VAT returns`,
                        buttonProps: {
                          size: 'sm',
                          kind: 'info',
                          fill: false,
                          magnify: true,
                        },
                      },
                    ]
                  : []),
                {
                  id: 'showUnmappedLines',
                  title: t`Unmapped Lines`,
                  icon: <ButtonIcon as={MdOutlineSort} type="extraButton" />,
                  buttonProps: {
                    size: 'sm',
                    kind: 'default',
                    fill: false,
                    magnify: true,
                    disabled: loading || !unmappedLines?.length,
                  },
                },
                {
                  id: 'goToSettings',
                  title: t`Settings`,
                  icon: <ButtonIcon as={IoMdSettings} type="extraButton" />,
                  buttonProps: {
                    size: 'sm',
                    kind: 'default',
                    fill: false,
                    magnify: true,
                  },
                },
              ],
            },
          }}
        />
      </>
    </Card>
  );
}

export default VATReportNewer;

const InfoIcon = styled(BiInfoCircle)`
  font-size: 20px;
  margin-left: -5px;
  color: gray;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-right: 8px;

  select {
    height: 22px;
    padding: 1px 6px;
    font-size: 12px;
  }
`;

const Title = styled((props) => <Label {...props} />)`
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Value = styled.div`
  font-size: 12px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const MetaData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-group {
    display: flex;
    align-items: center;
  }

  label {
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    margin-bottom: 0;
  }

  select {
    background: white;
    margin-top: 2px;
  }
`;

const Items = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
`;

const CheckHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
