import React, { useEffect, useState } from 'react';
import { MaintenanceBanner } from 'components';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { i18n } from '@lingui/core';

import { fromAuth } from 'store/selectors';
import client from 'services/utils/sanity';
import getErrorMessage from 'services/helpers/getErrorMessage';

function MaintenanceBannerContainer() {
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);
  const [maintenanceBannerColor, setMaintenanceBannerColor] = useState(null);
  const [showBanner, setShowBanner] = useState(true);

  const handleDismiss = () => {
    setShowBanner(false);
    localStorage.setItem(
      'discardedMaintenanceDate',
      maintenanceMessage?._updatedAt || new Date(),
    );
  };

  useEffect(() => {
    if (maintenanceMessage && maintenanceMessage._updatedAt) {
      const discardedMaintenanceDate = localStorage.getItem(
        'discardedMaintenanceDate',
      );
      if (discardedMaintenanceDate) {
        const discardedDate = new Date(discardedMaintenanceDate);
        const updatedAt = new Date(maintenanceMessage._updatedAt);
        if (discardedDate >= updatedAt) {
          setShowBanner(false);
        }
      }
    }
  }, [maintenanceMessage]);

  const user = useSelector((state) => fromAuth.getUser(state));

  const fetchData = () => {
    client
      .fetch(
        `*[_type == "maintenanceBanner" && language == "${
          user?.languageCode || i18n.locale
        }" && publish == true]`,
      )
      .then((data) => {
        if (data?.length > 0) {
          setMaintenanceMessage(data[0]);
          setMaintenanceBannerColor(data[0].bannerColor?.hex || '#fff');
        }
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return showBanner ? (
    <MaintenanceBanner
      bannerColor={maintenanceBannerColor}
      message={maintenanceMessage}
      handleDismiss={handleDismiss}
    />
  ) : null;
}

export default MaintenanceBannerContainer;
