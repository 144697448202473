import React from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs } from 'components';
import OrganizationSettings from 'containers/OrganizationSettings';
import OrganizationAltinnSetup from 'containers/OrganizationAltinnSetup';

import { toOrganisationSettings } from 'services/routehelpers';

/**
 * Organization Settings Page Component
 */

function OrganizationSettingsPage(props) {
  const { location, handleTabSelect } = props;
  const { tabEntry = 'overview' } = queryString.parse(location.search);
  const onTabSelect = (key) =>
    handleTabSelect(key, (data) => toOrganisationSettings(data));

  const tabs = [
    {
      title: t`Overview`,
      eventKey: 'overview',
      component: <OrganizationSettings {...props} />,
    },
    {
      title: t`Altinn Settings`,
      eventKey: 'altinn-settings',
      component: <OrganizationAltinnSetup {...props} />,
    },
  ];

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default OrganizationSettingsPage;
