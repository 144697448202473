import { t } from '@lingui/macro';

export const columns = (reportMode, isReadOnly) => [
  {
    width: reportMode ? 200 : 310,
    Header: t`Description`,
    accessor: 'name',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 160 : 180,
    Header: t`Registered`,
    sortType: 'basic',
    accessor: 'reportedTotal',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'reportedTotal',
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 160 : 170,
    Header: t`Payed`,
    sortType: 'basic',
    accessor: 'gl',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'gl',
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 160 : 170,
    Header: t`Due`,
    sortType: 'basic',
    accessor: 'deviation',
    className: 'flex-right',
    disableSortBy: true,
    predefinedTotal: 'deviation',
    cell: {
      type: 'number',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 180,
          hidden: reportMode,
          Header: t`Map transactions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'button',
            disableClick: true,
            buttons: [
              {
                id: 'mapTransaction',
                size: 'sm',
                kind: 'default',
                title: t`Map Transaction ID`,
                magnify: true,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
