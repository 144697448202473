import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, ButtonGroup } from 'react-bootstrap';
import BlockContent from '@sanity/block-content-to-react';

import { Button, Heading, ButtonIcon } from 'components';

import { formatDate } from 'services/helpers';
import { getDataSet } from 'services/utils/sanity';

function ReleaseNoteModal(props) {
  const { releaseNote, showModal, toggleHide } = props;

  return (
    <Modal show={showModal} onEscapeKeyDown={toggleHide}>
      <Modal.Header>
        <Header>
          <Heading level={3} style={{ margin: 0 }}>
            {releaseNote?.title}
          </Heading>
          <Date>
            <FaRegCalendarAlt />
            <DateHolder>{formatDate(releaseNote?.date)}</DateHolder>
          </Date>
        </Header>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <BlockContent
            blocks={releaseNote?.body}
            dataset={getDataSet()}
            projectId={process.env.SANITY_PROJECT_ID}
          />
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button fill kind="danger" magnify onClick={toggleHide}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

ReleaseNoteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
  releaseNote: PropTypes.shape({}).isRequired,
};

export default ReleaseNoteModal;

const Body = styled.div`
  margin: 0 10px 10px;

  & h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5em;
    margin: 20px 0 15px;
    font-weight: 600;
  }

  ul {
    margin-top: 10px;
    padding-left: 15px;
  }
  & li {
    margin: 0 0 5px;
    line-height: 22px;
  }

  img {
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 5px;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  svg {
    font-size: 16px;
    margin-top: -1px;
  }
`;

const DateHolder = styled.div`
  margin-left: 7px;
  font-family: 'Roboto', 'Helvetica', Arial, sans-serif;
`;
