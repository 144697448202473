import React from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs } from 'components';
import OpeningBalanceRegistration from 'containers/OpeningBalanceRegistration';
import OpeningBalanceMap from 'containers/OpeningBalanceMap';

import { toOpeningBalanceRegistration } from 'services/routehelpers';

/**
 * Opening Balance Registration Page component
 */

function OpeningBalanceRegistrationPage(props) {
  const { location, handleTabSelect } = props;
  const { tabEntry = 'registration', account } = queryString.parse(
    location.search,
  );
  const onTabSelect = (key) =>
    handleTabSelect(key, (data) =>
      toOpeningBalanceRegistration({ ...data, account }),
    );

  const tabs = [
    {
      title: t`Registration`,
      eventKey: 'registration',
      component: <OpeningBalanceRegistration {...props} />,
    },
    {
      title: t`Map`,
      eventKey: 'map',
      component: <OpeningBalanceMap {...props} />,
    },
  ];

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default OpeningBalanceRegistrationPage;
