import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { RiDownload2Fill } from '@react-icons/all-files/ri/RiDownload2Fill';

import { fileSizeFormatter } from 'services/helpers';
import { filterByFileCategory } from 'services/shapes';

export const columns = (reportMode, readOnly) => [
  {
    width: reportMode ? 60 : 80,
    Header: t`Year`,
    accessor: 'year',
    className: 'flex-center',
  },
  {
    width: reportMode ? 60 : 90,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: reportMode ? 200 : 280,
    Header: t`File Name`,
    accessor: 'fileName',
    className: '',
    withTooltip: !reportMode,
    cell: {
      type: 'input',
      inputType: 'text',
      disableClick: true,
      editable: !reportMode && !readOnly,
    },
  },
  {
    width: reportMode ? 100 : 120,
    Header: t`Category`,
    accessor: 'fileCategory',
    className: '',
    cell: {
      converter: (item) => {
        const shapeItem = filterByFileCategory().filter(
          (category) => category.id === item,
        );
        return shapeItem ? shapeItem[0].label : item;
      },
    },
  },
  {
    width: reportMode ? 100 : 120,
    Header: t`Category Item`,
    accessor: 'category',
    className: '',
    cell: {
      converter: (_, item) =>
        item?.fileCategory === 'ACCOUNT'
          ? item?.accountId
          : item?.transactionId,
    },
  },
  {
    width: reportMode ? 200 : 220,
    Header: t`Uploaded By`,
    accessor: 'uploadedBy',
    className: '',
    withTooltip: true,
  },
  {
    width: reportMode ? 180 : 200,
    Header: t`Uploaded At`,
    accessor: 'uploadedDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'datetime',
    },
  },
  {
    width: reportMode ? 70 : 90,
    Header: t`Size`,
    accessor: 'fileSize',
    disableSortBy: true,
    className: '',
    cell: {
      converter: (size) => fileSizeFormatter(size),
    },
  },
  {
    width: 110,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'download',
          icon: () => <RiDownload2Fill />,
          tooltip: t`Download`,
        },
        {
          id: 'preview',
          icon: () => <FaRegEye />,
          tooltip: t`Preview`,
          visible: (item) =>
            item.fileType?.includes('application/pdf') ||
            item.fileType?.includes('application/msword') ||
            item.fileType?.includes('application/vnd') ||
            (item.fileType?.includes('application/octet-stream') &&
              item.fileName?.includes('.docx')) ||
            item.fileType?.includes('image/'),
        },
        ...(!readOnly
          ? [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ]
          : []),
      ],
    },
  },
];

export default { columns };
