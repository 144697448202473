import React, { Component } from 'react';
import _get from 'lodash-es/get';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import b64toBlob from 'b64-to-blob';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';

import { AnimateAlert, FileUploadModal, FileOverview } from 'components';

import { api as authApi } from 'services/api';
import {
  getFileListApi,
  viewFileApi,
  putNewFilesOrder,
  renameFileApi,
  deleteFileApi,
  multipleFileUploadApi,
  uploadBankTransactionApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

import { FILE_RENAME_TIMEOUT_TIMEOUT } from 'constants/timeouts';

class FileUploadModalContainer extends Component {
  fileRenameTimeOut = null;

  api = authApi.create();

  static propTypes = {
    history: PropTypes.shape({}),
    search: PropTypes.shape({}),
    toRoute: PropTypes.func,
    company: PropTypes.shape({}).isRequired,
    modalTitle: PropTypes.string.isRequired,
    user: PropTypes.shape({}).isRequired,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    fileCategory: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
    recordId: PropTypes.string,
    roleType: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    history: undefined,
    search: undefined,
    toRoute: undefined,
    recordId: undefined,
    showModal: false,
    toggleModal: undefined,
  };

  state = {
    previewFile: {},
    uploadedFiles: [],
    sortedFiles: [],
    fileName: '',
    changeOrderLoading: false,
    isUploading: false,
    alertProps: {
      show: false,
    },
  };

  componentDidMount() {
    this.getFiles();
  }

  createQuery = (showAllFiles = false) => {
    const {
      account,
      recordId,
      company = {},
      fileCategory,
      transactionSID,
    } = this.props;

    const query = {
      account,
      fileCategory,
      toPeriod: company.currentWorkingPeriodEnd || 12,
      recordId: fileCategory === 'LINE' ? recordId : undefined,
      companyId: company.currentCompanySID,
      fromPeriod: company.currentWorkingPeriodStart || 1,
      periodType: company.currentPeriodType,
      periodYear: company.currentAccountingYear,
      transactionSID: fileCategory === 'LINE' ? transactionSID : undefined,
      showAll:
        fileCategory === 'PAYROLL' ||
        fileCategory === 'PAYROLL_SETTLEMENTS' ||
        fileCategory === 'BANK_STATEMENT' ||
        fileCategory === 'VAT' ||
        showAllFiles,
    };

    return queryString.stringify(query);
  };

  setFileName = (fileName) => {
    this.setState({ fileName });
  };

  createBankImportQuery = () => {
    const { account, company } = this.props;

    const query = {
      account,
      companyId: company.currentCompanySID,
    };

    return queryString.stringify(query);
  };

  getFiles = async (showAll) => {
    try {
      const response = await this.api.get(
        `/${getFileListApi}?${this.createQuery(showAll)}`,
      );
      if (response && response.transactionFileList) {
        this.setState({
          uploadedFiles: response.transactionFileList,
          sortedFiles: response.transactionFileList,
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  showAllFiles = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : false;
    this.getFiles(value);
  };

  uploadFiles = async (files) => {
    const { history, search, toRoute, fileCategory } = this.props;

    const formData = new FormData();
    try {
      this.setState({ isUploading: true });
      await Promise.all(
        files.map(async (_, index) => {
          this.changeApiProgressStatus(index);

          formData.append(
            'uploadingFiles',
            files[index],
            files[index].newName || files[index].name,
          );
        }),
      );
      if (fileCategory === 'BANK_STATEMENT') {
        const bankTransactionsData = await this.api.post(
          `/${uploadBankTransactionApi}?${this.createBankImportQuery()}`,
          formData,
        );

        this.setState({
          bankTransactionsData,
          alertProps: {
            show: true,
            title: t`Imported ${bankTransactionsData.rowsImport} rows.`,
            onConfirm: () => {
              history.push(toRoute(search));
              this.hideAlert();
            },
            showCloseButton: true,
          },
        });
      } else {
        await this.api.post(
          `/${multipleFileUploadApi}?${this.createQuery()}`,
          formData,
        );
      }

      const response = await this.api.get(
        `/${getFileListApi}?${this.createQuery()}`,
      );

      if (response && response.transactionFileList) {
        this.setState({
          uploadedFiles: response.transactionFileList,
          sortedFiles: response.transactionFileList,
          isUploading: false,
        });
      }
      if (typeof this.props.refreshParent === 'function') {
        this.props.refreshParent(
          !!_get(response, 'transactionFileList', []).length,
        );
      }
    } catch (e) {
      this.setState({
        isUploading: false,
      });
      toast.error(getErrorMessage(e));
    }
    return '';
  };

  handleDeleteFile = (index, fileKey) => {
    this.setState({
      alertProps: {
        show: true,
        type: 'warning',
        title: t`Warning`,
        onCancel: this.hideAlert,
        onConfirm: () => {
          this.hideAlert();
          this.removeFile(index, fileKey);
        },
        children: <Trans>Are you sure you want to delete this file?</Trans>,
        showCancel: true,
        cancelBtnText: t`Close`,
        confirmBtnText: t`Delete`,
        closeOnClickOutside: true,
      },
    });
  };

  removeFile = async (index, fileKey) => {
    const { uploadedFiles, sortedFiles } = this.state;

    this.changeApiProgressStatus(index);
    const { company } = this.props;

    try {
      await this.api.delete(`/${deleteFileApi}`, {
        companyId: company.currentCompanySID,
        transactionFileSID: index,
        uploadFileName: fileKey,
      });

      this.changeApiProgressStatus(index);
      const newFiles = uploadedFiles.filter(
        (item) => item.transactionFileSID !== index,
      );
      const newSortedFiles = sortedFiles.filter(
        (item) => item.transactionFileSID !== index,
      );
      this.setState({
        uploadedFiles: newFiles,
        sortedFiles: newSortedFiles,
      });

      if (typeof this.props.refreshParent === 'function') {
        this.props.refreshParent(!!newFiles.length);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  enableDisableNameChange = (index) => {
    const { sortedFiles } = this.state;

    const newFiles = sortedFiles.map((item) => {
      const newItem = item;
      if (newItem.transactionFileSID === index) {
        newItem.enableEdit = !newItem.enableEdit;
      }
      return newItem;
    });
    this.setState({
      sortedFiles: newFiles,
    });
  };

  changeFileName = async (event, index) => {
    const { sortedFiles, uploadedFiles } = this.state;
    const { company } = this.props;
    const { target } = event;
    const { value } = target;

    if (this.fileRenameTimeOut) {
      clearTimeout(this.fileRenameTimeOut);
    }
    this.fileRenameTimeOut = setTimeout(async () => {
      this.enableDisableNameChange(index);
      this.changeApiProgressStatus(index);
      await this.api.put(`/${renameFileApi}`, {
        companyId: company.currentCompanySID,
        transactionFileSID: index,
        fileName: value,
      });
      this.changeApiProgressStatus(index);

      const response = await this.api.get(
        `/${getFileListApi}?${this.createQuery()}`,
      );

      if (response && response.transactionFileList) {
        const changedItem = response.transactionFileList.filter(
          (file) => file.transactionFileSID === index,
        );
        const editedArray = uploadedFiles.map((item) =>
          item.transactionFileSID === index && changedItem && changedItem.length
            ? changedItem[0]
            : item,
        );

        const editedSortedArray = sortedFiles.map((item) =>
          item.transactionFileSID === index && changedItem && changedItem.length
            ? changedItem[0]
            : item,
        );

        this.setState({
          uploadedFiles: editedArray,
          sortedFiles: editedSortedArray,
        });
      }
    }, FILE_RENAME_TIMEOUT_TIMEOUT);
  };

  changeApiProgressStatus = (identifier) => {
    const { sortedFiles } = this.state;

    const newFiles = sortedFiles.map((item) => {
      const newItem = item;
      if (newItem.transactionFileSID === identifier) {
        newItem.showProgress = !newItem.showProgress;
      }
      return newItem;
    });
    this.setState({
      sortedFiles: newFiles,
    });
  };

  changeFilesOrder = async () => {
    const { sortedFiles } = this.state;

    this.setState({ changeOrderLoading: true });
    try {
      const removableKeys = ['showProgress', 'enableEdit', 'newName'];
      const newOrder = [...sortedFiles];

      removableKeys.forEach((key) => {
        if (newOrder.hasOwnProperty(key)) {
          delete newOrder[key];
        }
      });
      const response = await this.api.put(`/${putNewFilesOrder}`, {
        transactionOrderedList: newOrder,
      });

      if (response) {
        this.setState({
          uploadedFiles: newOrder,
          sortedFiles: newOrder,
        });

        toast.success(t`New files order was saved successfully`);
      }

      this.setState({ changeOrderLoading: false });
    } catch (error) {
      this.setState({ changeOrderLoading: false });
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  viewUploadedFiles = async (fileKey, fileName) => {
    const { company } = this.props;
    const query = {
      companyId: company.currentCompanySID,
      uploadFileName: fileKey,
    };
    const response = await this.api.get(
      `/${viewFileApi}?${queryString.stringify({ ...query })}`,
    );
    if (response) {
      const blob = b64toBlob(response.blobImage, response.mimeType);
      FileSaver.saveAs(blob, fileName);
    }
  };

  hideAlert = () => this.setState({ alertProps: { show: false } });

  previewFile = async (previewFile) => {
    const { company } = this.props;
    const query = {
      companyId: company.currentCompanySID,
      uploadFileName: previewFile.fileKey,
    };
    const response = await this.api.get(
      `/${viewFileApi}?${queryString.stringify({ ...query })}`,
    );

    this.setState(() => ({
      previewFile: {
        ...response,
        url: `${response.mimeType}${response.blobImage}`,
      },
    }));
  };

  setSortedFiles = (sortedFiles) => this.setState({ sortedFiles });

  closePreviewFile = () => this.setState(() => ({ previewFile: {} }));

  render() {
    const { roleType, fileCategory, modalTitle, toggleModal, showModal } =
      this.props;
    const {
      account,
      company,
      alertProps,
      previewFile,
      isUploading,
      uploadedFiles,
      sortedFiles,
      fileName,
      changeOrderLoading,
      bankTransactionsData,
    } = this.state;

    return (
      <>
        <AnimateAlert {...alertProps} />
        <FileOverview
          item={previewFile}
          open={!!Object.keys(previewFile).length}
          close={this.closePreviewFile}
          title={fileName}
        />
        <FileUploadModal
          {...{
            account,
            company,
            roleType,
            showModal,
            modalTitle,
            sortedFiles,
            isUploading,
            toggleModal,
            fileCategory,
            uploadedFiles,
            changeOrderLoading,
            bankTransactionsData,
          }}
          removeFile={this.handleDeleteFile}
          previewFile={this.previewFile}
          uploadFiles={this.uploadFiles}
          showAllFiles={this.showAllFiles}
          changeFileName={this.changeFileName}
          setSortedFiles={this.setSortedFiles}
          changeFilesOrder={this.changeFilesOrder}
          setFileName={this.setFileName}
          changeUploadedFilesOrder={this.changeUploadedFilesOrder}
          viewUploadedFiles={this.viewUploadedFiles}
          enableDisableNameChange={this.enableDisableNameChange}
          {...this.props}
        />
      </>
    );
  }
}

export default FileUploadModalContainer;
