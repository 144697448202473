import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import { Button, Heading, Spinner, ButtonIcon } from 'components';

function VacationPayRowsModal(props) {
  const {
    show,
    mainRow,
    zoneRow,
    options,
    zones,
    onSubmit,
    toggleModal,
    handleChange,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
  });

  return (
    <Modal show={show} onEscapeKeyDown={toggleModal}>
      <Form>
        <Modal.Header>
          <Heading level={3}>{t`Vacation Pay Settings`}</Heading>
        </Modal.Header>
        <Modal.Body>
          <InputHolder>
            <AutoSuggestInput
              clearButton
              id="select-main-row"
              size="lg"
              label={t`Update main percentage`}
              bsSize="lg"
              options={options}
              selected={mainRow || null}
              placeholder={t`Please select main percentage`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'main')
              }
            />
          </InputHolder>
          <InputHolder>
            <AutoSuggestInput
              clearButton
              id="select-main-zone"
              size="lg"
              label={t`Update main zone`}
              bsSize="lg"
              options={zones}
              selected={zoneRow || null}
              placeholder={t`Please select main zone`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'zone')
              }
            />
          </InputHolder>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={toggleModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default VacationPayRowsModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;
