import { t } from '@lingui/macro';

/**
 * Dashboard Groups Table Grid
 */

const getNumbers = (item, field) => {
  const percent = +(
    (item[`${field}ApprovedAccounts`] * 100) /
    (item[`${field}TotalAccounts`] || 1)
  ).toFixed(0);

  return `${percent}% ${item[`${field}ApprovedAccounts`]}/${
    item[`${field}TotalAccounts`]
  }`;
};

export const columns = ({ reportMode }) => [
  {
    width: 260,
    Header: t`Company name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: 100,
    Header: t`General`,
    accessor: 'general',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      converter: (_, item) => getNumbers(item, 'gl'),
    },
  },
  {
    width: 100,
    Header: t`Bank`,
    accessor: 'bank',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      converter: (_, item) => getNumbers(item, 'bank'),
    },
  },
  {
    width: 100,
    Header: t`Simple Ledger`,
    accessor: 'simple_ledger',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      converter: (_, item) => getNumbers(item, 'simpleLedger'),
    },
  },
  {
    width: 70,
    Header: t`VAT`,
    accessor: 'vatApproved',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'boolean',
    },
  },
  {
    width: 70,
    Header: t`Payroll`,
    accessor: 'payrollApproved',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'boolean',
    },
  },
];

export default { columns };
