import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { createPortal } from 'react-dom';

function AnimateAlert(props) {
  const { children, ...rest } = props;
  return createPortal(
    <div>
      <SweetAlert {...rest}>{children}</SweetAlert>
    </div>,
    document.getElementById('portal'),
  );
}

AnimateAlert.propTypes = {
  children: PropTypes.node,
};

AnimateAlert.defaultProps = {
  children: undefined,
};

export default AnimateAlert;
