import { t } from '@lingui/macro';

export const mapVatTransactionsFilter = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['transactionId', 'description', 'period'],
  },
];

export default mapVatTransactionsFilter;
