import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';

import { sortBooleanCustomTable, defineTemplates } from 'services/helpers';

export const columns = () => [
  {
    width: 105,
    Header: t`Priority`,
    accessor: 'priority',
    className: '',
    cell: {
      type: 'select',
      editable: true,
      disableClick: true,
      fieldOptions: {
        id: 'priority',
        single: true,
        autoHeight: true,
        small: true,
        placeholder: t`Please select Priority`,
        disableSort: true,
        autoFocus: true,
        options: [...Array(6).keys()].map((item) => ({
          id: item + 1,
          label: (item + 1).toString(),
        })),
      },
    },
  },
  {
    width: 210,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: true,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 220,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 150,
    Header: t`Template`,
    accessor: 'template',
    className: '',
    withTooltip: true,
    cell: {
      converter: defineTemplates,
      disableClick: true,
    },
  },
  {
    width: 180,
    Header: t`Filtered Accounts`,
    accessor: 'accounts',
    className: '',
    withTooltip: true,
    cell: {
      disableClick: true,
      converter: (item) =>
        Array.isArray(item) ? item.map((i) => i.id).join(', ') : '',
    },
  },
  {
    width: 100,
    Header: t`Activated`,
    sortType: sortBooleanCustomTable,
    accessor: 'activated',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  {
    width: 85,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'edit',
          icon: () => <GoPencil />,
          tooltip: t`Edit`,
          visible: (item) => !item.unavailable,
        },
        {
          id: 'delete',
          icon: () => <BiTrash />,
          tooltip: t`Detailed`,
        },
      ],
    },
  },
];

export default { columns };
