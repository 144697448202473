import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { BsFillCheckCircleFill } from '@react-icons/all-files/bs/BsFillCheckCircleFill'; // eslint-disable-line max-len

import { Button, ButtonIcon } from 'components';

import { fromCompany } from 'store/selectors';
import { companyInfoSuccess } from 'store/actions';
import { injectCompanyDataToURL } from 'services/helpers';
import { useOnClickOutside } from 'services/hooks';

const MIN_YEAR = 2020;
const MAX_YEAR = 2025;

const options = (() => {
  const years = [];

  for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
    years.push({
      eventKey: i,
    });
  }
  return years;
})();

function ChangeCompanyYearContainer(props) {
  const { location, history } = props;

  const [isOpen, setIsOpen] = useState({ customYear: true, dropdown: false });
  const [customYearValue, setCustomYearValue] = useState();

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();

  const company = useSelector((state) => fromCompany.getCompany(state));

  const isYearFromDefaultRange = useMemo(
    () =>
      +company.currentAccountingYear <= MAX_YEAR &&
      +company.currentAccountingYear >= MIN_YEAR,
    [company.currentAccountingYear],
  );

  const handleChange = async (currentAccountingYear) => {
    if (currentAccountingYear < 2100 && currentAccountingYear > 2000) {
      await dispatch(
        companyInfoSuccess({
          ...company,
          currentAccountingYear,
        }),
      );

      history.push(
        injectCompanyDataToURL(location, {
          id: company.uuid,
          accountingYear: currentAccountingYear,
          currentWorkingPeriodEnd: company.currentWorkingPeriodEnd,
        }),
      );

      setIsOpen({
        customYear:
          currentAccountingYear <= MAX_YEAR &&
          currentAccountingYear >= MIN_YEAR,
        dropdown: false,
      });

      setCustomYearValue('');
    } else {
      toast.error(t`Year must be in range from 2001 to 2099`);
    }
  };

  const handleChangeInput = (e) => setCustomYearValue(e.target.value);

  // Enter key to submit custom year
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleChange(customYearValue);
    }
  };
  const openCustomYearInput = () =>
    setIsOpen((prevState) => ({
      ...prevState,
      customYear: !prevState.customYear,
    }));

  const handleBlur = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      dropdown: false,
    }));
  };

  const closeDropdown = () =>
    setIsOpen((prevState) => ({
      customYear: true,
      dropdown: !prevState.dropdown,
    }));

  useEffect(() => {
    if (isOpen.dropdown && !isYearFromDefaultRange) {
      setCustomYearValue(company.currentAccountingYear);
    }
  }, [isOpen.dropdown]);
  useOnClickOutside(dropdownRef, handleBlur);

  return (
    <Container ref={dropdownRef}>
      <DropdownButton
        open={isOpen.dropdown}
        id="bg-vertical-dropdown-1"
        title={company?.currentAccountingYear || 0}
        bsSize="small"
        className="btn btn-fill btn-magnify"
        menuRole="menu"
        onClick={closeDropdown}
      >
        {options.map((item) => (
          <MenuItem
            key={item.eventKey}
            active={item.eventKey === +company.currentAccountingYear || false}
            eventKey={item.eventKey}
            onSelect={handleChange}
          >
            {item.eventKey}
          </MenuItem>
        ))}
        {isOpen.customYear && isYearFromDefaultRange ? (
          <ButtonHolder>
            <Button simple magnify kind="danger" onClick={openCustomYearInput}>
              <Trans>Custom Year</Trans>
            </Button>
          </ButtonHolder>
        ) : (
          <InputHolder>
            <InputField
              id="customYear"
              type="number"
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              placeholder="2001-2099"
              value={customYearValue || ''}
            />
            <Button
              size="xsmall"
              kind="success"
              fill
              onClick={() => handleChange(customYearValue)}
            >
              <ButtonIcon as={BsFillCheckCircleFill} />
            </Button>
          </InputHolder>
        )}
      </DropdownButton>
    </Container>
  );
}

const Container = styled.div`
  & .dropdown-menu {
    margin-top: 23px;
    right: 0 !important;
    left: auto !important;
  }
`;

const InputHolder = styled.div`
  display: flex;
  overflow: hidden;
  .btn {
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const InputField = styled.input`
  padding: 20px;
  width: 130px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonHolder = styled.div`
  margin: 5px 0 10px;
  .btn {
    display: block;
    background-color: #eb5e28;
    padding: 10px 10px !important;
    width: 90%;
    margin: 0 auto !important;
    color: #fff;
  }
`;

export default ChangeCompanyYearContainer;

ChangeCompanyYearContainer.propTypes = {
  roleType: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};
