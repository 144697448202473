import React from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, Row, Col } from 'react-bootstrap';

import {
  Field,
  Table,
  Button,
  Heading,
  Spinner,
  TableRow,
  TableCell,
  Paragraph,
  ButtonIcon,
  TableFilter,
  TablePagination,
} from 'components';

import { captionCreator } from 'services/helpers';

function MapTransactionIdModal(props) {
  const {
    data,
    header,
    company,
    search,
    toggleMapTransactionsIdModal,
    handlePageChange,
    handleChange,
    handleIconGroup,
    modalAction,
    setSelectedRows,
    selectedRows,
    handleFilterSubmit,
    handleFilterChange,
    status,
  } = props;

  if (isEmpty(data)) {
    return <Spinner />;
  }
  const modalActionText = modalAction
    ? t`Edit Mapped Transactions`
    : t`Map Transactions`;

  const selectedRowItems = new Map(selectedRows);
  const results = data.totalNoOfRecords;
  const mappedRow = [];

  selectedRowItems.forEach((value) => {
    mappedRow.push(value.lineSID);
  });

  const rows = data.glrecordList.map((row) => {
    const rowKind = mappedRow.includes(row.lineSID) ? 'success' : null;

    return (
      <TableRow key={`gLRecordList-${row.lineSID}`} kind={rowKind}>
        <TableCell>
          <Field
            type="checkbox"
            id={`${row.lineSID}-${row.recordId}`}
            name={row.lineSID}
            value={row.recordId}
            checked={mappedRow.includes(row.lineSID)}
            onChange={(e) => handleChange(e, row)}
            inputGridOffset={6}
          />
        </TableCell>
        <TableCell className="text-center">{row.accountId}</TableCell>
        <TableCell className="text-center">{row.period}</TableCell>
        <TableCell date className="text-right">
          {row.valueDate}
        </TableCell>
        <TableCell>{row.sourceDocumentId}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell number className="text-right">
          {row.amount}
        </TableCell>
      </TableRow>
    );
  });

  const footer = (
    <tfoot key="mapTransactionsToTransactionModal-footer">
      <TableRow>
        <TableCell colSpan="5" />
        <TableCell className="text-right">
          {parseInt(search.entries, 10) < results ? <Trans>Page </Trans> : ''}
          <Trans> Total: </Trans>
        </TableCell>
        <TableCell number className="text-right">
          {data.pageTotalAmount}
        </TableCell>
      </TableRow>

      {parseInt(search.entries, 10) < results ? (
        <TableRow>
          <TableCell colSpan="5" />
          <TableCell className="text-right">
            <Trans>Selection Total:</Trans>
          </TableCell>
          <TableCell number className="text-right">
            {data.totalAmount}
          </TableCell>
        </TableRow>
      ) : null}
    </tfoot>
  );

  return (
    <Modal
      show={toggleMapTransactionsIdModal}
      bsSize="lg"
      onEscapeKeyDown={() => handleIconGroup(6)}
    >
      <Modal.Header>
        <Heading level={3}>{modalActionText}</Heading>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Heading level={4}>
              <Trans>All transactions</Trans>
            </Heading>
            <Heading level={5}>
              <Trans>Selection: P0</Trans>
            </Heading>
            <span className="pull-right">
              <TableFilter
                inline
                search={search}
                pdfTitle={t`All transactions`}
                handleChange={handleFilterChange}
                handleSubmit={handleFilterSubmit}
              />
            </span>
            <StyledTableWrapper id="pdfToPrint">
              <Table
                responsive
                caption={captionCreator(company, data)}
                head={header}
                customFoot={footer}
              >
                {rows}
              </Table>
            </StyledTableWrapper>
            {status && (
              <Paragraph kind="success" alignment="center">
                Successful
              </Paragraph>
            )}
            <Button
              magnify
              kind="success"
              onClick={setSelectedRows}
              disabled={isEmpty(selectedRows)}
            >
              Map Transactions
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12}>
            <TablePagination
              results={results}
              entries={parseInt(search.entries, 10)}
              page={parseInt(search.page, 10)}
              onSelect={handlePageChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={() => handleIconGroup(6)}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MapTransactionIdModal.propTypes = {
  data: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  modalAction: PropTypes.bool,
  search: PropTypes.shape({}),
  handlePageChange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleFilterSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIconGroup: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  toggleMapTransactionsIdModal: PropTypes.bool,
  status: PropTypes.bool,
};
MapTransactionIdModal.defaultProps = {
  selectedRows: [],
  search: undefined,
  toggleMapTransactionsIdModal: false,
  status: false,
  modalAction: false,
};

export default MapTransactionIdModal;

const StyledTableWrapper = styled.div`
  width: 100%;
  overflow: overlay;
`;
