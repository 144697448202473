import { t } from '@lingui/macro';
import _isEmpty from 'lodash/isEmpty';

import { formatDate } from 'services/helpers';

const getMetadata = (values) => {
  const keys = {
    archivedDateTime: t`Archived Time:`,
    organizationNumber: t`Taxpayer ID:`,
    accountingSystem: t`Accounting System:`,
    dateInterval: t`Date Interval:`,
    accountingSystemReferenceId: t`VAT Return Number:`,
    type: t`VAT Return Category:`,
  };

  return Object.entries(keys)
    .map(([key, value]) => {
      if (key === 'dateInterval') {
        return {
          title: value,
          _value: values[key]?.from
            ? `${values[key]?.from} - ${values[key]?.to}`
            : null,
        };
      }

      if (key === 'archivedDateTime' && values[key]) {
        return {
          title: value,
          _value: formatDate(values[key], true),
        };
      }

      return {
        title: value,
        _value: values[key],
      };
    })
    .filter((item) => !!item._value);
};

export const vatReportFormatter = ({
  data,
  period,
  frequency,
  setUnmappedLines,
}) => {
  const metaData = [];
  const rows = [];
  let isReportAvailable = false;
  let unmappedLinesTemp = [];

  if (
    Array.isArray(data?.[frequency]?.data) &&
    !_isEmpty(data[frequency]?.data)
  ) {
    const currentReport = data[frequency].data.find(
      (item) => +item.term === +period,
    );

    if (!_isEmpty(currentReport)) {
      const {
        reportId,
        rows: vatRows,
        accumulatedRows,
        ...metadata
      } = currentReport;

      isReportAvailable = true;
      unmappedLinesTemp = metadata.unmappedLines;

      vatRows.forEach((row) => {
        const {
          id,
          vatCodeSaft,

          // Normal values
          glBaseAmount,
          glTaxAmount,
          vatReturnTaxAmount,
          vatReturnBaseAmount,

          // Reverse values
          glReverseTaxAmount,
          reverseTaxDeviation,
          vatReturnReverseTaxAmount,
        } = row;

        if (
          glTaxAmount ||
          glBaseAmount ||
          vatReturnTaxAmount ||
          vatReturnBaseAmount
        ) {
          rows.push({ ...row, reportId });
        }

        if (
          glReverseTaxAmount ||
          reverseTaxDeviation ||
          vatReturnReverseTaxAmount
        ) {
          rows.push({
            id,
            reportId,
            vatCodeSaft,
            vatReturnTaxAmount: vatReturnReverseTaxAmount,
            glTaxAmount: glReverseTaxAmount,
            taxDeviation: reverseTaxDeviation,
            reverseRow: true,
          });
        }
      });

      metaData.push(...getMetadata(metadata));
    }
  }

  setUnmappedLines(unmappedLinesTemp);
  return { metaData, rows, isReportAvailable };
};

export default vatReportFormatter;
