import React from 'react';

import Sanity from 'containers/Sanity';
import { BasicTemplate } from 'components';

/**
 * Sanity Page component
 */
function SanityPage(props) {
  return (
    <BasicTemplate noLogin pageType="sanity" {...props}>
      <Sanity {...props} />
    </BasicTemplate>
  );
}

export default SanityPage;
