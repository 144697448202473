import React from 'react';
import { ControlLabel } from 'react-bootstrap';

/**
 * Label component
 */
function Label({ ...props }) {
  return <ControlLabel {...props} />;
}

export default Label;
