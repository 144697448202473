import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkContainer } from 'react-router-bootstrap';
import { Button as BootstrapButton } from 'react-bootstrap';

/**
 * Button component
 */
function Button(props) {
  const {
    to,
    kind,
    size,
    wide,
    fill,
    icon,
    type,
    href,
    block,
    simple,
    rotate,
    magnify,
    disabled,
    moveLeft,
    moveRight,
    className,
    ...rest
  } = props;
  // Custom Bootstrap classes'
  const classes = classNames(
    'btn',
    {
      'btn-wd': wide,
      'btn-fill': fill,
      'btn-icon': icon,
      'btn-block': block,
      'btn-rotate': rotate,
      'btn-simple': simple,
      'btn-magnify': magnify,
      'btn-move-left': moveLeft,
      'btn-move-right': moveRight,
    },
    className,
  );
  if (to) {
    return (
      <LinkContainer
        to={to}
        style={{
          padding: simple ? '0px 10px' : undefined,
          margin: size === ('xs' || 'xsmall') ? '-5px 0' : undefined,
        }}
      >
        <BootstrapButton
          type={type}
          bsSize={size}
          bsStyle={kind}
          disabled={disabled}
          className={classes}
          {...rest}
        />
      </LinkContainer>
    );
  }
  return (
    <BootstrapButton
      type={type}
      href={href}
      style={{
        padding: simple ? '0px 10px' : undefined,
        margin: size === ('xs' || 'xsmall') ? '-5px 0' : undefined,
      }}
      bsSize={size}
      bsStyle={kind}
      className={classes}
      disabled={disabled}
      {...rest}
    />
  );
}

Button.propTypes = {
  to: PropTypes.string,
  kind: PropTypes.oneOf([
    'info',
    'link',
    'danger',
    'success',
    'warning',
    'default',
    'primary',
    'secondary',
    'primary-white',
    'primary-purple',
  ]),
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small', 'xs', 'xsmall']),
  wide: PropTypes.bool,
  fill: PropTypes.bool,
  icon: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  href: PropTypes.string,
  block: PropTypes.bool,
  simple: PropTypes.bool,
  rotate: PropTypes.bool,
  magnify: PropTypes.bool,
  disabled: PropTypes.bool,
  moveLeft: PropTypes.bool,
  moveRight: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  to: undefined,
  kind: 'default',
  size: undefined,
  wide: false,
  fill: false,
  icon: false,
  type: 'button',
  href: undefined,
  block: false,
  simple: false,
  rotate: false,
  magnify: false,
  disabled: false,
  moveLeft: false,
  moveRight: false,
  className: undefined,
};

export default Button;
