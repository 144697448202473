import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Row, Col, Modal as BootstrapModal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import {
  AvailableUsersPreviewColumns,
  AssociatedCompanyPreviewGroupsColumns,
} from 'services/tableshapes';

import {
  associatedCompanyGroupsFilters,
  availableUsersCompanyDetailedFilters,
} from 'services/tableFilterShapes';

import { DESKTOP, TABLET } from 'constants/mediaSizes';

function CompanyPreviewModal(props) {
  const { data, loading, closeModal, handleAction, search } = props;

  const content = (
    <>
      <Heading level={5}>
        <Trans>Name:</Trans> <strong>{data.companyName}</strong>
      </Heading>
      <Heading strong level={4} alignment="center">
        <Trans>Associated Company Groups</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: data.associatedCompanyGroups,
          loading,
          columns: AssociatedCompanyPreviewGroupsColumns(search),
          tableId: 'associatedCompanyGroupsCompanyModal',
          loadingId: 'groupId',
          headerConfigs: {
            exportFileName: `${
              data?.companyName
            } - ${t`Associated Company Groups`}`,
          },
          handleAction: (type, row) =>
            handleAction(type, row, 'editCompanyGroup'),
          withRowClick: true,
          filterConfigs: associatedCompanyGroupsFilters(),
          reportMode: search.reportMode,
          isModal: true,
        }}
      />

      <Heading strong level={4} alignment="center">
        <Trans>Available Users</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: data.availableUsers,
          loading,
          columns: AvailableUsersPreviewColumns(search),
          tableId: 'availableUsersCompanyModal',
          loadingId: 'uuid',
          headerConfigs: {
            exportFileName: `${data?.companyName} - ${t`Available Users`}`,
          },
          handleAction: (type, row) => handleAction(type, row, 'userPreview'),
          withRowClick: true,
          filterConfigs: availableUsersCompanyDetailedFilters(),
          reportMode: search.reportMode,
          isModal: true,
        }}
      />
    </>
  );

  return (
    <Modal show bsSize="md" onEscapeKeyDown={closeModal}>
      <BootstrapModal.Header>
        <Heading level={3}>
          <Trans>Company Detailed</Trans>
        </Heading>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <Row>
          <Col md={12}>{content}</Col>
        </Row>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button fill kind="danger" magnify onClick={closeModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}

export default CompanyPreviewModal;

CompanyPreviewModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  search: PropTypes.shape({}),
  loading: PropTypes.bool,
  closeModal: PropTypes.func,
  handleAction: PropTypes.func,
};

CompanyPreviewModal.defaultProps = {
  data: [],
  search: {},
  loading: true,
  closeModal: () => {},
  handleAction: () => {},
};

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
