import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, t } from '@lingui/macro';

import { Heading, CustomTableWithAutoSettings } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { calculateRowHeight } from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { payrollBalanceReconciliation } from 'services/tableshapes';

function BalanceReconciliation(props) {
  const { handleSSRowClick, dataSocialSecurity } = props;

  const { rows, loading } = dataSocialSecurity;
  const [actionSubmitting, setActionSubmitting] = useState({});
  const screenWidth = useCurrentWidth();
  const minHeight = useMemo(
    () => calculateRowHeight(screenWidth),
    [screenWidth],
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = (action) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowClick': {
          handleSSRowClick('ss');
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Balance reconciliation</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: rows,
          loading,
          minHeight,
          handleAction,
          hideFilters: true,
          actionSubmitting,
          withRowClick: true,
          columns: payrollBalanceReconciliation(),
          tableId: 'payrollBalanceReconciliation',
          headerConfigs: {
            exportFileName: t`Payroll Social Security - Balance Reconciliation`,
          },
        }}
      />
    </>
  );
}

export default BalanceReconciliation;
