import { t } from '@lingui/macro';

export const dashboardChartData = (
  screenSize,
  xAxisLabels,
  approvedData,
  controlledData,
  // approvedInSelectedFrequencyData,
) => {
  const defaults = {
    lineWidth: 3,
    lineDatalabelFontSize: 11,
    borderRadius: 5,
    lineDatalabelPaddings: {
      top: 3,
      bottom: 3,
      left: 6,
      right: 6,
    },
  };

  // FULL HD
  if (screenSize >= 1920 && screenSize < 2048) {
    defaults.lineDatalabelFontSize = 12;
    defaults.borderRadius = 6;
    defaults.lineDatalabelPaddings = {
      top: 4,
      bottom: 4,
      left: 8,
      right: 8,
    };

    // 2K
  } else if (screenSize >= 2048 && screenSize < 3840) {
    defaults.lineWidth = 4;
    defaults.borderRadius = 7;
    defaults.lineDatalabelFontSize = 13;
    defaults.lineDatalabelPaddings = {
      top: 4,
      bottom: 4,
      left: 8,
      right: 8,
    };

    // 4K
  } else if (screenSize >= 3840) {
    defaults.lineWidth = 6;
    defaults.borderRadius = 8;
    defaults.lineDatalabelFontSize = 20;
    defaults.lineDatalabelPaddings = {
      top: 7,
      bottom: 7,
      left: 13,
      right: 13,
    };
  }

  return {
    labels: xAxisLabels,
    datasets: [
      // {
      //   type: 'line',
      //   label: t`Approved at the selected frequency`,
      //   borderColor: '#2f3a4d',
      //   backgroundColor: 'white',
      //   borderWidth: defaults.lineWidth,
      //   fill: false,
      //   tension: 0.5,
      //   data: approvedInSelectedFrequencyData,
      //   datalabels: {
      //     backgroundColor: 'rgba(47,58,77,0.6)',
      //     padding: defaults.lineDatalabelPaddings,
      //     borderRadius: 5,
      //     color: 'white',
      //     offset: 5,
      //     font: {
      //       weight: 'bold',
      //       size: defaults.lineDatalabelFontSize,
      //     },
      //     formatter: (value) => `${value}%`,
      //     align: 'top',
      //     textShadowColor: 'rgb(79, 88, 112)',
      //     textShadowBlur: 3,
      //   },
      // },
      {
        type: 'bar',
        label: t`Approved accounts`,
        backgroundColor: '#34ba84',
        data: approvedData,
        hoverBackgroundColor: '#289066',
        borderRadius: defaults.borderRadius,
        borderSkipped: false,
      },
      {
        type: 'bar',
        label: t`Controlled accounts`,
        backgroundColor: '#008cef',
        hoverBackgroundColor: '#0061a3',
        data: controlledData,
        borderRadius: defaults.borderRadius,
        borderSkipped: false,
      },
    ],
  };
};

export default { dashboardChartData };
