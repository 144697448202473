import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import classNames from 'classnames';

/**
 * Card component
 */

function Card(props) {
  const {
    kind,
    plain,
    header,
    footer,
    center,
    children,
    handleStop,
    handleStart,
    headerCenter,
    footerCenter,
    headerBorder,
    footerBorder,
    dragabbleCard,
    backgroundColor,
    dragabbleCardType,
    ...rest
  } = props;
  // Component classes'
  const classes = classNames('card', {
    'card-plain': plain,
    'text-center': center,
    'card-chat': kind === 'chat',
    'card-lock': kind === 'lock',
    'card-login': kind === 'login',
    'card-circle-chart': kind === 'chart',
  });
  // Card Header classes
  const headerClasses = classNames('card-header', {
    'text-center': headerCenter,
  });
  // Component classes'
  const footerClasses = classNames('card-footer', {
    'text-center': footerCenter,
  });

  const card = (
    <div data-background-color={backgroundColor} className={classes} {...rest}>
      {header && (
        <Header headerBorder={headerBorder} className={headerClasses}>
          {header}
        </Header>
      )}
      <div className="card-content">{children}</div>
      {footer && (
        <Footer footerBorder={footerBorder} className={footerClasses}>
          {footer}
        </Footer>
      )}
    </div>
  );

  if (dragabbleCard) {
    return (
      <Draggable onStart={handleStart} onStop={handleStop}>
        <StyledDraggableWrapper
          dragabbleCardType={dragabbleCardType}
          data-testid="wrapper"
        >
          {card}
        </StyledDraggableWrapper>
      </Draggable>
    );
  }
  return card;
}

Card.propTypes = {
  backgroundColor: PropTypes.oneOf([
    'blue',
    'brown',
    'green',
    'purple',
    'orange',
  ]),
  kind: PropTypes.oneOf(['chart', 'login', 'lock', 'warning', 'register']),
  plain: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  center: PropTypes.bool,
  children: PropTypes.node,
  handleStop: PropTypes.func,
  handleStart: PropTypes.func,
  headerCenter: PropTypes.bool,
  footerCenter: PropTypes.bool,
  headerBorder: PropTypes.bool,
  footerBorder: PropTypes.bool,
  dragabbleCard: PropTypes.bool,
};

Card.defaultProps = {
  backgroundColor: undefined,
  kind: undefined,
  plain: false,
  header: undefined,
  footer: undefined,
  center: false,
  children: undefined,
  handleStop: undefined,
  handleStart: undefined,
  headerCenter: false,
  footerCenter: false,
  headerBorder: false,
  footerBorder: false,
  dragabbleCard: false,
};

export default Card;

const StyledDraggableWrapper = styled.div`
  z-index: 5000;
  position: fixed;
  box-shadow: rgb(204, 197, 180) 0 11px 43px;
  margin-bottom: 0;
  border-radius: 6px;
  height: ${(props) =>
    props.dragabbleCardType?.includes('ACCOUNT') ? '112px' : '190px'};
`;

const Header = styled.div`
  ${(props) =>
    props.headerBorder && `border-bottom: 2px solid rgba(182, 182, 182, 0.2);`}
`;

const Footer = styled.div`
  ${(props) =>
    props.footerBorder && `border-top: 2px solid rgba(182, 182, 182, 0.2);`}
`;
