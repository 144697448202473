import React, { useState } from 'react';
import styled from 'styled-components/macro';
// import toast from 'react-hot-toast';
import { ButtonGroup } from 'react-bootstrap';
import { BsBellFill } from '@react-icons/all-files/bs/BsBellFill';
import { useDispatch } from 'react-redux';

import { Spinner, Button, ReleaseNoteModal } from 'components';

import { resourceListReadSuccess } from 'store/resource/actions';
import { lastOpenReleaseDateApi } from 'services/apihelpers';
import { t } from '@lingui/macro';

function ReleaseNoteModalContainer(props) {
  const { loading, releaseNote } = props;
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const lastOpenedDate = localStorage.getItem('lastOpenedReleaseNotes');

  const showUnread =
    releaseNote?._updatedAt &&
    new Date(releaseNote._updatedAt) > new Date(lastOpenedDate);

  const toggleModal = async (state) => {
    setShowModal(state);

    try {
      if (state) {
        localStorage.setItem('lastOpenedReleaseNotes', releaseNote._updatedAt);
        await dispatch(
          resourceListReadSuccess(
            lastOpenReleaseDateApi,
            releaseNote._updatedAt,
          ),
        );
      }
    } catch (error) {
      // toast.error(
      //   error?.response?.headers?.get('Response-Message') || error?.message,
      // );
    }
  };

  return (
    <>
      <Container>
        {showUnread && <UnreadMessage />}
        <ButtonGroup bsSize="small">
          <Button
            fill
            kind="info"
            onClick={() => toggleModal(true)}
            disabled={loading}
            title={t`Release Notes`}
          >
            {loading ? <Spinner type="white" size="md" /> : <Bell />}
          </Button>
        </ButtonGroup>
      </Container>
      {showModal && (
        <ReleaseNoteModal
          showModal={showModal}
          releaseNote={releaseNote}
          toggleHide={() => toggleModal(false)}
        />
      )}
    </>
  );
}

export default ReleaseNoteModalContainer;

const Container = styled.div`
  position: relative;
`;

const UnreadMessage = styled.div`
  position: absolute;
  right: -2px;
  top: -5px;
  width: 10px;
  height: 10px;
  background-color: #d02b2b;
  border-radius: 50%;
  z-index: 100;
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const Bell = styled((props) => <BsBellFill {...props} />)`
  font-size: 14px;
  margin-bottom: -3px;
`;
