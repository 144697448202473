export const SUPPORTED_LANGUAGES = ['en', 'nb'];
export const DEFAULT_LANGUAGE = 'en';
export const PERIOD_TYPES_LENGTHS = {
  monthly: 12,
  yearly: 1,
  biMonthly: 6,
  tertiary: 3,
  quarterly: 4,
  halfYearly: 2,
};
