import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/**
 * Link component
 */
function Link(props) {
  const { to } = props;
  if (to) {
    return <NavLink {...props} />;
  }
  return <a {...props} />; // eslint-disable-line jsx-a11y/anchor-has-content
}

Link.propTypes = {
  to: PropTypes.string,
};

Link.defaultProps = {
  to: undefined,
};

export default Link;
