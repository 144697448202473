import React from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { BiHomeAlt } from '@react-icons/all-files/bi/BiHomeAlt';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { t, Trans } from '@lingui/macro';
import {
  Form,
  Modal,
  Tooltip,
  ButtonGroup,
  OverlayTrigger,
} from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import { Button, Heading, Paragraph, ButtonIcon } from 'components';

import { fromResource } from 'store/selectors';
import { getActiveCompanyByOrganizationApi } from 'services/apihelpers';

function ChangeCompanyModal(props) {
  const {
    showModal,
    companies,
    companyId,
    reference,
    toggleHide,
    handleSubmit,
    handleChange,
    changeCompany,
    currentCompanyId,
  } = props;
  const options = [];

  const visitedCompanies = useSelector(
    (state) =>
      fromResource.getList(state, `${getActiveCompanyByOrganizationApi}`)
        .visitedCompanies,
  );

  const disabled = companyId === currentCompanyId || companyId === 0;
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !disabled) {
      handleSubmit();
    }
  };

  if (!isEmpty(companies)) {
    Object.keys(companies).forEach((item) => {
      if (companies[item]?.recordStatus) {
        options.push({
          id: companies[item].uuid,
          label: `${companies[item].companyName || companies[item].name} - ${
            companies[item].registrationNumber
          }`,
        });
      }
    });
  }

  if (companyId && !options.find((item) => item.id === companyId)) {
    options.push({ id: companyId, label: '-' });
  }

  const currentCompanyName = options?.filter(
    (item) => item?.id === currentCompanyId,
  )?.[0]?.label;

  return (
    <BModal show={showModal} onEscapeKeyDown={toggleHide}>
      <Modal.Header>
        <Header level={3}>
          <Trans>Change Company</Trans>
          <p data-testid="current-company-name">{currentCompanyName}</p>
        </Header>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(visitedCompanies) && !!visitedCompanies.length && (
          <>
            <Paragraph>
              <Trans>Recently Visited Companies:</Trans>
            </Paragraph>
            <WrapperInfo>
              {visitedCompanies.map((item) => (
                <InfoBlock
                  key={item.uuid}
                  id={item.uuid}
                  onClick={changeCompany}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <LastVisitedTooltip id="tooltip-lv-companies">
                        {item.companyName} - {item.registrationNumber}
                      </LastVisitedTooltip>
                    }
                  >
                    <TooltipInside>
                      {item.companyName} - {item.registrationNumber}
                    </TooltipInside>
                  </OverlayTrigger>
                  <BtnWrapper>
                    <ButtonIcon as={FiExternalLink} />
                  </BtnWrapper>
                </InfoBlock>
              ))}
            </WrapperInfo>
          </>
        )}
        <Paragraph>
          <Trans>Select a company from the list</Trans>
        </Paragraph>
        <Form>
          {!!options.length && (
            <AutoSuggestInput
              autoFocus
              clearButton
              id="pick-company"
              bsSize="small"
              options={options}
              selected={companyId}
              reference={reference}
              onKeyDown={handleKeyDown}
              inputProps={{ 'data-testid': 'pick-company-input' }}
              emptyLabel={t`No matches found. Try to hit the button
              below to delete browser cache`}
              placeholder={t`Pick company from dropdown...`}
              handleAutoSuggestInputChange={handleChange}
            />
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsHolder>
          <ButtonGroup bsSize="small">
            <Button
              fill
              kind="info"
              type="submit"
              magnify
              disabled={disabled}
              onClick={handleSubmit}
            >
              <ButtonIcon as={BiHomeAlt} /> <Trans>Change Company</Trans>
            </Button>
            <Button fill kind="danger" magnify onClick={toggleHide}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </ButtonsHolder>
      </Modal.Footer>
    </BModal>
  );
}

ChangeCompanyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentCompanyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default ChangeCompanyModal;

const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const BtnWrapper = styled.div`
  margin-left: 5px;
  height: auto;
  display: flex;
  align-items: baseline;
  width: 10%;
  svg {
    font-size: 10px;
  }
`;

const WrapperInfo = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 15px;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 15px;
  font-size: 10px;
  padding: 5px 15px;
  &:hover {
    cursor: pointer;
    background-color: #75747a;
    color: #f5f5f5;
  }
  div:first-child {
    overflow-wrap: break-word;
    width: 90%;
  }
`;

const Header = styled((props) => <Heading {...props} />)`
  margin: 10px 10px 5px;

  p {
    margin: 2px 0 0 2px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }
`;

const BModal = styled((props) => <Modal {...props} />)`
  .modal-body,
  .modal-footer {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
  }
`;

const TooltipInside = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LastVisitedTooltip = styled(Tooltip)`
  font-size: 12px;
`;
