export const COMPANY_INFO_REQUEST = 'COMPANY_INFO_REQUEST';
export const COMPANY_INFO_SUCCESS = 'COMPANY_INFO_SUCCESS';
export const COMPANY_RELOAD_SUCCESS = 'COMPANY_RELOAD_SUCCESS';
export const COMPANY_INFO_FAILURE = 'COMPANY_INFO_FAILURE';
export const UPDATE_COMPANY_INFO_REQUEST = 'UPDATE_COMPANY_INFO_REQUEST';
export const DATA_UPDATE_TIME = 'DATA_UPDATE_TIME';

export const setDataUpdateTime = (payload) => ({
  payload,
  type: DATA_UPDATE_TIME,
});

export const companyInfoRequest = (history) => ({
  type: COMPANY_INFO_REQUEST,
  payload: {
    history,
  },
  meta: {
    resource: 'company',
    thunk: 'companyInfo',
  },
});

export const updateCompanyInfoFromUrl = (payload) => ({
  type: UPDATE_COMPANY_INFO_REQUEST,
  payload,
  meta: {
    resource: 'company',
    thunk: 'companyInfo',
  },
});

export const companyInfoSuccess = (company, thunk) => ({
  type: COMPANY_INFO_SUCCESS,
  payload: company,
  meta: {
    thunk,
  },
});

export const companyInfoFailure = (error, request, thunk) => ({
  type: COMPANY_INFO_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const companyReloadSuccess = (company, thunk) => ({
  type: COMPANY_RELOAD_SUCCESS,
  payload: company,
  meta: {
    thunk,
  },
});
