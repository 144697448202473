import React from 'react';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import {
  required,
  moreThanZero,
  minFloat,
  maxFloat,
} from 'services/validation';

const defaultValues = {
  vatCode: '',
  percentage: 0,
  reversePercentage: 0,
  description: '',
  accounts: '',
  reverseAccounts: '',
  saftCode: '',
  proRataPercentage: 100,
  proRataReversePercentage: 100,
  effectiveStartDate: '1900-01-01',
  effectiveStartDateIsInfinite: true,
  effectiveEndDate: '2100-01-01',
  effectiveEndDateIsInfinite: true,
  mode: 'add',
  hidden: false,
  reverseChargeCalculation: false,
};

function AddEditVATSettingsModal(props) {
  const { item, onSubmit, saftCodes, closeModal, historyItem } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
    values: currentFormValues,
  } = useForm({
    onSubmit: (values) => onSubmit(item, values),
    defaultValues,
  });

  switch (item.mode) {
    case 'add':
      defaultValues.mode = 'add';
      defaultValues.vatCode = '';
      defaultValues.percentage = 0;
      defaultValues.reversePercentage = 0;
      defaultValues.description = '';
      defaultValues.accounts = '';
      defaultValues.reverseAccounts = '';
      defaultValues.saftCode = '';
      defaultValues.proRataPercentage = 100;
      defaultValues.proRataReversePercentage = 100;
      defaultValues.effectiveStartDate = '1900-01-01';
      defaultValues.effectiveStartDateIsInfinite = true;
      defaultValues.effectiveEndDate = '2100-01-01';
      defaultValues.effectiveEndDateIsInfinite = true;
      defaultValues.hidden = false;
      defaultValues.reverseChargeCalculation = false;
      break;
    case 'map':
      defaultValues.mode = 'map';
      defaultValues.vatCode = item.code.vatCode;
      break;
    case 'edit':
      defaultValues.mode = 'edit';
      defaultValues.vatCode = item.originalObject.code;
      defaultValues.description = item.originalObject.description;
      defaultValues.percentage =
        item.history[historyItem].inTaxPercentage * 100;
      defaultValues.reversePercentage =
        item.history[historyItem].outTaxPercentage * 100;
      defaultValues.accounts = item.originalObject.inTaxAccount;
      defaultValues.reverseAccounts = item.originalObject.outTaxAccount;
      defaultValues.saftCode = item.originalObject.saftCode;
      defaultValues.proRataPercentage =
        item.history[historyItem].inTaxProRataPercentage * 100;
      defaultValues.proRataReversePercentage =
        item.history[historyItem].outTaxProRataPercentage * 100;
      defaultValues.effectiveStartDate =
        item.history[historyItem].effectiveStartDate;
      defaultValues.effectiveEndDate =
        item.history[historyItem].effectiveEndDate;
      defaultValues.effectiveStartDateIsInfinite =
        item.history[historyItem].effectiveStartDate === '1900-01-01';
      defaultValues.effectiveEndDateIsInfinite =
        item.history[historyItem].effectiveEndDate === '2100-01-01';
      defaultValues.hidden = item.originalObject.hidden;
      defaultValues.reverseChargeCalculation =
        item.originalObject.reverseChargeCalculation;
      break;
    case 'addRange':
      defaultValues.mode = 'addRange';
      defaultValues.vatCode = item.originalObject.code;
      defaultValues.description = item.originalObject.description;
      defaultValues.percentage = 0;
      defaultValues.reversePercentage = 0;
      defaultValues.accounts = item.originalObject.inTaxAccount;
      defaultValues.reverseAccounts = item.originalObject.outTaxAccount;
      defaultValues.saftCode = item.originalObject.saftCode;
      defaultValues.proRataPercentage = 100;
      defaultValues.proRataReversePercentage = 100;
      defaultValues.effectiveStartDate =
        item.history[historyItem].effectiveStartDate;
      defaultValues.effectiveStartDate = '1900-01-01';
      defaultValues.effectiveStartDateIsInfinite = true;
      defaultValues.effectiveEndDate = '2100-01-01';
      defaultValues.effectiveEndDateIsInfinite = true;
      defaultValues.hidden = item.originalObject.hidden;
      defaultValues.reverseChargeCalculation =
        item.originalObject.reverseChargeCalculation;
      break;
    default:
      defaultValues.mode = 'add';
      break;
  }

  function modalTitle() {
    switch (item.mode) {
      case 'add':
      case 'map':
        return t`Add Settings Item`;
      case 'edit':
        return t`Edit Settings Item`;
      case 'addRange':
        return t`Add Date Range Item`;
      default:
        return t`Add VAT Code`;
    }
  }

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>{modalTitle()}</Heading>
        </Modal.Header>
        <Modal.Body>
          <ReactFormField
            size="sm"
            type="text"
            name="vatCode"
            label={t`VAT Code`}
            disabled={
              item.mode === 'edit' ||
              item.mode === 'addRange' ||
              item.mode === 'map'
            }
            value={currentFormValues.vatCode || ''}
          />
          <ReactFormField
            size="sm"
            type="select"
            name="saftCode"
            label={t`SAF-T Code`}
            validate={[required]}
            disabled={item.mode === 'addRange'}
            placeholder={t`Select a SAF-T Code`}
          >
            <option key="selectOptionDefaultSAFTCode" value="" disabled>
              {t`Select a SAF-T Code`}
            </option>
            {saftCodes.map((saftItem) => (
              <option
                key={`vat-${saftItem.saftCode}`}
                value={saftItem.saftCode}
              >
                {saftItem.saftCode} - {saftItem.description}
              </option>
            ))}
          </ReactFormField>
          <ReactFormField
            noBorder
            size="small"
            type="text"
            name="description"
            label={t`Description`}
            disabled={item.mode === 'addRange'}
            placeholder={t`Enter a description`}
          />
          <ReactFormField
            noBorder
            size="small"
            type="number"
            name="percentage"
            label={t`Incoming percentage`}
            validate={[required, minFloat(-100), maxFloat(100)]}
            placeholder={t`Enter an incoming percentage`}
          />
          <ReactFormField
            noBorder
            size="small"
            type="number"
            name="reversePercentage"
            label={t`Outgoing percentage`}
            validate={[required, minFloat(-100), maxFloat(100)]}
            placeholder={t`Enter an outgoing percentage`}
          />
          <ReactFormField
            noBorder
            size="small"
            type="text"
            name="accounts"
            disabled={item.mode === 'addRange'}
            label={t`Account`}
            placeholder={t`Enter an account number`}
          />
          <ReactFormField
            noBorder
            size="small"
            type="text"
            name="reverseAccounts"
            disabled={item.mode === 'addRange'}
            label={t`Reverse Account`}
            placeholder={t`Enter a reverse account number`}
          />
          <div style={{ marginTop: 10 }}>
            <ReactFormField
              noBorder
              size="small"
              type="number"
              name="proRataPercentage"
              label={t`Pro Rata Percentage`}
              validate={[required, moreThanZero, minFloat(0), maxFloat(100)]}
              placeholder={t`Enter a pro rata percentage`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="number"
              name="proRataReversePercentage"
              label={t`Pro Rata Reverse Percentage`}
              validate={[required, moreThanZero, minFloat(0), maxFloat(100)]}
              placeholder={t`Enter a pro rata reverse percentage`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="reverseChargeCalculation"
              name="reverseChargeCalculation"
              label={t`Reverse Charge Calculation?`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="date"
              name="effectiveStartDate"
              id="effectiveStartDate"
              label={t`Effective Start Date`}
              isDisabled={JSON.parse(
                currentFormValues.effectiveStartDateIsInfinite,
              )}
              placeholder={t`Enter an effective start date`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="effectiveStartDateIsInfinite"
              name="effectiveStartDateIsInfinite"
              label={t`No Start Date (applies from the beginning)`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="date"
              name="effectiveEndDate"
              id="effectiveEndDate"
              isDisabled={JSON.parse(
                currentFormValues.effectiveEndDateIsInfinite,
              )}
              label={t`Effective End Date`}
              placeholder={t`Enter an effective end date`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="effectiveEndDateIsInfinite"
              name="effectiveEndDateIsInfinite"
              label={t`No End Date (applies indefinitely)`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="hidden"
              name="hidden"
              label={t`Hidden?`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} />{' '}
                  {item.vatCodeRuleSid !== 0 ? (
                    <Trans>Save</Trans>
                  ) : (
                    <Trans>Add</Trans>
                  )}
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditVATSettingsModal;
