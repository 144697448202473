import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { VATControlTable } from 'components';
import { toVATControlDrilldown } from '../services/routehelpers/index';

function VATControlTableContainer(props) {
  const {
    roleType,
    params,
    vatControlData,
    activePeriods,
    accumulated,
    handleFrequencyChange,
    handlePeriodChange,
    handleAccumulatedChange,
    periodType,
  } = props;
  const { accountingYear } = useParams();
  const { loading, rows } = vatControlData;
  const history = useHistory();

  const handleDrilldownClick = (_, action) => {
    history.push(
      toVATControlDrilldown(
        {
          activePeriods,
          frequency: periodType,
          accumulated,
          vatCode: action.code,
        },
        action.accountId,
      ),
    );
  };

  const handleAction = async (type, action) => {
    switch (type) {
      case 'toDrilldown': {
        handleDrilldownClick(type, action);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <VATControlTable
      year={accountingYear}
      {...{
        periodType,
        roleType,
        params,
        loading,
        vatControlData,
        activePeriods,
        accumulated,
        handleFrequencyChange,
        handlePeriodChange,
        handleAccumulatedChange,
        handleAction,
        tableData: rows,
      }}
    />
  );
}

export default VATControlTableContainer;
