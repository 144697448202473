import React, { createContext, useEffect, useMemo } from 'react';

export const ChristmasModeContext = createContext();

const isChristmasModeEnabled = () => {
  const now = new Date();
  const month = now.getMonth();
  const day = now.getDate();

  return month === 11 && day <= 28;
};

export function ChristmasModeProvider(props) {
  const [isEnabled] = React.useState(() => isChristmasModeEnabled());
  const [isOn, setIsOn] = React.useState(false);

  const getUnleashStatus = async () => {
    const savedValue = localStorage.getItem('christmas-mode');

    if (savedValue && savedValue !== 'false' && isEnabled) {
      setIsOn(true);
    }
  };

  const toggle = () => {
    setIsOn((value) => {
      localStorage.setItem('christmas-mode', !value);
      return !value;
    });
  };

  const value = useMemo(
    () => ({ isEnabled, isOn, toggle }),
    [isEnabled, isOn, toggle],
  );

  useEffect(() => {
    getUnleashStatus();
  }, []);

  return (
    <ChristmasModeContext.Provider value={value}>
      {props.children}
    </ChristmasModeContext.Provider>
  );
}
