import React from 'react';
import { Trans } from '@lingui/macro';

import { Heading, BasicTemplate } from 'components';

export function MaintenanceMode(props) {
  const { children } = props;
  const maintenanceModeEnabled = false;

  if (maintenanceModeEnabled) {
    return (
      <BasicTemplate noLogin {...props}>
        <Heading level={3} kind="muted" alignment="center">
          <Trans>Application is in a scheduled maintenance mode</Trans>
        </Heading>
      </BasicTemplate>
    );
  }

  return children;
}

export default MaintenanceMode;
