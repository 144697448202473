import React from 'react';
import { t } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings, ButtonIcon } from 'components';

import { reconciliationRulesColumns } from 'services/tableshapes';
import { reconciliationRulesFilters } from 'services/tableFilterShapes';

function ReconciliationRules(props) {
  const { data, title, handleAction, actionSubmitting, loading } = props;

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-VATSettings">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          columns: reconciliationRulesColumns(),
          tableId: 'reconciliationRulesTable',
          loadingId: 'id',
          reportMode: false,
          withRowClick: true,
          dynamicRowHeight: true,
          filterConfigs: reconciliationRulesFilters(),
          headerConfigs: {
            exportFileName: title,
            extraButtons: [
              {
                id: 'add',
                icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                title: t`Create new Rule Set`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                  disabled: loading,
                },
              },
            ],
          },
        }}
      />
    </Card>
  );
}

export default ReconciliationRules;
