import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { Row, Col } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';

import {
  Card,
  Link,
  Button,
  Heading,
  Spinner,
  Paragraph,
  ReactFormField,
} from 'components';

import {
  email,
  match,
  isMobile,
  required,
  minLength,
  maxLength,
  passwordPattern,
} from 'services/validation';

function RegistrationForm(props) {
  const { loading, onSubmit, submitted, countryList } = props;
  const {
    Form,
    meta: { canSubmit, isTouched },
  } = useForm({ onSubmit });

  const countryOptions = Object.keys(countryList).map((item) => (
    <option
      key={`countryList-${countryList[item].countrySID}`}
      value={countryList[item].countryCode}
    >
      {countryList[item].countryName}
    </option>
  ));

  const cardHeading = (
    <Heading level={3} className="card-title">
      <Trans>Register New Account</Trans>
    </Heading>
  );
  const cardFooter = (
    <div>
      {!submitted && (
        <Button
          fill
          wide
          type="submit"
          kind="primary-purple"
          disabled={loading || !canSubmit || !isTouched}
          tabIndex={0}
        >
          <Trans id="register.verb">Register</Trans>
        </Button>
      )}
      <SpinnerDiv>{loading && <Spinner type="normal" />}</SpinnerDiv>
      <div className="forgot">
        <Link to="/">
          <Trans>Back to login page</Trans>
        </Link>
      </div>
    </div>
  );

  return (
    <Form>
      <Card kind="login" header={cardHeading} footer={cardFooter} footerCenter>
        {!submitted && (
          <Row>
            <Col md={6}>
              <ReactFormField
                noBorder
                name="name"
                label={t`Name`}
                validate={[required, maxLength(200)]}
                placeholder={t`Enter your name`}
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                name="companyName"
                label={t`Company Name`}
                validate={[required, maxLength(200)]}
                placeholder={t`Enter the company name`}
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                name="registrationNumber"
                label={t`Registration Number`}
                validate={[required, minLength(9), maxLength(9)]}
                placeholder={t`Enter the registration number`}
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                type="select"
                name="countryCode"
                label={t`Country`}
                validate={[required]}
                placeholder={t`Select Country`}
                validatePristine={false}
              >
                <option value="" disabled>
                  {t`Please select country`}
                </option>
                {countryOptions}
              </ReactFormField>
            </Col>
            <Col md={6}>
              <ReactFormField
                noBorder
                name="username"
                type="email"
                label={t`Email`}
                validate={[email, required]}
                placeholder={t`Enter Email address`}
                autoComplete="off"
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                name="password"
                type="password"
                label={t`Password`}
                validate={[required, passwordPattern]}
                placeholder={t`Enter a password`}
                autoComplete="off"
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                name="retypePassword"
                type="password"
                label={t`Confirm Password`}
                validate={[required, minLength(8), match('password')]}
                placeholder={t`Re-enter your password`}
                autoComplete="off"
                validatePristine={false}
              />
              <ReactFormField
                noBorder
                type="text"
                name="contactNo"
                label="Phone Number"
                validate={[isMobile]}
                placeholder="Enter your phone number"
                validatePristine={false}
              />
            </Col>
          </Row>
        )}
        {submitted && (
          <Paragraph alignment="center" className="description">
            <Trans>We have sent you a verification email</Trans>
          </Paragraph>
        )}
      </Card>
    </Form>
  );
}

RegistrationForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
};

RegistrationForm.defaultProps = {
  loading: false,
  submitted: false,
  countryList: [],
};

export default RegistrationForm;

const SpinnerDiv = styled.div`
  height: 30px;
  padding-top: 10px;
`;
