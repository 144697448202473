import { t } from '@lingui/macro';

/**
 * Payroll Settings Payment Tax Columns
 */
export const columns = ({ reportMode, collections }) => [
  {
    width: 110,
    Header: t`ID`,
    accessor: 'payRollReportItemSID',
    className: 'flex-center',
  },
  {
    width: reportMode ? 480 : 730,
    Header: t`Description`,
    accessor: 'itemName',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 480 : 600,
    Header: t`Payroll Account`,
    accessor: 'accountID',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      converter: (_, item) => item.accountID.join(', '),
      editable: !reportMode,
      disableClick: true,
      fieldOptions: {
        id: 'accountID',
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Payroll Account`,
        ...collections.accountID,
      },
    },
  },
];

export default columns;
