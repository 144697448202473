import { t } from '@lingui/macro';

export const vatReportNewerFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'vatCodeSaft',
      'vatCodeDescription',
      'specification',
      'vatCodeAccountingSystem',
      'vatRate',
      'note',
    ],
  },
];

export default { vatReportNewerFilters };
