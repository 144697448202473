import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getVATControlDrilldownApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useVATControlDrilldownData = (props) => {
  const {
    accountingYear,
    companySid,
    activePeriods,
    accumulated,
    frequency,
    accountId,
    vatCode,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const rows = useSelector((state) =>
    fromResource.getList(state, getVATControlDrilldownApi),
  );

  const fetchRows = async () => {
    await dispatch(
      resourceListReadRequest(getVATControlDrilldownApi, {
        companySid,
        year: accountingYear,
        periodType: frequency,
        period: frequency === 'YEARLY' ? 12 : activePeriods,
        accumulated,
        accountId,
        vatCode,
      }),
    );
  };

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await fetchRows();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [companySid, accountingYear, accumulated, activePeriods, frequency]);

  return { rows, loading, fetchData };
};

export default useVATControlDrilldownData;
