import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap';

import { useCurrentWidth } from 'services/hooks';

function Tooltip(props) {
  const { grid, width, tooltip, children, className, additionalSpace } = props;

  const screenWidth = useCurrentWidth();

  const holder = useRef();
  const [extraWidth, setExtraWidth] = useState(additionalSpace);
  const [isEllipsis, setIsEllipsis] = useState(false);

  useEffect(() => {
    setExtraWidth(
      grid === 'sm' && screenWidth <= 1920
        ? additionalSpace
        : additionalSpace + 30,
    );
  }, [screenWidth, additionalSpace, grid]);

  useEffect(() => {
    // Set text-overflow: ellipsis if cell width smaller than text width
    setIsEllipsis(holder.current.scrollWidth + extraWidth > width);
  }, [width, holder.current?.scrollWidth, extraWidth]);

  if (isEllipsis) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <BTooltip
            id="tooltip"
            data-testid="tooltip"
            style={{
              wordBreak: 'break-all',
            }}
          >
            {tooltip}
          </BTooltip>
        }
      >
        <ValueHolder
          ref={holder}
          className={className}
          textOverflow={isEllipsis}
        >
          {children}
        </ValueHolder>
      </OverlayTrigger>
    );
  }

  return (
    <ValueHolder className={className} textOverflow={isEllipsis} ref={holder}>
      {children}
    </ValueHolder>
  );
}

Tooltip.propTypes = {
  // Cell width
  grid: PropTypes.string,
  width: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  // Width of icons and so on
  additionalSpace: PropTypes.number,
};

Tooltip.defaultProps = {
  grid: 'md',
  tooltip: undefined,
  children: undefined,
  className: '',
  additionalSpace: 0,
};

export default Tooltip;

const ValueHolder = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => props.textOverflow && `text-overflow: ellipsis;`};
`;
