import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { GroupUserDetailedTable } from 'components';

import UserPreviewModal from 'containers/UserPreviewModal';
import CompanyPreviewModal from 'containers/CompanyPreviewModal';
import MoveGroupUsersModal from 'containers/MoveGroupUsersModal';
import AddEditGroupUserModal from 'containers/AddEditGroupUserModal';
import AddEditGroupUsersModal from 'containers/AddEditGroupUsersModal';
import AddEditGroupCompanyModal from 'containers/AddEditGroupCompanyModal';

import { fromResource } from 'store/selectors';
import { toGroupUserDetailed } from 'services/routehelpers';
import {
  deleteGroupUsersApi,
  getGroupUserAssociatedApi,
  getGroupParentUserDetailsApi,
} from 'services/apihelpers';
import { resourceDeleteRequest, resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

function GroupUserDetailedContainer(props) {
  const { location, history, match } = props;
  const [actionSubmitting, setActionSubmitting] = useState({});

  const dispatch = useDispatch();
  const [modal, setModal] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const group = useSelector((state) =>
    fromResource.getList(state, getGroupParentUserDetailsApi),
  );
  const data = useSelector((state) =>
    fromResource.getList(state, getGroupUserAssociatedApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupUserAssociatedApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getGroupUserAssociatedApi, {
        groupId: match.params.id,
      }),
    );
    dispatch(
      resourceListReadRequest(getGroupParentUserDetailsApi, {
        groupId: match.params.id,
      }),
    );
  };
  const getSearch = () => {
    const search = location ? queryString.parse(location.search) : {};

    return {
      sortBy: search.sortBy || 'Asc',
      sortByField: search.sortByField || 'companyName',
      reportMode: search.reportMode || false,
    };
  };
  const search = getSearch();
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleDeleteGroup = async (row) => {
    try {
      await dispatch(
        resourceDeleteRequest(
          `${deleteGroupUsersApi}?${queryString.stringify({
            uuid: row.uuid,
            groupId: match.params.id,
          })}`,
        ),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    fetchData();
  };

  const handleAction = async (type, item, table) => {
    try {
      _setActionSubmitting(type, true);

      switch (type) {
        case 'add': {
          setModal('addEdit');
          break;
        }
        case 'userPreview': {
          setModal('userPreview');
          setSelectedRow(item);
          setSelectedUser(item);
          break;
        }
        case 'move': {
          setModal('move');
          setSelectedRow(item);
          break;
        }
        case 'delete': {
          await handleDeleteGroup(item);
          break;
        }
        case 'redirect': {
          history.push(toGroupUserDetailed({}, item));
          break;
        }
        case 'rowClick': {
          switch (table) {
            case 'companyPreview': {
              setModal('companyPreview');
              setSelectedRow(item);
              break;
            }
            case 'editUserGroup': {
              setModal('editUserGroup');
              setSelectedRow(item);
              break;
            }
            case 'editCompanyGroup': {
              setModal('editCompanyGroup');
              setSelectedRow(item);
              break;
            }
            case 'userPreview': {
              setModal('userPreview');
              setSelectedRow(item);
              setSelectedUser(item);
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(type, false);
    } catch (e) {
      _setActionSubmitting(type, false);
      toast.error(getErrorMessage(e));
    }
  };
  // eslint-disable-next-line consistent-return
  const closeModal = (closedModal) => {
    setModal('');
    setSelectedRow({});

    if (closedModal) {
      if (closedModal === 'userPreview') {
        return setSelectedUser(null);
      }
      if (selectedUser) {
        setModal('userPreview');
        setSelectedRow(selectedUser);
      }
    }
  };

  const renderModal = () => {
    let modalToRender = '';

    switch (modal) {
      case 'addEdit': {
        modalToRender = (
          <AddEditGroupUsersModal
            item={_isEmpty(selectedRow) ? group : selectedRow}
            refetch={fetchData}
            closeModal={closeModal}
          />
        );
        break;
      }
      case 'editUserGroup': {
        modalToRender = (
          <AddEditGroupUserModal
            item={selectedRow}
            refetch={fetchData}
            closeModal={() => closeModal('editUserGroup')}
          />
        );
        break;
      }
      case 'editCompanyGroup': {
        modalToRender = (
          <AddEditGroupCompanyModal
            item={selectedRow}
            refetch={fetchData}
            closeModal={() => closeModal('editCompanyGroup')}
          />
        );
        break;
      }
      case 'move': {
        modalToRender = (
          <MoveGroupUsersModal
            data={group}
            item={selectedRow}
            refetch={fetchData}
            closeModal={closeModal}
          />
        );
        break;
      }
      case 'userPreview': {
        modalToRender = (
          <UserPreviewModal
            id={selectedRow.uuid}
            search={search}
            closeModal={() => closeModal('userPreview')}
            handleAction={handleAction}
          />
        );
        break;
      }
      case 'companyPreview': {
        modalToRender = (
          <CompanyPreviewModal
            id={selectedRow.companyId}
            closeModal={() => closeModal('companyPreview')}
            handleAction={handleAction}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  useEffect(fetchData, [match.params.id]);

  return (
    <>
      {renderModal()}
      <GroupUserDetailedTable
        {...{
          data,
          group,
          search,
          loading,
          handleAction,
          actionSubmitting,
        }}
      />
    </>
  );
}

export default GroupUserDetailedContainer;
