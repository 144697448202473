import { t } from '@lingui/macro';

import { recordFilterList } from 'services/shapes';
import {
  sourceDocumentIdConverter,
  partialReconciliationSIDConverter,
} from 'services/tableshapes/GeneralTemplateGrid';

export const generalTemplateReconcileFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'year',
      'description',
      'reconciliationStatus',
      sourceDocumentIdConverter,
      partialReconciliationSIDConverter,
    ],
  },
  {
    id: 'reconciliationStatus',
    type: 'multiSelect',
    label: t`Status`,
    options: recordFilterList(),
    disableSort: true,
    placeholder: t`Please select Status`,
  },
];

export default generalTemplateReconcileFilters;
