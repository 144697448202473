import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs, PayrollSettingsPage } from 'components';

import BankSettings from 'containers/BankSettings';
import AccountsTable from 'containers/AccountsTable';
import VATSettingsNew from 'containers/VATSettingsNew';
import ReconciliationRules from 'containers/ReconciliationRules';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getFeatureUnleashApi } from 'services/apihelpers';
import { toReconciliationSettings } from 'services/routehelpers';

/**
 * Reconciliation Settings Page Component
 */

function ReconciliationSettingsPage(props) {
  const { location, handleTabSelect } = props;
  const dispatch = useDispatch();

  const rulesEnabled = useSelector(
    (state) =>
      fromResource.getList(
        state,
        `${getFeatureUnleashApi}/reconciliation-rules`,
      )?.status,
  );

  const { tabEntry = 'chart-of-accounts-settings' } = queryString.parse(
    location.search,
  );
  const onTabSelect = (key) =>
    handleTabSelect(key, (data) => toReconciliationSettings(data));

  const checkRulesEnabled = () => {
    dispatch(
      resourceListReadRequest(`${getFeatureUnleashApi}/reconciliation-rules`),
    );
  };

  useEffect(() => {
    checkRulesEnabled();
  }, []);

  const tabs = [
    {
      title: t`Chart of Accounts Settings`,
      eventKey: 'chart-of-accounts-settings',
      component: <AccountsTable {...props} />,
    },
    {
      title: t`Bank Settings`,
      eventKey: 'bank-settings',
      component: <BankSettings {...props} />,
    },
    {
      title: t`Payroll Settings`,
      eventKey: 'payroll-settings',
      component: <PayrollSettingsPage {...props} />,
    },
    {
      title: t`VAT Settings`,
      eventKey: 'vat-settings',
      component: <VATSettingsNew {...props} />,
    },
    ...(rulesEnabled
      ? [
          {
            title: t`Reconciliation Rules`,
            eventKey: 'reconciliation-rules',
            component: <ReconciliationRules {...props} />,
          },
        ]
      : []),
  ];

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default ReconciliationSettingsPage;
