import { t } from '@lingui/macro';

export const organizationAltinnPayrollFilter = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['companyName'],
  },
];

export default organizationAltinnPayrollFilter;
