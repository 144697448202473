import { t } from '@lingui/macro';
import { vatGroupTypeOptions } from '../shapes/index';

export const vatControlTableFilters = (tableData) => {
  if (!tableData.length) return [];
  const listOfCompanies = tableData.reduce((acc, curr) => {
    if (!acc.includes(curr.companyName)) {
      acc.push(curr.companyName);
    }
    return acc;
  }, []);
  const listOfGroupTypes = tableData.reduce((acc, curr) => {
    if (!acc.includes(curr.groupType)) {
      acc.push(curr.groupType);
    }
    return acc;
  }, []);
  const filteredOptions = () => {
    const allOptions = vatGroupTypeOptions();
    return allOptions.filter((option) =>
      listOfGroupTypes.includes(option.label),
    );
  };

  return [
    {
      id: 'search',
      size: 'sm',
      type: 'input',
      label: t`Search`,
      placeholder: t`Type to search`,
      fieldsToSearch: ['accountId', 'code'],
    },
    {
      id: 'groupType',
      type: 'multiSelect',
      label: t`Group Type`,
      options: filteredOptions(),
      disableSort: true,
      placeholder: t`Please select Group Type`,
    },
    ...(listOfCompanies.length > 1
      ? [
          {
            id: 'companyName',
            type: 'multiSelect',
            label: t`Company Name`,
            options: listOfCompanies.map((item) => ({
              label: item,
              value: item,
            })),
            disableSort: true,
            placeholder: t`Please select Company Name`,
          },
        ]
      : []),
  ];
};

export default { vatControlTableFilters };
