const getGLRowColor = (row) => {
  let bgColorClass = '';

  if (row.reconciliationStatus === 'RECONCILED') {
    bgColorClass = 'info';
  } else if (row.reconciliationStatus === 'PARTIAL_RECONCILED') {
    bgColorClass = 'blue';
  } else if (row.reconciliationStatus === 'APPROVED') {
    bgColorClass = 'warning';
  }

  return bgColorClass;
};

const getGLAccrualsRowColor = (row, accountingPeriod, accountingYear) => {
  let bgColorClass = '';
  const year = row.approvedIn ? +row.approvedIn.split(' - ')[0] : 0;
  const period = row.approvedIn ? +row.approvedIn.split(' - ')[1] : 0;

  const exclude =
    (year >= +accountingYear && period > +accountingPeriod) ||
    year > +accountingYear;

  if (!row.estimatedRemainingMonths) {
    bgColorClass = 'info';
  } else if (exclude) {
    bgColorClass = 'darkgray';
  }

  return bgColorClass;
};

const getBankRowColor = (row) => {
  let rowKind;

  if (row.grayLine) {
    rowKind = 'darkgray';
  } else if (row.reconciled) {
    rowKind = 'info';
  }

  return rowKind;
};

export { getGLRowColor, getBankRowColor, getGLAccrualsRowColor };
