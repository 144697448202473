import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { IoIosSend } from '@react-icons/all-files/io/IoIosSend';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { BsFillInfoCircleFill } from '@react-icons/all-files/bs/BsFillInfoCircleFill'; // eslint-disable-line max-len

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { required } from 'services/validation';

function ConfigureMFAModal(props) {
  const { type, step, user, item, options, onSubmit, closeModal, resendCode } =
    props;

  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });
  // eslint-disable-next-line max-len
  const mfaToolTip = t`By enabling multi factor authorization your account will be more secure.`;

  const renderStep = () => {
    let content;

    switch (step) {
      case 0: {
        content = (
          <>
            <Modal.Header>
              <Heading level={3}>
                <Trans>Authenticate</Trans>
              </Heading>
            </Modal.Header>
            <Modal.Body>
              <Heading
                level={5}
                alignment="center"
                kind={!user.contactNo && 'danger'}
              >
                {user.contactNo ? (
                  <Trans>Pin will be sent to this number</Trans>
                ) : (
                  <Trans>Please add a phone number to your profile first</Trans>
                )}
              </Heading>
              <Heading level={4} alignment="center">
                {user.contactNo}
              </Heading>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup bsSize="small">
                {user.contactNo && (
                  <Button
                    fill
                    magnify
                    kind="success"
                    type="submit"
                    disabled={!canSubmit}
                  >
                    {isSubmitting ? (
                      <Spinner type="white" size="md" />
                    ) : (
                      <>
                        <ButtonIcon as={IoIosSend} /> <Trans>Send</Trans>
                      </>
                    )}
                  </Button>
                )}
                <Button fill kind="danger" magnify onClick={closeModal}>
                  <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </>
        );
        break;
      }
      case 1: {
        content = (
          <>
            <Modal.Header>
              <Heading level={3}>
                <Trans>Pin validation</Trans>
              </Heading>
            </Modal.Header>
            <Modal.Body>
              <InputHolder>
                <ReactFormField
                  noBorder
                  feedback
                  autoFocus
                  size="sm"
                  name="token"
                  label={t`Pin`}
                  pattern="[0-9]*"
                  validate={[required]}
                  inputMode="numeric"
                  autoComplete="one-time-code"
                />
              </InputHolder>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup bsSize="small">
                <Button fill magnify kind="info" onClick={resendCode}>
                  <ButtonIcon as={IoIosSend} /> <Trans>Resend</Trans>
                </Button>
                <Button
                  fill
                  magnify
                  kind="success"
                  type="submit"
                  disabled={!canSubmit}
                >
                  {isSubmitting ? (
                    <Spinner type="white" size="md" />
                  ) : (
                    <>
                      <ButtonIcon as={HiCheck} /> <Trans>Validate</Trans>
                    </>
                  )}
                </Button>
                <Button fill kind="danger" magnify onClick={closeModal}>
                  <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </>
        );
        break;
      }
      case 2: {
        content = (
          <>
            <Modal.Header>
              <Heading level={3}>
                <Trans>Configure multi factor authentication</Trans>
              </Heading>
            </Modal.Header>
            <Modal.Body>
              <InputHolder>
                <ReactFormField
                  noBorder
                  feedback
                  clearButton
                  size="sm"
                  name="frequency"
                  type="autoSuggest"
                  label={t`Select MFA frequency`}
                  options={options}
                  disabled={
                    type !== 'organization' &&
                    !!user?.organization?.frequency &&
                    !!user?.organization?.mfa
                  }
                  validate={[required]}
                />
              </InputHolder>
              <CheckBoxHolder>
                <ReactFormField
                  noBorder
                  id="mfa"
                  type="toggle"
                  size="small"
                  name="mfa"
                  label={t`Multi factor authorization`}
                  disabled={
                    type !== 'organization' && !!user?.organization?.mfa
                  }
                />
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="tooltip">{mfaToolTip}</Tooltip>}
                >
                  <div style={{ marginTop: -8 }}>
                    <ButtonIcon
                      as={BsFillInfoCircleFill}
                      marginBottom={0}
                      marginLeft={5}
                    />
                  </div>
                </OverlayTrigger>
              </CheckBoxHolder>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup bsSize="small">
                <Button
                  fill
                  magnify
                  kind="success"
                  type="submit"
                  disabled={!canSubmit}
                >
                  {isSubmitting ? (
                    <Spinner type="white" size="md" />
                  ) : (
                    <>
                      <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                    </>
                  )}
                </Button>
                <Button fill kind="danger" magnify onClick={closeModal}>
                  <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </>
        );
        break;
      }
      default: {
        break;
      }
    }

    return content;
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>{renderStep()}</Form>
    </Modal>
  );
}

export default ConfigureMFAModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;

const CheckBoxHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  && > i {
    margin-left: 5px;
    padding-bottom: 15px;
  }
`;
