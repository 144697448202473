import React from 'react';
import { t } from '@lingui/macro';
import { Button } from 'components';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { RiShareForward2Fill } from '@react-icons/all-files/ri/RiShareForward2Fill'; // eslint-disable-line max-len

/**
 * Group User Detailed Grid
 */
export const columns = ({ reportMode }, handleAction) => [
  {
    width: reportMode ? 150 : 410,
    Header: t`User Name`,
    accessor: 'userName',
    className: 'flex-left',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 150 : 410,
    Header: t`Associated Groups`,
    accessor: 'associativeGroups',
    disableSortBy: true,
    className: '',
    withTooltip: !reportMode,
    cell: {
      converter: (_, item, __, forExport) =>
        forExport
          ? item?.associativeGroups?.map((group) => group.name).join(', ')
          : item?.associativeGroups?.map((group) => (
              <React.Fragment key={group.groupId}>
                {group.name}
                <Button
                  key={group.groupId}
                  simple
                  magnify
                  kind="default"
                  onClick={() => handleAction('redirect', group.groupId)}
                >
                  <FiExternalLink />
                </Button>
                &nbsp;
              </React.Fragment>
            )),
    },
  },
  {
    width: 110,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'userPreview',
          icon: () => <FaRegEye />,
          tooltip: t`Detailed`,
        },
        {
          id: 'move',
          icon: () => <RiShareForward2Fill />,
          tooltip: t`Move this user`,
        },
        {
          id: 'delete',
          icon: () => <BiTrash />,
          tooltip: t`Delete`,
        },
      ],
    },
  },
];

export default { columns };
