import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import { fromCompany, fromResource } from 'store/selectors';
import { resourceListReadRequest, resourceCreateRequest } from 'store/actions';

import {
  isOrgVismaNXTEnabled,
  getAvailableVismaNXTCompanies,
  getGroupCompanyListApi,
  getIsCompanyGroupRequiredApi,
  getCountryListApi,
  getUsersApi,
  createVismaNXTCompany,
} from 'services/apihelpers';
import { AdminNXTCompanySettingsModal } from 'components';
import toast from 'react-hot-toast';

function AdminCompanyAddNXTContainer(props) {
  const defaultValues = {
    companyname: '',
    registrationNumber: '',
    isMainCompany: 'false',
    vatEnabled: 'false',
    payrollEnabled: 'false',
    assetsEnabled: 'false',
    periodtypes: 'monthly',
    country: 'NO',
    deviatingFiscalYear: 'false',
    startDate: `${new Date().getFullYear()}-01-01`,
    endDate: `${new Date().getFullYear()}-12-31`,
    vismaNetCompanyId: '',
  };

  const company = useSelector((state) => fromCompany.getCompany(state));
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);

  const isOrganizationNXTEnabledLoading = useSelector((state) =>
    pending(state, isOrgVismaNXTEnabled),
  );

  const isOrganizationNXTEnabled = useSelector((state) =>
    fromResource.getList(state, isOrgVismaNXTEnabled),
  ).has_vismanxt_companyid;

  const vismaNXTCompanyListLoading = useSelector((state) =>
    pending(state, `${getAvailableVismaNXTCompanies}ListRead`),
  );

  const getUsersLoading = useSelector((state) =>
    pending(state, `${getUsersApi}ListRead`),
  );
  const getCountriesLoading = useSelector((state) =>
    pending(state, `${getCountryListApi}ListRead`),
  );
  const getGroupsLoading = useSelector((state) =>
    pending(state, `${getGroupCompanyListApi}ListRead`),
  );
  const isGroupRequiredLoading = useSelector((state) =>
    pending(state, `${getIsCompanyGroupRequiredApi}ListRead`),
  );

  const countries = useSelector((state) =>
    fromResource.getList(state, getCountryListApi),
  );

  const userListByCompany = useSelector((state) =>
    fromResource.getList(state, getUsersApi),
  );

  const companyGroupsList = useSelector((state) =>
    fromResource.getList(state, getGroupCompanyListApi),
  );

  const isCompanyGroupRequired = useSelector((state) =>
    fromResource.getList(state, getIsCompanyGroupRequiredApi),
  );

  const loading =
    isOrganizationNXTEnabledLoading ||
    vismaNXTCompanyListLoading ||
    getUsersLoading ||
    getCountriesLoading ||
    getGroupsLoading ||
    isGroupRequiredLoading;

  const fetchCompanies = async () => {
    const request = await dispatch(
      resourceListReadRequest(getAvailableVismaNXTCompanies, {
        organizationId: company.organizationId,
      }),
    );
    setCompanies(request.filter((c) => !c.isVismaNXT));
  };

  const fetchStatus = async () => {
    await dispatch(
      resourceListReadRequest(isOrgVismaNXTEnabled, {
        organizationId: company.organizationId,
      }),
    );
  };

  const fetchData = async () => {
    try {
      dispatch(resourceListReadRequest(getCountryListApi));
      dispatch(
        resourceListReadRequest(getUsersApi, {
          organizationId: company.organizationId,
        }),
      );
      dispatch(resourceListReadRequest(getGroupCompanyListApi));
      dispatch(resourceListReadRequest(getIsCompanyGroupRequiredApi));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const onSubmit = async (payload) => {
    const query = {
      country_code: payload.country,
      is_default: JSON.parse(payload.isMainCompany),
      name: payload.companyname,
      period_type: payload.periodtypes,
      registration_numbers: payload.registrationNumber,
      organization_sid: company.organizationId,
      user_uuid_account_holder: payload.user,
      user_uuid_approver: payload.user,
      vat_enabled: JSON.parse(payload.vatEnabled),
      payroll_enabled: JSON.parse(payload.payrollEnabled),
      assets_enabled: JSON.parse(payload.assetsEnabled),
      company_groups: payload.companyGroupIds || [],
      vismaNetCompanyId: payload.vismaNetCompanyId,
    };
    if (JSON.parse(payload.deviatingFiscalYear)) {
      query.fiscal_year_start = payload.startDate;
      query.fiscal_year_end = payload.endDate;
    } else {
      query.fiscal_year_start = defaultValues.startDate;
      query.fiscal_year_end = defaultValues.endDate;
    }
    try {
      await dispatch(resourceCreateRequest(createVismaNXTCompany, query));
      toast.success('Company added successfully');
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      props.closeModal();
      props.refetchData();
    }
  };

  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchCompanies();
  }, []);

  return (
    <AdminNXTCompanySettingsModal
      {...props}
      companies={companies}
      loading={loading}
      {...{
        isOrganizationNXTEnabled,
        countries,
        userListByCompany,
        companyGroupsList,
        isCompanyGroupRequired,
        defaultValues,
        onSubmit,
      }}
    />
  );
}

export default AdminCompanyAddNXTContainer;
