import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { Row, Col, Form } from 'react-bootstrap';

import {
  Card,
  Field,
  Paragraph,
  PeriodFilterButtons,
  OrganizationAltinnSetup,
} from 'components';
import AltinnConfigModal from 'containers/AltinnConfigModal';

import { toOrganisationSettings } from 'services/routehelpers';
import { resourceListReadRequest } from 'store/actions';
import { periodSelectorValueInitialize } from 'services/helpers';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import { getPayrollAltinnDataByOrganizationApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import toast from 'react-hot-toast';

export function OrganizationAltinnSetupContainer(props) {
  const { history } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => fromAuth.getUser(state));
  const data = useSelector((state) =>
    fromResource.getList(state, getPayrollAltinnDataByOrganizationApi),
  );
  const company = useSelector((state) => fromCompany.getCompany(state));
  const loading = useSelector((state) =>
    pending(state, `${getPayrollAltinnDataByOrganizationApi}ListRead`),
  );

  const [singlePeriod, setSinglePeriod] = useState(false);
  const [toggleWindow, setToggleWindow] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedCompanyIdRows, setSelectedCompanyIdRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mode, _] = useState('organization');
  const [toPeriod, setToPeriod] = useState(company.currentWorkingPeriodEnd);
  const [fromPeriod, setFromPeriod] = useState(
    company.currentWorkingPeriodStart,
  );
  const [periods, setPeriods] = useState(
    Array.from(Array(company.currentWorkingPeriodEnd).keys()),
  );
  const [subTabEntry, setSubTabEntry] = useState('payroll');
  const [periodType, setPeriodType] = useState(company.currentPeriodType);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getPayrollAltinnDataByOrganizationApi, {
        year: company.currentAccountingYear,
        organizationSid: user.organizationSID,
      }),
    );
  };

  const search = useMemo(() => {
    const { location } = props;
    const s = queryString.parse(location.search, { parseNumbers: true });

    return {
      tabEntry: s.tabEntry || 2,
      subTabEntry: s.subTabEntry || 'payroll',
    };
  }, [JSON.stringify(props.location)]);

  const selectCondition = (item, isSelectAll) => {
    const altinnSettingsValidation = !item.hasOrganizationAltinnSettings;

    if (altinnSettingsValidation && !isSelectAll) {
      toast.error(
        t`You need to add an Altinn integration for this organization`,
      );
    }

    return !altinnSettingsValidation;
  };

  const signIn = async (id) => {
    if (id) {
      setToggleWindow((toggle) => !toggle);
      setSelectedCompanyIdRows([]);
      fetchData();
    } else {
      setToggleWindow(true);
    }
  };

  const handleChange = (event, _periodType) => {
    const { target } = event;

    const value =
      target.type === 'checkbox'
        ? target.checked
        : parseInt(target.getAttribute('id'), 10);
    const { name } = target;

    const workingPeriodEnd = periodSelectorValueInitialize(_periodType, value);

    if (name === 'singlePeriod') {
      setSinglePeriod(value);
      setPeriods(
        value
          ? Array.from(Array(toPeriod).keys())
          : Array.from(Array(toPeriod).keys()),
      );
      setFromPeriod(value ? toPeriod : 1);
    } else {
      setPeriods(
        singlePeriod
          ? Array.from(Array(workingPeriodEnd).keys())
          : Array.from(Array(workingPeriodEnd).keys()),
      );
      setToPeriod(workingPeriodEnd);
      setFromPeriod(singlePeriod ? workingPeriodEnd : 1);
    }
  };

  const selectRowsFilter = (selectedCompanyIdRowItems, item) => {
    if (selectedCompanyIdRowItems.has(item)) {
      selectedCompanyIdRowItems.delete(item);
    } else {
      selectedCompanyIdRowItems.set(item, {
        companyId: item,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const updateCompanyAltinnHandleChange = (event) => {
    if (event?.item === 'all') {
      return setSelectedCompanyIdRows([]);
    }

    const target = event?.target;

    if (target?.name && target?.name === 'fetchAlreadyFetchA07') {
      setForceUpdate((force) => !force);
    } else {
      let selectedCompanyIdRowItems;
      if (Array.isArray(event.item)) {
        selectedCompanyIdRowItems = new Map();
        event.item.forEach((item) => {
          selectedCompanyIdRowItems.set(item.companySID, {
            companyId: item.companySID,
          });
        });
      } else {
        selectedCompanyIdRowItems = new Map(selectedCompanyIdRows);
        selectRowsFilter(selectedCompanyIdRowItems, event.item.companySID);
      }
      setSelectedCompanyIdRows(Array.from(selectedCompanyIdRowItems));
    }
  };

  const handleTabSelect = (key) => {
    history.push(
      toOrganisationSettings({
        ...search,
        subTabEntry: key,
      }),
    );
    setSubTabEntry(key);
  };

  const handleAction = async (action, value) => {
    try {
      _setActionSubmitting(action, true);
      switch (action) {
        case 'update': {
          await signIn();
          break;
        }
        case 'rowSelect': {
          updateCompanyAltinnHandleChange(value);
          break;
        }
        case 'tabChange': {
          handleTabSelect(value);
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    setSubTabEntry(search.subTabEntry);
  }, []);

  useEffect(() => {
    fetchData();
  }, [company.currentAccountingYear, user.organizationSID]);

  useEffect(() => {
    setPeriodType(
      subTabEntry === 'payroll' ? company.currentPeriodType : 'biMonthly',
    );
    if (subTabEntry === 'vat') {
      setToPeriod(Math.round(toPeriod / 2));
    }
  }, [subTabEntry]);

  return (
    <Card>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={10}>
              <Form>
                <Paragraph>
                  <Trans>
                    {' '}
                    Select periods range you want to update. Current range (
                    {fromPeriod}-{toPeriod})
                  </Trans>
                </Paragraph>
                <Paragraph>
                  <PeriodFilterButtons
                    size="sm"
                    periodType={periodType}
                    singlePeriod={singlePeriod}
                    activePeriods={periods}
                    handleFilterChange={handleChange}
                  />
                </Paragraph>
                <Field
                  inline
                  horizontal
                  id="singlePeriod"
                  type="checkbox"
                  name="singlePeriod"
                  label={t`Update single period`}
                  defaultChecked={singlePeriod}
                  onChange={(e) => handleChange(e, company.currentPeriodType)}
                />
                {subTabEntry === 'payroll' && (
                  <Field
                    inline
                    horizontal
                    id="fetchAlreadyFetchA07"
                    name="fetchAlreadyFetchA07"
                    type="checkbox"
                    label={t`Fetch Already Fetched A07`}
                    onChange={updateCompanyAltinnHandleChange}
                    defaultChecked={forceUpdate}
                  />
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {toggleWindow && (
        <AltinnConfigModal
          {...{
            mode,
            periods,
            toPeriod,
            subTabEntry,
            toggleWindow,
            singlePeriod,
            selectedCompanyIdRows,
            altinnSignIn: signIn,
          }}
        />
      )}
      <OrganizationAltinnSetup
        {...{
          data,
          loading,
          subTabEntry,
          search,
          handleAction,
          actionSubmitting,
          selectCondition,
        }}
      />
    </Card>
  );
}

export default OrganizationAltinnSetupContainer;

OrganizationAltinnSetupContainer.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  company: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    entries: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
};
