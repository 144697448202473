import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Sidebar } from 'components';

import { trackEvent } from 'components/Analytics';
import { toGeneralSettings } from 'services/routehelpers';
import { getFeatureUnleashApi } from 'services/apihelpers';
import { fromAuth, fromCompany, fromRoot } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { useOidc } from '@axa-fr/react-oidc';

function SidebarContainer(props) {
  const {
    user,
    roleType,
    company,
    sidebarToggle,
    sidebarProgress,
    checkFeatureUnleashed,
  } = props;
  const { logout } = useOidc();

  useEffect(() => {
    checkFeatureUnleashed({
      toggleName: 'mfa',
    });
    checkFeatureUnleashed({
      toggleName: 'validation-warning',
    });
  }, []);

  const [sidebarNavExpanded] = useState(true);
  const [sidebarUserExpanded] = useState(true);

  const handleOnLogout = () => {
    trackEvent({
      category: 'User',
      action: `User has logged out`,
    });
    logout();
  };

  return (
    <Sidebar
      handleSelect={props.handleSelect}
      handleBadgeOnClick={props.handleBadgeOnClick}
      sidebarNavExpanded={sidebarNavExpanded}
      sidebarUserExpanded={sidebarUserExpanded}
      {...{
        user,
        company,
        roleType,
        sidebarToggle,
        sidebarProgress,
        to: toGeneralSettings({ tabEntry: 2 }),
        handleLogout: handleOnLogout,
      }}
    />
  );
}

SidebarContainer.propTypes = {
  /** @type {shape} user */
  user: PropTypes.shape({ roleNames: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  /** @type {shape} roleType */
  roleType: PropTypes.shape({}).isRequired,
  /** @type {shape} company */
  company: PropTypes.shape({
    currentCompanySID: PropTypes.number.isRequired,
    currentAccountingYear: PropTypes.string.isRequired,
    currentWorkingPeriodEnd: PropTypes.number.isRequired,
  }).isRequired,
  sidebarToggle: PropTypes.bool,
  /** @type {shape} sidebarProgress */
  sidebarProgress: PropTypes.number.isRequired,
};

SidebarContainer.defaultProps = {
  sidebarToggle: false,
};

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  sidebarToggle: fromRoot.getSidebarToggleUpdate(state),
  sidebarProgress: fromAuth.getSidebarProgress(state),
});
const mapDispatchToProps = (dispatch) => ({
  checkFeatureUnleashed: (query) =>
    dispatch(
      resourceListReadRequest(`${getFeatureUnleashApi}/${query.toggleName}`),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
