import { detect } from 'detect-browser';

import { htmlTblCreater } from './el';

function exportObject2PDF(
  documentTitle,
  documentTitleStyle,
  headers,
  exportable,
  headerStyle,
  cellStyle,
  repeatHeader,
  fileName,
) {
  // Define a printable body element
  const printableBody = document.createElement('iframe');

  // Set the prontableBody to hidden
  printableBody.setAttribute(
    'style',
    'visibility: hidden; height: 0; width: 0; position: absolute;',
  );
  printableBody.setAttribute('id', 'objectExporterPrintableBodyId');
  printableBody.setAttribute('name', fileName);

  // Construct the document title
  printableBody.srcdoc = '<html></html>';

  // Append the printableBody to the current document
  document.getElementsByTagName('body')[0].appendChild(printableBody);

  // Get the printable elements
  const printableElements = document.getElementById(
    'objectExporterPrintableBodyId',
  );

  // Check when the printableBody is loaded successfully
  printableBody.onload = () => {
    // Detect the browser information
    const browser = detect();

    // Define a printable document
    let printableDocument =
      printableElements.contentWindow || printableElements.contentDocument;

    // Check if there is document element
    if (printableDocument.document)
      printableDocument = printableDocument.document;
    // eslint-disable-next-line max-len
    let printableDocumentContents = '';
    printableDocumentContents += htmlTblCreater(
      'pdf',
      headers,
      exportable,
      headerStyle,
      cellStyle,
      repeatHeader,
    );
    printableDocument.body.innerHTML = printableDocumentContents;

    // Assign style to the printable
    const style = document.createElement('style');
    style.innerHTML = `
      @page {
        size: A4 landscape;margin: 20px;
      }
      
      body {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    `;
    printableDocument.head.appendChild(style);

    // Prepare the printable for the print
    printableElements.focus();

    // Set proper file name for the PDF exported
    const oldTitle = document.title;
    document.title = documentTitle;

    setTimeout(() => {
      document.title = oldTitle;
    }, 100);

    // Check the if the browser is Edge or Internet Explorer
    if (browser.name === 'edge' || browser.name === 'ie') {
      printableElements.contentWindow.document.execCommand(
        'print',
        false,
        null,
      );
    } else {
      // All other browsers
      printableElements.contentWindow.print();
    }
  };
}

export default {
  export: (params) => {
    exportObject2PDF(
      params.documentTitle,
      params.documentTitleStyle,
      params.headers,
      params.exportable,
      params.headerStyle,
      params.cellStyle,
      params.repeatHeader,
      params.fileName,
    );
  },
};
