import React from 'react';
import { t } from '@lingui/macro';
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';

/**
 * Opening Balance OB Grid
 */
export const columns = ({ reportMode }) => [
  {
    width: 80,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'break',
          icon: () => <BiUnlink />,
          visible: 'isReconciled',
          tooltip: t`Unlink`,
        },
      ],
    },
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: 100,
    Header: t`Name`,
    accessor: 'name',
    className: '',
  },
  {
    width: 150,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 100,
    Header: t`ID`,
    accessor: 'matchId',
    className: '',
  },
  {
    width: 60,
    hidden: reportMode,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
];

export default columns;
