import React from 'react';
import { Modal } from 'react-bootstrap';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { AiFillWarning } from '@react-icons/all-files/ai/AiFillWarning';
import styled from 'styled-components/macro';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
  Field,
  Spinner,
} from 'components';

import {
  VATManualReconcileModalColumns,
  VATManualReconcileModalCommittedColumns,
} from 'services/tableshapes';
import {
  toPercentage,
  getPropertyForDate,
  formatNumber,
} from 'services/helpers';

export default function VATManualReconcileModal(props) {
  const {
    transaction,
    transactionId,
    showModal,
    toggleModal,
    handleAction,
    actionSubmitting,
    vatCodes,
    reconciliationDate,
    setReconciliationDate,
    vatCodeRuleSid,
    setVatCodeRuleSid,
    hasReconciliations,
    isSelectionValid,
    currentVatCode,
    baseLineCalculations,
    getRowColor,
    getCommittedRowColor,
    reconciliations,
    areSplitLinesFullyConsumed,
  } = props;

  return (
    <Modal
      show={showModal}
      bsSize="xl"
      onEscapeKeyDown={() => toggleModal(!showModal)}
    >
      <Modal.Header>
        <Heading level={3}>
          <Trans>Manual Reconcile - Transaction {transactionId}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <FieldsHolder>
          <Field
            noBorder
            size="sm"
            type="select"
            name="vatCodeRuleSid"
            id="vatCodeRuleSid"
            label={t`VAT Code`}
            value={vatCodeRuleSid}
            onChange={(e) => setVatCodeRuleSid(e.target.value)}
          >
            <option key="selectOptionDefaultVATCode" value="" disabled>
              {t`Select a VAT Code`}
            </option>
            {vatCodes.length > 0 &&
              vatCodes.map((vatItem) => (
                <option
                  key={`vat-${vatItem.vatCodeRuleSid}`}
                  value={vatItem.vatCodeRuleSid}
                >
                  {vatItem.code}
                </option>
              ))}
          </Field>
          <Field
            noBorder
            size="small"
            type="date"
            name="reconciliationDate"
            id="reconciliationDate"
            label={t`Reconciliation Date`}
            value={reconciliationDate}
            onChange={(e) => setReconciliationDate(e.target.value)}
          />

          <InfoHolder>
            {vatCodeRuleSid !== '' && (
              <>
                <div>
                  {t`Incoming Account for the chosen code: `}{' '}
                  <strong>{currentVatCode?.inTaxAccount || t`Not set`}</strong>
                </div>
                <div>
                  {t`Outgoing Account for the chosen code: `}{' '}
                  <strong>{currentVatCode?.outTaxAccount || t`Not set`}</strong>
                </div>
                <div>
                  {t`Incoming rate for the chosen code: `}{' '}
                  <strong>
                    {currentVatCode &&
                      currentVatCode.history &&
                      toPercentage(
                        getPropertyForDate(
                          reconciliationDate,
                          currentVatCode.history,
                          'inTaxPercentage',
                        ),
                      )}
                    {` %`}
                  </strong>
                </div>
                <div>
                  {t`Outgoing rate for the chosen code: `}{' '}
                  <strong>
                    {currentVatCode &&
                      currentVatCode.history &&
                      toPercentage(
                        getPropertyForDate(
                          reconciliationDate,
                          currentVatCode.history,
                          'outTaxPercentage',
                        ),
                      )}
                    {` %`}
                  </strong>
                </div>
              </>
            )}
          </InfoHolder>
        </FieldsHolder>
        <CustomTableWithAutoSettings
          {...{
            loading: false,
            handleAction,
            actionSubmitting,
            data: transaction,
            getRowColor,
            tableId: 'vatManualReconcileModal',
            columns: VATManualReconcileModalColumns(),
            loadingId: 'transactionId',
            withoutMainTotal: true,
            hideFilters: true,
            dontClearSelection: true,
            selectConfigs: {
              selectCondition: (row) =>
                row.reconciliationStatus === 'UNMATCHED',
              buttons: [
                {
                  id: 'storeReconciliation',
                  icon: <ButtonIcon as={HiCheck} marginRight={5} />,
                  title: t`Commit selected lines`,
                  clearSelected: true,
                  buttonProps: {
                    size: 'sm',
                    kind: 'success',
                    fill: true,
                    magnify: true,
                    disabled: vatCodeRuleSid === '' || !isSelectionValid,
                  },
                },
              ],
            },
          }}
        />
        <div>
          {baseLineCalculations &&
            baseLineCalculations.map((baseLineCalculation, i) => (
              <div key={i}>
                {t`Expected pre-tax / post-tax amounts for line `}{' '}
                {baseLineCalculation.lineId} (
                {formatNumber(baseLineCalculation.amount).formatted}): <br />
                {t`Incoming`} :{' '}
                <strong>
                  {formatNumber(baseLineCalculation.inTax?.amount).formatted} (
                  {toPercentage(baseLineCalculation.inTax?.rate)} %)
                </strong>
                <br />
                {t`Outgoing`} :{' '}
                <strong>
                  {formatNumber(baseLineCalculation.outTax?.amount).formatted} (
                  {toPercentage(baseLineCalculation.outTax?.rate)} %)
                </strong>
              </div>
            ))}
          {reconciliations && reconciliations.length > 0 && (
            <CustomTableWithAutoSettings
              {...{
                loading: false,
                handleAction,
                actionSubmitting,
                getRowColor: getCommittedRowColor,
                tableTitle: t`Committed Lines`,
                data: reconciliations,
                enableGroups: true,
                groupId: 'groupId',
                tableId: 'vatManualReconcileModalCommitted',
                columns: VATManualReconcileModalCommittedColumns(),
                withoutMainTotal: true,
                hideFilters: true,
              }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!areSplitLinesFullyConsumed && (
          <WarningHolder>
            <AiFillWarning size={24} style={{ margin: 0, padding: 0 }} />
            <Text>
              <Trans>
                If you split a line, the total amount of the splits must be
                equal to the original amount of said line.
              </Trans>
            </Text>
          </WarningHolder>
        )}
        <Button
          fill
          kind="success"
          magnify
          disabled={
            !hasReconciliations ||
            !areSplitLinesFullyConsumed ||
            actionSubmitting.manualReconcile
          }
          onClick={() =>
            handleAction('manualReconcile', {
              transactionId: transaction.transactionId,
              lines: transaction.lines,
              date: reconciliationDate,
              vatCodeRuleSid,
            })
          }
        >
          {actionSubmitting.manualReconcile && <Spinner />}
          {!actionSubmitting.manualReconcile && (
            <>
              <ButtonIcon as={HiCheck} /> {t`Reconcile`}
            </>
          )}
        </Button>
        <Button
          fill
          kind="danger"
          magnify
          onClick={() => toggleModal(!showModal)}
        >
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const FieldsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const InfoHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 1rem;
  min-width: 25%;
  height: 10ch;
`;

const WarningHolder = styled.div`
  color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  margin: 0 20px 0 10px;
`;
