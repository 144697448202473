import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link, Paragraph } from 'components';
import { Nav, Collapse } from 'react-bootstrap';

function SidebarNavDropdown(props) {
  const { to, icon, title, children, ...rest } = props;
  const [expanded, setExpanded] = useState(rest.expanded);
  const toggleItem = () => setExpanded(!expanded);

  const propsToPass = rest;
  delete propsToPass.expanded;
  delete propsToPass.eventKey;
  delete propsToPass.activeKey;
  delete propsToPass.activeHref;

  return (
    <li>
      <Link
        to={to}
        onClick={toggleItem}
        data-toggle="collapse"
        aria-expanded={expanded}
        data-menu-item={`${title
          .split(' ')
          .join('-')
          .toLowerCase()}-dropdown-button`}
      >
        <Icon>{icon}</Icon>
        <Paragraph>
          {title}
          <b className="caret" />
        </Paragraph>
      </Link>
      {/* stateful component: dynamic */}
      <div id="dashboardOverview" aria-expanded={expanded} {...propsToPass}>
        <Collapse in={expanded}>
          <Nav>{children}</Nav>
        </Collapse>
      </div>
    </li>
  );
}

SidebarNavDropdown.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  expanded: PropTypes.bool,
  children: PropTypes.node,
};

SidebarNavDropdown.defaultProps = {
  to: undefined,
  icon: undefined,
  title: undefined,
  expanded: undefined,
  children: undefined,
};

export default SidebarNavDropdown;

const Icon = styled.i`
  font-size: 25px;
`;
