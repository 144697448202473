import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AuditTable } from 'components';

import { getAuditApi } from 'services/apihelpers';
import { fromResource } from 'store/selectors';
import { toAuditDetailed } from 'services/routehelpers';
import { resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

export function AuditTableContainer(props) {
  const [actionSubmitting, setActionSubmitting] = useState({});

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => fromResource.getList(state, getAuditApi));
  const loading = useSelector((state) =>
    pending(state, `${getAuditApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getAuditApi, {
        year: params.accountingYear,
        latest: 100,
        companyId: params.companyId,
      }),
    );
  };
  const handleRowClick = (item) =>
    history.push(toAuditDetailed({}, item.auditId, item.auditCatId));

  const getSearch = () => {
    const { location } = props;
    const search = queryString.parse(location.search, {
      parseBooleans: true,
    });

    return {
      reportMode: search.reportMode || false,
    };
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, _props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowClick': {
          handleRowClick(_props);
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(fetchData, [props.location.pathname]);

  return (
    <AuditTable
      {...{
        data,
        search: getSearch(),
        loading,
        handleAction,
        actionSubmitting,
      }}
    />
  );
}

export default AuditTableContainer;
