import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import _isEmpty from 'lodash-es/isEmpty';
import { Progress } from 'react-sweet-progress';
import { FcPlanner } from '@react-icons/all-files/fc/FcPlanner';
import { FcPackage } from '@react-icons/all-files/fc/FcPackage';
import { FcMoneyTransfer } from '@react-icons/all-files/fc/FcMoneyTransfer';
import { FcSalesPerformance } from '@react-icons/all-files/fc/FcSalesPerformance'; // eslint-disable-line max-len

import { periodTypes } from 'services/shapes';
import { DESKTOP, LARGE_SCREENS, FOUR_K } from 'constants/mediaSizes';

const getItems = (company) => {
  const { currentPeriodType, accountSystem, companySettings } = company;
  const { vatEnabled, payrollEnabled } = companySettings;

  const enabledTitle = t`Enabled`;
  const disabledTitle = t`Disabled`;

  const periodType = periodTypes().find(
    (item) => item.id === currentPeriodType,
  );
  const periodTypeName = periodType?.label || '-';

  return [
    {
      name: t`Frequency:`,
      value: periodTypeName,
      emoji: <FcPlanner />,
    },
    {
      name: t`Accounting System:`,
      value: accountSystem,
      emoji: <FcPackage />,
    },
    {
      name: t`VAT:`,
      value: vatEnabled ? enabledTitle : disabledTitle,
      emoji: <FcSalesPerformance />,
    },
    {
      name: t`Payroll:`,
      value: payrollEnabled ? enabledTitle : disabledTitle,
      emoji: <FcMoneyTransfer />,
    },
  ];
};

const width = [
  // Small
  { info: '61%', progress: '37%' },
  // Middle
  { info: '71.5%', progress: '27%' },
  // Large
  { info: '63%', progress: '35.5%' },
];

function DashboardMain(props) {
  const { size = 0, overallCompanyProgress, company } = props;

  const items = useMemo(() => getItems(company), [company]);
  const { all, completed, percentage } = useMemo(() => {
    const defaultValues = { all: 0, completed: 0, percentage: 0 };

    if (_isEmpty(overallCompanyProgress)) return defaultValues;
    // eslint-disable-next-line no-shadow
    const { all, completed } = overallCompanyProgress;

    return {
      all,
      completed,
      percentage: completed === 0 ? 0 : Math.ceil((completed / all) * 100),
    };
  }, [overallCompanyProgress]);

  const overallTitle = (
    <p>
      <b>
        <Trans>
          Overall
          <br />
          Progress
        </Trans>
      </b>
    </p>
  );

  const overallValues = (
    <div className="progress-info">
      <p className="progress-num">{completed}</p>/
      <p className="progress-num">{all}</p>
    </div>
  );

  return (
    <Container>
      <Info size={size}>
        {items.map((item, i) => (
          <InfoItem key={`general-item-${i}`} delay={`${(i + 1) * 0.3}s`}>
            <ItemIcon>{item.emoji}</ItemIcon>
            <ItemField>
              <b>{item.name}</b> {item.value}
            </ItemField>
          </InfoItem>
        ))}
      </Info>
      <ProgressContainer size={size}>
        {size > 0 && <ProgressInfo size={size}>{overallTitle}</ProgressInfo>}
        <Progress type="circle" percent={percentage} strokeWidth={9} />
        <ProgressInfo size={size}>
          {size > 0 ? (
            overallValues
          ) : (
            <>
              {overallTitle}
              {overallValues}
            </>
          )}
        </ProgressInfo>
      </ProgressContainer>
    </Container>
  );
}

export default React.memo(DashboardMain);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-size: 14px;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 13px;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    font-size: 13.4px;
  }

  @media (min-width: ${FOUR_K.min}px) {
    font-size: 18px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
  align-items: stretch;
  width: ${({ size }) => width[size].info};
`;

const InfoItem = styled.div`
  background: white;
  border-radius: 5px;
  flex-basis: 100%;
  padding: 0.52em 0.6em;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  animation: move ${({ delay }) => delay} ease-in;

  @keyframes move {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: ${DESKTOP.max}px) {
    padding: 0.4em 0.6em;
  }
`;

const ItemIcon = styled.div`
  width: 2.14em;
  height: 2.14em;
  border-radius: 5px;
  margin-right: 0.7em;
  background: rgba(236, 236, 236, 0.77);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;

  svg {
    font-size: 1.2em;
  }

  @media (max-width: ${DESKTOP.max}px) {
    width: 2em;
    height: 2em;
    font-size: 0.9em;
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    width: 2em;
    height: 2em;
    font-size: 0.9em;
  }
`;

const ItemField = styled.div`
  font-size: 1.07em;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${DESKTOP.max}px) {
    font-size: 1.05em;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ size }) => (size > 0 ? 'column' : 'row')};
  padding: 0;
  gap: ${({ size }) => (size < 1 ? '1.5em' : '0.8em')};
  color: #b8b8b8;
  background: white;
  border-radius: 5px;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  width: ${({ size }) => width[size].progress};

  .react-sweet-progress-symbol {
    font-weight: 600;
    font-size: 1.42em;
  }

  .react-sweet-progress-circle-outer {
    font-size: 1em !important;
    width: ${({ size }) => (size < 1 ? '6em' : '5.7em')} !important;
    height: ${({ size }) => (size < 1 ? '6em' : '5.7em')} !important;
  }

  @media (max-width: ${DESKTOP.max}px) {
    .react-sweet-progress-circle-outer {
      font-size: 0.85em !important;
    }
  }

  @media (min-width: ${DESKTOP.max}px) and (max-width: ${LARGE_SCREENS.max}px) {
    .react-sweet-progress-circle-outer {
      font-size: 0.85em !important;
    }
  }
`;

const ProgressInfo = styled.div`
  text-align: ${({ size }) => size > 0 && 'center'};

  ${({ size }) =>
    size < 1 &&
    `
      display: flex;
      flex-direction: column;
      gap: 1em;
  `}

  .progress-info {
    display: flex;
    align-items: center;
    gap: 0.28em;
  }

  p {
    padding: 0;
    margin: 0;
    letter-spacing: ${({ size }) => (size < 1 ? '0.9px' : '0.5px')};
    text-transform: uppercase;
    line-height: ${({ size }) => (size < 1 ? '1.2em' : '1.3em')};
    font-size: ${({ size }) => (size < 1 ? '1.25em' : '1.07em')};
    color: #2f3a4d;

    @media (max-width: ${DESKTOP.max}px) {
      font-size: 1em;
    }
  }

  .progress-num {
    border-radius: 20px;
    font-size: ${({ size }) => (size < 1 ? '1em' : '0.92em')};
    font-weight: 700;
    background: rgba(236, 236, 236, 0.77);
    color: #2f3a4d;
    padding: 0.07em 0.7em;
  }
`;
