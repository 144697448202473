import { t } from '@lingui/macro';

export const columns = () => [
  {
    Header: t`ID`,
    reportWidth: 80,
    accessor: 'accountId',
    className: 'flex-center',
  },
  {
    Header: t`Description`,
    accessor: 'name',
    className: '',
    reportWidth: 250,
  },
  {
    Header: t`Period Closing Balance`,
    accessor: 'outgoingBalance',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    reportWidth: 50,
    Header: t`Status`,
    accessor: 'approved',
    className: 'flex-center',
    cell: {
      type: 'boolean',
      hidden: (row) => !row.activeRecord,
    },
  },
  {
    Header: t`Approved Date`,
    accessor: 'approveDate',
    className: 'flex-right',
    cell: {
      type: 'datetime',
    },
  },
  {
    Header: t`Approved By`,
    accessor: 'approver',
    className: '',
  },
  {
    Header: t`Period Comment`,
    accessor: 'periodComment',
    className: '',
  },
];

export default columns;
