import React from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function Legend(props) {
  const { title, color, placement } = props;

  return (
    <LegendItem>
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip
            id="tooltip"
            style={{
              wordBreak: 'break-all',
            }}
          >
            {title}
          </Tooltip>
        }
      >
        <LegendItemColor
          color={color}
          className={cx(color)}
          data-testid="item-color"
        />
      </OverlayTrigger>
    </LegendItem>
  );
}

Legend.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  placement: PropTypes.string,
};

Legend.defaultProps = {
  title: '',
  color: '',
  placement: 'left',
};

export default Legend;

const LegendItemColor = styled.div`
  width: 20px;
  height: 20px;
  animation: animate 1s ease infinite alternate;
  margin-left: 5px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  @keyframes animate {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;

const LegendItem = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 15px;
  align-items: center;
  flex-direction: row;
`;
