import _isEmpty from 'lodash-es/isEmpty';

const getSummary = ({
  outgoingBalance,
  accrualsTransactions,
  reconcileTransactions,
}) => {
  let openSum = 0;
  let accrualsSum = 0;
  let approvedSum = 0;
  let totalSum = 0;

  if (Array.isArray(accrualsTransactions)) {
    accrualsSum = accrualsTransactions.reduce(
      (sum, el) => sum + el.sumRemaining,
      0,
    );
  }

  if (Array.isArray(reconcileTransactions)) {
    approvedSum = reconcileTransactions
      .filter((el) => el.reconciliationStatus === 'APPROVED')
      .reduce((sum, el) => sum + el.amount, 0);
  }

  const showOpenSum = !_isEmpty(reconcileTransactions);

  if (showOpenSum) {
    const openItems = reconcileTransactions.filter(
      (el) => el.reconciliationStatus === 'OPEN',
    );
    openSum = openItems.reduce((sum, el) => sum + el.amount, 0);
  }

  totalSum = openSum + approvedSum + accrualsSum - outgoingBalance;

  const sumOpenPostsValues =
    typeof openSum === 'number' && !Number.isNaN(openSum) ? openSum : 0;

  const accrualsSumValues =
    typeof accrualsSum === 'number' && !Number.isNaN(accrualsSum)
      ? accrualsSum
      : 0;

  const approvedSumValues =
    typeof approvedSum === 'number' && !Number.isNaN(approvedSum)
      ? approvedSum
      : 0;

  const totalSumValues =
    typeof totalSum === 'number' && !Number.isNaN(totalSum)
      ? Math.abs(totalSum)
      : 0;

  return {
    showOpenSum,
    totalSumValues,
    accrualsSumValues,
    approvedSumValues,
    sumOpenPostsValues,
  };
};

export default getSummary;
