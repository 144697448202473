import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import _debounce from 'lodash-es/debounce';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import { FcOk } from '@react-icons/all-files/fc/FcOk';
import { FcLeave } from '@react-icons/all-files/fc/FcLeave';
import { FcKindle } from '@react-icons/all-files/fc/FcKindle';
import { FcPlanner } from '@react-icons/all-files/fc/FcPlanner';
import { FcDocument } from '@react-icons/all-files/fc/FcDocument';
import { FcLibrary } from '@react-icons/all-files/fc/FcLibrary';
import { FcAdvance } from '@react-icons/all-files/fc/FcAdvance';
import { FcAreaChart } from '@react-icons/all-files/fc/FcAreaChart';
import { FcSynchronize } from '@react-icons/all-files/fc/FcSynchronize';
import { FcReadingEbook } from '@react-icons/all-files/fc/FcReadingEbook';
import { FcHighPriority } from '@react-icons/all-files/fc/FcHighPriority';
import { FcMoneyTransfer } from '@react-icons/all-files/fc/FcMoneyTransfer';
import { FcMultipleInputs } from '@react-icons/all-files/fc/FcMultipleInputs';
import { FcSalesPerformance } from '@react-icons/all-files/fc/FcSalesPerformance'; // eslint-disable-line max-len
import { FcPodiumWithAudience } from '@react-icons/all-files/fc/FcPodiumWithAudience'; // eslint-disable-line max-len
import { AiFillInfoCircle } from '@react-icons/all-files/ai/AiFillInfoCircle';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Button, Spinner } from 'components';

import { fromCompany, fromAuth } from 'store/selectors';
import {
  formatDate,
  formatNumber,
  defineFrequencyPeriodTypes,
} from 'services/helpers';
import { periodTypes, filterByTemplateStatuses } from 'services/shapes';
import getErrorMessage from 'services/helpers/getErrorMessage';

import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceUpdateRequest,
  resourceListReadSuccess,
} from 'store/actions';
import {
  periodCommentApi,
  accountsControlApi,
  getGLAccountDetails,
  getSLAccountDetails,
  getBankAccountDetails,
  saveAccountUserCommentApi,
  updateAccountApproveStatusApi,
} from 'services/apihelpers';

import getSummary from './getSummary';
import getBankSummary from './getBankSummary';

const columns = {
  Properties: {
    [t`Template`]: {
      icon: <FcDocument />,
      accessor: 'template',
    },
    [t`Frequency`]: {
      icon: <FcPlanner />,
      accessor: 'frequency',
    },
    [t`Assignee`]: {
      icon: <FcReadingEbook />,
      accessor: 'accountHolder',
    },
  },
  Amounts: {
    [t`Year Opening Balance`]: {
      icon: <FcSalesPerformance />,
      accessor: 'openingBalance',
    },
    [t`Period Opening Balance`]: {
      icon: <FcAdvance />,
      accessor: 'ingoingBalance',
    },
    [t`Period Movement`]: {
      icon: <FcSynchronize />,
      accessor: 'movement',
    },
    [t`Period Closing Balance`]: {
      icon: <FcLeave />,
      accessor: 'outgoingBalance',
    },
  },
  Summary: {
    [t`Sum of open posts`]: {
      icon: <FcMultipleInputs />,
      accessor: 'sumOpenPostsValues',
    },
    [t`Sum Accrued amounts`]: {
      icon: <FcPodiumWithAudience />,
      accessor: 'accrualsSumValues',
    },
    [t`Sum Approved amounts`]: {
      icon: <FcOk />,
      accessor: 'approvedSumValues',
    },
    [t`Deviation to outgoing balance`]: {
      icon: <FcAreaChart />,
      accessor: 'totalSumValues',
    },
  },
  BankSummary: {
    [t`Bank Outgoing balance`]: {
      icon: <FcMoneyTransfer />,
      accessor: 'outgoingBankValues',
    },
    [t`Posted in GL, not posted in Bank`]: {
      icon: <FcKindle />,
      accessor: 'totalGL',
    },
    [t`Posted in Bank, not posted in GL`]: {
      icon: <FcLibrary />,
      accessor: 'totalBank',
    },
    [t`Deviation`]: {
      icon: <FcAreaChart />,
      accessor: 'deviation',
    },
    [t`Unexplained Deviation`]: {
      icon: <FcHighPriority />,
      accessor: 'unexplainedDeviation',
    },
  },
};

export function AccountDrilldownInfo(props) {
  const {
    account,
    glTotal,
    children,
    bankTotal,
    sourceData,
    isReadOnly,
    bookedBalance,
    reconcileTransactions,
    accrualsTransactions,
    approveAndGeneratePDF,
  } = props;

  const {
    approved,
    approver,
    accountId,
    periodType,
    approveDate,
    accountControl,
    periodCommentId,
    outgoingBalance,
    accountTemplateCode,
    generalLedgerAccountId,
  } = account;

  const dispatch = useDispatch();

  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const { currentWorkingPeriodEnd } = company || {};

  const [approveLoading, setApproveLoading] = useState(false);
  const [controlLoading, setControlLoading] = useState(false);
  const [periodCommentLoading, setPeriodCommentLoading] = useState(false);

  const dataToDisplay = useMemo(() => {
    const frequency = periodTypes().find(
      (item) => item.id === periodType,
    )?.label;
    const template = filterByTemplateStatuses().find(
      (item) => item.id === accountTemplateCode,
    )?.label;

    return { ...account, frequency, template };
  }, [account]);

  const { showOpenSum, ...summary } = useMemo(
    () =>
      getSummary({
        outgoingBalance,
        accrualsTransactions,
        reconcileTransactions,
      }),
    [reconcileTransactions, accrualsTransactions, outgoingBalance],
  );

  const bankSummary = useMemo(
    () =>
      getBankSummary({
        glTotal,
        bankTotal,
        bookedBalance,
        outgoingBalance,
        accountingPeriod: currentWorkingPeriodEnd,
      }),
    [
      glTotal,
      bankTotal,
      bookedBalance,
      outgoingBalance,
      currentWorkingPeriodEnd,
    ],
  );

  const isApproveDisabled = useMemo(
    () => account?.documentRequired && !account?.hasFile,
    [account],
  );

  const approveMeta = useMemo(() => {
    if (!approver || !approveDate) {
      return null;
    }

    return t`Approved by ${approver} - ${formatDate(approveDate, true)}`;
  }, [approved, approver, approveDate]);

  // eslint-disable-next-line no-nested-ternary
  const approveBtnText = isApproveDisabled
    ? t`Need documents to approve`
    : approved
    ? t`Unapprove`
    : t`Mark as Approved`;

  const {
    partially,
    controlled,
    controlledTooltip,
    isUncontrolEnabled,
    enabledForUncontrol,
  } = useMemo(() => {
    const _enabledForUncontrol = accountControl?.list?.filter(
      (item) => item.controllerUsername === user.username,
    );

    const tooltip = accountControl?.list?.map((ci) => {
      const controller = `${ci?.controllerUsername} ${formatDate(
        ci.controlDate,
        true,
      )}`;

      const time = `${
        accountControl.partiallyControlled
          ? `Period - (${formatDate(ci?.controlPeriodStart)} - ${formatDate(
              ci?.controlPeriodEnd,
            )})`
          : ''
      }`;

      return `${time} by ${controller}`;
    });

    return {
      controlledTooltip: tooltip,
      controlled: !!accountControl,
      enabledForUncontrol: _enabledForUncontrol,
      partially: accountControl?.partiallyControlled,
      isUncontrolEnabled: !!_enabledForUncontrol?.length,
    };
  }, [accountControl]);

  const updateAccountInRedux = async (values) => {
    const api =
      // eslint-disable-next-line no-nested-ternary
      accountTemplateCode === 'GENERAL'
        ? getGLAccountDetails
        : accountTemplateCode === 'BANK'
        ? getBankAccountDetails
        : getSLAccountDetails;

    await dispatch(
      resourceListReadSuccess(`${api}/${accountId}`, {
        ...sourceData,
        account: {
          ...sourceData.account,
          ...values,
        },
      }),
    );
  };

  const handleControlChange = async () => {
    setControlLoading(() => true);

    const _periodType = company.currentPeriodType.toUpperCase();

    const query = {
      accountIds: [accountId],
      companyId: company.uuid,
      year: +company.currentAccountingYear,
      periodType: _periodType === 'HALFYEARLY' ? 'HALF_YEARLY' : _periodType,
      period: defineFrequencyPeriodTypes(
        company.currentPeriodType,
        +currentWorkingPeriodEnd,
      ),
    };

    try {
      if (isUncontrolEnabled) {
        const enableIds = enabledForUncontrol?.map((i) => i.id);
        const deleteQuery = enableIds?.map((i) => `controlIds=${i}`)?.join('&');

        await dispatch(
          resourceDeleteRequest(`${accountsControlApi}?${deleteQuery}`),
        );

        const newControlList = accountControl.list.filter(
          (i) => !enableIds.includes(i.id),
        );
        const newAccountControl = newControlList?.length
          ? { ...accountControl, list: newControlList }
          : null;

        updateAccountInRedux({
          accountControl: newAccountControl,
        });
      } else {
        const response = await dispatch(
          resourceCreateRequest(accountsControlApi, query),
        );

        updateAccountInRedux({ accountControl: response[0] });
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    setControlLoading(() => false);
  };

  const getFormattedValue = useCallback((value) => {
    const formattedValue = formatNumber(value);

    return (
      <span className={formattedValue.className}>
        {formattedValue.formatted}
      </span>
    );
  }, []);

  const onApproveChange = async () => {
    setApproveLoading(() => true);
    const query = {
      accounts: [{ accountId, accountStatus: !approved }],
      periodTo: defineFrequencyPeriodTypes(
        company?.periodType,
        currentWorkingPeriodEnd,
      ),
      companyId: company.currentCompanySID,
      periodFrom: company.currentWorkingPeriodStart,
      periodType: company.currentPeriodType.toUpperCase(),
      periodYear: company.currentAccountingYear,
    };

    try {
      await dispatch(
        resourceCreateRequest(updateAccountApproveStatusApi, query),
      );

      await updateAccountInRedux({
        approved: !approved,
        approver: approved ? null : user?.name,
        approveDate: approved
          ? null
          : new Date().toLocaleString('en-US', {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
      });
      if (!approved) {
        try {
          await approveAndGeneratePDF({ skipDownload: true, saveReport: true });
          toast.success(t`Report generated successfully`);
        } catch (error) {
          toast.error(
            error?.response?.headers?.get('Response-Message') || error?.message,
          );
        }
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      setApproveLoading(() => false);
    }
  };

  const savePeriodComment = useCallback(
    _debounce(async (comment) => {
      setPeriodCommentLoading(() => true);

      try {
        const query = {
          comment,
          companyId: company.uuid,
          periodType: company.currentPeriodType.toUpperCase(),
          period: defineFrequencyPeriodTypes(
            company.currentPeriodType,
            +currentWorkingPeriodEnd,
          ),
          accountId,
          year: +company.currentAccountingYear,
        };

        // If period comment for this company with current period and year already exist
        if (periodCommentId) {
          // If period comment field is empty remove it from DB
          if (!comment) {
            // Delete period comment
            await dispatch(
              resourceDeleteRequest(
                `${periodCommentApi}?id=${periodCommentId}`,
              ),
            );

            updateAccountInRedux({
              periodComment: null,
              periodCommentId: null,
            });
          } else {
            // Change period comment
            await dispatch(
              resourceUpdateRequest(periodCommentApi, null, {
                comment,
                id: periodCommentId,
              }),
            );
          }
          // Case if period comment not exist in DB yet and field isn't empty
        } else if (comment) {
          // Create period comment
          const newPeriodComment = await dispatch(
            resourceCreateRequest(periodCommentApi, query),
          );

          updateAccountInRedux({
            periodComment: comment,
            periodCommentId: newPeriodComment.id,
          });
        }
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      } finally {
        setTimeout(() => {
          setPeriodCommentLoading(() => false);
        }, 800);
      }
    }, 800),
    [company, accountId, periodCommentId],
  );

  const saveStatusComment = useCallback(
    _debounce(async (comment) => {
      try {
        await dispatch(
          resourceCreateRequest(saveAccountUserCommentApi, {
            userComment: comment,
            year: company.currentAccountingYear,
            companyId: company.currentCompanySID,
            generalLedgerAccountSID: generalLedgerAccountId,
          }),
        );
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    }, 800),
    [company, generalLedgerAccountId],
  );

  const onCommentChange = (e) => {
    const { id } = e.target;
    const comment = e.target.value;

    updateAccountInRedux({
      [id]: id === 'comment' ? comment : comment || null,
    });
  };

  const onCommentBlur = useCallback(
    (e) => {
      const { id } = e.target;
      const comment = e.target.value;

      updateAccountInRedux({
        [id]: id === 'comment' ? comment : comment || null,
      });

      if (id === 'comment') {
        saveStatusComment(comment);
      } else {
        savePeriodComment(comment);
      }
    },
    [savePeriodComment, saveStatusComment],
  );

  return (
    <>
      <Header>
        <Statuses>
          <ApprovedStatus status={approved}>
            {approved ? t`Approved` : t`Not Approved`}
          </ApprovedStatus>
          <ControllStatus partially={partially} controlled={controlled}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {partially
              ? t`Partially Controlled`
              : controlled
              ? t`Controlled`
              : t`Not Controlled`}
          </ControllStatus>
        </Statuses>
        <MainInfo>
          <h2>
            {account?.accountId} - {account?.name}
          </h2>
          <div>
            <Button
              fill
              magnify
              size="sm"
              onClick={onApproveChange}
              kind={approved ? 'danger' : 'success'}
              disabled={isApproveDisabled || approveLoading || isReadOnly}
            >
              {approveLoading ? (
                <Spinner size="md" type="white" />
              ) : (
                approveBtnText
              )}
            </Button>
            {approveMeta && (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">{approveMeta}</Tooltip>}
                placement="bottom"
              >
                <InfoIcon />
              </OverlayTrigger>
            )}
            <Button
              fill
              magnify
              size="sm"
              disabled={controlLoading || isReadOnly}
              onClick={handleControlChange}
              kind={isUncontrolEnabled ? 'default' : 'info'}
            >
              {isUncontrolEnabled ? t`Uncontrol` : t`Mark as Controlled`}
            </Button>
            {controlled && !!controlledTooltip && (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">{controlledTooltip}</Tooltip>}
                placement="bottom"
              >
                <InfoIcon />
              </OverlayTrigger>
            )}
          </div>
        </MainInfo>
        {children}
      </Header>
      <Container>
        <Columns template={accountTemplateCode}>
          <ContainerItems>
            <SubTitle>
              <Trans>Properties</Trans>
            </SubTitle>
            {Object.keys(columns.Properties).map((key) => (
              <ContainerItem key={key}>
                <IconContainer>{columns.Properties[key].icon}</IconContainer>
                <ValueHolder>
                  <b>{t({ message: key })}:</b>
                  {dataToDisplay[columns.Properties[key].accessor]}
                </ValueHolder>
              </ContainerItem>
            ))}
          </ContainerItems>

          <ContainerItems withBG>
            <SubTitle>
              <Trans>Balance Amounts</Trans>
            </SubTitle>
            <Amounts>
              {Object.keys(columns.Amounts).map((key) => (
                <ContainerItem key={key} withoutBg>
                  <IconContainer>{columns.Amounts[key].icon}</IconContainer>
                  <ValueHolder justify>
                    <b>{t({ message: key })}:</b>
                    {getFormattedValue(
                      dataToDisplay[columns.Amounts[key].accessor],
                    )}
                  </ValueHolder>
                </ContainerItem>
              ))}
            </Amounts>
          </ContainerItems>

          {showOpenSum && accountTemplateCode === 'GENERAL' && (
            <ContainerItems>
              <SubTitle>
                <Trans>Summary</Trans>
              </SubTitle>
              <Amounts>
                {Object.keys(columns.Summary).map((key) => (
                  <ContainerItem key={key} withoutBg>
                    <IconContainer>{columns.Summary[key].icon}</IconContainer>
                    <ValueHolder justify>
                      <b>{t({ message: key })}:</b>
                      {getFormattedValue(
                        summary[columns.Summary[key].accessor],
                      )}
                    </ValueHolder>
                  </ContainerItem>
                ))}
              </Amounts>
            </ContainerItems>
          )}

          {accountTemplateCode === 'BANK' && (
            <ContainerItems>
              <SubTitle>
                <Trans>Summary</Trans>
              </SubTitle>
              <Amounts>
                {Object.keys(columns.BankSummary).map((key) => (
                  <ContainerItem key={key} withoutBg>
                    <IconContainer>
                      {columns.BankSummary[key].icon}
                    </IconContainer>
                    <ValueHolder justify>
                      <b>{t({ message: key })}:</b>
                      {getFormattedValue(
                        bankSummary[columns.BankSummary[key].accessor],
                      )}
                    </ValueHolder>
                  </ContainerItem>
                ))}
              </Amounts>
            </ContainerItems>
          )}

          <CommentsContainer>
            <div>
              <SubTitle>
                <Trans>Status comment</Trans>
              </SubTitle>
              <textarea
                spellCheck="false"
                data-gramm="false"
                value={account?.comment}
                id="comment"
                placeholder={t`Type status comment here...`}
                onChange={onCommentChange}
                onBlur={onCommentBlur}
              />
            </div>
            <div>
              <SubTitle>
                <Trans>Period comment</Trans>
              </SubTitle>
              <textarea
                disabled={periodCommentLoading}
                spellCheck="false"
                data-gramm="false"
                value={account?.periodComment}
                id="periodComment"
                placeholder={t`Type period comment here...`}
                onChange={onCommentChange}
                onBlur={onCommentBlur}
              />
            </div>
          </CommentsContainer>
        </Columns>
      </Container>
    </>
  );
}

export default AccountDrilldownInfo;

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 20px 0;
`;

const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const ContainerItem = styled.div`
  padding: ${({ withoutBg }) => (withoutBg ? '2px' : '8px')};
  border-radius: 6px;
  background: ${({ withoutBg }) => (withoutBg ? 'transparent' : 'white')};
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: ${({ withoutBg }) =>
    withoutBg ? 'none' : 'rgba(149, 157, 165, 0.05) 0px 8px 24px'};

  @media (max-width: 1600px) {
    gap: 6px;
  }

  @media (max-width: 1366px) {
    padding: ${({ withoutBg }) => (withoutBg ? '0' : '4px')};
  }
`;

const IconContainer = styled.div`
  background: rgba(236, 236, 236, 0.77);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 1600px) {
    width: 25px;
    height: 25px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const SubTitle = styled.h3`
  color: #afada4;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

const grid = {
  big: {
    GENERAL: '22% 25% 27% 23%',
    BANK: '22% 25% 30% 20%',
    SIMPLE_LEDGER: '30% 35% 33%',
  },
  middle: {
    GENERAL: '22% 28% 27% 20%',
    BANK: '22% 25% 30% 20%',
    SIMPLE_LEDGER: '30% 35% 33%',
  },
  small: {
    GENERAL: '20% 30% 30% 17%',
    BANK: '22% 25% 30% 20%',
    SIMPLE_LEDGER: '32% 41% 25%',
  },
};

const ValueHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  ${({ justify }) => justify && 'justify-content: space-between;'};

  span {
    display: inline-block;
    white-space: nowrap;
  }

  @media (max-width: 1600px) {
    font-size: 13px;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 1600px) {
    gap: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  & > div {
    height: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1366px) {
      height: calc(50% - 5px);
    }
  }

  textarea {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    color: #6a6666;
    outline: none;
    resize: none;
    line-height: 1.5;
    border: none;
    background: white;
    flex: 1;

    &:disabled {
      opacity: 0.5;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    ::placeholder {
      color: #cbc5c5;
    }

    @media (max-width: 1500px) {
      padding: 10px;
      font-size: 12px;
    }

    @media (max-width: 1366px) {
      font-size: 11px;
      padding: 5px 10px;
    }
  }
`;

const Amounts = styled.div`
  display: flex;
  padding: 10px;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  background: white;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;

  @media (max-width: 1366px) {
    padding: 8px;
  }
`;

const InfoIcon = styled(AiFillInfoCircle)`
  font-size: 15px;
  margin-left: -2px;
  color: #6a6a6a;
`;

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
  }

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;

  padding: 10px 10px 10px 20px;
  border: 1px solid #e1e1e0;
  border-radius: 6px 25px 25px 6px;
`;

const Statuses = styled.span`
  position: absolute;
  left: 15px;
  top: -10px;
  font-size: 12px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ApprovedStatus = styled.div`
  padding: 0 5px;
  background: #f4f3ef;
  color: ${({ status }) => (status ? '#7ac29a' : '#eb5e27')};
`;

const ControllStatus = styled.div`
  padding: 0 5px;
  background: #f4f3ef;
  color: ${({ controlled, partially }) =>
    // eslint-disable-next-line no-nested-ternary
    partially ? '#f3bb45' : controlled ? '#68b3c8' : '#6a6a6a'};
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: ${({ template }) => grid.big[template]};
  gap: 1%;

  @media (max-width: 1800px) {
    grid-template-columns: ${({ template }) => grid.middle[template]};
  }

  @media (max-width: 1366px) {
    grid-template-columns: ${({ template }) => grid.small[template]};
  }

  ${({ template }) =>
    (template === 'GENERAL' || template === 'BANK') &&
    `
    @media (max-width: 1500px) {
      ${IconContainer} {
        width: 0;
        height: 20px;
      }
    }

    @media (max-width: 1400px) {
      ${ValueHolder} {
        font-size: 12px;
      }
    }

    @media (max-width: 1240px) {
      grid-template-columns: 50% 50%;

      ${IconContainer} {
        width: 25px;
        height: 25px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      ${Amounts} {
        padding: 10px;
      }

      ${ContainerItems}:nth-of-type(1) ${ContainerItem} {
        padding: 11px 8px;
      }
    }
  `}
`;
