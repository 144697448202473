import { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { resourceListReadRequest } from 'store/actions';
import {
  uploadedFilesApi,
  getReconciliationGLAccountsFastApi,
  getReportVersionsApi,
} from 'services/apihelpers';
import { fromResource } from 'store/selectors';
import { defineFrequencyPeriodTypes, formatDate } from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useAccountOverviewData = (props) => {
  const {
    user,
    companyId,
    periodType,
    showDeactived,
    accountingYear,
    accountingPeriod,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getReconciliationGLAccountsFastApi),
  );
  const reports = useSelector((state) =>
    fromResource.getList(state, getReportVersionsApi),
  );

  const preparedData = useMemo(() => {
    let _data = data;
    if (Array.isArray(_data)) {
      if (!showDeactived) {
        _data = _data.filter((item) => item.activeRecord);
      }

      return _data.map((row) => {
        const { accountId, accountControl, childFileNames } = row;

        const controlled = !!accountControl;
        const childFiles = childFileNames?.length || 0;
        const enabledForUncontrol = accountControl?.list?.filter(
          (item) => item.controllerUsername === user.username,
        );
        const _reports = reports?.ACCOUNTS?.filter(
          (item) => item.accountId === accountId,
        );
        // Formatted all controlled values to display in tooltip
        const controlledTooltip = accountControl?.list?.map((ci) => {
          const controller = `${ci?.controllerUsername} ${formatDate(
            ci.controlDate,
            true,
          )}`;

          const time = `${
            accountControl.partiallyControlled
              ? `Period - (${formatDate(ci?.controlPeriodStart)} - ${formatDate(
                  ci?.controlPeriodEnd,
                )})`
              : ''
          }`;

          return `${time} by ${controller}`;
        });

        return {
          ...row,
          childFiles,
          controlled,
          controlledTooltip,
          enabledForUncontrol,
          partiallyControlled: accountControl?.partiallyControlled,
          reports: _reports,
        };
      });
    }

    return [];
  }, [data, showDeactived, reports]);

  const fetchReportsInfo = async () => {
    const reportQuery = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['ACCOUNTS'],
    };

    try {
      await dispatch(
        resourceListReadRequest(getReportVersionsApi, reportQuery),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const loadTableData = async () => {
    const query = {
      companyId,
      year: accountingYear,
      period: defineFrequencyPeriodTypes(periodType, accountingPeriod),
      periodType: periodType.toUpperCase(),
    };

    const reportQuery = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['ACCOUNTS'],
    };

    setLoading(() => true);

    try {
      await Promise.all([
        dispatch(
          resourceListReadRequest(getReconciliationGLAccountsFastApi, query),
        ),
        dispatch(
          resourceListReadRequest(uploadedFilesApi, {
            ...query,
            period: +accountingPeriod,
            fileCategory: 'ACCOUNT',
          }),
        ),
        dispatch(resourceListReadRequest(getReportVersionsApi, reportQuery)),
      ]);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadTableData();
  }, [companyId, periodType, accountingYear, accountingPeriod]);

  return {
    data,
    preparedData,
    reports,
    loading,
    loadTableData,
    fetchReportsInfo,
  };
};

export default useAccountOverviewData;
