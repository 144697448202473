import { useState, useEffect, useMemo } from 'react';
import { t } from '@lingui/macro';
import _isEmpty from 'lodash/isEmpty';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { setVATApproveApi, getVatDetailsNewer } from 'services/apihelpers';
import {
  resourceDeleteRequest,
  resourceCreateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import { formatDate } from 'services/helpers';
import { fromAuth, fromResource, fromCompany } from 'store/selectors';
import { vatReportFormatter } from 'services/dataFormatters/vat';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const getVATApprovalStatus = (approvedTerms, period) => {
  if (!Array.isArray(approvedTerms)) {
    return { isApproved: false, approver: '' };
  }

  const currentTermValue = approvedTerms.find(
    (item) => +item.period === +period,
  );
  const { approved, approvedBy, approvedDateTime } = currentTermValue || {};

  // eslint-disable-next-line no-shadow
  let approverInfo = '';

  if (approvedBy) {
    approverInfo = approved
      ? t`Approved by ${approvedBy} - ${formatDate(approvedDateTime, true)}`
      : t`Unapproved by ${approvedBy} - ${formatDate(approvedDateTime, true)}`;
  }

  return {
    approverInfo,
    isApproved: approved || false,
  };
};

const getPeriod = (activePeriods, frequency) => {
  if (frequency === 'BIMONTHLY') {
    return Math.ceil(+activePeriods / 2);
  }

  if (frequency === 'YEARLY') {
    return 1;
  }

  return +activePeriods;
};

export const vatReportDataFormatter = (data, activePeriods) => {
  if (_isEmpty(data)) {
    return [];
  }

  return Object.keys(data).map((key) => {
    const _period = getPeriod(activePeriods, key);
    return {
      periodType: key,
      period: _period,
      isApproved: getVATApprovalStatus(data[key].approvals, _period).isApproved,
      tableData: vatReportFormatter({
        data,
        frequency: key,
        period: _period,
        setUnmappedLines: () => {},
      }),
    };
  });
};

export const useVATReportData = (props) => {
  const { accountingYear, activePeriods, fetchVATOverview } = props;

  const dispatch = useDispatch();

  const [frequencies, setFrequencies] = useState([]);
  const [frequency, setFrequency] = useState(null);
  const [unmappedLines, setUnmappedLines] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const data = useSelector((state) =>
    fromResource.getList(state, getVatDetailsNewer),
  );

  const period = useMemo(
    () => getPeriod(activePeriods, frequency),
    [activePeriods, frequency],
  );

  const tableData = useMemo(
    () => vatReportFormatter({ data, frequency, period, setUnmappedLines }),
    [data, frequency, period, setUnmappedLines],
  );

  const reportData = useMemo(
    () => vatReportDataFormatter(data, activePeriods),
    [data, activePeriods],
  );

  const { isApproved, approverInfo } = useMemo(() => {
    const approvedTerms = data?.[frequency]?.approvals;
    return getVATApprovalStatus(approvedTerms, period);
  }, [data, frequency, period]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      const response = await dispatch(
        resourceListReadRequest(getVatDetailsNewer, {
          year: accountingYear,
          companyId: company.currentCompanySID,
        }),
      );

      if (response) {
        const periodTypes = Object.keys(response);

        setFrequencies(periodTypes);
        setFrequency(periodTypes[0]);
      } else {
        setFrequency(undefined);
        setFrequencies([]);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
      setFrequencies([]);
      setFrequency(undefined);
    }

    setLoading(() => false);
  };

  const handleApproveChange = async (value) => {
    try {
      if (value) {
        // POST
        const newItem = await dispatch(
          resourceCreateRequest(setVATApproveApi, {
            period,
            periodType: frequency,
            year: +accountingYear,
            companyId: company?.currentCompanySID,
            uuid: user?.uuid,
          }),
        );

        const newData = {
          ...data,
          [frequency]: {
            data: data[frequency].data,
            approvals: [...data[frequency].approvals, newItem],
          },
        };
        dispatch(resourceListReadSuccess(getVatDetailsNewer, newData));
      } else {
        // DELETE
        await dispatch(
          resourceDeleteRequest(
            // eslint-disable-next-line max-len
            `${setVATApproveApi}?period=${period}&year=${+accountingYear}&companyId=${
              company?.currentCompanySID
            }&periodType=${frequency}&uuid=${user?.uuid}`,
          ),
        );

        const newData = {
          ...data,
          [frequency]: {
            data: data[frequency].data,
            approvals: data[frequency].approvals.filter(
              (item) => +item.period !== +period,
            ),
          },
        };
        dispatch(resourceListReadSuccess(getVatDetailsNewer, newData));
      }
      fetchVATOverview();
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    fetchData();
  }, [accountingYear, company?.companyId]);

  return {
    period,
    loading,
    frequency,
    tableData,
    isApproved,
    frequencies,
    reportData,
    setFrequency,
    approverInfo,
    unmappedLines,
    handleApproveChange,
    fetchData,
  };
};

export default useVATReportData;
