import { t } from '@lingui/macro';

export const columns = (reportMode) => [
  {
    width: reportMode ? 220 : 400,
    Header: t`Type`,
    accessor: 'name',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`Amount From Altinn`,
    sortType: 'basic',
    accessor: 'reportedAmount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`Amount From GL`,
    sortType: 'basic',
    accessor: 'glAmount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 120,
    Header: t`Deviation`,
    sortType: 'basic',
    accessor: 'difference',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 150,
    Header: t`Accumulated YTD Amount from Altinn`,
    sortType: 'basic',
    accessor: 'accumulatedReportedAmount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 150,
    Header: t`Accumulated YTD Amount from GL`,
    sortType: 'basic',
    accessor: 'accumulatedGlAmount',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 150,
    Header: t`Accumulated YTD Deviation`,
    sortType: 'basic',
    accessor: 'accumulatedDifference',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 180,
    hidden: reportMode,
    Header: t`Map transactions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'button',
      disableClick: true,
      buttons: [
        {
          id: 'mapTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Map Transactions`,
          magnify: true,
        },
      ],
    },
  },
];

export default { columns };
