import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Panel as BPanel } from 'react-bootstrap';

/**
 * Panel component
 */
function Panel(props) {
  const {
    kind,
    title,
    footer,
    toggle,
    expanded,
    children,
    className,
    collapsible,
    defaultExpanded,
    ...rest
  } = props;
  // Custom Bootstrap classes'
  const classes = classNames('panel', {}, className);

  return (
    <div>
      <BPanel
        bsStyle={kind}
        expanded={expanded}
        className={classes}
        defaultExpanded={defaultExpanded}
        {...rest}
      >
        <BPanel.Heading>
          <BPanel.Title toggle={toggle}>{title}</BPanel.Title>
        </BPanel.Heading>
        <BPanel.Collapse>
          <BPanel.Body collapsible={collapsible}>{children}</BPanel.Body>
        </BPanel.Collapse>
        {footer && <BPanel.Footer>{footer}</BPanel.Footer>}
      </BPanel>
    </div>
  );
}

Panel.propTypes = {
  kind: PropTypes.oneOf([
    'info',
    'danger',
    'success',
    'warning',
    'default',
    'primary',
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  footer: PropTypes.node,
  toggle: PropTypes.bool,
  expanded: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
};

Panel.defaultProps = {
  kind: 'default',
  title: undefined,
  footer: undefined,
  toggle: undefined,
  expanded: true,
  children: undefined,
  className: undefined,
  collapsible: false,
  defaultExpanded: undefined,
};

export default Panel;
