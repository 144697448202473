import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { MoveGroupUsersModal } from 'components';

import { deleteGroupUsersApi, setUserGroupUsersApi } from 'services/apihelpers';
import { resourceDeleteRequest, resourceUpdateRequest } from 'store/actions';

function MoveGroupUsersModalContainer(props) {
  const { item, data, closeModal, refetch } = props;
  const dispatch = useDispatch();
  const userGroups = (data.availableUserGroups || []).map((c) => ({
    id: c.groupId,
    label: c.name,
  }));
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);

  const onSubmit = async () => {
    await dispatch(
      resourceDeleteRequest(
        `${deleteGroupUsersApi}?${queryString.stringify({
          uuid: item.uuid,
          groupId: data.groupId,
        })}`,
      ),
    );

    if (selectedUserGroups.length) {
      await dispatch(
        resourceUpdateRequest(setUserGroupUsersApi, null, {
          uuid: item.uuid,
          userGroupIds: selectedUserGroups.map((c) => c.id),
        }),
      );
    }

    closeModal();
    refetch();
  };
  const handleChange = (values, type) => {
    if (type === 'userGroups') {
      setSelectedUserGroups(values);
    }
  };

  return (
    <MoveGroupUsersModal
      {...{
        item,
        userGroups,
        closeModal,
        selectedUserGroups,
        onSubmit,
        handleChange,
      }}
    />
  );
}

MoveGroupUsersModalContainer.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
};

MoveGroupUsersModalContainer.defaultProps = {
  show: false,
  item: {},
};

export default MoveGroupUsersModalContainer;
