import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';

import { Card, Button, Avatar } from 'components';
import AutoSuggestInput from 'containers/AutoSuggestInput';

export function SelectCompany(props) {
  const {
    user,
    logout,
    onRedirect,
    selectedCompany,
    handleChangeCompany,
    availableCompanies,
  } = props;

  const options = [];

  if (!isEmpty(availableCompanies) && Array.isArray(availableCompanies)) {
    availableCompanies.forEach((item) => {
      options.push({
        id: item.uuid,
        label: `${item.companyName || item.name} - ${item.registrationNumber}`,
      });
    });
  }

  return (
    <Card kind="login" footerCenter>
      <ContentHolder>
        <Avatar user={user} dimensions={100} />
        <UserName>{user && user.name}</UserName>
        <LoggedInMessage>
          {options.length ? (
            <Trans>
              You have no default company. To continue please select company
              from the list:
            </Trans>
          ) : (
            <Trans>
              You have no available companies to login. Please ask an
              organization admin for access
            </Trans>
          )}
        </LoggedInMessage>
        {!!options.length && (
          <AutoSuggestInputStyles
            clearButton
            id="pick-company"
            bsSize="small"
            options={options}
            selected={selectedCompany}
            placeholder={t`Pick company from dropdown...`}
            handleAutoSuggestInputChange={handleChangeCompany}
          />
        )}
        <ButtonHolder>
          <Button
            fill
            wide={isEmpty(options)}
            kind="warning"
            type="button"
            onClick={logout}
          >
            <Trans>Log out</Trans>
          </Button>
          {!isEmpty(options) && (
            <Button
              fill
              wide
              kind="success"
              type="button"
              onClick={onRedirect}
              disabled={isEmpty(selectedCompany)}
            >
              <Trans>Next</Trans>
            </Button>
          )}
        </ButtonHolder>
      </ContentHolder>
    </Card>
  );
}

export default SelectCompany;

const ContentHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ButtonHolder = styled.div`
  margin-bottom: 13px;
  display: flex;
  gap: 10px;
  justify-content: stretch;
`;

const UserName = styled.div`
  width: 80%;
  overflow: hidden;
  font-size: 22px;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LoggedInMessage = styled.div`
  color: #b4b4b4;
  font-size: 15px;
  text-align: center;
  margin: 10px 15px 20px;
`;

const AutoSuggestInputStyles = styled(AutoSuggestInput)`
  width: 280px;
  margin-bottom: 20px;
`;
