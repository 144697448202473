import { t } from '@lingui/macro';

/**
 * Audit Table Columns
 */
export const columns = ({ reportMode }) => [
  {
    width: reportMode ? 100 : 200,
    Header: t`User Name`,
    accessor: 'username',
    className: '',
    withTooltip: true,
  },
  {
    width: reportMode ? 270 : 380,
    Header: t`Event Description`,
    accessor: 'eventDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: reportMode ? 100 : 130,
    Header: t`Audit Date`,
    accessor: 'auditDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'datetime',
    },
  },
];

export default { columns };
