import React, { useEffect, useState } from 'react';
import _uniqBy from 'lodash-es/uniqBy';
import _isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AddEditGroupUserModal } from 'components';

import { fromResource } from 'store/selectors';
import {
  addGroupUserApi,
  editGroupUserApi,
  getGroupParentUserDetailsApi,
} from 'services/apihelpers';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';

function AddEditGroupUserModalContainer(props) {
  const { item, closeModal } = props;
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const data = useSelector((state) =>
    fromResource.getList(state, getGroupParentUserDetailsApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupParentUserDetailsApi}ListRead`),
  );
  let users;

  const onSubmit = async (formData) => {
    const body = {
      name: formData.name,
      updatedUsers: selectedUsers.map((el) => el.id),
    };
    let groupId;

    if (item.groupId) {
      groupId = item.groupId;
      body.groupId = groupId;
      await dispatch(resourceUpdateRequest(editGroupUserApi, null, body));
    } else {
      await dispatch(resourceCreateRequest(addGroupUserApi, body));
    }
    closeModal(false, true);
  };
  const handleChange = (values, type) => {
    if (type === 'user') {
      setSelectedUsers(values);
    }
  };
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getGroupParentUserDetailsApi, {
        groupId: item.groupId,
      }),
    );
  };

  if (_isEmpty(data)) {
    users = [];
  } else {
    users = _uniqBy(
      [...(data.addedUsers || []), ...(data.availableUsers || [])],
      'uuid',
    );
    users = users.map((c) => ({
      id: c.uuid,
      label: c.name,
    }));
  }

  useEffect(fetchData, []);
  useEffect(() => {
    const newUsers = data?.addedUsers
      ? data?.addedUsers.map((c) => ({
          id: c.uuid,
          label: c.name,
        }))
      : [];

    if (JSON.stringify(newUsers) !== JSON.stringify(selectedUsers)) {
      setSelectedUsers(newUsers);
    }
  }, [JSON.stringify(data?.addedUsers)]);

  return (
    <AddEditGroupUserModal
      {...{
        item,
        users,
        loading,
        closeModal,
        selectedUsers,
        onSubmit,
        handleChange,
      }}
    />
  );
}

AddEditGroupUserModalContainer.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.shape({}),
  closeModal: PropTypes.func.isRequired,
};

AddEditGroupUserModalContainer.defaultProps = {
  show: false,
  item: {},
};

export default AddEditGroupUserModalContainer;
