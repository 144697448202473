import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Row, Col } from 'react-bootstrap';

import TemplateSelectionList from 'containers/TemplateSelectionList';
import { Card, Heading, LegendList, TemplateSelector } from 'components';

import { glLegends } from 'constants/legends';

const legends = glLegends();

/**
 * Reconciliation Page component
 */
function ReconciliationPage(props) {
  const {
    params,
    account,
    company,
    history,
    template,
    location,
    roleType,
    accountName,
  } = props;

  return (
    <Row>
      <Col md={12}>
        <Card
          header={
            <Heading level={6} className="card-title">
              <Trans>Navigate to account:</Trans>
            </Heading>
          }
        >
          <TemplateSelectionList {...props} />
        </Card>
        <StyledHeadingWithButton>
          <StyledRightContent>
            {template === 'GENERAL' && <LegendList collection={legends} />}
          </StyledRightContent>
        </StyledHeadingWithButton>
        {template !== 'BANK' && !account && (
          <Heading level={5} className="card-title">
            <Trans>Please select an account</Trans>
          </Heading>
        )}
        <TemplateSelector
          {...{
            params,
            account,
            company,
            history,
            location,
            roleType,
            accountName,
          }}
        />
      </Col>
    </Row>
  );
}

ReconciliationPage.propTypes = {
  /** @type {string} account */
  account: PropTypes.string,
  /** @type {string} accountName */
  accountName: PropTypes.string,
  /** @type {string} account */
  template: PropTypes.string,
  documentRequired: PropTypes.string,
  /** @type handleApproveChange */
  handleApproveChange: PropTypes.func.isRequired,
  /** @type approveStatus */
  approveStatus: PropTypes.bool,
};

ReconciliationPage.defaultProps = {
  account: '',
  accountName: '',
  template: undefined,
  approveStatus: false,
  documentRequired: true,
};

export default ReconciliationPage;

const StyledHeadingWithButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRightContent = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: flex-end;
  padding-left: 15px;
  flex-direction: column;
  justify-content: flex-start;
`;
