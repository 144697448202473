import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pending, rejected, fulfilled } from 'redux-saga-thunk';

import { MapPayrollTransactionModal } from 'components';

import {
  getPayrollOpeningBalanceApi,
  getPayrollReportsTransactionsApi,
  setPayrollReportsTransactionsMapApi,
  getPayrollSettlementsTransactionsApi,
  deletePayrollReportsTransactionsMapApi,
  setPayrollSettlementsTransactionsMapApi,
  deletePayrollSettlementsTransactionsMapApi,
  setPayrollSettlementsOpeningBalanceTransactionsMapApi,
} from 'services/apihelpers';
import { fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

const kinds = {
  total: 'danger',
  mapped: 'success',
  deviation: 'info',
};

function MapPayrollTransactionModalContainer(props) {
  const { row, type, bimonthly, toggleModal, subTabEntry, refetchParent } =
    props;
  const location = useLocation();
  const params = useParams();
  const [refetch, setRefetch] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const getSearch = () => {
    const search = location ? queryString.parse(location.search) : {};

    return {
      activePeriods:
        search.activePeriods || company.currentWorkingPeriodEnd || 12,
    };
  };
  const { activePeriods } = getSearch();
  const [selectedActivePeriods, setSelectedActivePeriods] = useState(
    +activePeriods,
  );
  const query = {
    showMore,
    year: params.accountingYear,
    companyId: params.companyId,
  };
  const openingBalance =
    ['SSTP', 'WTP', 'FTP'].includes(subTabEntry) && row.term === 0;
  let mapUrl;
  let unmapUrl;
  let requestUrl;

  if (type === 'report') {
    mapUrl = setPayrollReportsTransactionsMapApi;
    unmapUrl = deletePayrollReportsTransactionsMapApi;
    requestUrl = getPayrollReportsTransactionsApi;
    query.rowId = row.id;
    query.period = selectedActivePeriods;
  } else {
    unmapUrl = deletePayrollSettlementsTransactionsMapApi;

    if (openingBalance) {
      query.payrollType = subTabEntry;
      query.period = 1;
      mapUrl = setPayrollSettlementsOpeningBalanceTransactionsMapApi;
      requestUrl = getPayrollOpeningBalanceApi;
    } else {
      query.term = row.term;
      query.payrollType = row.settlement.type;
      mapUrl = setPayrollSettlementsTransactionsMapApi;
      requestUrl = getPayrollSettlementsTransactionsApi;
    }
  }

  const data = useSelector((state) =>
    fromResource.getList(state, `${requestUrl}`),
  );
  const failed = useSelector((state) =>
    rejected(state, `${requestUrl}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${requestUrl}ListRead`),
  );
  const mappingFulfilled = useSelector((state) =>
    fulfilled(state, `${mapUrl}Create`),
  );
  const unmappingFulfilled = useSelector((state) =>
    fulfilled(state, `${unmapUrl}Create`),
  );

  const handleShowAll = () => setShowMore(!showMore);
  const fetchData = (extraQuery = {}) => {
    dispatch(resourceListReadRequest(requestUrl, { ...query, ...extraQuery }));
  };
  const handlePeriodChange = (e) => {
    const val = parseInt(e.target.getAttribute('id'), 10);

    setSelectedActivePeriods(val);

    if (showMore) {
      fetchData({ period: val });
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleActionClick = async (item) => {
    const { lineId, mappingDetails } = item;

    if (mappingDetails.mapped) {
      await dispatch(
        resourceCreateRequest(unmapUrl, {
          lineId,
        }),
      );
    } else {
      const requestQuery = {
        lineId,
        year: params.accountingYear,
        companyId: params.companyId,
      };

      if (type === 'report') {
        requestQuery.rowId = row.id;
        requestQuery.period = selectedActivePeriods;
      } else {
        requestQuery.term = row.term;
        requestQuery.payrollType = row.settlement.type;
      }

      await dispatch(resourceCreateRequest(mapUrl, requestQuery));
    }
  };

  const handleAction = async (action, value) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          setRefetch(true);
          await handleActionClick(value);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const getRowColor = (item) => {
    if (item.mappingDetails.mapped) {
      if (item.disabled) return 'warning';
      return 'success';
    }

    return '';
  };

  const getTotalRowColor = (item) => kinds[item.accessor];

  useEffect(fetchData, [
    row.id,
    showMore,
    unmappingFulfilled,
    mappingFulfilled,
  ]);

  const closeModal = () => {
    toggleModal();
    if (refetch && typeof refetchParent === 'function') {
      refetchParent();
    }
  };

  return (
    <MapPayrollTransactionModal
      {...{
        row,
        data,
        type,
        failed,
        loading,
        showMore,
        bimonthly,
        getRowColor,
        handleAction,
        activePeriods,
        openingBalance,
        actionSubmitting,
        selectedActivePeriods,
        handleShowAll,
        getTotalRowColor,
        handleActionClick,
        handlePeriodChange,
        year: company.currentAccountingYear,
      }}
      toggleModal={closeModal}
    />
  );
}

export default MapPayrollTransactionModalContainer;
