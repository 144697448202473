import { t } from '@lingui/macro';

import isIn from 'validator/lib/isIn';
import isInt from 'validator/lib/isInt';
import isURL from 'validator/lib/isURL';
import equals from 'validator/lib/equals';
import isEmail from 'validator/lib/isEmail';
import isFloat from 'validator/lib/isFloat';
import isMobilePhone from 'validator/lib/isMobilePhone';

/* eslint-disable prefer-template */
const passwordRegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}$/;

const isEmpty = (value) =>
  value === undefined || value === null || value === '';
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export const email = (value) =>
  !isEmpty(value) && !isEmail(value) && t`Invalid email address`;

export const url = (value) =>
  !isEmpty(value) && !isURL(value) && t`Invalid URL`;

export const required = (value) => isEmpty(value) && t`Required field`;

export const moreThanZero = (value) =>
  +value < 1 && t`Must be bigger than zero`;

export const notZero = (value) =>
  (value === '0' || value === 0) && t`Value can't be 0`;

export const minLength = (min) => (value) =>
  !isEmpty(value) &&
  value.length < min &&
  t`Must be at least ${min} characters`;

export const maxLength = (max) => (value) =>
  !isEmpty(value) &&
  value.length > max &&
  t`Must be no more than ${max} characters`;

export const integer = (value) =>
  !isEmpty(value) && !isInt(value + '') && t`The value must be a number`;

export const minInt = (min) => (value) =>
  !isEmpty(value) &&
  !isInt(value + '', { min }) &&
  t`The value must be above or equal ${min}`;
export const maxInt = (max) => (value) =>
  !isEmpty(value) &&
  !isInt(value + '', { max }) &&
  t`The value must be below or equal ${max}`;

export const float = (value) =>
  !isEmpty(value) &&
  !isFloat(value + '') &&
  t`The value must be a number (with/without decimals)`;

export const minFloat = (min) => (value) =>
  !isEmpty(value) &&
  !isFloat(value + '', { min }) &&
  t`The value must be minimum or equal ${min}`;

export const maxFloat = (max) => (value) =>
  !isEmpty(value) &&
  !isFloat(value + '', { max }) &&
  t`The value must be maximum or equal ${max}`;

export const oneOf = (values) => (value) =>
  !isIn(value, values) && t`Must be one of: ${values.join(', ')}`;

export const match = (field) => (value, data) =>
  data && value !== data[field] && t`Must match`;

export const passwordPattern = (value) =>
  !isEmpty(value) &&
  (value.length < 8 || !value.match(passwordRegExp)) &&
  // eslint-disable-next-line max-len
  t`Passwords must contain at least 8 characters, including uppercase, lowercase letters, numbers and symbols`;

export const passwordPatternCMA = (value) =>
  !isEmpty(value) &&
  (value.length < 10 || !value.match(passwordRegExp)) &&
  // eslint-disable-next-line max-len
  t`Passwords must contain at least 10 characters, including uppercase, lowercase letters, numbers and symbols`;

export const isMobile = (value) => !isEmpty(value) && isMobilePhone(value);

export const matchPasswordFields = (value) => !equals(value) && t`Not Match`;

export const createValidator =
  (rules) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
