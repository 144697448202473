import { useSelector } from 'react-redux';
import { getUrlWithPredefinedCompany } from 'services/routehelpers';

import { fromCompany } from 'store/selectors';

function RedirectHelperPage(props) {
  const { history, location } = props;
  const company = useSelector(fromCompany.getCompany);

  const newUrls = location.pathname.split('/');
  const insertIndex = 1;
  const insertElements = [
    'client',
    ':companyId?',
    ':accountingYear?',
    ':accountingPeriod?',
  ];

  newUrls.splice(insertIndex, 1, ...insertElements);
  const newUrl = `${newUrls.join('/')}${location.search}`;
  history.push(getUrlWithPredefinedCompany(newUrl, company));

  return false;
}

export default RedirectHelperPage;
