import { t } from '@lingui/macro';

import { periodTypes } from 'services/shapes';
import { trialBalanceReportColumns } from 'services/tableshapes';
import useReportGenerator from './generator/useReportGenerator';

export const getTrialBalanceReportSettings = ({
  data,
  loading,
  company,
  orgNumber,
  frequency,
  accountingYear,
  accountingPeriod = 12,
}) => {
  const frequencyWithLabel = periodTypes().find(
    (item) => item.id === frequency,
  );

  const tableState = {
    globalFilter: { periodTypeCustom: [frequencyWithLabel] },
  };
  const columns = trialBalanceReportColumns({ tableState });

  return {
    loading,
    landscape: true,
    folderName: t`trial balance`,
    // eslint-disable-next-line no-undef,max-len
    fileName: t`${company} (${orgNumber}) Trial Balance - ${accountingYear}, ${tableState?.globalFilter?.periodTypeCustom?.[0]?.label}`,
    data: [
      [
        {
          period: company,
          withoutStatus: true,
          rows: data,
          withTotal: true,
          // eslint-disable-next-line max-len
          title: t`Trial Balance - ${accountingYear}, ${frequencyWithLabel?.label}`,
          tableShape: columns,
        },
      ],
    ],
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      reportType: 'TRIAL',
    },
  };
};

export const useTrialBalanceReportGenerator = (props) =>
  useReportGenerator(getTrialBalanceReportSettings(props));

export default useTrialBalanceReportGenerator;
