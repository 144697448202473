import React, { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigureMFAModal } from 'components';

import { fromAuth, fromResource } from 'store/selectors';
import {
  resourceUpdateRequest,
  resourceListReadRequest,
  updateCurrentUserSuccess,
} from 'store/actions';
import {
  sendMFAPinApi,
  checkMFAPinApi,
  setMFASettingsApi,
  checkMFAStillValidApi,
  setMFAOrganizationSettingsApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export function ConfigureMFAModalContainer(props) {
  const { type, closeModal, organization } = props;
  const dispatch = useDispatch();
  const user = useSelector(fromAuth.getUser);
  const [step, setStep] = useState(0);
  const item = useSelector((state) =>
    fromResource.getList(state, checkMFAStillValidApi),
  );
  const resendCode = async () => {
    try {
      await dispatch(resourceListReadRequest(sendMFAPinApi));
      setStep(1);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };
  const onSubmit = async (formData) => {
    switch (step) {
      case 0: {
        await resendCode();
        break;
      }
      case 1: {
        try {
          await dispatch(
            resourceListReadRequest(checkMFAPinApi, { token: formData.token }),
          );
          setStep(2);
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        break;
      }
      case 2: {
        try {
          let urlToCall = setMFASettingsApi;

          if (type === 'organization') {
            urlToCall = setMFAOrganizationSettingsApi;
            // eslint-disable-next-line no-param-reassign
            formData.organization = organization.organizationSID;
          }

          if (typeof formData.mfa === 'string') {
            // eslint-disable-next-line no-param-reassign
            formData.mfa = formData.mfa === 'true';
          }

          await dispatch(resourceUpdateRequest(urlToCall, null, formData));

          if (type === 'user') {
            dispatch(
              updateCurrentUserSuccess({
                ...user,
                mfa: formData.mfa,
                frequency: formData.frequency,
              }),
            );
          }

          closeModal(null, true);
        } catch (e) {
          toast.error(getErrorMessage(e));
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  const options = [
    {
      value: 'ALWAYS',
      label: t`Always`,
    },
    {
      value: 'DAILY',
      label: t`Daily`,
    },
    {
      value: 'WEEKLY',
      label: t`Weekly`,
    },
    {
      value: 'MONTHLY',
      label: t`Monthly`,
    },
    {
      value: 'NEVER',
      label: t`Never`,
    },
  ];

  if (type === 'organization') {
    item.mfa = organization?.mfa;
    item.frequency = organization?.frequency;
  } else if (user?.organization?.mfa) {
    item.mfa = user?.organizationMfa;
    item.frequency = user?.organizationFrequency;
  }

  useEffect(() => {
    dispatch(resourceListReadRequest(checkMFAStillValidApi));
  }, []);

  useEffect(() => {
    if (item.isValid) {
      setStep(2);
    }
  }, [item.isValid]);

  return (
    <ConfigureMFAModal
      {...{
        type,
        item,
        step,
        user,
        options,
        onSubmit,
        resendCode,
        closeModal,
      }}
    />
  );
}

ConfigureMFAModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ConfigureMFAModalContainer;
