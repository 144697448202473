import React from 'react';
import { t } from '@lingui/macro';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FaCogs } from '@react-icons/all-files/fa/FaCogs';
import { FaReceipt } from '@react-icons/all-files/fa/FaReceipt';
import { GoSettings } from '@react-icons/all-files/go/GoSettings';
import { BsBriefcaseFill } from '@react-icons/all-files/bs/BsBriefcaseFill';

import { SidebarNavItem, SidebarNavDropdown } from 'components';

import { fromCompany } from 'store/selectors';

import {
  toAudit,
  toFiles,
  toAssets,
  toDashboard,
  toGroupSettings,
  toGeneralSettings,
  toVATReconciliation,
  toAdminUserSettings,
  toReportsTrialBalance,
  toOrganisationSettings,
  toAdminCompanySettings,
  toReportsAccountDetails,
  toAccountReconciliation,
  toPayrollReconciliation,
  toReconciliationSettings,
} from 'services/routehelpers';

function SidebarNav(props) {
  const { roleType, handleSelect } = props;
  const company = useSelector(fromCompany.getCompany);
  const { companySettings } = company || {};

  const auditTitle = t`Audit`;
  const filesTitle = t`Files`;
  const groupTitle = t`Group`;
  const assetsTitle = t`Assets`;
  const overviewTitle = t`Overview`;
  const trialBalanceTitle = t`Trial Balance`;
  const userSettingsTitle = t`User`;
  const accountDetailsTitle = t`Account Details`;
  const companySettingsTitle = t`Company`;
  const generalSettingsTitle = t`General`;
  const organizationAdminTitle = t`Organization`;
  const VATReconciliationTitle = t`VAT`;
  const accountReconciliationTitle = t`Account`;
  const payrollReconciliationTitle = t`Payroll`;
  const reconciliationSettingsTitle = t`Reconciliation`;

  /**
   * Show Settings/Reconciliation menu if roleType.updater is true, or if roleType.isReadOnly is false
   */
  const showReconciliationMenu =
    !roleType.roles.includes('FINANCIAL_MANAGER') ||
    !roleType.roles.includes('AUDITOR');

  return (
    <Nav onSelect={handleSelect}>
      <SidebarNavItem
        to={toDashboard()}
        icon={<GoSettings />}
        eventKey={1}
        shortTitle={overviewTitle.charAt(0).toUpperCase()}
      >
        {t`Dashboard`}
      </SidebarNavItem>
      <SidebarNavDropdown
        expanded
        icon={<FaReceipt />}
        title={t`Reconciliation`}
        eventKey={2}
        data-menu="reconciliation-menu"
      >
        <SidebarNavItem
          to={toAccountReconciliation()}
          eventKey={2.1}
          shortTitle={accountReconciliationTitle.charAt(0).toUpperCase()}
        >
          {accountReconciliationTitle}
        </SidebarNavItem>
        {companySettings?.payrollEnabled && (
          <SidebarNavItem
            to={toPayrollReconciliation()}
            eventKey={2.3}
            shortTitle={payrollReconciliationTitle.charAt(0).toUpperCase()}
          >
            {payrollReconciliationTitle}
          </SidebarNavItem>
        )}
        {companySettings?.vatEnabled && (
          <SidebarNavItem
            to={toVATReconciliation()}
            eventKey={2.4}
            shortTitle={VATReconciliationTitle.charAt(0).toUpperCase()}
          >
            {VATReconciliationTitle}
          </SidebarNavItem>
        )}
        {companySettings?.assetsEnabled && (
          <SidebarNavItem
            to={toAssets()}
            eventKey={2.4}
            shortTitle={assetsTitle.charAt(0).toUpperCase()}
          >
            {assetsTitle}
          </SidebarNavItem>
        )}
      </SidebarNavDropdown>
      <SidebarNavDropdown
        icon={<FaCogs />}
        title={t`Settings & Admin`}
        eventKey={4}
        data-menu="settings-menu"
      >
        <SidebarNavItem
          to={toGeneralSettings()}
          eventKey={4.1}
          shortTitle={generalSettingsTitle.charAt(0).toUpperCase()}
        >
          {generalSettingsTitle}
        </SidebarNavItem>
        {showReconciliationMenu && (
          <SidebarNavItem
            to={toReconciliationSettings()}
            eventKey={4.2}
            shortTitle={reconciliationSettingsTitle.charAt(0).toUpperCase()}
          >
            {reconciliationSettingsTitle}
          </SidebarNavItem>
        )}
        {roleType.isAdmin && (
          <SidebarNavItem
            to={toAdminCompanySettings()}
            eventKey={4.3}
            shortTitle={companySettingsTitle.charAt(0).toUpperCase()}
          >
            {companySettingsTitle}
          </SidebarNavItem>
        )}
        {roleType.isAdmin && (
          <SidebarNavItem
            to={toAdminUserSettings()}
            eventKey={4.4}
            shortTitle={userSettingsTitle.charAt(0).toUpperCase()}
          >
            {userSettingsTitle}
          </SidebarNavItem>
        )}
        {roleType.isAdmin && (
          <SidebarNavItem
            to={toOrganisationSettings()}
            eventKey={4.5}
            shortTitle={organizationAdminTitle.charAt(0).toUpperCase()}
          >
            {organizationAdminTitle}
          </SidebarNavItem>
        )}
        {roleType.isAdmin && (
          <SidebarNavItem
            to={toGroupSettings()}
            eventKey={4.6}
            shortTitle={groupTitle.charAt(0).toUpperCase()}
          >
            {groupTitle}
          </SidebarNavItem>
        )}
      </SidebarNavDropdown>
      <SidebarNavDropdown
        icon={<BsBriefcaseFill />}
        title={t`Reports`}
        eventKey={5}
        data-menu="reports-menu"
      >
        <SidebarNavItem
          to={toReportsTrialBalance()}
          eventKey={5.1}
          shortTitle={trialBalanceTitle.charAt(0).toUpperCase()}
        >
          {trialBalanceTitle}
        </SidebarNavItem>
        <SidebarNavItem
          to={toReportsAccountDetails()}
          eventKey={5.2}
          shortTitle={accountDetailsTitle.charAt(0).toUpperCase()}
        >
          {accountDetailsTitle}
        </SidebarNavItem>
        <SidebarNavItem
          to={toAudit()}
          eventKey={5.4}
          shortTitle={auditTitle.charAt(0).toUpperCase()}
        >
          {auditTitle}
        </SidebarNavItem>
        <SidebarNavItem
          to={toFiles()}
          eventKey={5.5}
          shortTitle={filesTitle.charAt(0).toUpperCase()}
        >
          {filesTitle}
        </SidebarNavItem>
      </SidebarNavDropdown>
    </Nav>
  );
}

SidebarNav.propTypes = {
  roleType: PropTypes.shape({}).isRequired,
  handleSelect: PropTypes.func.isRequired,
  companySettings: PropTypes.shape({}),
};

SidebarNav.defaultProps = {
  companySettings: {},
};

export default SidebarNav;
