import React from 'react';

import AdminCompanySettingsTable from 'containers/AdminCompanySettingsTable';

/**
 * Admin Company Settings component
 */
function AdminCompanySettingsPage(props) {
  return <AdminCompanySettingsTable {...props} />;
}

export default AdminCompanySettingsPage;
