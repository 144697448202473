import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

const possibleTypes = () => [t`base`, t`ingoingTax`, t`outgoingTax`]; // eslint-disable-line

export const columns = () => [
  {
    width: 60,
    Header: t`VAT Code`,
    accessor: 'vatCode',
    className: 'flex-right',
    disableSortBy: true,
  },
  {
    width: 40,
    Header: t`Date`,
    accessor: 'reconciliationDate',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'date',
    },
  },
  {
    width: 80,
    Header: t`Line ID`,
    accessor: 'lineId',
    className: 'flex-right',
    disableSortBy: true,
  },
  {
    width: 60,
    Header: t`Account ID`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
    disableSortBy: true,
  },
  {
    width: 100,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 140,
    Header: t`Line Type`,
    accessor: 'type',
    className: '',
    disableSortBy: true,
    cell: {
      converter: (value) =>
        value !== 'headerLine'
          ? t({
              id: value,
              message: value,
            })
          : '',
    },
  },
  {
    width: 80,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'deleteCommittedReconciliation',
          icon: () => <BiTrash />,
          tooltip: t`Delete`,
          visible: (row) => row.type === 'headerLine',
        },
      ],
    },
  },
];

export default { columns };
