import { t } from '@lingui/macro';
import { defineRoles } from 'services/helpers';

export const AssociatedCompanyPreviewGroupsColumns = ({ reportMode }) => [
  {
    width: reportMode ? 240 : 360,
    Header: t`Group ID`,
    accessor: 'groupId',
    sortType: 'basic',
    className: '',
  },
  {
    width: reportMode ? 380 : 600,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: true,
  },
];

export const AvailableUsersPreviewColumns = ({ reportMode }) => [
  {
    width: reportMode ? 240 : 330,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: true,
  },
  {
    width: reportMode ? 240 : 290,
    Header: t`Role`,
    accessor: 'role',
    className: '',
    cell: {
      converter: (value) => defineRoles([value]),
    },
  },
  {
    width: reportMode ? 240 : 330,
    Header: t`Username`,
    accessor: 'username',
    className: '',
    withTooltip: true,
  },
];

export default {
  AvailableUsersPreviewColumns,
  AssociatedCompanyPreviewGroupsColumns,
};
