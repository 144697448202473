import React from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs, Card } from 'components';
import PayrollSettingsMapping from 'containers/PayrollSettingsMapping';
import PayrollSettingsPaymentTax from 'containers/PayrollSettingsPaymentTax';

import { toReconciliationSettings } from 'services/routehelpers';

/**
 * Payroll Settings Page Component
 */

function PayrollSettingsPage(props) {
  const { location, handleTabSelect } = props;
  const { tabEntry = 'payroll-settings', subTabEntry = 'mapping' } =
    queryString.parse(location.search);
  const onTabSelect = (key) =>
    handleTabSelect(key, () =>
      toReconciliationSettings({ tabEntry, subTabEntry: key }),
    );

  const tabs = [
    {
      title: t`Mapping`,
      eventKey: 'mapping',
      component: <PayrollSettingsMapping {...props} />,
    },
    {
      title: t`Payment Tax`,
      eventKey: 'payment',
      component: <PayrollSettingsPaymentTax {...props} />,
    },
  ];

  return (
    <Card>
      <Tabs tabs={tabs} activeKey={subTabEntry} onSelect={onTabSelect} />
    </Card>
  );
}

export default PayrollSettingsPage;
