import { t } from '@lingui/macro';

import { viewFinishedAccrualsOptions } from 'services/shapes';
import {
  originIDConverter,
  originAmountConvert,
  partialReconciliationIdConverter,
} from 'services/tableshapes/GeneralTemplateAccrualGrid';

export const generalTemplateAccrualsFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'description',
      'reconciliationId',
      'partialReconciliationId',
      originIDConverter,
      originAmountConvert,
      partialReconciliationIdConverter,
    ],
  },
  {
    id: 'estimatedRemainingMonths',
    type: 'multiSelect',
    label: t`Finished accruals`,
    options: viewFinishedAccrualsOptions(),
    disableSort: true,
    placeholder: t`Please select Finished accruals`,
  },
];

export default generalTemplateAccrualsFilters;
