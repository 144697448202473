import React, { useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import queryString from 'query-string';

import {
  getLatestReportApi,
  getAccrualsApi,
  getFileListApi,
  periodCommentApi,
  deleteCommentApi,
  accountsControlApi,
  addAccountCommentApi,
  editCurrentCommentApi,
  saveAccountUserCommentApi,
  getTransactionDialogueApi,
  getAccountDialogueListApi,
  updateTransactionCommentApi,
  updateAccountApproveStatusApi,
  approveZeroMovementAccountsApi,
  getGeneralLedgerTransactionApi,
  getMultipleAccountsDrilldownsApi,
  getTransactionSummaryTemplateApi,
  updateTransactionCommentCreateApi,
  getReconciliationGLAccountsFastApi,
  getBankReconcileDataApi,
  getVATOverviewApi,
  getVATSettlementsTotalApi,
  getVATSettlementsDeviationApi,
  getVatDetailsNewer,
  getPayrollSettlementsApi,
  getPayrollReportedSalary,
  payRollReportApi,
  getReportedSalaryDetails,
  getPayrollSocialSecurityApi,
  getVacationPayGLBaseApi,
  getVacationPayCalculationApi,
  getPayrollMappableReportsRowsApi,
  isPayrollApprovedApi,
  getFilesFromTransactionLevel,
  getAssetsApi,
  getAssetReconciliationApi,
  getTrialBalanceApi,
  downloadReportApi,
} from 'services/apihelpers';
import { AccountReconciliationTable } from 'components';

import { reportPackageDefaultValues } from 'constants/reportSettings';

import CommentsModal from 'containers/CommentsModal';
import FileUploadModal from 'containers/FileUploadModal';
import CommentsHistoryModal from 'containers/CommentsHistoryModal';
import ShowReportVersionsModal from 'containers/ShowReportVersionsModal';

// eslint-disable-next-line max-len
import generateMultipleReport from 'services/hooks/reports/generateMultipleReport';
import { resourceListReadSuccess } from 'store/resource/actions';
import { useAccountOverviewData } from 'services/hooks/accounts';
import { fromCompany, fromAuth } from 'store/selectors';
import {
  resourceUpdateRequest,
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  commentsRouteInitializer,
  defineFrequencyPeriodTypes,
  downloadFile,
} from 'services/helpers';
import {
  toReconciliation,
  toVATReconciliation,
  toPayrollReconciliation,
  toOpeningBalanceRegistration,
} from 'services/routehelpers';

import {
  bankTemplateDataFormatter,
  simpleLedgerDataFormatter,
} from 'services/dataFormatters/accounts';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  generateBankReport,
  generateGLReport,
  generateSLReport,
  generateAccountOverviewReport,
} from 'services/hooks/reports';

const isRowDisabled = (item) =>
  !item.approved && item.documentRequired && !item.hasFile && !item.hasDialogue;

function AccountReconciliationTableContainer(props) {
  const { roleType, match, location } = props;
  const { params } = match;
  const { companyId, accountingYear, accountingPeriod } = params;

  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeactived, setShowDeactived] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [_filters, setFilters] = useState(() => reportPackageDefaultValues);

  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const { name: username, languageCode } = user;
  const {
    periodType,
    currentCompanySID,
    currentCompanyRegistrationNumber,
    currentCompanyName,
    currentPeriodType,
    companySettings,
  } = company;
  const { payrollEnabled, vatEnabled, assetsEnabled } = companySettings || {};

  const { data, preparedData, loading, loadTableData, fetchReportsInfo } =
    useAccountOverviewData({
      user,
      companyId,
      periodType,
      showDeactived,
      accountingYear,
      accountingPeriod,
    });

  const search = useMemo(() => {
    const _search = queryString.parse(location.search, {
      parseBooleans: true,
    });

    return {
      filterByApprove: _search.filterByApprove,
      filterByTemplate: _search.filterByTemplate,
    };
  }, [JSON.stringify(location.search)]);

  const updateReduxResourceResponse = (resource, payload) =>
    dispatch(resourceListReadSuccess(resource, payload));

  const toggleModal = (value = {}) => {
    setModalProps(value);
  };

  const renderModal = () => {
    const { row, type } = modalProps;

    let content = false;

    const handleToggleModal = (_, enabled, account, files) => {
      let key = 'hasDialogue';

      if (type === 'files') {
        key = 'hasFile';

        const collection = data.map((item) => {
          const el = { ...item };

          if (account === item.accountId) {
            el.accountFileNames = files.map(({ fileName }) => fileName);
          }

          return el;
        });

        updateReduxResourceResponse(
          getReconciliationGLAccountsFastApi,
          collection,
        );
      }

      toggleModal({});

      if (typeof enabled === 'boolean') {
        const collection = data.map((item) => {
          const el = { ...item };

          if (row.accountId === item.accountId) {
            el[key] = enabled;
          }

          return el;
        });

        updateReduxResourceResponse(
          getReconciliationGLAccountsFastApi,
          collection,
        );
      }
    };

    switch (type) {
      case 'dialog': {
        content = (
          <CommentsModal
            {...{
              user,
              company,
              roleType,
              showModal: true,
              toggleModal: handleToggleModal,
              componentSetupValues: {
                recordId: row.accountId,
                commentModalType: 'accountLevelComment',
                commentsRoutes: commentsRouteInitializer(
                  'accountLevelComment',
                  {
                    commentsRoutes: {
                      deleteCommentApi,
                      addAccountCommentApi,
                      editCurrentCommentApi,
                      getTransactionDialogueApi,
                      getAccountDialogueListApi,
                      updateTransactionCommentApi,
                      updateTransactionCommentCreateApi,
                    },
                  },
                ),
              },
            }}
          />
        );
        break;
      }

      case 'files': {
        content = (
          <FileUploadModal
            {...{
              user,
              company,
              roleType,
              account: row.accountId,
              showModal: true,
              toggleModal: handleToggleModal,
              fileCategory: 'ACCOUNT',
            }}
          />
        );
        break;
      }

      case 'history': {
        content = (
          <CommentsHistoryModal
            row={row}
            company={company}
            params={params}
            isReadOnly={roleType?.isReadOnly}
            toggleModal={handleToggleModal}
            loadMainData={loadTableData}
          />
        );
        break;
      }

      case 'singleAccountReportVersions': {
        content = (
          <ShowReportVersionsModal
            reportType="ACCOUNTS"
            accountId={row.accountId}
            accountTitle={row.name}
            closeModal={toggleModal}
            accountingPeriod={accountingPeriod}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  const getRowColor = (row, isReport) => {
    const { accountId } = row;

    const isSelected = selectedItems.filter(
      (item) => item.accountId === accountId,
    );
    const requiresFiles = isRowDisabled(row);

    if (isSelected.length && requiresFiles && !isReport) {
      return 'warning';
    }

    if (!row.activeRecord) {
      return 'darkgray';
    }

    return '';
  };

  const selectCondition = (row) =>
    !['PAYROLL', 'VAT', 'ASSET'].includes(row.accountTemplateCode) &&
    row.activeRecord;

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting((state) => ({
      ...state.actionSubmitting,
      [action]: submitting,
    }));
  };

  React.useEffect(() => {
    function handleBeforeUnload(e) {
      if (Object.values(actionSubmitting).some((value) => value)) {
        // eslint-disable-next-line max-len
        const confirmationMessage = t`If you leave this page, you will lose some of your work. Are you sure you want to leave?`;
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return false;
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [actionSubmitting]);

  const handleRowClick = (row) => {
    let pushTo;

    if (row.accountTemplateCode === 'PAYROLL') {
      pushTo = toPayrollReconciliation({ bimonthly: true });
    } else if (row.accountTemplateCode === 'VAT') {
      pushTo = toVATReconciliation();
    } else {
      pushTo = toReconciliation({
        account: row.accountId,
      });
    }

    history.push(pushTo);
  };

  const handleRowSelect = (items) => {
    // Unselect all
    if (items.item === 'all') {
      setSelectedItems([]);

      // Select all
    } else if (Array.isArray(items.item)) {
      setSelectedItems(items.item);

      // Select/Unselect single
    } else {
      const existed = selectedItems.filter(
        (item) => item.accountId === items.item.accountId,
      );

      if (existed.length) {
        setSelectedItems((state) =>
          state.filter((item) => item.accountId !== items.item.accountId),
        );
      } else {
        setSelectedItems((state) => [...state, items.item]);
      }
    }
  };

  // eslint-disable-next-line default-param-last
  const _getFiles = async (item, options = {}, isGL) => {
    const filesList = [];

    const files = await dispatch(
      resourceListReadRequest(getFileListApi, {
        account: item.accountId,
        toPeriod: accountingPeriod,
        companyId: company.currentCompanySID,
        fromPeriod: 1,
        showAll: false,
        periodType: company.currentPeriodType,
        periodYear: accountingYear,
        fileCategory: 'ACCOUNT',
        ...options,
      }),
    );

    if (files?.transactionFileList) {
      filesList.push(
        ...files.transactionFileList.map((f) => ({
          key: f.fileKey,
          name: f.fileName,
          folderName: t`attachments`,
        })),
      );
    }

    if (isGL) {
      const transactionFiles = await dispatch(
        resourceCreateRequest(
          // eslint-disable-next-line max-len
          `${getFilesFromTransactionLevel}?companyId=${companyId}&year=${accountingYear}&period=${accountingPeriod}`,
          {
            accountIds: [
              {
                accountId: item.accountId,
              },
            ],
          },
        ),
      );

      if (transactionFiles?.length) {
        filesList.push(
          ...transactionFiles[0].fileNamesAndKeys.map((file) => ({
            name: file.fileName,
            key: file.fileKey,
            // eslint-disable-next-line max-len
            folderName: t`attachments/${transactionFiles[0].lineId}`,
          })),
        );
      }
    }

    return filesList;
  };

  const downloadBank = async (item) => {
    const { accountId, name, skipDownload, skipToast, saveReport } = item;

    try {
      const bankData = await dispatch(
        resourceListReadRequest(`${getBankReconcileDataApi}/${companyId}`, {
          accountId,
          year: accountingYear,
          toPeriod: accountingPeriod,
          fromPeriod: 1,
        }),
      );
      let glTotal = 0;
      let bankTotal = 0;

      const setGlTotal = (value) => {
        glTotal = value;
      };

      const setBankTotal = (value) => {
        bankTotal = value;
      };
      const { dataGL, dataBank } = bankTemplateDataFormatter({
        data: bankData,
        setGlTotal,
        setBankTotal,
        accountingPeriod,
      });
      await generateBankReport({
        dataGL,
        dataBank,
        glTotal,
        bankTotal,
        accountingYear,
        accountingPeriod,
        bookedBalance: bankData?.bookedBalance,
        accountInfo: item,
        approveStatus: item.approved,
        currentAccount: { accountId, name },
        getFiles: async () => _getFiles(item),
        currentPeriodType: company.currentPeriodType,
        companySID: company?.currentCompanySID,
        companyUUID: company?.uuid,
        username,
        company: currentCompanyName,
        orgNumber: currentCompanyRegistrationNumber,
        skipDownload,
        saveReport,
      });
      if (!skipToast) {
        await fetchReportsInfo();
        toast.success(t`Report generated successfully`);
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const downloadGeneralLedger = async (item) => {
    try {
      const reconcileData = await dispatch(
        resourceListReadRequest(getGeneralLedgerTransactionApi, {
          companyId,
          year: accountingYear,
          account: item.accountId,
          toPeriod: accountingPeriod,
        }),
      );
      const accrualsData = await dispatch(
        resourceListReadRequest(getAccrualsApi, {
          companyId,
          year: accountingYear,
          period: accountingPeriod,
          accountId: item.accountId,
        }),
      );

      await generateGLReport({
        data: reconcileData,
        accrualsData,
        accountingYear,
        accountingPeriod,
        username: user.name,
        companySID: company?.currentCompanySID,
        companyUUID: company?.uuid,
        currentAccount: item,
        accountInfo: item,
        approveStatus: item.approved,
        getFiles: async () => _getFiles(item, {}, true),
        company: company.currentCompanyName,
        currentPeriodType: company.currentPeriodType,
        orgNumber: company.currentCompanyRegistrationNumber,
        skipDownload: item.skipDownload,
        saveReport: true,
      });
      if (!item.skipToast) {
        await fetchReportsInfo();
        toast.success(t`Report generated successfully`);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const downloadSimpleLedger = async (item) => {
    try {
      const rows = await dispatch(
        resourceListReadRequest(getTransactionSummaryTemplateApi, {
          account: item.accountId,
          year: accountingYear,
          toPeriod: accountingPeriod,
          companyId: company.currentCompanySID,
          periodType: company.currentPeriodType,
          fromPeriod: 1,
        }),
      );
      await generateSLReport({
        rows: simpleLedgerDataFormatter(rows, []),
        account: item.accountId,
        getFiles: async () => _getFiles(item),
        accountName: item.name,
        approveStatus: item.approved,
        accountingYear,
        accountingPeriod,
        accountInfo: item,
        orgNumber: company.currentCompanyRegistrationNumber,
        company: company.currentCompanyName,
        username,
        companySID: company?.currentCompanySID,
        companyUUID: company?.uuid,
        currentPeriodType: company.currentPeriodType,
        skipDownload: item.skipDownload,
      });
      if (!item.skipToast) {
        await fetchReportsInfo();
        toast.success(t`Report generated successfully`);
      }
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const downloadPDF = async (item, latest, download = true) => {
    if (latest) {
      const query = {
        companyUUID: companyId,
        accountingYear,
        accountingPeriod,
        accountId: [item.accountId],
        reportType: ['ACCOUNTS'],
      };

      const latestReport = await dispatch(
        resourceListReadRequest(getLatestReportApi, query),
      );

      if (latestReport?.ACCOUNTS?.length) {
        const { storageLocation, version } = latestReport.ACCOUNTS[0];
        await downloadFile({
          /* eslint-disable max-len */
          url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
          name: t`${item.accountId} - ${item.name} (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`,
        });
      } else {
        toast.error('No report found');
      }
      return;
    }

    if (item.accountTemplateCode === 'BANK') {
      await downloadBank({ ...item, skipDownload: !download });
    } else if (item.accountTemplateCode === 'GENERAL') {
      await downloadGeneralLedger({ ...item, skipDownload: !download });
    } else if (item.accountTemplateCode === 'SIMPLE_LEDGER') {
      await downloadSimpleLedger({ ...item, skipDownload: !download });
    }
  };

  const downloadLastPackage = async () => {
    const query = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['REPORT_PACKAGE'],
    };

    const latestReport = await dispatch(
      resourceListReadRequest(getLatestReportApi, query),
    );

    if (latestReport?.REPORT_PACKAGE?.length) {
      const { storageLocation, version } = latestReport.REPORT_PACKAGE[0];
      await downloadFile({
        /* eslint-disable max-len */
        url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
        name: t`${company.currentCompanyName} (${company.currentCompanyRegistrationNumber}) - Complete Package (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`,
      });
    } else {
      toast.error('No report found');
    }
  };

  const handleBalanceRegistration = (row) => {
    history.push(toOpeningBalanceRegistration({ account: row.accountId }));
  };

  const handleApproveZeroMovement = async () => {
    const query = {
      year: company.currentAccountingYear,
      period: defineFrequencyPeriodTypes(
        company?.currentPeriodType,
        company?.currentWorkingPeriodEnd || 12,
      ),
      companyId: company.currentCompanySID,
      periodType: company.currentPeriodType.toUpperCase(),
    };

    await dispatch(
      resourceCreateRequest(approveZeroMovementAccountsApi, query),
    );
    loadTableData();
  };

  const handleApprove = async (approved, selectedRows) => {
    _setActionSubmitting(approved ? 'approve' : 'unApprove', true);
    let documentsRequired = false;

    const accounts = selectedRows.map((row) => {
      if (isRowDisabled(row)) {
        documentsRequired = true;
      }

      return {
        accountId: row.accountId,
        accountStatus: approved,
      };
    });

    if (!documentsRequired) {
      const query = {
        accounts,
        periodTo: defineFrequencyPeriodTypes(
          company?.periodType,
          +company.currentWorkingPeriodEnd,
        ),
        companyId: company.currentCompanySID,
        periodFrom: company.currentWorkingPeriodStart,
        periodType: company.currentPeriodType.toUpperCase(),
        periodYear: company.currentAccountingYear,
      };

      await dispatch(
        resourceCreateRequest(updateAccountApproveStatusApi, query),
      );

      const collection = data.map((item) => {
        const el = { ...item };
        const row = selectedRows.find((r) => r.accountId === item.accountId);

        if (row) {
          // eslint-disable-next-line no-param-reassign
          el.approved = approved;
        }

        return el;
      });

      // Create a report on approval
      if (approved) {
        const reporting = selectedRows.map((row) => {
          if (row.accountTemplateCode === 'BANK') {
            return new Promise((resolve) => {
              downloadBank({
                ...row,
                approved: true,
                skipDownload: true,

                skipToast: true,
              }).then(() => resolve());
            });
          }
          if (row.accountTemplateCode === 'GENERAL') {
            return new Promise((resolve) => {
              downloadGeneralLedger({
                ...row,
                approved: true,
                skipDownload: true,
                skipToast: true,
              }).then(() => resolve());
            });
          }
          if (row.accountTemplateCode === 'SIMPLE_LEDGER') {
            return new Promise((resolve) => {
              downloadSimpleLedger({
                ...row,
                approved: true,
                skipDownload: true,
                skipToast: true,
              }).then(() => resolve());
            });
          }
          return null;
        });

        await Promise.all(reporting)
          .then(async () => {
            await fetchReportsInfo();
            toast.success(t`Reports generated successfully`);
          })
          .catch((e) => {
            toast.error(e.message);
          })
          .finally(() => {
            updateReduxResourceResponse(
              getReconciliationGLAccountsFastApi,
              collection,
            );
            _setActionSubmitting(approved ? 'approve' : 'unApprove', false);
          });
      }
    }
  };

  const updateRowsInRedux = (accountId, fields) => {
    const newData = data.map((row) => {
      if (row.accountId === accountId) {
        return { ...row, ...fields };
      }
      return row;
    });

    updateReduxResourceResponse(getReconciliationGLAccountsFastApi, newData);
  };

  const handleAccountCommentChange = async (comment, row, id) => {
    if (comment !== row[id]) {
      _setActionSubmitting(row.accountId, true);

      // Update status comment
      if (id === 'comment') {
        await dispatch(
          resourceCreateRequest(saveAccountUserCommentApi, {
            year: company.currentAccountingYear,
            companyId: company.currentCompanySID,
            userComment: comment,
            generalLedgerAccountSID: row.generalLedgerAccountId,
          }),
        );

        updateRowsInRedux(row.accountId, { comment });
        // Update period comment
      } else if (id === 'periodComment') {
        const query = {
          comment,
          companyId,
          periodType: currentPeriodType.toUpperCase(),
          period: defineFrequencyPeriodTypes(
            currentPeriodType,
            +accountingPeriod,
          ),
          accountId: row.accountId,
          year: +accountingYear,
        };

        // If period comment for this company with current period and year already exist
        if (row?.periodComment) {
          // If period comment field is empty remove it from DB
          if (!comment) {
            // Delete period comment
            await dispatch(
              resourceDeleteRequest(
                `${periodCommentApi}?id=${row.periodCommentId}`,
              ),
            );

            updateRowsInRedux(row.accountId, {
              periodComment: null,
              periodCommentId: null,
            });
          } else {
            // Change period comment
            await dispatch(
              resourceUpdateRequest(periodCommentApi, null, {
                comment,
                id: row.periodCommentId,
              }),
            );

            updateRowsInRedux(row.accountId, { periodComment: comment });
          }
          // Case if period comment not exist in DB yet and field isn't empty
        } else if (comment) {
          // Create period comment
          const newPeriodComment = await dispatch(
            resourceCreateRequest(periodCommentApi, query),
          );

          updateRowsInRedux(row.accountId, {
            periodCommentId: newPeriodComment.id,
            periodComment: comment,
          });
        }
      }

      _setActionSubmitting(row.accountId, false);
    }
  };

  const handleControl = async (item, uncontrol) => {
    const accountIds = Array.isArray(item) && item.map((i) => i.accountId);
    const _periodType = currentPeriodType.toUpperCase();

    const query = {
      companyId,
      accountIds,
      year: +accountingYear,
      periodType: _periodType === 'HALFYEARLY' ? 'HALF_YEARLY' : _periodType,
      period: defineFrequencyPeriodTypes(currentPeriodType, +accountingPeriod),
    };

    try {
      if (uncontrol) {
        const enableIds = item?.enabledForUncontrol?.map((i) => i.id);
        const deleteQuery = enableIds?.map((i) => `controlIds=${i}`)?.join('&');

        _setActionSubmitting(item.accountId, true);

        await dispatch(
          resourceDeleteRequest(`${accountsControlApi}?${deleteQuery}`),
        );

        const newControlList = item.accountControl.list.filter(
          (i) => !enableIds.includes(i.id),
        );
        const newAccountControl = newControlList?.length
          ? { ...item.accountControl, list: newControlList }
          : null;
        updateRowsInRedux(item.accountId, {
          accountControl: newAccountControl,
        });

        _setActionSubmitting(item.accountId, false);
      } else {
        setActionSubmitting((state) => ({
          ...state,
          ...accountIds.reduce((obj, id) => ({ ...obj, [id]: true }), {}),
        }));

        const response = await dispatch(
          resourceCreateRequest(accountsControlApi, query),
        );
        const changeAccounts = response.map((i) => i.accountId);

        const newData = data.map((row) => {
          if (changeAccounts.includes(row.accountId)) {
            return {
              ...row,
              accountControl: response.find(
                (i) => i.accountId === row.accountId,
              ),
            };
          }
          return row;
        });

        updateReduxResourceResponse(
          getReconciliationGLAccountsFastApi,
          newData,
        );
        setActionSubmitting((state) => ({
          ...state,
          ...accountIds.reduce((obj, id) => ({ ...obj, [id]: false }), {}),
        }));
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const getVATOverviewData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVATOverviewApi, {
        companyId: currentCompanySID,
        year: accountingYear,
      }),
    );

  const getVATReportData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVatDetailsNewer, {
        year: accountingYear,
        companyId: currentCompanySID,
      }),
    );

  const getVATOutgoingBalance = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVATSettlementsTotalApi, {
        term: Math.ceil(+accountingPeriod / 2),
        companyId,
        year: accountingYear,
      }),
    );

  const getVATOutgoingBalanceDeviation = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVATSettlementsDeviationApi, {
        term: Math.ceil(+accountingPeriod / 2),
        companyId,
        year: accountingYear,
      }),
    );

  const getPayrollPRandOBData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getPayrollSettlementsApi, {
        companyId,
        year: accountingYear,
      }),
    );

  const getPayrollReportedSalaryData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getPayrollReportedSalary, {
        companyId,
        year: +accountingYear,
        period: +accountingPeriod,
      }),
    );

  const getPayrollReportedSalaryDetailsData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getReportedSalaryDetails, {
        uuid: companyId,
        bimonthly: true,
        accumulated: true,
        year: +accountingYear,
      }),
    );

  const getPayrollSSData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getPayrollSocialSecurityApi, {
        companyId,
        year: accountingYear,
      }),
    );

  const getPayrollVPGLBaseData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVacationPayGLBaseApi, {
        companyId: currentCompanySID,
        period: Math.ceil(+accountingPeriod / 2),
        year: accountingYear,
        periodType: 'BIMONTHLY',
        lang: languageCode,
      }),
    );

  const getPayrollVPData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getVacationPayCalculationApi, {
        companyId: currentCompanySID,
        period: Math.ceil(+accountingPeriod / 2),
        year: accountingYear,
        periodType: 'BIMONTHLY',
        lang: languageCode,
      }),
    );

  const getPayrollWTData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getPayrollMappableReportsRowsApi, {
        period: Math.ceil(+accountingPeriod / 2),
        bimonthly: true,
        year: accountingYear,
        companyId: currentCompanySID,
      }),
    );

  const getPayrollA07Data = async () => {
    try {
      return await dispatch(
        resourceListReadRequest(payRollReportApi, {
          bimonthly: true,
          accumulated: true,
          year: +accountingYear,
          companyId: currentCompanySID,
          period: Math.ceil(accountingPeriod / 2),
        }),
      );
    } catch {
      return {};
    }
  };

  const getPayrollApproveStatus = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(isPayrollApprovedApi, {
        bimonthly: true,
        year: accountingYear,
        companyId: currentCompanySID,
        period: Math.ceil(+accountingPeriod / 2),
      }),
    );

  const getAssetsRegister = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getAssetsApi, {
        companyId,
        year: accountingYear,
        period: accountingPeriod,
      }),
    );

  const getAssetsOverview = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getAssetReconciliationApi, {
        companyId,
        year: accountingYear,
        period: accountingPeriod,
      }),
    );

  const getTrialBalanceData = async () =>
    // eslint-disable-next-line no-return-await
    await dispatch(
      resourceListReadRequest(getTrialBalanceApi, {
        year: accountingYear,
        companyId: match?.params?.companyId,
      }),
    );

  const handleMultipleDownload = async (accounts, options, filters) => {
    let vatData;
    let payrollData;
    let assetsData;
    let trialBalanceData;
    let transactionFiles = [];

    const response = await dispatch(
      resourceCreateRequest(
        // eslint-disable-next-line max-len
        `${getMultipleAccountsDrilldownsApi}?companyId=${companyId}&year=${accountingYear}&period=${accountingPeriod}&periodType=${currentPeriodType.toUpperCase()}`,
        {
          accounts: accounts.map((i) => ({
            accountId: i.accountId,
            template: i.accountTemplateCode,
          })),
        },
      ),
    );

    const generalLedgerAccounts = accounts.filter(
      (account) => account.accountTemplateCode === 'GENERAL',
    );

    if (generalLedgerAccounts.length) {
      transactionFiles = await dispatch(
        resourceCreateRequest(
          // eslint-disable-next-line max-len
          `${getFilesFromTransactionLevel}?companyId=${companyId}&year=${accountingYear}&period=${accountingPeriod}`,
          {
            accountIds: generalLedgerAccounts.map((i) => ({
              accountId: i.accountId,
            })),
          },
        ),
      );
    }

    if (options.withVAT) {
      vatData = await Promise.all([
        getVATOverviewData(),
        getVATReportData(),
        getVATOutgoingBalance(),
        getVATOutgoingBalanceDeviation(),
      ]);
    }

    if (options.withPayroll) {
      payrollData = await Promise.all([
        getPayrollA07Data(),
        getPayrollPRandOBData(),
        getPayrollReportedSalaryData(),
        getPayrollReportedSalaryDetailsData(),
        getPayrollSSData(),
        getPayrollVPGLBaseData(),
        getPayrollVPData(),
        getPayrollWTData(),
        getPayrollApproveStatus(),
      ]);
    }

    if (options.withAssets) {
      assetsData = await Promise.all([
        getAssetsRegister(),
        getAssetsOverview(),
      ]);
    }

    if (options.withTrialBalance) {
      trialBalanceData = await getTrialBalanceData();
    }

    const files = accounts.reduce((filesList, nextAccount) => {
      if (nextAccount.accountFileNamesAndPeriod?.length) {
        const { accountId } = nextAccount;
        const folderName = `${accountId}`;

        filesList.push(
          ...nextAccount.accountFileNamesAndPeriod
            .filter((item) => {
              if (filters.singlePeriod) {
                return item.period === filters.period;
              }

              return item.period <= filters.period;
            })
            .map((item) => {
              const { fileName, fileKey, period } = item;

              const newFileName = fileName.split('.');
              // eslint-disable-next-line max-len
              newFileName[0] = `${newFileName[0]} (${period}, ${accountingYear})`;

              return {
                name: newFileName.join('.'),
                key: fileKey,
                folderName,
              };
            }),
        );
      }

      return filesList;
    }, []);

    if (transactionFiles?.length) {
      const moreFiles = transactionFiles.reduce((fileList, nextItem) => {
        const account = accounts.find(
          (item) => item.accountId === nextItem.accountId,
        );
        const { accountId, name } = account || {};
        const mainFolder = `${accountId} - ${name.replaceAll('/', '-')}`;

        fileList.push(
          ...nextItem.fileNamesAndKeys.map((file) => ({
            name: file.fileName,
            key: file.fileKey,
            // eslint-disable-next-line max-len
            folderName: `${mainFolder}/${nextItem.lineId}`,
          })),
        );

        return fileList;
      }, []);
      files.push(...moreFiles);
    }

    await generateMultipleReport(accounts, response, options, filters, {
      vatData,
      username,
      companySID: company?.currentCompanySID,
      companyUUID: company?.uuid,
      assetsData,
      payrollData,
      trialBalanceData,
      getRowColor: (row) => getRowColor(row, true),
      accountingYear,
      accountingPeriod,
      currentPeriodType,
      accountOverviewData: data,
      company: currentCompanyName,
      getFiles: () => files,
      getPayrollFiles: async () =>
        _getFiles(
          { accountId: '' },
          { showAll: true, fileCategory: 'PAYROLL' },
        ),
      getVATFiles: async () =>
        _getFiles({ accountId: '' }, { showAll: true, fileCategory: 'VAT' }),
      orgNumber: currentCompanyRegistrationNumber,
      skipDownload: 2,
    });
    fetchReportsInfo();
  };

  const handleAction = async (action, item, reportOptions, filters) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowClick': {
          handleRowClick(item);
          break;
        }

        case 'rowSelect': {
          handleRowSelect(item);
          break;
        }

        case 'cellSave': {
          const { id, value, row } = item;

          if (id === 'comment' || id === 'periodComment') {
            await handleAccountCommentChange(value, row, id);
          }
          break;
        }

        case 'control': {
          await handleControl(item);
          break;
        }

        case 'uncontrol': {
          await handleControl(item, true);
          break;
        }

        case 'approve': {
          await handleApprove(true, item);
          break;
        }

        case 'unApprove': {
          await handleApprove(false, item);
          break;
        }

        case 'approveZero': {
          await handleApproveZeroMovement();
          break;
        }

        case 'dialog':
        case 'files':
        case 'history':
        case 'singleAccountReportVersions': {
          toggleModal({ type: action, row: item });
          break;
        }

        case 'balanceRegistration': {
          handleBalanceRegistration(item);
          break;
        }

        case 'downloadInPDF': {
          _setActionSubmitting(`${action}-${item.accountId}`, true);
          await downloadPDF(item, false, false);
          _setActionSubmitting(`${action}-${item.accountId}`, false);
          break;
        }

        case 'downloadLastReport': {
          await downloadPDF(item, true);
          break;
        }

        case 'downloadLastPackage': {
          _setActionSubmitting(action, true);
          await downloadLastPackage();
          _setActionSubmitting(action, false);
          break;
        }

        case 'generatePDF': {
          _setActionSubmitting('generatePDF', true);

          await generateAccountOverviewReport({
            rows: data,
            company: currentCompanyName,
            companySID: currentCompanySID,
            companyUUID: companyId,
            username,
            getRowColor,
            orgNumber: currentCompanyRegistrationNumber,
            accountingYear,
            accountingPeriod,
            currentPeriodType,
            saveReport: false,
            skipDownload: false,
          });
          fetchReportsInfo();
          _setActionSubmitting('generatePDF', false);
          break;
        }

        case 'generateMultipleReport': {
          try {
            await handleMultipleDownload(item, reportOptions, filters);
          } catch (e) {
            toast.error(getErrorMessage(e));
          } finally {
            toast.success(t`Report generated successfully`);
          }
          break;
        }
        case 'generatePackage': {
          const accountsToPDF = data.filter(selectCondition);
          await handleMultipleDownload(
            accountsToPDF,
            {
              withOverview: true,
              withTrialBalance: true,
              withVAT: vatEnabled,
              withAssets: assetsEnabled,
              withPayroll: payrollEnabled,
            },
            _filters,
          );
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const handleCheckboxChange = () => {
    setShowDeactived((state) => !state);
  };

  const resetFilters = () => {
    setFilters(reportPackageDefaultValues);
  };

  return (
    <>
      {renderModal()}
      <AccountReconciliationTable
        {...{
          data: preparedData,
          search,
          loading,
          filters: _filters,
          setFilters,
          roleType,
          resetFilters,
          vatEnabled,
          getRowColor,
          handleAction,
          assetsEnabled,
          showDeactived,
          selectedItems,
          isRowDisabled,
          payrollEnabled,
          selectCondition,
          actionSubmitting,
          handleCheckboxChange,
        }}
      />
    </>
  );
}

export default AccountReconciliationTableContainer;
