import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings } from 'components';

import {
  selectionsTranslations,
  defineFrequencyPeriodTypes,
} from 'services/helpers';
import { accountDetailsColumns } from 'services/tableshapes';
import { accountDetailsFilters } from 'services/tableFilterShapes';

function AccountDetailsTable(props) {
  const { data, search, loading, company = {}, collections } = props;

  const { periodType, currentAccountingYear, currentWorkingPeriodEnd } =
    company;

  const sortBy = useMemo(
    () => [
      {
        id: 'accountId',
        desc: false,
      },
    ],
    [],
  );
  const period = defineFrequencyPeriodTypes(
    periodType,
    currentWorkingPeriodEnd,
  );

  const title = useMemo(
    () =>
      search?.account
        ? t`Account ID - ${
            search.account
          }, Selection - ${selectionsTranslations(search.period)}`
        : t`Account Details, Term - ${period}, ${currentAccountingYear}`,
    [search?.account, period],
  );

  const urlFilters = useMemo(() => {
    const filters = {};

    if (search.periods) {
      filters.period = Array.isArray(search.periods)
        ? search.periods
        : [search.periods];
    }

    if (search.accountId) {
      filters.accountId = Array.isArray(search.accountId)
        ? search.accountId
        : [search.accountId];
    }

    return filters;
  }, [JSON.stringify(search.periods), JSON.stringify(search.accountId)]);

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-accountDetails">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          urlFilters,
          columns: accountDetailsColumns(search),
          tableId: 'accountDetails',
          loadingId: 'accountId',
          reportMode: search.reportMode,
          filterConfigs: accountDetailsFilters(collections),
          headerConfigs: {
            exportFileName: title,
          },
          initialState: {
            sortBy,
          },
        }}
      />
    </Card>
  );
}

AccountDetailsTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  loading: PropTypes.bool,
};

AccountDetailsTable.defaultProps = {
  data: undefined,
  loading: true,
};

export default AccountDetailsTable;
