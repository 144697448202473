import React, { useState } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { BankTemplateGL } from 'components';
import getErrorMessage from 'services/helpers/getErrorMessage';

function BankTemplateGLTableContainer(props) {
  const {
    account,
    handleChange,
    handleSelectAll,
    handleUnreconcile,
    setTransactionsForModal,
  } = props;

  const { accountingYear, accountingPeriod } = useParams();

  const [actionSubmitting, setActionSubmitting] = useState({});

  // eslint-disable-next-line max-len
  const exportFileName = t`${account} GL Transactions, Period - ${accountingPeriod}, ${accountingYear}`;

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowSelect': {
          if (Array.isArray(item.item)) {
            handleSelectAll(item.item, 'GL');
          } else if (item.item === 'all') {
            handleSelectAll([], 'GL');
          } else {
            handleChange(item.item, 'GL');
          }
          break;
        }

        case 'reconciledTransactions': {
          const { reconciliationId } = item;
          setTransactionsForModal(reconciliationId);
          break;
        }

        case 'unreconcile': {
          _setActionSubmitting(item.lineId, true);
          await handleUnreconcile('gl', item.lineId);
          _setActionSubmitting(item.lineId, false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <BankTemplateGL
      {...props}
      handleAction={handleAction}
      exportFileName={exportFileName}
      actionSubmitting={actionSubmitting}
    />
  );
}

export default BankTemplateGLTableContainer;

BankTemplateGLTableContainer.propTypes = {
  handleChange: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleUnreconcile: PropTypes.func,
  search: PropTypes.shape({}),
};

BankTemplateGLTableContainer.defaultProps = {
  handleChange: () => {},
  handleSelectAll: () => {},
  handleUnreconcile: () => {},
  search: {},
};
