import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Navbar } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/es/NavbarToggle';
import { FaList } from '@react-icons/all-files/fa/FaList';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { FiMoreVertical } from '@react-icons/all-files/fi/FiMoreVertical';
import { MdOutlineKeyboardBackspace } from '@react-icons/all-files/md/MdOutlineKeyboardBackspace'; // eslint-disable-line max-len

import {
  Link,
  Button,
  ButtonIcon,
  PeriodFilterButtons,
  CollapsableMenuGroup,
} from 'components';

import { ChristmasModeContext } from 'services/context/ChristmasMode';
import { toReconciliationSettings } from 'services/routehelpers';

import './style.css';

function DashboardNavbar(props) {
  const {
    user,
    goBack,
    history,
    expanded,
    location,
    roleType,
    handleSearch,
    handleSelect,
    activePeriods,
    altinnExpireIn,
    handleMenuToggle,
    accountsNotMapped,
    handleAltinnClick,
    currentPeriodType,
    handlePeriodChange,
    handleSidebarChangeClick,
  } = props;
  const christmasMode = useContext(ChristmasModeContext);

  return (
    <>
      <Navbar
        fluid
        collapseOnSelect
        onToggle={handleMenuToggle}
        expanded={expanded}
      >
        {accountsNotMapped > 0 && (
          <InfoWrapper>
            <Link
              to={toReconciliationSettings({
                showAccountsNotMapped: true,
                glType: 'all',
              })}
            >
              {/* eslint-disable-next-line max-len */}
              <Trans>{`Number of accounts not default mapped: ${accountsNotMapped}. Click here to go to reconciliation settings`}</Trans>
            </Link>
          </InfoWrapper>
        )}
        <div>
          <div className="navbar-minimize">
            <Button fill icon onClick={handleSidebarChangeClick}>
              {document.body.classList.contains('sidebar-mini') ? (
                <ButtonIcon as={FaList} fontSize={15} />
              ) : (
                <ButtonIcon as={FiMoreVertical} />
              )}
            </Button>
            <Button fill icon kind="info" onClick={handleSearch}>
              <ButtonIcon as={FaSearch} fontSize={15} />
            </Button>
            <Button
              fill
              icon
              kind="danger"
              onClick={goBack}
              disabled={history.length <= 2}
            >
              <ButtonIcon as={MdOutlineKeyboardBackspace} marginRight={5} />
              <Trans>Back</Trans>
            </Button>
            <PeriodFilterButtons
              size="xsmall"
              periodType={currentPeriodType}
              activePeriods={activePeriods}
              handleFilterChange={handlePeriodChange}
            />
            {!!altinnExpireIn && (
              <AltinnWrapper>
                <Button
                  fill
                  wide
                  size="sm"
                  kind="danger"
                  onClick={handleAltinnClick}
                >
                  <Trans>
                    {`Altinn expires in: ${altinnExpireIn} minutes`}
                  </Trans>
                </Button>
              </AltinnWrapper>
            )}
          </div>
          <CollapsableMenuGroup
            {...{
              user,
              history,
              location,
              roleType,
              handleSelect,
            }}
          />
          <NavbarToggle />
        </div>
      </Navbar>
      {christmasMode.isOn && (
        <Lights>
          {[...Array(60).keys()].map((_, i) => (
            <li key={`light-${i}`} />
          ))}
        </Lights>
      )}
    </>
  );
}

DashboardNavbar.propTypes = {
  /** @type {shape} location Router object */
  location: PropTypes.shape({}).isRequired,
  /** @type {shape} history Router object */
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  /** @type {function} handleSelect Function for clicking on navigation */
  handleSelect: PropTypes.func,
  /** @type {function} handleSidebarChangeClick Function for changing to mini sidebar */
  handleSidebarChangeClick: PropTypes.func,
  /** @type {function} handleMenuToggle Function for toggling collapsed menu items */
  handleMenuToggle: PropTypes.func,
  /** @type {function} handlePeriodChange Function to change current company period */
  handlePeriodChange: PropTypes.func,
  /** @type {bool} expanded If true, menu is expanded */
  expanded: PropTypes.bool,
  /** @type {bool} periodLock If true, period is marked as locked */
  periodLock: PropTypes.bool,
  /** @type {array} activePeriods */
  activePeriods: PropTypes.arrayOf(PropTypes.number),
  /** @type {string} currentPeriodType */
  currentPeriodType: PropTypes.string,
  roleType: PropTypes.shape({}).isRequired,
  accountsNotMapped: PropTypes.number,
};

DashboardNavbar.defaultProps = {
  handleSelect: undefined,
  handleSidebarChangeClick: undefined,
  handlePeriodChange: undefined,
  handleMenuToggle: undefined,
  expanded: true,
  periodLock: false,
  activePeriods: undefined,
  currentPeriodType: undefined,
  accountsNotMapped: 0,
};

export default DashboardNavbar;

const InfoWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  position: absolute;
  align-items: center;
  background-color: #eb5e28;

  a {
    width: 100%;
    color: white;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
  }
`;

const AltinnWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
`;

const Lights = styled.ul`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0 !important;
  padding: 0;
  pointer-events: none;
  width: 100%;

  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    padding: 0;
    width: 12px;
    height: 14px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px 5px 24px 3px rgba(0, 247, 165, 1);
    animation-name: flash-1;
    animation-duration: 2s;

    &:nth-child(2n + 1) {
      background: rgb(0, 203, 255);
      box-shadow: 0px 5px 24px 3px rgba(0, 203, 255, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }

    &:nth-child(4n + 2) {
      background: rgb(238, 29, 29);
      box-shadow: 0px 5px 24px 3px rgba(238, 29, 29, 1);
      animation-name: flash-3;
      animation-duration: 2.1s;
    }

    &:nth-child(odd) {
      animation-duration: 1.8s;
    }

    &:nth-child(3n + 1) {
      animation-duration: 1.4s;
    }

    &:before {
      content: '';
      position: absolute;
      background: #66615b;
      width: 10px;
      height: 9px;
      border-radius: 3px;
      top: -8.5px;
      left: 1px;
    }

    &:after {
      content: '';
      top: -16px;
      left: 9px;
      position: absolute;
      width: 52px;
      height: 18px;
      border-bottom: solid #66615b 2px;
      border-radius: 50%;
    }

    &:last-child:after {
      content: none;
    }

    &:first-child {
      margin-left: -40px;
    }
  }

  @keyframes flash-1 {
    0%,
    100% {
      background: rgba(0, 247, 165, 1);
      box-shadow: 0px 5px 24px 3px rgba(0, 247, 165, 1);
    }
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 5px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  @keyframes flash-2 {
    0%,
    100% {
      background: rgba(0, 203, 255, 1);
      box-shadow: 0px 5px 24px 3px rgba(0, 203, 255, 1);
    }
    50% {
      background: rgba(0, 203, 255, 0.4);
      box-shadow: 0px 5px 24px 3px rgba(0, 203, 255, 0.2);
    }
  }
  @keyframes flash-3 {
    0%,
    100% {
      background: rgba(238, 29, 29, 1);
      box-shadow: 0px 5px 24px 3px rgba(238, 29, 29, 1);
    }
    50% {
      background: rgba(238, 29, 29, 0.4);
      box-shadow: 0px 5px 24px 3px rgba(238, 29, 29, 0.2);
    }
  }
`;
