import { t } from '@lingui/macro';

/**
 * Bank Settings Grid
 */
export const columns = ({ collections, reportMode }) => [
  {
    width: 70,
    Header: t`ID`,
    accessor: 'id',
    sortType: 'basic',
    className: 'flex-center',
  },
  {
    width: 150,
    Header: t`Number`,
    accessor: 'accountNumber',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 450 : 550,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: 200,
    Header: t`User Name`,
    accessor: 'username',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 400 : 540,
    Header: t`Template Account`,
    accessor: 'mappedGlAccountId',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      editable: (row) => !reportMode && !row.mappedGlAccountId,
      converter: (_, item) => {
        const selectedItem = collections.mappedGlAccountId?.options?.find(
          (acc) => acc.id === item.mappedGlAccountId,
        );

        if (selectedItem) {
          return selectedItem.label;
        }

        return item.mappedGlAccountId;
      },
      disableClick: true,
      fieldOptions: {
        id: 'mappedGlAccountId',
        single: true,
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Bank Template Account`,
        ...collections.mappedGlAccountId,
      },
    },
  },
];

export default { columns };
