import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { GroupCompanyDetailedTable } from 'components';

import UserPreviewModal from 'containers/UserPreviewModal';
import CompanyPreviewModal from 'containers/CompanyPreviewModal';
import MoveGroupCompaniesModal from 'containers/MoveGroupCompaniesModal';
import AddEditGroupCompanyModal from 'containers/AddEditGroupCompanyModal';
import AddEditGroupCompaniesModal from 'containers/AddEditGroupCompaniesModal';

import {
  secretCompanyApi,
  deleteGroupCompaniesApi,
  getGroupCompanyAssociatedApi,
  getGroupParentCompanyDetailsApi,
} from 'services/apihelpers';
import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';
import { fromResource } from 'store/selectors';
import { toGroupCompanyDetailed } from 'services/routehelpers';
import { resourceListReadSuccess } from 'store/resource/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

function GroupCompanyDetailedContainer(props) {
  const { location, history, match, roleType } = props;
  const [actionSubmitting, setActionSubmitting] = useState({});

  const dispatch = useDispatch();
  const [modal, setModal] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);

  const group = useSelector((state) =>
    fromResource.getList(state, getGroupParentCompanyDetailsApi),
  );
  const data = useSelector((state) =>
    fromResource.getList(state, getGroupCompanyAssociatedApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupCompanyAssociatedApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getGroupCompanyAssociatedApi, {
        groupId: match.params.id,
      }),
    );
    dispatch(
      resourceListReadRequest(getGroupParentCompanyDetailsApi, {
        groupId: match.params.id,
      }),
    );
  };
  const getSearch = () => {
    const search = location ? queryString.parse(location.search) : {};

    return {
      sortBy: search.sortBy || 'Asc',
      sortByField: search.sortByField || 'companyName',
      reportMode: search.reportMode || false,
    };
  };
  const search = getSearch();

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const changeSecretStatus = async (company) => {
    const { companyId, secret } = company;

    _setActionSubmitting(companyId, true);

    try {
      if (secret) {
        await dispatch(
          resourceDeleteRequest(
            `${secretCompanyApi}?${queryString.stringify({
              id: companyId,
            })}`,
          ),
        );
      } else {
        await dispatch(
          resourceCreateRequest(secretCompanyApi, { id: companyId }),
        );
      }

      const newData = data.map((item) =>
        item.companyId === companyId ? { ...item, secret: !secret } : item,
      );
      await dispatch(
        resourceListReadSuccess(getGroupCompanyAssociatedApi, newData),
      );

      toast.success(t`Secret field updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      _setActionSubmitting(companyId, false);
    }
  };

  const handleDeleteGroup = async (row) => {
    try {
      await dispatch(
        resourceDeleteRequest(
          `${deleteGroupCompaniesApi}?${queryString.stringify({
            groupId: match.params.id,
            companyId: row.companyId,
          })}`,
        ),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    fetchData();
  };

  const handleAction = async (type, item, table) => {
    try {
      _setActionSubmitting(type, true);

      switch (type) {
        case 'add': {
          setModal('addEdit');
          break;
        }
        case 'companyPreview': {
          setModal('companyPreview');
          setSelectedRow(item);
          setSelectedCompany(item);
          break;
        }
        case 'move': {
          setModal('move');
          setSelectedRow(item);
          break;
        }
        case 'delete': {
          await handleDeleteGroup(item);
          break;
        }
        case 'redirect': {
          history.push(toGroupCompanyDetailed({}, item));
          break;
        }
        case 'cellClick': {
          if (table === 'secret') {
            await changeSecretStatus(item);
          }
          break;
        }
        case 'rowClick': {
          switch (table) {
            case 'userPreview': {
              setModal('userPreview');
              setSelectedRow(item);
              break;
            }
            case 'editCompanyGroup': {
              setModal('editCompanyGroup');
              setSelectedRow(item);
              break;
            }
            case 'companyPreview': {
              setModal('companyPreview');
              setSelectedRow(item);
              setSelectedCompany(item);
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(type, false);
    } catch (e) {
      _setActionSubmitting(type, false);
      toast.error(getErrorMessage(e));
    }
  };

  // eslint-disable-next-line consistent-return
  const closeModal = (closedModal) => {
    setModal('');
    setSelectedRow({});

    if (closedModal) {
      if (closedModal === 'companyPreview') {
        return setSelectedCompany(null);
      }
      if (selectedCompany) {
        setModal('companyPreview');
        setSelectedRow(selectedCompany);
      }
    }
  };

  const renderModal = () => {
    let modalToRender = '';

    switch (modal) {
      case 'addEdit': {
        modalToRender = (
          <AddEditGroupCompaniesModal
            item={group}
            refetch={fetchData}
            closeModal={closeModal}
          />
        );
        break;
      }
      case 'editCompanyGroup': {
        modalToRender = (
          <AddEditGroupCompanyModal
            item={selectedRow}
            refetch={fetchData}
            closeModal={closeModal}
          />
        );
        break;
      }
      case 'move': {
        modalToRender = (
          <MoveGroupCompaniesModal
            data={group}
            item={selectedRow}
            refetch={fetchData}
            closeModal={closeModal}
          />
        );
        break;
      }
      case 'userPreview': {
        modalToRender = (
          <UserPreviewModal
            id={selectedRow.uuid}
            closeModal={closeModal}
            handleAction={handleAction}
          />
        );
        break;
      }
      case 'companyPreview': {
        modalToRender = (
          <CompanyPreviewModal
            id={selectedRow.companyId}
            closeModal={() => closeModal('companyPreview')}
            handleAction={handleAction}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  useEffect(fetchData, [match.params.id]);

  return (
    <>
      {renderModal()}
      <GroupCompanyDetailedTable
        {...{
          data,
          group,
          search,
          loading,
          roleType,
          handleAction,
          actionSubmitting,
        }}
      />
    </>
  );
}

export default GroupCompanyDetailedContainer;
