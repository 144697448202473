import React, { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Trans } from '@lingui/macro';

import { useVersion } from 'services/versionContext';

export default function UpdateNotification() {
  const { newVersionAvailable } = useVersion();

  const handleRefresh = useCallback((e) => {
    e.preventDefault();
    window.location.reload();
  }, []);

  useEffect(() => {
    if (newVersionAvailable) {
      toast(
        () => (
          <span
            role="button"
            tabIndex={0}
            style={{ color: 'black', textAlign: 'center', cursor: 'pointer' }}
            onClick={handleRefresh}
            onKeyPress={(e) => e.key === 'Enter' && handleRefresh(e)}
          >
            <Trans>
              Accountflow was recently updated. Click here to refresh.
            </Trans>
          </span>
        ),
        {
          duration: Number.POSITIVE_INFINITY,
          position: 'top-center',
          id: 'new-version-available',
        },
      );
    }
  }, [newVersionAvailable, handleRefresh]);

  return null;
}
