import { t } from '@lingui/macro';
import { textAvailability } from 'services/shapes';

export const vatDetailedFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'period',
      'vatCode',
      'accountId',
      'description',
      'transactionId',
      'accountDescription',
    ],
  },
  {
    id: 'withDescription',
    type: 'multiSelect',
    label: t`Text availability`,
    options: textAvailability(),
    disableSort: true,
    placeholder: t`Please select Text Availability`,
  },
];

export default { vatDetailedFilters };
