import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { AccrualsInfoModalGrid } from 'services/tableshapes';
import { accrualsInfoModalGridFilters } from 'services/tableFilterShapes';

import { DESKTOP, TABLET } from 'constants/mediaSizes';

function AccrualsInfoModal(props) {
  const {
    data,
    loading,
    reportMode,
    toggleModal,
    handleAction,
    actionSubmitting,
  } = props;

  const sortBy = useMemo(
    () => [
      {
        id: 'period',
        desc: false,
      },
    ],
    [],
  );

  const content = (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        reportMode,
        handleAction,
        actionSubmitting,
        isModal: true,
        tableId: 'AccrualsInfoModalGrid',
        columns: AccrualsInfoModalGrid(reportMode),
        loadingId: 'transactionId',
        filterConfigs: accrualsInfoModalGridFilters(),
        headerConfigs: {
          exportFileName: t`Accruals Info`,
        },
        initialState: {
          sortBy,
        },
      }}
    />
  );

  return (
    <Modal show onEscapeKeyDown={toggleModal} bsSize="lg">
      <Modal.Header>
        <Heading level={3}>
          <Trans>Accruals Info</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AccrualsInfoModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  closeModal: PropTypes.func,
  reportMode: PropTypes.bool,
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

AccrualsInfoModal.defaultProps = {
  data: [],
  loading: undefined,
  closeModal: undefined,
  reportMode: false,
  handleAction: () => {},
  actionSubmitting: {},
};

export default AccrualsInfoModal;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
