import { t } from '@lingui/macro';
import { vatDrilldownTypeOptions } from 'services/shapes';

export const vatControlDrilldownFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['sourceDocumentId', 'lineSid'],
  },
  {
    id: 'type',
    type: 'singleSelect',
    label: t`Type`,
    options: vatDrilldownTypeOptions(),
    required: true,
    disableSort: true,
    placeholder: t`Select type`,
  },
];

export default { vatControlDrilldownFilters };
