import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { t, Trans } from '@lingui/macro';
import { useLocation } from 'react-router-dom';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';

import { ButtonIcon, Heading, Button, Field } from 'components';

import {
  formatDate,
  formatNumber,
  createCSVFile,
  getMonthDifference,
  getDateWithoutTimezoneOffset,
} from 'services/helpers';

function CreateReconciliationModal(props) {
  const {
    row = {},
    dates,
    comment,
    setDate,
    toggleModal,
    changeComment,
    contraAccrualAccount,
    setContraAccrualAccount,
    accountList = [],
  } = props;
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { sourceDocumentId, amount } = row;

  const formattedAmount = formatNumber(typeof amount === 'number' ? amount : 0);

  const minValueForEndDate = useMemo(() => {
    if (!dates.from) return null;

    const minDate = new Date(dates.from);
    minDate.setMonth(minDate.getMonth() + 1);

    return getDateWithoutTimezoneOffset(minDate.toISOString());
  }, [dates.from]);

  const isEmptyField = !dates.from || !dates.to;
  const periods = !isEmptyField
    ? getMonthDifference(new Date(dates.from), new Date(dates.to))
    : 0;
  const disabled = isEmptyField || !periods || !contraAccrualAccount;
  const calculatedAmount = formatNumber(
    amount && periods ? amount / periods : 0,
  );
  const downloadInCSV = () => {
    const headers = [
      {
        accessor: 'transactionId',
        Header: t`Transaction ID`,
      },
      {
        accessor: 'amount',
        Header: t`Amount`,
        cell: {
          type: 'number',
        },
      },
      {
        accessor: 'period',
        Header: t`Period`,
      },
      {
        accessor: 'year',
        Header: t`Year`,
      },
      {
        accessor: 'originAmount',
        Header: t`Origin Amount`,
        cell: {
          type: 'number',
        },
      },
      {
        accessor: 'accrualAccount',
        Header: t`Accrual Account`,
      },
      {
        accessor: 'contraAccrualAccount',
        Header: t`Contra Accrual Account`,
      },
      {
        accessor: 'startDate',
        Header: t`Start Date`,
        cell: {
          type: 'date',
        },
      },
      {
        accessor: 'endDate',
        Header: t`End Date`,
        cell: {
          type: 'date',
        },
      },
    ];
    const data = [];
    const d = new Date(dates.from);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < periods; i++) {
      const currentDate = new Date(d.getFullYear(), d.getMonth(), 1);

      currentDate.setMonth(currentDate.getMonth() + i);

      data.push({
        transactionId: props.row.sourceDocumentId,
        amount: calculatedAmount.value,
        period: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        originAmount: props.row.amount,
        accrualAccount: query.account,
        contraAccrualAccount,
        startDate: new Date(dates.from),
        endDate: new Date(dates.to),
      });
    }

    const csvColumns = headers.map((head) => `"${head.Header}"`).join(',');
    const mapCSVBody = (el, head) => {
      if (head.cell?.type === 'number') {
        return `"${formatNumber(el[head.accessor] || 0).formatted}"`;
      }

      if (head.cell?.type === 'date') {
        return `"${formatDate(el[head.accessor] || '')}"`;
      }

      return `"${el[head.accessor] || ''}"`;
    };
    const csvRows = data
      .map((el) => headers.map((head) => mapCSVBody(el, head)).join(','))
      .join('\n');

    createCSVFile({
      name: t`accrual`,
      rows: csvRows,
      columns: csvColumns,
    });
  };

  return (
    <Modal show onEscapeKeyDown={() => toggleModal()}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Create Accrual</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <RowHolder>
          <div>
            <Trans>Selected Transaction:</Trans> {sourceDocumentId}
          </div>
          <div>
            <Trans>Selected Amount:</Trans>{' '}
            <span className={formattedAmount.className}>
              {formattedAmount.formatted}
            </span>
          </div>
        </RowHolder>
        <Hr />
        <RowHolder>
          <DateHolder>
            <Field
              id="from"
              size="sm"
              type="date"
              label={t`Accrual Start date:`}
              value={dates.from}
              onChange={(e) => setDate('from', e)}
            />
          </DateHolder>
          <DateHolder>
            <Field
              id="to"
              size="sm"
              type="date"
              label={t`Accrual End date:`}
              value={dates.to}
              min={minValueForEndDate}
              onChange={(e) => setDate('to', e)}
            />
          </DateHolder>
        </RowHolder>
        <ResultsContainer>
          <Item>
            <b>
              <Trans>Accrual Periods:</Trans>
            </b>{' '}
            {periods}
          </Item>
          <Item flex>
            <b>
              <Trans>Contra Accrual Account:</Trans>
            </b>
            <Field
              horizontal
              name="accountHolderUserId"
              type="select"
              size="sm"
              onChange={(e) => setContraAccrualAccount(e.target.value)}
            >
              <option value="">{t`Please Select Account`}</option>
              {accountList.map(({ accountId, name }, i) => (
                <option
                  key={`${accountId}-${i}`}
                  value={accountId}
                  selected={accountId === contraAccrualAccount}
                >
                  {accountId} - {name}
                </option>
              ))}
            </Field>
          </Item>
          <Item>
            <b>
              <Trans>Calculated Accrual per month:</Trans>
            </b>{' '}
            <span className={calculatedAmount.className}>
              {calculatedAmount.formatted}
            </span>
          </Item>
        </ResultsContainer>
        <div>
          <Field
            id="to"
            size="sm"
            type="text"
            label={t`Comment:`}
            value={comment}
            onChange={(e) => changeComment(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="default"
            onClick={downloadInCSV}
            disabled={disabled}
          >
            <ButtonIcon as={FaDownload} /> <Trans>CSV</Trans>
          </Button>
          <Button
            fill
            kind="success"
            type="submit"
            magnify
            disabled={disabled}
            onClick={() => toggleModal()}
          >
            <ButtonIcon as={HiPlus} /> <Trans>Create Accrual</Trans>
          </Button>
          <Button fill kind="danger" magnify onClick={() => toggleModal()}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateReconciliationModal;

const RowHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;

  label {
    font-size: 13px;
  }
`;

const ResultsContainer = styled.div`
  padding: 15px;
  border: 2px solid #f1f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  margin-bottom: 8px;

  ${({ flex }) =>
    flex &&
    `
    display: flex;
    align-items: center;
    gap: 6px;
  `};

  div {
    margin-top: -2px;
    width: 350px;
  }
`;

const Hr = styled.hr`
  margin-top: 0;
  margin-bottom: 20px;
`;

const DateHolder = styled.div`
  width: 49%;
`;
