import React, { useEffect, useContext } from 'react';
import cx from 'classnames';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { checkIfTestStagingEnv } from 'services/helpers';
import {
  Link,
  Logo,
  Toggle,
  Heading,
  SidebarNav,
  SidebarUser,
} from 'components';

import { ChristmasModeContext } from 'services/context/ChristmasMode';
import { toHome } from 'services/routehelpers';
import { algoliaIndex } from 'services/utils/algoliaSearch';
import * as PAGE_PATH from '../../constants';

/**
 * Sidebar component
 */

function Sidebar(props) {
  const {
    activeColor,
    sidebarToggle,
    backgroundColor,
    sidebarNavExpanded,
    sidebarUserExpanded,
  } = props;
  const christmasMode = useContext(ChristmasModeContext);

  useEffect(() => {
    const navigation = [
      {
        name: {
          en: 'Dashboard',
          nb: 'Dashboard',
        },
        link: PAGE_PATH.DASHBOARD_PAGE_PATH,
        objectID: 'dashboard',
      },
      {
        name: {
          en: 'Reconciliation | Account',
          nb: 'Avstemming | Konto',
        },
        link: PAGE_PATH.OVERVIEW_RECONCILIATION_PAGE_PATH,
        objectID: 'account_reconciliation',
      },
      {
        name: {
          en: 'Reconciliation | Payroll',
          nb: 'Avstemming | Lønn',
        },
        link: PAGE_PATH.PAYROLL_RECONCILIATION_PAGE_PATH,
        _tags: ['payrollEnabled'],
        objectID: 'payroll_reconciliation',
      },
      {
        name: {
          en: 'Reconciliation | VAT',
          nb: 'Avstemming | MVA',
        },
        link: PAGE_PATH.VAT_RECONCILIATION_PAGE_PATH,
        _tags: ['vatEnabled'],
        objectID: 'vat_reconciliation',
      },
      {
        name: {
          en: 'Reconciliation | Assets',
          nb: 'Avstemming | Anlegg',
        },
        link: PAGE_PATH.ASSETS_RECONCILIATION_PAGE_PATH,
        _tags: ['assetsEnabled'],
        objectID: 'assets_reconciliation',
      },
      {
        name: {
          en: 'Settings & Admin | General',
          nb: 'Oppsett & Admin | Generell',
        },
        link: PAGE_PATH.GENERAL_SETTINGS_PAGE_PATH,
        objectID: 'settings',
      },
      {
        name: {
          en: 'Settings & Admin | Reconciliation',
          nb: 'Oppsett & Admin | Avstemming',
        },
        link: PAGE_PATH.RECONCILIATION_SETTINGS_PAGE_PATH,
        objectID: 'reconciliation_settings',
      },
      {
        name: {
          en: 'Settings & Admin | Company',
          nb: 'Oppsett & Admin | Selskap',
        },
        link: PAGE_PATH.ADMIN_COMPANY_SETTINGS_PAGE_PATH,
        _tags: ['isAdmin'],
        objectID: 'company_settings',
      },
      {
        name: {
          en: 'Settings & Admin | User',
          nb: 'Oppsett & Admin | Bruker',
        },
        link: PAGE_PATH.ADMIN_USER_SETTINGS_PAGE_PATH,
        _tags: ['isAdmin'],
        objectID: 'user_settings',
      },
      {
        name: {
          en: 'Settings & Admin | Organization',
          nb: 'Oppsett & Admin | Organisasjons',
        },
        link: PAGE_PATH.ADMIN_ORGANISATION_SETTINGS_PAGE_PATH,
        _tags: ['isAdmin'],
        objectID: 'organization_settings',
      },
      {
        name: {
          en: 'Settings & Admin | Group',
          nb: 'Oppsett & Admin | Grupper',
        },
        link: PAGE_PATH.GROUP_SETTINGS_PAGE_PATH,
        _tags: ['isAdmin', 'groupEnabled'],
        objectID: 'group_settings',
      },
      {
        name: {
          en: 'Reports | Trial Balance',
          nb: 'Rapportert | Saldobalanse',
        },
        link: PAGE_PATH.REPORTS_OVERVIEW_PAGE_PATH,
        objectID: 'reports_trial_balance',
      },
      {
        name: {
          en: 'Reports | Account Details',
          nb: 'Rapporter | Kontodetaljer',
        },
        link: PAGE_PATH.ACCOUNT_DETAILS_REPORT_PAGE_PATH,
        objectID: 'reports_account_details',
      },
      {
        name: {
          en: 'Reports | Generate Reports',
          nb: 'Rapporter | Generer rapport',
        },
        link: PAGE_PATH.GENERATE_REPORT_PAGE_PATH,
        objectID: 'reports_generate_reports',
      },
      {
        name: {
          en: 'Reports | Audit',
          nb: 'Rapporter | Revidering',
        },
        link: PAGE_PATH.AUDIT_PAGE_PATH,
        objectID: 'reports_audit',
      },
    ];

    algoliaIndex.saveObjects(navigation).catch((err) => {
      toast.error(
        err?.response?.headers?.get('Response-Message') || err?.message,
      );
    });
  }, []);

  return (
    <div
      className="sidebar"
      data-background-color={backgroundColor}
      data-active-color={activeColor}
    >
      <div className={cx('logo', { christmasLogo: christmasMode.isOn })}>
        {sidebarToggle && document.body.classList.contains('sidebar-mini') ? (
          <Link to={toHome()} className="logo-mini">
            <Logo
              mini
              white
              isChristmas={christmasMode.isOn}
              width={christmasMode.isOn ? 35 : 30}
            />
          </Link>
        ) : (
          <Link
            to={toHome()}
            className="logo-normal"
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
            <Logo white isChristmas={christmasMode.isOn} width={180} />
          </Link>
        )}
      </div>
      <SidebarWrapper
        className="sidebar-wrapper"
        options={{ suppressScrollX: true }}
      >
        <SidebarUser {...props} expanded={sidebarUserExpanded} />
        <Heading
          level={3}
          kind="danger"
          alignment="center"
          background="warning"
          transformation="uppercase"
        >
          {checkIfTestStagingEnv(window.location.href)}
        </Heading>
        {christmasMode.isEnabled && (
          <ToggleStyles>
            <Toggle
              id="christmasModeToggle"
              value={christmasMode.isOn}
              onClick={christmasMode.toggle}
              labelPosition="left"
              label={t`Christmas Mode:`}
            />
          </ToggleStyles>
        )}
        <SidebarNav
          {...props}
          expanded={sidebarNavExpanded}
          handleSelect={() => {}}
        />
      </SidebarWrapper>
    </div>
  );
}

Sidebar.propTypes = {
  sidebarUserExpanded: PropTypes.bool.isRequired,
  /** @type {shape} company */
  company: PropTypes.shape({}).isRequired,
  sidebarNavExpanded: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  activeColor: PropTypes.string,
  handleBadgeOnClick: PropTypes.func,
  progressionBadgeModal: PropTypes.bool,
  sidebarToggle: PropTypes.bool,
  progressionValue: PropTypes.shape({
    totalCompletenessPercentage: PropTypes.number,
    transactionsReconciliationPercentage: PropTypes.number,
    accountReconciliationPercentage: PropTypes.number,
    templatesMappingPercentage: PropTypes.number,
  }),
};
Sidebar.defaultProps = {
  backgroundColor: 'brown',
  sidebarToggle: false,
  activeColor: 'danger',
  progressionBadgeModal: false,
  progressionValue: {},
  handleBadgeOnClick: undefined,
};

const SidebarWrapper = styled(PerfectScrollbar)`
  overflow-x: hidden !important;

  & .ps__rail-x:hover,
  & .ps__rail-y:hover,
  & .ps__rail-x:focus,
  & .ps__rail-y:focus,
  & .ps__rail-x.ps--clicking,
  & .ps__rail-y.ps--clicking {
    background-color: transparent;
  }
`;

const ToggleStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #979490;
  color: #ffffff69 !important;
  transform: scale(0.9);
  padding: 0 22px 15px 22px;
  margin-top: -10px;

  & > div:nth-child(1) {
    display: flex;
    align-items: center !important;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
  }

  label {
    padding-left: 0 !important;
    max-width: none !important;
    font-weight: 500 !important;
    margin: 0;
  }
`;

export default Sidebar;
