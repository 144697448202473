const placeholder = {
  generateFrame: (params, body) => {
    const printFrame = document.createElement('iframe');
    printFrame.setAttribute(
      'style',
      'visibility: hidden; height: 0; width: 0; position: absolute;',
    );
    printFrame.setAttribute('id', 'jsObjExporterFrameId');
    printFrame.srcdoc = `</head><body>${body}</body></html>`;
    return printFrame;
  },
};

// Export the module
export default placeholder;
