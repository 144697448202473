import React from 'react';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings } from 'components';

import { payrollReportedSalaryColumns } from 'services/tableshapes';

function PayrollReportedSalary(props) {
  const {
    rows,
    manual,
    loading,
    handleAction,
    payrollApproved,
    actionSubmitting,
  } = props;

  return (
    <CustomTableWithAutoSettings
      {...{
        data: rows,
        loading,
        handleAction,
        actionSubmitting,
        hideFilters: true,
        totalName: t`SUM:`,
        withCellClick: true,
        loadingId: 'rowNumber',
        tableId: 'payrollReportedSalary',
        columns: payrollReportedSalaryColumns({
          manual,
          payrollApproved,
          reportMode: false,
        }),
        headerConfigs: {
          exportFileName: t`Reported Salary`,
        },
      }}
    />
  );
}

export default PayrollReportedSalary;
