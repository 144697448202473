import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';

import { Label, Button } from 'components';

import { periodSelectors, periodFilterButtonsFill } from 'services/helpers';

function PeriodFilterButtons(props) {
  const {
    size,
    label,
    loading,
    disabled,
    periodType,
    singlePeriod,
    activePeriods,
    handleFilterChange,
  } = props;

  const periodSelect = periodSelectors(periodType);
  const selectedPeriodButton = periodFilterButtonsFill(
    activePeriods,
    periodType,
  );
  if (!selectedPeriodButton) return null;
  const selectedPeriod = singlePeriod
    ? [selectedPeriodButton]
    : Array.from(Array(selectedPeriodButton).keys());
  const periodButtons = new Set(selectedPeriod);
  const buttons = [];

  for (let y = periodSelect.start; y <= periodSelect.total; y += 1) {
    buttons.push(
      <Button
        id={y}
        key={`periodFilterButton-${y}`}
        size={size}
        fill={singlePeriod ? periodButtons.has(y) : periodButtons.has(y - 1)}
        onClick={(e) => handleFilterChange(e, periodType)}
        disabled={loading || disabled}
      >
        {y}
      </Button>,
    );
  }

  return label ? (
    <SelectorHolder>
      <Label>{label}</Label>
      <ButtonGroup>{buttons}</ButtonGroup>
    </SelectorHolder>
  ) : (
    <ButtonGroup>{buttons}</ButtonGroup>
  );
}

PeriodFilterButtons.propTypes = {
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small', 'xs', 'xsmall']),
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  periodType: PropTypes.string,
  singlePeriod: PropTypes.bool,
  activePeriods: PropTypes.arrayOf(PropTypes.number),
  handleFilterChange: PropTypes.func,
};

PeriodFilterButtons.defaultProps = {
  size: undefined,
  label: undefined,
  loading: false,
  disabled: false,
  periodType: 'monthly',
  singlePeriod: false,
  activePeriods: [1],
  handleFilterChange: undefined,
};

export default PeriodFilterButtons;

const SelectorHolder = styled.div`
  display: flex;
  flex-direction: column;
`;
