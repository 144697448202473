import React from 'react';
import TrialBalanceReport from 'containers/TrialBalanceReport';

/**
 * Reports Overview Page component
 */
function ReportsOverviewPage(props) {
  return <TrialBalanceReport {...props} />;
}

export default ReportsOverviewPage;
