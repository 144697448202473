import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Heading, CustomTable } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { bankTemplateGLNewColumns } from 'services/tableshapes';
import { bankTemplateGLFilters } from 'services/tableFilterShapes';
import { detectTopContentModeWhen2Tables } from 'services/helpers';
import { filterByReconciledMatchedTransaction } from 'services/shapes';

/**
 * Bank Template component
 */
function BankTemplateGL(props) {
  const {
    data,
    loading,
    roleType,
    getRowKind,
    handleAction,
    exportFileName,
    selectCondition,
    actionSubmitting,
    getManualSelectFunc,
  } = props;
  const screenWidth = useCurrentWidth();

  const fixedSize = useMemo(
    () => detectTopContentModeWhen2Tables(screenWidth, 0),
    [screenWidth],
  );

  const { isReadOnly } = roleType;

  const globalFilter = useMemo(
    () => ({
      reconciled: filterByReconciledMatchedTransaction().filter(
        (item) => item.id === 'UNMATCHED',
      ),
    }),
    [],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'postedDate',
        desc: false,
      },
    ],
    [],
  );

  return (
    <StyledTableWrapper>
      <Header level={4} alignment="center">
        <Trans>GL Transactions</Trans>
      </Header>
      <CustomTable
        {...{
          data: data?.transactions,
          loading,
          fixedSize,
          handleAction,
          height: 600,
          actionSubmitting,
          loadingId: 'lineId',
          getManualSelectFunc,
          hideSelection: true,
          getRowColor: getRowKind,
          isStaticTableHeight: true,
          tableId: 'bankTemplateGLLines',
          filterConfigs: bankTemplateGLFilters(),
          columns: bankTemplateGLNewColumns({
            isReadOnly,
          }),
          headerConfigs: {
            exportFileName,
          },
          selectConfigs: {
            selectCondition,
          },
          initialState: {
            sortBy,
            globalFilter,
          },
        }}
      />
    </StyledTableWrapper>
  );
}

BankTemplateGL.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  roleType: PropTypes.shape({}),
  getRowKind: PropTypes.func,
  handleAction: PropTypes.func.isRequired,
  exportFileName: PropTypes.string,
  selectCondition: PropTypes.func,
  getGLPage: PropTypes.func.isRequired,
  actionSubmitting: PropTypes.shape({}).isRequired,
};

BankTemplateGL.defaultProps = {
  loading: true,
  roleType: { isReadOnly: true },
  getRowKind: () => {},
  exportFileName: undefined,
  selectCondition: () => {},
};

export default BankTemplateGL;

const StyledTableWrapper = styled.div`
  width: 100%;
`;

const Header = styled((props) => <Heading {...props} />)`
  margin: 0 0 22px;
`;
