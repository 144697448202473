import React, { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { resourceListReadRequest } from 'store/actions';

import { ReconciliationRules, CreateRuleSetModal } from 'components';

import { fromResource } from 'store/selectors';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { getReconciliationGLAccountsFastApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

const items = [
  {
    id: 1,
    priority: 1,
    name: t`One-to-one`,
    description: t`Rule that finds 1 item in set 1 and 1 item in set 2`,
    activated: true,
    parameters:
      'Date range: +/- 5 days, Amount precision: Whole amount, Text Filters:',
  },
  {
    id: 2,
    priority: 2,
    name: t`One-to-many`,
    // eslint-disable-next-line max-len
    description: t`Rule that finds 1 item in set 1 and 1 or more items in set 2`,
    activated: true,
    parameters:
      'Date range: +/- 5 days, Amount precision: Whole amount, Text Filters:',
  },
  {
    id: 3,
    priority: 3,
    name: t`Many-to-many`,
    // eslint-disable-next-line max-len
    description: t`Rule that finds 1 or more items in both 1 and 2 set`,
    activated: true,
    parameters:
      'Date range: +/- 5 days, Amount precision: Whole amount, Text Filters:',
  },
];

function ReconciliationRulesContainer(props) {
  const dispatch = useDispatch();
  const { company, match } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState(null);
  const [data, setData] = useState([
    {
      id: 1,
      priority: 1,
      name: 'Standard Bank Rule Set',
      description: 'The default rule set for Bank template',
      activated: true,
      template: 'BANK',
      accounts: [],
      rules: [...items],
    },
    {
      id: 2,
      priority: 2,
      name: 'Standard General Ledger Rule Set',
      description: 'The default rule set for General Ledger template',
      activated: true,
      template: 'GENERAL',
      accounts: [],
      rules: [...items],
    },
  ]);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const title = t`Reconciliation Rules`;
  const accounts = useSelector((state) =>
    fromResource.getList(state, getReconciliationGLAccountsFastApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getReconciliationGLAccountsFastApi}ListRead`),
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const toggleModal = () => {
    setShowModal(false);
    setModalProps(null);
  };

  const handleSave = (type, item) => {
    if (type === 'save') {
      setData(data.map((i) => (i.id === item.id ? item : i)));
    } else {
      setData([...data, item]);
    }
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'add': {
          setShowModal(true);
          break;
        }

        case 'cellSave': {
          setData(
            data.map((i) =>
              i.id === item.row.id ? { ...i, priority: item.value[0].id } : i,
            ),
          );
          break;
        }

        case 'delete': {
          setData(data.filter((i) => i.id !== item.id));
          break;
        }

        case 'rowClick': {
          setData(
            data.map((i) => {
              if (i.id === item.id) {
                return { ...i, activated: !i.activated };
              }

              return i;
            }),
          );
          break;
        }

        case 'edit': {
          setShowModal(true);
          setModalProps(item);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const fetchAccounts = async () => {
    await dispatch(
      resourceListReadRequest(getReconciliationGLAccountsFastApi, {
        year: match?.params?.accountingYear,
        period: defineFrequencyPeriodTypes(
          company?.periodType,
          +match.params.accountingPeriod,
        ),
        companyId: match?.params?.companyId,
        periodType: company?.periodType.toUpperCase(),
      }),
    );
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <>
      <ReconciliationRules
        {...{ data, title, handleAction, actionSubmitting, loading }}
      />
      {showModal && (
        <CreateRuleSetModal
          {...{
            accounts,
            modalProps,
            handleSave,
            toggleModal,
            defaultItems: items,
          }}
        />
      )}
    </>
  );
}

export default ReconciliationRulesContainer;
