import React from 'react';

import VATReconciliationDetailedTable from 'containers/VATReconciliationDetailedTable'; // eslint-disable-line

/**
 * VAT Reconciliation Detailed Page Component
 */
function VATReconciliationDetailedPage(props) {
  return <VATReconciliationDetailedTable {...props} />;
}

export default VATReconciliationDetailedPage;
