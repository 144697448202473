import React from 'react';
import { t } from '@lingui/macro';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';

const icon = () => <ButtonIcon as={FiExternalLink} type="link" />;

export const vatOutgoingBalanceFormatter = (data, dataDeviation, term) => {
  const modalData = [];
  const deviation = (data?.glTotal || 0) - (data?.reportedTotal || 0);
  const rows = [];

  data?.glTotalAccounts?.forEach((item) => {
    item?.lines?.forEach((line) => {
      modalData.push({
        period: line.period,
        amount: line.amount,
        description: `${item.accountId} - ${item.accountDescription}`,
        transactionId: line.transactionId,
      });
    });
  });

  rows.push(
    {
      description: t`Outgoing term ${term} balance from GL`,
      amount: data?.glTotal || 0,
      action: {
        id: 'openModal',
        forCell: 'amount',
        icon,
      },
      hideActions: true,
    },
    {
      description: t`Item 19 from term ${term}`,
      amount: data?.reportedTotal || 0,
      hideActions: true,
    },
    {
      description: t`Deviation:`,
      amount: deviation,
      subtitle: {
        exported: true,
      },
      countUp: {
        enabled: true,
        id: 'amount',
        start: data?.glTotal || 0,
      },
    },
  );

  if (Array.isArray(dataDeviation) && dataDeviation.length) {
    rows.push(...dataDeviation);
    rows.push({
      description: t`Unexplained deviation:`,
      amount: deviation - dataDeviation.reduce((sum, el) => sum + el.amount, 0),
      subtitle: {
        exported: true,
      },
      countUp: {
        enabled: true,
        id: 'amount',
        start: deviation,
      },
    });
  }

  return { modalData, rows };
};

export default vatOutgoingBalanceFormatter;
