import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 540,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 160,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 260,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
