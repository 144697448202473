import React from 'react';
import { BsCheckLg } from '@react-icons/all-files/bs/BsCheckLg';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { t } from '@lingui/macro';

const width = {
  vat: 360,
  payroll: 720,
  reportMode: {
    vat: 300,
    payroll: 600,
  },
};

/**
 * Oranization Altinn Setup Table Grid
 */
export const columns = ({ reportMode }, tab) => [
  {
    width: 40,
    Header: ' ',
    disableSortBy: true,
    columns: [
      {
        width: 40,
        hidden: reportMode,
        accessor: 'selection',
        className: 'flex-center',
        disableSortBy: true,
        cell: {
          type: 'selection',
          disableClick: true,
        },
      },
    ],
  },
  {
    width: reportMode ? 120 : 240,
    Header: '  ',
    disableSortBy: true,
    columns: [
      {
        width: reportMode ? 120 : 240,
        Header: t`Company name`,
        accessor: 'companyName',
        className: '',
        withTooltip: true,
      },
    ],
  },
  {
    width: reportMode ? width.reportMode[tab] : width[tab],
    Header: t`Period Status`,
    withTooltip: false,
    className: 'flex-center',
    disableSortBy: true,
    columns: Array.from(Array(tab === 'payroll' ? 12 : 6).keys()).map(
      (index) => ({
        width: reportMode ? 50 : 60,
        Header: index + 1,
        id:
          tab === 'payroll'
            ? `periodAltinnStatusList-${index}`
            : `periodVatAltinnStatusList-${index}`,
        accessor:
          tab === 'payroll'
            ? 'periodAltinnStatusList'
            : 'periodVatAltinnStatusList',
        className: 'flex-center',
        withTooltip: !reportMode,
        disableSortBy: true,
        cell: {
          converter: (item, _, __, forExport) =>
            // eslint-disable-next-line no-nested-ternary
            forExport ? (
              item[index]?.isUpdated ? (
                '✔'
              ) : (
                '✖'
              )
            ) : item[index]?.isUpdated ? (
              <BsCheckLg style={{ color: '#17c900' }} />
            ) : (
              <MdClose style={{ color: '#e22918', fontSize: 20 }} />
            ),
        },
      }),
    ),
  },
  {
    width: reportMode ? 80 : 160,
    Header: ' ',
    disableSortBy: true,
    columns: [
      {
        width: reportMode ? 80 : 160,
        Header: t`Altinn Status`,
        accessor:
          tab === 'payroll' ? 'overallAltinnStatus' : 'overallAltinnVatStatus',
        className: '',
        withTooltip: true,
        disableSortBy: true,
        cell: {
          converter: (value) => {
            if (value === null) {
              return t`Not Updated`;
            }
            if (value) {
              return t`OK`;
            }
            return t`Failed`;
          },
        },
      },
    ],
  },
];

export default { columns };
