import React, { useState, useEffect, useMemo } from 'react';
import _get from 'lodash-es/get';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { AdminCompanySettingsModal } from 'components';

import { resourceListReadSuccess } from 'store/resource/actions';
import { fromCompany, fromResource } from 'store/selectors';
import {
  companyInfoRequest,
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
  updateCurrentUserSuccess,
} from 'store/actions';
import {
  getUsersApi,
  update24SOApi,
  editCompanyApi,
  updateXledgerApi,
  createCompanyApi,
  getCountryListApi,
  updateVismaClient,
  updateTripletexApi,
  updatePowerOfficeApi,
  getFeatureUnleashApi,
  setupVismaBusinessApi,
  currentUserDetailsApi,
  getGroupCompanyListApi,
  checkXledgerUserUseApi,
  verifyXledgerAccountApi,
  verifyTwentyFourSevenApi,
  verifyPowerOfficeTokenApi,
  validateTripletexTokenApi,
  reValidateXledgrAccountApi,
  getAccountingSystemListApi,
  getVismaBusinessClientsApi,
  reValidateTripletexTokenApi,
  reValidateTwentyFourSevenApi,
  getVatReportFrequencyListApi,
  getIsCompanyGroupRequiredApi,
  reValidatePowerOfficeTokenApi,
  checkVismaIntegrationStatusApi,
  checkXledgerOrganizationUserApi,
  checkHasTripletexOrganizationApi,
  getTripletexOrganizationTokenApi,
  isValidIntegration as isValidIntegrationApi,
  createTripletexCompanyApi,
} from 'services/apihelpers';
import { getErrorMessage } from 'services/helpers';

function AdminCompanySettingsContainer(props) {
  const {
    match,
    payload,
    modalAction,
    toggleModal,
    organizationAltinnAvailability,
  } = props;
  const dispatch = useDispatch();
  const company = useSelector((state) => fromCompany.getCompany(state));

  const [needRefetch, setNeedRefetch] = useState(false);
  const [useXledgerUser, setUseXledgerUser] = useState(false);
  const [isValidIntegration, setIsValidIntegration] = useState(false);
  const [hasTripletexOrganization, setHasTripletexOrganization] =
    useState(false);
  const [changeAccountCredentials, setChangeAccountCredentials] =
    useState(false);
  const [hasVismaIntegrationStatus, setHasVismaIntegrationStatus] =
    useState(false);
  const [hasXledgerOrganizationUser, setHasXledgerOrganizationUser] =
    useState(false);
  const [vismaDatabaseCode, setVismaDatabaseCode] = useState(
    payload?.vismaDatabaseCode?.slice(1) || '',
  );

  const getAccountingSystemsLoading = useSelector((state) =>
    pending(state, `${getAccountingSystemListApi}ListRead`),
  );
  const getUsersLoading = useSelector((state) =>
    pending(state, `${getUsersApi}ListRead`),
  );
  const getCountriesLoading = useSelector((state) =>
    pending(state, `${getCountryListApi}ListRead`),
  );
  const getGroupsLoading = useSelector((state) =>
    pending(state, `${getGroupCompanyListApi}ListRead`),
  );
  const isGroupRequiredLoading = useSelector((state) =>
    pending(state, `${getIsCompanyGroupRequiredApi}ListRead`),
  );
  const getVismaClientsLoading = useSelector((state) =>
    pending(state, `${getVismaBusinessClientsApi}ListRead`),
  );
  const checkVismaStatusLoading = useSelector((state) =>
    pending(state, `${checkVismaIntegrationStatusApi}ListRead`),
  );
  const updatingVisma = useSelector((state) =>
    pending(state, `${updateVismaClient}Create`),
  );
  const checkXledgerUseLoading = useSelector((state) =>
    pending(
      state,
      `${checkXledgerUserUseApi}/${
        payload?.companyId || payload?.companySID
      }ListRead`,
    ),
  );
  const checkXledgerOrganizationLoading = useSelector((state) =>
    pending(
      state,
      `${checkXledgerOrganizationUserApi}/${company?.organizationId}ListRead`,
    ),
  );
  const validatingVisma = useSelector((state) =>
    pending(state, `${setupVismaBusinessApi}Create`),
  );
  const loading = useSelector((state) =>
    pending(state, `${createCompanyApi}Create`),
  );
  const validatingToken = useSelector((state) =>
    pending(state, `${validateTripletexTokenApi}ListRead`),
  );
  const loadingFrequencyList = useSelector((state) =>
    pending(state, `${getVatReportFrequencyListApi}ListRead`),
  );
  const reValidatingTripletexDetails = useSelector((state) =>
    pending(state, `${reValidateTripletexTokenApi}ListRead`),
  );
  const reValidatingtwentyFourSevenDetails = useSelector((state) =>
    pending(state, `${reValidateTwentyFourSevenApi}ListRead`),
  );
  const reValidatingXledgerDetails = useSelector((state) =>
    pending(state, `${reValidateXledgrAccountApi}ListRead`),
  );
  const validatingtwentyFourSevenDetails = useSelector((state) =>
    pending(state, `${verifyTwentyFourSevenApi}ListRead`),
  );
  const validatingPowerOfficeToken = useSelector((state) =>
    pending(state, `${verifyPowerOfficeTokenApi}ListRead`),
  );
  const validatingXledgerDetails = useSelector((state) =>
    pending(state, `${verifyXledgerAccountApi}ListRead`),
  );

  const accountingSystemList = useSelector((state) =>
    fromResource.getList(state, getAccountingSystemListApi),
  );
  const userListByCompany = useSelector((state) =>
    fromResource.getList(state, getUsersApi),
  );
  const forAdminTripletexCompanies = useSelector((state) =>
    fromResource.getList(state, validateTripletexTokenApi),
  );
  const twentyFourSevenResponse = useSelector((state) =>
    fromResource.getList(state, verifyTwentyFourSevenApi),
  );
  const countryList = useSelector((state) =>
    fromResource.getList(state, getCountryListApi),
  );
  const companyGroupsList = useSelector((state) =>
    fromResource.getList(state, getGroupCompanyListApi),
  );
  const isCompanyGroupRequired = useSelector((state) =>
    fromResource.getList(state, getIsCompanyGroupRequiredApi),
  );
  const twentyFourSevenRevalidatedResponse = useSelector((state) =>
    fromResource.getList(state, reValidateTwentyFourSevenApi),
  );
  const tripleTexRevalidatedResponse = useSelector((state) =>
    fromResource.getList(state, reValidateTripletexTokenApi),
  );
  const xledgerRevalidatedResponse = useSelector((state) =>
    fromResource.getList(state, reValidateXledgrAccountApi),
  );
  const vismaClientList = useSelector((state) =>
    fromResource.getList(state, getVismaBusinessClientsApi),
  );
  const frequencyList = useSelector((state) =>
    fromResource.getList(state, getVatReportFrequencyListApi),
  );
  const isValidationWarningEnabled = useSelector((state) =>
    fromResource.getList(state, `${getFeatureUnleashApi}/validation-warning`),
  )?.status;

  const defaultValues = useMemo(() => {
    if (!modalAction) {
      return {
        country: 'NO',
        periodtypes: 'monthly',
        companyname: '',
        registrationNumber: '',
        adminVATReportingFrequency: '1',
      };
    }

    const formValues = {};

    if (!isEmpty(payload)) {
      const { companySettings } = payload;
      const cma = ['Visma Business', 'On Property - SQL'].includes(
        payload.accountSystem,
      );

      formValues.payrollEnabled = companySettings?.payrollEnabled || false;
      formValues.vatEnabled = companySettings?.vatEnabled || false;
      formValues.assetsEnabled = companySettings?.assetsEnabled || false;
      formValues.cma = cma ? payload.accountSystem : '';
      formValues.companyname = payload.name;
      formValues.vismaDatabaseCode = payload?.vismaDatabaseCode?.slice(1) || '';
      formValues.registrationNumber = payload.registrationNumber;
      formValues.periodnumber = payload.periodNo;
      formValues.periodtypes = payload.periodType;
      formValues.accountingsystem = cma
        ? 'ConnectMyApps'
        : payload.accountSystem || '';
      formValues.username = payload.accountSystemUsername;
      formValues.password = payload.accountSystemPassword;
      formValues.entityCode = payload.accountSystemIEntityCode;
      formValues.country = payload.countryCode;
      formValues.useOrganizationCredentials =
        payload.hasOrganizationAltinnSettings || false;
      formValues.useXledgerOrganizationUser =
        payload.useXledgerOrganizationUser || false;
      formValues.user = payload.accountHolder;
      formValues.adminVATReportingFrequency =
        companySettings?.vatReportFrequency?.vatReportFrequencyId || '';
      formValues.twentyFourSeven = JSON.stringify({
        clientId: payload.twentyFourSevenCompanyName
          ? parseInt(payload.twentyFourSevenCompanyName.split('-')[1], 10)
          : '',
        clientCompanyName: payload.twentyFourSevenCompanyName
          ? payload.twentyFourSevenCompanyName.split('-')[0]
          : '',
        valueId: payload.twentyFourSevenDecryptedId
          ? payload.twentyFourSevenDecryptedId
          : '',
      });
      formValues.altinnUsername = payload.altinnUsername || '';
      formValues.altinnPassword = '';
      formValues.useOrganizationToken = false;
      formValues.companyGroupIds = payload.companyGroupIds;
    }

    return formValues;
  }, [modalAction, JSON.stringify(payload)]);

  const fetchData = async () => {
    let useXledgerUserTemp = false;
    const hasVismaIntegrationStatusTemp = (
      await dispatch(resourceListReadRequest(checkVismaIntegrationStatusApi))
    )?.status;
    const hasXledgerOrganizationUserTemp = (
      await dispatch(
        resourceListReadRequest(
          `${checkXledgerOrganizationUserApi}/${company.organizationId}`,
        ),
      )
    )?.status;

    if (modalAction) {
      useXledgerUserTemp = (
        await dispatch(
          resourceListReadRequest(
            `${checkXledgerUserUseApi}/${
              payload?.companyId || payload?.companySID
            }`,
          ),
        )
      ).status;
    }

    setUseXledgerUser(useXledgerUserTemp);
    setHasVismaIntegrationStatus(hasVismaIntegrationStatusTemp);
    setHasXledgerOrganizationUser(hasXledgerOrganizationUserTemp);

    try {
      dispatch(resourceListReadRequest(getCountryListApi));
      dispatch(
        resourceListReadRequest(getUsersApi, {
          organizationId: company.organizationId,
        }),
      );
      dispatch(resourceListReadRequest(getAccountingSystemListApi));
      if (hasVismaIntegrationStatusTemp) {
        dispatch(resourceListReadRequest(getVismaBusinessClientsApi));
      }
      dispatch(
        resourceListReadRequest(getVatReportFrequencyListApi, {
          countryCode: company.countryCode,
        }),
      );
      dispatch(resourceListReadRequest(getGroupCompanyListApi));
      dispatch(resourceListReadRequest(getIsCompanyGroupRequiredApi));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const changeAccountingSystemCredentials = (value) => {
    if (value) {
      setChangeAccountCredentials(value);
    } else {
      setChangeAccountCredentials((state) => !state);
    }
  };

  const revalidateAccountingSystemCredentials = async (
    accountingSystem,
    trigger,
  ) => {
    let response = {};
    const query = { companyId: payload?.companyId || payload?.companySID };

    try {
      switch (accountingSystem) {
        case '24SEVEN OFFICE':
          response = await dispatch(
            resourceListReadRequest(reValidateTwentyFourSevenApi, query),
          );
          break;
        case 'TRIPLETEX':
          response = await dispatch(
            resourceListReadRequest(reValidateTripletexTokenApi, query),
          );
          break;
        case 'POWER OFFICE':
          response = await dispatch(
            resourceListReadRequest(reValidatePowerOfficeTokenApi, query),
          );
          break;
        case 'XLEDGER':
          if (trigger) {
            response = await dispatch(
              resourceListReadRequest(reValidateXledgrAccountApi, query),
            );
          }

          break;
        default:
          break;
      }

      setIsValidIntegration(
        typeof response.status !== 'undefined'
          ? response.status
          : !isEmpty(response),
      );
      toast.success(t`Your token is valid`);
      resourceListReadRequest(isValidIntegrationApi, {
        companyId: payload?.uuid,
      });
    } catch (error) {
      setIsValidIntegration(false);
      toast.error(getErrorMessage(error));
      dispatch(
        resourceListReadRequest(isValidIntegrationApi, {
          companyId: payload?.uuid,
        }),
      );
    }
  };

  const validateToken = async (type, token) => {
    try {
      let response = {};

      if (type === 'TRIPLETEX') {
        response = await dispatch(
          resourceListReadRequest(validateTripletexTokenApi, {
            companyId: payload?.companyId || payload?.companySID,
            employeeToken: token,
          }),
        );
      } else if (type === 'POWER OFFICE') {
        response = await dispatch(
          resourceListReadRequest(verifyPowerOfficeTokenApi, {
            companyId: payload?.companyId || payload?.companySID,
            clientKey: token,
          }),
        );
      }

      setIsValidIntegration(
        typeof response.status !== 'undefined'
          ? response.status
          : !isEmpty(response),
      );
      toast.success(t`Your token is valid`);
      dispatch(
        resourceListReadRequest(isValidIntegrationApi, {
          companyId: payload?.uuid,
        }),
      );
    } catch (error) {
      setIsValidIntegration(false);
      toast.error(getErrorMessage(error));
      dispatch(
        resourceListReadRequest(isValidIntegrationApi, {
          companyId: payload?.uuid,
        }),
      );
    }
  };

  const validateXledgerValues = async (values) => {
    const query = {
      companyId: payload?.companyId || payload?.companySID,
      username: values.username || payload?.accountSystemUsername,
      password: values.password,
      entityCode: values.entityCode || payload?.accountSystemIEntityCode,
      useOrganizationXledgerUser: JSON.parse(
        values.useXledgerOrganizationUser || false,
      ),
    };

    try {
      const response = await dispatch(
        resourceListReadRequest(verifyXledgerAccountApi, query),
      );
      setIsValidIntegration(response.status);
      toast.success(t`Your credentials are valid`);
    } catch (error) {
      setIsValidIntegration(false);
      toast.error(getErrorMessage(error));
    }
  };

  const validate247Values = async (values) => {
    const query = {
      username: values.username || payload?.accountSystemUsername,
      password: values.password,
      companyId: payload?.companyId || payload?.companySID,
    };

    try {
      await dispatch(resourceListReadRequest(verifyTwentyFourSevenApi, query));

      toast.success(t`Your credentials are valid`);
      setIsValidIntegration(true);
    } catch (error) {
      setIsValidIntegration(false);
      toast.error(getErrorMessage(error));
    }
  };

  const submitIntegrations = async (formData) => {
    const twentyFourSeven =
      (formData.twentyFourSeven &&
        JSON.parse(_get(formData, 'twentyFourSeven', {}))) ||
      {};
    const tripleTex =
      (formData.tripletexcompanies &&
        JSON.parse(_get(formData, 'tripletexcompanies', {}))) ||
      {};

    try {
      switch (formData.accountingsystem) {
        case 'TRIPLETEX': {
          const query = {
            companyId: payload?.companyId || payload?.companySID,
            token: formData.token,
            tripletexCompanyName:
              tripleTex.tripleTexCompanyName || tripleTex.tripleTexId
                ? `${tripleTex.tripleTexCompanyName}-${tripleTex.tripleTexId}`
                : '',
            tripletexCompanyId: tripleTex.tripleTexId.toString(),
          };
          await dispatch(
            resourceUpdateRequest(updateTripletexApi, null, query),
          );
          break;
        }

        case 'POWER OFFICE': {
          const query = {
            companyId: payload?.companyId || payload?.companySID,
            token:
              formData.accountingsystem === 'POWER OFFICE'
                ? formData.token
                : '',
          };
          await dispatch(
            resourceUpdateRequest(updatePowerOfficeApi, null, query),
          );
          break;
        }

        case 'XLEDGER': {
          const query = {
            companyId: payload?.companyId || payload?.companySID,
            useXledgerOrganizationUser: JSON.parse(
              formData.useXledgerOrganizationUser || false,
            ),
            username: formData.username || '',
            password: formData.password || '',
            entityCode: formData.entityCode || '',
          };
          await dispatch(resourceUpdateRequest(updateXledgerApi, null, query));
          break;
        }

        case '24SEVEN OFFICE': {
          let companyName = '';
          if (
            twentyFourSeven.clientId &&
            twentyFourSeven.clientId !== '' &&
            twentyFourSeven.clientCompanyName !== ''
          ) {
            // eslint-disable-next-line max-len
            companyName = `${twentyFourSeven.clientCompanyName}-${twentyFourSeven.clientId}`;
          }
          const query = {
            companyId: payload?.companyId || payload?.companySID,
            username: formData.username || '',
            password: formData.password,
            twentyFourSevenId: twentyFourSeven.valueId || '',
            twentyFourSevenCompanyName: companyName,
          };
          await dispatch(resourceUpdateRequest(update24SOApi, null, query));
          break;
        }

        case 'ConnectMyApps': {
          const query = {
            databaseCode: vismaDatabaseCode,
            companySid: payload?.companyId || payload?.companySID,
          };

          await dispatch(resourceCreateRequest(updateVismaClient, query));
          break;
        }

        default: {
          break;
        }
      }

      setIsValidIntegration(false);
      toast.success(t`Integrations were updated successfully`);

      dispatch(
        resourceListReadRequest(isValidIntegrationApi, {
          companyId: payload?.uuid,
        }),
      );
      setNeedRefetch(true);
    } catch (error) {
      toast.error(getErrorMessage(error));
      dispatch(
        resourceListReadRequest(isValidIntegrationApi, {
          companyId: payload?.uuid,
        }),
      );
    }
  };

  const changeValidatedValue = (value) => setIsValidIntegration(value);

  const clear24andTripletex = () => {
    dispatch(resourceListReadSuccess(validateTripletexTokenApi, null));
    dispatch(resourceListReadSuccess(reValidateTripletexTokenApi, null));
    dispatch(resourceListReadSuccess(verifyTwentyFourSevenApi, null));
    dispatch(resourceListReadSuccess(reValidateTwentyFourSevenApi, null));
  };

  const handleSubmit = async (formData) => {
    const twentyFourSeven =
      (formData.twentyFourSeven &&
        JSON.parse(_get(formData, 'twentyFourSeven', {}))) ||
      {};
    const tripleTex =
      (formData.tripletexcompanies &&
        JSON.parse(_get(formData, 'tripletexcompanies', {}))) ||
      {};
    let accountSystem;
    if (formData.accountingsystem) {
      if (formData.accountingsystem === 'ConnectMyApps') {
        accountSystem = formData.cma;
      } else {
        accountSystem = formData.accountingsystem;
      }
    } else {
      accountSystem = '';
    }

    const query = {
      accountSystem,
      vismaDatabaseCode,
      currentAccountingYear:
        payload?.currentAccountingYear || match?.params?.accountingYear,
      payrollEnabled: JSON.parse(formData.payrollEnabled || false),
      vatEnabled: JSON.parse(formData.vatEnabled || false),
      assetsEnabled: JSON.parse(formData.assetsEnabled || false),
      periodNo: formData.periodnumber,
      companyGroupIds: formData.companyGroupIds || [],
      companySID: payload?.companyId || payload?.companySID, // undefined for new companies
      periodType: formData.periodtypes,
      countryCode: formData.country, // Helper function for this? only use redux form data
      companyName: formData.companyname,
      organizationSID: modalAction ? undefined : company.organizationId, // undefined for new companies
      currentCompanyRegistrationNumber: formData.registrationNumber.replace(
        /\s/g,
        '',
      ),
      isUseMainAltinnData: JSON.parse(
        formData.useOrganizationCredentials || false,
      ),
      useXledgerOrganizationUser: JSON.parse(
        formData.useXledgerOrganizationUser || false,
      ),
      accountSystemPassword: formData.password || '',
      accountSystemUsername: formData.username || '',
      accountSystemIEntityCode: formData.entityCode || '',
      tripletexCompanyName:
        tripleTex.tripleTexCompanyName || tripleTex.tripleTexId
          ? `${tripleTex.tripleTexCompanyName}-${tripleTex.tripleTexId}`
          : '',
      tripletexCompanyId: tripleTex.tripleTexId,
      tripletexEmployeeToken:
        formData.accountingsystem === 'TRIPLETEX' ? formData.token : '',
      accountHolderUserUuid: formData.user,
      altinnUsername: formData.altinnUsername || null,
      altinnPassword: formData.altinnPassword || null,
      powerOfficeGoClientKey:
        formData.accountingsystem === 'POWER OFFICE' ? formData.token : '',
      vatReportFrequencyId: formData.adminVATReportingFrequency,
      twentyFourSevenId: twentyFourSeven.valueId || '',
      twentyFourSevenCompanyName:
        twentyFourSeven.clientCompanyName && twentyFourSeven.clientId
          ? `${twentyFourSeven.clientCompanyName}-${twentyFourSeven.clientId}`
          : '',
    };

    if (query.accountSystem === 'XLEDGER' && query.useXledgerOrganizationUser) {
      delete query.accountSystemUsername;
      delete query.accountSystemPassword;
    }

    try {
      if (modalAction === false) {
        if (query.accountSystem === 'TRIPLETEX') {
          await dispatch(
            resourceCreateRequest(createTripletexCompanyApi, {
              country_code: query.countryCode,
              is_default: false,
              name: query.companyName,
              period_type: query.periodType,
              registration_numbers: query.currentCompanyRegistrationNumber,
              organization_sid: query.organizationSID,
              user_uuid_account_holder: query.accountHolderUserUuid,
              user_uuid_approver: query.accountHolderUserUuid,
              fiscal_year_start: new Date(`${new Date().getFullYear()}-01-01`)
                .toISOString()
                .slice(0, 10),
              fiscal_year_end: new Date(`${new Date().getFullYear()}-12-31`)
                .toISOString()
                .slice(0, 10),
              vat_enabled: query.vatEnabled,
              payroll_enabled: query.payrollEnabled,
              assets_enabled: query.assetsEnabled,
              company_groups: query.companyGroupIds,
              tripletexCompanyName: query.tripletexCompanyName,
              tripletexCompanyId: query.tripletexCompanyId.toString(),
              tripletexEmployeeToken: query.tripletexEmployeeToken,
              altinn_username: query.altinnUsername,
              altinn_password: query.altinnPassword,
              use_altinn_org_credentials: query.isUseMainAltinnData,
            }),
          );
        } else {
          await dispatch(resourceCreateRequest(createCompanyApi, query));
        }

        const newUser = await dispatch(
          resourceListReadRequest(currentUserDetailsApi),
        );

        dispatch(updateCurrentUserSuccess(newUser));

        toast.success(t`Company created successfully`);
      } else {
        const updateQuery = {
          companyId: payload?.companyId || payload?.companySID,
          companyName: formData.companyname,
          assignedUser: formData.user,
          registrationNumber: formData.registrationNumber.replace(/\s/g, ''),
          periodType: formData.periodtypes,
          periodNo: formData.periodnumber,
          vatReportFrequency: Number(formData.adminVATReportingFrequency),
          companyGroupIds: formData.companyGroupIds || [],
          payrollEnabled: JSON.parse(formData.payrollEnabled || false),
          vatEnabled: JSON.parse(formData.vatEnabled || false),
          assetsEnabled: JSON.parse(formData.assetsEnabled || false),
          altinnSystemId: formData.altinnUsername || null,
          altinnSystemPassword: formData.altinnPassword || null,
          useMainAltinnData: JSON.parse(
            formData.useOrganizationCredentials || false,
          ),
          countryCode: formData.country,
          organizationSID: company.organizationId,
        };
        await dispatch(
          resourceUpdateRequest(editCompanyApi, null, updateQuery),
        );
        await dispatch(companyInfoRequest());

        toast.success(t`Company updated successfully`);
      }

      props.closeModal(false, true);
      clear24andTripletex();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const setupVismaBusiness = async (currentValues) => {
    try {
      await dispatch(
        resourceCreateRequest(setupVismaBusinessApi, {
          password: currentValues.password,
          serverName: currentValues.serverName,
          connectorKey: currentValues.connectorKey,
          databaseUsername: currentValues.databaseUsername,
        }),
      );
      await dispatch(resourceListReadRequest(getVismaBusinessClientsApi));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const handleVismaClientSelect = (value) => {
    const selectedItem = value[0];

    setVismaDatabaseCode(!isEmpty(value) ? selectedItem.id : undefined);
  };

  const handleAccountingSystemChange = async (e) => {
    if (e === 'TRIPLETEX' || e?.target?.value === 'TRIPLETEX') {
      const response = await dispatch(
        resourceListReadRequest(checkHasTripletexOrganizationApi, {
          organizationId: company.organizationId,
        }),
      );

      setHasTripletexOrganization(response.status);
    }
  };

  const getTripletexOrganizationToken = async () => {
    const response = await dispatch(
      resourceListReadRequest(getTripletexOrganizationTokenApi, {
        organizationId: company.organizationId,
      }),
    );

    return response.token;
  };

  const getVismaClientsList = () => {
    if (Array.isArray(vismaClientList)) {
      return vismaClientList.map((item) => ({
        ...item,
        id: item.databaseCode,
        label: `${item.databaseCode} - ${item.clientName}`,
      }));
    }

    return [];
  };

  const closeModal = () => {
    if (needRefetch) {
      props.closeModal(false, true);
    } else {
      props.closeModal();
    }

    clear24andTripletex();
  };

  useEffect(() => {
    fetchData();
    handleAccountingSystemChange(defaultValues.accountingsystem);
  }, []);

  const revalidatedResponses = {
    xledgerRevalidatedResponse,
    reValidatingXledgerDetails,
    reValidatingTripletexDetails,
    tripleTexRevalidatedResponse,
    reValidatingtwentyFourSevenDetails,
    twentyFourSevenRevalidatedResponse,
  };

  return (
    <AdminCompanySettingsModal
      {...props}
      data={accountingSystemList}
      onSubmit={handleSubmit}
      countries={countryList}
      vismaClientList={getVismaClientsList()}
      {...{
        loading,
        payload,
        closeModal,
        toggleModal,
        modalAction,
        updatingVisma,
        defaultValues,
        frequencyList,
        validateToken,
        useXledgerUser,
        validatingToken,
        validatingVisma,
        vismaDatabaseCode,
        userListByCompany,
        companyGroupsList,
        validate247Values,
        isValidIntegration,
        submitIntegrations,
        setupVismaBusiness,
        changeValidatedValue,
        revalidatedResponses,
        validateXledgerValues,
        isCompanyGroupRequired,
        handleVismaClientSelect,
        twentyFourSevenResponse,
        hasTripletexOrganization,
        validatingXledgerDetails,
        changeAccountCredentials,
        hasVismaIntegrationStatus,
        forAdminTripletexCompanies,
        isValidationWarningEnabled,
        validatingPowerOfficeToken,
        hasXledgerOrganizationUser,
        handleAccountingSystemChange,
        getTripletexOrganizationToken,
        organizationAltinnAvailability,
        validatingtwentyFourSevenDetails,
        changeAccountingSystemCredentials,
        revalidateAccountingSystemCredentials,

        // FETCH loadings
        getUsersLoading,
        getGroupsLoading,
        getCountriesLoading,
        loadingFrequencyList,
        checkXledgerUseLoading,
        isGroupRequiredLoading,
        getVismaClientsLoading,
        checkVismaStatusLoading,
        getAccountingSystemsLoading,
        checkXledgerOrganizationLoading,
      }}
    />
  );
}

export default AdminCompanySettingsContainer;

AdminCompanySettingsContainer.propTypes = {
  match: PropTypes.shape({}).isRequired,
  payload: PropTypes.shape({}),
  modalAction: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  organizationAltinnAvailability: PropTypes.bool.isRequired,
};

AdminCompanySettingsContainer.defaultProps = {
  payload: undefined,
};
