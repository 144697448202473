import _isEmpty from 'lodash-es/isEmpty';

export const withheldTaxFormatter = (data) => {
  const rows = [];
  if (!_isEmpty(data)) {
    data?.forEach((item) => {
      rows.push({
        id: item.id,
        name: item.name,
        reportedAmount: item.reportedAmount,
        glAmount: item.glAmount,
        difference: item.difference,
        accumulatedReportedAmount: item.accumulatedReportedAmount,
        accumulatedGlAmount: item.accumulatedGlAmount,
        accumulatedDifference: item.accumulatedDifference,
      });
    });
  }
  return rows;
};

export default withheldTaxFormatter;
