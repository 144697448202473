import React, { useEffect } from 'react';
import { pending, rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { UserPreviewModal } from 'components';

import { fromResource } from 'store/selectors';
import { getUserDetailedApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';

function UserPreviewModalContainer(props) {
  const { id, closeModal, handleAction } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getUserDetailedApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${getUserDetailedApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getUserDetailedApi}ListRead`),
  );

  useEffect(() => {
    if (id) {
      dispatch(resourceListReadRequest(getUserDetailedApi, { uuid: id }));
    }
  }, [id]);

  return (
    <UserPreviewModal
      {...{
        data,
        failed,
        loading,
        closeModal,
        handleAction,
      }}
    />
  );
}

export default UserPreviewModalContainer;
