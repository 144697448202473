import React, { useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { t, Trans } from '@lingui/macro';

import { Heading, CustomTableWithAutoSettings } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { calculateRowHeight } from 'services/helpers';
import { payrollPaymentReconciliation } from 'services/tableshapes';
import getErrorMessage from 'services/helpers/getErrorMessage';

function PaymentReconciliation(props) {
  const { data, loading, roleType, toggleModal, tableId } = props;
  const { items, predefinedTotal } = data;

  const [actionSubmitting, setActionSubmitting] = useState({});
  const screenWidth = useCurrentWidth();

  const minHeight = useMemo(
    () => calculateRowHeight(screenWidth),
    [screenWidth],
  );

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          toggleModal('mapSettlements', item.originalItem, item.settlementItem);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Payment reconciliation</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: items,
          tableId,
          loading,
          hideFilters: true,
          minHeight: items?.length ? items.length * minHeight : 100,
          handleAction,
          actionSubmitting,
          columns: payrollPaymentReconciliation(false, roleType.isReadOnly),
          headerConfigs: {
            exportFileName: t`Payroll Social Security - Payment Reconciliation`,
          },
          predefinedTotal,
        }}
      />
    </>
  );
}

export default PaymentReconciliation;
