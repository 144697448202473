import React from 'react';
import { t } from '@lingui/macro';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { HiOutlineRefresh } from '@react-icons/all-files/hi/HiOutlineRefresh';

import { ButtonIcon } from 'components';

import {
  definePeriodTypes,
  defineAccountingSystem,
  sortBooleanCustomTable,
} from 'services/helpers';

/**
 * Admin Company Settings Grid
 */
export const columns = ({ reportMode }, isSuperOrSystemAdmin) => [
  {
    width: 40,
    hidden: reportMode,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 70,
    Header: t`ID`,
    accessor: 'companyId',
    sortType: 'basic',
    className: 'flex-center',
    cell: {
      disableClick: true,
    },
  },
  {
    width: reportMode ? 270 : 365,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
    cell: {
      icon: () => <ButtonIcon as={HiOutlineRefresh} type="link" />,
      buttonClassName: 'absolute-right',
      action: reportMode ? false : 'changeCompany',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 120 : 210,
    Header: t`Registration Number`,
    accessor: 'registrationNumber',
    className: '',
    withTooltip: true,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 140,
    Header: t`Period Type`,
    accessor: 'periodType',
    className: '',
    cell: {
      converter: definePeriodTypes,
      disableClick: true,
    },
  },
  {
    width: 90,
    Header: t`Period Number`,
    accessor: 'periodNo',
    className: 'flex-right',
    cell: {
      disableClick: true,
    },
  },
  {
    width: reportMode ? 180 : 200,
    Header: t`Accounting System`,
    accessor: 'accountSystem',
    className: '',
    cell: {
      converter: defineAccountingSystem,
      disableClick: true,
    },
  },
  {
    width: reportMode ? 180 : 200,
    Header: t`Assignee`,
    accessor: 'accountHolderName',
    className: '',
    withTooltip: !reportMode,
    cell: {
      disableClick: true,
    },
  },
  {
    width: 100,
    Header: t`Integration Status`,
    sortType: sortBooleanCustomTable,
    accessor: 'isValidIntegration',
    className: 'flex-center',
    cell: {
      type: 'boolean',
      disableClick: true,
    },
  },
  {
    width: 100,
    Header: t`Activated`,
    sortType: sortBooleanCustomTable,
    accessor: 'recordStatus',
    className: 'flex-center',
    cell: {
      type: 'boolean',
      disableClick: true,
    },
  },
  ...(isSuperOrSystemAdmin
    ? [
        {
          width: 100,
          Header: t`Secret`,
          sortType: sortBooleanCustomTable,
          accessor: 'secret',
          className: 'flex-center',
          cell: {
            type: 'boolean',
          },
        },
      ]
    : []),
  {
    width: 85,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'companyPreview',
          icon: () => <FaRegEye />,
          tooltip: t`Detailed`,
        },
        {
          id: 'edit',
          icon: () => <GoPencil />,
          tooltip: t`Edit`,
          visible: (item) => !item.unavailable,
        },
      ],
    },
  },
];

export default { columns };
