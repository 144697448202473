import React, { useState, useRef, useMemo } from 'react';
import { DayPicker as DatePicker } from 'react-day-picker';
import styled, { keyframes } from 'styled-components/macro';
import { i18n } from '@lingui/core';
import { enUS, nb } from 'date-fns/locale';
import { isValid, parse } from 'date-fns';

import { Input } from 'components';

import { formatDate, getDateWithoutTimezoneOffset } from 'services/helpers';

function DayPicker(props) {
  const { value, min, onChange, id, range, isDisabled } = props;
  const [selectedDay, setSelectedDay] = useState(new Date(value));
  const [month, setMonth] = useState(new Date(value));
  const [inputValue, setInputValue] = useState(formatDate(value));
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);

  const { fromYear, toYear, fromMonth, disabled } = useMemo(() => {
    const currentYear = new Date().getFullYear();

    if (range) {
      return {
        fromYear: currentYear - range,
        toYear: currentYear + range,
      };
    }

    if (min) {
      const fromDate = new Date(min);
      const minDateYear = fromDate.getFullYear();

      return {
        fromMonth: fromDate,
        fromYear: minDateYear,
        toYear: minDateYear + 5,
        disabled: {
          before: new Date(min),
        },
      };
    }

    return {
      fromYear: currentYear - 5,
      toYear: currentYear + 5,
    };
  }, [min, range]);

  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef.current.focus();
  };

  const handleDaySelect = (currentDate) => {
    setSelectedDay(currentDate);
    if (currentDate) {
      setInputValue(formatDate(currentDate));
      closePopper();
      onChange({
        target: { value: getDateWithoutTimezoneOffset(currentDate), id },
      });
    } else {
      setInputValue('');
      onChange({ target: { value: '', id } });
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // keep the input value in sync

    const parsedDate = parse(
      e.target.value,
      i18n.locale === 'nb' ? 'dd.MM.yyyy' : 'MM/dd/yyyy',
      new Date(),
    );

    if (min) {
      if (isValid(parsedDate) && new Date(min) > parsedDate) {
        setSelectedDay(parsedDate);
        onChange({
          target: { value: getDateWithoutTimezoneOffset(parsedDate), id },
        });
      }
    }
    if (isValid(parsedDate)) {
      setSelectedDay(parsedDate);
      onChange({
        target: { value: getDateWithoutTimezoneOffset(parsedDate), id },
      });
      setMonth(parsedDate);
    } else {
      setSelectedDay(undefined);
      setMonth(new Date());
    }
  };

  const onBlur = (e) => {
    const picker = document.querySelector('.rdp-month');
    if (!picker?.contains(e.relatedTarget)) {
      setTimeout(() => {
        setIsPopperOpen(false);
      }, 100);
    }
  };

  return (
    <>
      <Wrapper ref={popperRef}>
        <Input
          type="text"
          value={inputValue}
          placeholder={i18n.locale === 'nb' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'}
          disabled={isDisabled}
          onChange={handleInputChange}
        />
        <Button
          ref={buttonRef}
          type="button"
          onClick={handleButtonClick}
          disabled={isDisabled}
          className="pa2 button-reset ba"
          aria-label="Pick a date"
          data-testid="btn"
        >
          <span role="img" aria-label="calendar icon">
            📅
          </span>
        </Button>
      </Wrapper>
      {isPopperOpen && (
        <div onBlur={onBlur}>
          <DayPick
            mode="single"
            locale={i18n.locale === 'nb' ? nb : enUS}
            selected={selectedDay}
            fromYear={fromYear}
            toYear={toYear}
            fromMonth={fromMonth}
            disabled={disabled}
            onSelect={handleDaySelect}
            initialFocus={isPopperOpen}
            captionLayout="dropdown"
            month={month}
            onMonthChange={setMonth}
          />
        </div>
      )}
    </>
  );
}

export default DayPicker;

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Button = styled.button`
  top: 5px;
  right: 5px;
  border: none;
  position: absolute;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

const Animation = keyframes`
  0% { opacity: 0.5 }
  100% { opacity: 1 }
`;

export const DayPick = styled(DatePicker)`
  .rdp-month {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #e8e7e3;
    animation-name: ${Animation};
    animation-duration: 0.3s;
  }
  .rdp-day_selected:not([aria-disabled='true']),
  .rdp-day_selected:focus:not([aria-disabled='true']) {
    background-color: #eb5e28;
    border: none;
  }
  .rdp-day:hover {
    background-color: rgba(235, 94, 40, 0.2);
  }
  .rdp-day_today_selected {
    border: none;
    background-color: rgba(235, 94, 40, 0.1);
  }
  input {
    width: 100%;
  }
`;
