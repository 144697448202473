import React, { useState } from 'react';

import { CreateReconciliationModal } from 'components';

const defaultDate = (date) =>
  date
    ? new Date(date).toISOString().slice(0, 10)
    : new Date().toISOString().slice(0, 10);

function CreateReconciliationModalContainer(props) {
  const { row } = props;

  const [contraAccrualAccount, setContraAccrualAccount] = useState(null);
  const [dates, setDates] = useState({
    from: defaultDate(row?.valueDate),
    to: null,
  });
  const [comment, setComment] = useState(row?.comment || '');

  const setDate = (name, e) =>
    setDates((prevState) => ({ ...prevState, [name]: e.target.value || null }));

  const changeComment = (e) => setComment(e.target.value);

  return (
    <CreateReconciliationModal
      {...props}
      {...{
        dates,
        setDate,
        comment,
        changeComment,
        contraAccrualAccount,
        setContraAccrualAccount,
      }}
    />
  );
}

export default CreateReconciliationModalContainer;
