/* eslint-disable import/no-import-module-exports */
import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';
import immutableStateInvariant from 'redux-immutable-state-invariant';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { config } from 'config';

import { api } from 'services/api';
import middlewares from './middlewares';
import rootReducer from './reducer';
import sagas from './sagas';

if (config.isDev) {
  middlewares.push(immutableStateInvariant());
}

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const combinedReducers = combineReducers({
  ...rootReducer,
  router: routerReducer,
});

const configureStore = (initialState, services = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const enhancers = [
    applyMiddleware(routerMiddleware, ...middlewares, sagaMiddleware),
  ];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(combinedReducers, initialState, composedEnhancers);
  let sagaTask = sagaMiddleware.run(sagas, services);

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextReducer = require('./reducer').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
    module.hot.accept('./sagas', () => {
      const nextSagas = require('./sagas').default; // eslint-disable-line global-require
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(nextSagas, services);
      });
    });
  }

  return store;
};

const store = configureStore({}, { api: api.create() }); // Create the store with default initial state
export const history = createReduxHistory(store);
export default store;
