import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AddEditGroupUserModal from 'containers/AddEditGroupUserModal';
import { GroupUserTable, PreviewGroupUsersModal } from 'components';

import {
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  userGroupSecretApi,
  deleteGroupUserApi,
  getGroupUserListApi,
} from 'services/apihelpers';
import { fromResource } from 'store/selectors';
import { toGroupUserDetailed } from 'services/routehelpers';
import { resourceListReadSuccess } from 'store/resource/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

function GroupUserContainer(props) {
  const { roleType } = props;

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const data = useSelector((state) =>
    fromResource.getList(state, getGroupUserListApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getGroupUserListApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(resourceListReadRequest(getGroupUserListApi));
  };
  const getSearch = () => {
    const search = location
      ? queryString.parse(location.search, { parseBooleans: true })
      : {};

    return {
      tabEntry: search.tabEntry || 'user',
      reportMode: search.reportMode || false,
    };
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };
  const handleDeleteGroup = async (item) => {
    try {
      _setActionSubmitting(item.groupId, true);

      await dispatch(
        resourceDeleteRequest(
          `${deleteGroupUserApi}?${queryString.stringify({
            groupId: item.groupId,
          })}`,
        ),
      );

      const collection = data.filter((el) => el.groupId !== item.groupId);

      dispatch(resourceListReadSuccess(getGroupUserListApi, collection));

      _setActionSubmitting(item.groupId, false);
    } catch (error) {
      _setActionSubmitting(item.groupId, false);
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const handleDetailedRedirect = (id) => {
    history.push(toGroupUserDetailed({}, id));
  };
  const renderModal = () => {
    let item;

    switch (modalProps.type) {
      case 'add':
      case 'edit': {
        item = (
          <AddEditGroupUserModal
            item={modalProps.item}
            closeModal={closeModal}
          />
        );
        break;
      }

      case 'viewUsers': {
        item = (
          <PreviewGroupUsersModal
            item={modalProps.item}
            closeModal={closeModal}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return item;
  };

  const changeSecretStatus = async (company) => {
    const { groupId, secret } = company;

    _setActionSubmitting(groupId, true);

    try {
      if (secret) {
        await dispatch(
          resourceDeleteRequest(
            `${userGroupSecretApi}?${queryString.stringify({
              groupId,
            })}`,
          ),
        );
      } else {
        await dispatch(
          resourceCreateRequest(userGroupSecretApi, { id: groupId }),
        );
      }

      const newData = data.map((item) =>
        item.groupId === groupId ? { ...item, secret: !secret } : item,
      );

      await dispatch(resourceListReadSuccess(getGroupUserListApi, newData));

      toast.success(t`Secret field updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      _setActionSubmitting(groupId, false);
    }
  };

  const handleAction = async (action, _props, column) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'add': {
          setModalProps({ type: 'add' });
          break;
        }

        case 'edit': {
          setModalProps({ type: 'edit', item: _props });
          break;
        }

        case 'delete': {
          await handleDeleteGroup(_props);
          break;
        }

        case 'viewUsers': {
          setModalProps({ type: 'viewUsers', item: _props });
          break;
        }

        case 'cellClick': {
          if (column === 'secret') {
            await changeSecretStatus(_props);
          } else {
            handleDetailedRedirect(_props.groupId, 'user');
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const search = getSearch();

  useEffect(fetchData, [JSON.stringify(params)]);

  return (
    <>
      {renderModal()}
      <GroupUserTable
        {...{
          data,
          search,
          loading,
          roleType,
          actionSubmitting,
          title: t`User Group`,
          handleAction,
        }}
      />
    </>
  );
}

export default GroupUserContainer;
