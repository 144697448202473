import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { plural } from '@lingui/macro';
import { Col } from 'react-bootstrap';
import { Pagination, Paragraph } from 'components';
import { pageSelectors } from 'services/helpers';

/**
 * Table Pagination component
 */

function TablePagination(props) {
  const {
    page,
    results,
    entries,
    onSelect,
    pullLeft,
    pullRight,
    ...restProps
  } = props;
  const pageSelector = pageSelectors(page, results, entries);

  const classes = classNames({
    'pull-left': pullRight ? undefined : pullLeft,
    'pull-right': pullRight,
  });

  const text = (
    <Paragraph>
      <br />
      {plural(pageSelector.pages, {
        zero: 'No results',
        one: `Showing ${results} entry`,
        other: `Showing ${pageSelector.startIndex + 1}-${
          pageSelector.endIndex
        } of ${results} entries`,
      })}
    </Paragraph>
  );

  const pagination = (
    <Pagination
      items={pageSelector.pages}
      onSelect={onSelect}
      activePage={page}
      {...restProps}
    />
  );

  return (
    <Col sm={6}>
      <span className={classes}>
        {text}
        {pageSelector.pages > 1 && pagination}
      </span>
    </Col>
  );
}

TablePagination.propTypes = {
  page: PropTypes.number,
  results: PropTypes.number,
  entries: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,
};

TablePagination.defaultProps = {
  page: 0,
  results: 0,
  pullLeft: true,
  pullRight: undefined,
};

export default TablePagination;
