import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components/macro';
import { NavItem as BootStrapNavItem } from 'react-bootstrap';

function SidebarNavItem(props) {
  const {
    to,
    icon,
    href,
    role,
    active,
    onClick,
    disabled,
    eventKey,
    onSelect,
    children,
    shortTitle,
  } = props;
  return (
    <LinkContainer
      to={to}
      data-menu-item={children.split(' ').join('-').toLowerCase()}
    >
      <BootStrapNavItem
        href={href}
        role={role}
        active={active}
        onClick={onClick}
        onSelect={onSelect}
        disabled={disabled}
        eventKey={eventKey}
      >
        {icon && <Icon>{icon}</Icon>}
        {icon && <Paragraph>{children}</Paragraph>}
        {!icon && <span className="sidebar-mini">{shortTitle}</span>}
        {!icon && <span className="sidebar-normal">{children}</span>}
      </BootStrapNavItem>
    </LinkContainer>
  );
}

SidebarNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  role: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  eventKey: PropTypes.number,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  shortTitle: PropTypes.string,
};

SidebarNavItem.defaultProps = {
  icon: undefined,
  href: undefined,
  role: undefined,
  active: false,
  onClick: undefined,
  disabled: false,
  eventKey: undefined,
  onSelect: undefined,
  children: undefined,
  shortTitle: undefined,
};

export default SidebarNavItem;

const Icon = styled.i`
  font-size: 25px;
`;
