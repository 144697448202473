import { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { getVatSettlementsApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useVATSettlementsData = (props) => {
  const { currentCompanySID, accountingYear, activePeriods } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const term = Math.ceil(activePeriods / 2);

  const data = useSelector((state) =>
    fromResource.getList(state, `${getVatSettlementsApi}/${currentCompanySID}`),
  );

  const rows = useMemo(
    () =>
      Array.isArray(data) ? data.filter((item) => +item.term <= +term) : [],
    [data, term],
  );

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(
          `${getVatSettlementsApi}/${currentCompanySID}`,
          { year: accountingYear },
        ),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentCompanySID, accountingYear]);

  return { rows, loading, fetchData };
};

export default useVATSettlementsData;
