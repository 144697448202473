import React from 'react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import {
  DropdownButton,
  MenuItem,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

function Dropdown(props) {
  const { id, title, disabled, options, onItemClick = () => {} } = props;

  return (
    <DropdownButton title={title} id={id} disabled={disabled}>
      {options.map((option) => (
        <MenuItem
          key={option.id}
          eventKey={option.id}
          onClick={() => onItemClick(option)}
        >
          {option.title}
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">{option.tooltip}</Tooltip>}
            placement="bottom"
          >
            <FaInfoCircle style={{ marginLeft: 6 }} />
          </OverlayTrigger>
        </MenuItem>
      ))}
    </DropdownButton>
  );
}

export default Dropdown;
