import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { Paragraph, Spinner } from 'components';

import BankTemplateNew from 'containers/BankTemplateNew';
import GeneralTemplate from 'containers/GeneralTemplate';
import SimpleLedgerTemplate from 'containers/SimpleLedgerTemplate';

import { fromResource } from 'store/selectors';
import { getReconciliationGLAccountsFastApi } from 'services/apihelpers';

/**
 * TemplateSelector component
 */
function TemplateSelector(props) {
  const { params, account, company, history, location, roleType, accountName } =
    props;

  const accountList = useSelector((state) =>
    fromResource.getList(state, getReconciliationGLAccountsFastApi),
  );

  const template = useMemo(() => {
    if (Array.isArray(accountList)) {
      const isAccountNumeric = Number.isFinite(Number(account));

      const currentAccount = accountList.find((item) => {
        if (isAccountNumeric) {
          return +item.accountId === +account;
        }
        return item.accountId === account;
      });

      return currentAccount?.accountTemplateCode;
    }

    return undefined;
  }, [account, JSON.stringify(accountList)]);

  if (!template) return <Spinner />;

  switch (template) {
    case 'BANK':
      return (
        <BankTemplateNew
          {...{
            account,
            company,
            history,
            location,
            roleType,
            accountName,
          }}
        />
      );
    case 'GENERAL':
      return <GeneralTemplate {...{ account, roleType, accountName }} />;
    case 'SIMPLE_LEDGER':
      return (
        <SimpleLedgerTemplate
          {...{
            params,
            account,
            company,
            roleType,
            accountName,
          }}
        />
      );
    default:
      return (
        <Paragraph>
          <Trans>
            Account is not mapped to a reconciliation template. Please map the
            account in the account settings!
          </Trans>
        </Paragraph>
      );
  }
}

TemplateSelector.propTypes = {
  /** @type {string} account */
  account: PropTypes.string,
  /** @type {string} accountName */
  accountName: PropTypes.string,
  /** @type {string} template */
  template: PropTypes.string,
  /** @type {shape} location Router object */
  location: PropTypes.shape({}).isRequired,
  /** @type {shape} history Router object */
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  /** @type {shape} roleType */
  roleType: PropTypes.shape({}).isRequired,
};

TemplateSelector.defaultProps = {
  account: '',
  accountName: '',
  template: undefined,
};

export default TemplateSelector;
