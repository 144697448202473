import React, { useState } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash-es/cloneDeep';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BankTemplate } from 'components';

import {
  putBankLineApi,
  deleteBankLineApi,
  getBankAccountDetails,
} from 'services/apihelpers';
import {
  resourceDeleteRequest,
  resourceUpdateRequest,
  resourceListReadSuccess,
} from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';

function BankTemplateTableContainer(props) {
  const {
    account,
    originalData,
    handleChange,
    handleSelectAll,
    handleUnreconcile,
    setTransactionsForModal,
  } = props;

  const dispatch = useDispatch();
  const { accountingYear, accountingPeriod } = useParams();

  const [actionSubmitting, setActionSubmitting] = useState({});

  // eslint-disable-next-line max-len
  const exportFileName = t`${account} Bank Transactions, Period - ${accountingPeriod}, ${accountingYear}`;

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const updateStore = async (collection) => {
    await dispatch(
      resourceListReadSuccess(
        `${getBankAccountDetails}/${account}`,
        collection,
      ),
    );
  };

  const handleDeleteBankRow = async (bankTransactionId) => {
    _setActionSubmitting(bankTransactionId, true);

    try {
      const query = { bankTransactionId };
      await dispatch(
        resourceDeleteRequest(
          `${deleteBankLineApi}?${queryString.stringify(query)}`,
        ),
      );

      const collection = _cloneDeep(originalData?.bankReconView);

      collection.bankAccounts = [...collection.bankAccounts];
      collection.bankAccounts[0] = { ...collection.bankAccounts[0] };
      // eslint-disable-next-line max-len
      collection.bankAccounts[0].bankTransactions =
        collection.bankAccounts[0].bankTransactions.filter(
          (el) => bankTransactionId !== el.id,
        );

      await updateStore({ ...originalData, bankReconView: collection });
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    _setActionSubmitting(bankTransactionId, false);
  };

  const handleRowSave = async ({ field, id, value }) => {
    _setActionSubmitting(id, true);

    try {
      const query = {
        lineId: id,
        [field]: value,
      };

      if (field === 'postedDate') {
        query.postedDate =
          query.postedDate.length > 10
            ? query.postedDate
            : `${query.postedDate}T00:00:00`;
      } else if (field === 'amount') {
        query.amount = +value;
      }

      await dispatch(resourceUpdateRequest(putBankLineApi, null, query));
      const collection = _cloneDeep(originalData?.bankReconView);

      collection.bankAccounts = [...collection.bankAccounts];
      collection.bankAccounts[0] = { ...collection.bankAccounts[0] };
      // eslint-disable-next-line max-len
      collection.bankAccounts[0].bankTransactions =
        collection.bankAccounts[0].bankTransactions.map((el) => {
          const newEl = { ...el };
          if (id === newEl.id) {
            // eslint-disable-next-line no-param-reassign
            newEl[field] = query[field];
          }
          return newEl;
        });

      await updateStore({ ...originalData, bankReconView: collection });
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }

    _setActionSubmitting(id, false);
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'delete': {
          await handleDeleteBankRow(item.id);
          break;
        }

        case 'reconciledTransactions': {
          const { reconciliationId } = item;
          setTransactionsForModal(reconciliationId);
          break;
        }

        case 'unreconcile': {
          _setActionSubmitting(item.id, true);
          await handleUnreconcile('bank', item.id);
          _setActionSubmitting(item.id, false);
          break;
        }

        case 'cellSave': {
          if (
            (item.id === 'amount' && +item.value !== item.row.amount) ||
            (item.id !== 'amount' && item.value !== item.row[item.id])
          ) {
            await handleRowSave({
              field: item.id,
              id: item.row.id,
              value: item.value,
            });
          }
          break;
        }

        case 'rowSelect': {
          if (Array.isArray(item.item)) {
            handleSelectAll(item.item, 'bank');
          } else if (item.item === 'all') {
            handleSelectAll([], 'bank');
          } else {
            handleChange(item.item, 'bank');
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <BankTemplate
      {...props}
      handleAction={handleAction}
      exportFileName={exportFileName}
      actionSubmitting={actionSubmitting}
    />
  );
}

export default BankTemplateTableContainer;

BankTemplateTableContainer.propTypes = {
  originalData: PropTypes.shape({}),
  handleChange: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleUnreconcile: PropTypes.func,
  search: PropTypes.shape({}),
};

BankTemplateTableContainer.defaultProps = {
  originalData: {},
  handleChange: () => {},
  handleSelectAll: () => {},
  handleUnreconcile: () => {},
  search: {},
};
