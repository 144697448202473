
      import API from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../.yarn/__virtual__/style-loader-virtual-503e34bb74/0/cache/style-loader-npm-3.3.1-4bbb6ec77f-8807445469.zip/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../.yarn/__virtual__/css-loader-virtual-3803aa5ee6/0/cache/css-loader-npm-6.7.1-b93a2de0d4-9442fe5915.zip/node_modules/css-loader/dist/cjs.js!../../../.yarn/__virtual__/sass-loader-virtual-9f280a4c87/0/cache/sass-loader-npm-13.0.2-ca68afd73d-d0978cc5c0.zip/node_modules/sass-loader/dist/cjs.js!../../../.yarn/__virtual__/thread-loader-virtual-aa111813f1/0/cache/thread-loader-npm-3.0.4-f7475e8d74-a67f0b6f72.zip/node_modules/thread-loader/dist/cjs.js??ruleSet[1].rules[0].use[3]!./paper-dashboard.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../.yarn/__virtual__/css-loader-virtual-3803aa5ee6/0/cache/css-loader-npm-6.7.1-b93a2de0d4-9442fe5915.zip/node_modules/css-loader/dist/cjs.js!../../../.yarn/__virtual__/sass-loader-virtual-9f280a4c87/0/cache/sass-loader-npm-13.0.2-ca68afd73d-d0978cc5c0.zip/node_modules/sass-loader/dist/cjs.js!../../../.yarn/__virtual__/thread-loader-virtual-aa111813f1/0/cache/thread-loader-npm-3.0.4-f7475e8d74-a67f0b6f72.zip/node_modules/thread-loader/dist/cjs.js??ruleSet[1].rules[0].use[3]!./paper-dashboard.scss";
       export default content && content.locals ? content.locals : undefined;
