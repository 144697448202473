import React, { useEffect } from 'react';
import { pending, rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { AuditDetailedTable } from 'components';

import { fromResource } from 'store/selectors';
import { getAuditDetailsApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';

export function AuditDetailedTableContainer(props) {
  const { companyId, auditId, auditCatId } = props.match.params;
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getAuditDetailsApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${getAuditDetailsApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getAuditDetailsApi}ListRead`),
  );
  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getAuditDetailsApi, {
        auditId,
        companyId,
        auditCatId,
      }),
    );
  };

  useEffect(fetchData, [props.location.pathname]);

  return (
    <AuditDetailedTable
      {...{
        data,
        failed,
        loading,
      }}
    />
  );
}

export default AuditDetailedTableContainer;
