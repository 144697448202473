import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';

import { Heading, CustomTableWithAutoSettings } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { calculateRowHeight } from 'services/helpers';
import { payrollOutgoingBalance } from 'services/tableshapes';

function OutgoingBalance(props) {
  const { data, loading, tableId } = props;

  const screenWidth = useCurrentWidth();
  const minHeight = useMemo(
    () => 3 * calculateRowHeight(screenWidth),
    [screenWidth],
  );

  return (
    <>
      <Heading alignment="center" level={4}>
        <Trans>Outgoing Balance</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data,
          tableId,
          loading,
          minHeight,
          hideFilters: true,
          columns: payrollOutgoingBalance(),
          headerConfigs: {
            exportFileName: t`Payroll Social Security - Outgoing Balance`,
          },
        }}
      />
    </>
  );
}

export default OutgoingBalance;
