import { t } from '@lingui/macro';
import { filterByReconciledMatchedTransaction } from 'services/shapes';

export const vatManualReconcileFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'transactionId',
      'description',
      'vatCode',
      'vatDescription',
      'accountId',
      'lineId',
    ],
  },
  {
    id: 'reconciliationStatus',
    type: 'multiSelect',
    label: t`Type of transaction`,
    options: filterByReconciledMatchedTransaction(),
    disableSort: true,
    placeholder: t`Please select type of transaction`,
  },
];

export default { vatManualReconcileFilters };
