import React, { useMemo } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { required, notZero } from 'services/validation';
import { periodsToDepreciate } from 'services/shapes';

function AddEditAssetCategoryModal(props) {
  const { item, accounts, onSubmit, closeModal, defaultCategories } = props;
  const defaultValues = useMemo(() => {
    const fields = {
      assetCategoryName: item.assetCategoryName || '',
      defaultPercentage: item.defaultPercentage || '',
      purchaseValueAccount: item.purchaseValueAccount || '',
      defaultPeriodsToDepreciate: item.defaultPeriodsToDepreciate || '',
      accumulatedAdjustmentAccount: item.accumulatedAdjustmentAccount || '',
      accumulatedDepreciationAccount: item.accumulatedDepreciationAccount || '',
      defaultPeriodsToDepreciateSelect: '',
    };

    if (fields.defaultPeriodsToDepreciate) {
      const defaultPeriodsToDepreciateSelect = periodsToDepreciate().find(
        (el) => el.id === fields.defaultPeriodsToDepreciate.toString(),
      );

      fields.defaultPeriodsToDepreciateSelect =
        defaultPeriodsToDepreciateSelect?.id || '0';
    }

    if (item.accounts?.length) {
      item.accounts.forEach((acc) => {
        if (acc.accountTypes.includes('PURCHASE_VALUE')) {
          fields.purchaseValueAccount = acc.accountId;
        }

        if (acc.accountTypes.includes('ADJUSTMENTS')) {
          fields.accumulatedAdjustmentAccount = acc.accountId;
        }

        if (acc.accountTypes.includes('DEPRECIATION')) {
          fields.accumulatedDepreciationAccount = acc.accountId;
        }
      });
    }

    if (item.notInUse?.length) {
      if (item.notInUse.includes('ADJUSTMENTS')) {
        fields.accumulatedAdjustmentAccount = 'notInUse';
      }
      if (item.notInUse.includes('DEPRECIATION')) {
        fields.accumulatedDepreciationAccount = 'notInUse';
      }
    }

    return fields;
  }, [JSON.stringify(item)]);
  const {
    Form,
    setFieldMeta,
    setFieldValue,
    meta: { canSubmit, isSubmitting },
    values: currentValues,
  } = useForm({
    onSubmit,
    defaultValues,
  });
  const addTitle = t`Add Asset Category`;
  const editTitle = t`Edit Asset Category`;

  let accountsOptions;
  if (!isEmpty(accounts)) {
    accountsOptions = accounts.map((account) => (
      <option
        key={`accountNumber-${account.accountNumber}`}
        value={account.accountNumber}
      >
        {account.accountNumber}
      </option>
    ));
  }

  let categoriesOptions;
  if (!isEmpty(defaultCategories)) {
    categoriesOptions = defaultCategories.map((category) => (
      <option
        key={`category-${category.assetCategoryId}`}
        value={category.assetCategoryId}
      >
        {category.assetCategoryName}
      </option>
    ));
  }

  const periodsToDepreciateSelectOptions = periodsToDepreciate().map((el) => (
    <option key={`defaultPeriodsToDepreciate-${el.id}`} value={el.id}>
      {el.label}
    </option>
  ));
  const handlePeriodsToDepreciateSelectChange = (e) => {
    setFieldValue('defaultPeriodsToDepreciate', e.target.value);
    if (e.target.value === '0') {
      setFieldMeta('defaultPeriodsToDepreciateSelect', { error: null });
    }
  };

  const handleCategoryChange = (e) => {
    const category = defaultCategories.find(
      (c) => c.assetCategoryId === +e.target.value,
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const key in category) {
      if (defaultValues.hasOwnProperty(key)) {
        setFieldValue(key, category[key]);

        if (key === 'defaultPeriodsToDepreciate') {
          const period = periodsToDepreciate().find(
            (p) => p.id === category[key]?.toString(),
          );
          setFieldValue('defaultPeriodsToDepreciateSelect', period?.id || '0');
          if (!period) {
            setFieldMeta('defaultPeriodsToDepreciateSelect', { error: null });
          }
        }
      }
    }
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>
            {item.assetCategoryId ? editTitle : addTitle}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <ReactFormField
            noBorder
            name="assetDefaultCategories"
            size="small"
            type="select"
            onChange={handleCategoryChange}
            label={t`Predefined categories`}
          >
            <option value="" disabled>
              {t`Please Select Predefined Category`}
            </option>
            {categoriesOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            size="small"
            name="assetCategoryName"
            label={t`Name`}
            validate={[required]}
            placeholder={t`Please Enter name`}
            autoComplete="off"
          />
          <ReactFormField
            noBorder
            name="purchaseValueAccount"
            size="small"
            type="select"
            label={t`Purchase value Account`}
            validate={[required]}
          >
            <option
              key="selectOptionDefaultPurchaseValueAccount"
              value=""
              disabled
            >
              {t`Please Select Purchase value Account`}
            </option>
            {accountsOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            name="accumulatedDepreciationAccount"
            size="small"
            type="select"
            label={t`Accumulated Depreciation Account`}
            validate={[required]}
          >
            <option value="" disabled>
              {t`Please Select Accumulated Depreciation Account`}
            </option>
            <option value="notInUse">{t`Not in use`}</option>
            {accountsOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            name="accumulatedAdjustmentAccount"
            size="small"
            type="select"
            label={t`Accumulated Adjustment Account`}
            validate={[required]}
          >
            <option value="" disabled>
              {t`Please Select Accumulated Adjustment Account`}
            </option>
            <option value="notInUse">{t`Not in use`}</option>
            {accountsOptions}
          </ReactFormField>
          {currentValues.defaultPeriodsToDepreciateSelect === '0' ? (
            <ReactFormField
              noBorder
              noFormat
              size="small"
              name="defaultPeriodsToDepreciate"
              type="number"
              label={t`Linear number of Depreciation`}
              validate={[required, notZero]}
              placeholder={t`Please Enter Linear number of Depreciation`}
            />
          ) : (
            <ReactFormField
              noBorder
              name="defaultPeriodsToDepreciateSelect"
              size="small"
              type="select"
              label={t`Linear number of Depreciation`}
              onChange={handlePeriodsToDepreciateSelectChange}
              validate={[required]}
            >
              <option value="" disabled>
                {t`Please Select Linear number of Depreciation`}
              </option>
              {periodsToDepreciateSelectOptions}
            </ReactFormField>
          )}
          <ReactFormField
            noBorder
            size="small"
            name="defaultPercentage"
            type="number"
            label={t`Balance Depreciation percentage`}
            validate={[required]}
            placeholder={t`Please Enter Balance Depreciation percentage`}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditAssetCategoryModal;
