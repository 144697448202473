import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 240,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    disableSortBy: true,
    withTooltip: true,
  },
  {
    width: 70,
    Header: t`Period`,
    accessor: 'period',
    disableSortBy: true,
    className: 'flex-right',
  },
  {
    width: 120,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    disableSortBy: true,
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
