import React, { useEffect, useState } from 'react';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { PayrollDrilldownModal } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { fromCompany, fromResource } from 'store/selectors';
import { getPayrollReportDrilldownApi } from 'services/apihelpers';

export function PayrollDrilldownModalContainer(props) {
  const { row, toggleModal, accumulated, activePeriods, bimonthly } = props;
  const [showDetails, setShowDetails] = useState(false);

  const dispatch = useDispatch();
  const company = useSelector(fromCompany.getCompany);

  const data = useSelector((state) =>
    fromResource.getList(state, getPayrollReportDrilldownApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getPayrollReportDrilldownApi}ListRead`),
  );
  const toggleShowDetails = () => setShowDetails(!showDetails);
  const period = bimonthly ? Math.ceil(activePeriods / 2) : activePeriods;

  const query = {
    period,
    bimonthly,
    accumulated,
    year: company.currentAccountingYear,
    rowNumber: row.rowNumber,
    side: row.side,
    companyId: company.currentCompanySID,
    aggregated: false,
    manual: row.manual,
  };

  useEffect(() => {
    dispatch(
      resourceListReadRequest(getPayrollReportDrilldownApi, { ...query }),
    );
  }, [row.rowNumber]);

  return (
    <PayrollDrilldownModal
      {...{
        row,
        data,
        period,
        loading,
        bimonthly,
        accumulated,
        toggleModal,
        showDetails,
        toggleShowDetails,
      }}
    />
  );
}

PayrollDrilldownModalContainer.defaultProps = {
  accumulated: false,
};

export default PayrollDrilldownModalContainer;
