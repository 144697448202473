import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { DESKTOP } from 'constants/mediaSizes';
import { reportVersionsColumns } from 'services/tableshapes';
import { reportVersionsFilters } from 'services/tableFilterShapes';

function ShowReportVersionsModal(props) {
  const {
    data,
    type,
    year,
    period,
    accountId,
    loading,
    actionSubmitting,
    handleAction,
    closeModal,
    bimonthly,
  } = props;

  const title = useMemo(() => {
    let result = `${t`Report Versions:`} `;

    if (type === 'ACCOUNTS') {
      result += `${t`Account ${accountId}`} `;
    }

    if (type === 'VAT') {
      result += `${t`VAT`} `;
    }

    if (type === 'PAYROLL') {
      result += `${t`Payroll`} `;
    }

    if (type === 'REPORT_PACKAGE') {
      result += `${t`Complete Packages`} `;
    }

    if (['VAT', 'ACCOUNTS', 'REPORT_PACKAGE'].includes(type)) {
      result += ` - ${year}, ${bimonthly ? Math.round(period / 2) : period}`;
    }

    if (type.includes('PAYROLL')) {
      result += t` - Term ${
        bimonthly ? Math.round(period / 2) : period
      }, ${year}`;
    }

    return result;
  }, [period, year, type, accountId, bimonthly]);

  // Transform data to divide period by two, if bimonthly
  const transformedData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!bimonthly) {
      return data;
    }

    return data.map((item) => ({
      ...item,
      accountingPeriod: Math.round(item.accountingPeriod / 2),
    }));
  }, [data, bimonthly]);

  return (
    <ModalStyles show onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>{title}</Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid" style={{ padding: 0 }}>
          <CustomTableWithAutoSettings
            {...{
              data: transformedData,
              loading,
              columns: reportVersionsColumns(type),
              tableId: 'reportVersionTable',
              loadingId: 'id',
              filterConfigs: reportVersionsFilters(),
              headerConfigs: {
                exportFileName: title,
              },
              handleAction,
              actionSubmitting,
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </ModalStyles>
  );
}

export default ShowReportVersionsModal;

const ModalStyles = styled(Modal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: 1200px) {
      width: 1150px;
    }
  }
`;
