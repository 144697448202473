import { t } from '@lingui/macro';

export const months = () => ({
  monthly: t`Period`,
  biMonthly: t`Term`,
  quarterly: t`Q`,
  tertiary: t`T`,
  halfYearly: t`HY`,
  yearly: t`Y`,
});

export default months;
