import { t } from '@lingui/macro';
import _isEmpty from 'lodash-es/isEmpty';

export const simpleLedgerDataFormatter = (data, deviationRows) => {
  let sumDev = 0;
  const deviationRowsItems = new Map(deviationRows);

  // HEADER
  const result = [
    {
      group: 2,
      subtitle: {
        exported: true,
      },
      description: t`Balance From General Ledger`.toUpperCase(),
      amount: data.sumOfReconciliation,
    },
  ];

  // NOT SAVED ROWS
  deviationRowsItems?.forEach((item) => {
    sumDev += parseFloat(item.amount || 0);
    result.push({
      group: 2,
      editable: true,
      ...item,
      withDelete: true,
    });
  });

  // SAVED DEVIATION ROWS
  if (!_isEmpty(data.deviationList)) {
    data.deviationList.forEach((item) => {
      result.push({
        group: 1,
        withDelete: true,
        deviationId: item.deviationCategory,
        deviationSID: item.deviationSID,
        description: item.deviationDescription,
        amount: item.deviationAmount,
      });
    });
  }

  result.push(
    {
      group: 2,
      row: 'balanceRow',
      editableAmount: true,
      description: t`Balance For Simple Ledger Template`,
      amount: data.balanceOfBankStatement,
    },
    {
      group: 1,
      description: t`Account Balance`,
      amount: parseFloat(data.balanceOfBankStatement || 0),
    },
  );

  // FOOTER
  if (deviationRowsItems.size > 0 || data.deviationList?.length > 0) {
    result.push(
      {
        group: 1,
        description: t`Sum Deviation Items`,
        amount: data.correctedDeviationBalance + sumDev,
      },
      {
        group: 1,
        description: t`Corrected Balance`,
        amount:
          parseFloat(data.balanceOfBankStatement || 0) +
          (data.correctedDeviationBalance
            ? data.correctedDeviationBalance + sumDev
            : sumDev),
      },
    );
  }

  // DEVIATION
  result.push({
    group: 2,
    subtitle: {
      exported: true,
    },
    description: t`Deviation`.toUpperCase(),
    amount:
      parseFloat(data.sumOfReconciliation) -
        (sumDev || data.correctedDeviationBalance) -
        parseFloat(data.balanceOfBankStatement || 0) || 0,
  });

  return result;
};

export default simpleLedgerDataFormatter;
