import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { InvalidateLinkForm } from 'components';

import { resourceDeleteRequest } from 'store/actions';
import { invalidateResetPasswordLinkApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function InvalidateLinkFormContainer(props) {
  const { location } = props;

  const { token, username } = queryString.parse(location.search);
  const noParamsInUrl = !token && !username;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const dispatch = useDispatch();

  const invalidateToken = async () => {
    setLoading(true);
    setFailed(false);
    setSuccess(false);

    try {
      await dispatch(
        resourceDeleteRequest(invalidateResetPasswordLinkApi, null, {
          token,
          username,
        }),
      );

      setLoading(false);
      setFailed(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      setFailed(true);
      setSuccess(false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (!noParamsInUrl) {
      invalidateToken();
    }
  }, []);

  return (
    <InvalidateLinkForm
      loading={loading}
      failed={failed}
      success={success}
      noParamsInUrl={noParamsInUrl}
    />
  );
}

InvalidateLinkFormContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default InvalidateLinkFormContainer;
