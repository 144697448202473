import { initialState } from './selectors';
import {
  USER_SUCCESS,
  LOGIN_SUCCESS,
  TIMEOUT_SUCCESS,
  UPDATE_USER_SUCCESS,
  SIDEBAR_PROGRESS_BADGE,
  META_USER_DATA_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_ACCOUNT_UPDATE_STATUS,
  UPDATE_PROFILE_COMPLETENESS_PERCENTAGE,
} from './actions';
import { CLEAR_STORE } from '../root/actions';

/* eslint-disable default-param-last */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isTimeout: false,
        user: payload,
      };
    case USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case META_USER_DATA_SUCCESS:
      return {
        ...state,
        metaUserData: payload,
      };
    case TIMEOUT_SUCCESS:
      return {
        ...state,
        isTimeout: true,
        user: { ...state.user, token: null },
      };
    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_ACCOUNT_UPDATE_STATUS:
      return {
        ...state,
        accountUpdateStatus: payload,
      };
    case UPDATE_PROFILE_COMPLETENESS_PERCENTAGE:
      return {
        ...state,
        profileCompletenessPercentage: payload,
      };
    case SIDEBAR_PROGRESS_BADGE:
      return {
        ...state,
        sidebarProgress: payload,
      };
    case CLEAR_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
