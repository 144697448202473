import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { CustomTableWithAutoSettings, Heading } from 'components';

import { groupUserDetailedColumns } from 'services/tableshapes';
import { settingsUserDetailedFilters } from 'services/tableFilterShapes';

/**
 * Group User Detailed Table component
 */
function GroupUserDetailedTable(props) {
  const { data, group, search, loading, handleAction, actionSubmitting } =
    props;

  return (
    <>
      <Heading level={3}>{group?.name}</Heading>
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          loadingId: 'companyId',
          dynamicRowHeight: true,
          reportMode: search.reportMode,
          tableId: 'groupCompanyDetailed',
          filterConfigs: settingsUserDetailedFilters(),
          columns: groupUserDetailedColumns(search, handleAction),
          headerConfigs: {
            exportFileName: `${group?.name} - ${t`User Groups Details`}`,
            extraButtons: [
              {
                id: 'add',
                title: t`Update group users`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
        }}
      />
    </>
  );
}

GroupUserDetailedTable.propTypes = {
  data: PropTypes.shape({}),
  group: PropTypes.shape({}),
  search: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

GroupUserDetailedTable.defaultProps = {
  data: [],
  group: {},
  search: {},
  loading: false,
  handleAction: () => {},
  actionSubmitting: {},
};

export default GroupUserDetailedTable;
