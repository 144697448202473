export const initialState = {
  user: null,
  metaUserData: {},
  availableCompanies: null,
  alerts: [],
  isTimeout: false,
  sidebarProgress: 0,
  accountUpdateStatus: false,
  profileCompletenessPercentage: 0,
};

export const getUser = (state = initialState) =>
  state.user || initialState.user;

export const getTimeoutStatus = (state = initialState) =>
  state.isTimeout || initialState.isTimeout;

export const getAccountUpdateStatus = (state = initialState) =>
  state.accountUpdateStatus || initialState.accountUpdateStatus;

export const getProfileCompletenessPercentage = (state = initialState) =>
  state.profileCompletenessPercentage ||
  initialState.profileCompletenessPercentage;

export const getAlerts = (state = initialState) =>
  state.alerts && state.alerts.length > 0 ? state.alerts : initialState.alerts;
export const getSidebarProgress = (state = initialState) =>
  state.sidebarProgress || initialState.sidebarProgress;

export const getAvailableCompanies = (state = initialState) =>
  state.availableCompanies || initialState.availableCompanies;

export const getMetaUserData = (state = initialState) =>
  state.metaUserData || initialState.metaUserData;
