import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { Card, Heading, CustomTableWithAutoSettings } from 'components';

import { vatDetailedFilters } from 'services/tableFilterShapes';
import { VATReconciliationDetailedColumns } from 'services/tableshapes';

function VATReconciliationDetailedTable(props) {
  const { data, loading, search } = props;

  const filteredData = useMemo(() => {
    if (Array.isArray(data.lines)) {
      return data.lines.map((item) => ({
        ...item,
        withDescription: item.description ? 'withText' : 'withoutText',
      }));
    }

    return [];
  }, [JSON.stringify(data)]);

  const sortBy = useMemo(
    () => [
      {
        id: 'date',
        desc: false,
      },
    ],
    [],
  );

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        data: filteredData,
        loadingId: 'accountId',
        tableId: 'vatDetailed',
        reportMode: search?.reportMode,
        filterConfigs: vatDetailedFilters(),
        columns: VATReconciliationDetailedColumns(search?.reportMode),
        headerConfigs: {
          exportFileName: t`VAT Detailed`,
        },
        initialState: {
          sortBy,
        },
      }}
    />
  );

  return (
    <Card
      header={
        <Heading level={3} className="card-title">
          <Trans>VAT Detailed</Trans>
        </Heading>
      }
    >
      {content}
    </Card>
  );
}

export default VATReconciliationDetailedTable;

VATReconciliationDetailedTable.propTypes = {
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
};

VATReconciliationDetailedTable.defaultProps = {
  data: {},
  loading: undefined,
};
