import { t } from '@lingui/macro';

import { filterByFileCategory, years, filterByPeriod } from 'services/shapes';

export const fileHistoryFilters = (customFilters) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'year',
      'period',
      'fileName',
      'uploadedBy',
      'fileCategory',
    ],
  },
  {
    id: 'year',
    type: 'multiSelect',
    label: t`Year`,
    options: years(),
    disableSort: true,
    placeholder: t`Please select Year`,
  },
  {
    id: 'period',
    type: 'multiSelect',
    label: t`Period`,
    options: filterByPeriod(),
    disableSort: true,
    placeholder: t`Please select Period`,
  },
  {
    id: 'fileCategory',
    type: 'multiSelect',
    label: t`Categories`,
    options: filterByFileCategory(),
    disableSort: true,
    placeholder: t`Please select Category`,
  },
  {
    id: 'uploadedBy',
    type: 'multiSelect',
    label: t`Uploaded By`,
    options: customFilters.uploadedBy,
    disableSort: true,
    placeholder: t`Please select Uploader`,
  },
  {
    id: 'uploadedDate',
    type: 'date',
    label: t`Uploaded At`,
  },
];

export default fileHistoryFilters;
