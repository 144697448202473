import React, { useEffect } from 'react';
import { pending, rejected } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { CompanyPreviewModal } from 'components';

import { fromResource } from 'store/selectors';
import { getCompanyDetailedApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';

function CompanyPreviewModalContainer(props) {
  const { id, closeModal, handleAction, search } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getCompanyDetailedApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${getCompanyDetailedApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getCompanyDetailedApi}ListRead`),
  );

  useEffect(() => {
    dispatch(resourceListReadRequest(getCompanyDetailedApi, { companyId: id }));
  }, []);

  return (
    <CompanyPreviewModal
      {...{
        data,
        failed,
        search,
        loading,
        closeModal,
        handleAction,
      }}
    />
  );
}

export default CompanyPreviewModalContainer;
