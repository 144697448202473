import { t } from '@lingui/macro';
import { years } from 'services/shapes';

export const periodHistoryFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['year', 'period', 'addedBy', 'changedBy', 'comment'],
  },
  {
    id: 'year',
    type: 'multiSelect',
    label: t`Year`,
    options: years(),
    disableSort: true,
    placeholder: t`Please select Year`,
  },
];

export default periodHistoryFilters;
