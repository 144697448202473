import { t } from '@lingui/macro';
import { hiddenOptions } from 'services/shapes';

export const vatSettingsCodesFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['code', 'type', 'accounts', 'reverseAccounts'],
  },
  {
    id: 'hidden',
    type: 'multiSelect',
    label: t`Visibility`,
    options: hiddenOptions(),
    disableSort: true,
    placeholder: t`Select visibility...`,
  },
];

export default vatSettingsCodesFilters;
