import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';

import { TableRow } from 'components';

const ItemTypes = {
  CARD: 'card',
};

export function DraggableItem(props) {
  const { id, index, items, children, changeState, component, ...rest } = props;
  const ref = useRef(null);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = items[dragIndex];
      if (typeof changeState === 'function') {
        changeState(
          // eslint-disable-next-line no-undef
          update(items, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
    },
    [items],
  );

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  if (component === 'row') {
    return (
      <TableRow
        ref={ref}
        style={{ opacity, cursor: 'grabbing' }}
        data-testid="table-row"
        data-handler-id={handlerId}
        {...rest}
      >
        {children}
      </TableRow>
    );
  }

  return (
    <Container ref={ref} opacity={opacity} data-handler-id={handlerId}>
      {children}
    </Container>
  );
}

DraggableItem.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf({}),
  children: PropTypes.shape({}),
  component: PropTypes.string,
  changeState: PropTypes.func,
};

DraggableItem.defaultProps = {
  items: [],
  children: undefined,
  component: undefined,
  changeState: () => {},
};

export default DraggableItem;

const Container = styled.div`
  border: ${(props) =>
    props.opacity ? '1px dotted transparent' : '1px dotted gray'};

  & > * {
    opacity: ${(props) => props.opacity};
  }
`;
