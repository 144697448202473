import React from 'react';
import { t } from '@lingui/macro';
import { Button } from 'components';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { RiShareForward2Fill } from '@react-icons/all-files/ri/RiShareForward2Fill'; // eslint-disable-line max-len

import { sortBooleanCustomTable } from 'services/helpers';

/**
 * Group Company Detailed Grid
 */
export const columns = ({ reportMode }, handleAction, isSuperOrSystemAdmin) => [
  {
    width: reportMode ? 120 : 210,
    Header: t`Registration Number`,
    accessor: 'registrationNumber',
    className: '',
  },
  {
    width: reportMode ? 150 : 410,
    Header: t`Company Name`,
    accessor: 'companyName',
    className: '',
    withTooltip: !reportMode,
    cell: {
      disableClick: true,
    },
  },
  {
    width: reportMode ? 150 : 410,
    Header: t`Associated Groups`,
    accessor: 'associativeGroups',
    disableSortBy: true,
    className: '',
    withTooltip: !reportMode,
    cell: {
      converter: (_, item, __, forExport) =>
        forExport
          ? item?.associativeGroups?.map((group) => group.name).join(', ')
          : item?.associativeGroups?.map((group) => (
              <React.Fragment key={group.groupId}>
                {group.name}
                <Button
                  key={group.groupId}
                  simple
                  magnify
                  kind="default"
                  onClick={() => handleAction('redirect', group.groupId)}
                >
                  <FiExternalLink />
                </Button>
                &nbsp;
              </React.Fragment>
            )),
      disableClick: true,
    },
  },
  ...(isSuperOrSystemAdmin
    ? [
        {
          width: 100,
          Header: t`Secret`,
          sortType: sortBooleanCustomTable,
          accessor: 'secret',
          className: 'flex-center',
          cell: {
            type: 'boolean',
          },
        },
      ]
    : []),
  {
    width: 110,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'companyPreview',
          icon: () => <FaRegEye />,
          tooltip: t`Detailed`,
        },
        {
          id: 'move',
          icon: () => <RiShareForward2Fill />,
          tooltip: t`Move this company`,
        },
        {
          id: 'delete',
          icon: () => <BiTrash />,
          tooltip: t`Delete`,
        },
      ],
    },
  },
];

export default { columns };
