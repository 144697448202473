import { t } from '@lingui/macro';

import {
  periodTypes,
  mappedOptions,
  filterByGlTypes,
  enableAccountOptions,
  requireDocumentsOptions,
  filterByTemplateStatuses,
} from 'services/shapes';

export const accountsFilters = (customFilters) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'accountId',
      'frequency',
      'standardAccountId',
      'accountHolderName',
      'accountDescription',
      'accountTemplateCode',
      'standardAccountDescription',
    ],
  },
  {
    id: 'accountCategory',
    type: 'multiSelect',
    label: t`Categories`,
    options: filterByGlTypes(),
    disableSort: true,
    placeholder: t`Please select Category`,
  },
  {
    id: 'accountTemplateCode',
    type: 'multiSelect',
    label: t`Templates`,
    options: filterByTemplateStatuses(),
    disableSort: true,
    placeholder: t`Please select Template`,
  },
  {
    id: 'frequency',
    type: 'multiSelect',
    label: t`Frequency`,
    options: periodTypes(),
    disableSort: true,
    placeholder: t`Please select Frequency`,
  },
  {
    id: 'reconcileAccount',
    type: 'multiSelect',
    label: t`Enable Account`,
    options: enableAccountOptions(),
    disableSort: true,
    placeholder: t`Please select Enabling`,
  },
  {
    id: 'documentRequired',
    type: 'multiSelect',
    label: t`Require Documents`,
    options: requireDocumentsOptions(),
    disableSort: true,
    placeholder: t`Please select Requirement`,
  },
  {
    id: 'accountSystemMapped',
    type: 'multiSelect',
    label: t`Mapped`,
    options: mappedOptions(),
    disableSort: true,
    placeholder: t`Please select Mapping`,
  },
  {
    id: 'accountHolderName',
    type: 'multiSelect',
    label: t`Assignee`,
    options: customFilters?.accountHolderName,
    disableSort: true,
    placeholder: t`Please select Assignee`,
  },
  {
    id: 'standardAccountSid',
    type: 'multiSelect',
    label: t`Standard Mapping`,
    options: customFilters?.standardAccountSid,
    disableSort: true,
    placeholder: t`Please select Standard Mapping`,
  },
];

export default accountsFilters;
