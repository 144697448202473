import React from 'react';
import { t } from '@lingui/macro';
import queryString from 'query-string';

import { Tabs } from 'components';
import GroupCompany from 'containers/GroupCompany';
import GroupUser from 'containers/GroupUser';

import { toGroupSettings } from 'services/routehelpers';

/**
 * Reconciliation Settings Page Component
 */

function GroupSettingsPage(props) {
  const { location, handleTabSelect } = props;
  const { tabEntry = 'company' } = queryString.parse(location.search);
  const onTabSelect = (key) =>
    handleTabSelect(key, (data) => toGroupSettings(data));

  const tabs = [
    {
      title: t`Company`,
      eventKey: 'company',
      component: <GroupCompany {...props} />,
    },
    {
      title: t`User`,
      eventKey: 'user',
      component: <GroupUser {...props} />,
    },
  ];

  return <Tabs tabs={tabs} activeKey={tabEntry} onSelect={onTabSelect} />;
}

export default GroupSettingsPage;
