import React from 'react';
import { t } from '@lingui/macro';
import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
import { FaUserSlash } from '@react-icons/all-files/fa/FaUserSlash';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';

import { defineRoles, sortBooleanCustomTable } from 'services/helpers';

/**
 * User Details Table Grid
 */

export const columns = ({ reportMode }) => [
  {
    width: 40,
    hidden: reportMode,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 300 : 390,
    Header: t`Name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 300 : 400,
    Header: t`User Name`,
    accessor: 'loginUsername',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 180 : 170,
    Header: t`Phone`,
    accessor: 'contactNo',
    className: '',
  },
  {
    width: reportMode ? 180 : 250,
    Header: t`Role`,
    accessor: 'roleName',
    className: '',
    cell: {
      converter: (value) => defineRoles([value]),
    },
  },
  {
    width: reportMode ? 110 : 100,
    Header: t`Activated`,
    sortType: sortBooleanCustomTable,
    accessor: 'recordStatus',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  {
    width: 110,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'userPreview',
          icon: () => <FaRegEye />,
          tooltip: t`Detailed`,
        },
        {
          id: 'edit',
          icon: () => <GoPencil />,
          tooltip: t`Edit`,
        },
        {
          id: 'deactivate',
          icon: () => <FaUserSlash />,
          tooltip: t`Deactivate`,
          visible: (row) => row.recordStatus,
        },
        {
          id: 'activate',
          icon: () => <FaUser />,
          tooltip: t`Activate`,
          visible: (row) => !row.recordStatus,
        },
      ],
    },
  },
];

export default { columns };
