import React from 'react';
import { t } from '@lingui/macro';
import { FaRegFilePdf } from '@react-icons/all-files/fa/FaRegFilePdf';

export const columns = (type) => [
  ...(type === 'ACCOUNTS'
    ? [
        {
          width: 55,
          Header: t`Account ID`,
          accessor: 'accountId',
          className: '',
        },
      ]
    : []),
  {
    width: 105,
    Header: t`Year`,
    accessor: 'accountingYear',
    className: '',
  },
  {
    width: 105,
    Header: t`Period`,
    accessor: 'accountingPeriod',
    className: '',
  },
  {
    width: 105,
    Header: t`Version`,
    accessor: 'version',
    className: '',
  },
  {
    width: 105,
    Header: t`Generated At`,
    accessor: 'createdAt',
    className: 'flex-right',
    cell: {
      type: 'datetime',
    },
  },
  {
    width: 155,
    Header: t`Generated By`,
    accessor: 'createdBy',
    className: '',
  },
  {
    width: 55,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'downloadPDF',
          icon: () => <FaRegFilePdf />,
          tooltip: t`Download`,
        },
      ],
    },
  },
];

export default { columns };
