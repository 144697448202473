/*eslint-disable */
import React, { useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import './index.css';

function PDF(props) {
  const { item, close } = props;

  const [numPages, setNumPages] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [scale, setScale] = useState(1.5);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => setNumPages(numPages), []);

  const handlePageChange = useCallback(
    (e) => {
      const { target: { id } } = e;
      setActivePage((state) => id === 'increase' ? state + 1 : state - 1);
    }, []
  );

  const removeTextLayerOffset = useCallback(() => {
    const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
    textLayers.forEach(layer => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }, []);

  const handleZoom = useCallback((e) => {
    setScale((state) => e.target.id === 'in' ? state + 0.25 : state - 0.25);
  }, [])

  return (
    <>
      <PDFViewer
        file={item.url}
        loading={null}
        onLoadSuccess={onDocumentLoadSuccess}>
        <div className="pdfButtonsHolder" onClick={close}>
          <div className="pdfClose">{t`Close`}</div>
        </div>
        <SidebarWrapper key={scale}>
          <Page pageNumber={activePage} onLoadSuccess={removeTextLayerOffset} scale={scale} />
        </SidebarWrapper>
      </PDFViewer>
      <div className="pdfButtonsHolder">
        {activePage !== 1 && (
          <div className="pdfPrev" id="decrease" onClick={handlePageChange}>
            {t`Prev`}
          </div>
        )}
        <div className="pdfPageHolder">
          {`${activePage}-${numPages}`}
          <div className="pdfZoom" id="in" onClick={handleZoom}>
            +
          </div>
          {Math.ceil(scale * 100)}%
          <div className="pdfZoom" id="out" onClick={handleZoom}>
            -
          </div>
        </div>
        {activePage !== numPages && (
          <div className="pdfNext" id="increase" onClick={handlePageChange}>
            {t`Next`}
          </div>
        )}
      </div>
    </>
  );
}

PDF.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string
  }),
  close: PropTypes.func,
}

PDF.defaultProps = {
  item: {},
  close: () => {}
}

export default PDF;

const PDFViewer = styled(Document)`
  overflow: hidden;

  .react-pdf__Page__textContent span {
    opacity: 0.1;
  }

  .react-pdf__Page__textContent span::selection {
    background-color: blue;
  }

  .react-pdf__Document {
    line-height: initial;
  }

  .react-pdf__Page__canvas {
    width: auto !important;
  }
`

const SidebarWrapper = styled(PerfectScrollbar)`
  max-height: 90vh;
  max-width: 90vw;

  & .ps__rail-x:hover,
  & .ps__rail-y:hover,
  & .ps__rail-x:focus,
  & .ps__rail-y:focus,
  & .ps__rail-x.ps--clicking,
  & .ps__rail-y.ps--clicking {
    background-color: transparent;
  }
`;
