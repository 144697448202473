import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';

import { BankAddEditModal } from 'components';

import { fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  addBankAccountApi,
  getOpenBankingLoginApi,
  getOpenBankingAccountsApi,
} from 'services/apihelpers';

function BankAddEditModalContainer(props) {
  const { closeModal, companyId } = props;

  const dispatch = useDispatch();

  const submitting = useSelector((state) =>
    pending(state, `${addBankAccountApi}Create`),
  );
  const openBankingLinkLoading = useSelector((state) =>
    pending(state, `${getOpenBankingLoginApi}ListRead`),
  );
  const openBankingAccountLoading = useSelector((state) =>
    pending(state, `${getOpenBankingAccountsApi}ListRead`),
  );
  const openBankingAccountError = useSelector((state) =>
    rejected(state, `${getOpenBankingAccountsApi}ListRead`),
  );
  const openBankingAccountList = useSelector((state) =>
    fromResource.getList(state, getOpenBankingAccountsApi),
  );

  const getCurrentBankAccount = (bankAccount) => {
    if (
      !Array.isArray(openBankingAccountList) ||
      !openBankingAccountList.length
    ) {
      return false;
    }

    return openBankingAccountList.filter(
      (item) => item.viiaId === bankAccount,
    )[0];
  };

  const handleSelectChange = ({ target: { value } }, callback) => {
    const bankAccount = getCurrentBankAccount(value);

    if (bankAccount && typeof callback === 'function') {
      callback(bankAccount);
    }
  };

  const handleSubmit = async (data) => {
    const bankAccount = getCurrentBankAccount(data.bankAccount) || {};

    const query = {
      companyId,
      name: data.name || '',
      userId: bankAccount.viiaUser || '',
      viiaAccountId: bankAccount.viiaId || '',
      accountNumber: data.accountNumber || '',
    };

    try {
      await dispatch(resourceCreateRequest(addBankAccountApi, query));

      closeModal(false, true);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const handleOpenBankingLogin = async () => {
    const openBankingLoginLink = await dispatch(
      resourceListReadRequest(getOpenBankingLoginApi),
    );

    if (openBankingLoginLink?.url) {
      window.location.assign(openBankingLoginLink.url);
    }
  };

  useEffect(() => {
    dispatch(resourceListReadRequest(getOpenBankingAccountsApi));
  }, []);

  return (
    <BankAddEditModal
      {...{
        closeModal,
        submitting,
        openBankingLinkLoading,
        openBankingAccountLoading,
        openBankingAccountError,
        openBankingAccountList,
      }}
      onSubmit={handleSubmit}
      handleSelectChange={handleSelectChange}
      handleOpenBankingLogin={handleOpenBankingLogin}
    />
  );
}

export default BankAddEditModalContainer;
