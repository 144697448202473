import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import { Button, Heading, Spinner, ButtonIcon } from 'components';

function CopyCompanySettingsModal(props) {
  const {
    step,
    failed,
    loading,
    onSubmit,
    companies,
    closeModal,
    handleChange,
    selectedCompanyToCopy,
    selectedCompaniesToChange,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({ onSubmit });
  let content;

  if (loading) {
    content = (
      <Heading alignment="center">
        <Spinner />
      </Heading>
    );
  } else if (failed) {
    content = (
      <Heading alignment="center" level={4}>
        <Trans>Something went wrong! Please try again.</Trans>
      </Heading>
    );
  } else {
    let options = [];

    if (Array.isArray(companies)) {
      options = companies.map((c) => ({
        id: c.uuid,
        label: c.companyName,
      }));
    }

    if (step === 0) {
      content = (
        <>
          <InputHolder>
            <AutoSuggestInput
              clearButton
              id="select-company-copy"
              size="lg"
              label={t`Selected company to be copied from`}
              bsSize="lg"
              options={options.filter(
                (c) =>
                  !selectedCompaniesToChange.length ||
                  selectedCompaniesToChange.find((sc) => sc.id !== c.id),
              )}
              selected={selectedCompanyToCopy}
              placeholder={t`Please Select Company to copy from`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'companyToCopy')
              }
            />
          </InputHolder>
          <InputHolder>
            <AutoSuggestInput
              multiple
              clearButton
              id="select-companies-to-change"
              size="lg"
              label={t`Selected companies to be copied to`}
              bsSize="lg"
              options={options.filter(
                (c) =>
                  !selectedCompanyToCopy.length ||
                  selectedCompanyToCopy.find((sc) => sc.id !== c.id),
              )}
              selected={selectedCompaniesToChange}
              placeholder={t`Please Select Companies to be copied to`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'companiesToChange')
              }
            />
          </InputHolder>
        </>
      );
    } else if (step === 1) {
      content = (
        <>
          <Heading level={5}>
            <Trans>Please confirm to copy the following settings from:</Trans>
          </Heading>
          <Heading level={6} kind="danger">
            {selectedCompanyToCopy.map((c) => c.label).join(', ')}
          </Heading>
          <Heading level={5}>
            <Trans>to</Trans>
          </Heading>
          {selectedCompaniesToChange.map((c) => (
            <Heading key={c.id} level={6} kind="danger">
              {c.label}
            </Heading>
          ))}
        </>
      );
    }
  }

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>
            <Trans>Copy company settings</Trans>
          </Heading>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <BottomHolder>
            <Heading
              level={6}
              alignment="left"
              style={{ marginRight: '10px', fontWeight: 'bold' }}
            >
              <Trans>
                Are you sure about this? This action cannot be reverted.
              </Trans>
            </Heading>
            <ButtonGroup bsSize="small">
              {!failed && !loading && (
                <Button
                  fill
                  magnify
                  kind="info"
                  type="submit"
                  disabled={
                    !canSubmit ||
                    !selectedCompaniesToChange.length ||
                    !selectedCompanyToCopy.length
                  }
                >
                  {isSubmitting ? (
                    <Spinner type="white" size="md" />
                  ) : (
                    <>
                      <ButtonIcon as={HiCheck} />{' '}
                      {step === 0 ? t`Save` : t`Confirm`}
                    </>
                  )}
                </Button>
              )}
              <Button fill kind="danger" magnify onClick={closeModal}>
                <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
              </Button>
            </ButtonGroup>
          </BottomHolder>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CopyCompanySettingsModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;

const BottomHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
