import { t } from '@lingui/macro';

export const columns = ({ reportMode, payrollApproved, manual }) => [
  {
    width: reportMode ? 220 : 400,
    Header: t`Type`,
    accessor: 'reportType',
    className: '',
    disableSortBy: true,
    withTotal: true,
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 120 : 140,
    Header: manual ? t`SKAT OPPGAVEPLIKTIG` : t`Altinn Reported`,
    sortType: 'basic',
    accessor: 'a07Reported',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`General Ledger Reported`,
    sortType: 'basic',
    accessor: 'glreported',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 150,
    Header: t`Reported Deviation`,
    sortType: 'basic',
    accessor: 'deviation',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
      converter: (_, item) =>
        !item?.subtitle ? item.a07Reported - item.glreported : '',
    },
  },
  {
    width: reportMode ? 120 : 180,
    Header: manual
      ? t`SKAT ARBEIDSGIVERAFGIFT PLIKTIG`
      : t`Altinn Social Security Duty`,
    sortType: 'basic',
    accessor: 'a07NoSocSec',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 180,
    Header: t`General Ledger Social Security Duty`,
    sortType: 'basic',
    accessor: 'glsocSec',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 180,
    Header: t`Social Security Duty Deviation`,
    sortType: 'basic',
    accessor: 'ssDeviation',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    cell: {
      type: 'number',
      converter: (_, item) =>
        !item?.subtitle ? item.a07NoSocSec - item.glsocSec : '',
    },
  },
  {
    width: reportMode ? 120 : 250,
    Header: t`Comment`,
    accessor: 'comment',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
    cell: {
      type: 'input',
      placeholder: t`Add comment:`,
      editable: (item) => !reportMode && !item.subtitle,
      inputType: 'text',
      disabled: payrollApproved,
      disabledTooltip: t`To modify data you need unapprove current period`,
      disableClick: true,
    },
  },
];

export default { columns };
