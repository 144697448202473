import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { validateRegistrationTokenRequest } from 'store/actions';
import { RegistrationValidation } from 'components';
import { connect } from 'react-redux';
import { fromAuth } from 'store/selectors';

class RegistrationValidationContainer extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    location: undefined,
  };

  state = {
    submitted: false,
    invalidToken: false,
    validatingToken: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { location, dispatch } = this.props;
    const { search } = location;
    const { token, companyId } = queryString.parse(search);

    if (token) {
      try {
        await dispatch(validateRegistrationTokenRequest({ token, companyId }));
        this.setState({ invalidToken: false });
      } catch (e) {
        this.setState({ invalidToken: true });
      }
    }
  };

  signIn = () => {
    const { history } = this.props;
    history.push('/login');
  };

  render() {
    const { submitted, invalidToken, validatingToken } = this.state;

    return (
      <RegistrationValidation
        signIn={this.signIn}
        {...{ submitted, invalidToken, validatingToken }}
        {...this.props}
      />
    );
  }
}

export default connect((state) => ({
  user: fromAuth.getUser(state),
}))(RegistrationValidationContainer);
