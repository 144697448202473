import React, { useState } from 'react';
import _uniqBy from 'lodash-es/uniqBy';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { AddEditGroupCompaniesModal } from 'components';

import { resourceUpdateRequest } from 'store/actions';
import { setGroupParentCompanyApi } from 'services/apihelpers';

function AddEditGroupCompaniesModalContainer(props) {
  const { item, closeModal, refetch } = props;
  const dispatch = useDispatch();
  const addedCompanies = item?.addedCompanies
    ? item?.addedCompanies.map((c) => ({
        id: c.uuid,
        label: c.companyName,
      }))
    : [];
  let companies = _uniqBy(
    [...(item.addedCompanies || []), ...(item.availableCompanies || [])],
    'uuid',
  );
  companies = companies.map((c) => ({
    id: c.uuid,
    label: `${c.companyName} - ${c?.registrationNumber}`,
  }));
  const [selectedCompanies, setSelectedCompanies] = useState(addedCompanies);

  const onSubmit = async (formData) => {
    // set companies to the group

    await dispatch(
      resourceUpdateRequest(setGroupParentCompanyApi, null, {
        groupId: item.groupId,
        createGroup: !item.groupId,
        updatedCompanies: selectedCompanies.map((el) => el.id),
        removeCompaniesFromGroup: JSON.parse(
          formData.removeCompaniesFromGroup || false,
        ),
      }),
    );

    closeModal();
    refetch();
  };
  const handleChange = (values, type) => {
    if (type === 'company') {
      setSelectedCompanies(values);
    }
  };

  return (
    <AddEditGroupCompaniesModal
      {...{
        item,
        companies,
        closeModal,
        selectedCompanies,
        onSubmit,
        handleChange,
      }}
    />
  );
}

AddEditGroupCompaniesModalContainer.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
};

AddEditGroupCompaniesModalContainer.defaultProps = {
  show: false,
  item: {},
};

export default AddEditGroupCompaniesModalContainer;
