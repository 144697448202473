import { t } from '@lingui/macro';

export const payrollSettingsPaymentTaxFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['payRollReportItemSID', 'itemName', 'accountID'],
  },
];

export default payrollSettingsPaymentTaxFilters;
