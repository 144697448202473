import React, { Component } from 'react';
import _get from 'lodash-es/get';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome';
import Marquee from 'react-fast-marquee';
import { ButtonGroup } from 'react-bootstrap';
import { t } from '@lingui/macro';

import {
  Button,
  Spinner,
  ButtonIcon as Icon,
  ChangeCompanyModal,
} from 'components';

import { trackEvent } from 'components/Analytics';
import { injectCompanyDataToURL } from 'services/helpers';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  changeCompanyApi,
  getFeatureUnleashApi,
  getActiveCompanyByOrganizationApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

class ChangeCompanyModalContainer extends Component {
  ChangeCompanyInputField = React.createRef();

  static propTypes = {
    user: PropTypes.shape({}).isRequired,
    company: PropTypes.shape({}).isRequired,
    activeCompanies: PropTypes.arrayOf(PropTypes.shape({})),
    getActiveCompanies: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeCompanies: [],
  };

  state = {
    loading: false,
    showModal: false,
    companyId: 0,
    currentCompanyId: _get(this.props, 'company.uuid', 0),
  };

  componentDidMount() {
    const { getUnleashLastCompanies } = this.props;
    getUnleashLastCompanies();
  }

  shouldComponentUpdate(nextProps) {
    const { company } = this.props;
    if (company.uuid !== nextProps.company.uuid) {
      this.setState({
        companyId: nextProps.company.uuid,
        currentCompanyId: nextProps.company.uuid,
      });
    }

    return true;
  }

  toggleHide = async () => {
    const { showModal } = this.state;
    const { user, getActiveCompanies } = this.props;
    const query = {
      page: 1,
      entries: 25,
      organizationId: user.organizationSID,
    };

    if (!showModal) {
      this.setState({ loading: true });

      try {
        await getActiveCompanies(query);
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
        this.setState(() => ({ showModal: false }));
      }

      this.setState({ loading: false, companyId: 0 });
    } else {
      this.setState((prevState) => ({
        companyId: 0,
        showModal: !prevState.showModal,
      }));
    }
  };

  handleChange = (value, isVisitedCompany) => {
    if (isVisitedCompany) {
      this.setState({
        companyId: value,
      });
    } else {
      this.setState({
        companyId: !isEmpty(value) ? value[0].id : 0,
      });
    }
  };

  handleSubmit = async (id) => {
    const { companyId } = this.state;
    const { location, activeCompanies, updateVisitedCompanies } = this.props;

    const currentCompanyId = typeof id === 'string' ? id : companyId;

    try {
      try {
        await updateVisitedCompanies({ companyUuid: currentCompanyId });
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }

      trackEvent({
        value: companyId,
        label: 'Company context',
        action: 'User Switched company',
        category: 'Company',
      });

      this.setState({ showModal: false, currentCompanyId });

      const checkedCompany =
        activeCompanies.filter((item) => item.uuid === currentCompanyId)[0] ||
        {};

      window.location.pathname = injectCompanyDataToURL(location, {
        id: currentCompanyId,
        accountingYear: checkedCompany.currentAccountingYear,
        currentWorkingPeriodEnd: checkedCompany.currentWorkingPeriodEnd,
      }).pathname;
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  changeCompany = (e) => {
    const { id } = e.currentTarget;
    this.handleChange(id, true);
    this.handleSubmit(id);
  };

  render() {
    const { loading, showModal, companyId, currentCompanyId } = this.state;
    const { company, roleType, activeCompanies } = this.props;

    let label = company?.currentCompanyName || '';

    if (company?.organizationName && roleType?.isSystemAdmin) {
      label += ` (${company?.organizationName})`;
    }

    return (
      <ButtonHolder data-loading={loading}>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="success"
            onClick={this.toggleHide}
            data-testid="company-name-btn"
            title={t`Switch Company`}
          >
            {loading ? (
              <Spinner type="white" size="md" />
            ) : (
              <ButtonInner>
                <div>
                  <Icon as={AiFillHome} marginBottom={-3} fontSize={15} />
                </div>
                {label.length > 23 ? (
                  <Marquee
                    play={false}
                    gradient={false}
                    className="marquee-container"
                  >
                    {label}
                  </Marquee>
                ) : (
                  label
                )}
              </ButtonInner>
            )}
          </Button>
          <ChangeCompanyModal
            toggleHide={this.toggleHide}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            changeCompany={this.changeCompany}
            {...{
              showModal,
              companyId,
              currentCompanyId,
              companies: activeCompanies,
              reference: this.ChangeCompanyInputField,
            }}
          />
        </ButtonGroup>
      </ButtonHolder>
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  activeCompanies: fromResource.getList(
    state,
    getActiveCompanyByOrganizationApi,
  ).validCompanies,
  company: fromCompany.getCompany(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateVisitedCompanies: (query) =>
    dispatch(resourceCreateRequest(changeCompanyApi, query)),
  getActiveCompanies: (query) =>
    dispatch(
      resourceListReadRequest(getActiveCompanyByOrganizationApi, {
        ...query,
      }),
    ),
  getUnleashLastCompanies: () => {
    dispatch(
      resourceListReadRequest(`${getFeatureUnleashApi}/last-visited-companies`),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeCompanyModalContainer);

const ButtonHolder = styled.div`
  .btn-group {
    width: ${({ dataLoading }) => !dataLoading && '100%'};
  }
`;

const ButtonInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;

  .marquee-container {
    max-width: 180px;

    .marquee {
      padding-right: 20px;
    }

    &:not(:hover) {
      .marquee {
        transform: translateX(0) !important;
      }
    }
  }
`;
