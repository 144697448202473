import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import styled, { css, keyframes } from 'styled-components/macro';
import { MdInfo } from '@react-icons/all-files/md/MdInfo';
import { MdWarning } from '@react-icons/all-files/md/MdWarning';
import { MdError } from '@react-icons/all-files/md/MdError';

import { fromEvents, fromResource } from 'store/selectors';
import { isBalanceUpdating } from 'services/apihelpers';

function getContrastYIQ(hexcolor) {
  if (!hexcolor) {
    return '#000';
  }
  if (hexcolor === '#3cb371') return '#fff';
  // eslint-disable-next-line no-param-reassign
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}

export default function UpdatedBalancePrompt() {
  const updatedBalance = useSelector(fromEvents.getDownloadProgress) || false;
  const isAlreadyUpdating =
    useSelector(
      (state) => fromResource.getList(state, isBalanceUpdating).status,
    ) || false;
  const [shouldFlash, setShouldFlash] = useState(false);

  useEffect(() => {
    if (updatedBalance?.status === 'Processed') {
      setShouldFlash(true);
    }
  }, [updatedBalance?.status, shouldFlash]);

  let color;
  let message;
  let icon;
  if (isAlreadyUpdating) {
    color = '#ffdf00'; // Color for the updating state
    message = t`Data from your accounting system is currently updating. Company data is read only. You can still navigate in Accountflow, but you will not be able to save any changes.`; // eslint-disable-line max-len
    icon = (
      <MdWarning
        size={16}
        color={getContrastYIQ(color)}
        style={{ marginRight: '1ch' }}
      />
    );
  } else {
    switch (updatedBalance.status) {
      case undefined:
        return null;
      case 'Processed': {
        color = '#3cb371';
        message = t`Data from your accounting system was recently updated. Click here to refresh.`; // eslint-disable-line max-len
        icon = (
          <MdInfo
            size={16}
            color={getContrastYIQ(color)}
            style={{ marginRight: '1ch' }}
          />
        );
        break;
      }
      case 'Failed': {
        color = '#ff0000';
        message = t`Data from your accounting system failed to update. Click here to refresh.`; // eslint-disable-line max-len
        icon = (
          <MdError
            size={16}
            color={getContrastYIQ(color)}
            style={{ marginRight: '1ch' }}
          />
        );
        break;
      }
      default: {
        color = '#ffdf00';
        message = t`Data from your accounting system is currently updating. Company data is read only. You can still navigate in Accountflow, but you will not be able to save any changes.`; // eslint-disable-line max-len
        icon = (
          <MdWarning
            size={16}
            color={getContrastYIQ(color)}
            style={{ marginRight: '1ch' }}
          />
        );
        break;
      }
    }
  }
  return (
    <UpdatedBalancePromptContainer
      backgroundColor={color}
      onClick={() => window.location.reload(true)}
      shouldFlash={shouldFlash}
    >
      <Text backgroundColor={color}>
        {icon}
        {message}
      </Text>
    </UpdatedBalancePromptContainer>
  );
}

const flashDuration = 0.7; // Duration of one flash in seconds
const pauseDuration = 10; // Duration of the pause in seconds
const totalDuration = flashDuration * 3 + pauseDuration; // Total duration of the animation cycle

const flash = keyframes`
  0%, 10%, 20%, 30%, 100% { opacity: 1; }
  5%, 15%, 25% { opacity: 0.6; }
`;

const flashAnimation = css`
  ${(props) =>
    props.shouldFlash
      ? css`
          ${flash} ${totalDuration}s ease-in-out infinite
        `
      : 'none'}
`;

const UpdatedBalancePromptContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 8px 20px;
  box-sizing: border-box;
  cursor: pointer;
  animation: ${flashAnimation};
`;

const Text = styled.p`
  font-weight: 500;
  color: ${(props) => getContrastYIQ(props.backgroundColor)};
  text-align: center;
  line-height: 1.5;
  margin: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;
