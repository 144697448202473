import React, { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { MapVATPaymentTransactionModal } from 'components';

import { getDateWithoutTimezoneOffset } from 'services/helpers';
import { fromCompany, fromResource } from 'store/selectors';
import {
  vatSettlementsUnmapApi,
  vatSettlementsMapApi,
  getVatSettlementsLinesApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  resourceDeleteRequest,
  resourceCreateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';

const getLimitMonthsValues = (period, periodType) => {
  if (periodType === 'YEARLY') {
    return { startMonth: 1, endMonth: 15 };
  }

  if (periodType === 'BIMONTHLY') {
    return { startMonth: +period * 2 - 1, endMonth: +period * 2 + 3 };
  }

  return { startMonth: +period, endMonth: +period + 3 };
};

function MapVATPaymentTransactionModalNewContainer(props) {
  const { period, periodType, toggleModal, refetchParent } = props;

  const [updateAfterClose, setUpdateAfterClose] = useState(false);

  const { accountingYear } = useParams();

  const { firstDate, lastDate } = useMemo(() => {
    let endDateYear = +accountingYear;

    const months = getLimitMonthsValues(period, periodType);

    if (months.endMonth > 12) {
      months.endMonth -= 12;
      endDateYear += 1;
    }

    // eslint-disable-next-line no-shadow
    const firstDate = getDateWithoutTimezoneOffset(
      new Date(+accountingYear, months.startMonth - 1, 1).toISOString(),
    );
    // eslint-disable-next-line no-shadow
    const lastDate = getDateWithoutTimezoneOffset(
      new Date(endDateYear, months.endMonth, 0).toISOString(),
    );

    return { firstDate, lastDate };
  }, []);

  const [dates, setDates] = useState({ from: firstDate, to: lastDate });
  const [actionSubmitting, setActionSubmitting] = useState({});

  const company = useSelector(fromCompany.getCompany);
  const { currentCompanySID } = company;
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, `${getVatSettlementsLinesApi}`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getVatSettlementsLinesApi}ListRead`),
  );
  const handleActionClick = async (id, mapped) => {
    if (mapped) {
      await dispatch(
        resourceDeleteRequest(
          // eslint-disable-next-line max-len
          `${vatSettlementsUnmapApi}?companyId=${currentCompanySID}&lineId=${id}`,
        ),
      );
    } else {
      await dispatch(
        resourceCreateRequest(
          `${vatSettlementsMapApi}?companyId=${currentCompanySID}`,
          {
            period,
            periodType,
            year: company.currentAccountingYear,
            lineId: id,
          },
        ),
      );
    }

    const newTransactions = data.map((item) => {
      if (item.lineId === id) {
        return {
          ...item,
          mappedInformation: {
            isMapped: !mapped,
            year: +accountingYear,
            periodType,
            period: +period,
          },
        };
      }

      return item;
    });

    dispatch(
      resourceListReadSuccess(getVatSettlementsLinesApi, newTransactions),
    );
  };
  const handleDateChange = (e) => {
    setDates(() => ({
      ...dates,
      [e.target.id]: e.target.value,
    }));
  };
  const getRowColor = (item) =>
    item.mappedInformation?.isMapped ? 'success' : '';

  useEffect(() => {
    dispatch(
      resourceListReadRequest(getVatSettlementsLinesApi, {
        fromDate: dates.from,
        toDate: dates.to,
        companyId: company.currentCompanySID,
      }),
    );
  }, [JSON.stringify(dates)]);

  const closeModal = () => {
    toggleModal();
    if (updateAfterClose) {
      refetchParent();
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, value) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          await handleActionClick(
            value.lineId,
            value?.mappedInformation?.isMapped,
          );
          setUpdateAfterClose(true);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <MapVATPaymentTransactionModal
      {...{
        data,
        dates,
        period,
        loading,
        closeModal,
        periodType,
        getRowColor,
        handleAction,
        accountingYear,
        handleDateChange,
        actionSubmitting,
      }}
    />
  );
}

export default MapVATPaymentTransactionModalNewContainer;
