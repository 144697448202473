import React, { useState } from 'react';
import toast from 'react-hot-toast';

import {
  OutgoingBalance,
  PaymentReconciliation,
  PayrollWithheldTax,
} from 'components';
import getErrorMessage from 'services/helpers/getErrorMessage';

function PayrollWithheldTaxContainer(props) {
  const { roleType, PRandOBdata, toggleModal, withheldTaxData } = props;
  const { rows, loading } = PRandOBdata;
  const { wtPaymentReconciliation, wtOutgoingBalance } = rows;

  const [actionSubmitting, setActionSubmitting] = useState({});

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          toggleModal('mapReports', item);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <>
      <PayrollWithheldTax
        {...{
          roleType,
          handleAction,
          withheldTaxData,
          actionSubmitting,
        }}
      />
      <PaymentReconciliation
        data={wtPaymentReconciliation}
        {...{
          loading,
          roleType,
          toggleModal,
          tableId: 'payrollWTPPaymentReconciliation',
        }}
      />
      <OutgoingBalance
        data={wtOutgoingBalance}
        {...{
          loading,
          tableId: 'payrollWTPOutgoingBalance',
        }}
      />
    </>
  );
}

export default PayrollWithheldTaxContainer;
