import { put, take, takeLatest, call, select, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { isBalanceUpdating, isValidIntegration } from 'services/apihelpers';
import {
  downloadProgress,
  errorMessage,
  connectionStatus,
  hasUpdatedBalance,
} from './actions';
import { resourceListReadRequest } from '../resource/actions';

const getCompanyID = (state) => state.company.uuid;

const MAX_RETRIES = 3;

function createEventChannel(url) {
  return eventChannel((emitter) => {
    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      emitter(data);
    };

    eventSource.onerror = (event) => {
      emitter({ type: 'SSE_ERROR', data: event });
    };

    eventSource.addEventListener('connected', (event) => {
      emitter({ type: 'connected', data: event.data });
    });

    eventSource.addEventListener('DOWNLOAD_PROGRESS', (event) => {
      emitter({ type: 'DOWNLOAD_PROGRESS', data: JSON.parse(event.data) });
    });

    eventSource.addEventListener('DOWNLOAD_ERROR', (event) => {
      emitter({ type: 'ERROR_MESSAGE', data: JSON.parse(event.data) });
    });

    eventSource.addEventListener('ERROR_MESSAGE', (event) => {
      emitter({ type: 'ERROR_MESSAGE', data: JSON.parse(event.data) });
    });

    return () => {
      eventSource.close();
    };
  });
}

function* watchServerSentEvents() {
  let retries = 0;

  while (retries < MAX_RETRIES) {
    const companyID = yield select(getCompanyID);
    if (!companyID) {
      return;
    }
    const channel = yield call(
      createEventChannel,
      `/services/eventmanager/event-stream/${companyID}`,
    );

    try {
      while (true) {
        const event = yield take(channel);

        switch (event.type) {
          case 'SSE_ERROR':
            throw new Error('SSE Connection Error');
          case 'DOWNLOAD_PROGRESS':
            yield put(downloadProgress(event.data));
            if (['Processed', 'Failed'].includes(event.data.status)) {
              yield put(
                resourceListReadRequest(isBalanceUpdating, {
                  financialYear:
                    event.data.accountingYear || event.data.period_year,
                  companyId: companyID,
                }),
              );
              yield put(
                resourceListReadRequest(isValidIntegration, {
                  companyId: companyID,
                }),
              );
              if (event.data.status === 'Processed') {
                yield put(hasUpdatedBalance());
              }
            }
            break;
          case 'ERROR_MESSAGE':
            yield put(errorMessage(event.data));
            break;
          case 'connected':
            yield put(connectionStatus(event.data));
            retries = 0;
            break;
          default:
            break;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('An unexpected error occurred:', error);
    } finally {
      if (channel) {
        channel.close();
      }

      retries += 1;
      if (retries < MAX_RETRIES) {
        yield delay(5000); // Wait for 5 seconds before retrying
      } else {
        // eslint-disable-next-line no-console
        console.error('Max retries reached. Not reconnecting.');
      }
    }
  }
}

export default function* _() {
  yield takeLatest('APP_READY', watchServerSentEvents);
}
