import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { currentCompanyDetailsApi, companyAPI } from 'services/apihelpers';

import * as actions from './actions';
import { toHome } from '../../services/routehelpers/index';
import { appReady } from '../root/actions';

/* eslint-disable no-shadow */
export function* updateCompanyInfoFromUrl(api, payload, { thunk }) {
  try {
    const company = yield call(
      [api, api.put],
      // eslint-disable-next-line max-len
      `/${companyAPI}/${payload.companyUuid}/year/${payload.year}/period/${payload.period}`,
    );

    yield put(actions.companyInfoSuccess(company, thunk));
  } catch (error) {
    yield put(actions.companyInfoFailure(error, thunk));
    yield put(push('/select-company'));
  }
}

export function* companyInfo(api, payload, { thunk }) {
  try {
    const company = yield call([api, api.get], `/${currentCompanyDetailsApi}`);

    yield put(actions.companyInfoSuccess(company, thunk));
  } catch (error) {
    if (error.response.status === 404) {
      push('/error');
    }
    yield put(actions.companyInfoFailure(error, thunk));
  }
}

export function* watchcompanyInfoRequest(api, { meta, payload }) {
  yield call(companyInfo, api, payload, meta);
}

export function* watchUpdateCompanyInfoRequest(api, { meta, payload }) {
  yield call(updateCompanyInfoFromUrl, api, payload, meta);
}

export function* companySuccessWatch(api, { meta, payload }) {
  yield call(
    updateCompanyInfoFromUrl,
    api,
    {
      companyUuid: payload.uuid,
      year: payload.currentAccountingYear,
      period: payload.currentWorkingPeriodEnd,
    },
    meta,
  );
  yield put(push(toHome()));
  yield put(appReady(true));
}

export default function* _({ api }) {
  yield takeEvery(actions.COMPANY_INFO_REQUEST, watchcompanyInfoRequest, api);
  // eslint-disable-next-line max-len
  yield takeEvery(
    actions.UPDATE_COMPANY_INFO_REQUEST,
    watchUpdateCompanyInfoRequest,
    api,
  );
  yield takeEvery(actions.COMPANY_RELOAD_SUCCESS, companySuccessWatch, api);
}
