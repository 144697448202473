import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import { Button, Heading, Spinner, ButtonIcon } from 'components';

function MoveGroupCompaniesModal(props) {
  const {
    item,
    onSubmit,
    closeModal,
    handleChange,
    companyGroups,
    selectedCompanyGroups,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>
            {t`Move`} <b>{item.companyName}</b> {t`company`}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <InputHolder>
            <AutoSuggestInput
              multiple
              clearButton
              id="select-companies"
              size="lg"
              label={t`Selected company groups to be moved to`}
              bsSize="lg"
              options={companyGroups}
              selected={selectedCompanyGroups}
              placeholder={t`Please Select Company Groups`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'companyGroups')
              }
            />
          </InputHolder>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit || !selectedCompanyGroups.length}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default MoveGroupCompaniesModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;
