import React from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings } from 'components';

import { vatOutgoingBalanceColumns } from 'services/tableshapes';
import { vatOutgoingBalanceFilters } from 'services/tableFilterShapes';

function VATReconciliationOutgoingBalanceTable(props) {
  const {
    term,
    rows,
    params,
    loading,
    roleType,
    handleAction,
    actionSubmitting,
    deviationRowSubmitting,
  } = props;
  const { accountingYear: year } = params;
  const title = t`VAT Settlements Outgoing Balance, Term - ${term}, ${year}`;

  return (
    <Card
      id="pdfToPrint"
      data-testid="pdfToPrint-vatOutgoingBalanceDeviation"
      header={<CardPDFTitle title={title} />}
    >
      <CustomTableWithAutoSettings
        {...{
          handleAction,
          actionSubmitting,
          data: rows,
          loadingId: 'description',
          tableId: 'vatOutgoingBalanceDeviation',
          filterConfigs: vatOutgoingBalanceFilters(),
          loading: loading || deviationRowSubmitting,
          columns: vatOutgoingBalanceColumns(roleType.isReadOnly),
          headerConfigs: {
            exportFileName: title,
            extraButtons: !roleType.isReadOnly && [
              {
                id: 'add',
                title: t`Add deviation row`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
        }}
      />
    </Card>
  );
}

export default VATReconciliationOutgoingBalanceTable;

VATReconciliationOutgoingBalanceTable.propTypes = {
  term: PropTypes.number,
  params: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

VATReconciliationOutgoingBalanceTable.defaultProps = {
  term: 1,
  params: { accountingYear: undefined },
  loading: true,
  handleAction: () => {},
  actionSubmitting: {},
};
