import React from 'react';
import Selecto from 'react-selecto';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table as BootstrapTable } from 'react-bootstrap';

import { Caption } from 'components';

/**
 * Table component
 */
function Table(props) {
  const {
    head,
    foot,
    hover,
    caption,
    striped,
    children,
    bordered,
    className,
    condensed,
    customHead,
    customFoot,
    responsive,
    withDragSelect,
    handleDragSelect,
    ...extraProps
  } = props;

  // Custom Bootstrap classes'
  const classes = classNames('table', {}, className);

  const onSelect = (e) => {
    if (typeof handleDragSelect === 'function') {
      handleDragSelect(e);
    }
  };

  return (
    <>
      {withDragSelect && (
        <Selecto
          continueSelect
          hitRate={1}
          onSelect={onSelect}
          selectByClick={false}
          dragContainer=".content"
          selectableTargets={['.table-row']}
        />
      )}
      <BootstrapTable
        style={{ fontSize: 'small' }}
        hover={hover}
        striped={striped}
        bordered={bordered}
        condensed={condensed}
        className={classes}
        responsive={responsive}
        {...extraProps}
      >
        {caption && <Caption style={{ marginLeft: '10px' }}>{caption}</Caption>}
        {head && !customHead && <thead>{head}</thead>}
        {!head && customHead && [customHead]}
        {foot && !customFoot && <tfoot>{foot}</tfoot>}
        {!foot && customFoot && [customFoot]}
        <tbody>{children}</tbody>
      </BootstrapTable>
    </>
  );
}

Table.propTypes = {
  head: PropTypes.node,
  foot: PropTypes.node,
  hover: PropTypes.bool,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  striped: PropTypes.bool,
  children: PropTypes.node,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  condensed: PropTypes.bool,
  customHead: PropTypes.node,
  customFoot: PropTypes.node,
  responsive: PropTypes.bool,
  withDragSelect: PropTypes.bool,
  handleDragSelect: PropTypes.func,
};

Table.defaultProps = {
  head: undefined,
  foot: undefined,
  hover: true,
  caption: undefined,
  striped: false,
  children: undefined,
  bordered: false,
  className: undefined,
  condensed: false,
  customHead: undefined,
  customFoot: undefined,
  responsive: true,
  withDragSelect: false,
  handleDragSelect: undefined,
};

export default Table;
