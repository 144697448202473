import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Row, Col } from 'react-bootstrap';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { BiPencil } from '@react-icons/all-files/bi/BiPencil';
import { ImFilesEmpty } from '@react-icons/all-files/im/ImFilesEmpty';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

import {
  Table,
  Field,
  Button,
  Spinner,
  TableRow,
  TableCell,
  Paragraph,
  ButtonIcon,
} from 'components';

/**
 * Opening Balance Registration component
 */
function OpeningBalanceRegistration(props) {
  const {
    data,
    search,
    failed,
    loading,
    lineData,
    roleType,
    handleChange,
    deviationList,
    handleIconGroup,
    previewDataHeader,
    handleEditSavedRowsChange,
    openingBalanceRegistrationRows,
  } = props;

  if (search.reportMode && isEmpty(deviationList)) {
    return (
      <Paragraph>
        <Trans>It seems there are no Deviations in this account.</Trans>
      </Paragraph>
    );
  }
  if (loading || isEmpty(data)) {
    return <Spinner />;
  }
  if (failed) {
    return (
      <Paragraph>
        <Trans>
          Something went wrong while fetching data. Please, try again later.
        </Trans>
      </Paragraph>
    );
  }

  const openingBalanceRowItems = new Map(openingBalanceRegistrationRows);
  const savedOpeningBalanceRegistrationRowItems = new Map(lineData);
  const openingBalanceData = [];
  savedOpeningBalanceRegistrationRowItems.forEach((item) => {
    const lineSID = item.originalRecordId.split('_');
    openingBalanceData.push(
      <TableRow
        key={`openingBalanceRowDataItems-${item.partialLineSID}`}
        kind={item.enableEdit ? 'warning' : ''}
      >
        <TableCell>
          {item.enableEdit && !roleType.isReadOnly ? (
            <div>
              {item.gltransactionId}
              <Button
                size="sm"
                simple
                magnify
                kind="default"
                onClick={() => handleIconGroup(6, null, item.rowId)}
              >
                <ButtonIcon as={BiPencil} />
              </Button>
            </div>
          ) : (
            `${item.gltransactionId} (${lineSID[0]})`
          )}
        </TableCell>
        <TableCell>
          {`${item.accountID} - ${item.accountIdDescription}`}
        </TableCell>
        <TableCell number className="text-right">
          {item.amount}
        </TableCell>
        <TableCell number className="text-right">
          {item.derivativeAmount}
        </TableCell>
        <TableCell>
          {item.enableEdit && !roleType.isReadOnly ? (
            <Field
              defaultValue={item.description}
              size="sm"
              name="description"
              onChange={(e) =>
                handleEditSavedRowsChange(e, item.partialLineSID)
              }
            />
          ) : (
            item.description
          )}
        </TableCell>
        <TableCell number className="text-right">
          {item.enableEdit && !roleType.isReadOnly ? (
            <Field
              type="number"
              size="sm"
              name="partialAmount"
              value={item.partialAmount}
              className="text-right"
              onChange={(e) =>
                handleEditSavedRowsChange(e, item.partialLineSID)
              }
            />
          ) : (
            parseFloat(item.partialAmount)
          )}
        </TableCell>
        <TableCell className="text-right">
          {!roleType.isReadOnly && (
            <Button
              simple
              magnify
              kind={item.enableEdit ? 'danger' : ''}
              onClick={() => handleIconGroup(9, null, item.partialLineSID)}
            >
              {item.enableEdit ? (
                <ButtonIcon as={IoClose} />
              ) : (
                <ButtonIcon as={BiPencil} />
              )}
            </Button>
          )}
          {!roleType.isReadOnly && (
            <Button
              simple
              magnify
              kind="danger"
              onClick={() => handleIconGroup(8, null, item.partialLineSID)}
            >
              <ButtonIcon as={BiTrash} />
            </Button>
          )}
        </TableCell>
      </TableRow>,
    );
  });

  const dynamicRows = [];
  openingBalanceRowItems.forEach((item) => {
    const lineSID = item.recordId !== undefined ? item.recordId.split('_') : '';
    dynamicRows.push(
      <TableRow key={`openingBalanceRowItems-${item.rowId}`}>
        <TableCell className="text-left">
          {item.transactionId === '' ? (
            <Button
              size="sm"
              magnify
              kind="default"
              onClick={() => handleIconGroup(6, null, item.rowId)}
            >
              Map Transaction ID
            </Button>
          ) : (
            <div>
              {item.transactionId}{' '}
              {item.recordId !== undefined ? `(${lineSID[0]})` : ''}
              <Button
                size="sm"
                simple
                magnify
                kind="default"
                onClick={() => handleIconGroup(6, null, item.rowId)}
              >
                <ButtonIcon as={BiPencil} />
              </Button>
            </div>
          )}
        </TableCell>
        <TableCell>
          {item.accountId !== null &&
            `${item.accountId} - ${item.accountIdDescription}`}
        </TableCell>
        <TableCell number className="text-right">
          {item.openingBalance}
        </TableCell>

        <TableCell number className="text-right">
          {item.acumulatedValue || 0}
        </TableCell>

        <TableCell>
          <Field
            type="text"
            size="sm"
            name="description"
            placeholder={t`Enter Description`}
            defaultValue={item.description}
            onChange={(e) =>
              handleChange(e, item.rowId, item.generalLedgerAccountSID)
            }
          />
        </TableCell>
        <TableCell>
          <Field
            type="number"
            size="sm"
            name="amount"
            value={item.amount}
            className="text-right"
            placeholder={t`Enter amount`}
            onChange={(e) =>
              handleChange(e, item.rowId, item.generalLedgerAccountSID)
            }
          />
        </TableCell>
        <TableCell className="text-right">
          <Button
            simple
            magnify
            kind="default"
            onClick={() => handleIconGroup(7, null, item.rowId)}
          >
            <ButtonIcon as={ImFilesEmpty} />
          </Button>
          <Button
            simple
            magnify
            kind="danger"
            onClick={() => handleIconGroup(5, null, item.rowId)}
          >
            <ButtonIcon as={BiTrash} />
          </Button>
        </TableCell>
        <TableCell colSpan={1} />
      </TableRow>,
    );
  });

  return (
    <div>
      <Row>
        <Col>
          <Table responsive customHead={previewDataHeader}>
            {dynamicRows}
            {openingBalanceData}
          </Table>
        </Col>
      </Row>
    </div>
  );
}

OpeningBalanceRegistration.propTypes = {
  data: PropTypes.shape({}),
  /** @type {shape} user */
  /** @type {func} handleChange */
  handleChange: PropTypes.func.isRequired,
  /** @type {func} handleReconcile */
  handleIconGroup: PropTypes.func.isRequired,
  /** @type {shape} search */
  loading: PropTypes.bool,
  /** @type {bool} failed */
  failed: PropTypes.bool,
  /** @type {shape} accountDetailsList */
  deviationList: PropTypes.shape({}),
  /** @type {shape} search */
  search: PropTypes.shape({}).isRequired,
  /** @type func */

  lineData: PropTypes.shape({}),
  /** @type {node} handleEditSavedRowsChange */
  handleEditSavedRowsChange: PropTypes.func.isRequired,
  previewDataHeader: PropTypes.node.isRequired,
  openingBalanceRegistrationRows: PropTypes.shape({}),
};

OpeningBalanceRegistration.defaultProps = {
  data: undefined,
  loading: true,
  failed: false,
  deviationList: undefined,
  lineData: undefined,
  openingBalanceRegistrationRows: undefined,
};

export default OpeningBalanceRegistration;
