import React from 'react';
import { t } from '@lingui/macro';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';

/**
 * Asset Reconciliation Overview Columns
 */

export const columns = (isReadOnly) => [
  {
    width: 250,
    Header: ' ',
    disableSortBy: true,
    columns: [
      {
        width: 250,
        Header: t`Category`,
        accessor: 'assetCategoryName',
        className: '',
        withTooltip: true,
      },
    ],
  },
  {
    width: 390,
    Header: t`Purchase value`,
    className: 'flex-center',
    disableSortBy: true,
    columns: [
      {
        width: 130,
        Header: t`Assets`,
        sortType: 'basic',
        accessor: 'assetPurchaseValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectAssetPurchase',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`GL`,
        sortType: 'basic',
        accessor: 'glPurchaseValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectPurchaseGLAccounts',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`Deviation`,
        sortType: 'basic',
        accessor: 'purchaseValueDeviation',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          converter: (_, row) => row.glPurchaseValue - row.assetPurchaseValue,
        },
      },
    ],
  },
  {
    width: 390,
    Header: t`Accumulated Depreciations`,
    className: 'flex-center',
    disableSortBy: true,
    columns: [
      {
        width: 130,
        Header: t`Assets`,
        sortType: 'basic',
        accessor: 'assetDepreciationValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectAssetDepreciation',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`GL`,
        sortType: 'basic',
        accessor: 'glDepreciationValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectDepreciationGLAccounts',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`Deviation`,
        sortType: 'basic',
        accessor: 'depreciationValueDeviation',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          converter: (_, row) =>
            row.glDepreciationValue - row.assetDepreciationValue,
        },
      },
    ],
  },
  {
    width: 390,
    Header: t`Adjustments`,
    className: 'flex-center',
    disableSortBy: true,
    columns: [
      {
        width: 130,
        Header: t`Assets`,
        sortType: 'basic',
        accessor: 'assetAdjustmentValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectAssetAdjustment',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`GL`,
        sortType: 'basic',
        accessor: 'glAdjustmentValue',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectAdjustmentsGLAccounts',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`Deviation`,
        sortType: 'basic',
        accessor: 'adjustmentValueDeviation',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          disableSortBy: true,
          converter: (_, row) =>
            row.glAdjustmentValue - row.assetAdjustmentValue,
        },
      },
    ],
  },
  {
    width: 390,
    Header: t`Closing balance`,
    className: 'flex-center',
    disableSortBy: true,
    columns: [
      {
        width: 130,
        Header: t`Assets`,
        sortType: 'basic',
        accessor: 'assetTotal',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectAssetTotal',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`GL`,
        sortType: 'basic',
        accessor: 'glTotal',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
          action: 'redirectClosingGLAccounts',
          disableClick: true,
        },
      },
      {
        width: 130,
        Header: t`Deviation`,
        sortType: 'basic',
        accessor: 'totalDeviation',
        className: 'flex-right',
        disableSortBy: true,
        cell: {
          type: 'number',
          converter: (_, row) => row.glTotal - row.assetTotal,
        },
      },
    ],
  },
  ...(!isReadOnly
    ? [
        {
          width: 85,
          Header: ' ',
          accessor: 'actions',
          disableSortBy: true,
          columns: [
            {
              width: 85,
              Header: t`Actions`,
              accessor: 'actions',
              className: 'flex-center',
              disableSortBy: true,
              cell: {
                type: 'actions',
                disableClick: true,
                actions: [
                  {
                    id: 'edit',
                    icon: () => <GoPencil />,
                    tooltip: t`Edit`,
                  },
                  {
                    id: 'delete',
                    icon: () => <BiTrash />,
                    tooltip: t`Delete`,
                  },
                ],
              },
            },
          ],
        },
      ]
    : []),
];

export default { columns };
