import React, { useState, useRef, useMemo } from 'react';
import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { ButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';

import { Button, Spinner, Field, ReportSettings } from 'components';

import { useOnClickOutside } from 'services/hooks';
import { reportPackageDefaultValues } from 'constants/reportSettings';

const getDefaultValues = (checkboxes) =>
  checkboxes.reduce(
    (result, next) => ({ ...result, [next.id]: !next.disabled }),
    {},
  );

function CustomDropdown(props) {
  const { title, icon, submitting, disabled, checkboxes, handleButtonClick } =
    props;

  const defaultFilterState = useMemo(() => reportPackageDefaultValues, []);

  const [isOpened, setOpened] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(() =>
    getDefaultValues(checkboxes),
  );
  const [additionalFiltersState, setAdditionalFiltersState] = useState(
    () => defaultFilterState,
  );

  const containerRef = useRef(null);

  const toggleOpen = () => {
    setOpened((state) => !state);

    if (isOpened) {
      setCheckboxValues(() => getDefaultValues(checkboxes));
    }
  };

  const handleCheckboxChange = (e) => {
    setCheckboxValues((state) => ({
      ...state,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearState = () => {
    if (isOpened) {
      setOpened(false);
      setCheckboxValues(() => getDefaultValues(checkboxes));
      setAdditionalFiltersState(defaultFilterState);
    }
  };

  const handleClick = async () => {
    await handleButtonClick(checkboxValues, additionalFiltersState);
    clearState();
  };

  useOnClickOutside(containerRef, clearState);

  return (
    <Container ref={containerRef}>
      <ButtonHolder
        fill
        magnify
        size="sm"
        kind="success"
        onClick={toggleOpen}
        disabled={disabled || submitting}
      >
        {submitting ? (
          <Spinner size="md" />
        ) : (
          <>
            {icon} {title}
            <ArrowIcon rotate={isOpened} />
          </>
        )}
      </ButtonHolder>
      {isOpened && (
        <DropdownContent>
          {checkboxes.map((checkbox) => (
            <Field
              horizontal
              key={checkbox.id}
              id={checkbox.id}
              type="checkbox"
              name={checkbox.id}
              label={checkbox.label}
              disabled={checkbox.disabled}
              checked={checkboxValues[checkbox.id]}
              onChange={handleCheckboxChange}
            />
          ))}
          <ReportSettings
            type="ALL"
            state={additionalFiltersState}
            onChange={setAdditionalFiltersState}
          />
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="success"
              onClick={handleClick}
              disabled={submitting}
            >
              {submitting ? <Spinner size="md" /> : title}
            </Button>
          </ButtonGroup>
        </DropdownContent>
      )}
    </Container>
  );
}

export default CustomDropdown;

const Container = styled.div`
  position: relative;

  .btn-group {
    margin-left: 0 !important;
    margin-top: 5px;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
`;

const DropdownContent = styled.div`
  padding: 20px;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  width: max-content;
  z-index: 999;
  background: white;
  border-radius: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  transition: height 0.3s;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  animation: height 0.3s ease-in;

  @keyframes height {
    0% {
      max-height: 0;
      padding: 0 20px;
    }
    50% {
      padding: 20px;
    }
    100% {
      max-height: 300px;
    }
  }
`;

const ButtonHolder = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-bottom: 0 !important;
  }
`;

const ArrowIcon = styled(MdArrowDropDown)`
  color: white;
  fill: white;
  margin-left: 5px;
  font-size: 20px;

  ${({ rotate }) => rotate && `transform: rotate(180deg)`}
`;
