import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';

import { SystemInfoPanel } from 'components';

import { fromCompany, fromResource } from 'store/selectors';
import { getCMCompanySystem } from 'services/apihelpers';

class SystemInfoPanelContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number,
    }).isRequired,
    systemInfo: PropTypes.shape({
      accountingSystemName: PropTypes.string,
      systemEntityId: PropTypes.string,
      username: PropTypes.string,
      token: PropTypes.string,
      lastUpdateDateTime: PropTypes.string,
    }),
    loading: PropTypes.bool,
    failed: PropTypes.bool,
  };

  static defaultProps = {
    systemInfo: {},
    loading: true,
    failed: false,
  };

  shouldComponentUpdate(nextProps) {
    const { company } = this.props;

    if (
      nextProps.company.currentCompanySID !== company.currentCompanySID ||
      nextProps.company.currentAccountingYear !==
        company.currentAccountingYear ||
      nextProps.company.currentWorkingPeriodEnd !==
        company.currentWorkingPeriodEnd ||
      nextProps.company.currentWorkingPeriodStart !==
        company.currentWorkingPeriodStart
    ) {
      this.fetchData(nextProps);
    }

    return true;
  }

  render() {
    const { loading, failed, systemInfo } = this.props;
    return <SystemInfoPanel {...{ loading, failed, systemInfo }} />;
  }
}

const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
  systemInfo: fromResource.getList(state, getCMCompanySystem),
  loading: pending(state, `${SystemInfoPanelContainer}ListRead`),
  failed: rejected(state, `${SystemInfoPanelContainer}ListRead`),
});

export default connect(mapStateToProps)(SystemInfoPanelContainer);
