import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import b64toBlob from 'b64-to-blob';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentFlow, FileOverview } from 'components';
import getErrorMessage from 'services/helpers/getErrorMessage';

import {
  resourceDeleteRequest,
  resourceUpdateRequest,
  resourceListReadSuccess,
  resourceListReadRequest,
} from 'store/actions';
import {
  toReconciliation,
  toVATReconciliation,
  toPayrollReconciliation,
} from 'services/routehelpers';
import {
  allFiles,
  viewFileApi,
  deleteFileApi,
  renameFileApi,
} from 'services/apihelpers';
import { fromResource } from 'store/selectors';

function DocumentFlowContainer(props) {
  const { company, location, roleType, history } = props;

  const data = useSelector((state) => fromResource.getList(state, allFiles));
  const loading = useSelector((state) => pending(state, `${allFiles}ListRead`));
  const dispatch = useDispatch();

  const [actionSubmitting, setActionSubmitting] = useState({});
  const [previewFile, setPreviewFile] = useState({});
  const [fileName, setFileName] = useState();

  const getSearch = () => {
    const search = queryString.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });

    return {
      reportMode: search.reportMode || false,
    };
  };

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(allFiles, {
        companyId: company.uuid,
      }),
    );
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const deleteFile = async (_props) => {
    _setActionSubmitting(_props.fileKey, true);
    await dispatch(
      resourceDeleteRequest(deleteFileApi, null, {
        companyId: company.currentCompanySID,
        transactionFileSID: _props.id,
        uploadFileName: _props.fileKey,
      }),
    );

    const collection = data.filter((item) => item.fileKey !== _props.fileKey);

    await dispatch(resourceListReadSuccess(allFiles, collection));
    _setActionSubmitting(_props.fileKey, false);
  };

  const editFilename = async (_props) => {
    _setActionSubmitting(_props.row.fileKey, true);

    await dispatch(
      resourceUpdateRequest(renameFileApi, null, {
        companyId: company.currentCompanySID,
        transactionFileSID: _props.row.id,
        fileName: _props.value,
      }),
    );
    const collection = data.map((item) =>
      item.fileKey === _props.row.fileKey
        ? { ...item, fileName: _props.value }
        : item,
    );

    await dispatch(resourceListReadSuccess(allFiles, collection));
    _setActionSubmitting(_props.row.fileKey, false);
  };

  const openPreviewFile = async (item) => {
    setFileName(item.fileName);
    _setActionSubmitting(`preview-${item.fileKey}`, true);
    const query = {
      companyId: company.currentCompanySID,
      uploadFileName: item.fileKey,
    };

    const response = await dispatch(
      resourceListReadRequest(viewFileApi, query),
    );

    setPreviewFile(() => ({
      ...response,
      url: `${response.mimeType}${response.blobImage}`,
    }));
    _setActionSubmitting(`preview-${item.fileKey}`, false);
  };

  const downloadFile = async (item) => {
    _setActionSubmitting(item.fileKey, true);

    const query = {
      companyId: company.currentCompanySID,
      uploadFileName: item.fileKey,
    };
    const response = await dispatch(
      resourceListReadRequest(viewFileApi, query),
    );
    if (response) {
      const blob = b64toBlob(response.blobImage, response.mimeType);
      FileSaver.saveAs(blob, item.fileName);
    }

    _setActionSubmitting(item.fileKey, false);
  };

  const redirectByAccountOrTransactionId = (_props) => {
    let pushTo;

    if (_props.fileCategory.toUpperCase() === 'PAYROLL') {
      pushTo = toPayrollReconciliation({ bimonthly: true });
    } else if (_props.fileCategory === 'VAT') {
      pushTo = toVATReconciliation();
    } else if (_props.fileCategory !== 'PAYROLL_SETTLEMENTS') {
      pushTo = toReconciliation({
        account: _props.accountId,
      });
    }

    history.push(pushTo);
  };

  const handleAction = async (action, _props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellSave': {
          await editFilename(_props);
          break;
        }

        case 'rowClick': {
          redirectByAccountOrTransactionId(_props);
          break;
        }

        case 'download': {
          await downloadFile(_props);
          break;
        }

        case 'preview': {
          await openPreviewFile(_props);
          break;
        }

        case 'delete': {
          await deleteFile(_props);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const closePreviewFile = () => setPreviewFile({});

  useEffect(() => {
    fetchData();
  }, [company.uuid]);

  return (
    <>
      <FileOverview
        item={previewFile}
        open={!!Object.keys(previewFile).length}
        close={closePreviewFile}
        title={fileName}
      />
      <DocumentFlow
        {...{
          data,
          loading,
          company,
          handleAction,
          actionSubmitting,
          search: getSearch(),
          isReadOnly: roleType?.isReadOnly,
        }}
      />
    </>
  );
}

export default DocumentFlowContainer;
