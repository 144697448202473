import React from 'react';
import { Toaster } from 'react-hot-toast';

import { RenderRoutes, BuildInfo, VersionAlert } from 'components';
import { ChristmasModeProvider } from 'services/context/ChristmasMode';
import { VersionProvider } from 'services/versionContext';

import Analytics from './Analytics';
import MaintenanceModeComponent from './MaintenanceMode';

import routes from './routes';

const toastOptions = {
  success: {
    style: {
      background: '#41d888',
      color: 'white',
      textAlign: 'left',
    },
    iconTheme: {
      primary: 'white',
      secondary: '#41d888',
    },
  },
  error: {
    style: {
      background: '#f94416',
      color: 'white',
      textAlign: 'left',
    },
    iconTheme: {
      primary: 'white',
      secondary: '#f94416',
    },
  },
};

function App(props) {
  return (
    <VersionProvider>
      <MaintenanceModeComponent>
        <ChristmasModeProvider>
          <BuildInfo />
          <VersionAlert />
          <RenderRoutes routes={routes()} props={props} />
        </ChristmasModeProvider>
        <Analytics />
        <Toaster
          position="top-right"
          containerStyle={{
            top: 75,
          }}
          toastOptions={toastOptions}
        />
      </MaintenanceModeComponent>
    </VersionProvider>
  );
}

export default App;
