import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { Modal, ButtonGroup, Form } from 'react-bootstrap';

import {
  Toggle,
  Field,
  Button,
  Heading,
  Spinner,
  ButtonIcon,
} from 'components';

function BalanceDataModal(props) {
  const {
    mfa,
    showMFA,
    loading,
    openMFA,
    roleType,
    closeModal,
    handleChange,
    handleSubmit,
    altinnUsername,
    organizationCode,
    organizationName,
    isMainAltinnData,
    handleToggleChange,
    isUserGroupRequired,
    isCompanyGroupRequired,
    handleIsMainAltinnDataChange,
  } = props;
  // eslint-disable-next-line max-len
  const checkLabel = t`Add Altinn integration to all clients in organization`;

  return (
    <Modal show onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Edit Organization Admin Modal</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={handleChange}>
          <Field
            noBorder
            type="text"
            size="small"
            name="organizationName"
            label={t`Organization Name`}
            defaultValue={organizationName}
          />
          <Field
            noBorder
            type="text"
            size="small"
            name="organizationCode"
            label={t`Organization Code`}
            defaultValue={organizationCode}
          />
          {!!showMFA && !roleType.isReadOnly && (
            <ButtonHolder>
              <ToggleHolder>
                <Toggle
                  disabled
                  value={mfa}
                  label={t`Multi factor authorization`}
                  labelPosition="top"
                />
              </ToggleHolder>
              <Button onClick={openMFA}>
                <Trans>Configure</Trans>
              </Button>
            </ButtonHolder>
          )}
          {!roleType.isReadOnly && (
            <>
              <ToggleHolder>
                <Toggle
                  id="isUserGroupRequired"
                  value={isUserGroupRequired}
                  label={t`User Group required`}
                  onClick={handleToggleChange}
                />
              </ToggleHolder>
              <ToggleHolder>
                <Toggle
                  id="isCompanyGroupRequired"
                  value={isCompanyGroupRequired}
                  label={t`Company Group required`}
                  onClick={handleToggleChange}
                />
              </ToggleHolder>
            </>
          )}
          <hr />
          <Heading level={4}>
            <Trans> Altinn Integration </Trans>
          </Heading>
          <Field
            noBorder
            size="small"
            name="altinnUsername"
            type="text"
            label={t`Altinn System ID`}
            placeholder={t`Please Enter Altinn System ID`}
            defaultValue={altinnUsername}
            autoComplete="off"
          />
          <Field
            noBorder
            size="small"
            name="altinnPassword"
            type="password"
            label={t`Altinn System Password`}
            placeholder={t`Please Enter Altinn System Password`}
            autoComplete="off"
          />
        </Form>
        <CheckHolder>
          <Field
            horizontal
            id="isMainAltinnData"
            type="checkbox"
            name="isMainAltinnData"
            label={checkLabel}
            value={isMainAltinnData}
            onChange={handleIsMainAltinnDataChange}
          />
        </CheckHolder>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            kind="success"
            magnify
            type="submit"
            onClick={handleSubmit}
          >
            <ButtonIcon as={HiCheck} />{' '}
            {loading ? <Spinner type="white" size="md" /> : t`Submit`}
          </Button>
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

BalanceDataModal.propTypes = {
  payload: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BalanceDataModal;

const CheckHolder = styled.div`
  margin-top: 10px;
`;

const ButtonHolder = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;
  justify-content: space-between;
`;

const ToggleHolder = styled.div`
  margin-right: 50px;
`;
