import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { DashboardNavbar, UpdatedBalancePrompt } from 'components';
import AltinnExpireModal from 'containers/AltinnExpireModal';
import AlgoliaModal from 'containers/AlgoliaModal';
import MaintenanceBanner from 'containers/MaintenanceBanner';

import { fromCompany, fromResource } from 'store/selectors';
import {
  sidebarToggle,
  companyInfoSuccess,
  resourceListReadRequest,
} from 'store/actions';
import {
  injectCompanyDataToURL,
  changeDateFormatAndZone,
  periodSelectorValueInitialize,
} from 'services/helpers';

import { getAccountsNotMappedApi } from 'services/apihelpers';

const handleSelect = (eventKey) => {
  switch (eventKey) {
    case 1.1:
      break;
    case 1.2:
      break;
    case 1.3:
      break;
    case 1.4:
      break;
    case 1.5:
    default:
      break;
  }
};

const getMinutesLeft = (date) => {
  if (!date) {
    return 0;
  }

  const startDate = new Date();
  const endDate = changeDateFormatAndZone(date);

  const diff = endDate - startDate;

  if (diff > 0) {
    return Math.floor(Math.abs(diff) / 1000 / 60);
  }

  return 0;
};

class DashboardNavbarContainer extends Component {
  static propTypes = {
    location: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    accountsNotMapped: PropTypes.number,
    roleType: PropTypes.shape({}).isRequired,
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
      currentWorkingPeriodStart: PropTypes.number,
      currentWorkingPeriodEnd: PropTypes.number,
      currentPeriodType: PropTypes.string,
    }).isRequired,
    readAccountsNotMapped: PropTypes.func.isRequired,
    sidebarStatusUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accountsNotMapped: undefined,
  };

  state = {
    expanded: true,
    periodLock: false,
    algoliaProps: {},
    altinnExpireIn: getMinutesLeft(this.props.altinnExpiration.expiredDate),
    showExpireAltinn: false,
  };

  componentDidMount() {
    if (this.state.altinnExpireIn) {
      this.startTimer();
    }
    this.props.readAccountsNotMapped({
      year: this.props.match.params.accountingYear,
      companyId: this.props.match.params.companyId,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { match, altinnExpiration } = this.props;

    if (
      JSON.stringify(nextProps.altinnExpiration) !==
      JSON.stringify(altinnExpiration)
    ) {
      this.setState(
        {
          altinnExpireIn: getMinutesLeft(
            nextProps.altinnExpiration.expiredDate,
          ),
        },
        this.startTimer,
      );
    }

    if (
      JSON.stringify(nextProps.match.params) !== JSON.stringify(match.params)
    ) {
      this.props.readAccountsNotMapped({
        year: nextProps.match.params.accountingYear,
        companyId: nextProps.match.params.companyId,
      });
    }

    return true;
  }

  startTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if (this.state.altinnExpireIn) {
        this.setState((prevState) => ({
          altinnExpireIn: prevState.altinnExpireIn - 1,
        }));
      } else {
        clearInterval(this.interval);
      }
    }, 60 * 1000);
  };

  handlePeriodChange = async (event, periodType) => {
    const { target } = event;
    const { company, history, location, changeCompanyPeriod } = this.props;
    const period = parseInt(target.getAttribute('id'), 10);
    const currentWorkingPeriodEnd = periodSelectorValueInitialize(
      periodType,
      period,
    );

    const newLocation = injectCompanyDataToURL(location, {
      id: company.uuid,
      currentWorkingPeriodEnd,
      accountingYear: company.currentAccountingYear,
    });
    const query = queryString.parse(newLocation.search);

    if (query.page && query.page !== '1') {
      query.page = '1';
      newLocation.search = `?${queryString.stringify(query)}`;
    }

    await changeCompanyPeriod({
      ...company,
      currentWorkingPeriodEnd,
    });
    history.push(newLocation);
  };

  handleSidebarChangeClick = () => {
    const { sidebarStatusUpdate } = this.props;
    this.setState((prevState) => {
      document.body.classList.toggle(
        'sidebar-mini',
        !prevState.miniSidebarEnabled,
      );

      sidebarStatusUpdate(!prevState.miniSidebarEnabled);
      return {
        miniSidebarEnabled: !prevState.miniSidebarEnabled,
      };
    });
  };

  handleMenuToggle = () => {
    this.setState((prevState) => {
      document.documentElement.classList.toggle('nav-open', prevState.expanded);
      return {
        expanded: !prevState.expanded,
      };
    });
  };

  toggleAltinn = () => {
    this.setState((prevState) => ({
      showExpireAltinn: !prevState.showExpireAltinn,
    }));
  };

  goBack = () => this.props.history.goBack();

  handleSearch = () => {
    this.setState({ algoliaProps: { show: true } });
  };

  closeAlgoliaModal = () => {
    this.setState({ algoliaProps: {} });
  };

  render() {
    const {
      expanded,
      periodLock,
      algoliaProps,
      altinnExpireIn,
      showExpireAltinn,
    } = this.state;
    const { user, company, history, location, roleType, accountsNotMapped } =
      this.props;
    const { currentPeriodType } = company || {};
    const { currentWorkingPeriodEnd } = company || {};
    let activePeriods = 0;

    if (currentWorkingPeriodEnd) {
      activePeriods = Array.from(Array(currentWorkingPeriodEnd).keys());
    }

    return (
      <>
        <MaintenanceBanner />
        <UpdatedBalancePrompt />
        <AlgoliaModal
          {...algoliaProps}
          onClose={this.closeAlgoliaModal}
          roleType={roleType}
        />
        <DashboardNavbar
          goBack={this.goBack}
          expanded={expanded}
          handleSearch={this.handleSearch}
          handleMenuToggle={this.handleMenuToggle}
          handleAltinnClick={this.toggleAltinn}
          handlePeriodChange={this.handlePeriodChange}
          handleSidebarChangeClick={this.handleSidebarChangeClick}
          {...{
            user,
            company,
            history,
            location,
            roleType,
            periodLock,
            activePeriods,
            altinnExpireIn,
            accountsNotMapped,
            currentPeriodType,
            handleSelect,
          }}
        />
        <AltinnExpireModal
          show={showExpireAltinn}
          handleClose={this.toggleAltinn}
          altinnExpireIn={altinnExpireIn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
  accountsNotMapped: fromResource.getList(state, getAccountsNotMappedApi).count,
});

const mapDispatchToProps = (dispatch) => ({
  sidebarStatusUpdate: (sidebarAction) =>
    dispatch(sidebarToggle(sidebarAction)),
  readAccountsNotMapped: (query) =>
    dispatch(resourceListReadRequest(getAccountsNotMappedApi, query)),
  changeCompanyPeriod: (company) => dispatch(companyInfoSuccess(company)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardNavbarContainer),
);
