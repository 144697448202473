import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';

function Spinner(props) {
  const { type, size } = props;

  let color;
  let wrapperHeight;

  switch (type) {
    case 'normal':
      color = '#66615b';
      break;
    case 'white':
      color = '#FFFFFF';
      break;
    case 'primary':
      color = '#7a9e9f';
      break;
    case 'info':
      color = '#68b3c8';
      break;
    case 'success':
      color = '#7ac29a';
      break;
    case 'warning':
      color = '#F3BB45';
      break;
    case 'danger':
      color = '#EB5E28';
      break;
    default:
      break;
  }

  switch (size) {
    case 'sm': {
      wrapperHeight = '10px';
      break;
    }
    case 'md': {
      wrapperHeight = '20px';
      break;
    }
    case 'lg':
    default: {
      wrapperHeight = '40px';
      break;
    }
  }

  const waveKey = keyframes`
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
    }

  `;

  return (
    <Wrapper wrapperHeigth={wrapperHeight}>
      <InsiderRect color={color} waveKey={waveKey} />
      <InsiderRectTwo color={color} waveKey={waveKey} />
      <InsiderRectThree color={color} waveKey={waveKey} />
      {size !== 'sm' && (
        <>
          <InsiderRectFour color={color} waveKey={waveKey} />
          <InsiderRectFive color={color} waveKey={waveKey} />
        </>
      )}
    </Wrapper>
  );
}

Spinner.propTypes = {
  type: PropTypes.oneOf([
    'info',
    'white',
    'normal',
    'danger',
    'primary',
    'success',
    'warning',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Spinner.defaultProps = {
  type: 'normal',
  size: 'md',
};

export default Spinner;

const Wrapper = styled.div`
  height: ${(props) => props.wrapperHeigth};
  max-height: 50px;
`;

const InsiderRect = styled.div`
  width: 6px;
  height: 100%;
  display: inline-block;
  animation: ${(props) => props.waveKey} 1.3s infinite ease-in-out;
  margin-left: 1px;
  background-color: ${(props) => props.color};
`;

const InsiderRectTwo = styled(InsiderRect)`
  animation-delay: -1.1s;
`;

const InsiderRectThree = styled(InsiderRect)`
  animation-delay: -1s;
`;

const InsiderRectFour = styled(InsiderRect)`
  animation-delay: -0.9s;
`;

const InsiderRectFive = styled(InsiderRect)`
  animation-delay: -0.8s;
`;
