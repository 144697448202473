import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { ButtonGroup, Row } from 'react-bootstrap';

import { Button, Heading, CustomTable } from 'components';

import { useCurrentWidth } from 'services/hooks';
import { bankTemplateNewColumns } from 'services/tableshapes';
import { bankTemplateFilters } from 'services/tableFilterShapes';
import { detectTopContentModeWhen2Tables } from 'services/helpers';
import { filterByReconciledMatchedTransaction } from 'services/shapes';

/**
 * Bank Template component
 */
function BankTemplate(props) {
  const {
    data,
    bankId,
    loading,
    roleType,
    getRowKind,
    handleAction,
    exportFileName,
    selectCondition,
    actionSubmitting,
    handleAddBankRow,
    getManualSelectFunc,
  } = props;
  const screenWidth = useCurrentWidth();

  const fixedSize = useMemo(
    () => detectTopContentModeWhen2Tables(screenWidth, 0),
    [screenWidth],
  );

  const { isReadOnly } = roleType;

  const globalFilter = useMemo(
    () => ({
      reconciled: filterByReconciledMatchedTransaction().filter(
        (item) => item.id === 'UNMATCHED',
      ),
    }),
    [],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'postedDate',
        desc: false,
      },
    ],
    [],
  );

  return (
    <Row>
      <StyledTableWrapper>
        <Header>
          <Heading level={4} alignment="center">
            <Trans>Bank Transactions</Trans>
          </Heading>
          <ButtonHolder>
            <ButtonGroup bsSize="small">
              <Button onClick={handleAddBankRow} disabled={!bankId}>
                <FaPlus style={{ marginBottom: -1 }} />{' '}
                <Trans>Add bank lines</Trans>
              </Button>
            </ButtonGroup>
          </ButtonHolder>
        </Header>
        <CustomTable
          {...{
            data: data?.bankTransactions,
            loading,
            fixedSize,
            handleAction,
            loadingId: 'id',
            actionSubmitting,
            height: 600,
            getManualSelectFunc,
            hideSelection: true,
            isStaticTableHeight: true,
            getRowColor: getRowKind,
            tableId: 'bankTemplateBankLines',
            filterConfigs: bankTemplateFilters(),
            columns: bankTemplateNewColumns({
              isReadOnly,
            }),
            headerConfigs: {
              exportFileName,
            },
            selectConfigs: {
              selectCondition,
            },
            initialState: {
              sortBy,
              globalFilter,
            },
          }}
        />
      </StyledTableWrapper>
    </Row>
  );
}

BankTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  roleType: PropTypes.shape({}),
  getRowKind: PropTypes.func,
  handleAction: PropTypes.func.isRequired,
  exportFileName: PropTypes.string,
  selectCondition: PropTypes.func,
  getBankPage: PropTypes.func.isRequired,
  actionSubmitting: PropTypes.shape({}).isRequired,
  handleAddBankRow: PropTypes.func,
};

BankTemplate.defaultProps = {
  loading: true,
  roleType: { isReadOnly: true },
  getRowKind: () => {},
  exportFileName: undefined,
  selectCondition: () => {},
  handleAddBankRow: () => {},
};

export default BankTemplate;

const StyledTableWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ButtonHolder = styled.div``;

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  h4 {
    margin: 0;
  }
`;
