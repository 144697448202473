import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { MdPrivacyTip } from '@react-icons/all-files/md/MdPrivacyTip';
import { FaClipboardList } from '@react-icons/all-files/fa/FaClipboardList';

import { Sanity, ButtonIcon } from 'components';

import client from 'services/utils/sanity';
import getErrorMessage from 'services/helpers/getErrorMessage';

const icons = {
  'terms-of-service': {
    icon: (
      <ButtonIcon
        as={FaClipboardList}
        color="#6971fd"
        fontSize={25}
        marginBottom={0}
        marginRight={10}
      />
    ),
    checkbox: t`I agree to the Business Terms of Service`,
  },
  'privacy-statement': {
    icon: (
      <ButtonIcon
        as={MdPrivacyTip}
        color="#6971fd"
        fontSize={25}
        marginBottom={0}
        marginRight={10}
      />
    ),
    checkbox: t`I agree to the Privacy Policy`,
  },
};

function SanityContainer({ location, title }) {
  const [terms, setTerms] = useState([]);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const page = location.pathname.split('/')[1];

  const fetchData = () => {
    setLoading(true);

    client
      .fetch(
        `*[_type == "${page}" && title == "${i18n.locale}"] {
        title,
        body,
      }`,
      )
      .then((data) => {
        setTerms(data);
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [i18n.locale, page]);

  return (
    <Sanity
      {...{
        title,
        terms,
        agree,
        loading,
        setAgree,
        icon: icons[page].icon,
        checkbox: icons[page].checkbox,
      }}
    />
  );
}

export default SanityContainer;

SanityContainer.propTypes = {
  location: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};
