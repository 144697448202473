import { t } from '@lingui/macro';

export const columns = (payroll) => [
  {
    width: payroll ? 550 : 650,
    Header: t`Type of value`,
    accessor: 'title',
    className: '',
    disableSortBy: true,
  },
  {
    width: 310,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'value',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
