import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';

import { fromAuth } from 'store/selectors';
import { getColorMode } from 'services/helpers/getColorMode';

import GlobalStyle from './globalStyles';
import { darkTheme, lightTheme } from './themes';

function GlobalTheme({ children }) {
  const user = useSelector(fromAuth.getUser);

  return (
    <ThemeProvider
      theme={getColorMode(user?.colorMode) === 'light' ? lightTheme : darkTheme}
    >
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}

export default GlobalTheme;
