import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { TiCancel } from '@react-icons/all-files/ti/TiCancel';
import { IoMdBrush } from '@react-icons/all-files/io/IoMdBrush';
import { AiFillPlusSquare } from '@react-icons/all-files/ai/AiFillPlusSquare';
import { AiFillCheckSquare } from '@react-icons/all-files/ai/AiFillCheckSquare';

import {
  Card,
  ButtonIcon,
  CustomTableSelect,
  CustomTableWithAutoSettings,
} from 'components';

import { fromResource } from 'store/selectors';
import { getFeatureUnleashApi } from 'services/apihelpers';
import { recordFilterList, viewFinishedAccrualsOptions } from 'services/shapes';
import {
  generalTemplateColumns,
  generalTemplateAccrualColumns,
} from 'services/tableshapes';
import {
  generalTemplateAccrualsFilters,
  generalTemplateReconcileFilters,
} from 'services/tableFilterShapes';

function GeneralTemplate(props) {
  const {
    data,
    loading,
    roleType,
    withTitle,
    getRowColor,
    hideAccruals,
    selectedRows = {},
    accrualsData,
    handleAction,
    accrualsLoading,
    actionSubmitting,
    topSelectConverter,
    getRowAccrualsColor,
    clearSelectedIds,
    getManualSelectFuncAccruals,
    getManualSelectFuncReconcile,
    accrualsSelectCondition,
    accrualsSelectConverter,
    reconcileSelectCondition,
    reconcileSelectConverter,
  } = props;
  const createAccrualEnable = useSelector(
    (state) =>
      fromResource.getList(
        state,
        `${getFeatureUnleashApi}/accruals-suggestions`,
      ).status,
  );

  const accrualsSortBy = useMemo(
    () => [
      {
        id: 'estimatedRemainingMonths',
        desc: true,
      },
    ],
    [],
  );
  const reconcileSortBy = useMemo(
    () => [
      {
        id: 'sourceDocumentId',
        desc: false,
      },
    ],
    [],
  );
  const reconcileFilters = useMemo(
    () => ({
      reconciliationStatus: recordFilterList().filter(
        (item) => item.id === 'OPEN',
      ),
    }),
    [],
  );
  const accrualsFilters = useMemo(
    () => ({
      estimatedRemainingMonths: viewFinishedAccrualsOptions().filter((item) =>
        ['withoutFinishedAccruals'].includes(item.id),
      ),
    }),
    [],
  );
  let reconcileSelectButtons = [
    ...(selectedRows?.Reconcile?.length === 1 && createAccrualEnable
      ? [
          {
            id: 'create',
            icon: <ButtonIcon as={AiFillPlusSquare} marginRight={5} />,
            title: t`Create Reconciliation`,
            buttonProps: {
              size: 'sm',
              kind: 'warning',
              fill: true,
              magnify: true,
            },
          },
        ]
      : []),
    {
      id: 'partialReconcile',
      icon: <ButtonIcon as={AiFillCheckSquare} marginRight={5} />,
      title: t`Partial Reconcile`,
      getTitle: (rows) => {
        const selectedSum = +rows
          .reduce((sum, row) => sum + row.original.amount, 0)
          .toFixed(0);

        return selectedSum === 0 ? t`Reconcile` : t`Partial Reconcile`;
      },
      minSelected: 2,
      buttonProps: {
        size: 'sm',
        kind: 'info',
        fill: true,
        magnify: true,
      },
    },
    {
      id: 'approve',
      icon: <ButtonIcon as={HiCheck} marginRight={5} />,
      title: t`Approve`,
      buttonProps: {
        size: 'sm',
        kind: 'success',
        fill: true,
        magnify: true,
      },
    },
    {
      id: 'unApprove',
      icon: <ButtonIcon as={TiCancel} marginRight={5} />,
      title: t`Unapprove`,
      buttonProps: {
        size: 'sm',
        kind: 'danger',
        fill: true,
        magnify: true,
      },
    },
    {
      id: 'cancel',
      icon: <ButtonIcon as={IoMdBrush} marginRight={5} />,
      title: t`Clear`,
      clearSelected: true,
      buttonProps: {
        size: 'sm',
        kind: '',
        fill: true,
        magnify: true,
      },
    },
  ];
  let topDeviation = 0;

  if (selectedRows.Accruals?.length && selectedRows.Reconcile?.length) {
    reconcileSelectButtons = [
      {
        id: 'cancel',
        icon: <ButtonIcon as={IoMdBrush} marginRight={5} />,
        title: t`Clear`,
        clearSelected: true,
        buttonProps: {
          size: 'sm',
          kind: '',
          fill: true,
          magnify: true,
        },
      },
    ];

    const selectedAccrualsSum = selectedRows.Accruals?.reduce(
      (sum, row) => sum + row.sumRemaining,
      0,
    );
    const selectedReconcileSum = selectedRows.Reconcile?.reduce(
      (sum, row) => sum + row.amount,
      0,
    );

    topDeviation = +(selectedAccrualsSum - selectedReconcileSum).toFixed(0);
  }

  const topSelectButtons = [
    {
      id: 'topReconcile',
      icon:
        topDeviation === 0 ? (
          ''
        ) : (
          <ButtonIcon as={AiFillCheckSquare} marginRight={5} />
        ),
      title: topDeviation === 0 ? t`Reconcile` : t`Partial Reconcile`,
      minSelected: 2,
      buttonProps: {
        size: 'sm',
        kind: topDeviation === 0 ? 'success' : 'info',
        fill: true,
        magnify: true,
      },
    },
  ];

  return (
    <>
      {!!selectedRows.Accruals?.length && !!selectedRows.Reconcile?.length && (
        <SelectWrapper>
          <CustomTableSelect
            {...{
              handleAction,
              actionSubmitting,
              setSelectedRowIds: clearSelectedIds,
              tableProps: {
                selectedFlatRows: [
                  ...selectedRows.Accruals,
                  ...selectedRows.Reconcile,
                ],
              },
              selectConfigs: {
                buttons: topSelectButtons,
                converter: topSelectConverter,
              },
            }}
          />
        </SelectWrapper>
      )}
      {!hideAccruals && (
        <Card>
          <CustomTableWithAutoSettings
            {...{
              withTitle,
              handleAction,
              actionSubmitting,
              data: accrualsData || [],
              height: 600,
              getManualSelectFunc: getManualSelectFuncAccruals,
              columns: generalTemplateAccrualColumns(),
              tableId: 'Accruals',
              loading: accrualsLoading,
              minHeight: 1,
              loadingId: 'partialReconciliationId',
              tableTitle: t`Accruals`,
              getRowColor: getRowAccrualsColor,
              filterConfigs: generalTemplateAccrualsFilters(),
              headerConfigs: {
                exportFileName: t`Accruals`,
              },
              selectConfigs: {
                converter: accrualsSelectConverter,
                selectCondition: accrualsSelectCondition,
                buttons: [
                  {
                    id: 'cancel',
                    icon: <ButtonIcon as={IoMdBrush} marginRight={5} />,
                    title: t`Clear`,
                    clearSelected: true,
                    buttonProps: {
                      size: 'sm',
                      kind: '',
                      fill: true,
                      magnify: true,
                    },
                  },
                ],
              },
              initialState: {
                sortBy: accrualsSortBy,
                globalFilter: accrualsFilters,
              },
            }}
          />
        </Card>
      )}
      <Card>
        <CustomTableWithAutoSettings
          {...{
            data,
            loading,
            withTitle,
            getRowColor,
            handleAction,
            actionSubmitting,
            height: 600,
            getManualSelectFunc: getManualSelectFuncReconcile,
            columns: generalTemplateColumns(roleType.isReadOnly),
            tableId: 'Reconcile',
            loadingId: 'recordId',
            tableTitle: t`Reconcile`,
            filterConfigs: generalTemplateReconcileFilters(),
            headerConfigs: {
              exportFileName: t`Reconcile`,
            },
            selectConfigs: {
              buttons: reconcileSelectButtons,
              converter: reconcileSelectConverter,
              selectCondition: reconcileSelectCondition,
            },
            initialState: {
              sortBy: reconcileSortBy,
              globalFilter: reconcileFilters,
            },
          }}
        />
      </Card>
    </>
  );
}

export default GeneralTemplate;

const SelectWrapper = styled.div`
  margin-top: 15px;
`;
