import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { useDispatch } from 'react-redux';

import { CMAUserAddEditModal } from 'components';

import { createCMAUserApi } from 'services/apihelpers';
import { resourceCreateRequest } from 'store/actions';
import getErrorMessage from '../services/helpers/getErrorMessage';

function CMAUserAddEditModalContainer(props) {
  const [cmaUserCreating, setCmaUserCreating] = useState(false);
  const dispatch = useDispatch();
  const { closeModal } = props;

  const handleCMAUserSubmit = async (values) => {
    setCmaUserCreating(true);

    try {
      await dispatch(resourceCreateRequest(createCMAUserApi, values));
      closeModal(false, true);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    setCmaUserCreating(false);
  };

  return (
    <CMAUserAddEditModal
      onSubmit={handleCMAUserSubmit}
      closeModal={closeModal}
      cmaUserCreating={cmaUserCreating}
    />
  );
}

CMAUserAddEditModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CMAUserAddEditModalContainer;
