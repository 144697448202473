import { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { vatOutgoingBalanceFormatter } from 'services/dataFormatters/vat';
import {
  getVATSettlementsTotalApi,
  getVATSettlementsDeviationApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const useVATOutgoingBalanceData = (props) => {
  const { activePeriods, accountingYear, companyId } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getVATSettlementsTotalApi),
  );
  const dataDeviation = useSelector((state) =>
    fromResource.getList(state, getVATSettlementsDeviationApi),
  );

  const term = Math.ceil(activePeriods / 2);

  const { modalData, rows } = useMemo(
    () => vatOutgoingBalanceFormatter(data, dataDeviation, term),
    [data, dataDeviation, term],
  );

  const fetchDeviationRows = async () => {
    await dispatch(
      resourceListReadRequest(getVATSettlementsDeviationApi, {
        term,
        companyId,
        year: accountingYear,
      }),
    );
  };

  const fetchRows = async () => {
    await dispatch(
      resourceListReadRequest(getVATSettlementsTotalApi, {
        term,
        companyId,
        year: accountingYear,
      }),
    );
  };

  const fetchDeviations = async () => {
    setLoading(() => true);
    try {
      await fetchDeviationRows();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await fetchRows();
      await fetchDeviationRows();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [term, companyId, accountingYear]);

  return { rows, loading, modalData, fetchDeviations, fetchData };
};

export default useVATOutgoingBalanceData;
