import { t } from '@lingui/macro';

const columns = () => [
  {
    id: 1,
    label: t`Transaction ID`,
    value: 'id',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 2,
    label: t`Description`,
    value: 'description',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 3,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default columns;
