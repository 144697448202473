import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { Legend } from 'components';

export function LegendList(props) {
  const { collection } = props;

  const renderLegendItem = (item) => <Legend key={item.title} {...item} />;

  return <LegendHolder>{collection.map(renderLegendItem)}</LegendHolder>;
}

LegendList.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

LegendList.defaultProps = {
  collection: [],
};

export default LegendList;

const LegendHolder = styled.div`
  top: 87px;
  right: -25px;
  z-index: 1;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
`;
