import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LoginNavbar } from 'components';

function handleSelect(eventKey) {
  switch (eventKey) {
    case 1.1:
      break;
    case 1.2:
      break;
    case 1.3:
      break;
    case 1.4:
      break;
    case 1.5:
      break;

    default:
      break;
  }
}
class LoginNavbarContainer extends Component {
  static propTypes = {
    kind: PropTypes.oneOf(['default', 'inverse']),
    children: PropTypes.node,
  };

  static defaultProps = {
    kind: 'inverse',
    children: undefined,
  };

  state = {
    expanded: true,
  };

  handleMenuToggle = () => {
    this.setState((prevState) => {
      document.documentElement.classList.toggle('nav-open', prevState.expanded);
      return { expanded: !prevState.expanded };
    });
  };

  render() {
    const { kind, children, noLogin } = this.props;
    const { expanded } = this.state;

    return (
      <LoginNavbar
        handleSelect={(eventKey) => handleSelect(eventKey)}
        handleMenuToggle={() => this.handleMenuToggle()}
        expanded={expanded}
        {...{ kind, noLogin, children }}
      />
    );
  }
}

export default LoginNavbarContainer;
