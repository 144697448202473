import React, { Component } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TableHeader from 'containers/TableHeader';
import { MapTransactionIdModal } from 'components';

import { fromCompany, fromAuth } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getGeneralLedgerDataApi } from 'services/apihelpers';
import { mapTransactionIdColumns } from 'services/tableshapes';

class MapTransactionIdModalContainer extends Component {
  static propTypes = {
    /** @type {shape} company object */
    company: PropTypes.shape({}).isRequired,
    /** @type {func} toggleMapTransactionsIdModal */
    toggleMapTransactionsIdModal: PropTypes.func.isRequired,
    /** @type {shape} user */
    user: PropTypes.shape({
      /** @type {[string, number]} entries */
      entries: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired,
    /** @type {func} getSelectedRows */
    getSelectedRows: PropTypes.func.isRequired,
    /** @type {shape} accountDetailsList */
    response: PropTypes.shape({}),
    account: PropTypes.number,
    assetModalSelectedRows: PropTypes.arrayOf(PropTypes.shape({})),
    payload: PropTypes.shape({}),
    modalAction: PropTypes.bool,
    rowId: PropTypes.number,
    handleIconGroup: PropTypes.func.isRequired,
    getGeneralLedgerData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    assetModalSelectedRows: [],
    response: [],
    account: undefined,
    payload: undefined,
    modalAction: false,
    rowId: undefined,
  };

  state = {
    status: false,
    search: {},
    selectedRows: !isEmpty(this.props.assetModalSelectedRows)
      ? this.props.assetModalSelectedRows
      : [],
  };

  componentDidMount() {
    this.getLineLevelDetails(this.getSearch());
  }

  getSearch = (props) => {
    const { user } = props || this.props;

    return {
      page: this.state.search.page || 1,
      query: this.state.search.query || '',
      sortBy: this.state.search.sortBy || 'Asc',
      entries: this.state.search.entries || user.entries || 10,
      sortByField: this.state.search.sortByField || 'accountID',
    };
  };

  getLineLevelDetails = async (search) => {
    const { company, payload, account, modalAction, getGeneralLedgerData } =
      this.props;

    const query = {
      ...search,
      year: company.currentAccountingYear,
      account: modalAction ? payload.assetCategory.accountId : account,
      toPeriod: 0,
      companyId: company.currentCompanySID,
      fromPeriod: 0,
      searchText: search.query,
    };

    const response = await getGeneralLedgerData(query);

    if (response) {
      this.setState({ accountDetailsList: response });
    }
  };

  setSelectedRows = () => {
    const { getSelectedRows, handleIconGroup, rowId } = this.props;
    const { selectedRows } = this.state;

    getSelectedRows(selectedRows, rowId);
    this.setState({ status: true });
    handleIconGroup(6);
  };

  handleFilterSubmit = (event) => {
    event.preventDefault();

    this.getLineLevelDetails(this.getSearch());
  };

  handleFilterChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    const search = this.getSearch();

    if (search[name].value !== value) {
      const newSearch = {
        ...search,
        page: 1,
        [name]: value,
      };
      if (['entries', 'query'].includes(name) === false) {
        return false;
      }

      this.setState({ search: newSearch });
    }
    return '';
  };

  handleChange = (event, item) => {
    const { selectedRows } = this.state;
    const { target } = event;

    if (target.type === 'checkbox') {
      const selectedRowsItems = new Map(selectedRows);
      if (selectedRowsItems.has(item.lineSID)) {
        selectedRowsItems.delete(item.lineSID);
      } else {
        selectedRowsItems.set(item.lineSID, { ...item });
      }
      this.setState({
        selectedRows: Array.from(selectedRowsItems),
      });
    }
  };

  handlePageChange = (page) => {
    const search = this.getSearch();

    if (search.page !== page) {
      const newSearch = {
        ...search,
        page,
      };

      this.setState({ search: newSearch });
      this.getLineLevelDetails(newSearch);
    }
  };

  render() {
    const search = this.getSearch();
    const { selectedRows, accountDetailsList, status } = this.state;
    const {
      user,
      company,
      payload,
      modalAction,
      handleIconGroup,
      assetModalSelectedRows,
      toggleMapTransactionsIdModal,
    } = this.props;

    return (
      <MapTransactionIdModal
        data={accountDetailsList}
        header={<TableHeader columns={mapTransactionIdColumns} />}
        handleChange={this.handleChange}
        setSelectedRows={this.setSelectedRows}
        handlePageChange={this.handlePageChange}
        handleFilterSubmit={this.handleFilterSubmit}
        handleFilterChange={this.handleFilterChange}
        {...{
          user,
          search,
          status,
          company,
          payload,
          modalAction,
          selectedRows,
          handleIconGroup,
          assetModalSelectedRows,
          toggleMapTransactionsIdModal,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralLedgerData: (query) =>
    dispatch(
      resourceListReadRequest(getGeneralLedgerDataApi, {
        ...query,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapTransactionIdModalContainer);
