import { push } from 'redux-first-history';
import { needsLogin } from '../root/actions';

const middleware = (store) => (next) => (action) => {
  const { payload } = action;

  if (
    payload?.response?.status === 400 &&
    payload?.headers?.['response-message']?.includes('Not allowed')
  ) {
    return store.dispatch(push('/select-company'));
  }

  if (payload?.response?.status === 401) {
    return store.dispatch(needsLogin());
  }

  return next(action);
};

export default middleware;
