import React from 'react';
import PropTypes from 'prop-types';

import logo from 'assets/images/logo.svg';
import logoMini from 'assets/images/logoMini.svg';
import logoWhite from 'assets/images/logoWhite.svg';
import logoChristmas from 'assets/images/logoWhite_Christmas.svg';
import logoMiniWhite from 'assets/images/logoMiniWhite.svg';
import logoChristmasMini from 'assets/images/logoMiniWhite_Christmas.svg';
import ImgWrapper from './index.style';

function Logo(props) {
  const { mini, white, isChristmas } = props;

  let src;
  if (isChristmas) {
    src = mini ? logoChristmasMini : logoChristmas;
  } else if (white) {
    src = mini ? logoMiniWhite : logoWhite;
  } else {
    src = mini ? logoMini : logo;
  }

  return <ImgWrapper alt="Logo" {...props} src={src} />;
}

Logo.propTypes = {
  mini: PropTypes.bool,
  white: PropTypes.bool,
  grayscale: PropTypes.bool,
};

Logo.defaultProps = {
  mini: false,
  white: false,
  grayscale: undefined,
};

export default Logo;
