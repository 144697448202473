import { t } from '@lingui/macro';

export const associatedCompanyGroupsFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['groupId', 'name'],
  },
];

export const availableUsersCompanyDetailedFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['username', 'name', 'role'],
  },
];

export default {
  associatedCompanyGroupsFilters,
  availableUsersCompanyDetailedFilters,
};
