import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal, ButtonGroup } from 'react-bootstrap';

import {
  Field,
  Button,
  Spinner,
  Heading,
  ButtonIcon,
  ReactFormField,
  PeriodFilterButtons,
  CustomTableWithAutoSettings,
} from 'components';

import { altinnConfigModalColumns } from 'services/tableshapes';

import './style.css';
import { DESKTOP } from 'constants/mediaSizes';

function AltinnConfigModal(props) {
  const {
    mode,
    error,
    loading,
    company,
    onSubmit,
    bimonthly,
    toggleWindow,
    altinnSignIn,
    initialState,
    fetchSuccess,
    handleAction,
    activePeriods,
    initialResponse,
    sessionValidate,
    actionSubmitting,
    periodSelectPerRow,
    handlePeriodChange,
    altinnFetchDetails,
    authenticateResponse,
    altinnUserCredentials,
    altinnUpdatedDataList,
    handlePeriodSelectPerRow,
  } = props;

  const {
    Form,
    reset,
    handleSubmit,
    meta: { canSubmit },
  } = useForm({ onSubmit });

  const disabled = !canSubmit;

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  const showPeriodSelectPerRow = mode !== 'VAT' && periodSelectPerRow;

  const data = useMemo(
    () =>
      Array.isArray(altinnUpdatedDataList)
        ? altinnUpdatedDataList.map((row) => ({ ...row, periodSelectPerRow }))
        : [],
    [JSON.stringify(altinnUpdatedDataList), periodSelectPerRow],
  );

  const table = (
    <CustomTableWithAutoSettings
      {...{
        data,
        loading,
        isModal: true,
        hideFilters: true,
        handleAction,
        actionSubmitting,
        columns: altinnConfigModalColumns(showPeriodSelectPerRow),
        tableId: 'altinnConfigModal',
        loadingId: 'period',
        headerConfigs: {
          exportFileName: t`Altinn Config Update`,
        },
      }}
    />
  );

  const periodSelectors = (
    <SelectorsHolder>
      {periodSelectPerRow ? (
        <div />
      ) : (
        <PeriodButtonsContainer>
          <PeriodFilterButtons
            size="sm"
            periodType={bimonthly ? 'biMonthly' : 'monthly'}
            activePeriods={Array.from(Array(+activePeriods).keys())}
            handleFilterChange={handlePeriodChange}
          />
          <Year level={5}>{`- ${company.currentAccountingYear}`}</Year>
        </PeriodButtonsContainer>
      )}
      <Field
        noBorder
        id="periodSelectPerRow"
        type="checkbox"
        size="small"
        name="periodSelectPerRow"
        label={t`Single period select`}
        onChange={handlePeriodSelectPerRow}
      />
    </SelectorsHolder>
  );

  return (
    <Modal show={toggleWindow} onEscapeKeyDown={() => altinnSignIn(1)}>
      <Form>
        <Modal.Header>
          <Heading level={3}>
            <Trans>Altinn Login</Trans>
          </Heading>
        </Modal.Header>
        <Modal.Body>
          {company.altinnUsername === null && (
            <div>
              <h4 style={{ textAlign: 'center' }}>
                <Trans>
                  Please setup Altinn credentials through{' '}
                  <strong>Integrations</strong>
                </Trans>
              </h4>
            </div>
          )}
          {company.altinnUsername !== null && initialState && (
            <div>
              <ReactFormField
                autoFocus
                name="ssn"
                type="text"
                size="small"
                label={t`Social Security Number:`}
                disabled={loading}
              />
              <ReactFormField
                size="small"
                type="password"
                name="altinnUserPassword"
                label={t`Altinn User Password:`}
                disabled={loading}
                onKeyDown={handleOnKeyDown}
                autoComplete="new-password"
              />
              {(mode === 'company' || mode === 'VAT') && (
                <>
                  {mode === 'company' && periodSelectors}
                  {table}
                </>
              )}
            </div>
          )}
          {initialState === false &&
            altinnFetchDetails === false &&
            fetchSuccess === false && (
              <div>
                {sessionValidate === false &&
                  (!error ||
                    (authenticateResponse &&
                      +authenticateResponse.errorId === 989)) && (
                    <ReactFormField
                      autoFocus
                      name="otp"
                      type="text"
                      size="small"
                      label={t`SMS Code:`}
                      disabled={loading}
                      onKeyDown={handleOnKeyDown}
                      autoComplete="one-time-code"
                    />
                  )}
                {(error ||
                  (authenticateResponse && authenticateResponse?.errorId)) && (
                  <Heading level={6} alignment="center" background="danger">
                    {(authenticateResponse && authenticateResponse?.message) ||
                      error}
                  </Heading>
                )}
                {sessionValidate === true &&
                  (mode === 'company' || mode === 'VAT') && (
                    <>
                      {mode === 'company' && periodSelectors}
                      {table}
                    </>
                  )}
                {sessionValidate === true && mode === 'organization' && (
                  <div>
                    <Heading level={5}>
                      <Trans>
                        Your Credentials Has Been Saved. Refetch A07 Records.
                      </Trans>
                    </Heading>
                  </div>
                )}
              </div>
            )}
          {initialState === false &&
            altinnFetchDetails === true &&
            fetchSuccess === false && (
              <div>
                <h4 style={{ textAlign: 'center' }}>
                  {/* eslint-disable-next-line */}
                  <Trans>
                    Waiting for a response from Altinn... This can take a couple
                    of minutes
                  </Trans>
                </h4>
              </div>
            )}
          {initialState === false &&
            altinnFetchDetails === false &&
            fetchSuccess === true &&
            authenticateResponse !== undefined &&
            authenticateResponse?.status !== null &&
            authenticateResponse?.status !== 'Fail' && (
              <div>
                <h4 style={{ textAlign: 'center' }}>
                  <Trans>Fetching completed.</Trans>
                </h4>
              </div>
            )}
          {initialState === false &&
            authenticateResponse !== undefined &&
            authenticateResponse?.status !== null &&
            authenticateResponse?.status === 'Fail' && (
              <div>
                <h4 style={{ textAlign: 'center' }}>
                  <Trans>Fetching Failed.</Trans>
                </h4>
              </div>
            )}
          <br />
          {initialState === true &&
          initialResponse &&
          initialResponse.message ? (
            <pre
              className={
                initialState === true &&
                initialResponse &&
                initialResponse.status === 'InvalidCredentials'
                  ? 'bg-danger'
                  : 'bg-success'
              }
            >
              {initialResponse.message}
            </pre>
          ) : (
            ''
          )}

          {initialState === false &&
          authenticateResponse &&
          authenticateResponse?.message ? (
            <pre
              className={
                authenticateResponse?.status === 'Fail'
                  ? 'bg-danger'
                  : 'bg-success'
              }
            >
              {authenticateResponse?.message}
            </pre>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              wide
              kind="info"
              type="submit"
              disabled={
                disabled ||
                altinnUserCredentials ||
                altinnFetchDetails ||
                company.altinnUsername === null ||
                (altinnFetchDetails === false && fetchSuccess === true)
              }
            >
              {altinnUserCredentials || altinnFetchDetails ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Fetch Altinn Details</Trans>
              )}
            </Button>

            <Button
              fill
              magnify
              kind="danger"
              onClick={() => {
                reset();
                altinnSignIn(1);
              }}
            >
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
AltinnConfigModal.propTypes = {
  initialResponse: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  authenticateResponse: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  altinnUserCredentials: PropTypes.bool,
  altinnFetchDetails: PropTypes.bool,
  sessionValidate: PropTypes.bool,
  error: PropTypes.string,
  fetchSuccess: PropTypes.bool,
  initialState: PropTypes.bool,
  toggleWindow: PropTypes.bool,
  altinnUpdatedDataList: PropTypes.shape({}).isRequired,
  mode: PropTypes.string,
  altinnSignIn: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bimonthly: PropTypes.bool,
  handleAction: PropTypes.func,
  activePeriods: PropTypes.number,
  actionSubmitting: PropTypes.shape({}),
  periodSelectPerRow: PropTypes.bool,
  handlePeriodChange: PropTypes.func.isRequired,
  handlePeriodSelectPerRow: PropTypes.func.isRequired,
};

AltinnConfigModal.defaultProps = {
  mode: 'company',
  error: '',
  handleAction: () => {},
  bimonthly: false,
  toggleWindow: false,
  fetchSuccess: false,
  initialState: true,
  actionSubmitting: {},
  activePeriods: 12,
  sessionValidate: false,
  periodSelectPerRow: false,
  altinnFetchDetails: false,
  altinnUserCredentials: false,
};

export default AltinnConfigModal;

const SelectorsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Year = styled((props) => <Heading {...props} />)`
  margin: 0 0 0 10px;
  font-weight: 400;
`;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 650px;
    }
  }
`;

const PeriodButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
