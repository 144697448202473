import React from 'react';

import GroupUserDetailed from 'containers/GroupUserDetailed';

/**
 * Group User Detailed Page component
 */
function GroupUserDetailedPage(props) {
  return <GroupUserDetailed {...props} />;
}

export default GroupUserDetailedPage;
