import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import { AlgoliaModal } from 'components';

import { fromCompany } from 'store/selectors';
import { useOnClickOutside } from 'services/helpers';

export function AlgoliaModalContainer(props) {
  const { show, onClose, roleType } = props;
  const containerRef = useRef();
  const [selected, setSelected] = useState(1);
  const company = useSelector((state) => fromCompany.getCompany(state));
  let content = null;

  const getHitsNode = (ref) =>
    ref.current.getElementsByClassName('ais-Hits')[0];

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      } else if (e.keyCode === 38) {
        if (selected !== 1) {
          const hitsNode = getHitsNode(containerRef);

          if (hitsNode) {
            let newScrollPosition =
              (selected - 1) * 52 - hitsNode.getBoundingClientRect().height;

            if (newScrollPosition < 0) {
              newScrollPosition = 0;
            }

            hitsNode.scrollTo({
              top: newScrollPosition,
              behavior: 'smooth',
            });

            setSelected(selected - 1);
          }
        }
      } else if (e.keyCode === 40) {
        const hitsNode = getHitsNode(containerRef);

        if (hitsNode) {
          const children = hitsNode.children?.[0]?.children;

          if (selected + 1 <= children.length) {
            let newScrollPosition =
              (selected + 1) * 52 - hitsNode.getBoundingClientRect().height;

            if (newScrollPosition < 0) {
              newScrollPosition = 0;
            }

            hitsNode.scrollTo({
              top: newScrollPosition,
              behavior: 'smooth',
            });

            setSelected(selected + 1);
          }
        }
      }
    },
    [onClose, selected],
  );
  const getFilters = () => {
    const filters = [];

    if (!company?.companySettings?.vatEnabled) {
      filters.push('NOT _tags:vatEnabled');
    }

    if (!company?.companySettings?.payrollEnabled) {
      filters.push('NOT _tags:payrollEnabled');
    }

    if (!company?.companySettings?.assetsEnabled) {
      filters.push('NOT _tags:assetsEnabled');
    }

    if (!roleType.isAdmin) {
      filters.push('NOT _tags:isAdmin');
    }

    return filters.join(' AND ');
  };

  useEffect(() => {
    const overflow = show ? 'hidden' : 'visible';
    const homeHolder = document.getElementById('app');

    if (homeHolder) {
      homeHolder.style.overflow = overflow;

      if (overflow === 'hidden') {
        window.scrollPosition = window?.scrollY || 0;

        homeHolder.style.cssText = `
          top: -${window.scrollPosition}px;
          left: 0px;
          right: 0px;
          position: fixed;
       `;
      } else {
        homeHolder.style.cssText = '';
        window.scroll(0, window.scrollPosition || 0);
        window.scrollPosition = 0;
      }
    }
  }, [show]);
  useOnClickOutside(containerRef, onClose);

  if (show) {
    content = createPortal(
      <AlgoliaModal
        onClose={onClose}
        filters={getFilters()}
        selected={selected}
        containerRef={containerRef}
        handleKeyDown={handleKeyDown}
      />,
      document.getElementById('portal'),
    );
  }

  return content;
}

export default AlgoliaModalContainer;

AlgoliaModalContainer.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  roleType: PropTypes.shape({}),
};

AlgoliaModalContainer.defaultProps = {
  show: false,
  onClose: () => {},
  roleType: {},
};
