import React from 'react';
import AccountDetailsTable from 'containers/AccountDetailsTable';

/**
 * Account Details Report Page component
 */
function AccountDetailsReportPage(props) {
  return <AccountDetailsTable {...props} />;
}

export default AccountDetailsReportPage;
