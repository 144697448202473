import React from 'react';

import UserDetailsTable from 'containers/UserDetailsTable';

/**
 * Admin User Settings component
 */
function AdminUserSettingsPage(props) {
  return <UserDetailsTable {...props} />;
}

export default AdminUserSettingsPage;
