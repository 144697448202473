import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { ButtonGroup } from 'react-bootstrap';

import {
  Card,
  Tabs,
  Field,
  Button,
  Heading,
  Spinner,
  TableIconGroup,
  GenerateReportButton,
  PeriodFilterButtons,
} from 'components';

import CardPDFTitle from 'containers/CardPDFTitle';
import PayrollWithheldTax from 'containers/PayrollWithheldTax';
import PayrollFinancialTax from 'containers/PayrollFinancialTax';
import PayrollSocialSecurity from 'containers/PayrollSocialSecurity';
import PayrollReportedSalary from 'containers/PayrollReportedSalary';
import PayrollReportedSalaryDetails from 'containers/PayrollReportedSalaryDetails'; // eslint-disable-line
import PayrollReconciliationVacationPayTable from 'containers/PayrollReconciliationVacationPayTable'; // eslint-disable-line

import { vatReportIconShape } from 'services/TableIconGroupShapes';
import { toReconciliationSettings } from '../../../services/routehelpers/index';

function PayrollReconciliationTable(props) {
  const {
    tabs,
    term,
    files,
    search,
    company,
    Preview,
    disabled,
    roleType,
    a07Loading,
    generatePDF,
    PRandOBdata,
    toggleModal,
    latestReport,
    pdfGenerating,
    accountingYear,
    handleRowClick,
    vacationPayData,
    handleTabSelect,
    payrollApproved,
    withheldTaxData,
    handleSSRowClick,
    reportedSalaryData,
    dataSocialSecurity,
    handlePeriodChange,
    loadingAltinnFetch,
    handleCommentChange,
    handleApprovedChange,
    handleCheckboxChange,
    payrollApprovedLoading,
    handleAccumulatedChange,
    reportedSalaryDetailsData,
    handleDownloadLatestReport,
    downloadingLatest,
  } = props;

  const { bimonthly, subTabEntry, activePeriods, accumulated, onlyNumbers } =
    search;

  const approve = t`Approve`;
  const unApprove = t`Unapprove`;
  const bimonthlyLabel = t`Bi-Monthly`;
  const chooseTermLabel = t`Choose term`;
  const choosePeriodLabel = t`Choose period`;

  const handleIconGroup = (id) => {
    switch (id) {
      case 1:
        toggleModal('dialog');
        break;
      case 2:
        toggleModal('file_upload');
        break;
      default:
        break;
    }
  };
  let contentReports = '';

  if (subTabEntry === 'report') {
    contentReports = (
      <PayrollReportedSalary
        {...{
          search,
          activePeriods,
          handleRowClick,
          payrollApproved,
          reportedSalaryData,
          handleCommentChange,
        }}
      />
    );
  }

  if (subTabEntry === 'salary-details') {
    contentReports = (
      <PayrollReportedSalaryDetails
        {...{
          search,
          company,
          roleType,
          accountingYear,
          payrollApproved,
          payrollApprovedLoading,
          reportedSalaryDetailsData,
        }}
      />
    );
  }

  if (subTabEntry === 'SSTP') {
    contentReports = (
      <PayrollSocialSecurity
        {...{
          roleType,
          PRandOBdata,
          toggleModal,
          handleSSRowClick,
          dataSocialSecurity,
        }}
      />
    );
  }

  if (subTabEntry === 'FTP') {
    contentReports = (
      <PayrollFinancialTax
        {...{
          roleType,
          toggleModal,
          PRandOBdata,
        }}
      />
    );
  }

  if (subTabEntry === 'WTP') {
    contentReports = (
      <PayrollWithheldTax
        {...{
          roleType,
          toggleModal,
          PRandOBdata,
          withheldTaxData,
        }}
      />
    );
  }

  if (subTabEntry === 'VP') {
    contentReports = (
      <PayrollReconciliationVacationPayTable
        {...{
          term,
          roleType,
          toggleModal,
          activePeriods,
          vacationPayData,
          payrollApproved,
        }}
      />
    );
  }

  const renderTooltip = (items) => {
    const slicedArray = items.slice(0, 5);

    return slicedArray?.map((item, i) =>
      i < slicedArray.length - 1 ? (
        <React.Fragment key={i}>
          {item} <br key={i} />
        </React.Fragment>
      ) : (
        item
      ),
    );
  };

  const additionalData = useMemo(() => {
    if (Array.isArray(files) && files?.length) {
      return (
        files[0]?.fileNames?.length && {
          uploadFiles: {
            badge: files[0].fileNames.length,
            tooltip: renderTooltip(files[0].fileNames),
          },
        }
      );
    }

    return null;
  }, [JSON.stringify(files)]);

  return (
    <Card
      header={
        <Options>
          <Heading level={3} className="card-title">
            <Trans>Payroll Reconciliation</Trans>
          </Heading>
          {Preview()}
        </Options>
      }
    >
      <ButtonsHolder>
        <LeftButtons>
          <TableIconGroup
            {...{
              roleType,
              handleIconGroup,
              additionalData,
              shape: vatReportIconShape,
            }}
          />
        </LeftButtons>
        <ButtonGroup bsSize="small">
          <Button
            data-testid="create-manual-payroll"
            onClick={() => toggleModal('create-manual')}
          >
            <Trans>Create manual Report</Trans>
          </Button>
          <Button
            disabled={loadingAltinnFetch}
            data-testid="fetch-payroll-reports"
            onClick={() => toggleModal('altinn')}
          >
            {loadingAltinnFetch ? (
              <Spinner button />
            ) : (
              <Trans>Fetch Payroll Reports</Trans>
            )}
          </Button>
          <Button
            disabled={a07Loading}
            data-testid="show-a07-report"
            onClick={() => toggleModal('report')}
          >
            <Trans>Show A07 Report</Trans>
          </Button>
          <GenerateReportButton
            disabled={disabled}
            pdfGenerating={pdfGenerating}
            generatePDF={generatePDF}
            skipDownload={false}
            saveReport={false}
          />
        </ButtonGroup>
      </ButtonsHolder>
      <ButtonsHolder>
        <TermHolder>
          {bimonthly ? chooseTermLabel : choosePeriodLabel}
          <PeriodContainer>
            <PeriodFilterButtons
              size="small"
              periodType={bimonthly ? 'biMonthly' : 'monthly'}
              singlePeriod={!accumulated}
              activePeriods={Array.from(Array(+activePeriods).keys())}
              handleFilterChange={handlePeriodChange}
            />
            <Year level={5}>{`- ${accountingYear}`}</Year>
          </PeriodContainer>
        </TermHolder>
        <Options>
          {subTabEntry === 'report' && (
            <div>
              <Field
                horizontal
                id="manualRows"
                type="checkbox"
                name="manualRows"
                disabled={reportedSalaryData.loading}
                label={t`Show manual input`}
                checked={reportedSalaryData.manual}
                onChange={reportedSalaryData.updateManual}
              />
              <Field
                horizontal
                id="onlyNumbers"
                type="checkbox"
                name="onlyNumbers"
                label={t`Show only rows with numbers`}
                checked={onlyNumbers}
                onChange={(e) => handleCheckboxChange(e, 'onlyNumbers')}
              />
            </div>
          )}
          <div>
            {subTabEntry !== 'VP' && (
              <Field
                horizontal
                id="bimonthly"
                type="checkbox"
                name="bimonthly"
                label={bimonthlyLabel}
                checked={bimonthly}
                onChange={(e) => handleCheckboxChange(e, 'bimonthly')}
              />
            )}
            {subTabEntry === 'SSTP' && (
              <Field
                horizontal
                id="showAccumulated"
                type="checkbox"
                name="showAccumulated"
                label={t`Show accumulated YTD Period`}
                checked={accumulated}
                onChange={handleAccumulatedChange}
              />
            )}
            {(subTabEntry === 'report' || subTabEntry === 'salary-details') && (
              <Field
                horizontal
                id="showSingle"
                type="checkbox"
                name="showSingle"
                label={t`Show single period`}
                checked={!accumulated}
                onChange={handleAccumulatedChange}
              />
            )}
          </div>
          {!roleType.isReadOnly && (
            <Button
              kind={payrollApproved ? 'danger' : 'success'}
              onClick={handleApprovedChange}
              disabled={payrollApprovedLoading}
              data-testid={
                payrollApproved
                  ? 'payroll-unapprove-btn'
                  : 'payroll-approve-btn'
              }
            >
              {payrollApprovedLoading && <Spinner size="md" />}
              {!payrollApprovedLoading && payrollApproved ? unApprove : approve}
            </Button>
          )}
          <GenerateReportButton
            variant="icon"
            options
            withVersions
            versionsMeta={{ reportType: 'PAYROLL' }}
            period={activePeriods}
            bimonthly={bimonthly}
            disabled={disabled}
            pdfGenerating={pdfGenerating || downloadingLatest}
            pdfDownloading={disabled}
            noGenerateButton={typeof latestReport?.PAYROLL !== 'undefined'}
            generatePDF={() =>
              generatePDF({ skipDownload: true, saveReport: true })
            }
            disableGenerate={!payrollApproved}
            downloadLast={() => handleDownloadLatestReport(activePeriods)}
            currentVersion={latestReport}
          />
        </Options>
      </ButtonsHolder>
      <Tabs
        tabs={tabs}
        onSelect={handleTabSelect}
        activeKey={subTabEntry}
        config={toReconciliationSettings({
          tabEntry: 'payroll-settings',
        })}
      />
      <ContentHolder id="pdfToPrint">
        <CardPDFTitle
          defaultNoTitle
          title={
            <Trans>
              {company.currentCompanyName}: Term - {term}
            </Trans>
          }
        />
        {contentReports}
      </ContentHolder>
    </Card>
  );
}

export default PayrollReconciliationTable;

const ContentHolder = styled.div`
  margin: 10px 0;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ButtonsHolder = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const TermHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftButtons = styled.div``;

const PeriodContainer = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
`;

const Year = styled((props) => <Heading {...props} />)`
  margin: 0 0 0 10px;
  font-weight: 400;
`;
