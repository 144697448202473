import React from 'react';
import cx from 'classnames';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { formatNumber } from 'services/helpers';
import { TbUnlink } from '@react-icons/all-files/tb/TbUnlink';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { TWO_K, FOUR_K, LARGE_SCREENS } from 'constants/mediaSizes';

export function CustomTableTotal(props) {
  const {
    grid,
    getRef,
    tableId,
    isModal,
    totalName,
    handleAction,
    predefinedTotal,
    withoutMainTotal,
    totalColumnIndex,
    calculateCellWidth,
    responsiveRowWidth,
    totalTitleClassName,
    additionalTotalRows,
    tableProps: { flatRows, headerGroups, selectedFlatRows },
  } = props;

  const calculateTotal = (column, rows) =>
    rows.reduce((sum, item) => {
      if (typeof column.cell.converter === 'function') {
        return (
          sum + Number(column.cell.converter(item.original, item.original))
        );
      }
      return sum + Number(item.original[column.id] || 0);
    }, 0);

  const totalRow = (
    headerGroupProps,
    headerGroup,
    customTotalRow,
    rowIndex,
  ) => (
    <Holder
      key={`${headerGroupProps.key}-${tableId}`}
      {...headerGroupProps}
      ref={getRef}
      grid={grid?.id}
      isModal={isModal}
      className={cx('tr', {
        withoutBorder: !!customTotalRow,
        withBorder: rowIndex === 0 && withoutMainTotal,
      })}
    >
      {headerGroup.headers.map((column, index) => {
        const cellWidth = calculateCellWidth(column.width);
        const headerProps = {
          ...column.getHeaderProps({
            ...column.getSortByToggleProps(),
            style: { minWidth: cellWidth, width: cellWidth },
          }),
          onClick: undefined, // To disable sorting by click on cell
        };
        let content = '';
        let className = '';

        const withAction = customTotalRow?.withAction?.(
          customTotalRow?.[column?.id],
        );
        const tooltip = customTotalRow?.[`${column.id}_tooltip`];

        if (index === totalColumnIndex) {
          content = customTotalRow?.[column?.id] || totalName || t`Total`;

          if (column.withSelectedRowsTotal && selectedFlatRows?.length) {
            content = t`Subtotal`;
          }

          if (totalTitleClassName) {
            className = totalTitleClassName;
          }
        } else if (column.withSelectedRowsTotal && selectedFlatRows?.length) {
          const formattedVal = formatNumber(
            calculateTotal(column, selectedFlatRows),
            column?.cell?.rounded,
          );

          content = formattedVal.formatted;
          className = formattedVal.className;
        } else if (column.withTotal) {
          const formattedVal = formatNumber(
            calculateTotal(column, flatRows),
            column?.cell?.rounded,
          );

          content = formattedVal.formatted;
          className = formattedVal.className;
        } else if (column.predefinedTotal) {
          const total = customTotalRow
            ? customTotalRow?.[column?.id]
            : predefinedTotal?.[column.predefinedTotal] || 0;
          const formattedVal = formatNumber(total, column?.cell?.rounded);

          content = formattedVal.formatted;
          className = formattedVal.className;
        }

        return (
          <div
            key={`${headerProps.key}-${tableId}`}
            className={cx('th', className, column.className)}
            {...headerProps}
          >
            {tooltip ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
              >
                <div>{content}</div>
              </OverlayTrigger>
            ) : (
              content
            )}
            {withAction && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">{customTotalRow?.tooltip}</Tooltip>
                }
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={() => handleAction(withAction, column.id)} />
              </OverlayTrigger>
            )}
          </div>
        );
      })}
    </Holder>
  );

  return headerGroups.map((headerGroup, idx) => {
    const headerGroupProps = headerGroup.getHeaderGroupProps({
      style: { width: responsiveRowWidth },
    });

    return (
      <React.Fragment key={idx}>
        {/* Main total row */}
        {!withoutMainTotal && totalRow(headerGroupProps, headerGroup)}

        {/* Additional total rows */}
        {Array.isArray(additionalTotalRows) &&
          additionalTotalRows?.length > 0 &&
          additionalTotalRows.map((row, index) => (
            <React.Fragment key={index}>
              {totalRow(headerGroupProps, headerGroup, row, index)}
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  });
}

CustomTableTotal.propTypes = {
  grid: PropTypes.shape({}),
  getRef: PropTypes.func,
  tableId: PropTypes.string,
  totalName: PropTypes.string,
  tableProps: PropTypes.shape({
    state: PropTypes.shape({}),
    flatRows: PropTypes.arrayOf(PropTypes.shape({})),
    headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  predefinedTotal: PropTypes.shape({}),
  totalTitleClassName: PropTypes.string,
  additionalTotalRows: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomTableTotal.defaultProps = {
  grid: {},
  getRef: undefined,
  tableId: undefined,
  totalName: undefined,
  tableProps: {
    state: {},
    flatRows: [],
    headerGroups: [],
  },
  predefinedTotal: {},
  totalTitleClassName: undefined,
  additionalTotalRows: undefined,
};

export default CustomTableTotal;

const Holder = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  border-top: 1px solid #dedede;

  &.withoutBorder {
    border-top: 0;
  }

  &.withBorder {
    border-top: 1px solid #dedede;
  }

  .isSortable {
    cursor: pointer;
  }

  .sort-icon-holder {
    font-size: 12px;
    margin-left: 5px;
  }

  i:hover {
    color: #ff8f5e;
  }

  .edit-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    textarea {
      height: 25px;
    }

    i {
      padding-left: 10px;
      margin-right: -10px;
    }
  }

  .flex-center {
    text-align: center;
    justify-content: center;
  }

  .flex-right {
    text-align: right;
    justify-content: flex-end;
  }

  .tr.body-row.clickable {
    cursor: pointer;
  }

  .tr {
    :nth-child(even) {
      background-color: #f5f5f5;
    }

    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th.isSorted .fa {
    color: #ff8f5e;
  }

  .th {
    margin: 0;
    padding: 10px 15px;
    display: flex !important;
    overflow: hidden;
    position: relative;
    font-size: 13px;
    flex-wrap: wrap;
    font-weight: 600;
    align-items: center;
    border-bottom: 1px solid #dedede;
    flex-direction: row;
    text-transform: uppercase;

    ${({ isModal, grid }) =>
      !isModal &&
      grid === 'sm' &&
      `
          @media (max-width: ${LARGE_SCREENS.max}px) {
            font-size: 11.5px;
            padding: 8px 5px;
          }
      `}

    ${({ isModal, grid }) =>
      !isModal &&
      grid !== 'sm' &&
      `
          @media (min-width: ${TWO_K.min}px) {
            font-size: 13px;
            padding: 10px 15px;
          }
    
          @media (min-width: ${FOUR_K.min}px) {
            font-size: 15px;
            padding: 10px 15px;
          }
      `}

    :last-child {
      border-right: 0;
    }

    input[type='checkbox'] {
      height: 15px;
      margin: 0;
      padding: 0;
    }

    .resizer {
      top: 0;
      right: -5px;
      width: 10px;
      height: 100%;
      position: absolute;
      touch-action: none;
    }
  }
`;

const Link = styled(TbUnlink)`
  margin-left: 5px;
  cursor: pointer;
  color: gray;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  padding: 2px;
  border: 1px solid #717171;
  transition: background-color 0.3s;

  &:hover {
    background: #717171;
    color: white;
  }
`;
