const customMediaQuery = (minWidth) => `@media (min-width: ${minWidth}px)`;

export const sizes = {
  desktop: 2560,
  laptopL: 1440,
  laptopM: 1150,
  laptop: 1024,
  tablet: 768,
  mobileL: 425,
  mobileM: 375,
  mobileS: 320,
  mobile: 0,
};

const mobile = customMediaQuery(sizes.mobile);

const mobileS = customMediaQuery(sizes.mobileS);

const mobileM = customMediaQuery(sizes.mobileM);

const mobileL = customMediaQuery(sizes.mobileL);

const tablet = customMediaQuery(sizes.tablet);

const laptop = customMediaQuery(sizes.laptop);

const laptopM = customMediaQuery(sizes.laptopM);

const laptopL = customMediaQuery(sizes.laptopL);

const desktop = customMediaQuery(sizes.desktop);

export const media = {
  mobile,
  mobileS,
  mobileM,
  mobileL,
  tablet,
  laptop,
  laptopM,
  laptopL,
  desktop,
};

export default media;
