import React from 'react';
import { t } from '@lingui/macro';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';
import { sortBooleanCustomTable } from 'services/helpers';

/**
 * Group User Grid
 */
export const columns = ({ reportMode }, isSuperOrSystemAdmin) => [
  {
    width: 70,
    Header: t`ID`,
    accessor: 'groupId',
    className: 'flex-center',
  },
  {
    width: reportMode ? 400 : 590,
    Header: t`Group Name`,
    accessor: 'name',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: 200,
    hidden: reportMode,
    Header: t`Users`,
    accessor: 'userGroupList',
    className: '',
    disableSortBy: true,
    cell: {
      icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
      action: reportMode ? false : 'viewUsers',
      converter: () => t`View all users...`,
      disableClick: true,
    },
  },
  {
    width: 100,
    Header: t`User Count`,
    accessor: 'userCount',
    className: 'flex-right',
  },
  ...(isSuperOrSystemAdmin
    ? [
        {
          width: 100,
          Header: t`Secret`,
          sortType: sortBooleanCustomTable,
          accessor: 'secret',
          className: 'flex-center',
          cell: {
            type: 'boolean',
          },
        },
      ]
    : []),
  {
    width: 90,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'edit',
          icon: () => <GoPencil />,
          tooltip: t`Edit`,
        },
        {
          id: 'delete',
          icon: () => <BiTrash />,
          tooltip: t`Delete`,
        },
      ],
    },
  },
];

export default columns;
