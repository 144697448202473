import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, Radio } from 'react-bootstrap';

import { Label } from 'components';

import { formatNumber } from 'services/helpers';

import { SET_INPUT_AUTOCOMPLETE_TIMEOUT } from 'constants/timeouts';

/**
 * Input component
 */
function Input(props) {
  const {
    type,
    size,
    invalid,
    disabled,
    readOnly,
    inputRef,
    noBorder,
    children,
    multiple,
    noFormat,
    placeholder,
    autoComplete,
    ...rest
  } = props;
  const [inputType, setInputType] = useState(
    autoComplete !== 'off' ? type : 'search', // google by default does not autofill input with type=search
  );
  const [value, setValue] = useState(
    typeof props.value !== 'undefined' ? props.value : props.defaultValue,
  );
  const [focused, setFocused] = useState(props.autoFocus || false);
  const classes = classNames('form-control', {
    'input-no-border': noBorder,
    error: invalid,
    valid: !invalid,
  });
  let componentClass;
  const onChange = (e) => {
    setValue(e.target.value);

    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }
  };
  const onFocus = (e) => {
    setFocused(true);

    if (typeof props.onFocus === 'function') {
      props.onFocus(e);
    }
  };
  const onBlur = (e) => {
    setFocused(false);

    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }
  };

  if (type === 'textarea') {
    componentClass = { type, componentClass: 'textarea', placeholder };
  } else if (type === 'select') {
    componentClass = { componentClass: 'select', multiple, children };
  } else if (type === 'checkbox' || type === 'radio') {
    componentClass = {
      type,
      readOnly,
      disabled,
    };
  } else if (type === 'file') {
    componentClass = {
      type,
      multiple,
    };
  } else {
    componentClass = { type, componentClass: 'input', placeholder };

    if (autoComplete && !['on', 'off'].includes(autoComplete)) {
      componentClass.autoComplete = autoComplete;
    }
  }

  useEffect(() => {
    if (autoComplete === 'off') {
      // set correct input type after google autofill worked
      setTimeout(() => setInputType(type), SET_INPUT_AUTOCOMPLETE_TIMEOUT);
    }
  }, []);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Only way with current setup for now
  /* eslint-disable jsx-a11y/label-has-for */
  if (type === 'checkbox') {
    const checkboxClasses = classNames('checkbox', ...classes);
    return (
      <div className={checkboxClasses}>
        <input {...rest} {...componentClass} type={inputType} />
        <Label style={{ paddingLeft: props.label ? '10px' : 0 }}>
          {props.label}
        </Label>
      </div>
    );
    /* eslint-enable jsx-a11y/label-has-for */
  }
  if (type === 'date') {
    return <input style={{ width: '100%' }} readOnly type="text" />;
  }
  if (type === 'radio') {
    return <Radio {...componentClass}>{children}</Radio>;
  }
  if (readOnly) {
    return <FormControl.Static {...rest}>{children}</FormControl.Static>;
  }

  if (inputType === 'number') {
    if (!focused && !noFormat) {
      componentClass.type = 'text';
      componentClass.value = formatNumber(+value).formatted;
    }

    return (
      <FormControl
        {...rest}
        type={inputType}
        value={value}
        {...componentClass}
        bsSize={size}
        onBlur={onBlur}
        onFocus={onFocus}
        bsClass={classes}
        onChange={onChange}
        disabled={disabled}
        inputRef={inputRef}
      />
    );
  }

  return (
    <FormControl
      {...rest}
      {...componentClass}
      type={inputType}
      bsSize={size}
      bsClass={classes}
      disabled={disabled}
      inputRef={inputRef}
    />
  );
}

Input.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'file',
    'date',
    'email',
    'radio',
    'select',
    'number',
    'textarea',
    'password',
    'checkbox',
  ]),
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inline: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.func,
  noBorder: PropTypes.bool,
  children: PropTypes.node,
  multiple: PropTypes.bool,
  noFormat: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
};

Input.defaultProps = {
  type: 'text',
  size: undefined,
  label: undefined,
  inline: false,
  invalid: undefined,
  disabled: false,
  readOnly: false,
  inputRef: undefined,
  noBorder: false,
  children: undefined,
  multiple: false,
  noFormat: false,
  placeholder: undefined,
  autoComplete: 'on',
};

export default Input;
