import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Hits, Configure, SearchBox } from 'react-instantsearch-dom';

import { AlgoliaHit } from 'components';

import algoliaIcon from 'assets/images/search-by-algolia-light-background.svg';

export function AlgoliaModal(props) {
  const {
    filters,
    onClose,
    selected,
    handleSubmit,
    containerRef,
    handleKeyDown,
  } = props;

  const getLabel = (type) => {
    let label = '';

    switch (type) {
      case 'placeholder': {
        label = t`Type to search`;

        break;
      }
      default: {
        break;
      }
    }

    return label;
  };

  return (
    <ModalHolder onKeyDown={handleKeyDown}>
      <Configure filters={filters} />
      <Content ref={containerRef}>
        <AlgoliaIconHolder>
          <AlgoliaIcon src={algoliaIcon} />
        </AlgoliaIconHolder>
        <CloseIconHolder onClick={onClose}>
          <CloseIcon as={IoClose} />
        </CloseIconHolder>
        <SearchBox
          autoFocus
          showLoadingIndicator
          onChange={handleSubmit}
          translate={getLabel}
        />
        {/* TODO: Upgrade to react instantsearch hooks */}
        {/* eslint-disable react/no-unstable-nested-components */}
        <Hits
          hitComponent={(hitProps) => (
            <AlgoliaHit
              {...hitProps}
              onClose={onClose}
              selected={hitProps.hit?.__position === selected}
            />
          )}
        />
      </Content>
    </ModalHolder>
  );
}

AlgoliaModal.propTypes = {
  filters: PropTypes.string,
  onClose: PropTypes.func,
  selected: PropTypes.string,
  handleSubmit: PropTypes.func,
  containerRef: PropTypes.func,
  handleKeyDown: PropTypes.func,
};

AlgoliaModal.defaultProps = {
  filters: '',
  onClose: () => {},
  selected: '',
  handleSubmit: () => {},
  containerRef: () => {},
  handleKeyDown: () => {},
};

export default AlgoliaModal;

const ModalHolder = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 10000000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  width: 50%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 600px;
  max-height: 80%;
  padding-top: 40px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  background-color: white;

  .ais-Hits {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin-top: 20px;
    padding-bottom: 30px;

    .ais-Hits-list {
      height: 100%;
      margin: 0;
      padding: 0 40px;
      list-style-type: none;

      li {
        width: 100%;

        a {
          width: 100%;
          color: #5468ff;
          border: 1px solid #5468ff;
          cursor: pointer;
          display: block;
          padding: 10px;
          text-align: center;
          transition: transform 0.2s ease-in-out;
          border-radius: 10px;
          margin-bottom: 10px;

          &:hover,
          &.selected {
            color: white;
            transform: scale(1.05);
            font-weight: bold;
            background-color: #5468ff;
          }
        }
      }
    }
  }

  .ais-SearchBox {
    width: 100%;
    padding: 20px 40px 0;

    .ais-SearchBox-form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ais-SearchBox-input {
      width: 100%;
      height: 40px;
      padding: 0 11px;
      font-size: 18px;
      max-width: 400px;
      border-radius: 10px;
    }

    button {
      width: 40px;
      height: 40px;
      border: 1px solid #5468ff;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      border-radius: 10px;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

const AlgoliaIconHolder = styled.div`
  top: 15px;
  left: 15px;
  position: absolute;
`;

const AlgoliaIcon = styled.img``;

const CloseIconHolder = styled.div`
  top: 15px;
  right: 15px;
  cursor: pointer;
  position: absolute;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const CloseIcon = styled.i`
  font-size: 24px;
`;
