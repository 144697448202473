import { useEffect, useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { resourceListReadRequest } from 'store/actions';
import { fromResource } from 'store/selectors';
import { getPayrollSettlementsApi } from 'services/apihelpers';
import {
  outgoingBalanceFormatter,
  paymentReconciliationFormatter,
} from 'services/dataFormatters/payroll';
import getErrorMessage from 'services/helpers/getErrorMessage';

export const usePRandOBdata = (props) => {
  const { accountingYear, uuid, activePeriods } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) =>
    fromResource.getList(state, getPayrollSettlementsApi),
  );

  const term = useMemo(() => Math.ceil(+activePeriods / 2), [activePeriods]);

  const rows = useMemo(() => {
    const ssPaymentReconciliation = paymentReconciliationFormatter({
      data,
      term,
      subTabEntry: 'SSTP',
    });
    const wtPaymentReconciliation = paymentReconciliationFormatter({
      data,
      term,
      subTabEntry: 'WTP',
    });
    const ftPaymentReconciliation = paymentReconciliationFormatter({
      data,
      term,
      subTabEntry: 'FTP',
    });

    const ssOutgoingBalance = outgoingBalanceFormatter({
      data,
      term,
      subTabEntry: 'SSTP',
      glTitle: t`Due social security from GL:`,
      paymentTitle: t`Due social security from Payments:`,
    });
    const wtOutgoingBalance = outgoingBalanceFormatter({
      data,
      term,
      subTabEntry: 'WTP',
      glTitle: t`Due withheld tax GL:`,
      paymentTitle: t`Due withheld tax from Payments:`,
    });

    const ftOutgoingBalance = outgoingBalanceFormatter({
      data,
      term,
      subTabEntry: 'FTP',
      glTitle: t`Due financial tax from GL:`,
      paymentTitle: t`Due financial tax from Payments:`,
    });

    return {
      ssPaymentReconciliation,
      wtPaymentReconciliation,
      ftPaymentReconciliation,

      ssOutgoingBalance,
      wtOutgoingBalance,
      ftOutgoingBalance,
    };
  }, [data, term]);

  const fetchData = async () => {
    setLoading(() => true);

    try {
      await dispatch(
        resourceListReadRequest(getPayrollSettlementsApi, {
          year: accountingYear,
          companyId: uuid,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accountingYear, uuid]);

  return { loading, rows, fetchData };
};

export default usePRandOBdata;
