import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { BiRefresh } from '@react-icons/all-files/bi/BiRefresh';

import { Card, Button, Heading, Paragraph, ButtonIcon } from 'components';

import { ERROR_BOX } from 'constants/errorBox';
import errorAnimation from 'assets/animations/error.json';
import { useOidc } from '@axa-fr/react-oidc';

function ErrorCard(props) {
  const { kind, error, errorInfo, unrecoverable } = props;
  const history = useHistory();
  const { login } = useOidc();

  const title = (
    <Heading level={2} className="card-title">
      <Trans>Something went wrong!</Trans>
    </Heading>
  );

  if (['balances', 'basic'].includes(kind)) {
    const { styles, button } = ERROR_BOX();
    const { height, ...colors } = styles[kind];
    const { icon, text, redirect } = button[kind];

    return (
      <Container height={height}>
        <ContainerInner colors={colors}>
          {title}
          <LottieAnimation
            width="18vw"
            height="auto"
            options={{
              loop: true,
              autoplay: true,
              animationData: errorAnimation,
            }}
          />
          <ErrorContent colors={colors}>
            {unrecoverable ? (
              <Paragraph level={5} style={{ paddingBottom: '1.75rem' }}>
                <Trans>
                  An unexpected error occured. Please contact Accountflow
                  support.
                </Trans>
              </Paragraph>
            ) : (
              <Paragraph level={5}>
                <Trans>
                  We are already working on fixing this problem. To continue
                  using application try:
                </Trans>
              </Paragraph>
            )}
            {!unrecoverable && (
              <ButtonsHolder>
                {history ? (
                  <Button
                    kind="info"
                    fill
                    magnify
                    onClick={() => history.go(0)}
                  >
                    <ButtonIcon as={BiRefresh} fontSize={20} marginRight={5} />
                    <Trans>Refresh page</Trans>
                  </Button>
                ) : (
                  <Button
                    kind="info"
                    fill
                    magnify
                    onClick={() => window.location.reload()}
                  >
                    <ButtonIcon as={BiRefresh} fontSize={20} marginRight={5} />
                    <Trans>Refresh page</Trans>
                  </Button>
                )}
                or
                {redirect ? (
                  <Button
                    fill
                    kind="success"
                    onClick={() => history.push(redirect())}
                  >
                    <ButtonIcon marginBottom={-3} marginRight={5} as={icon} />
                    {text}
                  </Button>
                ) : (
                  <Button fill kind="success" onClick={() => login()}>
                    <ButtonIcon marginBottom={-3} marginRight={5} as={icon} />
                    <Trans>Log in again</Trans>
                  </Button>
                )}
              </ButtonsHolder>
            )}
          </ErrorContent>
          <Paragraph level={5} className="error-name">
            {error && error.toString()}
          </Paragraph>
          {errorInfo && (
            <StyledDetails>{errorInfo.componentStack}</StyledDetails>
          )}
        </ContainerInner>
      </Container>
    );
  }

  return (
    <Col md={6} sm={6} mdOffset={4} smOffset={3}>
      <Card kind="lock" header={title} backgroundColor="orange">
        <Heading level={4}>{error && error.toString()}</Heading>
        <br />
        {errorInfo && (
          <StyledDetails>{errorInfo?.componentStack}</StyledDetails>
        )}
      </Card>
    </Col>
  );
}

ErrorCard.propTypes = {
  kind: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  errorInfo: PropTypes.shape({}).isRequired,
};

ErrorCard.defaultProps = {
  kind: undefined,
};

export default ErrorCard;

const StyledDetails = styled.details`
  white-space: pre-wrap !important;
  overflow-wrap: break-word !important;
  font-family: monospace !important;
`;

const LottieAnimation = styled(Lottie)`
  height: auto;
  margin: 0;
`;

const ButtonsHolder = styled.div`
  gap: 8px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// // Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ContainerInner = styled((props) => <Card {...props} />)`
  color: ${({ colors }) => colors.detailsColor};
  padding: 2vw;
  display: flex;
  text-align: center;
  background: ${({ colors }) => colors.background};
  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  h2 {
    color: ${({ colors }) => colors.textColor};
    font-weight: 600;
  }

  .error-name {
    margin-top: 25px;
    margin-bottom: 0;
  }
`;

const ErrorContent = styled.div`
  color: ${({ colors }) => colors.textColor};
  display: flex;
  padding: 10px 0;
  background: ${({ colors }) => colors.boxColor};
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;

  font-size: 16px;

  p {
    margin: 15px 0 0;
    font-size: 16px !important;
    max-width: 80%;
  }
`;
