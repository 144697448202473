import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { BiLogOut } from '@react-icons/all-files/bi/BiLogOut';
import { Link } from 'react-router-dom';

import { Button, Avatar, ButtonIcon } from 'components';

import { defineRoles } from 'services/helpers';

function SidebarUser(props) {
  const { to, user, company, roleType, handleLogout, sidebarToggle } = props;

  return (
    <div className="user">
      <div className="info">
        <TopHolder>
          <Avatar user={user} dimensions={40} />
          <Link
            to={to}
            role="button"
            style={{ padding: '0 0 0 10px' }}
            aria-expanded="true"
          >
            <Username>{user?.name}</Username>
            <div style={{ fontSize: '13px', lineHeight: '18px' }}>
              {defineRoles(roleType.roles)}
            </div>
          </Link>
        </TopHolder>
        <div className="clearfix" />
        {sidebarToggle ? (
          <>
            <LogoutDiv>
              <Button
                simple
                magnify
                style={{ paddingLeft: '18px' }}
                onClick={handleLogout}
                kind="danger"
                size="xs"
              >
                <ButtonIcon as={BiLogOut} marginLeft={-5} />
              </Button>
            </LogoutDiv>
            <LogoutDiv>
              <div style={{ paddingLeft: '15px' }}>
                {company.totalCompletenessPercentage} %
              </div>
            </LogoutDiv>
          </>
        ) : (
          <LogoutDiv>
            <Button fill wide onClick={handleLogout} kind="danger" size="xs">
              {t`Log out`}
            </Button>
          </LogoutDiv>
        )}
      </div>
    </div>
  );
}

SidebarUser.propTypes = {
  to: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  roleType: PropTypes.shape({}).isRequired,
  handleLogout: PropTypes.func.isRequired,
  sidebarToggle: PropTypes.bool,
};

SidebarUser.defaultProps = {
  to: undefined,
  sidebarToggle: false,
};

export default SidebarUser;

const TopHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;
`;

const Username = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoutDiv = styled.div`
  color: #eb5e28;
  display: flex;
  padding-top: 10px;
  justify-content: center;
`;
