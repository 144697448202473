import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { ReconciliationPage } from 'components';

import { fromCompany, fromResource } from 'store/selectors';
import { getReconciliationGLAccountsFastApi } from 'services/apihelpers';

class ReconciliationPageContainer extends Component {
  static propTypes = {
    /** @type {shape} location Router object */
    location: PropTypes.shape({}).isRequired,
    /** @type {shape} history Router object */
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    company: PropTypes.shape({}).isRequired,
  };

  getSearch = (props = this.props) => {
    const { location } = props;
    const search = location
      ? queryString.parse(location.search, { parseBooleans: true })
      : {};

    return {
      account: search.account,
    };
  };

  getAccountFromList = () => {
    const { account } = this.getSearch();
    const { accountList } = this.props;

    let accountData = {};

    if (account && Array.isArray(accountList)) {
      accountList.forEach((item) => {
        if (item.accountId === account) {
          accountData = {
            template: item.accountTemplateCode,
            accountName: item.name,
            approveStatus: item.approved,
            documentRequired: item.documentRequired,
            transactionFileListAccount: item.hasFile,
          };
        }
      });
    }

    return accountData;
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const search = this.getSearch();
    const accountInfoFromList = this.getAccountFromList();

    return (
      <ReconciliationPage
        params={params}
        refreshParent={this.refreshParent}
        {...this.props}
        {...accountInfoFromList}
        {...search}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  company: fromCompany.getCompany(state),
  accountList: fromResource.getList(state, getReconciliationGLAccountsFastApi),
});

export default connect(mapStateToProps)(ReconciliationPageContainer);
