import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { vatOutgoingBalanceModal } from 'services/tableshapes';
import { vatOutgoingBalanceModalFilters } from 'services/tableFilterShapes';

import { DESKTOP, TABLET } from 'constants/mediaSizes';

function VATOutgoingBalanceModal(props) {
  const {
    term,
    loading,
    modalData,
    closeModal,
    handleAction,
    actionSubmitting,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'period',
        desc: false,
      },
    ],
    [],
  );

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        handleAction,
        actionSubmitting,
        data: modalData,
        isModal: true,
        tableId: 'vatOutgoingBalanceModal',
        columns: vatOutgoingBalanceModal(),
        loadingId: 'transactionId',
        filterConfigs: vatOutgoingBalanceModalFilters(),
        headerConfigs: {
          exportFileName: t`Outgoing balance, owed VAT - Term {term}`,
        },
        initialState: {
          sortBy,
        },
      }}
    />
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Outgoing balance, owed VAT - Term {term}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={closeModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VATOutgoingBalanceModal;

VATOutgoingBalanceModal.propTypes = {
  term: PropTypes.number,
  loading: PropTypes.bool,
  closeModal: PropTypes.func,
  handleAction: PropTypes.func,
  actionSubmitting: PropTypes.shape({}),
};

VATOutgoingBalanceModal.defaultProps = {
  term: undefined,
  loading: undefined,
  closeModal: undefined,
  handleAction: () => {},
  actionSubmitting: {},
};

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
