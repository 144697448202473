import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  CustomTableWithAutoSettings,
  Field,
  Label,
  PeriodFilterButtons,
  Spinner,
} from 'components';

import { VATControlColumns } from 'services/tableshapes';
import { vatControlTableFilters } from 'services/tableFilterShapes';

const values = {
  MONTHLY: t`Monthly`,
  BIMONTHLY: t`Bi-Monthly`,
  QUARTERLY: t`Quarterly`,
  TERTIARY: t`Tertiary`,
  HALF_YEARLY: t`Half-Yearly`,
  YEARLY: t`Yearly`,
};

const periodTypes = {
  MONTHLY: 'monthly',
  BIMONTHLY: 'biMonthly',
  QUARTERLY: 'quarterly',
  TERTIARY: 'tertiary',
  HALF_YEARLY: 'halfYearly',
  YEARLY: 'yearly',
};

function VATControlTable(props) {
  const {
    tableData,
    loading,
    roleType,
    activePeriods,
    accumulated,
    handleFrequencyChange,
    handlePeriodChange,
    handleAccumulatedChange,
    periodType,
    handleAction,
  } = props;
  const title = t`VAT Specification Control`;

  const sortBy = useMemo(
    () => [
      {
        id: 'companyName',
        desc: false,
      },
      {
        id: 'groupType',
        desc: false,
      },
      {
        id: 'accountId',
        desc: false,
      },
    ],
    [],
  );

  const arrayedActivePeriods = useMemo(
    () => Array.from(Array(activePeriods).keys()),
    [activePeriods],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-vatSpecificationControl">
      <CardPDFTitle title={title} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <MetaData>
            <PeriodContainer>
              <InfoBlock>
                <Title>
                  <Trans>Frequency:</Trans>
                </Title>
                <Field
                  name="selectedReport"
                  type="select"
                  onChange={handleFrequencyChange}
                  defaultValue={periodType}
                >
                  {Object.keys(values).map((item) => (
                    <option key={`frequency-${item}`} value={item}>
                      {t({ message: values[item] })}
                    </option>
                  ))}
                </Field>
              </InfoBlock>
              {periodType !== 'YEARLY' && !!periodType && (
                <PeriodFilterButtons
                  size="small"
                  singlePeriod={!accumulated}
                  periodType={periodTypes[periodType]}
                  activePeriods={arrayedActivePeriods}
                  handleFilterChange={handlePeriodChange}
                />
              )}
            </PeriodContainer>
            <PeriodContainer>
              <Field
                horizontal
                id="accumulated"
                type="checkbox"
                name="accumulated"
                label={t`Show single period`}
                checked={!accumulated}
                onChange={handleAccumulatedChange}
              />
            </PeriodContainer>
          </MetaData>
          <CustomTableWithAutoSettings
            {...{
              data: tableData,
              loadingId: 'accountId',
              tableId: 'vatSpecificationControl',
              filterConfigs: vatControlTableFilters(tableData),
              loading,
              handleAction,
              columns: VATControlColumns(roleType.isReadOnly),
              withTotal: true,
              headerConfigs: {
                exportFileName: title,
              },
              initialState: {
                sortBy,
              },
            }}
          />
        </>
      )}
    </Card>
  );
}

export default VATControlTable;

VATControlTable.propTypes = {
  params: PropTypes.shape({}),
  loading: PropTypes.bool,
};

VATControlTable.defaultProps = {
  params: { accountingYear: undefined },
  loading: true,
};

const MetaData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .form-group {
    display: flex;
    align-items: center;
  }

  label {
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    margin-bottom: 0;
  }

  select {
    background: white;
    margin-top: 2px;
  }
`;

const PeriodContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-right: 8px;

  select {
    height: 22px;
    padding: 1px 6px;
    font-size: 12px;
  }
`;

const Title = styled(Label)`
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 5px;
`;
