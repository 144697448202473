import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import { Button, Heading, ButtonIcon, CustomTable, Spinner } from 'components';

import { years } from 'services/shapes';
import { commentsHistoryColumns } from 'services/tableshapes';
import { periodHistoryFilters } from 'services/tableFilterShapes';

import { DESKTOP } from 'constants/mediaSizes';

function CommentsHistoryModal(props) {
  const {
    data,
    loading,
    params,
    isReadOnly,
    toggleModal,
    handleAction,
    actionSubmitting,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Needs to wait until modal animation finished otherwise custom table blurs
    const modalAnimation = setTimeout(() => setShow(true), 500);

    return () => {
      clearTimeout(modalAnimation);
    };
  }, []);

  const globalFilter = useMemo(
    () => ({
      year: years().filter((item) => item.label === params.accountingYear),
    }),
    [params?.accountingYear],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'year',
        desc: false,
      },
      {
        id: 'period',
        desc: false,
      },
    ],
    [],
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Period Comments History</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {show ? (
          <CustomTable
            {...{
              data,
              loading,
              isModal: true,
              handleAction,
              actionSubmitting,
              columns: commentsHistoryColumns(isReadOnly),
              tableId: 'periodCommentsHistory',
              loadingId: 'id',
              reportMode: false,
              filterConfigs: periodHistoryFilters(),
              headerConfigs: {
                exportFileName: t`Period Comments History`,
              },
              initialState: {
                sortBy,
                globalFilter,
              },
            }}
          />
        ) : (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button fill kind="danger" magnify onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommentsHistoryModal;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1105px;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;
