import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'components';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';

/**
 * Login Navigation Bar component
 */
function LoginNavbar({
  handleSelect,
  handleMenuToggle,
  kind,
  children,
  expanded,
  noLogin,
  ...props
}) {
  // Custom Paper PRO contextual classes
  const classes = classNames(
    { 'navbar-transparent': kind === 'inverse' },
    'navbar-absolute',
  );

  return (
    <Navbar
      collapseOnSelect
      className={classes}
      bsStyle={kind}
      onToggle={handleMenuToggle}
      expanded={expanded}
      {...props}
    >
      <Navbar.Header>
        <Navbar.Brand>
          <Logo height={100} />
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
    </Navbar>
  );
}

LoginNavbar.propTypes = {
  /** @type {func} handleSelect handles select on navbar menus */
  handleSelect: PropTypes.func.isRequired,
  /**
   /** @type {func} handleMenuToggle handles menu toogle on responsive pages */
  handleMenuToggle: PropTypes.func.isRequired,
  /**
   * Bootstrap LoginNavbar styles
   * @type {("default"|"inverse")}
   */
  kind: PropTypes.oneOf(['default', 'inverse']),
  /** @type {bool} expanded Default menu state */
  expanded: PropTypes.bool.isRequired,
  /** @type {node} children Nodes passed throught the component */
  children: PropTypes.node,
};

LoginNavbar.defaultProps = {
  kind: 'inverse',
  children: undefined,
};

export default LoginNavbar;
