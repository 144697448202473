import { t } from '@lingui/macro';

const map = t`Map transaction`;
const unMap = t`Unmap transaction`;
const alreadyMapped = t`Already mapped`;

/**
 * Map Payroll Transactions Grid
 */
export const columns = () => [
  {
    width: 140,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
  },
  {
    width: 120,
    Header: t`Account ID`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
  },
  {
    width: 115,
    Header: t`Mapped to Period`,
    accessor: 'mappedTo',
    className: 'flex-right',
  },
  {
    width: 95,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 100,
    Header: t`Date`,
    accessor: 'date',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: 130,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 160,
    Header: t`Action`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'button',
      titleConverter: (item) => {
        if (item?.disabled) {
          return alreadyMapped;
        }

        return item?.mappingDetails?.mapped ? unMap : map;
      },
      disableClick: true,
      buttons: [
        {
          id: 'mapTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Map Transaction`,
          magnify: true,
          disabled: (item) => item?.disabled,
        },
      ],
    },
  },
];

export default { columns };
