import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';

function Heading(props) {
  const {
    kind,
    level,
    children,
    alignment,
    className,
    background,
    transformation,
    ...rest
  } = props;
  const { reportMode } = queryString.parse(window.location.search, {
    parseBooleans: true,
  });
  // Custom Bootstrap contextual classes
  const classes = classNames(
    {
      reportMode,
      'text-info': kind === 'info',
      'text-muted': kind === 'muted',
      'text-danger': kind === 'danger',
      'text-primary': kind === 'primary',
      'text-success': kind === 'success',
      'text-warning': kind === 'warning',
      'text-left': alignment === 'left',
      'text-right': alignment === 'right',
      'text-center': alignment === 'center',
      'text-nowrap': alignment === 'nowrap',
      'text-justify': alignment === 'justify',
      'text-lowercase': transformation === 'lowercase',
      'text-uppercase': transformation === 'uppercase',
      'text-capitalize': transformation === 'capitalize',
      'bg-info': background === 'info',
      'bg-danger': background === 'danger',
      'bg-primary': background === 'primary',
      'bg-success': background === 'success',
      'bg-warning': background === 'warning',
    },
    className,
  );

  return React.createElement(
    `h${level}`,
    { className: classes, ...rest },
    children,
  );
}

/**
 * Heading component
 */
Heading.propTypes = {
  kind: PropTypes.oneOf([
    'info',
    'muted',
    'danger',
    'success',
    'warning',
    'primary',
  ]),
  level: PropTypes.number,
  children: PropTypes.node,
  alignment: PropTypes.oneOf(['left', 'center', 'right', 'justify', 'nowrap']),
  className: PropTypes.string,
  background: PropTypes.oneOf([
    'info',
    'danger',
    'success',
    'warning',
    'primary',
  ]),
  transformation: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),
};

Heading.defaultProps = {
  kind: undefined,
  level: 1,
  children: undefined,
  alignment: undefined,
  className: undefined,
  background: undefined,
  transformation: undefined,
};

export default Heading;
