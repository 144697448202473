import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { ButtonGroup, DropdownButton, MenuItem } from 'react-bootstrap';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { IoSettingsSharp } from '@react-icons/all-files/io5/IoSettingsSharp';
import { GoListUnordered } from '@react-icons/all-files/go/GoListUnordered';
import { MdVisibilityOff } from '@react-icons/all-files/md/MdVisibilityOff';
import { MdOutlineInsertDriveFile } from '@react-icons/all-files/md/MdOutlineInsertDriveFile'; // eslint-disable-line max-len

import {
  Beta,
  Field,
  Button,
  Spinner,
  MultiSelect,
  ButtonIcon,
} from 'components';

import { LARGE_SCREENS } from 'constants/mediaSizes';
import {
  TOP_CONTENT_MODES,
  PAGE_VIEW as pageView,
  PAGE_SIZES as pageSizes,
  TABLE_EXPORT as tableExport,
} from 'constants/table';

import {
  formatDate,
  formatNumber,
  createPDFFile,
  createCSVFile,
  createXLSXFile,
  defineNumberStyles,
  detectCustomTableTopContentMode,
} from 'services/helpers';
import { useCurrentWidth } from 'services/hooks';

const { small, medium } = TOP_CONTENT_MODES;
const styles = {
  small: {
    inputWidth: 100,
    extraFontSize: 10.6,
    resultFontSize: 12,
    horizontalPadding: 4,
    extraHorizontalPadding: 4,
  },
  medium: {
    inputWidth: 115,
    extraFontSize: 11,
    resultFontSize: 12,
    horizontalPadding: 5,
    extraHorizontalPadding: 5,
  },
};

const filterExportRows = (el) => !el?.original?.disableExport;

export function CustomTableTopContent(props) {
  const {
    grid,
    columns,
    tableId,
    isModal,
    setGrid,
    fixedSize,
    filterOpen,
    tableProps,
    hideFilters,
    handleAction,
    resetSettings,
    toggleFilters,
    filterConfigs,
    actionSubmitting,
    showResetSettings,
    headerConfigs = {},
  } = props;
  const [mode, setMode] = useState(fixedSize || 'small');
  const [shiftButtons, setShiftButtons] = useState(false);
  const screenSize = useCurrentWidth();

  const PAGE_VIEW = pageView();
  const PAGE_SIZES = pageSizes();
  const TABLE_EXPORT = tableExport();

  const { exportFileName, extraButtons = [] } = headerConfigs;
  const {
    data,
    rows,
    gotoPage,
    pageCount,
    allColumns,
    setPageSize,
    setGlobalFilter,
    setHiddenColumns,
    selectedFlatRows,
    state: { pageSize, pageIndex, globalFilter, hiddenColumns },
  } = tableProps;
  const handleToggleItems = (e) => {
    const columnsToHide = allColumns
      .filter((item) => e.target.value.includes(item.id))
      .map((item) => item.id);

    setHiddenColumns(columnsToHide);
  };

  const handleExport = async (id) => {
    if (id === 'downloadCSV') {
      const filterColumns = (head) =>
        !['actions', 'selection', ...hiddenColumns].includes(head.accessor);
      const columnsToRender = columns.filter(filterColumns);

      const csvColumns = columnsToRender
        .map((head) => {
          if (head.columns) {
            return head.columns
              .filter(filterColumns)
              .map((el) => `"${head.Header} ${el.Header}"`)
              .join(',');
          }

          return `"${head.Header}"`;
        })
        .join(',');
      const mapCSVBody = (el, head) => {
        if (
          typeof head.cell?.converter === 'function' &&
          head.cell?.type !== 'datetime'
        ) {
          let value =
            head.cell.converter(
              el.original?.[head.accessor],
              el.original,
              null,
              true,
            ) ?? '';

          if (
            typeof value === 'number' &&
            (head.cell?.type === 'number' || head.cell?.inputType === 'number')
          ) {
            value = formatNumber(value).formatted;
          }

          return value;
        }

        if (
          head.cell?.type === 'number' ||
          (head.cell?.type === 'input' && head.cell?.inputType === 'number')
        ) {
          return `"${
            el.original?.subtitle && !el.original?.subtitle?.exported
              ? ''
              : formatNumber(el.original?.[head.accessor] || 0).formatted
          }"`;
        }

        if (head.cell?.type === 'date') {
          return `"${formatDate(el.original?.[head.accessor] || '')}"`;
        }

        if (head.cell?.type === 'datetime') {
          const datetime =
            typeof head.cell?.converter === 'function'
              ? head.cell?.converter(el.original)
              : el.original?.[head.accessor] || '';

          return `"${formatDate(datetime, true)}"`;
        }

        if (head.cell?.type === 'boolean') {
          return `"${el.original?.[head.accessor] ? '✓' : '✗'}"`;
        }

        if (head.cell?.type === 'arrayOfBoolean') {
          return head.cell?.booleans
            ?.filter((item) =>
              item?.displayIfTrue ? el.original?.[item.id] : true,
            )
            ?.map((item) => (el.original?.[item.id] ? '✓' : '✗'))
            ?.join(' ');
        }

        return `"${el.original?.[head.accessor] || ''}"`;
      };
      const csvRows = tableProps.flatRows
        .filter(filterExportRows)
        .map((el) =>
          columnsToRender
            .map((head) => {
              if (head.columns) {
                return head.columns
                  .filter(filterColumns)
                  .map((headChild) => mapCSVBody(el, headChild))
                  .join(',');
              }

              return mapCSVBody(el, head);
            })
            .join(','),
        )
        .join('\n');

      createCSVFile({
        name: exportFileName,
        rows: csvRows,
        columns: csvColumns,
      });
    } else if (id === 'downloadXLSX') {
      const filterColumns = (head) =>
        !['actions', 'selection', ...hiddenColumns].includes(head.accessor);
      const columnsToRender = columns.filter(filterColumns);
      const xlsxColumns = [];

      columnsToRender.forEach((head) => {
        if (head.columns) {
          head.columns.filter(filterColumns).forEach((el) => {
            xlsxColumns.push({
              key: el.accessor,
              header: `${head.Header} ${el.Header}`,
              align: head.className,
              type: head.cell?.type,
            });
          });
        } else {
          xlsxColumns.push({
            key: head.accessor,
            header: head.Header,
            align: head.className,
            type: head.cell?.type,
          });
        }
      });
      const mapXLSXBody = (el, head) => {
        if (
          typeof head.cell?.converter === 'function' &&
          head.cell?.type !== 'datetime'
        ) {
          let value =
            head.cell.converter(
              el.original?.[head.accessor],
              el.original,
              null,
              true,
            ) ?? '';

          if (
            typeof value === 'number' &&
            (head.cell?.type === 'number' || head.cell?.inputType === 'number')
          ) {
            value = formatNumber(value).value;
          }

          return value;
        }

        if (
          head.cell?.type === 'number' ||
          (head.cell?.type === 'input' && head.cell?.inputType === 'number')
        ) {
          return el.original?.subtitle && !el.original?.subtitle?.exported
            ? ''
            : formatNumber(el.original?.[head.accessor] || 0).value;
        }

        if (head.cell?.type === 'date') {
          return formatDate(el.original?.[head.accessor] || '');
        }

        if (head.cell?.type === 'datetime') {
          const datetime =
            typeof head.cell?.converter === 'function'
              ? head.cell?.converter(el.original)
              : el.original?.[head.accessor] || '';

          return `"${formatDate(datetime, true)}"`;
        }

        if (head.cell?.type === 'boolean') {
          return el.original?.[head.accessor] ? '✓' : '✗';
        }

        if (head.cell?.type === 'arrayOfBoolean') {
          return head.cell?.booleans
            ?.filter((item) =>
              item?.displayIfTrue ? el.original?.[item.id] : true,
            )
            ?.map((item) => (el.original?.[item.id] ? '✓' : '✗'))
            ?.join(' ');
        }

        return el.original?.[head.accessor] || '';
      };
      const xlsxRows = tableProps.flatRows
        .filter(filterExportRows)
        .map((el) => {
          const elementToReturn = {};

          columnsToRender.forEach((head) => {
            if (head.columns) {
              head.columns.filter(filterColumns).forEach((headChild) => {
                elementToReturn[headChild.accessor] = mapXLSXBody(
                  el,
                  headChild,
                );
              });
            }
            elementToReturn[head.accessor] = mapXLSXBody(el, head);
          });

          return elementToReturn;
        });

      await createXLSXFile({
        name: exportFileName,
        rows: xlsxRows,
        columns: xlsxColumns,
      });
    } else if (id === 'downloadPDF') {
      const filterColumns = (head) =>
        !['actions', 'selection', ...hiddenColumns].includes(head.accessor);
      const columnsToRender = columns.filter(filterColumns);
      const pdfColumns = [];
      const thStyles = `
        color: #252422;
        margin: 0;
        padding: 5px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
      `;
      const tdStyles = `
        color: #252422;
        margin: 0;
        padding: 5px;
        font-size: 14px;
      `;

      columnsToRender.forEach((head) => {
        if (head.columns) {
          head.columns.filter(filterColumns).forEach((el) => {
            pdfColumns.push({
              name: el.accessor,
              alias: `${head.Header} ${el.Header}`,
              style: thStyles,
            });
          });
        } else {
          pdfColumns.push({
            name: head.accessor,
            alias: head.Header,
            style: thStyles,
          });
        }
      });
      const mapPDFBody = (el, head) => {
        let value = '';
        let extraStyles = '';

        if (el.original?.subtitle) {
          extraStyles = 'font-weight: bold;';
        }

        if (
          typeof head.cell?.converter === 'function' &&
          head.cell?.type !== 'datetime'
        ) {
          value =
            head.cell.converter(
              el.original?.[head.accessor],
              el.original,
              null,
              true,
            ) ?? '';
          if (
            typeof value === 'number' &&
            (head.cell?.type === 'number' || head.cell?.inputType === 'number')
          ) {
            const resp = formatNumber(value);
            value = resp.formatted;

            extraStyles += defineNumberStyles(resp);
          }
        } else if (
          head.cell?.type === 'number' ||
          (head.cell?.type === 'input' && head.cell?.inputType === 'number')
        ) {
          const resp = formatNumber(el.original?.[head.accessor] || 0);
          value =
            el.original?.subtitle && !el.original?.subtitle?.exported
              ? ''
              : resp.formatted;

          extraStyles += defineNumberStyles(resp);
        } else if (head.cell?.type === 'date') {
          value = formatDate(el.original?.[head.accessor] || '');
        } else if (head.cell?.type === 'datetime') {
          const datetime =
            typeof head.cell?.converter === 'function'
              ? head.cell?.converter(el.original)
              : el.original?.[head.accessor] || '';

          value = formatDate(datetime, true);
        } else if (head.cell?.type === 'boolean') {
          value = el.original?.[head.accessor] ? '✓' : '✗';
        } else if (head.cell?.type === 'arrayOfBoolean') {
          value = head.cell?.booleans
            ?.filter((item) =>
              item?.displayIfTrue ? el.original?.[item.id] : true,
            )
            ?.map((item) => (el.original?.[item.id] ? '✓' : '✗'))
            ?.join(' ');
        } else {
          value = el.original?.[head.accessor] || '';
        }

        switch (head.className) {
          case 'flex-center': {
            extraStyles += 'text-align: center;';
            break;
          }
          case 'flex-right': {
            extraStyles += 'text-align: right;';
            break;
          }
          default: {
            break;
          }
        }

        return {
          value,
          styles: `${tdStyles}${extraStyles}`,
        };
      };
      const pdfRows = tableProps.flatRows.filter(filterExportRows).map((el) => {
        const elementToReturn = {};

        columnsToRender.forEach((head) => {
          if (head.columns) {
            head.columns.filter(filterColumns).forEach((headChild) => {
              elementToReturn[headChild.accessor] = mapPDFBody(el, headChild);
            });
          }
          elementToReturn[head.accessor] = mapPDFBody(el, head);
        });

        return elementToReturn;
      });

      createPDFFile({
        name: exportFileName,
        rows: pdfRows,
        columns: pdfColumns,
      });
    } else {
      await handleAction(
        id,
        selectedFlatRows.map((el) => el.original),
      );
    }
  };
  const handlePageChange = (value) => {
    gotoPage(value);
  };
  const handlePageSizeChange = (value) => {
    setPageSize(value);
  };
  const handlePageGridChange = (value) => {
    setGrid(value);
    tableProps.state.grid = value;
  };
  const columnsToHide = allColumns.map((item) => {
    const newItem = { label: item.Header, value: item.id };

    if (item.id === 'selection') {
      newItem.label = t`Selection`;
    } else if (item.parent?.Header?.trim()) {
      newItem.label = `${item.parent?.Header} - ${item.Header}`;
    }

    return newItem;
  });
  const filtersApplied =
    globalFilter &&
    Object.keys(globalFilter).filter((item) => item !== 'search').length;
  const renderButton = (item) => {
    const submitting = actionSubmitting[item.id];
    const handleButtonClick = (val = null) => {
      handleAction(
        item.superSpecialAddCompanyButton && val !== null ? val : item.id,
        selectedFlatRows.map((el) => el.original),
      );
    };
    if (item.superSpecialAddCompanyButton) {
      const buttonTitle = (
        <>
          <ButtonIcon as={FaPlus} marginRight={4} />
          {item.title}
        </>
      );
      return (
        <DropdownButton
          key={`table-top-button-${tableId}-${item.id}`}
          {...item.buttonProps}
          disabled={item?.buttonProps?.disabled}
          data-testid={`extra-button-${item.id}`}
          title={buttonTitle}
          style={{ borderRadius: '4px' }}
          bsSize="small"
        >
          {submitting ? (
            <Spinner size="md" />
          ) : (
            item.options.map((el) => (
              <MenuItem
                key={`table-top-button-${tableId}-${item.id}-${el.id}`}
                eventKey={el.id}
                onClick={() => handleButtonClick(el.id)}
              >
                {el.title}
              </MenuItem>
            ))
          )}
        </DropdownButton>
      );
    }
    return (
      <RelativeButton
        key={`table-top-button-${tableId}-${item.id}`}
        {...item.buttonProps}
        onClick={handleButtonClick}
        disabled={submitting || item?.buttonProps?.disabled}
        className="extra-button"
        data-testid={`extra-button-${item.id}`}
      >
        {item?.tag && <Beta text={item.tag} />}
        {submitting ? (
          <Spinner size="md" />
        ) : (
          <>
            {!!item.icon && item.icon}
            {item.title}
          </>
        )}
      </RelativeButton>
    );
  };

  const handleInputChange = (e) => {
    const newFilters = { ...globalFilter };

    if (e.target.value) {
      newFilters[e.target.id] = e.target.value;
    } else {
      delete newFilters[e.target.id];
    }

    setGlobalFilter(newFilters);
  };

  /* eslint-disable react/no-unstable-nested-components */
  const titles = {
    small: {
      resetSettings: () => (
        <>
          <ButtonIcon
            as={IoSettingsSharp}
            fontSize={14}
            marginRight={3}
            marginBottom={-3}
          />
          {t`Reset`}
        </>
      ),
      filters: () => (
        <>
          <ButtonIcon
            as={FaFilter}
            fontSize={10}
            marginLeft={2}
            marginRight={2}
            marginBottom={-1}
          />
          {filtersApplied ? `(${filtersApplied})` : ''}
        </>
      ),
      export: () => <FaDownload style={{ marginBottom: -2 }} />,
      grid: grid.label,
      hiddenColumns: () => (
        <>
          <ButtonIcon as={MdVisibilityOff} fontSize={15} />
          {hiddenColumns.length ? ` (${hiddenColumns.length})` : ''}
        </>
      ),
      page: () => (
        <>
          {screenSize > 800 && (
            <ButtonIcon
              as={MdOutlineInsertDriveFile}
              fontSize={15}
              marginRight={3}
            />
          )}
          {pageIndex + 1}
        </>
      ),
      lines: () => (
        <>
          {screenSize > 800 && (
            <ButtonIcon
              as={GoListUnordered}
              fontSize={14}
              marginRight={3}
              marginBottom={-3}
            />
          )}
          {PAGE_SIZES.find((el) => el.id === pageSize)?.label}
        </>
      ),
    },

    medium: {
      resetSettings: () => (
        <>
          <ButtonIcon
            as={IoSettingsSharp}
            fontSize={14}
            marginRight={3}
            marginBottom={-3}
          />
          {t`Reset`}
        </>
      ),
      filters: () => (
        <>
          <ButtonIcon
            as={FaFilter}
            fontSize={10}
            marginRight={3}
            marginBottom={-1}
          />
          {t`Filters${filtersApplied ? ` (${filtersApplied})` : ''}`}
        </>
      ),
      export: () => <FaDownload style={{ marginBottom: -1 }} />,
      grid: grid.label,
      hiddenColumns: () => (
        <>
          {t`Hidden columns`}
          {hiddenColumns.length ? ` (${hiddenColumns.length})` : ''}
        </>
      ),
      page: () => (
        <>
          <ButtonIcon as={MdOutlineInsertDriveFile} marginRight={3} />
          {pageIndex + 1}
        </>
      ),
      lines: () => (
        <>
          <ButtonIcon
            as={GoListUnordered}
            fontSize={14}
            marginRight={3}
            marginBottom={-3}
          />
          {PAGE_SIZES.find((el) => el.id === pageSize)?.label}
        </>
      ),
    },

    default: {
      resetSettings: () => t`Reset settings`,
      filters: () => (
        <>
          <FilterIcon />
          {t`Filters${filtersApplied ? ` (${filtersApplied})` : ''}`}
        </>
      ),
      hiddenColumns: () => (
        <>
          {t`Hidden columns`}
          {hiddenColumns.length ? ` (${hiddenColumns.length})` : ''}
        </>
      ),
      page: () => t`Page: ${pageIndex + 1}`,
      lines: () =>
        t`Show: ${PAGE_SIZES.find((el) => el.id === pageSize)?.label}`,
      grid: t`View: ${grid.label}`,
      export: () => (
        <>
          <FaDownload style={{ marginRight: 5, marginBottom: -1 }} />
          {t`Export`}
        </>
      ),
    },
  };

  const basicRightButtons = (type = mode) => (
    <>
      <DropdownButton
        pullRight
        id={`view-dd-${tableId}`}
        title={titles[type].grid}
        bsSize="small"
        onSelect={handlePageGridChange}
      >
        {PAGE_VIEW.map((item) => (
          <MenuItem
            key={`show-per-page-${item.id}-${tableId}`}
            active={item.id === grid.id}
            eventKey={item}
          >
            {item.label}
          </MenuItem>
        ))}
      </DropdownButton>
      <DropdownButton
        pullRight
        id={`export-dd-${tableId}`}
        title={
          TABLE_EXPORT.find((exp) => actionSubmitting[exp.id]) ? (
            <Spinner size="md" type="white" />
          ) : (
            titles[type].export()
          )
        }
        bsSize="small"
        onSelect={handleExport}
      >
        {TABLE_EXPORT.map((item) => (
          <MenuItem
            key={`export-button-${tableId}-${item.id}`}
            eventKey={item.id}
          >
            {actionSubmitting[item.id] ? <Spinner size="md" /> : item.label}
          </MenuItem>
        ))}
      </DropdownButton>
    </>
  );

  if (hideFilters) {
    const _mode = isModal ? 'medium' : 'default';
    return (
      <GridHolder mode={_mode} flexEnd>
        <ButtonRightGroup>{basicRightButtons(_mode)}</ButtonRightGroup>
      </GridHolder>
    );
  }

  // eslint-disable-next-line consistent-return, react-hooks/rules-of-hooks
  useEffect(() => {
    if (fixedSize) {
      return () => setMode(fixedSize);
    }

    setShiftButtons(
      (extraButtons?.length >= 3 && screenSize < 1366) ||
        (extraButtons?.length === 2 && screenSize <= 1140),
    );

    setMode(
      detectCustomTableTopContentMode({
        isModal,
        screenSize,
        length: extraButtons?.length,
      }),
    );
  }, [screenSize, extraButtons?.length, fixedSize]);

  return (
    <>
      <GridHolder mode={mode} buttonsSize={extraButtons?.length}>
        <LeftHolder>
          <ButtonLeftGroup bsSize="small">
            {showResetSettings && (
              <Button magnify kind="default" onClick={resetSettings}>
                {titles[mode].resetSettings()}
              </Button>
            )}
            {!!filterConfigs.filter((item) => item.id !== 'search').length && (
              <Button
                magnify
                kind="default"
                fill={filterOpen}
                onClick={toggleFilters}
                data-testid="table-filter-button"
              >
                {titles[mode].filters()}
              </Button>
            )}
            <MultiSelect
              size="small"
              name={`hiddenColumns-${tableId}`}
              title={titles[mode].hiddenColumns()}
              checked={hiddenColumns}
              options={columnsToHide}
              onSelect={handleToggleItems}
              wrapperClassName="btn-group"
            />
          </ButtonLeftGroup>
          <InputHolder
            mode={mode}
            fixedSize={!!fixedSize}
            withValue={!!globalFilter.search}
            buttonsSize={extraButtons?.length}
          >
            <Field
              id="search"
              size="sm"
              type="input"
              onChange={handleInputChange}
              placeholder={t`Type to search`}
              defaultValue={globalFilter.search}
            />
          </InputHolder>
        </LeftHolder>
        {extraButtons.length && !shiftButtons ? (
          <CenterHolder>
            <ButtonCenterGroup bsSize="small">
              {extraButtons.map(renderButton)}
            </ButtonCenterGroup>
          </CenterHolder>
        ) : null}
        <RightHolder mode={mode}>
          <ResultsHolder
            mode={mode}
            buttonsSize={extraButtons?.length}
            selectedRows={selectedFlatRows?.length}
          >
            <strong>{rows.length}</strong> {t`Results`}
          </ResultsHolder>
          <ButtonRightGroup>
            {pageCount > 1 && (
              <DropdownButton
                id={`page-dd-${tableId}`}
                title={titles[mode].page()}
                bsSize="small"
                onSelect={handlePageChange}
                data-testid="dropdown-page"
              >
                {Array.from({ length: pageCount }, (e, i) => i).map((item) => (
                  <MenuItem
                    key={`page-${item}-${tableId}`}
                    active={item === pageIndex}
                    eventKey={item}
                  >
                    {item + 1}
                  </MenuItem>
                ))}
              </DropdownButton>
            )}
            {PAGE_SIZES[0].id < data.length && (
              <DropdownButton
                id={`show-dd-${tableId}`}
                title={titles[mode].lines()}
                bsSize="small"
                onSelect={handlePageSizeChange}
              >
                {PAGE_SIZES.map((item) => (
                  <MenuItem
                    key={`show-per-page-${item.id}-${tableId}`}
                    active={item.id === pageSize}
                    eventKey={item.id}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </DropdownButton>
            )}
            {basicRightButtons()}
          </ButtonRightGroup>
        </RightHolder>
      </GridHolder>
      {extraButtons.length && shiftButtons ? (
        <GridHolder mode={mode}>
          <CenterHolder shiftButtons>
            <ButtonCenterGroup bsSize="small">
              {extraButtons.map(renderButton)}
            </ButtonCenterGroup>
          </CenterHolder>
        </GridHolder>
      ) : null}
    </>
  );
}

CustomTableTopContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  tableId: PropTypes.string,
  isModal: PropTypes.bool,
  fixedSize: PropTypes.string,
  filterOpen: PropTypes.bool,
  tableProps: PropTypes.shape({}).isRequired,
  handleAction: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  filterConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  headerConfigs: PropTypes.shape({}),
  actionSubmitting: PropTypes.shape({}),
};

CustomTableTopContent.defaultProps = {
  columns: [],
  tableId: undefined,
  isModal: false,
  fixedSize: undefined,
  filterOpen: false,
  filterConfigs: [],
  headerConfigs: {},
  actionSubmitting: {},
};

export default CustomTableTopContent;

const GridHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'space-between')};

  .fa {
    margin-right: 5px;
  }

  ${({ mode }) =>
    [small, medium].includes(mode) &&
    `
     button {
      font-size: 11px !important;
      padding: 5px ${styles[mode].horizontalPadding}px !important;
      ${mode === 'small' && 'line-height: inherit !important;'}

       &.extra-button {
         padding: 5px ${styles[mode].extraHorizontalPadding}px !important;
         font-size: ${styles[mode].extraFontSize}px !important;
       }
     }
  `};

  ${({ buttonsSize }) =>
    buttonsSize >= 3 &&
    `
     @media (min-width: ${LARGE_SCREENS.max}px) and (max-width: 2060px) {
        button {
          padding: 5px 8px !important;
          font-size: 11.2px !important;
        }
     }
  `};
`;

const ResultsHolder = styled.span`
  gap: ${({ mode }) => (mode === medium ? '3px' : '5px')};
  display: ${({ mode, selectedRows }) =>
    mode === 'small' && selectedRows ? 'none' : 'flex'};
  font-size: ${({ mode }) => [small, medium].includes(mode) && '12px'};
  line-height: normal !important;
  margin-right: ${({ mode }) => (mode === medium ? '5px' : '10px')};

  ${({ mode }) =>
    mode === small &&
    `
       top: -20px;
       right: 0;
       position: absolute;
       margin-right: 0;
  `};

  ${({ buttonsSize }) =>
    buttonsSize >= 3 &&
    `
     @media (min-width: ${LARGE_SCREENS.max}px) and (max-width: 2060px) {
        gap: 3px;
        font-size: 13px !important;
        margin-right: 6px;
     }
  `};
`;

const LeftHolder = styled.div`
  display: flex;
  align-items: stretch;
`;

const RightHolder = styled.div`
  display: flex;
  position: ${({ mode }) => mode === small && 'relative'};
  align-items: center;
`;

const CenterHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputHolder = styled.div`
  .form-group {
    height: 100%;
  }

  & input {
    left: -2px;
    height: 100% !important;
    border: 2px solid
      ${(props) =>
        props.withValue ? '#2260c9 !important' : '#66615b !important'};
    position: relative;
    font-size: ${({ mode }) => mode === small && '11px !important'};
    max-width: ${({ mode }) =>
      [small, medium].includes(mode)
        ? `${styles[mode].inputWidth}px !important`
        : 'none'};
    background-color: ${(props) =>
      props.withValue
        ? 'rgba(176, 208, 235, 0.5) !important'
        : 'transparent !important'};
  }

  ${({ buttonsSize }) =>
    buttonsSize >= 3 &&
    `
     @media (min-width: ${LARGE_SCREENS.max}px) and (max-width: 2060px) {
        & input {
          height: 31px !important;
        }
     }
  `};

  ${({ fixedSize }) =>
    fixedSize &&
    `
     @media (max-width: 800px) {
      & input {
        max-width: 60px !important;
      }
     }
  `};
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ButtonLeftGroup = styled((props) => <ButtonGroup {...props} />)`
  gap: 2px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .btn-group {
    height: 100%;

    button {
      height: 100%;
    }
  }
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ButtonCenterGroup = styled((props) => <ButtonGroup {...props} />)`
  gap: 2px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ButtonRightGroup = styled((props) => <ButtonGroup {...props} />)`
  gap: 2px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-end;

  .dropdown {
    height: 100%;

    button {
      height: 100%;
    }
  }
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const FilterIcon = styled((props) => <FaFilter {...props} />)`
  font-size: 11px;
  margin-right: 3px;
  margin-bottom: -1px;
`;

const RelativeButton = styled(Button)`
  position: relative;
`;
