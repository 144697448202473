import { t } from '@lingui/macro';

/**
 * Income Types Specification
 */
export const columns = (accumulated) => [
  ...(accumulated
    ? [
        {
          id: 1,
          label: t`Period`,
          value: 'period',
          sortable: false,
          className: '',
          enabled: true,
        },
      ]
    : []),
  {
    id: 2,
    label: t`Income`,
    value: 'income',
    sortable: false,
    className: '',
    enabled: true,
  },
  {
    id: 3,
    label: t`Description`,
    value: 'description',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 4,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 5,
    label: t`Count`,
    value: 'count',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 6,
    label: t`With Social Security`,
    value: 'withSS',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 7,
    label: t`Withholding Tax`,
    value: 'withhtax',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 8,
    label: t`Percentage`,
    value: '',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 9,
    label: t`Calculated Social Security`,
    value: 'calculatedSS',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
