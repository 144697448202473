import { t } from '@lingui/macro';

export const columns = (accumulated) => [
  {
    width: 60,
    Header: t`Zone`,
    accessor: 'zone',
    className: '',
  },
  ...(accumulated
    ? [
        {
          width: 60,
          Header: t`Period`,
          accessor: 'period',
          className: '',
        },
      ]
    : []),
  {
    width: 100,
    Header: t`Name`,
    accessor: 'name',
    className: '',
  },
  {
    width: 200,
    Header: t`Type`,
    accessor: 'type',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 80,
    Header: t`Percentage`,
    accessor: 'percentage',
    className: 'flex-right',
  },
  {
    width: 100,
    Header: t`Social Security Amount`,
    sortType: 'basic',
    accessor: 'ssAmount',
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
