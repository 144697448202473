import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { Row, Col, ButtonGroup, Modal } from 'react-bootstrap';

import {
  Label,
  Field,
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
} from 'components';

import { required, notZero, integer, minInt } from 'services/validation';
import { formatNumber } from 'services/helpers';
import {
  months,
  assetTypes,
  periodsToDepreciate,
  depreciationFrequency,
  depreciationEventTypes,
} from 'services/shapes';

const todayDate = new Date().toISOString().slice(0, 10);

function AddEditAssetModal(props) {
  const {
    item,
    onSubmit,
    closeModal,
    assetCategories,
    assetTransactions,
    fetchTransactions,
    depreciationEvents,
    addDepreciationEvent,
    removeDepreciationEvent,
    handleDepreciationEventChange,
  } = props;
  const [filledTransactionData, setFieldTransactionData] = useState(false);

  const defaultValues = useMemo(() => {
    const fields = {
      lineIds: item.lineIds || '',
      assetType: item.assetType || assetTypes()[1].id,
      assetName: item.assetName || '',
      baseAmount: item.baseAmount || '',
      percentage: item.percentage || 0,
      dateOfPurchase: item.dateOfPurchase || todayDate,
      assetCategoryId: item.assetCategoryId || '',
      terminationLimit: item.terminationLimit,
      periodsToDepreciate: item.periodsToDepreciate ?? '',
      depreciationFrequency: item.depreciationFrequency || '',
      dateOfDepreciationStart: item.dateOfDepreciationStart || todayDate,
      periodsToDepreciateSelect: '',
    };

    if (fields.periodsToDepreciate || fields.periodsToDepreciate === 0) {
      const periodsToDepreciateSelect = periodsToDepreciate().find(
        (el) => el.id === fields.periodsToDepreciate.toString(),
      );
      fields.periodsToDepreciateSelect = periodsToDepreciateSelect?.id || '0';
    }

    return fields;
  }, [JSON.stringify(item)]);

  const {
    Form,
    setFieldValue,
    meta: { canSubmit, isSubmitting },
    values: currentValues,
    setFieldMeta,
  } = useForm({
    onSubmit,
    defaultValues,
  });
  const addTitle = t`Add Asset`;
  const editTitle = t`Edit Asset`;

  let assetCategoriesOptions;
  if (!isEmpty(assetCategories)) {
    assetCategoriesOptions = assetCategories.map((el) => (
      <option
        key={`accountNumber-${el.assetCategoryId}`}
        value={el.assetCategoryId}
      >
        {el.assetCategoryName}
      </option>
    ));
  }
  let assetTransactionsOptions = [];
  if (!isEmpty(assetTransactions)) {
    assetTransactionsOptions = assetTransactions.map((el) => ({
      value: el.id,
      label: `${el.transactionId} | ${el.postedDate} | ${
        formatNumber(el.amount).formatted
      } | ${el.description}`,
    }));
  }

  const handleAssetCategoryChange = (el) => {
    const id = el.target.value;
    const selectedItem = assetCategories.find(
      (elItem) => elItem.assetCategoryId === +id,
    );
    setFieldValue('percentage', selectedItem.defaultPercentage);
    setFieldValue('terminationLimit', selectedItem.defaultTerminationLimit);
    setFieldValue(
      'depreciationFrequency',
      selectedItem.defaultDepreciationFrequency,
    );
    setFieldValue('lineIds', '');
    setFieldValue(
      'periodsToDepreciate',
      selectedItem.defaultPeriodsToDepreciate,
    );

    if (
      periodsToDepreciate().find(
        (i) => i.id === selectedItem.defaultPeriodsToDepreciate.toString(),
      )
    ) {
      setFieldValue(
        'periodsToDepreciateSelect',
        selectedItem.defaultPeriodsToDepreciate,
      );
      setFieldMeta('periodsToDepreciate', { error: null });
    } else {
      setFieldValue('periodsToDepreciateSelect', '0');
      setFieldMeta('periodsToDepreciateSelect', { error: null });
    }

    fetchTransactions(id);
  };
  const handleDateOfPurchaseChange = (el) => {
    setFieldValue('dateOfDepreciationStart', el.currentTarget.value);
  };
  const typeOptions = [
    assetTypes().map((el) => (
      <option key={el.id} value={el.id}>
        {el.label}
      </option>
    )),
  ];

  const monthEventTypesOptions = [
    <option key="selectOptionDefaultMonthEventTypes" value="" disabled>
      {t`Select Month Event Types`}
    </option>,
    months().map((el) => (
      <option key={el.id} value={el.id}>
        {el.label}
      </option>
    )),
  ];

  const depreciationEventTypesOptions = [
    <option key="selectOptionDefaultDepreciationEventTypes" value="" disabled>
      {t`Select Depreciation Event Types`}
    </option>,
    depreciationEventTypes().map((el) => (
      <option key={el.id} value={el.id}>
        {el.label}
      </option>
    )),
  ];
  const frequencyOptions = [
    <option key="selectOptionDefaultDepreciationFrequency" value="" disabled>
      {t`Select Depreciation Frequency`}
    </option>,
    depreciationFrequency().map((el) => (
      <option key={el.id} value={el.id}>
        {el.label}
      </option>
    )),
  ];

  const renderDepreciationItem = (el, index) => {
    const handleChange = (e) => handleDepreciationEventChange(e, index);

    return (
      <DepreciationItem key={`${index}-depreciation-balance`}>
        <FieldHolder>
          <Field
            noBorder
            size="sm"
            name="amount"
            type="number"
            label={t`Amount`}
            value={el.amount}
            onChange={handleChange}
            placeholder={t`Please enter amount`}
          />
        </FieldHolder>
        <FieldHolder>
          <Field
            noBorder
            size="sm"
            name="month"
            type="select"
            value={el.month}
            label={t`Month`}
            onChange={handleChange}
          >
            {monthEventTypesOptions}
          </Field>
        </FieldHolder>
        <FieldHolder>
          <Field
            noBorder
            noFormat
            size="sm"
            name="year"
            type="number"
            label={t`Year`}
            value={el.year}
            onChange={handleChange}
            placeholder={t`Please enter Year`}
          />
        </FieldHolder>
        <FieldHolder>
          <Field
            noBorder
            size="sm"
            name="depreciationEventType"
            type="select"
            value={el.depreciationEventType}
            label={t`Event Type`}
            onChange={handleChange}
          >
            {depreciationEventTypesOptions}
          </Field>
        </FieldHolder>
        <DepreciationItemRemoveButton
          onClick={() => removeDepreciationEvent(index)}
        >
          <ButtonIcon as={BiTrash} />
        </DepreciationItemRemoveButton>
      </DepreciationItem>
    );
  };

  const periodsToDepreciateSelectOptions = periodsToDepreciate().map((el) => (
    <option key={`periodsToDepreciate-${el.id}`} value={el.id}>
      {el.label}
    </option>
  ));
  const handlePeriodsToDepreciateSelectChange = (e) => {
    setFieldValue('periodsToDepreciate', e.target.value);
    if (e.target.value === '0') {
      setFieldMeta('periodsToDepreciateSelect', { error: null });
    }
  };

  const handleLineIdsChange = (e) => {
    if (!e.target.value.length) {
      setFieldValue('lineIds', '');
      setFieldTransactionData(false);
    } else if (!filledTransactionData) {
      const transaction = assetTransactions.filter(
        (i) => i.id === e.target.value[0],
      )?.[0];

      setFieldValue('baseAmount', transaction?.amount);
      setFieldValue('assetName', transaction?.description);
      setFieldValue('dateOfPurchase', transaction?.postedDate);
      setFieldValue('dateOfDepreciationStart', transaction?.postedDate);

      setFieldTransactionData(true);
    }
  };

  const handleAssetTypeChange = (e) => {
    if (e.target.value === 'BALANCE') {
      setFieldMeta('periodsToDepreciate', { error: null });
      setFieldMeta('periodsToDepreciateSelect', { error: null });
      setFieldMeta('depreciationFrequency', { error: null });
    } else {
      setFieldMeta('percentage', { error: null });
      setFieldMeta('terminationLimit', { error: null });
    }
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="lg">
      <Form>
        <Modal.Header>
          <Heading level={3}>{item.assetId ? editTitle : addTitle}</Heading>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <ReactFormField
                noBorder
                size="small"
                name="assetName"
                label={t`Name`}
                validate={[required]}
                placeholder={t`Please enter name`}
              />
              <ReactFormField
                noBorder
                name="assetType"
                size="small"
                type="select"
                label={t`Type`}
                onChange={handleAssetTypeChange}
                validate={[required]}
              >
                {typeOptions}
              </ReactFormField>
              <ReactFormField
                noBorder
                name="assetCategoryId"
                size="small"
                type="select"
                label={t`Asset Category`}
                onChange={handleAssetCategoryChange}
                validate={[required]}
              >
                <option
                  key="selectOptionDefaultAssetCategory"
                  value=""
                  disabled
                >
                  {t`Please Select Asset Category`}
                </option>
                {assetCategoriesOptions}
              </ReactFormField>
              {currentValues.assetType === 'BALANCE' ? (
                <>
                  <ReactFormField
                    noBorder
                    size="small"
                    type="number"
                    name="terminationLimit"
                    label={t`Termination Limit`}
                    validate={[minInt(15000), required, integer]}
                    placeholder={t`Please enter termination limit`}
                  />
                  <ReactFormField
                    noBorder
                    size="small"
                    type="number"
                    name="percentage"
                    label={t`Percentage %`}
                    validate={[required, integer]}
                    placeholder={t`Please enter percentage`}
                  />
                </>
              ) : (
                <>
                  <ReactFormField
                    noBorder
                    size="sm"
                    name="depreciationFrequency"
                    type="select"
                    label={t`Depreciation frequency`}
                    validate={[required]}
                  >
                    {frequencyOptions}
                  </ReactFormField>
                  {currentValues.periodsToDepreciateSelect === '0' ? (
                    <ReactFormField
                      noBorder
                      noFormat
                      size="small"
                      type="number"
                      name="periodsToDepreciate"
                      label={t`Periods to depreciate`}
                      validate={[required, notZero]}
                      placeholder={t`Please enter periods to depreciate`}
                    />
                  ) : (
                    <ReactFormField
                      noBorder
                      name="periodsToDepreciateSelect"
                      size="small"
                      type="select"
                      label={t`Periods to depreciate`}
                      onChange={handlePeriodsToDepreciateSelectChange}
                      validate={[required]}
                    >
                      <option value="" disabled>
                        {t`Please Select Periods to depreciate`}
                      </option>
                      {periodsToDepreciateSelectOptions}
                    </ReactFormField>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <ReactFormField
                noBorder
                size="small"
                type="number"
                name="baseAmount"
                label={t`Base Amount`}
                validate={[required]}
                placeholder={t`Please enter base amount`}
              />
              <ReactFormField
                noBorder
                size="sm"
                name="dateOfPurchase"
                type="date"
                range={30}
                label={t`Date of purchase`}
                validate={[required]}
                onChange={handleDateOfPurchaseChange}
                placeholder={t`Please enter date of purchase`}
              />
              <ReactFormField
                noBorder
                size="sm"
                name="dateOfDepreciationStart"
                type="date"
                range={30}
                label={t`Date of depreciation start`}
                validate={[required]}
                placeholder={t`Please enter date of depreciation start`}
              />
              <ReactFormField
                noBorder
                multiple
                name="lineIds"
                size="small"
                type="autoSuggest"
                onChange={handleLineIdsChange}
                label={t`Please select Asset Transaction`}
                options={assetTransactionsOptions}
              />
            </Col>
            <Col md="12">
              <DepreciationsWrapper>
                <Label>{t`Events`}</Label>
                {depreciationEvents.map(renderDepreciationItem)}
              </DepreciationsWrapper>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonWrapper>
            <ButtonGroup bsSize="small">
              <Button
                fill
                magnify
                kind="success"
                onClick={addDepreciationEvent}
              >
                <ButtonIcon as={HiPlus} />
                {` `}
                <Trans>Add Event</Trans>
              </Button>
            </ButtonGroup>
            <ButtonGroup bsSize="small">
              <Button
                fill
                magnify
                kind="info"
                type="submit"
                disabled={!canSubmit}
              >
                {isSubmitting ? (
                  <Spinner type="white" size="md" />
                ) : (
                  <>
                    <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                  </>
                )}
              </Button>
              <Button fill kind="danger" magnify onClick={closeModal}>
                <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
              </Button>
            </ButtonGroup>
          </ButtonWrapper>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditAssetModal;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DepreciationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DepreciationItem = styled.div`
  margin: 10px 0;
  display: flex;
  position: relative;
  flex-direction: row;
`;

const FieldHolder = styled.div`
  width: 23%;
  margin: 0 5px;
`;

const DepreciationItemRemoveButton = styled.div`
  top: 40%;
  right: 0;
  cursor: pointer;
  position: absolute;
  transform: translate(0, 50%);
`;
