import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Label,
  Button,
  Heading,
  Spinner,
  Paragraph,
  ButtonIcon,
  CustomMultiSelectInput,
  Field,
} from 'components';

function AddEditVATChildCompany(props) {
  const {
    dates,
    onSubmit,
    editMode,
    companies,
    closeModal,
    submitting,
    currentItem,
  } = props;
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [dateRanges, setDateRanges] = useState(dates);

  const hasEmptyDateRange = useMemo(() => {
    if (Array.isArray(dateRanges)) {
      return dateRanges.some((range) => !range.fromDate || !range.toDate);
    }

    return false;
  });

  const disabled = useMemo(() => {
    if (editMode) return hasEmptyDateRange;

    return !selectedCompany.length || hasEmptyDateRange;
  }, [editMode, selectedCompany, hasEmptyDateRange]);

  const handleChange = (values) => {
    setSelectedCompany(values);
  };

  const removeDateRange = (id) => {
    setDateRanges(dateRanges.filter((range) => range.id !== id));
  };

  const addRange = () => {
    setDateRanges((prevState) => [
      ...prevState,
      {
        id: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(2, 10),
        fromDate: null,
        toDate: null,
      },
    ]);
  };

  const onChange = (e, id) => {
    setDateRanges((prevState) =>
      prevState.map((range) => {
        if (range.id === id) {
          return {
            ...range,
            [e.target.id]: e.target.value || null,
          };
        }

        return range;
      }),
    );
  };

  const handleSubmit = () => {
    onSubmit(selectedCompany?.[0]?.id, dateRanges);
  };

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Modal.Header>
        <Heading level={3}>
          {editMode ? (
            <Trans>{currentItem.name} - Edit date ranges</Trans>
          ) : (
            <Trans>Add joint registered company</Trans>
          )}
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {!editMode && (
          <InputHolder>
            <CustomMultiSelectInput
              modal
              single
              id="company"
              label={t`Company`}
              options={companies}
              onSelect={handleChange}
              selected={selectedCompany}
              placeholder={t`Please Select Company`}
            />
          </InputHolder>
        )}
        <Header>
          <Label>
            <Trans>Date Ranges</Trans>
          </Label>
          <ButtonGroup bsSize="small">
            <Button fill kind="success" magnify onClick={addRange}>
              <Trans>+ Add range</Trans>
            </Button>
          </ButtonGroup>
        </Header>
        {dateRanges.map((range) => (
          <RowHolder key={range.id}>
            <DateHolder>
              <Field
                id="fromDate"
                size="sm"
                type="date"
                label={t`From:`}
                value={range.fromDate}
                onChange={(e) => onChange(e, range.id)}
              />
            </DateHolder>
            <DateHolder>
              <Field
                id="toDate"
                size="sm"
                type="date"
                label={t`To:`}
                value={range.toDate}
                min={range.fromDate}
                onChange={(e) => onChange(e, range.id)}
              />
            </DateHolder>
            <IconHolder onClick={() => removeDateRange(range.id)}>
              <BiTrash />
            </IconHolder>
          </RowHolder>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            magnify
            kind="info"
            type="submit"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {submitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
              </>
            )}
          </Button>
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
        {hasEmptyDateRange && (
          <Paragraph kind="danger" style={{ marginTop: 10 }}>
            <Trans>All ranges should have start and end date</Trans>
          </Paragraph>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditVATChildCompany;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const RowHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 15px;
  margin-bottom: 10px;

  label {
    font-size: 13px;
  }
`;

const DateHolder = styled.div`
  width: 47%;
`;

const IconHolder = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  cursor: pointer;
`;
