import React from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { defineRoles } from 'services/helpers';
import {
  AssociatedUserGroupsColumns,
  AvailableUserCompaniesColumns,
} from 'services/tableshapes';
import {
  availableUserCompaniesFilters,
  associatedUserGroupsFilters,
} from 'services/tableFilterShapes';

import { DESKTOP, TABLET } from 'constants/mediaSizes';

function UserPreviewModal(props) {
  const { data, search, loading, closeModal, handleAction } = props;

  const userData = (
    <>
      <Heading level={5}>
        <Trans>Role:</Trans> <strong>{defineRoles([data.role])}</strong>
      </Heading>
      <Heading level={5}>
        <Trans>Name:</Trans> <strong>{data.name}</strong>
      </Heading>
      <Heading level={5}>
        <Trans>Username:</Trans> <strong>{data.username}</strong>
      </Heading>
    </>
  );

  const content = (
    <>
      <Heading strong level={4} alignment="center">
        <Trans>Associated Company Groups</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: data.associatedCompanyGroups,
          loading,
          columns: AssociatedUserGroupsColumns(search),
          tableId: 'associatedCompanyGroupsUserModal',
          loadingId: 'groupId',
          headerConfigs: {
            exportFileName: `${data?.name} - ${t`Associated Company Groups`}`,
          },
          handleAction: (type, row) =>
            handleAction(type, row, 'editCompanyGroup'),
          withRowClick: true,
          filterConfigs: associatedUserGroupsFilters(),
          reportMode: search.reportMode,
          isModal: true,
        }}
      />

      <Heading strong level={4} alignment="center">
        <Trans>Associated User Groups</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: data.associatedUserGroups,
          loading,
          columns: AssociatedUserGroupsColumns(search),
          tableId: 'associatedUserGroupsUserModal',
          loadingId: 'groupId',
          headerConfigs: {
            exportFileName: `${data?.name} - ${t`Associated User Groups`}`,
          },
          handleAction: (type, row) => handleAction(type, row, 'editUserGroup'),
          withRowClick: true,
          filterConfigs: associatedUserGroupsFilters(),
          reportMode: search.reportMode,
          isModal: true,
        }}
      />

      <Heading strong level={4} alignment="center">
        <Trans>Available Companies</Trans>
      </Heading>
      <CustomTableWithAutoSettings
        {...{
          data: data.availableCompanies,
          loading,
          columns: AvailableUserCompaniesColumns(search),
          tableId: 'availableCompaniesUserModal',
          loadingId: 'companyId',
          headerConfigs: {
            exportFileName: `${data?.name} - ${t`Available Companies`}`,
          },
          handleAction: (type, row) =>
            handleAction(type, row, 'companyPreview'),
          withRowClick: true,
          filterConfigs: availableUserCompaniesFilters(),
          reportMode: search.reportMode,
          isModal: true,
        }}
      />
    </>
  );

  return (
    <Modal show bsSize="md" onEscapeKeyDown={closeModal}>
      <BootstrapModal.Header>
        <Heading level={3}>
          <Trans>User Detailed</Trans>
        </Heading>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {userData}
        {content}
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button fill kind="danger" magnify onClick={closeModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}

export default UserPreviewModal;

UserPreviewModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  search: PropTypes.shape({}),
  loading: PropTypes.bool,
  closeModal: PropTypes.func,
  handleAction: PropTypes.func,
};

UserPreviewModal.defaultProps = {
  data: [],
  search: {},
  loading: true,
  closeModal: () => {},
  handleAction: () => {},
};

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1000px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
