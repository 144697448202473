import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { getCountryListDetailsApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { registrationRequest, resourceListReadRequest } from 'store/actions';

import { RegistrationForm } from 'components';

function RegistrationFormContainer() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const dispatch = useDispatch();

  const handleRegisterSubmit = async (values) => {
    setLoading(true);

    try {
      await dispatch(registrationRequest({ ...values, isCompany: true }));

      setSubmitted(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(getErrorMessage(e));
    }
  };
  const fetchData = async () => {
    try {
      const data = await dispatch(
        resourceListReadRequest(getCountryListDetailsApi),
      );
      setCountryList(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <RegistrationForm
      loading={loading}
      onSubmit={handleRegisterSubmit}
      submitted={submitted}
      countryList={countryList}
    />
  );
}

export default RegistrationFormContainer;
