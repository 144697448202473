import { initialState } from './selectors';
import {
  DATA_UPDATE_TIME,
  COMPANY_INFO_SUCCESS,
  COMPANY_RELOAD_SUCCESS,
} from './actions';
import { CLEAR_STORE } from '../root/actions';

/* eslint-disable default-param-last */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_INFO_SUCCESS:
    case COMPANY_RELOAD_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case DATA_UPDATE_TIME:
      return {
        ...state,
        dataUpdateTime: payload,
      };
    case CLEAR_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
