import React, { Component } from 'react';
import _unique from 'lodash-es/uniq';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fulfilled, pending, rejected } from 'redux-saga-thunk';

import TableHeader from 'containers/TableHeader';
import { ReconciledListPreviewModal } from 'components';

import { autoReconcileColumns } from 'services/tableshapes';
import { fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  setTotalAutoGLReconcileApi,
  getTotalAutoGLReconcileApi,
} from 'services/apihelpers';

import { trackEvent } from '../components/Analytics';

class ReconciledListPreviewListModalContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
      currentWorkingPeriodEnd: PropTypes.number.isRequired,
    }).isRequired,
    data: PropTypes.shape({}),
    failed: PropTypes.bool,
    loading: PropTypes.bool,
    submitted: PropTypes.bool,
    showModal: PropTypes.bool.isRequired,
    submitting: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,

    /** @type {function} totalAutoGLReconcile */
    totalAutoGLReconcile: PropTypes.func.isRequired,
    onReconcileClose: PropTypes.func.isRequired,
    account: PropTypes.string,
  };

  static defaultProps = {
    data: undefined,
    failed: false,
    account: undefined,
    loading: false,
    submitted: false,
    submitting: false,
  };

  state = {
    hiddenItems: [],
    expandAllRows: true,
    deselectedItems: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    const { submitted, onReconcileClose } = this.props;

    if (submitted) {
      onReconcileClose();
    }
  }

  fetchData = () => {
    const {
      account,
      getTotalAutoGLReconcile,
      match: { params },
    } = this.props;

    const query = {
      account,
      uuid: params.companyId,
      year: params.accountingYear,
      period: params.accountingPeriod,
    };

    getTotalAutoGLReconcile(query);
  };

  handleAutoReconcile = async () => {
    const { deselectedItems } = this.state;
    const { data, company, account, toggleModal, setTotalAutoGLReconcile } =
      this.props;

    try {
      await setTotalAutoGLReconcile({
        ...data,
        deselectedItems: deselectedItems.filter((el) => Number.isInteger(el)),
      });

      trackEvent({
        category: 'Company',
        label: 'Balance reconciliation',
        action: `User auto reconcilied GL account ${account}`,
        value: company.currentCompanySID,
      });

      toggleModal('handleAutoReconcile');
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  handleReconcileChange = (e) => {
    const {
      target: { name, value, checked },
    } = e;
    const { data } = this.props;
    const { deselectedItems } = this.state;
    let newDeselectedItems = [...deselectedItems];

    if (name === 'reconcile-parent') {
      const children = data.suggestions
        .find((item) => item[0]?.sourceDocumentId === value)
        .map((item) => item.lineId);

      if (checked) {
        newDeselectedItems = newDeselectedItems.filter(
          (el) => el !== value && !children.includes(el),
        );
      } else {
        newDeselectedItems.push(value);
        newDeselectedItems.push(...children);
        newDeselectedItems = _unique(newDeselectedItems);
      }
    } else if (checked) {
      newDeselectedItems = newDeselectedItems.filter((el) => el !== +value);
    } else {
      newDeselectedItems.push(+value);
    }

    this.setState({ deselectedItems: newDeselectedItems });
  };

  toggleOpenedItems = (id) => {
    const { hiddenItems: prevHiddenItems } = this.state;
    let hiddenItems = [...prevHiddenItems];

    if (hiddenItems.includes(id)) {
      hiddenItems = hiddenItems.filter((el) => el !== id);
    } else {
      hiddenItems.push(id);
    }

    this.setState({ hiddenItems, expandAllRows: false });
  };

  handleExpandAllRows = () => {
    const { data } = this.props;

    this.setState((state) => {
      const expandAllRows = !state.expandAllRows;
      let hiddenItems = [];

      if (!expandAllRows) {
        hiddenItems = data.suggestions.map(
          (items) => items[0]?.sourceDocumentId,
        );
      }

      return { hiddenItems, expandAllRows };
    });
  };

  render() {
    const {
      data,
      failed,
      loading,
      account,
      roleType,
      submitted,
      showModal,
      submitting,
      toggleModal,
    } = this.props;

    return (
      <ReconciledListPreviewModal
        {...{
          ...this.state,
          data,
          failed,
          loading,
          account,
          roleType,
          showModal,
          submitted,
          submitting,
          toggleModal,
          header: (
            <TableHeader
              {...{
                columns: autoReconcileColumns,
                tableName: 'autoReconcile',
              }}
            />
          ),
          toggleOpenedItems: this.toggleOpenedItems,
          handleExpandAllRows: this.handleExpandAllRows,
          handleAutoReconcile: this.handleAutoReconcile,
          handleReconcileChange: this.handleReconcileChange,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  data: fromResource.getList(state, getTotalAutoGLReconcileApi),
  company: fromCompany.getCompany(state),
  failed: rejected(state, `${getTotalAutoGLReconcileApi}ListRead`),
  loading: pending(state, `${getTotalAutoGLReconcileApi}ListRead`),
  submitting: pending(state, `${setTotalAutoGLReconcileApi}Create`),
  submitted: fulfilled(state, `${setTotalAutoGLReconcileApi}Create`),
});

const mapDispatchToProps = (dispatch) => ({
  getTotalAutoGLReconcile: (query) =>
    dispatch(resourceListReadRequest(getTotalAutoGLReconcileApi, query)),
  setTotalAutoGLReconcile: (query) =>
    dispatch(resourceCreateRequest(setTotalAutoGLReconcileApi, query)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ReconciledListPreviewListModalContainer);
