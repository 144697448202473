import React from 'react';
import { ButtonIcon } from 'components';
import { t } from '@lingui/macro';

import { FaHistory } from '@react-icons/all-files/fa/FaHistory';
import { FaRegFilePdf } from '@react-icons/all-files/fa/FaRegFilePdf';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { GiSightDisabled } from '@react-icons/all-files/gi/GiSightDisabled';
import { BiCommentDetail } from '@react-icons/all-files/bi/BiCommentDetail';
import { MdOutlineFileCopy } from '@react-icons/all-files/md/MdOutlineFileCopy';

import {
  defineTemplates,
  definePeriodTypes,
  sortBooleanCustomTable,
} from 'services/helpers';

/**
 * Account Reconciliation Grid
 */
export const columns = (isReadOnly) => [
  ...(!isReadOnly
    ? [
        {
          width: 70,
          accessor: 'selection',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'selection',
            isVisible: (row) =>
              !['PAYROLL', 'VAT', 'ASSET'].includes(row.accountTemplateCode) &&
              row.activeRecord,
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 70,
    Header: t`ID`,
    accessor: 'accountId',
    className: 'flex-center',
  },
  {
    width: 340,
    Header: t`Description`,
    accessor: 'name',
    className: '',
    withTooltip: true,
  },
  {
    width: 150,
    Header: t`Year Opening Balance`,
    sortType: 'basic',
    accessor: 'openingBalance',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
      icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
      action: 'balanceRegistration',
      disableClick: true,
    },
  },
  {
    width: 130,
    Header: t`Period Opening Balance`,
    sortType: 'basic',
    accessor: 'ingoingBalance',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Period Movement`,
    accessor: 'movement',
    sortType: 'basic',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Period Closing Balance`,
    accessor: 'outgoingBalance',
    sortType: 'basic',
    className: 'flex-right',
    disableSortBy: true,
    withTotal: true,
    withSelectedRowsTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 150,
    hidden: true,
    Header: t`Template`,
    accessor: 'accountTemplateCode',
    className: '',
    disableSortBy: true,
    withTooltip: true,
    cell: {
      converter: defineTemplates,
    },
  },
  {
    width: 150,
    hidden: true,
    Header: t`Frequency`,
    accessor: 'periodType',
    className: '',
    disableSortBy: true,
    withTooltip: true,
    cell: {
      converter: definePeriodTypes,
    },
  },
  {
    width: 150,
    hidden: true,
    Header: t`Assignee`,
    accessor: 'accountHolder',
    className: '',
    withTooltip: true,
  },
  {
    width: 250,
    Header: t`Period Comment`,
    accessor: 'periodComment',
    className: 'flex-left',
    cell: {
      type: 'textarea',
      editable: (item) => item.activeRecord && !isReadOnly,
      placeholder: t`Add period comment:`,
      disableClick: true,
      actions: [
        {
          id: 'history',
          icon: () => <ButtonIcon as={FaHistory} type="editableAction" />,
          tooltip: t`History`,
          visible: true,
        },
      ],
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 95,
          Header: t`Status`,
          accessor: 'approved',
          sortType: sortBooleanCustomTable,
          className: 'flex-center',
          cell: {
            type: 'arrayOfBoolean',
            withTooltip: true,
            hidden: (row) => !row.activeRecord,
            booleans: [
              {
                id: 'approved',
                tooltip: 'approvedTooltip',
              },
              {
                id: 'controlled',
                tooltip: 'controlledTooltip',
                displayIfTrue: true,
              },
            ],
          },
        },
        {
          width: 250,
          Header: t`Status Comment`,
          accessor: 'comment',
          className: 'flex-left',
          disableSortBy: true,
          cell: {
            type: 'textarea',
            editable: (item) => item.activeRecord && !isReadOnly,
            placeholder: t`Add comment:`,
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 150,
    Header: t`Number of Files on Transactions`,
    accessor: 'childFiles',
    cell: {
      tooltip: (row) => {
        if (!row?.childFileNames?.length) {
          return false;
        }

        return (
          <div style={{ width: '100%' }}>
            {row?.childFileNames?.slice(0, 5)?.map((item, i) => (
              <React.Fragment key={i}>
                {item} <br />
              </React.Fragment>
            ))}
            {row?.childFileNames?.length > 5 && '...'}
          </div>
        );
      },
    },
    className: 'flex-right',
  },
  {
    width: 140,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'uncontrol',
          icon: () => <GiSightDisabled />,
          tooltip: t`Uncontrol`,
          visible: (item) =>
            item?.controlled &&
            item.activeRecord &&
            item?.enabledForUncontrol?.length > 0,
        },
        {
          id: 'dialog',
          icon: () => <BiCommentDetail />,
          tooltip: t`Open dialogue`,
          accessor: 'hasDialogue',
          visible: (item) => item.activeRecord,
        },
        {
          id: 'files',
          visible: (item) => item.activeRecord,
          icon: () => <MdOutlineFileCopy />,
          badge: (item) => {
            if (item.accountFileNames?.length) {
              return item.accountFileNames.length;
            }

            return false;
          },
          tooltip: (row) => {
            if (row?.accountFileNames?.length) {
              const slicedArray = row.accountFileNames.slice(0, 5);

              return slicedArray?.map((item, i) =>
                i < slicedArray.length - 1 ? (
                  <React.Fragment key={i}>
                    {item} <br />
                  </React.Fragment>
                ) : (
                  item
                ),
              );
            }
            return t`Attach files`;
          },
          accessor: 'hasFile',
        },
        {
          id: 'downloadInPDF',
          icon: () => <FaRegFilePdf />,
          tooltip: t`Download in PDF`,
          options: [
            {
              id: 'currentVersion',
              label: (row) => {
                const version =
                  typeof row.reports === 'object' && row.reports?.length
                    ? row.reports.reduce((acc, report) => {
                        // If there's no object for this account or the current report version is higher than the stored one
                        if (!acc || acc.version < report.version) {
                          return report; // replace the object
                        }
                        return acc;
                      }, null)
                    : {};

                return `${t`Current version`}: ${version?.version || ''}`;
              },
              textOnly: true,
              visible: (row) =>
                typeof row.reports === 'object' && row.reports?.length,
            },
            {
              id: 'generatedBy',
              label: (row) => {
                const version =
                  typeof row.reports === 'object' && row.reports.length
                    ? row.reports.reduce((acc, report) => {
                        // If there's no object for this account or the current report version is higher than the stored one
                        if (!acc || acc.version < report.version) {
                          return report; // replace the object
                        }
                        return acc;
                      }, null)
                    : {};

                return `${t`By`}: ${version?.createdBy || ''}`;
              },
              textOnly: true,
              visible: (row) =>
                typeof row.reports === 'object' && row.reports?.length,
            },
            {
              id: 'downloadLastReport',
              label: t`Download last report`,
              visible: (row) =>
                typeof row.reports === 'object' && row.reports?.length,
            },
            {
              id: 'downloadInPDF',
              label: t`Generate new PDF report`,
              visible: (row) => !row.reports?.length,
            },
            {
              id: 'singleAccountReportVersions',
              label: t`Show report versions`,
              visible: (row) =>
                typeof row.reports === 'object' && row.reports?.length,
            },
          ],
          visible: (item) =>
            (typeof item.reports === 'object' && item.reports?.length) ||
            (item.approved &&
              item.activeRecord &&
              ['GENERAL', 'BANK', 'SIMPLE_LEDGER'].includes(
                item.accountTemplateCode,
              )),
        },
      ],
    },
  },
];

export default columns;
