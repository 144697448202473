import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t } from '@lingui/macro';
import { Col, Row } from 'react-bootstrap';

import { useCurrentWidth } from 'services/hooks';
import { dashboardGroupsColumns } from 'services/tableshapes';
import { detectTopContentModeWhen2Tables } from 'services/helpers';
import { dashboardGroupsFilters } from 'services/tableFilterShapes';
import {
  Card,
  CustomMultiSelectInput,
  CustomTableWithAutoSettings,
} from 'components';

export function DashboardGroups(props) {
  const {
    search,
    companies,
    // loadingUsers,
    handleChange,
    loadingGroups,
    selectedGroups,
    loadingCompanies,
    // availableCompanies,
    availableGroupsToSelect,
    // loadingAvailableCompanies,
  } = props;

  const screenWidth = useCurrentWidth();

  const fixedSize = useMemo(
    () => detectTopContentModeWhen2Tables(screenWidth, 0),
    [screenWidth],
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'name',
        desc: false,
      },
    ],
    [],
  );
  // const showUsers =
  //   !!selectedGroups.length && !_isEmpty(users) && Array.isArray(users);
  const showCompanies = selectedGroups[0]?.type === 'company';
  // const showAvailableCompanies = selectedGroups[0]?.type === 'company';
  // const renderUserItem = (item, index) => {
  //   const percent = +(
  //     (item.approvedAccounts * 100) /
  //     (item.totalAccounts || 1)
  //   ).toFixed(0);
  //
  //   return (
  //     <Row key={`item-${item.uuid}-${index}`}>
  //       <Col md={11}>
  //         <div>
  //           <div>{item.name}</div>
  //           <ProgressBar striped now={percent} label={`${percent}%`} />
  //         </div>
  //       </Col>
  //       <Col md={1}>
  //         <Paragraph>
  //           {item.approvedAccounts || 0}/{item.totalAccounts || 0}
  //         </Paragraph>
  //       </Col>
  //     </Row>
  //   );
  // };

  return (
    <Card style={{ minHeight: '80vh' }}>
      <Row>
        <Col md={12}>
          <SelectHolder>
            <CustomMultiSelectInput
              single
              id="company"
              label={t`Selected group to view statistics`}
              loading={loadingGroups}
              options={availableGroupsToSelect}
              selected={selectedGroups}
              onSelect={handleChange}
              placeholder={t`Please Select Group to view statistics`}
            />
          </SelectHolder>
        </Col>
        {showCompanies && (
          <Col md={12}>
            <CustomTableWithAutoSettings
              {...{
                data: companies,
                fixedSize,
                height: 600,
                loading: loadingCompanies,
                columns: dashboardGroupsColumns(search),
                tableId: 'Companies',
                loadingId: 'name',
                tableTitle: t`Companies Progress`,
                reportMode: search.reportMode,
                filterConfigs: dashboardGroupsFilters(),
                headerConfigs: {
                  exportFileName: t`Companies Progress`,
                },
                initialState: {
                  sortBy,
                },
              }}
            />
          </Col>
        )}
        {/* {showAvailableCompanies && ( */}
        {/*   <Col md={6}> */}
        {/*     <CustomTableWithAutoSettings */}
        {/*       {...{ */}
        {/*         data: availableCompanies, */}
        {/*         fixedSize, */}
        {/*         height: 600, */}
        {/*         loading: loadingAvailableCompanies, */}
        {/*         columns: dashboardGroupsColumns(search), */}
        {/*         tableId: 'AvailableCompanies', */}
        {/*         loadingId: 'name', */}
        {/*         tableTitle: t`Available Companies Progress`, */}
        {/*         reportMode: search.reportMode, */}
        {/*         filterConfigs: dashboardGroupsFilters(), */}
        {/*         headerConfigs: { */}
        {/*           exportFileName: t`Available Companies Progress`, */}
        {/*         }, */}
        {/*         initialState: { */}
        {/*           sortBy, */}
        {/*         }, */}
        {/*       }} */}
        {/*     /> */}
        {/*   </Col> */}
        {/* )} */}
      </Row>
    </Card>
  );
}

export default DashboardGroups;

const SelectHolder = styled.div`
  width: 400px;
  margin: auto;

  div {
    z-index: 10;
  }
`;
