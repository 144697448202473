import { t } from '@lingui/macro';
import { filterByReconciledMatchedTransaction } from 'services/shapes';

export const bankTemplateFilters = () => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: ['description', 'currency'],
  },
  {
    id: 'amount',
    type: 'range',
    label: t`Amount`,
  },
  {
    id: 'postedDate',
    type: 'date',
    label: t`Bank Date`,
  },
  {
    id: 'reconciled',
    type: 'multiSelect',
    label: t`Type of transaction`,
    options: filterByReconciledMatchedTransaction(),
    disableSort: true,
    placeholder: t`Please select type of transaction`,
  },
];

export default bankTemplateFilters;
