import { t } from '@lingui/macro';

import {
  bankTemplateNewColumns,
  bankTemplateGLNewColumns,
} from 'services/tableshapes';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { months } from 'constants/shortNames';
import { bankLegends } from 'constants/legends';
import { getBankRowColor } from 'services/helpers/tables';

import {
  filterByReconciledMatchedTransaction,
  filterByTemplateStatuses,
  periodTypes,
} from 'services/shapes';
import { bankDefaultValues } from 'constants/reportSettings';
import useReportGenerator, {
  generatePDF,
} from './generator/useReportGenerator';

// eslint-disable-next-line max-len
import getBankSummary from '../../../components/molecules/AccountDrilldownInfo/getBankSummary';

const periodNames = months();

const filterRows = (rows, filters) => {
  if (rows && filters.length) {
    if (filters.length === 2) {
      return rows;
    }

    return rows.filter((item) =>
      filters.includes('MATCHED') ? item.reconciled : !item.reconciled,
    );
  }

  return [];
};

const legends = bankLegends().slice(0, 1);

export const getBankReportSettings = (props) => {
  const {
    dataGL,
    loading,
    getFiles,
    dataBank,
    glTotal,
    filters = bankDefaultValues,
    bankTotal,
    bookedBalance,
    accountInfo,
    approveStatus,
    accountingYear,
    currentAccount,
    showCurrentMonth,
    accountingPeriod,
    tableLevelApproved,
    currentPeriodType,
    saveReport,
    ...rest
  } = props;

  const { accountId, name } = currentAccount || {};

  const formattedPeriod = defineFrequencyPeriodTypes(
    currentPeriodType,
    accountingPeriod,
  );

  const periodName = `${periodNames[currentPeriodType]} ${formattedPeriod}`;

  const checkboxes =
    typeof showCurrentMonth === 'boolean'
      ? {
          [t`Show current month`]: showCurrentMonth,
        }
      : undefined;

  const filterOptions = Object.keys(filters).filter((key) => filters[key]);

  const _filters = filterByReconciledMatchedTransaction()
    .filter((item) => filterOptions.includes(item.id))
    .map((item) => item.label)
    .join(', ');

  const bankRows = filterRows(dataBank?.bankTransactions, filterOptions);
  const glRows = filterRows(dataGL?.transactions, filterOptions);

  const frequency = periodTypes().find(
    (item) => item.id === accountInfo?.periodType,
  )?.label;
  const template = filterByTemplateStatuses().find(
    (item) => item.id === accountInfo?.accountTemplateCode,
  )?.label;

  const summary = getBankSummary({
    glTotal,
    bankTotal,
    bookedBalance,
    accountingPeriod,
    outgoingBalance: accountInfo?.outgoingBalance,
  });

  const comments = [];

  if (accountInfo?.comment) {
    comments.push({
      title: t`Status Comment`,
      value: accountInfo?.comment,
    });
  }

  if (accountInfo?.periodComment) {
    comments.push({
      title: t`Period Comment`,
      value: accountInfo?.periodComment,
    });
  }

  return {
    ...rest,
    loading,
    getFiles,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      accountId,
      reportType: 'ACCOUNTS',
      saveReport: true,
    },
    fileName: `${accountId} - ${name} (${periodName}, ${accountingYear})`,
    isApproved: approveStatus,
    data: [
      [
        {
          accountInfo: true,
          headerMeta: {
            period: `${periodName}, ${accountingYear}`,
            title: ' ',
            approveStatus: approveStatus || tableLevelApproved,
          },
          values: [
            [
              {
                title: t`Template`,
                value: template,
              },
              {
                title: t`Frequency`,
                value: frequency,
              },
              {
                title: t`Assignee`,
                value: accountInfo?.accountHolder,
              },
            ],
            [
              {
                title: t`Year Opening Balance`,
                value: accountInfo?.openingBalance,
                number: true,
              },
              {
                title: t`Period Opening Balance`,
                value: accountInfo?.ingoingBalance,
                number: true,
              },
              {
                title: t`Period Movement`,
                value: accountInfo?.movement,
                number: true,
              },
              {
                title: t`Period Closing Balance`,
                value: accountInfo?.outgoingBalance,
                number: true,
              },
            ],
            [
              {
                title: t`Bank Outgoing balance`,
                value: summary?.outgoingBankValues,
                number: true,
              },
              {
                title: t`Posted in GL, not posted in Bank`,
                value: summary?.totalGL,
                number: true,
              },
              {
                title: t`Posted in Bank, not posted in GL`,
                value: summary?.totalBank,
                number: true,
              },
              {
                title: t`Deviation`,
                value: summary?.deviation,
                number: true,
              },
              {
                title: t`Unexplained Deviation`,
                value: summary?.unexplainedDeviation,
                number: true,
              },
            ],
            ...(comments.length ? [comments] : []),
          ],
        },
        {
          filters: _filters,
          period: `${periodName}, ${accountingYear}`,
          rows: glRows,
          title: t`${accountId} - ${name} (GL Transactions)`,
          tableShape: bankTemplateGLNewColumns({}),
          withTotal: true,
          legends,
          checkboxes,
          tableLevelApproved,
          getRowColor: getBankRowColor,
        },
        {
          filters: _filters,
          period: glRows?.length ? '' : `${periodName}, ${accountingYear}`,
          rows: bankRows,
          title: t`${accountId} - ${name} (Bank Transactions)`,
          tableShape: bankTemplateNewColumns({}),
          withTotal: true,
          legends,
          checkboxes,
          tableLevelApproved,
          getRowColor: getBankRowColor,
        },
      ],
    ],
  };
};

export const useBankReportGenerator = (props) =>
  useReportGenerator(getBankReportSettings(props));

export const generateBankReport = async (props) => {
  await generatePDF(getBankReportSettings(props));
};

export default useBankReportGenerator;
