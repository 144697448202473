import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useLocation, useHistory } from 'react-router-dom';

import AddEditAssetCategoryModal from 'containers/AddEditAssetCategoryModal';
import {
  Card,
  AssetReconciliationOverview,
  GenerateReportButton,
} from 'components';

import { resourceListReadSuccess } from 'store/resource/actions';
import { toAssets, toReportsAccountDetails } from 'services/routehelpers';
import { resourceDeleteRequest } from 'store/actions';
import {
  deleteAssetCategoryApi,
  getAssetReconciliationApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import CardPDFTitle from './CardPDFTitle';

export function AssetReconciliationOverviewContainer(props) {
  const {
    data,
    loading,
    Preview,
    roleType,
    disabled,
    fetchData,
    generatePDF,
    pdfGenerating,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const getSearch = () => {
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      tabEntry: search.tabEntry || 'reconciliation-overview',
      reportMode: search.reportMode || false,
    };
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const deleteAssetCategory = async (row) => {
    _setActionSubmitting(row.assetCategoryId, true);

    try {
      await dispatch(
        resourceDeleteRequest(
          `${deleteAssetCategoryApi}?${queryString.stringify({
            assetCategoryId: row.assetCategoryId,
          })}`,
        ),
      );

      const collection = [...data];
      const index = collection.findIndex(
        (el) => el.assetCategoryId === row.assetCategoryId,
      );

      if (index >= 0) {
        collection.splice(index, 1);

        dispatch(
          resourceListReadSuccess(getAssetReconciliationApi, collection),
        );
      }

      _setActionSubmitting(row.assetCategoryId, false);
      toast.success(t`Asset category deleted successfully`);
    } catch (error) {
      _setActionSubmitting(row.assetCategoryId, false);
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const handleRedirect = (type, action, item) => {
    let redirectTo;

    switch (type) {
      case 'asset': {
        redirectTo = toAssets({
          tabEntry: 'register',
          assetCategory: JSON.stringify([
            {
              id: item.assetCategoryId,
              label: item.assetCategoryName,
            },
          ]),
        });
        break;
      }
      case 'gl': {
        redirectTo = toReportsAccountDetails({
          periods: JSON.stringify(
            item.redirectPeriods.map((el) => ({
              id: el,
              label: el.toString(),
            })),
          ),
          accountId: JSON.stringify(
            item[action]?.map((el) => ({
              id: el,
              label: el.toString(),
            })),
          ),
        });
        break;
      }
      default: {
        break;
      }
    }

    if (redirectTo) {
      history.push(redirectTo);
    }
  };
  const handleAction = async (action, _props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'addCategory': {
          setModalProps({ type: 'addCategory' });
          break;
        }

        case 'edit': {
          setModalProps({ type: 'edit', item: _props });
          break;
        }

        case 'delete': {
          await deleteAssetCategory(_props);
          break;
        }

        case 'redirectAssetTotal':
        case 'redirectAssetPurchase':
        case 'redirectAssetAdjustment':
        case 'redirectAssetDepreciation': {
          handleRedirect('asset', action, _props);
          break;
        }

        case 'redirectClosingGLAccounts':
        case 'redirectPurchaseGLAccounts':
        case 'redirectAdjustmentsGLAccounts':
        case 'redirectDepreciationGLAccounts': {
          handleRedirect('gl', action, _props);
          break;
        }

        default: {
          break;
        }
      }
      if (action !== 'tableStateChange') {
        document.activeElement.blur();
      }
      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };
  const renderModal = () => {
    let content;

    switch (modalProps.type) {
      case 'edit':
      case 'addCategory': {
        content = (
          <AddEditAssetCategoryModal
            item={modalProps.item}
            closeModal={closeModal}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  const search = getSearch();
  const hiddenColumns = [];

  if (Array.isArray(data)) {
    const hideColumns = {
      glTotal: true,
      glPurchaseValue: true,
      glAdjustmentValue: true,
      glDepreciationValue: true,
    };

    data?.forEach((item) => {
      if (item.glPurchaseValue) {
        hideColumns.glPurchaseValue = false;
      }

      if (item.glDepreciationValue) {
        hideColumns.glDepreciationValue = false;
      }

      if (item.glAdjustmentValue) {
        hideColumns.glAdjustmentValue = false;
      }

      if (item.glTotal) {
        hideColumns.glTotal = false;
      }
    });

    if (hideColumns.glTotal) {
      hiddenColumns.push('glTotal', 'totalDeviation');
    }
    if (hideColumns.glPurchaseValue) {
      hiddenColumns.push('glPurchaseValue', 'purchaseValueDeviation');
    }
    if (hideColumns.glAdjustmentValue) {
      hiddenColumns.push('glAdjustmentValue', 'adjustmentValueDeviation');
    }
    if (hideColumns.glDepreciationValue) {
      hiddenColumns.push('glDepreciationValue', 'depreciationValueDeviation');
    }
  }

  return (
    <>
      {renderModal()}
      <Card
        id="pdfToPrint"
        data-testid="pdfToPrint-assetReconciliationOverview"
      >
        <CardPDFTitle
          title={
            <Header>
              <Trans>Asset Reconciliation Overview</Trans>
              <ButtonsHolder>
                <GenerateReportButton
                  disabled={disabled}
                  pdfGenerating={pdfGenerating}
                  generatePDF={generatePDF}
                  skipDownload={false}
                  saveReport={false}
                />
                {Preview()}
              </ButtonsHolder>
            </Header>
          }
        />
        <AssetReconciliationOverview
          {...{
            data,
            search,
            loading,
            roleType,
            hiddenColumns,
            actionSubmitting,
            title: t`Asset Reconciliation Overview`,
            handleAction,
          }}
        />
      </Card>
    </>
  );
}

export default AssetReconciliationOverviewContainer;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
