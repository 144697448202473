export const vatSettingsCodesFormatter = (data) => {
  const collection = [];

  if (!Array.isArray(data)) {
    return data;
  }

  data.forEach((item) => {
    if (item.history) {
      item.history.forEach((historyItem, index) => {
        collection.push({
          ...item,
          ...historyItem,
          historyIndex: index,
          originalObject: item,
        });
      });
    } else {
      collection.push(item);
    }
  });

  return collection;
};

export default vatSettingsCodesFormatter;
