import { media } from './media';

// Basic variables, theme-agnostic
const basicTheme = {
  media,
  colors: {
    white: '#FFFFFF',
    black: '#000000',
  },
};

export const lightTheme = {
  ...basicTheme,
  name: 'light',
  colorsThemed: {
    background: {
      static: '#6971fd',
    },
  },
};

export const darkTheme = {
  ...basicTheme,
  name: 'dark',
  colorsThemed: {
    background: {
      static: '#6971fd',
    },
  },
};
