import { t } from '@lingui/macro';
import _isEmpty from 'lodash-es/isEmpty';

export const a07Formatter = (data) => {
  const reportTotal = [];
  const reportInfo = [];
  const socialSecuritySpecs = [];
  const incomeTypes = [];

  if (!_isEmpty(data?.payrollReportTotals)) {
    reportTotal.push(
      {
        name: t`Social Security`,
        value: data.payrollReportTotals.socialSecurityTotal,
      },
      {
        name: t`Withheld Tax`,
        value: data.payrollReportTotals.withheldTaxTotal,
      },
      {
        name: t`Income - With Social Security`,
        value: data.payrollReportTotals.incomeWithSocialSecurityTotal,
      },
      {
        name: t`Income - Without Social Security`,
        value: data.payrollReportTotals.incomeWithoutSocialSecurityTotal,
      },
      {
        name: t`Financial Tax`,
        value: data.payrollReportTotals.financialTaxTotal,
      },
      {
        name: t`Total Person Reported`,
        value: data.payrollReportTotals.totalPersonReportedTotal,
      },
    );
  }

  if (!_isEmpty(data?.reportInfoRows)) {
    reportInfo.push(
      ...data.reportInfoRows.map((item) => ({
        period: item.period,
        sender: item.delivererName,
        id: item.altinnReferanse,
        date: item.deliveryDateTime,
      })),
    );
  }

  if (!_isEmpty(data?.altinnPayrollSocialSecurityRows)) {
    data.altinnPayrollSocialSecurityRows.forEach((item) => {
      socialSecuritySpecs.push({
        zone: item.soneSS,
        name: item.socialSecurityName,
        type: item.typeSS,
        amount: item.amountSS,
        percentage: `${item.percentageSS}%`,
        ssAmount: item.calculatedAmountSS,
        period: item.period,
      });
    });
  }

  if (!_isEmpty(data.altinnPayrollIncomeRows)) {
    data.altinnPayrollIncomeRows.forEach((item) => {
      incomeTypes.push({
        type: item.incomeType,
        description: item.description,
        amount: item.amount,
        count: item.count,
        period: item.period,
        withSocSec: item.withSocialSecurity,
        withWithholdTax: item.isWithholdingTaxDeductible,
        percentage: item.percentage,
        amountSocSec: item.calculatedSocialSecurity,
      });
    });
  }

  return { reportTotal, reportInfo, incomeTypes, socialSecuritySpecs };
};

export default a07Formatter;
