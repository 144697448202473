import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Col, Row, Form, ButtonGroup } from 'react-bootstrap';

import {
  Field,
  Button,
  Spinner,
  Heading,
  MultiSelect,
  RowSelectButtons,
  TableFilterTypingInputGroup,
} from 'components';

import {
  glTypes,
  periodTypes,
  recordFilterList,
  viewCommentsOptions,
  filterByApproveStatuses,
  filterByTemplateStatuses,
  accountReconciliationCategories,
} from 'services/shapes';

/**
 * Table Filter component
 */

function TableFilter(props) {
  const {
    query,
    search,
    entries,
    pdfLoading,
    handleGetPDF,
    handleSubmit,
    handleChange,
    handleRowSelect,
    handleOnKeyDown,
    hideTransactions,
    enablePeriodType,
    showFilterHeader,
    withPeriodization,
    handleBankRowSelect,
    disablePartialReconcile,
  } = props;

  return (
    <>
      {showFilterHeader ? (
        <Heading level={4} className="card-title">
          <Trans>Filter Options</Trans>
        </Heading>
      ) : null}
      <Row>
        <Col md={12}>
          <Form inline onChange={handleChange} onSubmit={handleSubmit}>
            <TopFiltersHolder>
              <div>
                <TableFilterTypingInputGroup
                  {...{
                    query,
                    search,
                    entries,
                    handleSubmit,
                    handleOnKeyDown,
                    hideTransactions,
                    withPeriodization,
                  }}
                />
              </div>
              <FilterItem>
                <ButtonGroup>
                  <Button size="sm" onClick={handleGetPDF}>
                    {pdfLoading ? (
                      <Spinner type="white" size="md" />
                    ) : (
                      <Trans>Download in PDF</Trans>
                    )}
                  </Button>
                </ButtonGroup>
              </FilterItem>
            </TopFiltersHolder>
            <br />
            <StyledFilterGroup>
              {!!enablePeriodType && !!search.periodType && (
                <FilterItem>
                  <Field
                    type="select"
                    size="small"
                    name="periodType"
                    label={t`Period Type`}
                    className="filter-item"
                    defaultValue={search.periodType}
                  >
                    {periodTypes().map(
                      (item) =>
                        item !== 6 && (
                          <option key={item.id} value={item.id}>
                            {item.label}
                          </option>
                        ),
                    )}
                  </Field>
                </FilterItem>
              )}
              {!!search.glType && (
                <FilterItem>
                  <Field
                    type="select"
                    size="small"
                    defaultValue={search.glType}
                    name="glType"
                    label={t`Ledger`}
                    placeholder={t`Select Ledger type`}
                  >
                    {glTypes().map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </Field>
                </FilterItem>
              )}
              {!!search.filterByApprove && (
                <FilterItem>
                  <Field
                    type="select"
                    size="small"
                    defaultValue={search.filterByApprove}
                    name="filterByApprove"
                    label={t`Filter Account by`}
                    placeholder={t`Select status`}
                  >
                    {filterByApproveStatuses().map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </Field>
                </FilterItem>
              )}
              {!!search.filterByTemplate && (
                <FilterItem>
                  <MultiSelect
                    name="filterByTemplate"
                    title={t`Filter Account by Template`}
                    checked={search.filterByTemplate}
                    size="small"
                    fill
                    onSelect={handleChange}
                    options={filterByTemplateStatuses()}
                  />
                </FilterItem>
              )}
              {!!search.accountCategories && (
                <FilterItem>
                  <Field
                    type="select"
                    size="small"
                    defaultValue={search.accountCategories}
                    name="accountCategories"
                    label={t`Account Categories`}
                    placeholder={t`Select Category`}
                  >
                    {accountReconciliationCategories().map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </Field>
                </FilterItem>
              )}
              {!!search.commentsOption && (
                <FilterItem>
                  <Field
                    type="select"
                    size="small"
                    defaultValue={search.commentsOption}
                    name="commentsOption"
                    label={t`Comments Option`}
                    placeholder={t`Select Comment Visbility`}
                  >
                    {viewCommentsOptions().map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </Field>
                </FilterItem>
              )}
              {!!handleRowSelect && (
                <RowSelectButtons handleRowSelect={handleRowSelect} />
              )}
              {!!search.recordFilterList &&
                recordFilterList()
                  .filter((filters) =>
                    disablePartialReconcile
                      ? filters.id !== 'partial'
                      : filters.id,
                  )
                  .map((recordFilter) => (
                    <FilterItem key={`recordFilterList-${recordFilter.id}`}>
                      <Field
                        type="checkbox"
                        value={recordFilter.id}
                        defaultChecked={search.recordFilterList.includes(
                          recordFilter.id,
                        )}
                        id={`${recordFilter.id}-recordFilterList`}
                        name="recordFilterList"
                        label={recordFilter.label}
                      />
                    </FilterItem>
                  ))}
              {!!search.filterByFrequency && (
                <FilterItem>
                  <MultiSelect
                    name="filterByFrequency"
                    title={t`Filter Account by Frequency`}
                    checked={search.filterByFrequency}
                    size="small"
                    fill
                    onSelect={handleChange}
                    options={periodTypes()}
                  />
                </FilterItem>
              )}
              {!!search.accountUpdated && (
                <FilterItem>
                  <Field
                    type="checkbox"
                    key="updatedAccounts"
                    value={!search.accountUpdated}
                    defaultChecked={search.accountUpdated}
                    id="accountUpdated"
                    name="accountUpdated"
                    label={t`Show Updated Accounts`}
                  />
                </FilterItem>
              )}
              {!!handleBankRowSelect && (
                <FilterItem>
                  <StyledBankRowsSelectGroup>
                    <RowSelectButtons handleRowSelect={handleBankRowSelect} />
                  </StyledBankRowsSelectGroup>
                </FilterItem>
              )}
            </StyledFilterGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
}

TableFilter.propTypes = {
  showFilterHeader: PropTypes.bool,
  hideTransactions: PropTypes.bool,

  search: PropTypes.shape({}).isRequired,
  query: PropTypes.string.isRequired,
  entries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  enablePeriodType: PropTypes.bool,
  disablePartialReconcile: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleRowSelect: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  handleBankRowSelect: PropTypes.func,
};

TableFilter.defaultProps = {
  hideTransactions: false,
  showFilterHeader: true,
  enablePeriodType: false,
  disablePartialReconcile: false,
  handleRowSelect: undefined,
  handleBankRowSelect: undefined,
};

export default TableFilter;

const TopFiltersHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterItem = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
`;

const StyledFilterGroup = styled.div`
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledBankRowsSelectGroup = styled.div`
  margin-left: auto;
`;
