import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Row,
  Col,
  ButtonGroup,
  OverlayTrigger,
  Tooltip as BTooltip,
} from 'react-bootstrap';

import { Button } from 'components';

import isEmpty from 'lodash-es/isEmpty';

function TableIconGroup(props) {
  const { shape, roleType, handleIconGroup, additionalData } = props;
  const buttonValues = shape(roleType, additionalData);

  const firstGroup = !isEmpty(buttonValues.buttonShapes.firstGroup)
    ? buttonValues.buttonShapes.firstGroup.map((button) => {
        let buttonTemplate = (
          <Button
            key={`separableIconGroup-${button.id}`}
            size={button.size}
            fill={button.fill}
            kind={button.kind}
            wide={button.wide}
            style={button.style}
            onClick={() => handleIconGroup(button.id, props)}
            magnify={button.magnify}
          >
            {button.icon} {button.label}
            {button?.additionalData?.badge && (
              <NumberFiles>{button?.additionalData?.badge}</NumberFiles>
            )}
          </Button>
        );

        if (button?.additionalData?.tooltip) {
          buttonTemplate = (
            <OverlayTrigger
              overlay={
                <BTooltip id="tooltip">
                  {button?.additionalData?.tooltip}
                </BTooltip>
              }
              placement="bottom"
            >
              {buttonTemplate}
            </OverlayTrigger>
          );
        }

        return button.isEnabled && buttonTemplate;
      })
    : '';
  const secondGroup = !isEmpty(buttonValues.buttonShapes.secondGroup)
    ? buttonValues.buttonShapes.secondGroup.map(
        (button) =>
          button.isEnabled && (
            <Button
              key={`separableIconGroup-${button.id}`}
              size={button.size}
              fill={button.fill}
              kind={button.kind}
              wide={button.wide}
              style={button.style}
              onClick={() => handleIconGroup(button.id, props)}
              magnify={button.magnify}
            >
              {button.icon} {button.label}
            </Button>
          ),
      )
    : '';

  return (
    <Row>
      {!!firstGroup && (
        <Col md={secondGroup ? 6 : 12}>
          <div>
            <ButtonGroup>{firstGroup}</ButtonGroup>
          </div>
        </Col>
      )}
      {!!secondGroup && (
        <Col md={firstGroup ? 6 : 12}>
          <div className="pull-right" style={{ marginRight: '16px' }}>
            <ButtonGroup>{secondGroup}</ButtonGroup>
          </div>
        </Col>
      )}
    </Row>
  );
}

TableIconGroup.propTypes = {
  shape: PropTypes.node.isRequired,
  roleType: PropTypes.shape({}).isRequired,
  handleIconGroup: PropTypes.func.isRequired,
};

export default TableIconGroup;

const NumberFiles = styled.p`
  top: -10px;
  right: -10px;
  color: white;
  padding: 3px 7px;
  position: absolute;
  font-size: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: #7ac29a;
`;
