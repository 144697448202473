import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export function Avatar(props) {
  const { user, dimensions } = props;

  let source = '';
  let initials = '';

  if (user && user.uuid) {
    source = user.avatar;

    const nameSplit = user.name.split(' ');
    const firstName = nameSplit[0] || '';
    const lastName = nameSplit[1] || '';

    // eslint-disable-next-line prefer-destructuring
    initials = firstName[0];

    if (lastName) {
      initials += ` ${lastName[0]}`;
    }
  }

  return (
    <UserAvatar src={source} dimensions={dimensions}>
      {!source && !!initials && (
        <Initials dimensions={dimensions}>{initials}</Initials>
      )}
    </UserAvatar>
  );
}

Avatar.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  dimensions: PropTypes.number.isRequired,
};

export default Avatar;

const UserAvatar = styled.div`
  width: ${(props) => props.dimensions}px;
  height: ${(props) => props.dimensions}px;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.dimensions / 2}px;
  ${(props) =>
    props.src &&
    `background-image: url(${props.src});
    background-size: cover;
    background-position: center center;
  `}
  justify-content: center;
  background-color: #dbdbdb;
  position: relative;
`;

const Initials = styled.div`
  color: black;
  font-size: ${(props) => props.dimensions / 5}px;
  font-weight: 600;
`;
