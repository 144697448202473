import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import BToggle from 'react-bootstrap-toggle';
import PropTypes from 'prop-types';

import { Label } from 'components';

const labelPositions = {
  top: 'column',
  left: 'row-reverse',
  right: 'row',
  bottom: 'column-reverse',
};

export function Toggle(props) {
  const {
    id,
    name,
    value,
    label,
    onClick,
    markActive,
    labelPosition,
    ...rest
  } = props;
  const { disabled } = rest;
  const labelStyles = {};

  if (!disabled) {
    labelStyles.cursor = 'pointer';
  }

  if (labelPosition !== 'top') {
    labelStyles.paddingLeft = '10px';
  }

  if (markActive) {
    if (typeof value === 'undefined') {
      labelStyles.color = '#66615b';
    } else {
      labelStyles.color = 'white';
    }
  }

  const handleClick = () => {
    onClick(id || name);
  };

  const labelComponent = (
    <Label style={labelStyles} onClick={handleClick}>
      {label}
    </Label>
  );

  return (
    <ToggleHolder labelPosition={labelPosition}>
      {!!label && labelPosition === 'top' && labelComponent}
      <BToggle
        id={id || name}
        on={t`ON`}
        off={t`OFF`}
        active={typeof value === 'boolean' ? value : value === 'true'}
        onstyle="success"
        offstyle="warning"
        {...rest}
        onClick={handleClick}
      />
      {!!label && labelPosition !== 'top' && labelComponent}
    </ToggleHolder>
  );
}

Toggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  markActive: PropTypes.bool,
  labelPosition: PropTypes.string,
};

Toggle.defaultProps = {
  id: '',
  name: '',
  value: false,
  label: undefined,
  onClick: () => {},
  disabled: false,
  markActive: false,
  labelPosition: undefined,
};

export default Toggle;

const ToggleHolder = styled.div`
  display: flex;
  align-items: ${(props) =>
    props.labelPosition === 'top' ? 'flex-start' : 'flex-end'};
  gap: ${({ labelPosition }) =>
    ['left', 'right'].includes(labelPosition) && '5px'};
  flex-direction: ${(props) =>
    props.labelPosition ? labelPositions[props.labelPosition] : 'row'};
`;
