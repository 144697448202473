import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  TitleYearDropdown,
  GenerateReportButton,
  CustomTableWithAutoSettings,
} from 'components';

import { periodTypes } from 'services/shapes';
import { trialBalanceReportColumns } from 'services/tableshapes';
import { trialBalanceReportFilters } from 'services/tableFilterShapes';
import { useTrialBalanceReportGenerator } from 'services/hooks/reports';
import { fromCompany } from 'store/selectors';

function TrialBalanceReport(props) {
  const {
    data,
    title,
    search,
    loading,
    tableState,
    handleAction,
    actionSubmitting,
    periodYear,
    periodYears,
  } = props;
  const [filters, setFilters] = useState({ trialBalance: 'quarterly' });

  const sortBy = useMemo(
    () => [
      {
        id: 'accountNumber',
        desc: false,
      },
    ],
    [],
  );
  const globalFilter = useMemo(
    () => ({
      periodTypeCustom: periodTypes().filter((p) => p.id === 'quarterly'),
    }),
    [],
  );

  const company = useSelector(fromCompany.getCompany);
  const { currentCompanyName, currentCompanyRegistrationNumber } = company;
  const { generatePDF, disabled, pdfGenerating, Preview } =
    useTrialBalanceReportGenerator({
      data,
      loading,
      frequency: filters.trialBalance,
      accountingYear: periodYear,
      company: currentCompanyName,
      orgNumber: currentCompanyRegistrationNumber,
    });

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-trialBalanceReport">
      <CardPDFTitle
        title={
          <TitleWrapper>
            <div>
              <TitleYearDropdown
                handleAction={handleAction}
                title={t`Trial Balance`}
                year={periodYear}
                years={periodYears}
              />
            </div>
            <ButtonsHolder>
              {Preview()}
              <GenerateReportButton
                disabled={disabled}
                pdfGenerating={pdfGenerating}
                generatePDF={generatePDF}
                options="TRIAL"
                filters={filters}
                setFilters={setFilters}
                resetFilters={() => {
                  setFilters({ trialBalance: 'quarterly' });
                }}
                saveReport={false}
                skipDownload={false}
              />
            </ButtonsHolder>
          </TitleWrapper>
        }
      />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          columns: trialBalanceReportColumns({ ...search, tableState }),
          tableId: 'trialBalanceReport',
          loadingId: 'accountNumber',
          reportMode: search.reportMode,
          filterConfigs: trialBalanceReportFilters(),
          headerConfigs: {
            exportFileName: title,
          },
          initialState: {
            sortBy,
            globalFilter,
          },
        }}
      />
    </Card>
  );
}

export default TrialBalanceReport;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
