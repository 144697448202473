import React, { useEffect, useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { TrialBalanceReport } from 'components';

import { fromResource } from 'store/selectors';
import {
  getTrialBalanceApi,
  getTrialBalancePeriodYearsApi,
} from 'services/apihelpers';
import { resourceListReadRequest } from 'store/actions';
import { toReportsAccountDetails } from 'services/routehelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function TrialBalanceReportContainer(props) {
  const { location, history, match } = props;
  const dispatch = useDispatch();
  const [tableState, setTableState] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const data = useSelector((state) =>
    fromResource.getList(state, getTrialBalanceApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${getTrialBalanceApi}ListRead`),
  );
  const periodYears = useSelector((state) =>
    fromResource.getList(state, getTrialBalancePeriodYearsApi),
  );
  const getPeriodYear = useMemo(() => {
    const { periodYear } = location
      ? queryString.parse(location.search, { parseNumbers: true })
      : {};

    return periodYear || match?.params?.accountingYear;
  }, [location]);

  const fetchData = () => {
    dispatch(
      resourceListReadRequest(getTrialBalanceApi, {
        year: getPeriodYear,
        companyId: match?.params?.companyId,
      }),
    );
    dispatch(
      resourceListReadRequest(getTrialBalancePeriodYearsApi, {
        companyId: match?.params?.companyId,
      }),
    );
  };
  const getSearch = () => {
    const search = location
      ? queryString.parse(location.search, { parseBooleans: true })
      : {};

    return {
      reportMode: search.reportMode || false,
    };
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const redirectToReportsDetailed = (period, item) => {
    history.push(
      toReportsAccountDetails({
        period,
        account: item.accountNumber,
        accumulated: !!tableState?.globalFilter?.accumulatedCustom,
      }),
    );
  };
  const handleAction = async (action, itemProps) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'tableStateChange': {
          setTableState(itemProps);
          break;
        }

        case 'changeYear': {
          dispatch(
            resourceListReadRequest(getTrialBalanceApi, {
              year: itemProps,
              companyId: match?.params?.companyId,
            }),
          );
          history.push({
            search: queryString.stringify({
              ...queryString.parse(location.search),
              periodYear: itemProps,
            }),
          });
          break;
        }

        case 'p0':
        case 'MONTHLY1':
        case 'MONTHLY2':
        case 'MONTHLY3':
        case 'MONTHLY4':
        case 'MONTHLY5':
        case 'MONTHLY6':
        case 'MONTHLY7':
        case 'MONTHLY8':
        case 'MONTHLY9':
        case 'MONTHLY10':
        case 'MONTHLY11':
        case 'MONTHLY12':
        case 'BIMONTHLY1':
        case 'BIMONTHLY2':
        case 'BIMONTHLY3':
        case 'BIMONTHLY4':
        case 'BIMONTHLY5':
        case 'BIMONTHLY6':
        case 'QUARTERLY1':
        case 'QUARTERLY2':
        case 'QUARTERLY3':
        case 'QUARTERLY4':
        case 'TERTIARY1':
        case 'TERTIARY2':
        case 'TERTIARY3':
        case 'HALF_YEARLY1':
        case 'HALF_YEARLY2':
        case 'YEARLY1':
        case 'all': {
          redirectToReportsDetailed(action, itemProps);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const search = getSearch();

  useEffect(fetchData, [JSON.stringify(match.params)]);

  return (
    <TrialBalanceReport
      {...{
        data,
        search,
        loading,
        tableState,
        actionSubmitting,
        title: t`Trial Balance`,
        handleAction,
        periodYear: getPeriodYear,
        periodYears,
      }}
    />
  );
}

export default TrialBalanceReportContainer;
