import { t } from '@lingui/macro';

export const PAGE_VIEW = () => [
  {
    id: 'sm',
    label: t`Small`,
  },
  {
    id: 'md',
    label: t`Medium`,
  },
];

export const PAGE_SIZES = () => [
  { id: 15, label: 15 },
  { id: 50, label: 50 },
  { id: 100, label: 100 },
  { id: 500, label: 500 },
  { id: 1000, label: 1000 },
  { id: 1000000, label: t`All` },
];

export const DEFAULT_COL = {
  width: 150,
  minWidth: 20,
  maxWidth: 1000,
};

export const TOP_CONTENT_MODES = {
  small: 'small',
  medium: 'medium',
  def: 'default',
};

export const TABLE_EXPORT = () => [
  {
    id: 'downloadPDF',
    label: t`PDF`,
  },
  {
    id: 'downloadCSV',
    label: t`CSV`,
  },
  {
    id: 'downloadXLSX',
    label: t`Excel`,
  },
];

export const LOTTIE_SIZES = {
  sm: {
    controlled: '25.6px',
    success: '24px',
    negative: '20px',
    notFound: '28px',
  },
  md: {
    controlled: '32px',
    success: '30px',
    negative: '25px',
    notFound: '33px',
  },
};

export const MAX_HEIGHT_FOR_MODAL = 500;
