import React from 'react';
import styled from 'styled-components/macro';

const types = {
  link: {
    marginBottom: -2,
    fontSize: 16,
  },
  extraButton: {
    marginRight: 5,
    marginBottom: -1,
    fontSize: 12,
  },
  selectConfig: {
    marginRight: 5,
    fontSize: 16,
    marginBottom: -4,
  },
  editableAction: {
    marginTop: -2,
    fontSize: 15,
  },
};

function ButtonIcon(props) {
  const {
    as,
    type,
    color,
    mirror,
    fontSize = 16,
    marginTop = 0,
    marginLeft = 0,
    marginRight = 0,
    marginBottom = -4,
    title,
  } = props;

  return (
    <Icon
      as={as}
      color={color}
      mirror={mirror}
      fontSize={type ? types[type].fontSize : fontSize}
      marginTop={type ? types[type].marginTop : marginTop}
      marginLeft={marginLeft}
      marginRight={type ? types[type].marginRight : marginRight}
      marginBottom={type ? types[type].marginBottom : marginBottom}
      title={title}
    />
  );
}

export default ButtonIcon;

const Icon = styled.svg`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  ${({ color }) => color && `color: ${color};`}
  ${({ mirror }) =>
    mirror &&
    `
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  `};
`;
