import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import AutoSuggestInput from 'containers/AutoSuggestInput';
import { Button, Heading, Spinner, ButtonIcon } from 'components';

function MoveGroupUsersModal(props) {
  const {
    item,
    onSubmit,
    userGroups,
    closeModal,
    handleChange,
    selectedUserGroups,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>
            {t`Move`} <b>{item.userName}</b> {t`user`}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <InputHolder>
            <AutoSuggestInput
              multiple
              clearButton
              id="select-users"
              size="lg"
              label={t`Selected user groups to be moved to`}
              bsSize="lg"
              options={userGroups}
              selected={selectedUserGroups}
              placeholder={t`Please Select User Groups`}
              handleAutoSuggestInputChange={(value) =>
                handleChange(value, 'userGroups')
              }
            />
          </InputHolder>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit || !selectedUserGroups.length}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default MoveGroupUsersModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;
