import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { CrossYearUnlinkModal } from 'components';

import { resourceCreateRequest } from 'store/actions';
import { deactivateCrossYearApi } from 'services/apihelpers';

function CrossYearUnlinkModalContainer(props) {
  const { data, toggleModal } = props;
  const dispatch = useDispatch();
  const submitting = useSelector((state) =>
    pending(state, `${deactivateCrossYearApi}Create`),
  );
  const handleSubmit = async () => {
    try {
      await dispatch(resourceCreateRequest(deactivateCrossYearApi, data));
      toggleModal(false, true);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  return (
    <CrossYearUnlinkModal
      {...{
        data: data?.listOfBreakingLines || {},
        submitting,
        toggleModal,
        handleSubmit,
      }}
    />
  );
}

CrossYearUnlinkModalContainer.propTypes = {
  data: PropTypes.shape({}).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default CrossYearUnlinkModalContainer;
