import { t } from '@lingui/macro';

/**
 * Vacation Pay Basis Grid
 */
export const columns = () => [
  {
    width: 500,
    Header: t`Account`,
    accessor: 'accountId',
    disableSortBy: true,
    withTooltip: true,
    className: '',
    cell: {
      converter: (_, arr) => `${arr.accountId} - ${arr.name}`,
    },
  },
  {
    width: 240,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    disableSortBy: true,
    withTotal: true,
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
