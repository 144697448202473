import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { CustomTableWithAutoSettings } from 'components';

import { years } from 'services/shapes';
import { FileHistoryColumns } from 'services/tableshapes';
import { fileHistoryFilters } from 'services/tableFilterShapes';
import { generateUniqueFilterOptions } from 'services/helpers';

function DocumentFlow(props) {
  const {
    data,
    company,
    search,
    loading,
    isReadOnly,
    handleAction,
    actionSubmitting,
  } = props;
  const { accountingYear } = useParams();
  const preparedData = useMemo(
    () =>
      Array.isArray(data)
        ? data.map((item) => ({
            ...item,
            fileCategory: item.fileCategory.toUpperCase(),
          }))
        : [],
    [JSON.stringify(data)],
  );

  const customFilters = useMemo(
    () => ({
      uploadedBy: generateUniqueFilterOptions(data, 'uploadedBy'),
    }),
    [JSON.stringify(data)],
  );
  const initialFilters = useMemo(
    () =>
      search.reportMode
        ? {}
        : {
            year: years().filter((item) => [+accountingYear].includes(item.id)),
          },
    [accountingYear],
  );

  return (
    <CustomTableWithAutoSettings
      {...{
        data: preparedData,
        loading,
        columns: FileHistoryColumns(search.reportMode, isReadOnly),
        tableId: 'documentFlowTable',
        headerConfigs: {
          exportFileName: `${t`Reports`} - ${t`Files`} - ${
            company?.currentCompanyName
          }`,
        },
        customFilters,
        loadingId: 'fileKey',
        handleAction,
        withRowClick: true,
        filterConfigs: fileHistoryFilters(customFilters),
        reportMode: search?.reportMode,
        actionSubmitting,
        initialState: {
          globalFilter: initialFilters,
        },
      }}
    />
  );
}

export default DocumentFlow;
