export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const loginRequest = (company) => ({
  type: LOGIN_REQUEST,
  payload: company,
  meta: {
    resource: 'login',
    thunk: 'loginUser',
  },
});

export const loginSuccess = (user, request, thunk) => ({
  type: LOGIN_SUCCESS,
  payload: user,
  meta: {
    request,
    thunk,
  },
});

export const loginFailure = (error, request, thunk) => ({
  type: LOGIN_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const userRequest = () => ({
  type: USER_REQUEST,
  payload: {},
  meta: {
    resource: 'user',
    thunk: 'fetchUser',
  },
});

export const userSuccess = (user, request, thunk) => ({
  type: USER_SUCCESS,
  payload: user,
  meta: {
    request,
    thunk,
  },
});

export const userFailure = (error, request, thunk) => ({
  type: USER_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const registrationRequest = (registrationData) => ({
  type: REGISTER_REQUEST,
  payload: { registrationData },
  meta: {
    resource: 'registerCompany',
    thunk: 'registerCompany',
  },
});

export const registrationSuccess = (user, request, thunk) => ({
  type: REGISTER_SUCCESS,
  payload: user,
  meta: {
    request,
    thunk,
  },
});

export const registrationFailure = (error, request, thunk) => ({
  type: REGISTER_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const TIMEOUT_REQUEST = 'TIMEOUT_REQUEST';
export const TIMEOUT_SUCCESS = 'TIMEOUT_SUCCESS';
export const TIMEOUT_FAILURE = 'TIMEOUT_FAILURE';

export const timeoutRequest = () => ({
  type: TIMEOUT_REQUEST,
  meta: {
    resource: 'login',
    thunk: 'timeoutUser',
  },
});

export const timeoutSuccess = (request, thunk) => ({
  type: TIMEOUT_SUCCESS,
  meta: {
    request,
    thunk,
  },
});

export const timeoutFailure = (error, request, thunk) => ({
  type: TIMEOUT_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const VALIDATE_REGISTRATION_TOKEN_REQUEST =
  'VALIDATE_REGISTRATION_TOKEN_REQUEST';
export const VALIDATE_REGISTRATION_TOKEN_SUCCESS =
  'VALIDATE_REGISTRATION_TOKEN_SUCCESS';
export const VALIDATE_REGISTRATION_TOKEN_FAILURE =
  'VALIDATE_REGISTRATION_TOKEN_FAILURE';

export const validateRegistrationTokenRequest = (params) => ({
  type: VALIDATE_REGISTRATION_TOKEN_REQUEST,
  payload: params,
  meta: {
    resource: 'confirmRegistration',
    thunk: 'validateRegistrationTokenUser',
  },
});

export const validateRegistrationTokenSuccess = (request, thunk) => ({
  type: VALIDATE_REGISTRATION_TOKEN_SUCCESS,
  payload: request,
  meta: {
    request,
    thunk,
  },
});

export const validateRegistrationTokenFailure = (error, request, thunk) => ({
  type: VALIDATE_REGISTRATION_TOKEN_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const updateCurrentUserSuccess = (response) => ({
  type: UPDATE_CURRENT_USER_SUCCESS,
  payload: response,
});

export const UPDATE_ACCOUNT_UPDATE_STATUS = 'UPDATE_ACCOUNT_UPDATE_STATUS';
export const updateAccountUpdateStatus = (response) => ({
  type: UPDATE_ACCOUNT_UPDATE_STATUS,
  payload: response,
});

export const UPDATE_PROFILE_COMPLETENESS_PERCENTAGE =
  'UPDATE_PROFILE_COMPLETENESS_PERCENTAGE';

export const updateProfileCompletenessPercentage = (response) => ({
  type: UPDATE_PROFILE_COMPLETENESS_PERCENTAGE,
  payload: response,
});

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const addAlert = (response) => ({
  type: ADD_ALERT,
  payload: response,
});

export const removeAlert = (response) => ({
  type: REMOVE_ALERT,
  payload: response,
});

export const SIDEBAR_PROGRESS_BADGE = 'SIDEBAR_PROGRESS_BADGE';
export const sidebarProgressUpdate = (response) => ({
  type: SIDEBAR_PROGRESS_BADGE,
  payload: response,
});

export const UPDATE_USER_SUCCESS = 'RELOAD_USER_SUCCESS';

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const META_USER_DATA_REQUEST = 'META_USER_DATA_REQUEST';
export const META_USER_DATA_SUCCESS = 'META_USER_DATA_SUCCESS';
export const META_USER_DATA_FAILURE = 'META_USER_DATA_FAILURE';

export const metaUserDataSuccess = (data) => ({
  type: META_USER_DATA_SUCCESS,
  payload: data,
});

export const metaUserDataFailure = (error) => ({
  type: META_USER_DATA_FAILURE,
  error: true,
  payload: error,
});
