import { t } from '@lingui/macro';

function getErrorMessage(error) {
  if (error?.reason) {
    return `${error?.error}\n${t`Details`}: ${error?.reason}`;
  }
  if (error?.response?.headers?.get('Response-Message')) {
    return decodeURIComponent(error.response.headers.get('Response-Message'));
  }
  if (error.headers?.get('Response-Message')) {
    return decodeURIComponent(error.headers.get('Response-Message'));
  }
  if (error?.message) {
    return error.message;
  }
  return t`An unexpected error occurred`; // Default error message
}

export default getErrorMessage;
