import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

export const columns = (isReadOnly) => [
  {
    width: 80,
    Header: t`Year`,
    accessor: 'year',
    className: '',
  },
  {
    width: 80,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 230,
    Header: t`Comment`,
    accessor: 'comment',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Added/Changed by`,
    accessor: 'username',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Added Time`,
    accessor: 'createdTime',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'datetime',
    },
  },
  {
    width: 200,
    Header: t`Changed Time`,
    accessor: 'modifiedTime',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      type: 'datetime',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 80,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
