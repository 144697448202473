import { t } from '@lingui/macro';

/**
 * Social Security Specification
 */
export const columns = (accumulated) => [
  {
    id: 1,
    label: t`Zone`,
    value: 'sone',
    sortable: false,
    className: '',
    enabled: true,
  },
  ...(accumulated
    ? [
        {
          id: 2,
          label: t`Period`,
          value: 'period',
          sortable: false,
          className: '',
          enabled: true,
        },
      ]
    : []),
  {
    id: 3,
    label: t`Name`,
    value: 'name',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 4,
    label: t`Type`,
    value: 'type',
    sortable: false,
    className: 'text-left',
    enabled: true,
  },
  {
    id: 5,
    label: t`Amount`,
    value: 'amount',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 6,
    label: t`Percentage`,
    value: 'percentage',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
  {
    id: 7,
    label: t`Social Security Amount`,
    value: 'percentage',
    sortable: false,
    className: 'text-right',
    enabled: true,
  },
];

export default { columns };
